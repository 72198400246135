import React, { useCallback, useEffect, useState } from "react"
import { Routes, Route,Navigate,NavLink as MyNavLink, Link, useLocation } from "react-router-dom";
import { createIntl, createIntlCache, RawIntlProvider } from "react-intl"
import en_US from "i18n/en_US.js"
import zh_CN from "i18n/zh_CN.js"
import styled, {
  ThemeProvider
} from "styled-components"
import theme from "./theme";
import "@arco-design/web-react/dist/css/arco.css";
import { Modal } from "@arco-design/web-react";

import Page from "components/Page"
import ScrollToTop from "components/ScrollToTop"
import Logo from "components/Logo"
import Button from "components/Button";
import Footer from "components/Footer"

import Home from "views/Home"
import SupplyWithdraw from "views/SupplyWithdraw"
import BorrowRepay from "views/BorrowRepay"
import MintBURN from "views/MintBURN"
import Emission from "views/Emission"
import Stake from "views/Stake"
import Liquidity from "views/Liquidity"
import Markets from "views/Markets"
import MarketsDetails from "views/MarketsDetails"
import Bridge from "views/Bridge"
import Liquidation from "views/Liquidation"
import Liquidate from "views/Liquidate"
import History from "views/History"
import Price from "views/Price"

import { useWeb3React } from '@web3-react/core'
import MetaMaskCard from 'walletCards/connectorCards/MetaMaskCard';
import WalletConnectV2Card from 'walletCards/connectorCards/WalletConnectV2Card';
import CoinbaseWalletCard from 'walletCards/connectorCards/CoinbaseWalletCard';

import stake_LP_Token from "lending-sdk/lib/clean_build/contracts/stake/IERC20.json"
import liquidity_LP_Token from "lending-sdk/lib/clean_build/contracts/Liquidity/IERC20.json"
import IERC20 from "lending-sdk/lib/clean_build/contracts/general/IERC20.json"
import menu from 'assets/header/menu.svg'
import Fire from 'assets/Fire.svg'

import { useQuery,getName } from "utils";
import { network_Name, del_Notify, del__cancel, add_Notifies, update_Notifies, } from "lending-sdk/utils/index1";
import useLending from "hooks/useLending";
import multicall2ABI from 'abis/multicall2.json'
import erc20ABI from 'abis/erc20claimed.json'
import Notification from "components/Notification";
import Notification__cancel from "components/Notification__cancel";


declare global {
  interface Window {
    isBitKeep: boolean,
    bitkeep: {
      ethereum: boolean,
    },
    okxwallet: boolean,
    new_web3: any;
  }
}


// 中英翻译
const messages: Record<string, Record<string, string>> = {
  en: en_US,
  cn: zh_CN,
};
// const local_lauguage = window.localStorage.getItem("lending-local-language");
// const initialLocale = local_lauguage || "en";
const initialLocale = "en";
const cache = createIntlCache();

let intl = createIntl(
  {
    locale: initialLocale,
    defaultLocale: "en",
    messages: messages[initialLocale],
  },
  cache
);
let fmt = intl.formatMessage;
// 中英翻译
const App: React.FC = () => {
  const [mobileMenu, setMobileMenu] = useState(true);
  const [show__menu_all, setShow__menu_all] = useState(false);
  const Lending = useLending();
  const [claimed, setClaimed] = useState(false);
  const [claiming, setClaiming] = useState(false);
  const [nowNotifies, setNowNotifies] = useState<any>([]);
  const [show__cancel, setShow__cancel] = useState<boolean>(false);

  // 中英翻译
  const [locale, setLocale] = React.useState(initialLocale);

  const changeLanguage = (newLocale: string): void => {
    intl = createIntl(
      { locale: newLocale, defaultLocale: "en", messages: messages[newLocale] },
      cache
    );
    console.log(newLocale);
    window.localStorage.setItem("lending-local-language", newLocale);
    document.documentElement.lang = newLocale;
    setLocale(newLocale);
  };


  // TopBar
  const deployAssets = {
    stake: stake_LP_Token,
    liquidity: liquidity_LP_Token,
  }
  const {
    connector,
    account,
    chainId,
    provider,
    ENSName,
    isActive,
    isActivating,
  } = useWeb3React();
  const [curURL, setCurURL] = useState(window.location.href)
  const [showListSetting, setShowListSetting] = useState(false)
  const [networkName, setNetworkName] = useState<string>("mainnet")
  const [hasStakeLP, sethasStakeLP] = useState<Record<string, any> | undefined>(undefined)
  const [hasLiquidityLP, sethasLiquidityLP] = useState<Record<string, any> | undefined>(undefined)
  let query = useQuery()


  useEffect(() => {
    account && chainId
      ? setNetworkName(network_Name[chainId])
      : setNetworkName("mainnet");
    const _hasStakeLP = deployAssets["stake"]['networks'][`${chainId}`]
    const hasLiquidityLP = deployAssets["liquidity"]['networks'][`${chainId}`]
    sethasStakeLP(_hasStakeLP)
    sethasLiquidityLP(hasLiquidityLP)
  }, [account, chainId])

  useEffect(() => {
    let url = window.location.href;
    setCurURL(url);
  }, [query])

  // TopBar

  // walletButton
  const supportchain = chainId ? Object.keys(IERC20.networks).includes((chainId as number).toString()) : false;
  const [unlockModalIsOpen, setUnlockModalIsOpen] = useState(false);
  const [userAdress, setUseAdress] = useState('')
  const [walletIcon, setWalletIcon] = useState('')


  const handleUnlockWalletClick = useCallback(() => {
    setUnlockModalIsOpen(true)
  }, [account, chainId])


  useEffect(() => {
    const localAccount: any = (account ? account.toString() : false) || localStorage.getItem("account");
    if (account) {
      const localAdress = localAccount.substring(0, 4) + "..." + localAccount.substring(localAccount.length - 4, localAccount.length)
      localStorage.setItem("account", localAccount);
      setUseAdress(localAdress)
    } else {
      // setUnlockModalIsOpen(true)
      localStorage.removeItem("account");
    }
  }, [account]);

  useEffect(() => {
    try {
      connector.activate()
    } catch (error) {
      console.log(error)
    }

  }, [])
  // walletButton



  const tokenInfos = [{
    address: '0x64Ea4B0B4363Fc053B073Be3ec83448Aa4035aFc',
    symbol: "DAI",
    decimals: 18,
    balance: '10000000000000000000000',
  }, {
    address: '0x4ed818eDd4a0c36A3F43826A60630879b8164401',
    symbol: "DF",
    decimals: 18,
    balance: '100000000000000000000000',
  },  {
    address: '0xEB50F151484A64b972747f31E6483C6463e32cc1',
    symbol: "USDC",
    decimals: 6,
    balance: '10000000000',
  }, {
    address: '0x778d20CE2f51B3b882b76b8268F32F6211E69834',
    symbol: "USDT",
    decimals: 6,
    balance: '10000000000',
  }, {
    address: '0x25503b21b631C85E88Dd4b6b55eac52f9672399C',
    symbol: "WBTC",
    decimals: 8,
    balance: '100000000',
  }, {
    address: '0x661C5cC96D9440F282f53dd804537811f889A205',
    symbol: "FRAX",
    decimals: 18,
    balance: '1000000000000000000000',
  }, {
    address: '0x7e7e6f65F32C6b39d2dD135F4b62646d9e2DAf17',
    symbol: "UNI",
    decimals: 18,
    balance: '1000000000000000000000',
  }, {
    address: '0x826dC9032A77D4442EaF6a2f2D3FAa21b1042BE2',
    symbol: "ARB",
    decimals: 18,
    balance: '1000000000000000000000',
  }, {
    address: '0x4AC85451c974cF297e4Cf754036Dcc01182e1694',
    symbol: "MAI",
    decimals: 18,
    balance: '1000000000000000000000',
  }]

  const handleFaucet = async () => {
    // console.log('handle Faucet')
    if(claiming){
      return false
    }
    setClaiming(true)

    const multicall2 = new Lending.web3.eth.Contract(multicall2ABI, '0xcA11bde05977b3631167028862bE2a173976CA11')
    // console.log('multicall2',multicall2)
    const calls = tokenInfos.map((tokenItem) => {
      const contractToken = new Lending.web3.eth.Contract(erc20ABI, tokenItem.address)
      const recipient = account
      const amount = tokenItem.balance
      return {
        target: tokenItem.address,
        callData: contractToken.methods["allocateTo"](recipient, amount).encodeABI()
      }
    })
    // console.log('calls', calls)
    // multicall2.methods.aggregate(calls).send({ from: account })
    multicall2.methods.tryAggregate(false,calls).send({ from: account })
      .on("transactionHash", function (hash: any) {
        console.log("transactionHash: ", hash)
        setClaimed(true)
        add_Notifies({
          state: "pedding",
          action: "Faucet Token",
          transactionHash: hash,
          time: new Date().getTime()
        }, setNowNotifies, account as string, chainId as number);
      })
      .on("receipt", function (receipt: any) {
        console.log("receipt: ", receipt)
        update_Notifies(receipt, setNowNotifies, account as string, chainId as number);
        setTimeout(() => {
          del_Notify(receipt, setNowNotifies);
        }, 3000);
      })
      .on("error", function (error: any) {
        // setClaimed(false)
        setClaiming(false)
        console.log("error", error)
        setShow__cancel(true);
        setTimeout(() => {
          setShow__cancel(false);
        }, 3000);
      })
  }
  const checkFaucet = async () => {
    // const tokenInfos = [{
    //   address: '0x64Ea4B0B4363Fc053B073Be3ec83448Aa4035aFc',
    //   symbol: "DAI",
    //   decimals: 18,
    //   balance: '10000000000000000000000',
    // }, {
    //   address: '0x4ed818eDd4a0c36A3F43826A60630879b8164401',
    //   symbol: "DF",
    //   decimals: 18,
    //   balance: '100000000000000000000000',
    // }]
    const multicall2 = new Lending.web3.eth.Contract(multicall2ABI, '0xcA11bde05977b3631167028862bE2a173976CA11')
    // console.log('multicall2',multicall2)
    const calls = tokenInfos.map((tokenItem) => {
      const contractToken = new Lending.web3.eth.Contract(erc20ABI, tokenItem.address)
      const recipient = account
      return {
        target: tokenItem.address,
        callData: contractToken.methods["claimed"](recipient).encodeABI()
      }
    })
    // console.log('calls', calls)
    const resp = await multicall2.methods.aggregate(calls).call()
    // console.log('resp', resp.returnData)
    // console.log('resp', !!Number(resp.returnData[0]), !!Number(resp.returnData[1]))

    let isClaimed = true
    resp&&resp.returnData&&resp.returnData.map((item:any)=>{
      console.log(!!!Number(item))
      if(!!!Number(item)){
        isClaimed = false
      }
    })
    // const isClaimed = !!Number(resp.returnData[0])
    setClaimed(isClaimed)
  }

  useEffect(() => {
    if (Lending && account && chainId === 11155111) {
      checkFaucet()
    }
  }, [Lending, account, chainId])




  return (
    
      <RawIntlProvider value={intl}>
        <Providers>
          <StyledTopBar>
            <StyledTopBarInner >

            <Notification
              chainID={chainId}
              notifies={nowNotifies}
              setNowNotifies={setNowNotifies}
              del_Notify={del_Notify}
            />
            <Notification__cancel
              notifies={show__cancel}
              setShow__cancel={setShow__cancel}
              del_Notify={del__cancel}
            />

              <StyledMenuLeft>
                {/* H5 menu */}
                <Settings
                  onClick={() => { setShowListSetting(!showListSetting) }}>
                  <img src={menu} alt="" />
                  {
                    showListSetting &&
                    <NetWorkList>
                      <NetWorkItem>
                        <NavLink to="/lending">
                          {fmt({ id: "topbar_Dashboard" })}
                        </NavLink>
                      </NetWorkItem>
                      {/* {
                      hasStakeLP && */}
                      {/* <NetWorkItem>
                        <NavLink to="/BLP">
                          {fmt({ id: "BLP" })}
                        </NavLink>
                        <ReactTooltip
                          id={"BLP"}
                          place="bottom"
                          type="light"
                          effect="float"
                          className="tooltipBLP"
                          textColor="#131313"
                          borderColor={"#E8ECEF"}
                          border={true}
                        >
                          <span>
                            {fmt({ id: "ComingSoon" })}
                          </span>
                        </ReactTooltip>
                      </NetWorkItem> */}
                      {hasLiquidityLP &&
                      <NetWorkItem>
                        <NavLink to="/Liquidity">
                          {fmt({ id: "Liquidity" })}
                        </NavLink>
                        <Img src={Fire}/>
                      </NetWorkItem>
                      }
                      {/* } */}
                      {/* <NetWorkItem>
                        <NavLink to="/Emission">
                          {fmt({ id: "Emission" })}
                        </NavLink>
                      </NetWorkItem> */}
                      <NetWorkItem>
                        <NavLink to={`/Markets/${networkName}`}>
                          {fmt({ id: "topbar_Markets" })}
                        </NavLink>
                      </NetWorkItem>
                      {/* <NetWorkItem>
                        <NavLink to={`/Bridge/${networkName}`}>
                          {fmt({ id: "topbar_Bridge" })}
                        </NavLink>
                      </NetWorkItem> */}
                      <NetWorkItem>
                        <NavLink to={`/Price/${networkName}`}>
                          {fmt({ id: "topbar_Price" })}
                        </NavLink>
                      </NetWorkItem>
                      <NetWorkItem>
                        <NavLink to="/Liquidation">
                          {fmt({ id: "topbar_Liquidation" })}
                        </NavLink>
                      </NetWorkItem>
                    </NetWorkList>
                  }
                </Settings>

                <StyledLogoWrapper>
                  <Logo />
                </StyledLogoWrapper>


                {/* PC menu */}
                <StyledMenu>
                  {/* Dashboard */}
                  <MenuItem className={curURL.includes("lending") ? "active" : ""}>
                    <NavLink to="/lending">
                      {fmt({ id: "topbar_Dashboard" })}
                    </NavLink>
                  </MenuItem>
                  {/* Liquidity */}
                  {/* {
                  hasStakeLP && */}
                  {/* <MenuItem className={curURL.includes("BLP") ? "active" : ''}>
                    <NavLink to="/BLP">
                      {fmt({ id: "BLP" })}
                    </NavLink>
                    <ReactTooltip
                      id={"BLP"}
                      place="bottom"
                      type="light"
                      effect="float"
                      className="tooltipBLP"
                      textColor="#131313"
                      borderColor={"#E8ECEF"}
                      border={true}
                    >
                      <span>
                        {fmt({ id: "ComingSoon" })}
                      </span>
                    </ReactTooltip>
                  </MenuItem> */}
                  {/* } */}
                  {/* Distribution */}
                  {hasLiquidityLP &&
                  <MenuItem className={curURL.includes("Liquidity") ? "active" : ''}>
                    <NavLink to="/Liquidity">
                      {fmt({ id: "Liquidity" })}
                    </NavLink>
                    <Img src={Fire}/>
                  </MenuItem>
                  }
                  {/* <MenuItem className={curURL.includes("Emission") ? "active" : ''}>
                    <NavLink to="/Emission">
                      {fmt({ id: "Emission" })}
                    </NavLink>
                  </MenuItem> */}
                  {/* Markets */}
                  <MenuItem className={curURL.includes("Markets") ? "active" : ''}>
                    <NavLink to={`/Markets/${networkName}`}>
                      {fmt({ id: "topbar_Markets" })}
                    </NavLink>
                  </MenuItem>
                  {/* <MenuItem className={curURL.includes("Bridge") ? "active" : ''}>
                    <NavLink to={`/Bridge/${networkName}`}>
                      {fmt({ id: "topbar_Bridge" })}
                    </NavLink>
                  </MenuItem> */}
                  <MenuItemSpe
                    // className={
                    //   curURL.includes("Price") ||
                    //   curURL.includes("Liquidation") ||
                    //     curURL.includes('Liquidate') ||
                    //     curURL.includes('History')
                    //     ? "active" : ''
                    // }
                    >
                    <span>{fmt({ id: "topbar_More" })}</span>
                    <NavList>
                      <NavLink
                        to={`/Price/${networkName}`}>
                        {fmt({ id: "topbar_Price" })}
                      </NavLink>
                      <NavLink
                        to={`/Liquidation/`}>
                        {fmt({ id: "topbar_Liquidation" })}
                      </NavLink>
                    </NavList>
                  </MenuItemSpe>
                </StyledMenu>
              </StyledMenuLeft>


              <StyledNavWrapper>
                <StyledAccountButtonWrapper>
                  <StyledWalletButton>
                    {
                      chainId===11155111&&
                    <Faucet 
                    className={claiming || claimed ?"ban" :''} 
                    onClick={()=>{handleFaucet()}}>Faucet</Faucet>
                    }
                  </StyledWalletButton>

                  <StyledWalletButton>
                    <Button
                      changeLanguage={changeLanguage}
                      chainId={chainId}
                      account={account}
                      onClick={handleUnlockWalletClick}
                      variant="wallet"
                      text={!!account ? userAdress : fmt({ id: "Connect_Wallet" })}
                      locale={locale}
                    >
                      {
                        !!account && walletIcon != '' && (
                          <WalletBox>
                            <WalletSVG src={walletIcon} />
                          </WalletBox>
                        )
                      }
                    </Button>
                  </StyledWalletButton>
                  <Modal
                      className="wallet"
                      title={
                        <div style={{textAlign:'left',fontWeight:'600'}}>
                          <div>
                            {chainId ? "Account" : "Connect wallet"}
                          </div>
                        </div>
                      }
                      visible={unlockModalIsOpen}
                      onOk={() => setUnlockModalIsOpen(false)}
                      onCancel={() => setUnlockModalIsOpen(false)}
                      autoFocus={false}
                      footer={null}
                    >
                      <div>
                        {/* {!chainId && <p className="gray -mt-3 mb-3">Connect wallet in one click to start using Unitus</p>} */}
                        <ConnectBoxList>
                          {((getName(connector) === "MetaMask" && chainId) ||
                            !chainId ||
                            (chainId && !supportchain)) && <MetaMaskCard />}
                          {((getName(connector) === "WalletConnect" && chainId) ||
                            !chainId ||
                            (chainId && !supportchain)) && <WalletConnectV2Card />}
                          {((getName(connector) === "Coinbase" && chainId) ||
                            !chainId ||
                            (chainId && !supportchain)) && <CoinbaseWalletCard />}
                        </ConnectBoxList>
                        {/* {!chainId && <p className="grayNoFont font15 walletBg mt-3 p-4">View only permissions. We cannot do anything without your approval,How to use Unitus? <a href="https://unitus.gitbook.io/unitus" target="_blank"
                      rel="noopener noreferrer" style={{ color: '#FBBF24' }}>Learn more <IconLaunch className="cursor-pointer ml-1" /></a></p>} */}
                      </div>
                    </Modal>
                </StyledAccountButtonWrapper>
              </StyledNavWrapper>
            </StyledTopBarInner>
          </StyledTopBar >

          {/* TopBar */}
          <ScrollToTop>
            <Page>
              <Routes>
                <Route path="/lending/lend/:CurrencyPair" element={<SupplyWithdraw />} />
                <Route path="/lending/loan/:CurrencyPair" element={<BorrowRepay />} />
                <Route path="/lending/mint/:CurrencyPair" element={<MintBURN />} />
                <Route path="/Emission" element={<Emission />} />
                <Route path="/BLP" element={<Stake />} />
                <Route path="/Liquidity" element={<Liquidity />} />
                <Route path="/Markets/:currentNetwork" element={<Markets />} />
                <Route path="/MarketsDetails/:currentNetwork/:curToken" element={<MarketsDetails />} />
                <Route path="/Liquidation" element={<Liquidation />} />
                <Route path="/Price/:currentNetwork" element={<Price />} />
                <Route path="/Liquidate/:Liquidate_account" element={<Liquidate />} />
                <Route path="/Bridge/:currentNetwork" element={<Bridge />} />
                <Route path="/History" element={<History />} />
                <Route path="/lending" element={<Home />} />
                <Route path="/" element={<Navigate to="/lending"/>} />
                <Route path="*" element={<Navigate to="/lending"/>} />
              </Routes>
            </Page>
            <Footer locale={"en"}></Footer>
          </ScrollToTop>
        </Providers>
      </RawIntlProvider>
    
  );
};

const Providers = ({ children }: {
  children: React.ReactNode
}) => {
  return (
    <ThemeProvider theme={theme}>
        {children}
    </ThemeProvider>
  )
}


// TopBar
const Settings = styled.button`
  @media(min-width: 1200px) {
    display: none;
  }
  @media(max-width: 1199px) {
    position: relative;
    border: none;
    background: none;
    img {
      width: 24px;
      margin-right: 8px;
    }
  }
`
const NetWorkList = styled.div`
  z-index: 999;
  position: absolute;
  top: 54px;
  left: 0;
  min-width: 180px;
  border-radius: 8px;
  padding-top: 10px;
  padding-bottom: 10px;
  border: 1px solid rgba(19, 19, 19, 0.08);
  background: ${(props) => props.theme.colors.white};
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.08);
  .dark & {
    background: ${(props) => props.theme.colors.black};
    box-shadow:0px 16px 32px 0px rgb(255 255 255 / 0.08);
  }
  &.networkList {
    left: -140px;
  }
  @media(max-width: 1199px) {
    top: 34px;
  }
`
const NetWorkItem = styled.div`
  cursor: pointer;
  color: #000;
  display: flex;
  align-items: center;
  line-height: 34px;
  padding-left: 15px;
  padding-right: 10px;

  span.checked,
  &:hover span {
    color: #5EAFE7;
  }
  .dark & {
    color: #fff;
  }
  img {
    width: 20px;
    margin-right: 12px;
  }
  &.networkItem {
    justify-content: space-between;
  }
  a {
    color: #000;
    span.checked,
    &:hover {
      color: #5EAFE7;
    }
    .dark & {
      color: #fff;
    }
  }
`
const Img = styled.img`
  width: 13px;
  height: 16px;
  margin-left: 5px;
  vertical-align: bottom;
`
const Faucet = styled.div`
cursor: pointer;
display: flex;
    align-items: center;
    height: 100%;
border-radius: 4px;
  background: rgba(255, 255, 255, 0.08);
  margin-right: 16px;
  padding: 0 16px;

  &.ban {
    background: #eff1f4;
    color: #9fa4b3 !important;
    pointer-events: none;
  }
`
const StyledMenu = styled.div`
  display: flex;
  align-items: center;
  color: #ffffff;
  @media (max-width: 1199px) {
    display: none;
  }
`
const MenuItem = styled.div`
  margin-right: 40px;
  position: relative;
  &:hover {
    /* background-color: red; */
    cursor: pointer;
    &::before {
      content: '';
      position: absolute;
      width: 40px;
      height: 3px;
      flex-shrink: 0;
      background: #FF764B;
      border-radius: 3px 3px 0 0;
      bottom: -24px;
      left: calc(50% - 20px);
    }
  }

  a {
    padding: 20px 0px;
    color: #fff;
  }

  &.active {
    &::before {
      content: '';
      position: absolute;
      width: 40px;
      height: 3px;
      flex-shrink: 0;
      background: #FF764B;
      border-radius: 3px 3px 0 0;
      bottom: -24px;
      left: calc(50% - 20px);
    }
  }
`
const NavList = styled.div`
  display: none;
  position: absolute;
  z-index: 999;
  flex-direction: column;
  top: 44px;
  padding: 8px 24px;
  /* flex-direction: column;
  align-items: flex-start; */
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  background: #000;
`
const MenuItemSpe = styled.div`
  span {
    padding: 30px 0;
    font-weight: 600;
  }
  margin-right: 40px;
  position: relative;
  &:hover {
    /* background-color: red; */
    ${NavList} {
      display: flex;
      min-width: 90px;
    };
    cursor: pointer;
    &::before {
      content: '';
      position: absolute;
      width: 40px;
      height: 3px;
      flex-shrink: 0;
      background: #FF764B;
      border-radius: 3px 3px 0 0;
      bottom: -24px;
      left: calc(50% - 20px);
    }
  }

  a {
    padding: 4px 0px;
    color: #fff;
    line-height: 28px;
    &:hover {
      color: #5EAFE7;
    }
  }

  &.active {
    &::before {
      content: '';
      position: absolute;
      width: 40px;
      height: 3px;
      flex-shrink: 0;
      background: #FF764B;
      border-radius: 3px 3px 0 0;
      bottom: -24px;
      left: calc(50% - 20px);
    }
  }
`
const StyledLogoWrapper = styled.div`
  width: auto;
  margin-right: 72px;
  @media (max-width: 1199px) {
    display: none;
    margin-right: 0px;
  }
`
const StyledTopBar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.white};
  background: #000;
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
`

const StyledTopBarInner = styled.div`
  align-items: center;
  display: flex;
  height: 70px;
  justify-content: space-between;
  width:100vw;
  padding:0 15px;
  box-sizing:border-box;
  @media (max-width: 1199px) {
    width: 100%;
    min-width: 100%;
    height: 42px;
  }
`
const StyledMenuLeft = styled.div`
  display:flex;
  justify-content:flex-start;
  align-items: center;
`
const StyledNavWrapper = styled.div`
  display: flex;
  justify-content: center;
  @media (max-width: 1119px) {
    align-items:center
  }
`;

const StyledAccountButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 1119px) {
    justify-content: center;
    width: auto
  }
`;

const NavLink = styled(MyNavLink)`
  font-weight: 600;
  &.selected {
    color: green;
  }
`
// TopBar

// walletButton
const StyledWalletButton = styled.div`
  height:40px;
  @media (max-width: 1199px) {
    height:30px
  }
`

const WalletSVG = styled.img`
  width:18px;
  height:18px;
  @media (max-width: 767px) {
    /* 解决手机端初始加载图片 尺寸 应用 pc端默认样式 */
    min-width:18px;
    width:18px;
    height:18px;
    margin-right:10px
  }
`
const WalletBox = styled.div`
  width:22px;
  height:22px;
  border-radius:50%;
  /* background-color:#fff; */
  display:flex;
  justify-content: center;
  align-items:center;
  margin-left:-8px;
  margin-right:10px;
  @media (max-width: 767px) {
    width:18px;
    height:18px;
    margin-left:4px;
    background-color:transparent;
  }
`
const ConnectBoxList = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 8px;
`
// walletButton
export default App
