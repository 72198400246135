import * as React from "react"
import { LendingDataV2 } from 'lendingData/gen'
import controller_json from 'lending-sdk/lib/clean_build/contracts/general/Controller.json'
import { useNavigate, useLocation } from "react-router-dom";
import { useIntl } from "react-intl"
import Modal from "components/ModalAction"
import { useWeb3React } from '@web3-react/core'
import { useQuery, setUrlParamWith_utm_source, format_bn__new, bnToDec, formate_round_up, } from "utils"
import numeral from "numeral";
import { ethers, BigNumber as BigNumberE } from "ethers";
import useMarkets from "hooks/useMarkets"
import TotalAssets from "components/TotalAssets"
import _TotalAssets from "components/_TotalAssets"
// import AssetsPortfolioComponent from "./components/AssetsPortfolio"
import Notification from "components/Notification"
import Notification__cancel from "components/Notification__cancel"
import AllMarkets from "./components/AllMarkets"
import styled from "styled-components"
// import { multicall_lsr } from 'lending-sdk/utils/index1'
import { factor } from 'constants/constants.js';
import useLending from "hooks/useLending"
import SupplyList from "./SupplyList"
import BorrowList from "./BorrowList"
import FlexBoxWrap from "components/FlexBoxWrap"
import BigNumber from "bignumber.js"
import close from "assets/stake/closex.svg"
import { sModeConf, ErrorCode, ModeMap, ModeToMap } from "./configs"
import { network_Name, add_Notifies, update_Notifies, del_Notify, del__cancel } from 'lending-sdk/utils/index1'
import general_DL_Token from 'lending-sdk/lib/clean_build/contracts/general/DL_Token.json'
import timelockAddr from 'lending-sdk/lib/clean_build/contracts/general/timelock.json'
import TimeLockABI from 'abis/TimeLock.json'
import ReactTooltip from "react-tooltip"
import img_set from "assets/Bridge/setting.svg"
import setting_light from "assets/Bridge/setting-light.svg"
import tanhao from "assets/Bridge/tanhao.svg"


const Home: React.FC = () => {
  const Lending = useLending()
  const {
    connector,
    account,
    chainId,
    provider,
    ENSName,
    isActive,
    isActivating,
  } = useWeb3React();
  let query = useQuery()
  const navigate = useNavigate()
  const location = useLocation()
  const intl = useIntl()
  const fmt = intl.formatMessage

  const [nowNotifies, setNowNotifies] = React.useState<any>([])
  const [show__cancel, setShow__cancel] = React.useState<boolean>(false)

  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [isModalOpen_disable, setIsModalOpen_disable] = React.useState(false)
  const [isEnabledShow, setIsEnabledShow] = React.useState(false)
  const [isExeing, setIsExeing] = React.useState(false)
  const [claimable_gt_0, setClaimable_gt_0] = React.useState(false)

  const [selectedOption, setSelectedOption] = React.useState('Stablecoins')
  const [toShowModeData, setToShowModeData] = React.useState<any>({})
  const [accountClaimable, setAccountClaimable] = React.useState<any>([])
  const [claimingIds, setClaimingIds] = React.useState<any>([])
  const [isClaimingAll, setIsClaimingAll] = React.useState<any>(false)

  const [marketsData, setMarketsData] = React.useState<any>({})
  const [accountMode, setAccountMode] = React.useState<any>({})
  const [enableSmodeData, setEnableSmodeData] = React.useState<any>([])
  const [disableSmodeData, setDisableSmodeData] = React.useState<any>({})
  const [curITokenMap, setCurITokenMap] = React.useState<any>({})

  // let claimingIds: any = []

  // const AssetsType = query.get('AssetsType')
  const {
    marketsList,
    totalAssets, allAssetsList, allSyntheticList,
    allList_supply, allList_borrow, BLP
  } = useMarkets()
  // console.log(JSON.stringify(totalAssets, null, 2))
  // console.log(allList_supply)
  const totalAssetsTextList = [
    "Total_personal_deposits",
    "Total_personal_loans",
    "Collateral_Ratio",
  ];
  // 如果没有参数 则重定向 默认添加 queryString参数
  // if (!query.get('AssetsType') || !query.get('currentPool')) {
  let currentUrl
  if (query.get('utm_source')) {
    currentUrl = setUrlParamWith_utm_source(location.search, ['utm_source'], [`${query.get('utm_source')}`], '/lending')
    navigate(`${currentUrl}`)
  }

  // }

  const check = async () => {
    console.log(allList_supply)
    console.log(allList_borrow)
  }

  const handleDismiss = () => {
    setIsModalOpen(false)
  }
  const handleDismiss_disable = () => {
    setIsModalOpen_disable(false)
  }

  const handleChange = (event: any) => {
    setSelectedOption(event.target.value);
  }




  const getAccountData = React.useCallback(async () => {
    // console.log("***** get Data *****")
    if (Lending && account && chainId) {
      const provider = new ethers.providers.Web3Provider(Lending.web3.currentProvider)
      const controller_address = controller_json.networks[chainId].Controller.address
      const ld = new LendingDataV2(controller_address, provider)


      const fn_getMarketsData = new Promise((resolve, reject) => {
        const fn = async () => {
          const res = await ld.getMarketsData(account, factor)
          return res
        }
        const res = fn()
        resolve(res)
      })
      const fn_getAccountMode = new Promise((resolve, reject) => {
        const fn = async () => {
          const res = await ld.getAccountMode(account)
          return res
        }
        const res = fn()
        resolve(res)
      })
      // const fn_getEnableSmodeData = new Promise((resolve, reject) => {
      //   const fn = async () => {
      //     const res = await ld.getEnableSmodeData(account)
      //     return res
      //   }
      //   const res = fn()
      //   resolve(res)
      // })
      // const fn_getDisableSmodeData = new Promise((resolve, reject) => {
      //   const fn = async () => {
      //     const res = await ld.getDisableSmodeData(account)
      //     return res
      //   }
      //   const res = fn()
      //   resolve(res)
      // })


      const [
        MarketsData,
        // AccountMode,
        // EnableSmodeData,
        // DisableSmodeData
      ] = await Promise.all([
        fn_getMarketsData,
        // fn_getAccountMode,
        // fn_getEnableSmodeData,
        // fn_getDisableSmodeData
      ])
      // console.log(MarketsData)
      setMarketsData(MarketsData)

      const AccountMode = await ld.getAccountMode(account)
      setAccountMode(AccountMode)

      let EnableSmodeData
      let DisableSmodeData
      if (AccountMode && AccountMode.smode === 0) {
        EnableSmodeData = await ld.getEnableSmodeData(account)
        setEnableSmodeData(EnableSmodeData)
      } else {
        DisableSmodeData = await ld.getDisableSmodeData(account, factor)
        setDisableSmodeData(DisableSmodeData)
      }


      // console.log(MarketsData)
      // console.log(AccountMode)
      // console.log(EnableSmodeData)
      // console.log(DisableSmodeData)
    }
  }, [Lending, chainId])
  React.useEffect(() => {
    getAccountData()
    let refreshInterval = setInterval(getAccountData, 1000 * 10)
    return () => clearInterval(refreshInterval)
  }, [Lending, chainId])


  React.useEffect(() => {
    setIsEnabledShow(false)
    setIsModalOpen(false)
    setIsModalOpen_disable(false)
  }, [chainId, account])



  React.useEffect(() => {
    if (
      selectedOption &&
      enableSmodeData.length > 0
    ) {
      let tmp
      enableSmodeData.map((mode: any) => {
        if (mode.smodeLabel === ModeMap[selectedOption]) {
          tmp = mode
        }
      })
      setToShowModeData(tmp)
    }
  }, [selectedOption, enableSmodeData])


  const renderModeBtn = () => {
    if (!chainId) return
    // if (!(accountMode.smode)) return
    try {
      if (accountMode.smode === 0) {
        return (
          <ModeBtn
            onClick={() => { setIsModalOpen(true) }}
          >
            <span>Enter Supercharged Mode</span>
            <img className="dark" src={img_set} alt="" />
            <img className="light" src={setting_light} alt="" />
            {/* <span>---{accountMode.smode}</span> */}
          </ModeBtn>
        )
      } else {
        if (sModeConf[chainId][accountMode.smode] === 'StableCoins') {
          return (
            <ModeBtn
              onClick={() => { setIsEnabledShow(!isEnabledShow) }}
            >
              <span>Stablecoins</span>
              <img className="dark" src={img_set} alt="" />
              <img className="light" src={setting_light} alt="" />
              {/* <span>---{accountMode.smode}</span> */}
            </ModeBtn>
          )
        } else if (sModeConf[chainId][accountMode.smode] === 'ETH') {
          return (
            <ModeBtn
              onClick={() => { setIsEnabledShow(!isEnabledShow) }}
            >
              <span>ETH derivatives</span>
              <img className="dark" src={img_set} alt="" />
              <img className="light" src={setting_light} alt="" />
              {/* <span>---{accountMode.smode}</span> */}
            </ModeBtn>
          )
        }
      }
    } catch (error) {
      return (
        <ModeBtn >
          <span>Err</span>
        </ModeBtn>
      )
    }
  }
  const handleEnterSMode = (exit?: number) => {
    if (!chainId) return
    if (isExeing) {
      return console.log('isExeing...')
    }
    setIsExeing(true)
    console.log(selectedOption)
    const numMode = sModeConf[chainId][ModeMap[selectedOption]]
    Lending.contracts?.general.Controller.methods.enterSMode(exit === 0 ? 0 : numMode)
      .send({ from: account })
      .on("transactionHash", function (hash: string) {
        console.log("transactionHash: ", hash)
        setIsModalOpen(false)
        setIsExeing(false)
        setIsModalOpen_disable(false)
        add_Notifies({
          state: "pedding",
          action: exit === 0 ? "Exit Supercharged Mode" : "Enter Supercharged Mode",
          transactionHash: hash,
          time: new Date().getTime()
        }, setNowNotifies, account as string, chainId as number)
      })
      .on("receipt", function (receipt: any) {
        console.log("receipt: ", receipt)
        update_Notifies(receipt, setNowNotifies, account as string, chainId as number)
        setTimeout(() => {
          del_Notify(receipt, setNowNotifies)
        }, 3000)
      })
      .on("error", function (error: any) {
        console.log("error", error)
        setIsExeing(false)
        setShow__cancel(true)
        setTimeout(() => {
          setShow__cancel(false)
        }, 3000)
      })
  }


  React.useEffect(() => {
    if (chainId && general_DL_Token.networks[chainId]) {
      const iTokenConf = general_DL_Token.networks[chainId]
      const iTokenMap = {}
      Object.keys(iTokenConf).map((symbol: any) => {
        iTokenMap[iTokenConf[symbol].address] = iTokenConf[symbol].symbol
      })
      // console.log(iTokenMap)
      setCurITokenMap(iTokenMap)
    }
  }, [chainId])
  const iTokenAddressToSymbol = React.useCallback((address: any) => {
    try {
      return (curITokenMap[address])
    } catch (error) {
      return ('')
    }
  }, [curITokenMap])


  const renderImg = (item: any) => {
    let icon
    let symbol
    if (marketsList && marketsList.length > 0) {
      marketsList.map((ItemInfo: any) => {
        if (ItemInfo.underlying === item.asset) {
          icon = ItemInfo.icon
          symbol = ItemInfo.reveal_symbol
        }
      })
    }

    return (
      <>
        <img src={icon} alt="" />
        <span>{symbol}</span>
      </>
    )
  }
  const renderStatus = (item: any) => {
    const timeStramp = Number(item.releaseTime.toString())
    const curTime = Math.ceil(new Date().getTime() / 1000)
    if (curTime > timeStramp) {
      return (
        <>
          <span className="orange">Claimable</span>
          {/* <span>Pending</span>
          <span>{timeStramp}</span>
          <span>{curTime}</span> */}
        </>
      )
    } else {
      return (
        <>
          <span>Pending</span>
          {/* <span>Claimable</span>
          <span>{timeStramp}</span>
          <span>{curTime}</span> */}
        </>
      )
    }
  }
  const renderBTN = (item: any) => {
    const timeStramp = Number(item.releaseTime.toString())
    const curTime = Math.ceil(new Date().getTime() / 1000)
    if (curTime > timeStramp) {
      return (
        <span
          className={
            claimingIds.includes(item.id.toString())
              || isClaimingAll
              ? 'ban' : ''
          }
          onClick={() => { claimSigle(item) }}>
          Claim
        </span>
      )
    } else {
      return (
        <span className="ban">Claim</span>
      )
    }
  }
  const renderAmount = (item: any) => {
    let amount
    let value
    if (marketsList && marketsList.length > 0) {
      marketsList.map((ItemInfo: any) => {
        if (ItemInfo.underlying === item.asset) {
          amount = formate_round_up(
            numeral(bnToDec(new BigNumber(item.tokenAmounts.toString()), ItemInfo.decimals))
              .format("0.000a")
              .toLocaleUpperCase()
          )
          value = formate_round_up(
            numeral(bnToDec(
              new BigNumber(item.tokenAmounts.toString())
                .multipliedBy(new BigNumber(ItemInfo.price))
                .div(new BigNumber(1e18))
              , ItemInfo.decimals
            ))
              .format("0.000a")
              .toLocaleUpperCase()
          )
        }
      })
    }

    return (
      <>
        <span>{amount}</span>
        <span className="value">{'$' + value}</span>
        {/* {' - ' + item.tokenAmounts.toString()}÷ */}
      </>
    )
  }
  const claimSigle = (item: any) => {
    // console.log(item, marketsList)
    let symbol: any
    if (marketsList && marketsList.length > 0) {
      marketsList.map((ItemInfo: any) => {
        if (ItemInfo.underlying === item.asset) {
          symbol = ItemInfo.reveal_symbol
        }
      })
    }

    // let tmp: any = window.localStorage.getItem('localClaimingIds')
    // let localClaimingIds
    // if (tmp) {
    //   localClaimingIds = JSON.parse(tmp)
    // } else {
    //   localClaimingIds = []
    // }
    // console.log(localClaimingIds)
    // console.log(claimingId)

    const claimingId = item.id.toString()
    if (claimingIds.includes(claimingId)) {
      return console.log('claiming')
    }
    setClaimingIds([...claimingIds, claimingId])
    // const tmpArr = localClaimingIds.push(claimingId)
    // console.log(tmpArr)
    // window.localStorage.setItem('localClaimingIds', JSON.stringify(tmpArr))
    // setTimeout(() => { setClaimingIds([...claimingIds, claimingId]) }, 0)
    // // claimingIds = [...claimingIds, claimingId]
    // console.log(localClaimingIds)

    // const userClaimableIds = [BigNumberE.from(item.id.toString())]
    // console.log(userClaimableIds)
    const timeLock = new Lending.web3.eth.Contract(TimeLockABI, timelockAddr[chainId as number].address)
    const userClaimableIds = [Number(item.id.toString())]
    timeLock.methods.claim(userClaimableIds)
      .send({ from: account })
      .on("transactionHash", function (hash: string) {
        console.log("transactionHash: ", hash)
        add_Notifies({
          state: "pedding",
          action: 'Claim ' + symbol,
          transactionHash: hash,
          time: new Date().getTime()
        }, setNowNotifies, account as string, chainId as number)
      })
      .on("receipt", function (receipt: any) {
        console.log("receipt: ", receipt)
        update_Notifies(receipt, setNowNotifies, account as string, chainId as number)
        setTimeout(() => {
          del_Notify(receipt, setNowNotifies)
        }, 3000)
      })
      .on("error", function (error: any) {
        console.log("error", error)
        // let tmp: any = window.localStorage.getItem('localClaimingIds') ?? '[]'
        // let localClaimingIds: any = JSON.parse(tmp)
        // const array = localClaimingIds;
        // const indexToRemove = array.indexOf(claimingId);
        // array.splice(indexToRemove, 1);
        setClaimingIds([])
        // console.log(claimingId, claimingIds);
        // console.log(array);
        // claimingIds = array
        // setClaimingIds(array)
        // setClaimingIds(array)
        // window.localStorage.setItem('localClaimingIds', JSON.stringify(array))

        setShow__cancel(true)
        setTimeout(() => {
          setShow__cancel(false)
        }, 3000)
      })
  }
  const claimAll = () => {
    if (accountClaimable && accountClaimable.length === 0) { return console.log('accountClaimable 0') }
    // console.log(claimingIds)
    // console.log(accountClaimable)
    if (isClaimingAll) {
      return console.log('claiming')
    }
    setIsClaimingAll(true)

    let tmpArray: any = []
    if (claimingIds && claimingIds.length > 0) {
      accountClaimable.map((id: any) => {
        if (!claimingIds.includes(id.id.toString())) {
          const timeStramp = Number(id.releaseTime.toString())
          const curTime = Math.ceil(new Date().getTime() / 1000)
          if (curTime > timeStramp) {
            tmpArray.push(id.id.toString())
          }
        }
      })
    }
    if (claimingIds && claimingIds.length === 0) {
      accountClaimable.map((id: any) => {
        const timeStramp = Number(id.releaseTime.toString())
        const curTime = Math.ceil(new Date().getTime() / 1000)
        if (curTime > timeStramp) {
          tmpArray.push(id.id.toString())
        }
      })
    }
    // console.log(tmpArray)
    // return
    const timeLock = new Lending.web3.eth.Contract(TimeLockABI, timelockAddr[chainId as number].address)
    const userClaimableIds = tmpArray
    timeLock.methods.claim(userClaimableIds)
      .send({ from: account })
      .on("transactionHash", function (hash: string) {
        console.log("transactionHash: ", hash)
        add_Notifies({
          state: "pedding",
          action: 'Claim All',
          transactionHash: hash,
          time: new Date().getTime()
        }, setNowNotifies, account as string, chainId as number)
      })
      .on("receipt", function (receipt: any) {
        console.log("receipt: ", receipt)
        update_Notifies(receipt, setNowNotifies, account as string, chainId as number)
        setTimeout(() => {
          del_Notify(receipt, setNowNotifies)
        }, 3000)
      })
      .on("error", function (error: any) {
        console.log("error", error)
        setIsClaimingAll(false)
        setShow__cancel(true)
        setTimeout(() => {
          setShow__cancel(false)
        }, 3000)
      })
  }


  React.useEffect(() => {
    const getFn = async () => {
      if (Lending && chainId && account) {
        const provider = new ethers.providers.Web3Provider(Lending.web3.currentProvider)
        const controller_address = controller_json.networks[chainId].Controller.address
        const ld = new LendingDataV2(controller_address, provider)
        const accountClaimable = await ld.getAccountClaimableAgreements(account)
        // setDelayStrategyConfig(delayStrategyConfig)
        // console.log(accountClaimable) // accountClaimable
        setAccountClaimable(accountClaimable)
      }
    }

    getFn()
    let t = setInterval(getFn, 1000 * 10);
    return () => clearInterval(t);
  }, [Lending, chainId, account])

  React.useEffect(() => {
    if (accountClaimable && accountClaimable.length > 0) {
      // console.log(accountClaimable)
      let tmpBool = false
      accountClaimable.map((item: any) => {
        const timeStramp = Number(item.releaseTime.toString())
        const curTime = Math.ceil(new Date().getTime() / 1000)
        if (curTime > timeStramp) {
          // setClaimable_gt_0(true)
          tmpBool = true
        }
      })
      setClaimable_gt_0(tmpBool)
    }
  }, [accountClaimable])



  return (
    <>
      <Notification
        chainID={chainId}
        notifies={nowNotifies}
        setNowNotifies={setNowNotifies}
        del_Notify={del_Notify}
      />
      <Notification__cancel
        notifies={show__cancel}
        setShow__cancel={setShow__cancel}
        del_Notify={del__cancel}
      />


      <FlexBoxWrap>
        <TotalAssets
          totalAssets={totalAssets && totalAssets?.totalValue}
          totalAssetsTextList={totalAssetsTextList}
          BLP={BLP}
        />

        {/* <AssetsPortfolioComponent allAssetsList={AssetsType === 'Lend' ? allAssetsList : allSyntheticList} /> */}

        <AccountSupplyBorrow>
          <AssetListWrap>
            <TypeTitle>{fmt({ id: "Supplies" })}</TypeTitle>
            {
              allList_supply && allList_supply.length > 0
                ? <ListWrap>
                  <SupplyList supplyMarkets={allList_supply} />
                </ListWrap>
                : <NothingWrap>Nothing supplied yet</NothingWrap>
            }
          </AssetListWrap>

          <GAP />

          <AssetListWrap>
            <TypeTitle>
              <>
                {fmt({ id: "Borrows" })}
                <StyledSpan>
                  {renderModeBtn()}
                  {
                    isEnabledShow &&
                    <EnabledMode
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                      }}
                    >
                      <EnabledTitle>Supercharged Mode</EnabledTitle>
                      <Enabledcategory>Asset category</Enabledcategory>
                      <EnabledStatus>
                        <StatusL>{ModeToMap[accountMode?.smodeLabel]}</StatusL>
                        <StatusR>Enabled</StatusR>
                      </EnabledStatus>
                      <EnabledStatusDetials>
                        Supercharged Mode empowers your assets LTV up to 95%.
                      </EnabledStatusDetials>
                      <Btn
                        // className={!true ? 'disabled' : ''}
                        onClick={() => {
                          setIsEnabledShow(!isEnabledShow)
                          setIsModalOpen_disable(true)
                        }}>
                        {'Exit Supercharged Mode'}
                      </Btn>
                    </EnabledMode>
                  }
                </StyledSpan>
              </>
            </TypeTitle>
            {
              allList_borrow && allList_borrow.length > 0
                ? <ListWrap>
                  <BorrowList supplyMarkets={allList_borrow} />
                </ListWrap>
                : <NothingWrap>Nothing borrowed yet</NothingWrap>
            }
          </AssetListWrap>
        </AccountSupplyBorrow>


        {/* <button onClick={() => { check() }}>kkkkkkkkkk</button> */}
        {
          marketsList && marketsList.length > 0 &&
          accountClaimable &&
          accountClaimable.length > 0 &&
          <ClaimList>
            <ClaimTop>
              <TopLeft>
                Delay assets
              </TopLeft>
              <TopRight
                onClick={() => { claimAll() }}
                className={isClaimingAll || !claimable_gt_0 ? 'ban' : ''}>
                Claim All
              </TopRight>
            </ClaimTop>

            <ClaimTitle>
              <ClaimTitleSigle className="one">Asset</ClaimTitleSigle>
              <ClaimTitleSigle className="two">Amount</ClaimTitleSigle>
              <ClaimTitleSigle className="three">Status</ClaimTitleSigle>
              <ClaimTitleSigle className="four"></ClaimTitleSigle>
            </ClaimTitle>

            <ClaimBody>
              {
                accountClaimable.map((item: any, index: number) => {
                  return (
                    <ClaimItem key={index}>
                      <Item1>
                        {renderImg(item)}
                      </Item1>
                      <Item2>
                        {renderAmount(item)}
                      </Item2>
                      <Item3>
                        {renderStatus(item)}
                      </Item3>
                      <Item4>
                        {/* {' - ' + item.id.toString()} */}
                        {renderBTN(item)}
                      </Item4>
                    </ClaimItem>
                  )
                })
              }
            </ClaimBody>
          </ClaimList>
        }


        <AllMarkets
          supplyMarkets={marketsList}
          marketsData={marketsData}
          accountSupplyList={allList_supply}
        />
      </FlexBoxWrap>



      {/* enable */}
      <Modal isOpen={isModalOpen}>
        <StyledModalTitle>
          {'Enter Supercharged Mode'}
          <StyledClosed>
            <CloseModal src={close} onClick={handleDismiss} />
          </StyledClosed>
        </StyledModalTitle>

        <PaddingWrap>
          <ModuleTitle>Asset category</ModuleTitle>
          <OperateSec className="Nopadding">
            <select value={selectedOption} onChange={(e) => { handleChange(e) }}>
              {
                enableSmodeData && enableSmodeData.length > 0 &&
                enableSmodeData.map((mode: any) => {
                  return (
                    <option
                      key={mode.smodeID}
                      value={ModeToMap[mode.smodeLabel]}
                    >
                      {ModeToMap[mode.smodeLabel]}
                    </option>
                  )
                })
              }
            </select>
          </OperateSec>

          <ModuleTitle>Transaction Overview</ModuleTitle>
          <OperateSec>
            <SecTop>
              <ChangeItem>
                <ChangeLeft>Allowed to Borrow</ChangeLeft>
                <ChangeRight>
                  {
                    toShowModeData &&
                    toShowModeData.assets &&
                    toShowModeData.assets.to &&
                    toShowModeData.assets.to.map((iTokenAddress: any, idx: number) => {
                      return (
                        <>
                          {idx !== 0 && idx < toShowModeData.assets.to.length ? ',' : ''}
                          {iTokenAddressToSymbol(iTokenAddress)}
                        </>
                      )
                    })
                  }
                </ChangeRight>
              </ChangeItem>
              {
                toShowModeData &&
                toShowModeData.ltv &&
                toShowModeData.ltv.map((ltvItem: any) => {
                  return (
                    <ChangeItem>
                      <ChangeLeft>{iTokenAddressToSymbol(ltvItem.asset)} {' LTV'}</ChangeLeft>
                      <ChangeRight>
                        <RedColor>{format_bn__new(ltvItem.from.toString(), 16, 0)}%</RedColor>
                        <StyledArrow>{'→'}</StyledArrow>
                        <RedColor>{format_bn__new(ltvItem.to.toString(), 16, 0)}%</RedColor>
                      </ChangeRight>
                    </ChangeItem>
                  )
                })
              }
              {
                toShowModeData &&
                toShowModeData.adequacyRatio &&
                <ChangeItem>
                  <ChangeLeft>{'Adequacy Radio'}</ChangeLeft>
                  <ChangeRight>
                    {
                      new BigNumber(toShowModeData.adequacyRatio.from.toString())
                        .eq(new BigNumber(2).pow(new BigNumber(256)).minus(new BigNumber(1)))
                        ? '...'
                        : new BigNumber(toShowModeData.adequacyRatio.from.toString()).gt(new BigNumber(1e22))
                          ? <RedColor>{'＞10,000'}</RedColor>
                          : <RedColor>
                            {format_bn__new(toShowModeData.adequacyRatio.from.toString(), 18, 2)}
                          </RedColor>
                    }
                    <StyledArrow>{'→'}</StyledArrow>
                    {
                      new BigNumber(toShowModeData.adequacyRatio.to.toString())
                        .eq(new BigNumber(2).pow(new BigNumber(256)).minus(new BigNumber(1)))
                        ? '...'
                        : new BigNumber(toShowModeData.adequacyRatio.to.toString()).gt(new BigNumber(1e22))
                          ? <RedColor>{'＞10,000'}</RedColor>
                          : <RedColor>
                            {format_bn__new(toShowModeData.adequacyRatio.to.toString(), 18, 2)}
                          </RedColor>
                    }
                  </ChangeRight>
                </ChangeItem>
              }
              {
                toShowModeData &&
                toShowModeData.borrowingPower &&
                <ChangeItem>
                  <ChangeLeft>{'Borrow Power'}</ChangeLeft>
                  <ChangeRight>
                    <RedColor>
                      {'$' + format_bn__new(toShowModeData.borrowingPower.from.toString(), 18, 2)}
                    </RedColor>
                    <StyledArrow>{'→'}</StyledArrow>
                    <RedColor>
                      {'$' + format_bn__new(toShowModeData.borrowingPower.to.toString(), 18, 2)}
                    </RedColor>
                  </ChangeRight>
                </ChangeItem>
              }
            </SecTop>
          </OperateSec>

          <OperateSec className="mt16">
            <TipsText>
              <LeftIcon>
                <img src={tanhao} alt="" />
              </LeftIcon>
              <RightText>
                Entering Supercharged Mode allows you to have higher borrowing power and borrow assets within specific category. Please visit our FAQ guide to learn more about how it works and the applied restrictions.
              </RightText>
            </TipsText>
          </OperateSec>

          {
            toShowModeData.canExecute
              ? <Btn onClick={() => { handleEnterSMode() }}>
                {'Enter Supercharged Mode'}
              </Btn>
              : <>
                <Btn
                  className={'disabledTips'}
                  data-tip
                  data-for={"tips00111"}
                >
                  {'Enter Supercharged Mode'}
                </Btn>
                <ReactTooltip
                  id={"tips00111"}
                  place="top"
                  type="light"
                  effect="float"
                  className="tooltip"
                  textColor={`${(props: any) => props.theme.operationPage.grey}`}
                  borderColor={"#E8ECEF"}
                  border={true}
                >
                  <TooltipSpan>
                    {ErrorCode?.[toShowModeData?.reason]}
                  </TooltipSpan>
                </ReactTooltip>
              </>
          }
        </PaddingWrap>
      </Modal>


      {/* dis-able */}
      <Modal isOpen={isModalOpen_disable}>
        <StyledModalTitle>
          {'Exit Supercharged Mode'}
          <StyledClosed>
            <CloseModal src={close} onClick={handleDismiss_disable} />
          </StyledClosed>
        </StyledModalTitle>

        <PaddingWrap>
          <ModuleTitle>Transaction Overview</ModuleTitle>
          <OperateSec>
            <SecTop>
              <ChangeItem>
                <ChangeLeft>Supercharged Mode Category</ChangeLeft>
                <ChangeRight>
                  {disableSmodeData.smodeLabel}
                  <StyledArrow>{'→'}</StyledArrow>
                  {'None'}
                </ChangeRight>
              </ChangeItem>
              <ChangeItem>
                <ChangeLeft>Allowed to Borrow</ChangeLeft>
                <ChangeRight>
                  {'All Assets'}
                </ChangeRight>
              </ChangeItem>
              {
                disableSmodeData &&
                disableSmodeData.ltv &&
                disableSmodeData.ltv.map((ltvItem: any) => {
                  return (
                    <ChangeItem>
                      <ChangeLeft>{iTokenAddressToSymbol(ltvItem.asset)} {' LTV'}</ChangeLeft>
                      <ChangeRight>
                        <RedColor>{format_bn__new(ltvItem.from.toString(), 16, 0)}%</RedColor>
                        <StyledArrow>{'→'}</StyledArrow>
                        <RedColor>{format_bn__new(ltvItem.to.toString(), 16, 0)}%</RedColor>
                      </ChangeRight>
                    </ChangeItem>
                  )
                })
              }
              {
                disableSmodeData &&
                disableSmodeData.adequacyRatio &&
                <ChangeItem>
                  <ChangeLeft>{'Adequacy Radio'}</ChangeLeft>
                  <ChangeRight>
                    {
                      new BigNumber(disableSmodeData.adequacyRatio.from.toString())
                        .eq(new BigNumber(2).pow(new BigNumber(256)).minus(new BigNumber(1)))
                        ? '...'
                        : new BigNumber(disableSmodeData.adequacyRatio.from.toString()).gt(new BigNumber(1e22))
                          ? <RedColor>{'＞10,000'}</RedColor>
                          : <RedColor>
                            {format_bn__new(disableSmodeData.adequacyRatio.from.toString(), 18, 2)}
                          </RedColor>
                    }
                    <StyledArrow>{'→'}</StyledArrow>
                    {
                      new BigNumber(disableSmodeData.adequacyRatio.to.toString())
                        .eq(new BigNumber(2).pow(new BigNumber(256)).minus(new BigNumber(1)))
                        ? '...'
                        : new BigNumber(disableSmodeData.adequacyRatio.to.toString()).gt(new BigNumber(1e22))
                          ? <RedColor>{'＞10,000'}</RedColor>
                          : <RedColor>
                            {format_bn__new(disableSmodeData.adequacyRatio.to.toString(), 18, 2)}
                          </RedColor>
                    }
                  </ChangeRight>
                </ChangeItem>
              }
              {
                disableSmodeData &&
                disableSmodeData.borrowingPower &&
                <ChangeItem>
                  <ChangeLeft>{'Borrow Power'}</ChangeLeft>
                  <ChangeRight>
                    <RedColor>
                      {'$' + format_bn__new(disableSmodeData.borrowingPower.from.toString(), 18, 2)}
                    </RedColor>
                    <StyledArrow>{'→'}</StyledArrow>
                    <RedColor>
                      {'$' + format_bn__new(disableSmodeData.borrowingPower.to.toString(), 18, 2)}
                    </RedColor>
                  </ChangeRight>
                </ChangeItem>
              }
            </SecTop>
          </OperateSec>

          {
            disableSmodeData.canExecute
              ? <Btn onClick={() => { handleEnterSMode(0) }}>
                {'Exit Supercharged Mode'}
              </Btn>
              : <>
                <Btn
                  className={'disabledTips'}
                  data-tip
                  data-for={"tips001112"}
                >
                  {'Exit Supercharged Mode'}
                </Btn>
                <ReactTooltip
                  id={"tips001112"}
                  place="top"
                  type="light"
                  effect="float"
                  className="tooltip"
                  textColor={`${(props: any) => props.theme.operationPage.grey}`}
                  borderColor={"#E8ECEF"}
                  border={true}
                >
                  <TooltipSpan>
                    {/* {'err code: '} */}
                    {/* {disableSmodeData.reason} */}
                    {'Exiting Supercharged Mode will render your position undercollateralized.'}
                  </TooltipSpan>
                </ReactTooltip>
              </>
          }
        </PaddingWrap>
      </Modal>
    </>
  );
}

export default Home



const ClaimList = styled.div`
  background: #fff;
  border-radius: 16px;
  overflow: auto !important;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 20px;
  .dark & {
    background: rgb(19, 19, 19);
  }
`
const ClaimTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const TopLeft = styled.div`
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 60px;
`
const TopRight = styled.div`
  width: 89px;
  height: 30px;
  border-radius: 4px;
  background: rgba(232, 244, 237, 1);
  color: #00BC8F;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  font-weight: 700;
  &.ban {
    background: rgb(239, 241, 244);
    pointer-events: none;
    color: rgb(159, 164, 179) !important;
  }
`
const ClaimTitle = styled.div`
  display: flex;
  margin-bottom: 16px;
`
const ClaimTitleSigle = styled.div`
  text-align: right;
  color: rgb(145, 149, 164);
  font-size: 14px;
  font-weight: 600;
  &.one {
    flex: 1;
    text-align: left !important;
  }
  &.two {
    flex: 1;
  }
  &.three {
    flex: 2;
  }
  &.four {
    flex: 2;
  }
`
const ClaimBody = styled.div`
  
`
const ClaimItem = styled.div`
  display: flex;
  text-align: right;
  font-size: 14px;
  font-weight: 700;
  height: 82px;
  align-items: center;
  border-top: 1px solid rgba(1, 1, 1, 0.08);
  .dark & {
    border-top: 1px solid rgba(255, 255, 255, 0.08);
  }
`
const Item1 = styled.div`
  flex: 1;
  text-align: left !important;
  img {
    width: 32px;
    height: 32px;
    margin-right: 10px;
  }
`
const Item2 = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  line-height: 24px;
  .value {
    color: rgb(153, 153, 153);
  }
`
const Item3 = styled.div`
  flex: 2;
  .orange {
    color: #FF764B;
  }
`
const Item4 = styled.div`
  flex: 2;
  display: flex;
  flex-direction: row-reverse;
  span {
    width: 68px;
    height: 30px;
    border-radius: 4px;
    background: rgba(232, 244, 237, 1);
    color: #00BC8F;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
    &.ban {
      background: rgb(239, 241, 244);
      pointer-events: none;
      color: rgb(159, 164, 179) !important;
    }
  }
`


const StyledArrow = styled.span`
  margin-left: 8px;
  margin-right: 8px;
`
const TooltipSpan = styled.span` 
  color: rgb(145, 149, 164);
`
const RedColor = styled.div` 
  color: rgba(0, 187, 82, 1);
`
const EnabledStatusDetials = styled.div` 
  font-size: 12px;
  line-height: 24px;
  margin-top: 16px;
  margin-bottom: 16px;
  color: #999;
`
const StyledSpan = styled.span` 
  position: relative;
  display: flex;
  align-items: center;
`
const EnabledStatus = styled.div` 
  background: #F5F6F9;
  color: rgba(153, 153, 153, 1);
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  padding: 0 8px;
  .dark & {
    background-color: #131313;
  }
`
const StatusL = styled.div` 
  color: rgba(153, 153, 153, 1);
  font-size: 16px;
  `
const StatusR = styled.div` 
  color: rgba(0, 187, 82, 1);
  font-size: 16px;
  position: relative;
  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: rgba(0, 187, 82, 1);
    left: -16px;
    top: 25px;
  }
`
const Enabledcategory = styled.div`
  color: rgba(153, 153, 153, 1);
  font-size: 14px;
  line-height: normal;
  margin-top: 16px;
  margin-bottom: 8px;
`
const EnabledTitle = styled.div`
  color: #131313;
  font-size: 16px;
  line-height: normal;
  .dark & {
    color: #fff;
  }
`
const EnabledMode = styled.div`
  padding: 16px;
  position: absolute;
  left: 40px;
  top: 40px;
  background-color: #fff;
  /* box-shadow:0px 16px 32px 0px rgb(255 255 255 / 0.08); */
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.2);
  width: 340px;
  z-index: 99;
  border-radius: 8px;
  .dark & {
    background:rgba(0, 0, 0, 1);
    box-shadow: 1px 1px 1px -24px ${props => props.theme.colors.darkGrey};
    border: 1px solid rgba(255, 255, 255, 0.16);
  }
`
const ModuleTitle = styled.div`
  color: rgba(153, 153, 153, 1);
  font-size: 16px;
  margin-top: 16px;
  margin-bottom: 8px;
`
const TipsText = styled.div`
  color: rgba(255, 167, 38, 1);
  font-size: 12px;
  display: flex;
  gap: 10px;
`
const LeftIcon = styled.div`
  display: flex;
  /* margin-right: 20px; */
  width: 40px;
  /* background-color: red; */
  img {
    width: 18px;
  }
`
const RightText = styled.div`
  display: flex;
`
const ChangeItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  font-size: 14px;
`
const ChangeLeft = styled.div`
  display: flex;
`
const ChangeRight = styled.div`
  display: flex;
  font-weight:600;
  .dark & {
    color: #fff;
  }
`
const Btn = styled.div`
  width: 100%;
  height: 50px;
  border-radius: 8px;
  background: rgba(0, 188, 143, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  color: ${(props) => props.theme.colors.white};
  cursor: pointer;
  margin-top: 16px;
  font-size: 16px;
  &.disabled {
    pointer-events:none;
    background: ${(props) => props.theme.colors.lightTableBorder};
    color: ${(props) => props.theme.colors.disabledBtn};
  }
  &.disabledTips {
    /* background: ${(props) => props.theme.colors.lightTableBorder}; */
    background: #dcdcdc;
    /* background: ${(props) => props.theme.colors.lightTableBorder}; */
    color: ${(props) => props.theme.colors.disabledBtn};
    /* color: red; */
  }
  @media(max-width: 1199px) {
    height: 40px;
    line-height:40px;
  }
`;
const SecTop = styled.div`
  color: #999;
`;
const TopText = styled.div`
  &.speHover:hover {
    cursor: pointer;
    opacity: 0.8;
    transform: scale(1.05);
  }
`;
const PaddingWrap = styled.div`
  width: 100%;
  padding: 24px;
`;
const OperateSec = styled.div`
  width: 100%;
  padding: 8px 8px;
  border-radius: 8px;
  background: #F5F6F9;
  &.mt16 {
    margin-top: 16px;
  }
  &.Nopadding {
    height: 44px;
    padding: 0 8px;
  }
  select {
    border: none;
    width: 100%;
    height: 100%;
    background: transparent;
  }
  .dark & {
    /* background: rgba(153, 153, 153, 1); */
    background: rgba(19, 19, 19, 1);
  }
`;
const CloseModal = styled.img`
  width: 24px;
  height: 24px;
`
const StyledModalTitle = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  padding-top: 24px;
  margin-bottom: 16px;
  .dark & {
    color: #fff;
  }
`
const StyledClosed = styled.div`
  position: absolute;
  right: 20px;
  top: 22px;
`
const ModeBtn = styled.div`
  border-radius: 4px;
  padding: 0 12px;
  background: #13131308;
  height: 34px;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  margin-left: 40px;
  /* color: #444; */
  color: rgb(145, 149, 164);
  .dark & {
    background: rgba(255, 255, 255, 0.08);
    color: #fff;
  }
  img {
    width: 16px;
    margin-left: 8px;
    &.light {
      display: block;
    }
    &.dark {
      display: none;
    }
    .dark & {
      &.light {
        display: none;
      }
      &.dark {
        display: block;
      }
    }
  }
  span {
    font-size:14px;
    font-weight:600;
  }
`
const Mode = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 40px;
  margin-right: 8px;
`

const NothingWrap = styled.div`
  height: 78px;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.global_grey};
  /* background: red; */
  padding-bottom: 24px;
  font-size: 14px;
  font-weight: 500;
`
const AccountSupplyBorrow = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  /* background: red; */
  @media (max-width: 1199px) {
    flex-direction: column;
  }
`
const AssetListWrap = styled.div`
  flex: 1;
  background: #fff;
  border-radius: 12px;
  /* overflow: hidden; */
  padding: 0px 24px 0;
  .dark & {
    background: #131313;
  }
  @media (max-width: 1199px) {
    width: 100%;
    border-radius: 10px;
    padding: 0px 12px 0;
  }
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.15);
`
const GAP = styled.div`
  width: 16px;
  /* background: blue; */
  @media (max-width: 1199px) {
    width: 0;
    height: 10px;
  }
`
const TypeTitle = styled.div`
  color: #131313;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 60px;
  display: flex;
  align-items: center;
  .dark & {
    color: #FFF;
  }
`
const ListWrap = styled.div`
`