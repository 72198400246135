import React from 'react'
import styled from 'styled-components'

interface BorderWrapProps {
  width?:string,
  borderRadius?:string,
  children:React.ReactNode
}

const BorderWrap: React.FC<BorderWrapProps> = ({ children,width ,borderRadius}) => (
  <StyledBorderWrap width={width} borderRadius={borderRadius}>
    {children}
  </StyledBorderWrap>
)
interface StyledBorderWrapProps {
  width?:string,
  borderRadius?:string
}
const StyledBorderWrap = styled.div<StyledBorderWrapProps>`
  width: ${(props) => props.width ? props.width : '100%'};
  display: flex; 
  border-radius: 8px;
  padding: 24px;
  background: ${(props) => props.theme.operationPage.light.sectionBG};
  margin:0 auto;
  .dark & {
    background: ${(props) => props.theme.box_bg_night};
  }

  @media (max-width: 1119px) {
    width: 100%;
    border: none;
    display: block; 
    padding: 14px 10px;
  }
`

export default BorderWrap