/* eslint no-mixed-operators: 0 */
/* eslint no-param-reassign: 0 */
import {network_Name} from 'lending-sdk/utils/index1'
// import { requestAPI } from "utils";

  // https://api.dforce.network/lendservice/chart?network=kovan&type=supply&asset=0x7161dF9319A1Af07F9921B438D333768d4Fe8544
  export const fetchAPY = async (chainId,address,type) =>{
    // const data = await requestAPI(`https://beta.unitus.finance/general/chart?network=${network_Name[chainId]}&asset=${address}&type=${type}`)
    // return data
    try {
      const response = await fetch(`https://beta.unitus.finance/general/chart?network=${network_Name[chainId]}&asset=${address}&type=${type}`)
      const res = await response.json();
      // console.log(chainId,address,type)
      // console.log(res)
      return res
    } catch (error) {
      console.log(error)
      return null
    }
  }