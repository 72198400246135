import React, { useCallback, useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { useWeb3React } from '@web3-react/core'
import moment from "moment";
import useLending from "hooks/useLending";
import { useQuery } from 'utils';
import liquidity_LP_Token from "lending-sdk/lib/clean_build/contracts/Liquidity/IERC20.json"
import {
  _getEarned, _getstakedBalance, getAccountTotalValue, multicall_lsr, multicall_target
} from "lending-sdk/utils/index1"
import { fetchLiquidity } from "./fetchLiquidity"
import MarketDistribution from './components/MarketDistribution';
import BigNumber from 'bignumber.js';

// 获取本地token配置
const deployAssets = {
  liquidity: liquidity_LP_Token,
}
const Liquidity: React.FC = () => {
  const {
    connector,
    account,
    chainId,
    provider,
    ENSName,
    isActive,
    isActivating,
  } = useWeb3React();
  const lending = useLending();
  const [liquidity, setLiquidity] = useState<any[]>([])
  const navigate = useNavigate()
  const [localConfig, setLocalConfig] = useState<any[]>([])
  const [_threshold,set_Threshold] = useState<string | undefined>(undefined)
  let totalValue:any;
  const fetchApi = useCallback(async (_chainId:number,_account:string) => {
    const {
      liquidityDate,
      localConfig_alltoken,
      threshold,
      earned,
      rewardData,
      earnedArb,
      rewardDataArb
    } = await fetchLiquidity(_chainId,_account)
    
    set_Threshold(threshold)
    setLocalConfig(localConfig_alltoken)
    // console.log(earned,rewardData)
    if (lending?.contracts && account && chainId) {
      try {
        totalValue = await getAccountTotalValue(lending.web3.currentProvider, chainId, account);
      } catch (error) {
        console.log(error)
      }
      const updateLiquidityDate = await Promise.all(
        liquidityDate && liquidityDate.map(async (item: any) => {
          let underlying_address,balance,allowance,harvestStatus,Locked,AllTotalSupply= '...',TotalStaked = '...',TotalSupply,LPShare = '...',TotalStakedValue='...',MyStaked='...',MystakedValue='...',_userAPR:string = '...',_userAPRArb:string| undefined = undefined ;
          switch (item.poolType) {
            case 'single':
              // MyStaked = await _getstakedBalance(lending.contracts['liquidity'][`r${item.reveal_symbol}`], account)
              // balance = await _getstakedBalance(lending.contracts['liquidity'][`${item.reveal_symbol}`], account)
              // try {
              //   debugger
              //   balance = await lending.contracts['liquidity']['VaultData']?.methods.getAvailableBalance(item.LPAddress,account,'920000000000000000').call()
              // } catch (error) {
              //   balance = '0'
              // }
              // allowance = lending.contracts['liquidity'][`${item.reveal_symbol}`] && await lending.contracts['liquidity'][`${item.reveal_symbol}`]?.methods
              // .allowance(account, item.LPAddress)
              // .call();
              try {
                const multical_params = [
                  {
                    target:lending.contracts['liquidity'][`r${item.reveal_symbol}`]?.address,
                    call:['balanceOf(address)(uint256)',account],
                    key:'MyStaked'
                  },
                  // {
                  //   target:lending.contracts['liquidity'][`${item.reveal_symbol}`]?.address,
                  //   call:['balanceOf(address)(uint256)',account],
                  //   key:'balance'
                  // },
                  {
                    target:lending.contracts['liquidity']['VaultData']?.address,
                    call:['getAvailableBalance(address,address,uint256)(uint256)',item.LPAddress,account,'920000000000000000'],
                    key:'balance'
                  },
                  {
                    target:lending.contracts['liquidity'][`${item.reveal_symbol}`]?.address,
                    call:['allowance(address,address)(uint256)',account,item.address],
                    key:'allowance'
                  },
                  {
                    target:lending.contracts['liquidity'][`r${item.reveal_symbol}`]?.address,
                    call:['totalSupply()(uint256)'],
                    key:'TotalStaked'
                  },
                  {
                    target:lending.contracts['liquidity'][`${item.reveal_symbol}`]?.address,
                    call:['totalSupply()(uint256)'],
                    key:'TotalSupply'
                  }
                ]
                const res = await multicall_lsr(lending?.contracts?.web3,multicall_target[chainId], multical_params)
                if(!res) return;
                MyStaked = res.results.transformed.MyStaked;
                balance = res.results.transformed.balance;
                allowance = res.results.transformed.allowance;
                TotalStaked = res.results.transformed.TotalStaked;
                TotalSupply = res.results.transformed.TotalSupply;
                LPShare = new BigNumber(res.results.transformed.TotalStaked).multipliedBy(new BigNumber(10).pow(18)).div(new BigNumber(res.results.transformed.TotalSupply)).toFixed(0);
                TotalStakedValue = new BigNumber(res.results.transformed.TotalStaked).multipliedBy(item.Price).div(new BigNumber(10).pow(18)).toFixed(0).toString();
                MystakedValue = new BigNumber(res.results.transformed.MyStaked).multipliedBy(item.Price).div(new BigNumber(10).pow(18)).toFixed(0).toString();
                underlying_address = lending.contracts['liquidity'][`${item.reveal_symbol}`]?.address;
                // 计算user UTS APR
                // 门槛计算  userTotalSupply * threshold <=  weightingValue
                const isWeightingToken = earned?.rewardInfo?.[item.address]
                if(!isWeightingToken  || new BigNumber(earned?.BLPStakedValue).lt(new BigNumber(totalValue[0]).plus(new BigNumber(MystakedValue)).multipliedBy(new BigNumber(rewardData?.threshold)).dividedBy(new BigNumber(10).pow(18)).toFixed(0)) || new BigNumber(MystakedValue).eq(0) || new BigNumber(earned?.rewardInfo?.[item.address]?.["supplyRewardRate"]).eq(0) || new BigNumber(earned?.rewardInfo?.[item.address]?.["weightingSupplyValue"]).eq(0)){
                // if( new BigNumber(!isWeightingToken || earned?.BLPStakedValue).eq('0')){
                  _userAPR = '0'
                }else{
                  // _userAPR
                  let userWeightingValue;
                  if(new BigNumber(earned?.weightingValue).gte(new BigNumber(MystakedValue).multipliedBy(new BigNumber(rewardData?.magnification)).dividedBy(new BigNumber(10).pow(18)).toFixed(0))){
                    userWeightingValue = new BigNumber(MystakedValue).plus(new BigNumber(MystakedValue).multipliedBy(new BigNumber(rewardData?.magnification).dividedBy(new BigNumber(10).pow(18)))).dividedBy(new BigNumber(10).pow(item.decimals)).multipliedBy(new BigNumber(10).pow(18)).toString()
                  }else{
                    userWeightingValue = new BigNumber(MystakedValue).multipliedBy(new BigNumber(10).pow(18)).dividedBy(new BigNumber(10).pow(item.decimals)).plus(new BigNumber(earned?.weightingValue)).toString()
                  }
                  // 此token总分发价值 (supplyRewardRate * 365 *rewardPrice)
                  const tokenSupplyReward = new BigNumber(earned?.rewardInfo?.[item.address]?.["supplyRewardRate"]).multipliedBy(new BigNumber(365)).multipliedBy(new BigNumber(earned?.rewardPrice)).dividedBy(new BigNumber(10).pow(18));
                  // 此token每1美金一年分发多少美金的 preTokenRewardValue = tokenSupplyReward / weightingSupplyValue
                  const preTokenRewardValue = tokenSupplyReward.multipliedBy(new BigNumber(10).pow(18)).dividedBy(new BigNumber(earned?.rewardInfo?.[item.address]?.["weightingSupplyValue"]).toFixed(0));
                  // 用户一年总分发 userTokenRewardValue = preTokenRewardValue * weightingSupplyValue
                  const userTokenRewardValue = new BigNumber(preTokenRewardValue).multipliedBy(new BigNumber(userWeightingValue)).div(new BigNumber(10).pow(18)).toFixed(0)
                  _userAPR = new BigNumber(userTokenRewardValue).multipliedBy(new BigNumber(10).pow(item.decimals)).div(new BigNumber(MystakedValue)).toFixed(0);
                }
              // 计算user UTS APR

              // 计算user ARB APR
              if(chainId === 5 || chainId === 42161){
                // 门槛计算  userTotalSupply * threshold <=  weightingValue
                  const isWeightingTokenArb = earnedArb?.rewardInfo?.[item.address]
                  if(!isWeightingTokenArb  || new BigNumber(earnedArb?.BLPStakedValue).lt(new BigNumber(totalValue[0]).plus(new BigNumber(MystakedValue)).multipliedBy(new BigNumber(rewardDataArb?.threshold)).dividedBy(new BigNumber(10).pow(18)).toFixed(0)) || new BigNumber(MystakedValue).eq(0) || new BigNumber(earnedArb?.rewardInfo?.[item.address]?.["supplyRewardRate"]).eq(0) ||new BigNumber(earnedArb?.rewardInfo?.[item.address]?.["weightingSupplyValue"]).eq(0)){
                    _userAPRArb = '0'
                  }else{
                    // _userAPR
                    let userWeightingValue;
                    if(new BigNumber(earnedArb?.weightingValue).gte(new BigNumber(MystakedValue).multipliedBy(new BigNumber(rewardDataArb?.magnification)).dividedBy(new BigNumber(10).pow(18)).toFixed(0))){
                      userWeightingValue = new BigNumber(MystakedValue).plus(new BigNumber(MystakedValue).multipliedBy(new BigNumber(rewardDataArb?.magnification).dividedBy(new BigNumber(10).pow(18)))).dividedBy(new BigNumber(10).pow(item.decimals)).multipliedBy(new BigNumber(10).pow(18)).toString()
                    }else{
                      userWeightingValue = new BigNumber(MystakedValue).multipliedBy(new BigNumber(10).pow(18)).dividedBy(new BigNumber(10).pow(item.decimals)).plus(new BigNumber(earnedArb?.weightingValue)).toString()
                    }
                    // 此token总分发价值 (supplyRewardRate * 365 *rewardPrice)
                    const tokenSupplyReward = new BigNumber(earnedArb?.rewardInfo?.[item.address]?.["supplyRewardRate"]).multipliedBy(new BigNumber(365)).multipliedBy(new BigNumber(earnedArb?.rewardPrice)).dividedBy(new BigNumber(10).pow(18));
                    // 此token每1美金一年分发多少美金的 preTokenRewardValue = tokenSupplyReward / weightingSupplyValue
                    const preTokenRewardValue = tokenSupplyReward.multipliedBy(new BigNumber(10).pow(18)).dividedBy(new BigNumber(earnedArb?.rewardInfo?.[item.address]?.["weightingSupplyValue"]).toFixed(0));
                    // 用户一年总分发 userTokenRewardValue = preTokenRewardValue * weightingSupplyValue
                    const userTokenRewardValue = new BigNumber(preTokenRewardValue).multipliedBy(new BigNumber(userWeightingValue)).div(new BigNumber(10).pow(18)).toFixed(0)
                    _userAPRArb = new BigNumber(userTokenRewardValue).multipliedBy(new BigNumber(10).pow(item.decimals)).div(new BigNumber(MystakedValue)).toFixed(0);
                  }
              } 
              // 计算user ARB APR
              } catch (error) {
                MyStaked = '...'
                balance = undefined
                allowance = '0'
                TotalStaked = '...';
                TotalSupply = '...';
                LPShare = '...';
                TotalStakedValue = '...';
                MystakedValue = '...';
                _userAPR = '...';
                _userAPRArb = undefined
              }

              break;
            default:
              MyStaked = '...'
              balance = undefined
              allowance = '0'
              TotalStaked = '...';
              TotalSupply = '...';
              LPShare = '...';
              TotalStakedValue = '...';
              MystakedValue = '...';
              _userAPR = '...';
              _userAPRArb = undefined
              break;
          }
          const isApproved = (Number(allowance) === 0) ? false : true

          return {
            ...item,
            underlying_address,
            balance,
            MyStaked,
            TotalStaked,
            TotalStakedValue,
            LPShare,
            MystakedValue,
            userAPR:_userAPR,
            userAPRArb:_userAPRArb,
            // earned,
            // harvestStatus,
            isApproved,
            allowance,
            // Locked
          }
        })
      )
      // console.log("updateLiquidityDate",updateLiquidityDate)
      setLiquidity(updateLiquidityDate)
    } else {
      setLiquidity(liquidityDate)
    }
  }, [lending, account,chainId])


  useEffect(() => {
    let Timer:any;
    if(chainId && account){
      const hasLiquidityLP = deployAssets["liquidity"]['networks'][`${chainId}`]
      if(hasLiquidityLP){
        fetchApi(chainId,account)
        Timer = setInterval(() => {
          fetchApi(chainId,account)
        }, 1000*10)
      }else{
        navigate("/lending")
      }
      
    }
    // const Timer = setInterval(() => {
    //   fetchApi(chainId ?? 1,'')
    // }, 1000*10)
    return () => {
      clearInterval(Timer)
    }
  }, [chainId, account, lending])


  return (
    <>
      <MarketDistribution
        stake={liquidity}
        threshold={_threshold}
        localConfig_alltoken={localConfig}
      />
    </>
  )
}
export default Liquidity