import React from 'react'
import styled, { keyframes } from 'styled-components'

export interface ModalProps {
  isOpen?: boolean,
  children?: React.ReactNode,
  onDismiss?: () => void
}


const dismissProps = (onDismiss: any) => {
  try {
    onDismiss()
  } catch (err) { console.log(err) }
}
const stopClose = (e: any) => {
  try {
    // console.log(e)
    e.preventDefault()
    e.stopPropagation()
  } catch (err) { console.log(err) }
}

const Modal: React.FC<ModalProps> = ({ isOpen, children, onDismiss }) => {
  if (isOpen) {
    return (
      <Umbralayer onClick={() => { dismissProps(onDismiss) }}>
        <StyledModal isOpen={isOpen} onClick={(e) => { stopClose(e) }}>
          {children}
        </StyledModal>
      </Umbralayer>
    )
  }
  return null
}



interface StyledModalProps {
  isOpen?: boolean
}

const StyledModal = styled.div<StyledModalProps>`
  /* box-shadow: 24px 24px 48px -24px ${props => props.theme.colors.darkGrey}; */
  position: absolute;
  background: #fff;
  padding:26px 20px 20px;
  top:50%;
  transform: translateY(-50%);
  width: 520px;
  background: #fff;
  border-radius: 20px;
  /* border: 1px solid #2E344E; */

  @media(max-width: 1199px) {
    width:calc(100% - 64px);
    margin:0 auto;
    padding:20px 10px 25px
  }

  .dark & {
    /* background: #0A1130; */
    background: #000;
    border: 1px solid #333;
  }
`
const Umbralayer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  position: fixed;
  left:0;
  top:0;
  right:0;
  bottom:0;
  z-index: 9999;
  inset: 0px;
  background: rgba(0,0,0, 0.6);

  .dark & {
    background: rgba(0,0,0, 0.9);
  }
`

export default Modal