import React, { useState, useCallback, useEffect } from "react";
import {
  NavLink as RouterLink
} from "react-router-dom";
import Modal from "components/ModalAction";
import ModalTitle from "components/ModalAction/components/ModalTitle";
import ModalContent from "components/ModalAction/components/ModalContent";

import styled, { keyframes } from "styled-components";
import { useIntl } from "react-intl";
import { bnToDec, formate_round_up, useQuery, getDeployAddressMap, DFApproveXArray, setupNetwork, listConfigs, _formatNumberNotCarry, format_bn, numberFormatter } from "utils";
import { ethers } from 'ethers'
import numeral from "numeral";
import BigNumber from "bignumber.js";
import { etherscan, network_Name, add_Notifies, update_Notifies, del_Notify, del__cancel } from 'lending-sdk/utils/index1'
import { useWeb3React } from '@web3-react/core'
import useLending from "hooks/useLending";
import UnlockWalletModal from "components/UnlockWalletModal";
import LiquidityModal from "components/LiquidityModal";
import Notification from "components/Notification";
import Notification__cancel from "components/Notification__cancel";
import Link from "assets/stake/link.svg"
import Switch from "assets/stake/Switch.svg"
import unselected from "assets/supply/img__off.svg";
import select from "assets/supply/img__on.svg";
import img_all from "assets/Bridge/net-all.svg"
import img_eth from "assets/Bridge/net-eth.svg"
import img_arbitrum from "assets/Bridge/net-arbitrum.svg"
import img_op from "assets/Bridge/net-op.svg"
import img_bsc from "assets/Bridge/net-bsc.svg"
import img_polygon from "assets/Bridge/Polygon.svg"
import img_kava from "assets/Bridge/net-kava.svg"

import metamask from "assets/wallet-MetaMask.svg";
import Brave from 'assets/walletLog/Brave.svg'
import coinbase from "assets/wallet-Coinbase.svg";
import icon_walletconnect from "assets/wallet-WalletConnect.svg";
import FlexBGFilter from "components/FlexBGFilter";
import FlexBoxWrap from "components/FlexBoxWrap";
import useMarkets from "hooks/useMarkets";
import TotalAssets from "components/_TotalAssets";

interface MarketDistributionProps {
  stake: any[]
  threshold:string | undefined
  localConfig_alltoken: any[]
}

const MarketDistribution: React.FC<MarketDistributionProps> = ({
  stake,threshold, localConfig_alltoken
}) => {
  const intl = useIntl();
  const fmt = intl.formatMessage;
  const {
    connector,
    account,
    chainId,
    provider,
    ENSName,
    isActive,
    isActivating,
  } = useWeb3React();
  const lending = useLending();
  const [unlockModalIsOpen, setUnlockModalIsOpen] = useState(false);
  const [currentNetwork, setCurrentNetwork] = useState<string>('all')
  const [currentChainId, setCurrentChainId] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(true)

  const { totalAssets } = useMarkets()

  const allStake = [...stake]
  const normal = [...stake].filter(item => item?.status !== 'ended')
  const assetsData = {
    "all": allStake,
    "normal": normal
  }
  
  const [fetchStake, setFetchStake] = useState<any[]>([])
  const [showDetail, setShowDetail] = useState<number>(-1);

  const [TotalStaked, setTotalStaked] = useState(0);
  const [DailyDistribution,setDailyDistribution] = useState(0)
  const [LPShare, setLPShare] = useState(0);
  const [userAPR, setuserAPR] = useState(0);
  const [_MyStaked, _setMyStaked] = useState(0);

  const [currentSymbol, setCurrentSymbol] = useState("");
  const [poolSymbol, setPoolSymbol] = useState("");
  const [address, setAddress] = useState("");
  const [action, setAction] = useState("");
  const [assetsIcon, setAssetsIcon] = useState("");
  const [MyStaked, setMyStaked] = useState<string>('...')
  const [poolType, setPoolType] = useState<string>('...')
  const [balance, setBalance] = useState<string>('...')
  const [decimals, setDecimals] = useState<number>(18)
  const [stakeModalIsOpen, setStakeModalIsOpen] = useState(false);
  const [zapModalIsOpen, setZapModalIsOpen] = useState(false);
  const [isApproved, setIsApproved] = useState<boolean>(false)
  const [allowance, setAllowance] = useState<string>('0')
  const [nowNotifies, setNowNotifies] = useState<any>([]);
  const [show__cancel, setShow__cancel] = useState<boolean>(false);
  // notify tips


  const [typeList, setTypeList] = useState(false)
  const [curType, setCurType] = useState<string>('0')

  const showStakeModle = (e: any, currentSymbol: string, poolSymbol: string, action: string, assetsIcon: string, poolType: string, _isApproved: boolean, _allowance: string, _address: string) => {
    setCurrentSymbol(currentSymbol);
    setPoolSymbol(poolSymbol)
    setAddress(_address)
    setIsApproved(_isApproved)
    setAllowance(_allowance)
    // console.log(currentSymbol,_allowance)
    setAction(action);
    setAssetsIcon(assetsIcon)
    const currentBalance = stake.filter(item => item?.reveal_symbol === currentSymbol)[0].balance
    setBalance(currentBalance)
    const currentStakeBalance = stake.filter(item => item?.reveal_symbol === currentSymbol)[0].MyStaked
    setMyStaked(currentStakeBalance)
    setPoolType(poolType)
    // const currentRewardRate = stake.filter(item => item?.reveal_symbol === currentSymbol)[0].rewardRate
    // setRewardRate(currentRewardRate)
    const currentDecimal = stake.filter(item => item?.reveal_symbol === currentSymbol)[0].decimals
    setDecimals(currentDecimal)
    setStakeModalIsOpen(true);
  }

  const showModleZap = (e: any, currentItem: string) => {
    console.log(currentItem)
    setZapModalIsOpen(true)
  }
  const handleDismissZapModal = useCallback(() => {
    setZapModalIsOpen(false)
  }, [zapModalIsOpen])


  const handleDismissStakeModal = useCallback(() => {
    setStakeModalIsOpen(false);
  }, [stakeModalIsOpen])

  let sort_data: any[]
  const [sortStakeData, setSortStakeData] = useState<any[]>([])

  const sortData = useCallback((current_status: number, current_Dispatch: React.Dispatch<React.SetStateAction<number>>, current_type: string) => {
    setTotalStaked(0)
    setDailyDistribution(0)
    setLPShare(0)
    setuserAPR(0)
    _setMyStaked(0)
    const _fetchStake = [...stake]
    if (current_status === 0) {
      current_Dispatch(1)
      sort_data = _fetchStake.sort((a, b) => Number(b[current_type]) - Number(a[current_type]))
    } else if (current_status === 1) {
      current_Dispatch(-1)
      sort_data = _fetchStake.sort((a, b) => Number(a[current_type]) - Number(b[current_type]))
    } else if (current_status === -1) {
      current_Dispatch(0)
      sort_data = assetsData["all"]
    }
    console.log(sort_data)
    setSortStakeData(sort_data)
  }, [fetchStake])

  const handleUnlockWalletClick = useCallback(() => {
    setUnlockModalIsOpen(true);
  }, [setUnlockModalIsOpen]);



  useEffect(() => {
    if (stake.length > 0) {
      //每次轮询 取API最新数据进行更新
      const fetchStake = [...stake].filter((_it) => (currentChainId ? (_it.ChainId === currentChainId) : true)).filter((_item) => _item?.status !== 'ended');
      if (sortStakeData.length > 0) {
        const updateSortStakeData = sortStakeData.reduce((result: any[], item) => {
          const currentItem = fetchStake.find(item2 => item2.address === item?.address)
          if (currentItem) {
            result.push(currentItem)
          }
          return result
        }, [])
        setFetchStake(updateSortStakeData)
      } else {
        setFetchStake(assetsData['normal'].filter((_it) => (!!currentChainId ? (_it.ChainId === currentChainId) : true)))
      }
    }
  }, [stake, sortStakeData, currentChainId])

  const showDetailHandler = (e: any, index: number) => {
    // 点击tr行 显示 Detail 组件, 点击Detail组件禁止冒泡
    console.log(e.target)
    if (document.querySelectorAll('.detail')[index]?.contains(e.target) || e.target.id === 'getLP') {
      return
    }

    if (showDetail === index) {
      setShowDetail(-1);
    } else {
      setShowDetail(index);
    }
  }

  const switchNetwork = (e: any) => {
    const currentChain = e.target.dataset.chain;
    console.log(e.target)
    console.log("currentChain", currentChain)
    // console.log(e.target.tagName)
    // console.log(e.currentTarget.querySelector(".active"))
    setCurrentChainId(Number(currentChain))
    setCurrentNetwork(network_Name?.[currentChain] ?? 'all')
    if (e.target.tagName === 'A') {
      e.currentTarget.querySelector(".active")?.classList.remove("active");
      e.target.parentNode.classList.add('active')
    } else if (e.target.tagName === 'UL') {
      return;
    }
    // console.log(e.target.datasets("networkName"))
  };

  const [metamaskActive, setMetamaskActive] = useState(false);
  const [walletConnectActive, setWalletConnectActive] = useState(false);
  const [walletIcon, setWalletIcon] = useState("");
  const handleDismissUnlockModal = useCallback(() => {
    setUnlockModalIsOpen(false);
    setMetamaskActive(false);
    setWalletConnectActive(false);
  }, [setUnlockModalIsOpen]);

  useEffect(() => {
    setLoading(true)
    setShowDetail(-1)
    setStakeModalIsOpen(false);
    // console.log(showDetail)
  }, [account, chainId, currentChainId])
  useEffect(() => {
    if (sortStakeData.length > 0) {
      let allStake =  [...stake].filter((_it) => (currentChainId ? (_it.ChainId === currentChainId) : true)).filter((_item) => _item?.status !== 'ended');
      // 判断是否 selectType
      switch (curType) {
        case '0':
          break;
        case '1':
          allStake = allStake.filter(item => ((item?.poolType !== 'Beefy') && (item?.poolType !== 'Vault')))
          break;
        default:
          break;
      }
      const updateSortStakeData = sortStakeData.reduce((result: any[], item) => {
        const currentItem = allStake.find(item2 => item2.address === item?.address)
        if (currentItem) {
          result.push(currentItem)
        }
        return result
      }, [])
      setFetchStake(updateSortStakeData)
      setLoading(false)
    } else {
      switch (curType) {
        case '0':
              setFetchStake(assetsData['normal'].filter((_it) => (!!currentChainId ? (_it.ChainId === currentChainId) : true)))
              setLoading(false)
          break;
        case '1':
              setFetchStake(assetsData['normal'].filter((_it) => (!!currentChainId ? (_it.ChainId === currentChainId) : true)).filter(item => ((item?.poolType !== 'Beefy') && (item?.poolType !== 'Vault'))))
              setLoading(false)
          break;
        default:
          break;
      }
    }

  }, [stake, sortStakeData, currentChainId, curType])

  useEffect(() => {
    // stakeModal isOpen 时候 轮询更新 balance
    if (currentSymbol !== '') {
      const currentBalance = stake.filter(item => item?.reveal_symbol === currentSymbol)[0].balance
      setBalance(currentBalance)
      const currentStakeBalance = stake.filter(item => item?.reveal_symbol === currentSymbol)[0].MyStaked
      setMyStaked(currentStakeBalance)
    }
  }, [currentSymbol])
  const [TotalStakeValue,setTotalStakeValue] = useState<BigNumber>(new BigNumber(0))
  const [MyTotalStakeValue,setMyTotalStakeValue] = useState<BigNumber>(new BigNumber(0))
  const [thresholdStatus,setThresholdStatus] = useState<number | undefined>(undefined)
  const [percent,setPercent] = useState<string | undefined>(undefined)
  const [needed,setNeeded] = useState<BigNumber>(new BigNumber(0))
  // const [MyTotalSupply,setMyTotalSupply] = useState<string>('0')
  useEffect(()=>{
    setMyTotalStakeValue(new BigNumber(0))
    setTotalStakeValue(new BigNumber(0))
    setThresholdStatus(undefined)
    setNeeded(new BigNumber(0))
    if(chainId && stake.length>0 && totalAssets?.totalValue && threshold){
      try {
        let MyStakeValue: BigNumber = new BigNumber(0)
        let _TotalStakeValue: BigNumber = new BigNumber(0)
        stake.map((item:any)=>{
          // console.log(MyStakeValue,item?.MystakedValue)
          MyStakeValue =  new BigNumber(MyStakeValue).plus(new BigNumber(item?.MystakedValue))
          _TotalStakeValue = new BigNumber(_TotalStakeValue).plus(new BigNumber(item?.TotalStakedValue))
        })
        setMyTotalStakeValue(MyStakeValue)
        setTotalStakeValue(_TotalStakeValue)
        // setMyTotalSupply(totalAssets?.totalValue?.[0])
        // const MyStakeValue = stake.reduce((pre: any, cur: any,)=>{
        //   console.log(pre.MystakedValue,cur.MystakedValue)
        //   return (new BigNumber(pre.MystakedValue).plus(new BigNumber(cur.MystakedValue)))
        // },[{MystakedValue:'0'}])
        // console.log(totalAssets?.totalValue?.[0],MyStakeValue.toString(),_TotalStakeValue.toString(),threshold)

        const thresholdValue = new BigNumber(totalAssets?.totalValue?.[0]).multipliedBy(new BigNumber(threshold)).div(new BigNumber(10).pow(18))
        if(isNaN(Number(MyTotalStakeValue)) || isNaN(Number(MyStakeValue))){
          return;
        }
        if(new BigNumber(totalAssets?.totalValue?.[0]).lte(new BigNumber(0))){
          setThresholdStatus(0)
        }else{
          if(MyStakeValue.lte(thresholdValue)){
            setThresholdStatus(1)
            setPercent(_formatNumberNotCarry(bnToDec(MyStakeValue.multipliedBy(new BigNumber(10).pow(18)).div(new BigNumber(totalAssets?.totalValue?.[0])), 18,4),'percent'))
            setNeeded(thresholdValue.minus(MyStakeValue))
          }else{
            setThresholdStatus(2)
            setPercent(_formatNumberNotCarry(bnToDec(MyStakeValue.multipliedBy(new BigNumber(10).pow(18)).div(new BigNumber(totalAssets?.totalValue?.[0])), 18,4),'percent'))
            setNeeded(MyStakeValue)
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
  },[stake,totalAssets,chainId,threshold])

  const [showList, setShowList] = useState(false)

  const NetMap = {
    all: {
      net: 'All_Chains',
      icon: img_all
    },
    mainnet: {
      net: 'Ethereum',
      icon: img_eth
    },
    ArbitrumOne: {
      net: 'Arbitrum',
      icon: img_arbitrum
    },
    Optimism: {
      net: 'Optimism',
      icon: img_op
    },
    bsc: {
      net: 'BSC',
      icon: img_bsc
    },
    Polygon: {
      net: 'Polygon',
      icon: img_polygon
    },
    Kava: {
      net: 'Kava',
      icon: img_kava
    },
    Goerli: {
      net: 'Goerli',
      icon: img_eth
    },
  }

  // const buttonOnBlur = () => {
  //   setTimeout(() => {
  //     setShowList(false)
  //   }, 300)
  // }
  useEffect(() => {
    document.body.addEventListener("click", (e) => {
      // 如果点击了 网络切换 select 列表不消失
      const NetworkSelect = document
        .querySelector(".NetworkName")
        ?.contains(e.target as HTMLElement);
      if (NetworkSelect) {
        return;
      }
      if (showList) {
        setShowList(false)
      }
      // 如果点击了 网络切换 select 列表不消失
      const TypeSelect = document
        .querySelector(".SelectType")
        ?.contains(e.target as HTMLElement);
      if (TypeSelect) {
        return;
      }
      if (typeList) {
        setTypeList(false)
      }

    });
    return () => {
      document.body.removeEventListener("click", (event) => {
        event.preventDefault();
      }, false);
    };
  }, [showList, typeList]);


  const approveToken = async () => {
    // if (lending && account) {
    //   lending.contracts.stake[`${symbol}`] && lending.contracts.stake[`${symbol}`].methods
    //     .approve(spenderAddress, ethers.constants.MaxUint256)
    //     .send({ from: account })
    // }
  }


  const zapMax = () => {
    // setisMax(true)
    // // if (bnToDec(new BigNumber(balance), decimals,4) === 0) {
    // if (new BigNumber(balance).eq('0')) {
    //   setStakeValue('')
    // } else {
    //   setStakeValue(numeral(bnToDec(new BigNumber(balance), decimals, 6)).format('0.000000'))
    //   setOriginalStakeValue(new BigNumber(balance))
    // }
  }
  const InputZapChange = (value: string) => {
    // setisMax(false)
    // value = value.trim();
    // var reg_ZH_CN = new RegExp("[\\u4E00-\\u9FFF]+", "g");
    // var reg_ABC = /[a-z]/g;
    // // 限制只可输入小数点后6位
    // let quantityScale = "6";
    // let reg_maxLength = new RegExp(
    //   "^(0|[1-9][0-9]*)(\\.[0-9]{0," + quantityScale + "})?$"
    // );
    // // 限制只可输入小数点后6位
    // if (reg_ZH_CN.test(value)) {
    //   return console.log("不可以输入中文");
    // }
    // if (reg_ABC.test(value)) {
    //   return console.log("English ABC prohibited");
    // }
    // if (!reg_maxLength.test(value) && value !== "") {
    //   return console.log(
    //     "The maximum number of digits after the decimal point is exceeded"
    //   );
    // }
    // // if (bnToDec(new BigNumber(balance), decimals,4) === 0) {
    // if (new BigNumber(balance).eq('0')) {
    //   return false
    // }
    // let MaximumOverflow = new BigNumber(value)
    //   .multipliedBy(new BigNumber(10).pow(new BigNumber(decimals)))
    //   .gt(new BigNumber(balance));
    // if (MaximumOverflow) {
    //   StakeMax()
    // } else {
    //   setStakeValue(value)
    //   setOriginalStakeValue(new BigNumber(value).multipliedBy(new BigNumber(10).pow(new BigNumber(decimals))))
    // }
  }
  const zap = async () => {
    // if (lending && account) {
    //   if (!Number(stakeValue)) {
    //     return false
    //   }
    //   setSTAKE('STAKING')
    //   setDisabledStakeBtn(true)
    //   lending.contracts.stake[`r${symbol}`] &&
    //     lending.contracts.stake[`r${symbol}`]
    //       .methods.stake(originalStakeValue.toString()).send({ from: account })
    //       .on('transactionHash', function (hash: string) {
    //         setStakeValue('')
    //         onDismiss && onDismiss()
    //         console.log(hash)
    //         add_Notifies({
    //           state: "pedding",
    //           // action: "Stake " + formatNumberNotCarry(originalStakeValue.toNumber(),5,5) + ' ' + symbol,
    //           action: "Stake " + stakeValue + ' ' + poolSymbol,
    //           transactionHash: hash,
    //           time: new Date().getTime()
    //         }, setNowNotifies, account as string, chainId as number);
    //       })
    //       .on('confirmation', function (_confirmationNumber: any, receipt: any) {
    //         console.log(_confirmationNumber, receipt)
    //       })
    //       .on('receipt', async (receipt: any) => {
    //         console.log(receipt);
    //         await getAllowance(symbol, address);
    //         // setSTAKE('STAKE')
    //         setDisabledStakeBtn(false)
    //         update_Notifies(receipt, setNowNotifies, account as string, chainId as number);
    //         setTimeout(async () => {
    //           await del_Notify(receipt, setNowNotifies);
    //         }, 3000);
    //       })
    //       .on('error', function (error: { toString: () => string | string[]; message: any; }) {
    //         setSTAKE('STAKE')
    //         setDisabledStakeBtn(false)
    //         setShow__cancel(true);
    //         setTimeout(() => {
    //           setShow__cancel(false);
    //         }, 3000);
    //         if (!error.toString().includes("-32601")) {
    //           return console.log(error)
    //         }
    //       })
    //       .catch((error: { toString: () => string | string[]; message: any; }) => {
    //         setSTAKE('STAKE')
    //         setDisabledStakeBtn(false)
    //         setShow__cancel(true);
    //         setTimeout(() => {
    //           setShow__cancel(false);
    //         }, 3000);
    //         if (!error.toString().includes("-32601")) {
    //           return console.log(error)
    //         }
    //       })
    // }
  }


  return (
    <>
      <UnlockWalletModal
        isOpen={unlockModalIsOpen}
        onDismiss={handleDismissUnlockModal}
        metamaskActive={metamaskActive}
        walletConnectActive={walletConnectActive}
      />
      <Notification
        chainID={chainId}
        notifies={nowNotifies}
        setNowNotifies={setNowNotifies}
        del_Notify={del_Notify}
      />
      <Notification__cancel
        notifies={show__cancel}
        setShow__cancel={setShow__cancel}
        del_Notify={del__cancel}
      />
      {/* <StakeTableTitle>{fmt({ id: "Stake_Title" })}</StakeTableTitle>
      <TitleSub>{fmt({ id: "Price_Title__sub" })}</TitleSub> */}
      <TopSection>
        <AssetWrap>
          <StyledTitle>
            {fmt({ id: "Liquidity_Title" })}
          </StyledTitle>
          <PriceTitleSub>{fmt({ id: "Liquidity_Title__sub" })}&nbsp;<a href='https://unitus.gitbook.io/unitus/overview/liquidity-mining-faq' target="_blank" rel="noreferrer">{fmt({ id: 'LearnMore' })}</a></PriceTitleSub>
        </AssetWrap>
        {/* <TotalAssets
          totalAssets={[
            { title: "TotalStake", value: isNaN(Number(TotalStakeValue))? undefined : TotalStakeValue.toString(),dollar:true },
            { title: "MyBLPStake", value: isNaN(Number(MyTotalStakeValue)) ? undefined : MyTotalStakeValue.toString(),dollar:true,thresholdStatus,needed}
          ]}
        /> */}
      </TopSection>
      {
        localConfig_alltoken && localConfig_alltoken.length > 0 &&
        <>
          <TableWrap>
            {
              (!loading && fetchStake.length > 0) ?
                <Table className="leftTable">
                  <thead>
                    <tr>
                      <th className="firstTh">{fmt({ id: "MiningAssets" })}</th>
                      <th>
                        <SortArea onClick={(): void => sortData(DailyDistribution, setDailyDistribution, "DailyDistribution")}>
                          <div className="specialApy">
                            <div>
                              <span>{fmt({ id: "Paid_Day" })}</span>
                            </div>
                            <div className="bottomAPY">
                              <span>{fmt({ id: "Total_Distribution" })}</span>
                            </div>
                          </div>
                          <i className={"active" + DailyDistribution}></i>
                        </SortArea>
                      </th>
                    <th>
                        <SortArea onClick={(): void => sortData(TotalStaked, setTotalStaked, "TotalStaked")}>
                          {fmt({ id: "Total_Staked" })}
                          <i className={"active" + TotalStaked}></i>
                        </SortArea>
                      </th>
                    {/* <th>
                        <SortArea onClick={(): void => sortData(LPShare, setLPShare, "LPShare")}>
                          {fmt({ id: "LPShare" })}
                          <i className={"active" + LPShare}></i>
                        </SortArea>
                      </th> */}
                      <th>
                        <SortArea
                          onClick={(): void =>
                            sortData(userAPR, setuserAPR, "userAPR")
                          }
                        >
                          {/* {fmt({ id: "supply_apy" })} */}
                          <div className="specialApy">
                            <div>
                              <span>{fmt({ id: "MyBoost" })}</span>
                            </div>
                            <div className="bottomAPY">
                              <span>{fmt({ id: "AssetsBoost" })}</span>
                            </div>
                          </div>
                          <i className={"active" + userAPR}></i>
                        </SortArea>
                      </th>
                      <th>
                        <SortArea onClick={(): void => sortData(_MyStaked, _setMyStaked, "MyStaked")}>
                          {fmt({ id: "MyStaked" })}
                          <i className={"active" + _MyStaked}></i>
                        </SortArea>
                      </th>
                      <th className="switchBtn"> </th>
                    </tr>
                  </thead>
                  <tbody>
                    {fetchStake.map((item, index) => {
                      return (
                        // <tr key={item?.address} onClick={(e) => (item?.ChainId === chainId) ? showDetailHandler(e, index) : void (0)}>
                        <tr key={item?.address} onClick={(e) => showDetailHandler(e, index)}>
                          <td className="firstTd">
                            <TokenIconWrap>
                              <TokenIcon src={item?.icon} />
                            </TokenIconWrap>
                            <TokenSymbol id="getLP" href={item?.Link} target="_self">{item?.poolSymbol}</TokenSymbol>
                          </td>
                          <td className="specialApy">
                            <div className="supply">
                            <img src="/Unitus.svg" />
                              <span className="highlight">
                              {
                                (item?.DailyDistribution && !isNaN(Number(item?.DailyDistribution))) ?
                                _formatNumberNotCarry(bnToDec(new BigNumber(item?.DailyDistribution), 18, 4))
                                :'...'
                              }
                              </span>
                            </div>
                            {
                              item?.DailyDistributionArb &&
                              <div className="supply">
                                <img src="/ARB.svg" />
                              <span className="highlight">
                              {
                                (item?.DailyDistributionArb && !isNaN(Number(item?.DailyDistributionArb))) ?
                                _formatNumberNotCarry(bnToDec(new BigNumber(item?.DailyDistributionArb), 18, 4))
                                :'...'
                              }
                              </span>
                            </div>
                            }
                            <div className="APR">
                            <img src="/Unitus.svg" />
                              <span>
                              {
                                (item?.Distributed && !isNaN(Number(item?.Distributed))) ?
                                _formatNumberNotCarry(bnToDec(new BigNumber(item?.Distributed), 18, 4))
                                :'...'
                              }
                              </span>
                            </div>
                            {item?.DistributedArb &&
                            <div className="APR">
                              <img src="/ARB.svg" />
                              <span>
                              {
                                (item?.DistributedArb && !isNaN(Number(item?.DistributedArb))) ?
                                _formatNumberNotCarry(bnToDec(new BigNumber(item?.DistributedArb), 18, 4))
                                :'...'
                              }
                              </span>
                            </div>
                            }
                          </td>
                        <td className="specialApy">
                          <div>
                          <span className="highlight">
                            {
                            (isNaN(Number(item?.TotalStaked)) || item?.TotalStaked === '...') ?
                            '...'
                            : _formatNumberNotCarry(bnToDec(new BigNumber(item?.TotalStaked), 18, 4), 'abbr')
                            }
                          </span>
                          </div>
                          <div>
                          <span>
                            {
                              (isNaN(Number(item?.TotalStakedValue)) || item?.TotalStakedValue === '...' || item?.TotalStakedValue === "NaN") ?
                              '...'
                              :'$'+_formatNumberNotCarry(bnToDec(new BigNumber(item?.TotalStakedValue), 18, 4), 'abbr')
                            }
                          </span>
                          </div>
                        </td>
                        <td className="specialApy">
                        {/* <div className="APR">
                          <img src="/Unitus.svg" />
                          <span className="highlight">
                          {
                            (item?.userAPR && (item?.userAPR !== '...')) ?
                            _formatNumberNotCarry(bnToDec(new BigNumber(item?.userAPR), 18, 4), 'percent')
                            :'...'
                          }
                          </span>
                        </div> */}
                        {
                            (item?.userAPR && (item?.userAPR !== '...')) &&
                            <div className="APR">
                              <img src="/Unitus.svg" />
                              <span className="highlight">
                                {/* {isNaN(Number(item?.userAPR)).toString()} */}
                              {
                                _formatNumberNotCarry(bnToDec(new BigNumber(item?.userAPR), 18, 4), 'percent')
                              }
                              </span>
                            </div>
                          }
                          {
                          item?.userAPRArb &&
                          <div className="APR">
                            <img src="/ARB.svg" />
                            <span className="highlight">
                            {
                              _formatNumberNotCarry(bnToDec(new BigNumber(item?.userAPRArb), 18, 4), 'percent')
                            }
                            </span>
                          </div>
                        }
                        <div className="bottomAPY">
                          <img src="/Unitus.svg" />
                          <span>
                            {
                              bnToDec(new BigNumber(item?.MinimumSupplyRewardAPY).abs(), 18) > 9999.99
                                ?
                                '999,999.00%'
                                :
                                numeral(
                                  numeral(
                                    bnToDec(new BigNumber(item?.MinimumSupplyRewardAPY), 18, 4)
                                  ).format("0.0000")
                                ).format("0,0.00%")
                            }
                          </span>
                          ~
                          <span>
                            {
                              bnToDec(new BigNumber(item?.SupplyRewardAPY).abs(), 18) > 9999.99
                                ?
                                '999,999.00%'
                                :
                                numeral(
                                  numeral(
                                    bnToDec(new BigNumber(item?.SupplyRewardAPY), 18, 4)
                                  ).format("0.0000")
                                ).format("0,0.00%")
                            }
                          </span>
                        </div>
                        {
                          item?.SupplyRewardAPYArb && item?.MinimumSupplyRewardAPYArb &&
                          <div className="bottomAPY">
                            <img src="/ARB.svg" />
                            <span>
                              {
                                bnToDec(new BigNumber(item?.MinimumSupplyRewardAPYArb).abs(), 18) > 9999.99
                                  ?
                                  '999,999.00%'
                                  :
                                  numeral(
                                    numeral(
                                      bnToDec(new BigNumber(item?.MinimumSupplyRewardAPYArb), 18, 4)
                                    ).format("0.0000")
                                  ).format("0,0.00%")
                              }
                            </span>
                            ~
                            <span>
                              {
                                bnToDec(new BigNumber(item?.SupplyRewardAPYArb).abs(), 18) > 9999.99
                                  ?
                                  '999,999.00%'
                                  :
                                  numeral(
                                    numeral(
                                      bnToDec(new BigNumber(item?.SupplyRewardAPYArb), 18, 4)
                                    ).format("0.0000")
                                  ).format("0,0.00%")
                              }
                            </span>
                          </div>
                        }
                      </td>
                          {/* <td>
                            <span className="highlight">
                            {
                              (isNaN(Number(item?.LPShare)) || item?.LPShare === '...') ?
                              '...'
                              : _formatNumberNotCarry(bnToDec(new BigNumber(item?.LPShare),18,4),'percent')
                            }
                            </span>
                          </td> */}
                          <td className="specialApy">
                          <div>
                          <span className="highlight">
                            {
                            (isNaN(Number(item?.MyStaked)) || item?.MyStaked === '...') ?
                            '...'
                            : _formatNumberNotCarry(bnToDec(new BigNumber(item?.MyStaked), 18, 4), 'abbr')
                            // :formate_round_up(
                            //   numeral(
                            //     bnToDec(new BigNumber(item?.MyStaked), 18)
                            //   )
                            //     .format("0.000a")
                            //     .toLocaleUpperCase()
                            // )
                            }
                          </span>
                          </div>
                          <div>
                          <span>
                            {
                              (isNaN(Number(item?.MystakedValue)) || item?.MystakedValue === '...') ?
                              '...'
                              :'$'+_formatNumberNotCarry(bnToDec(new BigNumber(item?.MystakedValue), 18, 4), 'abbr')
                              // :'$'+formate_round_up(
                              //   numeral(
                              //     bnToDec(new BigNumber(item?.MystakedValue), 18)
                              //   )
                              //     .format("0.000a")
                              //     .toLocaleUpperCase()
                              // )
                            }
                          </span>
                          </div>
                          </td>
                          <td className="switchBtn">
                            {
                              (
                                item?.icon ?
                                  account ?
                                      <DetailLink className={showDetail === index ? "open" : ""}>
                                        <span>{fmt({ id: "Detail" })}</span>
                                      </DetailLink>
                                    : <SwitchNetwork onClick={(e) => handleUnlockWalletClick()}>{fmt({ id: "Connect_Wallet" })}</SwitchNetwork>
                                  : <DetailLink className="disabled"><span>Detail</span></DetailLink>
                              )
                            }
                          </td>
                          <td
                            className={showDetail === index ? "detail detailShow" : "detail"}
                            style={
                              showDetail === index
                                ? { height: "auto", visibility: "visible",marginTop:"8px" }
                                : { height: 0, visibility: "hidden" }
                            }
                          >
                            <Detail>
                              <Alink>
                                {/* <a href={item?.TradeLink} target="_blank">{fmt({ id: 'AcquireUTS' })}</a> */}
                                <a href={item?.Link} target="_self">{fmt({ id: 'Get' })}{item?.poolSymbol} </a>
                                <a href={chainId ? `${etherscan[chainId]}${item?.address}` : ''} target="_blank">{fmt({ id: 'View_Contract' })}</a>
                              </Alink>
                              {/* <Harvest>
                                <Earned>
                                  <StakeTitle>
                                    {fmt({ id: "Zap" })}
                                    </StakeTitle>
                                  <Zap 
                                  className="disabled"
                                    // onClick={(e) => showModleZap(e, item)}
                                  >
                                    <p className="mouseOut">{fmt({ id: "Zap" })}</p>
                                    <p className="mouseIn">{fmt({ id: "ComingSoon" })}</p>
                                  </Zap>
                                </Earned>
                              </Harvest> */}
                              <PerformAction>
                                <StakeTitle>{fmt({ id: "Staking" })}</StakeTitle>
                                <ActionBtn>
                                  <>
                                    <StakeBtn
                                      onClick={(e) =>
                                        showStakeModle(e, item?.reveal_symbol, item?.poolSymbol, "STAKE", item?.icon, item?.poolType, item?.isApproved, item?.allowance, item?.address)
                                      }
                                    >
                                      {fmt({ id: "STAKE" })}
                                    </StakeBtn>
                                    <UnStakeBtn
                                      onClick={(e) =>
                                        showStakeModle(e, item?.reveal_symbol, item?.poolSymbol, "UNSTAKE", item?.icon, item?.poolType, item?.isApproved, item?.allowance, item?.address)
                                      }
                                    >
                                      {fmt({ id: "UNSTAKE" })}
                                    </UnStakeBtn>
                                  </>
                                </ActionBtn>
                              </PerformAction>
                            </Detail>
                          </td>
                        </tr>
                      )
                    })
                    }
                  </tbody>
                </Table>
                : (!loading && fetchStake.length === 0) ?
                  // <NoData>{fmt({ id: 'noData' })}</NoData>
                  <NoData></NoData>
                  :
                  <Table className="leftTable">
                    <thead>
                    <tr>
                      <th className="firstTh">{fmt({ id: "MiningAssets" })}</th>
                      <th>
                        <SortArea>
                          <div className="specialApy">
                            <div>
                              <span>{fmt({ id: "Paid_Day" })}</span>
                            </div>
                            <div className="bottomAPY">
                              <span>{fmt({ id: "Total_Distribution" })}</span>
                            </div>
                          </div>
                          <i className={"active" + DailyDistribution}></i>
                        </SortArea>
                      </th>
                    <th>
                        <SortArea>
                          {fmt({ id: "Total_Staked" })}
                          <i className={"active" + TotalStaked}></i>
                        </SortArea>
                      </th>
                      <th>
                        <SortArea>
                          {/* {fmt({ id: "supply_apy" })} */}
                          <div className="specialApy">
                            <div>
                              <span>{fmt({ id: "MyBoost" })}</span>
                            </div>
                            <div className="bottomAPY">
                              <span>{fmt({ id: "AssetsBoost" })}</span>
                            </div>
                          </div>
                          <i className={"active" + userAPR}></i>
                        </SortArea>
                      </th>
                      <th>
                        <SortArea>
                          {fmt({ id: "MyStaked" })}
                          <i className={"active" + _MyStaked}></i>
                        </SortArea>
                      </th>
                      <th className="switchBtn"> </th>
                    </tr>
                    </thead>
                    <tbody>
                      {
                        Array.from({ length: 5 }).map((key, index) => (
                          <tr key={index}>
                            <td className="firstTd">
                              <TokenIconWrap className="TokenIconBg" />
                              <TokenSymbol className="TokenSymbolBg"></TokenSymbol>
                            </td>
                            <td className="specialApy">
                            <div className="supply">
                              <span className="highlight">...</span>
                            </div>
                            <div className="APR">
                              <span>...</span>
                            </div>
                          </td>
                        <td className="specialApy">
                          <div>
                          <span className="highlight">...</span>
                          </div>
                          <div>
                          <span>...</span>
                          </div>
                        </td>
                        <td className="specialApy">
                        <div className="APR">
                          <img src="/Unitus.svg" />
                          <span className="highlight">...</span>
                        </div>
                        <div className="bottomAPY">
                          <span>...</span>~
                          <span>...</span>
                        </div>
                      </td>
                          <td className="specialApy">
                          <div>
                          <span className="highlight">...</span>
                          </div>
                          <div>
                          <span>...</span>
                          </div>
                          </td>
                            <td className="loadingDetail">
                              <DetailLink className="disabled"><span>Detail</span></DetailLink>
                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </Table>
            }
          </TableWrap>
          <LiquidityModal
            isOpen={stakeModalIsOpen}
            onDismiss={handleDismissStakeModal}
            address={address}
            symbol={currentSymbol}
            poolSymbol={poolSymbol}
            action={action}
            assetsIcon={assetsIcon}
            balance={balance}
            MyStaked={MyStaked}
            poolType={poolType}
            decimals={decimals}
            // rewardRate={rewardRate}
            add_Notifies={add_Notifies}
            update_Notifies={update_Notifies}
            del_Notify={del_Notify}
            setNowNotifies={setNowNotifies}
            setShow__cancel={setShow__cancel}
            isApproved={isApproved}
            allowance={allowance}
          />


          {
            zapModalIsOpen &&
            <Modal isOpen={zapModalIsOpen}>
              <ModalTitle text={'poolSymbol'} icon={"/static/media/default.svg"} onDismiss={handleDismissZapModal} />
              <ModalContent>
                <FormWarp className={"active"}>
                  <Balance>
                    {fmt({ id: "_Balance" })}{'...'}
                  </Balance>
                  <InputWarp
                  // className={rewardRate === '0' ? 'disabled' : ''}
                  >
                    <Input type="text"
                      placeholder="Amount"
                      value={'stakeValue'}
                      // disabled={rewardRate === '0' ? true : false} 
                      onChange={(e) => InputZapChange(e.target.value)}
                    />
                    <Max onClick={zapMax}>{fmt({ id: "MAX" })}</Max>
                  </InputWarp>
                  {
                    false
                      ? <Enable className={"disabled"} onClick={(e) => approveToken()}>
                        {fmt({ id: 'ENABLE' })}
                      </Enable>
                      : <Btn 
                        className={''} 
                        onClick={async () => await zap()}
                        >
                            <span className="mouseOut">{fmt({ id: "zap" })}</span>
                            <span className="mouseIn">{fmt({ id: "ComingSoon" })}</span>
                        </Btn>
                  }
                </FormWarp>
              </ModalContent>
            </Modal>
          }
        </>
      }
    </>
  )
}
const TopSection = styled.div`
  height: 284px;
  padding: 30px 0;
  width: 72%;
  min-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  @media (max-width: 1199px){
    width: 100%;
    min-width:inherit;
    padding: 10px 12px;
    height: auto;
    /* width: 100%; */
    margin: 0 auto;
    /* padding: 0 12px;
    gap: 4px; */
  }
`
const Btn = styled.div`
  width: 100%;
  height: 50px;
  border-radius: 25px;
  text-align: center;
  font-weight:600;
  background: ${(props) => props.theme.colors.supply.dark};
  color: ${(props) => props.theme.colors.white};
  cursor: pointer;
  &.disabled {
    pointer-events:none;
    background: ${(props) => props.theme.colors.lightTableBorder};
    color: ${(props) => props.theme.colors.disabledBtn};
  }
  @media(max-width: 1199px) {
    height: 40px;
    line-height:40px;
  }
`;
const Enable = styled.div`
  flex-basis: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  cursor: pointer;
  border-radius: 25px;
  color: ${(props) => props.theme.colors.white};
  background: ${(props) => props.theme.colors.supply.dark};
  background: linear-gradient(90deg, #5EAFE7 0%, #6E5DE7 100%);
  /* .dark & {
    background: ${(props) => props.theme.colors.supply.dark};
  } */
  &.disabled{
    cursor: not-allowed;
    pointer-events:none;
    color: #a1a4b1;
    background: ${(props) => props.theme.colors.lightPageBg};
  }
  @media(max-width: 1199px) {
    height: 40px;
    line-height:40px;
  }
`;
const Max = styled.div`
  width: 60px;
  height: 28px;
  line-height: 28px;
  position: absolute;
  right: 14px;
  top: 11px;
  cursor: pointer;
  text-align: center;
  color: ${(props) => props.theme.colors.supply.dark};
  background: ${(props) => props.theme.colors.supply.light};
  @media(max-width: 1199px) {
    top:8px;
  }
`;
const Input = styled.input`
  text-indent: 20px;
  border-radius: 25px;
`;
const InputWarp = styled.div`
  position: relative;
  margin-bottom: 25px;
  border-radius: 25px;
  border: 1px solid ${(props) => props.theme.colors.lightTableBorder};
  &.disabled {
    cursor: pointer;
    pointer-events:none;
    background: ${(props) => props.theme.colors.lightTableBorder};
    color: ${(props) => props.theme.colors.disabledBtn};
    ${Input}{
      background: ${(props) => props.theme.colors.lightTableBorder};
    }
  }
  @media(max-width: 1199px) {
    margin-bottom: 15px;
  }
`;
const Balance = styled.div`
  text-align: right;
  color: ${(props) => props.theme.DarkfontColor[100]};
`;
const FormWarp = styled.div`
  display: none;
  margin-top: 2px;
  line-height: 50px;
  box-sizing: border-box;
  &.active {
    display: flex;
    flex-direction: column;
  };
  &.locked{
    pointer-events:none;
    ${InputWarp}{
      background: ${(props) => props.theme.colors.lightTableBorder};
      color: ${(props) => props.theme.colors.disabledBtn};
      margin-bottom:0;
      ${Input}{
        background: ${(props) => props.theme.colors.lightTableBorder};
        color: ${(props) => props.theme.colors.disabledBtn};
      }
      ${Max}{
        background: ${(props) => props.theme.colors.lightPageBg};
        color: ${(props) => props.theme.colors.disabledBtn};
      }
    }
    
  }
  @media(max-width: 1199px) {
    line-height:40px;
  }
`;
// const StakeTableTitle = styled.h1`
// display: flex;
// align-items: center;
// /* height: 60px; */
// font-size: 22px;
// font-weight: bold;
// /* line-height: 60px; */
// margin:0 auto;
// width: 72%;
// min-width: 1200px;
// height: 224px;
// font-family:'LendingFont';
// font-weight:500;
// color:${(props) => props.theme.colors.white};
// /* .dark & {
//   color:${(props) => props.theme.colors.white};
// } */
// @media(max-width: 1199px) {
//   width: calc(100% - 24px);
//   min-width:inherit;
//   font-size:18px;
//   /* height:auto; */
//   /* line-height: 24px; */
//   margin:0 auto;
// }
// `
// const TitleSub = styled.div`
//   color: #FFF;
//   font-size: 16px;
//   font-style: normal;
//   font-weight: 600;
//   line-height: 24px;
//   width: 590px;
//   @media (max-width: 1199px) {
//     width: 90%;
//     font-size: 12px;
//     line-height: 16px;
//   }
// `
const AssetWrap = styled.div`
  /* height: 224px; */
  /* background-color: red; */
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px 0 0;
  margin-bottom: 24px;
  @media (max-width: 1199px) {
    height: auto;
    padding: 10px 0;
    margin-bottom: 12px;
  }
`
const StyledTitle = styled.div`
  color: #FFF;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
  @media (max-width: 1199px) {
    font-size: 16px;
    line-height: 40px;
  }
`
const PriceTitleSub = styled.div`
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  width: 590px;
  a{
    color: ${(props) => props.theme.fontColor[100]};
    text-decoration: underline!important;
  }
  @media (max-width: 1199px) {
    width: 90%;
    font-size: 12px;
    line-height: 18px;
  }
`
// const TabWrap = styled.div`
//   display: flex;
//   justify-content: center;
//   width: 100%;
//   background: rgba(255, 255, 255, 0.08);
// `
const TabWrapInner = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  width: 72%;
  min-width: 1200px;
  height: 100%;
  /* border-radius: 20px; */
  @media (max-width: 1199px) {
    min-width: 100%;
    /* flex-direction: column; */
    align-items: flex-start;
    flex-wrap: wrap;
  }
`
const flash = keyframes`
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
`;
const TableWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin:16px auto 0;
  width: 72%;
  min-width: 1200px;
  table {
    flex-grow: 1;
  }
  @media (max-width: 1199px) {
    width: calc(100% - 24px);
    min-width:inherit;
    flex-direction: column;
    .leftTable {
      margin-bottom: 5px;
      thead tr {
        height: auto;
      }
      tr {
        padding: 8px 0;
        td {
          height: auto;
          /* line-height: 55px; */
          /* justify-content: center; */
        }
      }
      /* LPshare */
       th:nth-child(6),
      td:nth-child(6){
        width:30px;
        flex:inherit
      }
      /* th:nth-child(3),td:nth-child(3),
      th:nth-child(5),td:nth-child(5){ */
      th:nth-child(3),td:nth-child(3),
      th:nth-child(2),td:nth-child(2){
        /* width:30px;
        flex:inherit */
        display: none;
      }
    }
    td:last-child {
      display: none;
    }
  }
`;
const SortArea = styled.div`
  display:flex;
  align-items: center;
  justify-content:inherit;
  cursor: pointer;
  .specialApy {
        display: flex;
        flex-direction: column;
        justify-content: center;
        div {
          line-height: 20px;
          span {
            display: inline-block;
          }
          &.bottomAPY {
            color: #9195a4;
            font-size: 14px;
            &:first-child {
              margin-left: 5px;
            }
          }
        }
      }
      @media (max-width: 1199px) {
        &.specialApy {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          div {
            line-height: 20px;
            span {
              display: inline-block;
            }
            &.bottomAPY {
              color: #9195a4;
              font-size: 10px;
              &:first-child {
                margin-left: 5px;
              }
            }
          }
        }
      }
`
const ApyGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const Table = styled.table`
  width: max-content;
  border-radius: 16px;
  border-spacing: inherit;
  color: ${(props) => props.theme.colors.black};
  background:${(props) => props.theme.TableBg};
  padding-bottom: 20px;
  @media (max-width: 1199px) {
    width: 100%;
    border-radius: 10px;
  }
  .dark & {
    color: ${(props) => props.theme.colors.white};
    background:${(props) => props.theme.DarkTableBg};
  }
  font-weight: 500;
  thead {
    tr{
      font-size:14px;
      @media (max-width: 1199px) {
        margin:0 10px;
      }
    }
    /* tr:first-child {
      border-bottom: 0;
    } */
    tr:nth-child(1) {
      line-height:54px;
      padding:8px 0;
      border-bottom: 1px solid ${(props) => props.theme.colors.lightTableBorder};
      .dark & {
        border-bottom: 1px solid
          ${(props) => props.theme.colors.darkTableBorder};
      }
      @media (max-width: 1199px) {
        padding:5px 0;
      }
    }
  }
  tr {
    padding:8px 0;
    margin: 0 24px;
    text-align: right;
    height: auto;
    line-height: 82px;
    box-sizing: border-box;
    border-bottom: 1px solid ${(props) => props.theme.colors.lightTableBorder};
    flex-wrap: wrap;
    align-items:center;
    font-weight:600;
    .dark & {
      border-bottom: 1px solid ${(props) => props.theme.colors.darkTableBorder};
    }
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    @media (max-width: 1199px) {
      padding: 0;
      height: auto;
      margin: 0 10px 0;
      /* line-height: 55px; */
      align-items:inherit;
    }
    th,
    td {
      flex: 1;
      &.loadingDetail{
          flex: none;
          flex-basis: 200px;
        }
    }
    th {
      /* font-size: 14px; */
      color:${(props) => props.theme.colors.lightGrey};
      font-weight: normal;
      text-align: right;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      i {
        display: inline-flex;
        flex-direction: column;
        margin-left: 5px;
        &:before {
          content: "";
          display: inline-block;
          height: 0px;
          width: 0px;
          border-right: 4px solid transparent;
          border-left: 4px solid transparent;
          border-bottom: 4px solid ${(props) => props.theme.colors.lightGrey};
          margin-bottom: 2px;
        }
        &:after {
          content: "";
          display: inline-block;
          height: 0px;
          width: 0px;
          border-right: 4px solid transparent;
          border-left: 4px solid transparent;
          border-top: 4px solid ${(props) => props.theme.colors.lightGrey};
        }
        &.active1 {
          &:after {
            border-top: 4px solid ${(props) => props.theme.colors.supply.dark};
          }
        }
        &.active-1 {
          &:before {
            border-bottom: 4px solid
              ${(props) => props.theme.colors.supply.dark};
          }
        }
      }    
      &.switchBtn{
        flex: none;
        flex-basis: 200px;
      }
      @media (max-width: 1199px) {
        display: flex;
        font-size: 13px;
        line-height: 20px;
        align-items: center;
        justify-content: flex-end;
        /* LPshare */
        /* &:nth-child(3){
          display: none;
        } */
        &.specialApy {
          display: flex;
          /* flex-direction: column; */
          align-items: center;
          min-width:110px;
          div {
            line-height: 20px;
            span {
              display: inline-block;
            }
            &.bottomAPY {
              color: #9195a4;
              /* font-size: 12px; */
              &:first-child {
                margin-left: 5px;
              }
            }
          }
        }
        &.switchBtn{
        flex: none;
        flex-basis: 30px;
      }
      }
    }
    td {
      &.specialApy {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        font-size: 14px;
        /* justify-content: center; */
          /* span {
            display: inline-block;
            line-height: 24px;
          } */
          div {
            
            display: flex;
            justify-content: flex-end;
            align-items: center;
            line-height: 24px;
            img{
              display: inline-block;
              width: 15px;
              margin-right: 5px;
            }
            span {
              display: inline-block;
              &.green{
                color:${(props) => props.theme.colors.borrow.dark};
              }
              &.red{
                color:${(props) => props.theme.colors.red};
              }
            }
            &.bottomAPY {
              display: flex;
              justify-content: flex-end;
              align-items: center;
              color:${(props) => props.theme.fontColor[999]};
              font-size: 14px;
              &:first-child {
                margin-left: 5px;
              }
            }
            &.APR {
            font-size: 14px;
            }
          }
      }
      span{
        color:${(props) => props.theme.fontColor[999]};
      }
      .highlight{
          font-size: 16px;
          line-height: 24px;
          color: ${(props) => props.theme.DarkfontColor[100]};
          .dark & {
          color: ${(props) => props.theme.fontColor[100]};
          }
      }
      &.detail{
        flex-basis: 100%;
        height: 0;
        line-height: 54px;
        visibility: hidden;
        font-weight:normal;
        border-radius: 8px;
        /* transition:height ease-in-out .2s; */
        background: ${(props) => props.theme.colors.lightPageBg};
        .dark & {
          background: ${(props) => props.theme.colors.darkPageBg};
        }
      }
      &.switchBtn{
        flex: none;
        flex-basis: 200px;
      }
      .detailShow {
        /* transition:height ease-in-out .2s; */
      }
      @media (max-width: 1199px) {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: 14px;
        /* LPshare */
        /* &:nth-child(3){
          display: none;
        } */
        &.specialApy {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: center;
          div {
            line-height: 20px;
            span {
              display: inline-block;
            }
            &.bottomAPY {
              display: flex;
              justify-content: flex-end;
              align-items: center;
              color: #9195a4;
              font-size: 10px;
              &:first-child {
                margin-left: 5px;
              }
              img{
                display: inline-block;
                width: 15px;
                margin-right: 5px;
              }
            }
          }
        }
        .highlight{
          font-size: 12px;
        }
        /* &.specialApy {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          min-width:110px;
          div {
            line-height: 20px;
            span {
              display: inline-block;
            }
            &.bottomAPY {
              color: #656a7f;
              &:first-child {
                margin-left: 5px;
              }
            }
          }
        } */
        &.detailShow {
          display:flex;
        }
        &.loadingDetail{
          display:flex;
        }
      }
    }
    .firstTh,
    .firstTd {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      text-align: left;
      /* min-width: 215px; */
      width: 150px;
      flex: none;
      position: relative;
      span{
        display: inline-block;
        width: 108px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      @media (max-width: 1199px) {
        width: 75px;
        /* width: auto; */
        flex: none;
        span{
          width: max-content;
        }
      }
    }
    &:last-child {
      border-bottom: 0;
      .detailShow {
        margin-bottom:20px;
        @media (max-width: 1199px) {
          margin-bottom:15px;
        }
      }
    }
  }
`;
const TokenSymbol = styled.a`
  display: inline-block;
  /* min-width: 50px; */
  height: 17px;
  line-height: 17px;
  color: ${(props) => props.theme.DarkfontColor[100]};
  .dark & {
    color: ${(props) => props.theme.fontColor[100]};
  }
  &.TokenSymbolBg {
    width: 50px!important;
    animation: ${flash} 1.2s linear infinite forwards;
    background: #e1e5ee;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 800px 100px;
    position: relative;
  }
  &:after{
      content:'';
      width:16px;
      height:16px;
      position:absolute;
      margin-left:5px;
      margin-top: 2px;
      background-image:url(${Link});
      cursor: pointer;
    }
  @media (max-width: 1199px) {
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content:center;
    margin-bottom:5px;
    &:after{
      width:16px;
      height:16px;
      right: 0;
      /* margin-left:48px; */
      margin-top: 0;
    }
  }
`;
const TokenIconWrap = styled.div`
  width: 32px;
  /* min-width: 32px; */
  height: 32px;
  border-radius: 50%;
  margin-right: 10px;
  @media (max-width: 1199px) {
    width: 26px;
    /* min-width: 26px; */
    height: 26px;
    margin-bottom:5px;
    margin-right:5px;
  }
  &.TokenIconBg {
    border-radius: 50%;
    animation: ${flash} 1.2s linear infinite forwards;
    background: #e1e5ee;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 800px 100px;
    position: relative;
  }
`;
const TokenIcon = styled.img`
  display: block;
  width: 32px;
  height: 32px;
  @media (max-width: 1199px) {
    width: 26px;
    height: 26px;
  }
`;
const ChainIcon = styled.img`
  display: inline-block;
  width: 32px;
  height: 32px;
  @media (max-width: 1199px) {
    width: 26px;
    height: 26px;
  }
`;
// const ReawrdTokenIcon = styled.img`
//   display: inline-block;
//   width: 24px;
//   height: 24px;
//   margin-right:7px;
//   @media (max-width: 1199px) {
//     width: 20px;
//     height: 20px;
//   }
// `;
const SwitchNetwork = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  color: ${(props) => props.theme.colors.supply.dark};
  font-weight:normal;
  line-height: 70px;
  position: relative;
  @media (max-width: 1199px) {
    display: none;
  }
`;
const DetailLink = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  color: ${(props) => props.theme.colors.supply.dark};
  font-weight:normal;
  line-height: 70px;
  position: relative;
  &:after {
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    margin: 0 5px 2px 5px;
    transform: rotate(135deg);
    transform-origin: center;
    transition: all ease-in-out 0.2s;
    border-right: 2px solid ${(props) => props.theme.colors.supply.dark};
    border-top: 2px solid ${(props) => props.theme.colors.supply.dark};
    @media (max-width: 1199px) {
      position: absolute;
      right: -6px;
    }
  }
  &.open {
    &:after {
      transform: rotate(-45deg);
      transform-origin: center;
      margin: 7px 5px 0 5px;
      transition: all ease-in-out 0.2s;
    }
  }
  &.disabled {
    color: ${(props) => props.theme.colors.lightGrey};
    &:after {
      content: "";
      display: inline-block;
      width: 8px;
      height: 8px;
      margin: 0 5px 2px 5px;
      transform: rotate(135deg);
      transform-origin: center;
      transition: all ease-in-out 0.2s;
      border-right: 2px solid ${(props) => props.theme.colors.lightGrey};
      border-top: 2px solid ${(props) => props.theme.colors.lightGrey};
    }
  }
  @media (max-width: 1199px) {
    line-height: 54px;
    span{
      display:none
    }
  }
`;
const Detail = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 26px 20px 30px 26px; */
  padding: 24px;
  box-sizing: border-box;
  gap: 24px;
  @media (max-width: 1199px) {
    padding: 10px;
    flex-basis:100%;
    flex-direction:column;
    gap: 12px;
  }
`;
const Alink = styled.div`
  display: flex;
  /* flex-basis:200px; */
  flex: none;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 4px;
  margin-right: 50px;
  max-width: 350px;
  a {
    position:relative;
    word-spacing:5px;
    color: ${(props) => props.theme.colors.supply.dark};
    text-align:left;
    line-height: 45px;
    &:after{
      content:'';
      width:16px;
      height:16px;
      position:absolute;
      top:15px;
      margin-left:5px;
      background-image:url(${Link});
    }
  }
  @media (max-width: 1199px) {
    max-width: none;
    a{
      display:inline-block;
      text-align:left;
      line-height: 35px;
      &:after{
        top:10px;
        margin-left:5px;
      }
    }
    margin:10px 0 0;
    flex-direction:column;
    justify-content: space-evenly;
    flex-basis:auto;
    order:1;
    width:100%;
    line-height:30px;
  }
`;
// const LinkTo = styled.div`
//   width:14px;
//   height:14px;
//   background-image:url(${Link});
// `;
const SwitchTo = styled.div`
  width:16px;
  height:17px;
  margin-right:12px;
  background-image:url(${Switch});
`;
const SwitchChainIcon = styled.img`
  display: inline-block;
  width: 24px;
  height: 24px;
  @media (max-width: 1199px) {
    width: 24px;
    height: 24px;
  }
`;
const Harvest = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1.5;
  width: 100%;
  /* flex-basis: 400px; */
  padding: 0 20px 20px;
  box-sizing: border-box;
  border-radius: ${(props) => props.theme.borderradius};
  background: ${(props) => props.theme.fontColor[100]};
  .dark & {
    background: ${(props) => props.theme.DarkTableBg};
  }
  text-align: left;
  @media (max-width: 1199px) {
    width:100%;
    margin-bottom:10px;
    flex-basis:auto;
    padding: 0 10px 10px;
  }
`;
const Zap = styled.div`
  width: 100%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  border-radius: ${(props) => props.theme.borderradius};
  color: ${(props) => props.theme.colors.white};
  background: ${(props) => props.theme.supply};
  .mouseOut{
    display: inline-block;
  }
  .mouseIn{
    display: none;
  }
  &:hover{
    .mouseOut{
      display: none;
    
    }
    .mouseIn{
      display: inline-block;
    }
  }
  &.disabled {
    cursor: pointer;
    color: #a1a4b1;
    background: ${(props) => props.theme.colors.lightOpacityGrey};
    .dark & {
      background: ${(props) => props.theme.colors.darkOpacityGrey};
    }
  }
  @media (max-width: 1199px) {
    flex-basis:48%;
  }
`
const Earned = styled.div`
  width: 100%;
  margin: 0;
  border-radius: ${(props) => props.theme.borderradius};
  span {
    display: inline-block;
    line-height: 54px;
  }
  b {
    font-weight: normal;
    color: ${(props) => props.theme.colors.lightGrey};
  }
  div {
    /* font-size:20px; */
    font-family:LendingFont;
    /* height: 40px; */
    /* line-height: 40px; */
  }
  /* @media (max-width: 1199px) {
    div{
      font-size:16px;
    }
  } */
`;
// const EarnedList = styled.div`
//   height: 40px;
//   display:flex;
//   flex-direction: column;
//   justify-content: space-around;
// `
// const HarvestBtn = styled.div`
//   width: 48%;
//   height: 40px;
//   line-height: 40px;
//   text-align: center;
//   border-radius: ${(props) => props.theme.borderradius};
//   margin-top: 54px;
//   font-weight:600;
//   color: #a1a4b1;
//   /* background: ${(props) => props.theme.colors.lightPageBg}; */
//   background: linear-gradient(90deg, #5EAFE7 0%, #6E5DE7 100%);
//   /* .dark & {
//     background: ${(props) => props.theme.colors.darkPageBg};
//   }; */
//   cursor: pointer;
//   pointer-events:all;
//   color: ${(props) => props.theme.colors.white};
//   /* background: ${(props) => props.theme.colors.supply.dark}; */
//   /* .dark & {
//     background: ${(props) => props.theme.colors.supply.dark};
//   } */
//   &.disabled {
//     cursor: pointer;
//     pointer-events:none;
//     color: #a1a4b1;
//     background: ${(props) => props.theme.colors.lightPageBg};
//     .dark & {
//       background: ${(props) => props.theme.colors.darkPageBg};
//     }
//   }
// `;
const PerformAction = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 450px;
  /* flex-basis: 350px; */
  flex: 1.5;
  padding: 0 20px 20px;
  box-sizing: border-box;
  border-radius: ${(props) => props.theme.borderradius};
  background: ${(props) => props.theme.fontColor[100]};
  text-align: left;
  .dark & {
    background: ${(props) => props.theme.DarkTableBg};
  }
  @media (max-width: 1199px) {
    width:100%;
    flex-basis:auto;
    padding: 0 10px 10px;
  }
`;
const StakeTitle = styled.p`
font-size: 16px;
font-weight: 500;
font-style: normal;
  margin: 0;
  color:${(props) => props.theme.DarkfontColor[100]};
  .dark & {
    color:${(props) => props.theme.fontColor[100]};
  }
`;
const ActionBtn = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight:600;
  gap: 10px;
  @media (max-width: 1199px) {
    justify-content: space-between;
  }
`;
const StakeBtn = styled.div`
  /* flex-basis: 150px; */
  flex: 1;
  height: 40px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  border-radius: ${(props) => props.theme.borderradius};
  color: ${(props) => props.theme.colors.white};
  background: ${(props) => props.theme.supply};
  /* background: linear-gradient(90deg, #5EAFE7 0%, #6E5DE7 100%); */
  /* .dark & {
    background: ${(props) => props.theme.colors.supply.dark};
  } */
  &.disabled {
    cursor: pointer;
    pointer-events:none;
    color: #a1a4b1;
    background: ${(props) => props.theme.colors.lightPageBg};
    .dark & {
      background: ${(props) => props.theme.colors.darkPageBg};
    }
  }
  @media (max-width: 1199px) {
    flex-basis:48%;
  }
`;
const UnStakeBtn = styled.div`
  /* flex-basis: 150px; */
  flex: 1;
  height: 40px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  border-radius: ${(props) => props.theme.borderradius};
  background: ${(props) => props.theme.supplyLightBg};
  color: ${(props) => props.theme.supply};
  @media (max-width: 1199px) {
    flex-basis:48%;
  }
`;
// const Enable = styled.div`
//   flex-basis: 100%;
//   height: 40px;
//   line-height: 40px;
//   text-align: center;
//   cursor: pointer;
//   border-radius: ${(props) => props.theme.borderradius};
//   color: ${(props) => props.theme.colors.white};
//   background: ${(props) => props.theme.colors.supply.dark};
//   background: linear-gradient(90deg, #5EAFE7 0%, #6E5DE7 100%);
//   /* .dark & {
//     background: ${(props) => props.theme.colors.supply.dark};
//   } */
//   &.disabled{
//     cursor: not-allowed;
//     pointer-events:none;
//     color: #a1a4b1;
//     background: ${(props) => props.theme.colors.lightPageBg};
//   }
// `;
// const SelectWarp = styled.div`
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   gap: 16px;
//   /* .NetworkName{
//     margin-right: 16px;
//   } */
//   @media(max-width: 1199px) {
//     width: 100%;
//     justify-content: space-between;
//   }
// `
// const NetWorkName = styled.button`
//   display: none;

//   @media(max-width: 1199px) {
//     cursor: pointer;
//     /* border: 1px solid #5FAAE7; */
//     padding:0 10px 0 10px;
//     height:100%;
//     width: 160px;
//     line-height:36px;
//     font-size:14px;
//     font-weight:bold;
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     background: ${(props) => props.theme.colors.lightOpacityGrey};
//     position: relative;
//     margin: 14px 0;

//     b {
//       margin: 0;
//       color: rgb(36,42,71);
//       .dark & {
//         color: #fff;
//       }
//     }
//     .dark & {
//       background: ${(props) => props.theme.colors.darkOpacityGrey};
//     }
//     .l1img {
//       width: 24px;
//       margin-right: 12px;
//     }
//     border-radius:40px;
//   }
// `
const SelectType = styled.button`
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* background: ${(props) => props.theme.colors.lightOpacityGrey}; */
    background: ${(props) => props.theme.colors.darkOpacityGrey};
    position: relative;
    width: 200px;
    padding:0 10px 0 10px;
    color: #9195A4;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    line-height: 36px;
    b {
      margin: 0;
      font-weight: 500;
      color:${(props) => props.theme.colors.white};
      /* .dark & {
        color: #fff;
      } */
    }
    .l1img {
      width: 24px;
      margin-right: 12px;
    }
    /* .dark & {
      background: ${(props) => props.theme.colors.darkOpacityGrey};
    } */
    border-radius:40px;
  @media(max-width: 1199px) {
    cursor: pointer;
    /* border: 1px solid #5FAAE7; */
    padding:0 10px 0 10px;
    width: 160px;
    line-height:36px;
    font-size:14px;
    font-weight:bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: ${(props) => props.theme.colors.lightOpacityGrey};
    position: relative;
    margin: 7px 0;
    b {
      margin: 0;
      font-weight: 700;
      color:${(props) => props.theme.colors.white};
    }
    .dark & {
      background: ${(props) => props.theme.colors.darkOpacityGrey};
    }
    border-radius:40px;
  }
`
const LeftBlock = styled.div`
  display: flex;
  align-items: center;
`
const StyledArrow = styled.div`
  margin-left: 16px;
  width: 0;
  height: 0;
  border: 4px solid transparent;
  margin-bottom: -5px;
  border-top: 4px solid ${(props) => props.theme.colors.white};
  &.re {
    transform: rotate(180deg);
    margin-bottom: 2px;
  }

  /* .dark & {
    border-top: 4px solid #fff;
  } */
`
const NetWorkList = styled.ul`
  z-index: 999;
  position: absolute;
  top: 44px;
  left: 0;
  min-width: 160px;
  background: ${(props) => props.theme.colors.white};
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  &.selectList{
    width: 200px;
    @media(max-width: 1199px) {
      width: auto;
    }
  }
  .dark & {
    background: ${(props) => props.theme.colors.black};
    /* box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.15); */
    box-shadow:0px 16px 32px 0px rgb(255 255 255 / 0.08);
  }
`
const NetWorkItem = styled.li`
  cursor: pointer;
  color: #fff!important;
  display: flex;
  align-items: center;
  /* justify-content: space-around; */
  line-height: 34px;
  padding-left: 15px;
  padding-right: 10px;

  span {
    color: #000;
  }

  span.active,
  &:hover span {
    color: #5EAFE7;
  }
  .dark & {
    span {
      color: #fff;
    }
  }
  img {
    width: 24px;
    margin-right: 12px;
  }
`
const NavLink = styled.a`
  color: ${(props) => props.theme.colors.lightGrey};
  &:hover {
    color: ${(props) => props.theme.colors.lightGrey};
  }
`;
const NoData = styled.div`
  width: 100%;
  height:200px;
  font-size:18px;
  display: flex;
  justify-content: center;
  align-items: center;
  color:#000;
  .dark & {
    color:#fff;
  }
`
export default MarketDistribution;
