import React from "react";
import styled from "styled-components";



export const StyledChart = ({ children }: {
    children: React.ReactNode
  }) => {
    return (
        <StyledChartWrap>
            {children}
        </StyledChartWrap>
    )
}
export const OverSafeMAX = ({ children }: {
    children: React.ReactNode
  }) => {
    return (
        <OverSafeMAXWrap>
            {children}
        </OverSafeMAXWrap>
    )
}
export const StyledBool = ({ children }: {
    children: React.ReactNode
  }) => {
    return (
        <StyledBoolWrap>
            {children}
        </StyledBoolWrap>
    )
}
interface InputWrapProps {
    children: React.ReactNode
    radius?:string
}
export const InputWrap: React.FC<InputWrapProps> = ({ children,radius }) => {
    return (
        <InputWrapBox radius={radius}>
            {children}
        </InputWrapBox>
    )
}
interface InputWrapBoxProps {
    radius?:string
}

const InputWrapBox = styled.div<InputWrapBoxProps>`
    height: 66px;
    border-radius: ${(props) => props.radius ? props.radius : '12px'};
    border: 1px solid #d9dbdd;
    overflow: hidden;
    margin-top: 20px;
    position: relative;

    .dark & {
        border: none;
    }

    @media (max-width: 767px) {
        height: 42px;
        border: 1px solid #d9dbdd;
        margin-top: 10px;
    }
`
const StyledBoolWrap = styled.span`
    margin-left: 22px;
    margin-right: 10px;

    @media (max-width: 767px) {
        margin-left: 10px;
        margin-right: 6px;
    }
`
// change height
const OverSafeMAXWrap = styled.div`
    position: absolute;
    color: #ff6565;
    font-size: 12px;
    font-weight: 400;
    top: 50px;
    right: 0;
    width: 100%;
    background: #fff8f8;
    height: 30px;
    line-height: 30px;
    text-align: center;
`
const StyledChartWrap = styled.div`
    margin-top: 22px;

    @media (max-width: 767px) {
        margin-top: 18px;
    }
`



