const en_US = {
    Price_Impact: 'Price Deviation is {showImpact}',
    Zap: 'Zap',
    Price_Title__sub: 'Asset prices in the Unitus lending protocol come from Chainlink and the price feed service. The price feed service is temporarily maintained by Unitus Team, and will be determined by decentralized governance in the future.',
    Price_Title: 'Price Feed',
    dForce_Report: 'Unitus Report',
    supply_APY: 'APY',
    borrow_APY: 'APY',
    top_Supplied: 'Supplied',
    top_borrowed: 'Borrowed',
    Supplies: 'Supplies',
    Borrows: 'Borrows',
    Theme_Mode: 'Theme Mode',
    topbar_Dashboard: 'Dashboard',
    topbar_Markets: 'Markets',
    topbar_Bridge: 'Bridge',
    Emission: 'Emission',
    BLP: 'BLP',
    topbar_Price: 'Price',
    topbar_Liquidation: 'Liquidation',
    topbar_More: 'More',
    ComingSoon: 'Coming Soon',
    atleast__heyue: 'The transfer amount must be greater than {amount} {token}. ',
    curNetErr_1004: 'Insufficient Liquidity',
    curNetErr_1005: 'Epoch Volume Cap Reached',
    curNetErr_1017: 'Pool Total Cap Reached',
    curNetErr_maxSend: 'Max Send Amount Reached',
    // db
    Integrations__Exchanges_and_Bridge: 'Exchanges and Bridge',
    TextPriceUpdate__tips: 'Update the price to the latest',
    TextPriceUpdate: 'Update',
    markets_closed_1__Borrowings: 'Borrowings',
    markets_closed_1__Reedem_collaterals: 'Reedem collaterals',
    markets_closed_1__Mintings: 'Mintings',
    markets_closed_1: ' paused, try it later.',
    out_of_service: 'Temporarily out of service, please come back later.',
    Total_USX_Circulating_Supply: 'USX Circulating Supply',
    totalMinted: ' Total Minted',
    totalCollateral: 'Total Collateral ',
    Liquidity__swap: 'Liquidity-Swap',
    Liquidity__Total_Debt: 'Total Debt',
    Liquidity__Remaining_Balance: 'Remaining Balance',
    Liquidity__Utilization: 'Utilization',
    Liquidity__Total_Amount: 'Total Amount',
    Liquidity__sDF_Amount: 'sDF Amount',
    Liquidity__Orders: 'Orders',
    Liquidity__USX_Utilized: 'USX Utilized',
    Liquidity__DF_Buybacked: 'DF Buybacked',
    Liquidity__Price: 'USX/DF Price',
    Liquidity__USX_in_Utilization: 'USX in Utilization',
    DB_Source: 'Source',
    Whereabouts: 'Distribution',
    USX_Distribution: 'Liquidity by Networks',
    DF_Multi_chain: 'Liquidity Breakdown',
    Total_Supply__db: 'Market Cap',
    Circulating_Supply: 'Circulating Supply',
    DF_Price: 'DF Price',
    Buy: 'Buy',
    db_Lending: 'Lending',
    db_USX_Suppied: 'USX Supplied',
    db_ETH_Borrowed: 'ETH Borrowed',
    db_Adequacy_Ratio: 'Adequacy Ratio',
    db_USX_Supply_APY: 'USX Supply APY',
    db_ETH_Borrow_APY: 'ETH Borrow APY',
    Protocol_Liquidity__sub: 'Provide DF/USX dex liquidity',
    db_Others: 'Others',
    db_Staked: ' Deposited',
    POO_Treasury: 'dForce Treasury',
    Protocol_Liquidity_1: 'Protocol Liquidity',
    Protocol_Liquidity: 'Protocol Liquidity',
    ETH_Markets: 'ETH Markets',
    DF_Markets: 'DF Token',
    db_DEX_Liquidity: 'DEX',
    db_Bridge_Liquidity: 'Layer2',
    USX_Markets: 'USX',
    menu_Dashboard: 'Dashboard',
    MintSupplyBorrow: 'Mint USX-Supply USX-Borrow ETH',
    MintSupplyBorrow_sub: 'Arbitrage ETH yeild from Lending Markets and earn profits',
    Revenue: 'Annualized Platform Revenue',
    Income: 'Annualized Protocol Income',
    Income_detail: 'Breakdown by Months ',
    Total_Income_30d__detail: 'Including interest spread (borrow interest - supply interest) and profits generated from multiple sources through protocol operations and captured by dForce treasury.',
    Total_Income_30d: 'Annualized Protocol Income',
    Total_revenue_30d: 'Annualized Platform Revenue',
    Total_revenue_30d__detail: 'Including interest fees paid by borrowers and revenue generated from multiple sources through protocol operations. ',
    Total_Value: 'Market Cap',
    Total_USX: 'Total USX',
    Total_Accumulated_Borrow: 'Total Accumulated Borrow',
    Total_Assets: 'Total Assets',
    Protocol_Income: 'Revenue Breakdown',

    // bridge
    atleast_10df: 'The transfer amount must be greater than 10 DF.',
    atleast_5usx: 'The transfer amount must be greater than 5 USX.',
    bridge_routing__text: 'The cross-chain routes are powered by cBridge, dForce Bridge, Layer2 Bridge, and LayerZero.',
    bridge_Fee__text: 'Unitus does not charge any fees, but users have to pay gas fees to facilitate the cross-chain migration of assets.',
    Fee: 'Fee',
    Slippage_Tolerance: 'Slippage Tolerance',
    minutes: 'minutes',
    Estimated_Time: 'Estimated Time of Arrival',
    Minimum_Received: 'Minimum Received',
    Routing: 'Routing',
    TRANSFER: 'TRANSFER',
    APPROVE: 'APPROVE',
    Add_to_MetaMask: 'Add to MetaMask',
    From: 'From',
    bridge__Bridge: 'Bridge',
    To: 'To',
    Send: 'Send',
    Receive__info: 'This amount is estimated based on the current bridge rate and fees.',
    Receive: 'Receive (estimated)',
    Receive_swap: 'Receive',
    curNetErr: 'Insufficient liquidity on {net}. Please try with a lower amount.',
    atleast: 'Minimal send amount is {amount} ',
    Slippage__tips: 'The transaction will fail in the event that the bridging fees increases above the preset percentage during the execution.',
    Routing__info: 'This amount is estimated based on the current bridge rate and fees.',

    // mini pool
    vault__Supply_APR: 'Supply APR',
    vault__Borrow_APR: 'Borrow APR',
    Debt_Ceiling: 'Debt Ceiling',
    Vaults: 'Vault',
    Claim_df: 'Claim:',
    User_Receive: 'User Receive',
    Yields: 'Yields',
    REPAY_USX_AND_WITHDRAW: 'REPAY AND WITHDRAW',
    ADD_COLLATERAL_AND_BORROW: 'SUPPLY AND BORROW',
    ADD_COLLATERAL: 'SUPPLY',
    NOTHING_TO_DO: 'NOTHING TO DO',
    Borrow_fee__tips: 'One-off upfront fee charged upon each loan origination',
    Interest__tips__show: 'Annualized borrow interest rate accrues over time',
    Liquidation_fee__tips: 'Discount a liquidator receives when buying collateral flagged for liquidation',
    Liquidation_Price__tips: 'Collateral Price at which your Position will be Liquidated.',
    LTV__tips: 'Outstanding loan balance divided by total collateral amount. LTV can range between 0 to 100%',
    ltv_popup_tips: 'The maximum borrowing power of a specific collateral specified per collateral and expressed in percentage points.',
    Collateral: 'Collateral',
    Interest: 'Interest',
    Total_Borrowed: 'Total Borrowed',
    Left_to_Borrow: 'Left to Borrow',
    LTV: 'LTV',
    Reward_APR: 'Reward APR',
    My_Open_Position: 'My Open Position',
    Collateral_Supplied: 'Collateral Supplied',
    Collateral_Value: 'Collateral Value',
    Liquidation_Price: 'Liquidation Price',
    Total_Collateral: 'Total Collateral',
    Borrow_fee: 'Borrow Fee',
    Borrow_fee_rate: 'Borrow Fee Rate',
    Liquidation_fee: 'Liquidation Fee',
    Price: ' Price',
    Repay: 'Repay ',
    Pool_Info: 'Vault',
    Borrowed: " Borrowed",
    Supply_Collateral: "Supply Collateral",
    Withdraw_Collateral: "Withdraw Collateral",
    poweredByDebank: 'Powered by Debank',
    YourTotalAssets: 'Your total assets on multi chains: ',
    Mintage: 'Mintage',
    Combined_APY: 'Combined APY',
    connect_wallet_first: 'Please connect your wallet first.',
    YES: 'YES',
    NO: 'NO',
    MAX: 'MAX',
    Amount: 'Amount',
    language: 'EN',
    Markets_title: 'Markets infos',
    LEND: 'LEND',
    ASSETS: 'ASSETS',
    Assets__Yield_Markets: 'YIELD MARKETS',
    Assets__usdx: 'USDx',
    Assets__goldx: 'GOLDx',
    TRADE: 'TRADE',
    FARM: 'FARM',
    Governance: 'GOVERNANCE',
    Governance__vote: 'VOTE',
    Governance__air: 'AIRDROP',
    Governance__forum: 'FORUM',
    Documents: 'Documents',
    Community: 'Community',
    Connect_Wallet: 'Connect Wallet',
    Select: 'Select',
    mainnet_network: 'Note: You are currently connected to Ethereum Mainnet.',
    wrong_network: 'Error: Wrong network, please switch to mainnet.',
    kovan_network: 'Note: You are currently connected to Kovan Testnet.',
    bsc_network: 'Note: You are currently connected to Binance Smart Chain.',
    bsctest_network: 'Note: You are currently connected to Binance Test Smart Chain.',
    heco_network: 'Note: You are currently connected to Huobi ECO Chain.',
    wrong_network_Text: 'Wrong Network',
    // wallet modal
    Connected_with_MetaMask: 'Connected with MetaMask',
    Connected_with_Brave: 'Connected with Brave',
    Connected_with_BitKeep: 'Connected with BitKeep',
    Connected_with_Rabby: 'Connected with Rabby',
    Connected_with_OKX: 'Connected with OKX',
    Connected_with_Coinbase: 'Connected with Coinbase',
    Connected_with_WalletConnect: 'Connected with WalletConnect',
    Connected_with_TrustWallet: 'Connected with Trust Wallet',
    Change: 'Change',
    Disconnect: 'Disconnect',
    Transactions: 'Transactions',
    Clear_all: 'Clear all',
    //home
    //My Assets 
    Assets: 'Assets',
    Available_to_borrow: 'Available to borrow',
    My_Assets: 'My Assets',
    Total_personal_deposits: 'Total Supply',
    Total_personal_loans: 'Total Borrow',
    Collateral_Ratio: 'Adequacy Ratio',
    // Assets_Card
    Supplying_Balance: 'Supplying Balance',
    Borrowing_Balance: 'Borrowing Balance',
    Minting_Balance: 'Mintage Balance',
    Interest_Earned: 'Interest Earned',
    Interest_Accrued: 'Interest Accrued',
    Interest_Accrued_mint: 'Fee Accrued',
    // All Markets
    ALL: 'ALL',
    MainCryptos: 'Main Cryptos',
    Synth: 'Synth',
    LP: 'LP',
    Stable: 'Stable',
    Markets_Assets: 'Assets',
    Market_Size: 'Market Size',
    Mint_Size: 'Market Size',
    Liquidity: 'Liquidity',
    Wallet: 'Wallet',
    SUPPLY: 'Supply',
    BORROW: 'Borrow',
    WITHDRAW: 'Withdraw',
    REPAY: 'Repay',
    MINT: 'MINT',
    minted: ' Minted',
    toolTip: 'Please supply before borrow.',
    // Markets Page
    Multi_Chain_Markets: 'All Markets on All Chains',
    Markets_Total_Assets: 'Total Assets',
    Market_Overview: 'Market Overview',
    All_Markets: 'All Markets',
    Supply_Markets: 'Collaterals',
    Mint_Markets: 'Synth Markets',
    Markets_Total_Supply: 'Total Supply',
    Markets_Total_Collateral: 'Markets Total Collateral',
    Markets_Total_Borrow: 'Total Borrow',
    Markets_Collateralization_ratio: 'Markets Collateralization ratio',
    Markets_Total_Mint: 'Total Mint',
    Accumulated_Borrow: 'Accumulated Borrow',
    Total_Supply: 'Total Supply',
    Total_Borrow: 'Total Borrow',
    Top_Supply: 'Top Markets (Supply)',
    Top_Borrow: 'Top Markets (Borrow)',
    Total_Mint: 'Total Mint',
    Top_Mint: 'Top Markets (Mint)',
    // Mining Page
    Title1: 'Stake BLP to earn UTS',
    Title2: 'Users will be required to supply liquidity for BLP (either DF/UTS or USX/UTS), equivalent to 1% of the total value of their deposit and staked iUSX in US dollars, to qualify for UTS rewards on their deposits and borrowings.',
    TotalStake: 'Total BLP Staked',
    MyBLPStake: 'My BLP Staked',
    Daily_Distribution: 'Daily Distribution',
    Total_Distribution: 'Distributed',
    DailyDistribution_Distributed: 'Daily Distribution / Distributed',
    Remaining: 'Remaining',
    Available_to_claim: 'Available to claim (DF):',
    Claim: 'Claim',
    Claimable: 'Claimable',
    Pending: 'Pending',
    Supply_APY: 'Supply APY',
    Borrow_APY: 'Borrow APY',
    Reward_to_MAX: 'Boost APR',
    Supply: 'Supply',
    Borrow: 'Borrow ',
    Reward: 'Reward',
    Gauge: 'Gauge',
    MiningAssets: 'Assets',
    Paid_Day: 'Daily',
    // Liquidation History
    LiquidationHistory: 'History',
    seizeTokens: 'Seize Tokens',
    repayAmount: 'Repay Amount',
    borrower: 'Borrower',
    liquidator: 'Liquidator',
    timestamp: 'Timestamp',
    blockNumber: 'BlockNumber',
    // Liquidation
    Liquidation: 'Liquidation',
    Liquidate: 'Liquidate',
    liquidation_list: 'Liquidate list',
    Shortfall: 'Shortfall ($)',
    Account: 'Account',
    supplyValue: 'Total Supply($)',
    collateralValue: 'Total Collateral($)',
    borrowedValue: 'Total Borrow($)',
    collateralRatio: 'CollateralRatio',
    Supply_Assets: 'Supply Assets',
    liquidation_amount: 'Amount',
    Borrow_Assets: 'Borrow Assets',
    MAX_Liquidation: 'MAX Liquidation',
    Wallet_Balance: 'Wallet',
    RequestedAmountClose: 'RequestedAmountClose',
    // supply
    ENABLE: 'ENABLE ',
    ENABLING: 'ENABLING…',
    cancelled_by_user: 'Action cancelled by user',
    Home: 'Back',
    Supply_Balance: 'Total Supply',
    Health_Factor: 'Adequacy Ratio',
    Supplied: ' Supplied',
    supply_notes: 'Please fill in or select the proportion bar below to enter the amount of asset you want to supply.',
    Health_Factor_tips: 'Keep it >1 to avoid liquidation.',
    supply_apy: 'Supply APY',
    supply_apy_tips: 'The Annual Percentage Yield (APY) for supplying in each market',
    loan_to_value: 'LTV (Loan to Value %)',
    loan_to_value_tips: 'LTV ranges from 0 to 100%, representing the value of borrowed asset against the value of collateral.',
    price: ' Price',
    balance: ' Balance',
    borrow_apy_space: ' Borrow APY',
    as_collateral: 'As Collateral',
    as_collateral__how: 'Whether the asset can be used as collateral.',
    as_collateral__tips: 'Closing this collateral asset will render your position undercollateralized.',
    as_collateral__tips__notopen: 'The asset can not be used as collateral.',
    Collateral_Balance_tips: 'Total number of current asset users mortgaged',
    Available_to_Withdraw: 'Safe to Withdraw',
    isExecutedTips: 'This asset is required to support your borrowed assets. Either repay borrowed assets, or supply another asset as collateral.',
    supplied_tokens: 'Please withdraw your deposit before borrowing the same asset.',
    withdraw__warning: '{params} left before we reach the supply cap',
    //borrow
    Available_to_borrow_tips: 'The remaining amount of stablecoin that can be borrowed with this segregated asset as collateral.',
    safe_max: 'SAFE MAX',
    Borrow_Balance: 'Total Borrow',
    borrow_notes: 'Please fill in or select the proportion bar below to enter the amount of asset you want to borrow.',
    borrow_apy: 'Borrow APY',
    borrow_apy_tips: 'The Annual Percentage Yield (APY) for borrowing in each market',
    borrowed: ' Borrowed',
    max_available_borrow: 'MAX  Borrow Value',
    safe_available_borrow: 'SAFE  Borrow Value',
    max_available_borrow_tips: 'Maximal borrowing amount of this asset',
    safe_available_borrow_tips: 'Maximal safe borrowing amount of this asset',
    Borrow_Factor: 'Borrow Factor',
    Borrow_Factor_tips: 'Borrow non-stablecoin asset will likely be subject to a discount on max LTV',
    Available_to_Borrow: 'Safe to Borrow',
    Available_to_Borrow_a: 'Available to Borrow',
    borrowed_tokens: 'Please repay your loan or burn the synth before supplying the same asset as collateral.',
    borrow__warning: '{params} left before we reach the borrow cap',
    //mint
    Available_to_Mint: 'Safe to Mint',
    Borrowing_Power: 'Borrowing Power',
    Segregated_Asset: 'Segregated Asset',
    mint_apy: 'Mintage APY',
    mint_notes: 'Please fill in or select the proportion bar below to enter the amount of asset you want to mint.',
    BURN: 'BURN',
    mint_apy_tips: 'The annual percentage yield (APY) of each synth',
    before_mint_tokens: 'Please withdraw your collateral before minting the same asset.',
    //faq
    faq: 'FAQ',
    //footer
    Documentation: 'Documentation',
    Audit: 'Audit',
    FAQ: 'FAQ',
    Forum: 'Forum',
    // spe
    enable_first: 'You must enable {token} before repaying for the first time.',
    enable_first__supply: 'You must enable {token} before supplying for the first time.',
    enable_first__mint: 'You must enable {token} before burning for the first time.',

    // menu
    menu__Synths: 'Synths',
    menu_Legacy: 'Legacy',
    menu_Analytics: 'Analytics',
    menu_Token_Terminal: 'Token Terminal',
    menu_Loan: 'Loan',
    menu_Sec: 'Security',
    menu_Audit_Reports: 'Audit',
    menu_Buy_Bounty: 'Bounty',
    menu_Markets: 'Markets',
    menu_Lend: 'Lend',
    menu_Synths: 'Synths',
    menu__General: 'General',
    menu__Synthetic: 'Synthetic',
    menu__Stocks: 'Stocks',
    menu_Staking: 'Stake',
    menu_Trade: 'Trade',
    menu_Earn: 'Earn',
    menu_Farm: 'Farm',
    menu_Governance: 'Governance',
    menu_Delegate: 'Delegate',
    menu_Proposals: 'Vote',
    menu_markets: 'Yield',
    menu_usdx: 'USDx',
    menu_goldx: 'GOLDx',
    menu_Vote: 'On-chain Vote',
    menu_StakeDF: 'Stake',
    menu_LockDF: 'Lock-up',
    menu_Tutorials: 'Forum',
    menu_Liquidation: 'Liquidation',
    menu_Stake: 'Liquidity',
    menu_Lending: 'Lending',
    menu_Tools: 'Tools',
    menu_Price: 'Price',
    menu_Bridge: 'Bridge',
    menu_Docs: 'Document',
    menu_Integrations: 'Integrations',

    // trade
    SEND: 'SEND',
    fee_tips: 'Unitus does not charge any fees, but users have to pay gas fees to facilitate the cross-chain migration of assets.',
    min_swap_notes: 'The min swap amount must be greater than {amount} {symbol}.',
    max_swap_notes: 'The max swap amount must be less than {amount} {symbol}.',
    RECEIVE: 'RECEIVE',
    Amount: 'Amount',
    Balance: ' Balance',
    MAX: 'MAX',
    search_placeholder: 'Search name or address',
    no_date: 'No date',
    Enable_Gas_Token: 'Enable Gas Token',
    Enable_Gas_Token_tips: 'Activate Gas Token to reduce transaction costs!',
    Advanced: 'Advanced',
    Gas_Fee: 'Gas Fee (GWEI)',
    slippage: 'Limit additional price slippage',
    Minimum_received: 'Minimum received',
    Standard: 'Standard',
    Fast: 'Fast',
    Instant: 'Instant',
    SWAP: 'SWAP',
    No_Supported: 'No Supported',
    Unlocking: 'Unlocking...',
    Insufficient_Balance: 'Insufficient Balance',
    No_liquidity: 'Insufficient liquidity',
    Approve: 'Approve',
    crossTokenTooLess: 'Require amount doesn\'t meet the minimal limit.',
    crossTokenTooMore: 'Require amount doesn\'t meet the maximal limit.',
    // stake
    Dual_Rewards: 'Dual Rewards',
    Dual_Rewards__sub: 'Stake LP token to earn DF&DODO mining rewards',
    Single_Reward: 'Single Reward',
    Single_Reward__sub: 'Stake LP token to earn DF mining reward',
    Single_Reward__iusx: 'Stake token to earn mining reward',
    Stake_Title: 'BLP (Bond Liquidity Provision)',
    Title__sub: 'Users will be required to supply liquidity for BLP (either DF/UTS or USX/UTS), equivalent to 1% of the total value of their deposit and staked iUSX in US dollars, to qualify for UTS rewards on their deposits and borrowings.',
    Noemissions: '$0 required to be eligible',
    eligible: 'required to be eligible',
    Eligibleforemissions: 'Eligible for emissions',
    All_Chains: 'All Chains',
    AllType: 'All Types',
    YieldAggregator: 'Yield Aggregator',
    LiquidityMining: 'Liquidity Mining',
    _Vault: 'Vault',
    Chain: 'Chain',
    APY: 'APY',
    APR: 'APR',
    MyBoost: 'My Boost',
    AssetsBoost: 'Assets Boost',
    ended: 'Ended',
    normal: 'Normal',
    comingSoon: 'Soon',
    Zap: 'Zap',
    AcquireUTS: 'Acquire UTS',
    Pancake: 'Pancake',
    dmm: 'dmm',
    dodo: 'DODO',
    ShowEndedPools: 'Show Ended Pools',
    noData: 'No Data',
    Combined_APY: 'Combined APY',
    Mining: 'Mining',
    Yielding: 'Yielding',
    Simply: 'Simply',
    Earned: 'Earned',
    zap: 'zap',
    zapintoLP: 'zap into LP',
    Total_Staked: 'Total Staked',
    LPShare: 'LP Share',
    MyStaked: 'My Staked',
    Get: 'Get ',
    View_Contract: 'View Contract',
    HARVEST: 'HARVEST',
    HARVESTING: 'HARVESTING',
    STAKE: 'STAKE',
    STAKING: 'STAKING',
    Staked: 'Staked: ',
    _Balance: 'Balance: ',
    UNSTAKE: 'UNSTAKE',
    UNSTAKING: 'UNSTAKING',
    Stake_Farming: 'Stake Farming',
    Staking: 'Staking',
    Detail: 'Detail',
    Switch_to: 'Switch to ',
    markets_closed: 'Outside trading hours in Nasdaq, Reedem collaterals, continue Borrowings and Mintings are not allowed.',
    lock_up: 'Lock-up period for pledged assets will end by:',
    // Liquidity
    Liquidity_Title: 'Stake iUSX to earn UTS',
    Liquidity_Title__sub: 'Users will be required to supply liquidity for BLP (either DF/UTS or USX/UTS), equivalent to 1% of the total value of their deposit and staked iUSX in US dollars, to qualify for UTS rewards on their deposits and borrowings.',
    // stakeDF
    _Title: 'DF Staking with no lock-up requirements',
    _SubTitle: 'Free Staking features lower yield and less voting weights than Lock-up Staking, but you can unstake at any time.',
    LearnMore: 'Learn More',
    Market_Staked_DF: 'Total Free-Staked',
    // Market_Staked_DF_tips:'Total Staked DF / Total Circulated DF (%)',
    As_of_total: 'As of total %',
    My_Staked_DF: 'My Staked DF',
    Total_Circulating_sDF: 'Total Circulating sDF',
    sDF_DF: 'sDF : DF',
    Staking_APY: 'Staking APY',
    enable_first__stake: 'You must enable {token} before staking for the first time.',
    Stake: 'Stake ',
    Unstake: 'Unstake ',
    UNSTAKE_sDF: 'UNSTAKE',
    Available_to_Unstake: 'Available to Unstake',
    Receive_DF: "Receive : ",
    Receive_sDF: 'Receive : ',
    brewing_info1: 'You have staked ',
    brewing_info2: ' DF',
    lock_up_tips: 'DF can be locked up to 4 years. Longer period goes with higher weight in voting and staking yields.',
    lock_up_Staking: 'Lock-up Staking',
    have_sDF: 'You already have sDF,',
    lock_up: 'Lock up',
    // lockveDF
    Back: 'Back',
    DF_veDF_Title: 'Choose a lock-up period for DF Staking',
    DF_veDF_SubTitle: 'Users are able to choose how long to lock up your DF – the longer you lock, the higher yield and more voting weights. ',
    Total_Locked: 'Total Locked',
    Total_DF_Locked_Tips: 'Total Locked DF / Total Staked DF',
    veDF_Total_Supply: 'Total Circulating veDF',
    lock_As_of_total: 'As of total %',
    Average_Lock_Time: 'Average Lock-up Peroid',
    veDF_Holder_APY: 'APY for Lock-up Staking',
    APYfor6: 'APR (6 Months):',
    APYfor12: 'APR (1 Year):',
    APYfor24: 'APR (2 Years):',
    APYfor48: 'APR (4 Years)',
    enable_first__stakeAndlock: 'Enable your {token} before staking for the first time.',
    enable_first__lock: 'Grant your approval for the protocol to transfer your {token} before staking for the first time.',
    Select_lock_title: 'Fill in the amount and select lock-up peroid',
    Re_create_Lock: 'Re-stake',
    Re_create_Lock_tips: 'You can top up your current staking with earned DF, for which current lock-up period will apply.',
    Choose_lock_time: 'Select lock-up period (APY: ',
    ApyTips: 'Free Staking APY + Lock-up Staking APY',
    LockTips: 'New lock-up period must be longer than the current one.',
    Unlock_by: 'Unlock by: ',
    Receive_veDF: 'You will receive : ',
    STAKE_AND_LOCK: 'STAKE AND LOCK',
    CREATE: 'CREATE',
    ADD_AMOUNT: 'Top-up',
    EXPEND_TIME: 'Extend Lock-up',
    RECREATE_LOCK: 'RESTAKE',
    CLAIM: 'CLAIM',
    CLAIM_AND_UNSTAKE: 'CLAIM AND UNSTAKE',
    CLAIM_DF_TIPS: 'Selecting this option will claim the token and unwrap to DF, otherwise continue to enjoy free staking yield',
    claim_and_unwrap_DF: 'To claim and unwrap to DF',
    EXIT: 'EXIT',
    ExitEnableTips: 'Enable {token} before unstaking for the first time.',
    My_Staking_Dashboard: 'My Lock-up Dashboard',
    Your_DF_Locked: 'My Locking DF',
    My_Apy: 'My APY',
    Start_Time: 'Start Time',
    Unlock_Time: 'Unlock by',
    Unlockby_tips: 'Lock-up Staking will be automatically switched to Free Staking upon expiration.',
    Staked_veDF: 'My veDF Balance',
    Available_to_Claim: 'Available to Claim',
    // Proposals
    Delegate: 'Delegate',
    veDFBalance: 'veDF Balance',
    ProposalsList: 'Proposals list',
    Recipient: 'Recipient',
    Check_the_list: 'Check the list',
    Self: 'Self',
    // delegateList
    d_Title: 'Delegation of Governance',
    d_SubTitle: 'Anyone holding veDF could either vote directly or delegate to others who want to particpate in governance on their behalf.',
    list_title: 'Select the address you want to delegate to',
    Delegating_To: 'Delegating To',
    change: 'Redelegate',
    Rank: 'Rank',
    Address: 'Address',
    VoteWeight: 'Vote Weight',
    TotalVotes: 'Total Votes',
    // delegate modal
    ChooseDelegationType: 'Delegate vote',
    sec1: 'Activate your DF for participation in governance voting.',
    Activate: 'Activate',
    sec2: 'Delegate your veDF to one of dForce governors by either selecting the address from the list or entering his/her address directly.',
    WalletAddress: 'Wallet Address',
    // Price
    PriceTitle: 'Price Feed',
    PriceTitle__sub: 'Liquidation on Unitus is open to everyone who repays defaulted loans for borrowers and receive a nice bonus for doing so, which helps to keep the system solvent and robust. ',
    Select_a_Token: 'Select a token',
    Select_Source_Chain: 'Select Source Chain',
    Select_Target_Chain: 'Select Target Chain',
    not_support_token_pair: 'No route found for this swap.',
    // LSR
    Mint: 'Mint',
    Title: 'Mint (or redeem) USX against supported stablecoins at 1:1 rate.',
    Swap_Tokens: 'MINT / BURN',
    _Send: 'Send',
    _Receive: 'Receive',
    Fee: 'Fee',
    Reserves: 'Reserves',
    Reserves_new: 'Liquidity',
    Collaterals: 'Collaterals',
    Debt_Ceiling: 'Debt Ceiling',
    Mintage: 'Minted (UR)',
    Mintage_new: 'Reserves (UR)',
    Ethereum: 'Ethereum',
    Arbitrum: 'Arbitrum',
    Optimism: 'Optimism',
    BSC: 'BSC',
    Polygon: 'Polygon',
    // swap
    swap__Swap: 'Swap',
    Insufficient_Liquidity: 'Insufficient Liquidity',
    swap_Price: ' Price',
    Advanced_settings: 'Advanced settings',
    Minimum_Received: 'Minimum Received',
    Gas_Price: 'Gas Price',
    Gas_Normal: 'Normal',
    Gas_Fast: 'Fast',
    Gas_Instant: 'Instant',
    swap__title: 'Peer-to-Peer marketplace with aggregated liquidity across different platforms and the best price.',
}
export default en_US;