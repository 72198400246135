import React from 'react'
import styled from 'styled-components'
import ReactTooltip from 'react-tooltip';
import { useIntl } from 'react-intl'
import { useWeb3React } from '@web3-react/core'
import {
  format_bn__new as format_bn,
  format_num_to_K,
  formatTimeStamp,
  deconstruction_wallet_source,
  postDataToServer,
  useQuery, DFApproveXArray
} from "utils"
// images
import tooltip from 'assets/supply/tooltip.svg'
import increase from 'assets/supply/increase.svg'
import increase_green from 'assets/supply/increase-green.svg'


interface FancyValueProps {
  i18n_text: string,
  value?: string,
  showIcon?: string,
  token?: string,
  collateral?: boolean,
  collateral_bool?: string,
  canbe_executed?: string,
  tobe?: any,
  tobe__tab2?: any,
  isChoosen__supply__tab?: boolean,
  borrow_checked?: boolean,
  contract?: any,
  dlTokenAddr?: any,
  is_green?: boolean,
  isTitle?: boolean,
  height?: string
  isRed?: boolean,
  isRed_right?: boolean,
  isPower?: boolean,
}

const ChangeableValue: React.FC<FancyValueProps> = ({
  i18n_text,
  showIcon,
  token,
  value,
  collateral,
  collateral_bool,
  canbe_executed,
  tobe,
  tobe__tab2,
  isChoosen__supply__tab,
  borrow_checked,
  contract,
  dlTokenAddr,
  is_green,
  isTitle,
  height,
  isRed,
  isRed_right,
  isPower
}) => {
  const {
    connector,
    account,
    chainId,
    provider,
    ENSName,
    isActive,
    isActivating,
  } = useWeb3React();
  const intl = useIntl()
  const fmt = intl.formatMessage
  // console.log(value)

  const makeItAsCollateral = () => {
    console.log(contract)
    contract.methods[collateral_bool === 'true' ? 'exitMarkets' : 'enterMarkets']([dlTokenAddr]).send({ from: account })
      .on('transactionHash', function (hash: string) {
        console.log('transactionHash: ', hash)
      })
      .on('receipt', function (receipt: any) {
        console.log('receipt: ', receipt)
      })
  }

  return (
    <StyledBalance isTitle={isTitle} height={height}>
      <StyledTitle isTitle={isTitle}>
        {token && <>{token}</>}
        <span>{fmt({ id: i18n_text })}</span>
        {
          showIcon &&
          <>
            <a data-tip data-for={showIcon} style={{ marginLeft: '5px' }}>
              <img src={tooltip} alt='' style={{ marginBottom: '4px' }} />
            </a>
            <ReactTooltip
              id={showIcon}
              place="top"
              type="light"
              effect="float"
              className="tooltip"
              textColor={'#9195A4'}
              borderColor={'#E8ECEF'}
              border={true}
            >
              <span>{showIcon}</span>
            </ReactTooltip>
          </>
        }
      </StyledTitle>

      {
        // value? 
        isChoosen__supply__tab
          // 左侧 supply / borrow
          ? tobe
            ? <>
              <StyledValue>
                <span style={{ color: '#9195A4' }}>{value ? value : '...'}</span>
                <StyledArrow isRed={isRed} />
                <span>
                  {isPower ? tobe : tobe === '0.00' ? '...' : tobe}
                </span>
              </StyledValue>
            </>
            : value
              ? <StyledValue>{value}</StyledValue>
              : <StyledDot>...</StyledDot>

          // 右侧 withdraw / repay
          : tobe__tab2
            ? <>
              <StyledValue>
                <span style={{ color: '#9195A4' }}>{value ? value : '...'}</span>
                <StyledArrow isRed={isRed_right} />
                <span>
                  {isPower ? tobe__tab2 : tobe__tab2 === '0.00' ? '...' : tobe__tab2}
                </span>
              </StyledValue>
            </>
            : value
              ? <StyledValue>{value}</StyledValue>
              : <StyledDot>...</StyledDot>
        // : <StyledDot>...</StyledDot>
      }
    </StyledBalance>
  );
};


interface StyledArrowProps {
  isRed?: boolean
}
const StyledArrow = styled.div<StyledArrowProps>`
  display: inline-block;
  margin: 0 auto;
  width: 12px;
  height: 4px;
  background-color: ${(props) => (props.isRed ? '#FA4437' : '#05C7A0')};
  position: relative;
  margin-left: 4px;
  margin-right: 6px;
  margin-bottom: 4px;
  &::after {
    content: '';
    position: absolute;
    right: -10px;
    border: 6px solid transparent;
    /* border-left: 6px solid red; */
    border-left: 6px solid ${(props) => (props.isRed ? '#FA4437' : '#05C7A0')};
    top: -4px;
  }
`
const StyledIncrease = styled.img`
  width: 10px;
  margin-bottom: 2px;
  margin-left: 5px;
  margin-right: 5px;
`
interface StyledBalanceProps {
  isTitle?: boolean,
  height?: string
}
const StyledBalance = styled.div<StyledBalanceProps>`
  display: flex;
  justify-content: space-between;
  color: ${(props) => props.theme.global_grey};
  line-height: ${(props) => props.isTitle ? '24px' : props.height ? props.height : '46px'};
  font-size: ${(props) => props.isTitle ? '18px' : '16px'};
  font-weight: ${(props) => props.isTitle ? 'bold' : '500'};
  margin: '0 0 34px';
  @media (max-width: 1119px) {
    font-size: 13px;
    line-height: 30px;
    margin: '0 0 20px';
  }
`
interface StyledTitleProps {
  isTitle?: boolean
}
const StyledTitle = styled.div<StyledTitleProps>`
  font-weight: ${(props) => props.isTitle ? 'bold' : '400'};
`
const StyledDot = styled.div`

`
const StyledValue = styled.div`
  color: ${(props) => props.theme.operationPage.light.mainBlack};
  .dark & {
    color: ${(props) => props.theme.operationPage.dark.mainBlack};
  }
`

interface ValueHintProps {
  darkMode?: boolean;
}



const StyledIcon = styled.span.attrs({ role: "img" })`
  font-size: 32px;
`;

const LabelWrapDisplay = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;

const ValueHint = styled.span<ValueHintProps>`
  cursor: default;
  display: block;
  position: absolute;
  top: -8px;
  right: -8px;
  background: #ae0e463b;
  color: ${(props) => props.theme.colors.primary.main};
  line-height: 16px;
  font-weight: bold;
  font-size: 12px;
  border: 1.5px solid ${(props) => props.theme.colors.primary.main};
  border-radius: 100px;
  padding: 0px 5px 1px 5px;
  opacity: 0.4;

  &:hover {
    opacity: 1;
    z-index: 10;
  }
`;

export default ChangeableValue;
