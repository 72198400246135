import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import { useIntl } from "react-intl";
import numeral from "numeral";
import BigNumber from "bignumber.js";
import { ethers } from 'ethers'
import useLending from "hooks/useLending";
import { useWeb3React } from '@web3-react/core'
import { bnToDec, formatNumberNotCarry } from "utils";
import Modal from "components/ModalAction";
import ModalTitle from "components/ModalAction/components/ModalTitle";
import ModalContent from "components/ModalAction/components/ModalContent";

export interface ModalProps {
  isOpen?: boolean;
  onDismiss?: () => void;
  symbol: string;
  poolSymbol: string;
  address:string;
  action: string;
  assetsIcon: string;
  balance: string;
  MyStaked: string;
  poolType:string;
  isApproved?:boolean;
  allowance?:string;
  // rewardRate: string,
  decimals: number;
  add_Notifies: any;
  update_Notifies: any;
  del_Notify: any;
  setNowNotifies: React.Dispatch<React.SetStateAction<any>>;
  setShow__cancel: React.Dispatch<React.SetStateAction<boolean>>
}

const StakeModal: React.FC<ModalProps> = ({
  isOpen,
  onDismiss,
  symbol,
  poolSymbol,
  address,
  action,
  assetsIcon,
  balance,
  MyStaked,
  poolType,
  isApproved,
  allowance,
  // rewardRate,
  decimals,
  add_Notifies,
  update_Notifies,
  del_Notify,
  setNowNotifies,
  setShow__cancel
}) => {
  const {
    connector,
    account,
    chainId,
    provider,
    ENSName,
    isActive,
    isActivating,
  } = useWeb3React();
  const lending = useLending();
  const intl = useIntl();
  const fmt = intl.formatMessage;
  const [selectTab, setSelectTab] = useState<string>("");
  // const [symbol, setSymbol] = useState<string>('')
  // const [_poolSymbol, set_poolSymbol] = useState<string>('')
  // const [assets_icon, setAssets_icon] = useState<string>('')
  const [ENABLE, setENABLE] = useState<string>('ENABLE')
  const [_allowance, set_Allowance] = useState<string>('0')
  const [stakeValue, setStakeValue] = useState<string>('')
  const [originalStakeValue, setOriginalStakeValue] = useState<BigNumber>(new BigNumber(0))
  const [disabledStakeBtn, setDisabledStakeBtn] = useState<boolean>(false)
  const [STAKE, setSTAKE] = useState<string>('STAKE')
  const [isMax, setisMax] = useState(false)
  const [unstakeValue, setUnstakeValue] = useState<string>('')
  const [originalUnStakeValue, setOriginalUnStakeValue] = useState<BigNumber>(new BigNumber(0))
  const [disabledUnStakeBtn, setDisabledUnStakeBtn] = useState<boolean>(false)
  const [UNSTAKE, setUNSTAKE] = useState<string>('UNSTAKE')

  const getAllowance = useCallback(async(_symbol:string,_address:string) => {
    try {
      const _allowance: string = await lending.contracts.stake[`${_symbol}`].methods
        .allowance(account, _address)
        .call();
      // console.log("try: ",_symbol, _allowance)
      set_Allowance(_allowance)
      if(new BigNumber(_allowance).eq(0)){
        setENABLE(fmt({ id: "ENABLE" }));
        return;
      }
      if(new BigNumber(stakeValue).multipliedBy(new BigNumber(10).pow(18)).gt(new BigNumber(_allowance))){
        setENABLE(fmt({ id: "ENABLE" }));
      }else{
        setENABLE(fmt({ id: "STAKE" }));
      }

    } catch (e) {
      console.log("catch: eeeee",e)
      set_Allowance('0')
      setENABLE(fmt({ id: "ENABLE" }));
    }
  },[account,lending?.contracts?.lockDF?.DF,stakeValue])
  useEffect(() => {
    account && symbol && getAllowance(symbol,address)
  }, [account,lending?.contracts,stakeValue,symbol,address])
  // 打开 modal 初始化 赋值 allowance
  useEffect(() => {
    allowance && set_Allowance(allowance)
  },[allowance,isOpen])
  useEffect(() => {
    isOpen && symbol && getAllowance(symbol,address)
  },[isOpen,symbol,address])
  const InputStakeChange = (value: string) => {
    setisMax(false)
    value = value.trim();
    var reg_ZH_CN = new RegExp("[\\u4E00-\\u9FFF]+", "g");
    var reg_ABC = /[a-z]/g;
    // 限制只可输入小数点后6位
    let quantityScale = "6";
    let reg_maxLength = new RegExp(
      "^(0|[1-9][0-9]*)(\\.[0-9]{0," + quantityScale + "})?$"
    );
    // 限制只可输入小数点后6位
    if (reg_ZH_CN.test(value)) {
      return console.log("不可以输入中文");
    }
    if (reg_ABC.test(value)) {
      return console.log("English ABC prohibited");
    }
    if (!reg_maxLength.test(value) && value !== "") {
      return console.log(
        "The maximum number of digits after the decimal point is exceeded"
      );
    }
    // if (bnToDec(new BigNumber(balance), decimals,4) === 0) {
    if (new BigNumber(balance).eq('0')) {
      return false
    }
    let MaximumOverflow = new BigNumber(value)
      .multipliedBy(new BigNumber(10).pow(new BigNumber(decimals)))
      .gt(new BigNumber(balance));
    if (MaximumOverflow) {
      StakeMax()
    } else {
      setStakeValue(value)
      setOriginalStakeValue(new BigNumber(value).multipliedBy(new BigNumber(10).pow(new BigNumber(decimals))))
    }
  }
  const StakeMax = () => {
    setisMax(true)
    // if (bnToDec(new BigNumber(balance), decimals,4) === 0) {
    if (new BigNumber(balance).eq('0')) {
      setStakeValue('')
    } else {
      setStakeValue(numeral(bnToDec(new BigNumber(balance), decimals, 6)).format('0.000000'))
      setOriginalStakeValue(new BigNumber(balance))
    }
  }
  const InputUnStakeChange = (value: string) => {
    setisMax(false)
    value = value.trim();
    var reg_ZH_CN = new RegExp("[\\u4E00-\\u9FFF]+", "g");
    var reg_ABC = /[a-z]/g;
    // 限制只可输入小数点后6位
    let quantityScale = "6";
    let reg_maxLength = new RegExp(
      "^(0|[1-9][0-9]*)(\\.[0-9]{0," + quantityScale + "})?$"
    );
    // 限制只可输入小数点后6位
    if (reg_ZH_CN.test(value)) {
      return console.log("不可以输入中文");
    }
    if (reg_ABC.test(value)) {
      return console.log("English ABC prohibited");
    }
    if (!reg_maxLength.test(value) && value !== "") {
      return console.log(
        "The maximum number of digits after the decimal point is exceeded"
      );
    }
    // if (bnToDec(new BigNumber(MyStaked), decimals,4) === 0) {
    if (new BigNumber(MyStaked).eq('0')) {
      return false
    }
    let MaximumOverflow = new BigNumber(value)
      .multipliedBy(new BigNumber(10).pow(new BigNumber(decimals)))
      .gt(new BigNumber(MyStaked));
    if (MaximumOverflow) {
      UnStakeMax()
    } else {
      setUnstakeValue(value)
      setOriginalUnStakeValue(new BigNumber(value).multipliedBy(new BigNumber(10).pow(new BigNumber(decimals))))
    }
  }
  const UnStakeMax = () => {
    setisMax(true)
    // if (bnToDec(new BigNumber(MyStaked), decimals,4) === 0) {
    if (new BigNumber(MyStaked).eq('0')) {
      setUnstakeValue('')
    } else {
      setUnstakeValue(numeral(bnToDec(new BigNumber(MyStaked), decimals, 6)).format('0.000000'))
      setOriginalUnStakeValue(new BigNumber(MyStaked))
    }
  }


  const approveHandler = useCallback(async (e: any, poolType:string, spenderAddress: string, symbol: string,poolSymbol:string) => {
    switch (poolType) {
      case 'single':
        await approveHandlerSingle(e, spenderAddress, symbol,poolSymbol)
        break;
      case 'iToken':
        await approveHandlerSingle(e, spenderAddress, symbol,poolSymbol)
        break;
      default:
        break;
    }
  }, [lending, account])

  const approveHandlerSingle = useCallback(async (e: any, spenderAddress: string, symbol: string,poolSymbol:string) => {
    if (lending && account) {
      setENABLE("ENABLING")
        lending.contracts.stake[`${symbol}`] && lending.contracts.stake[`${symbol}`].methods
          .approve(spenderAddress, ethers.constants.MaxUint256)
          .send({ from: account })
          .on("transactionHash", function (hash: string) {
            console.log("transactionHash: ", hash);
            add_Notifies({
              state: "pedding",
              action: "Enable " + poolSymbol,
              transactionHash: hash,
              time: new Date().getTime()
            }, setNowNotifies, account as string, chainId as number);
          })
          .on("receipt", async(receipt: any)=>{
            console.log("receipt: ", receipt);
            await getAllowance(symbol,spenderAddress);
            update_Notifies(receipt, setNowNotifies, account as string, chainId as number);
            setTimeout(async () => {
              await del_Notify(receipt, setNowNotifies);
            }, 3000);
            // setENABLE("ENABLE")
          })
          .on("error", function (error: any) {
            console.log("error", error);
            setShow__cancel(true);
            setTimeout(() => {
              setShow__cancel(false);
            }, 3000);
            setENABLE("ENABLE")
          })
          .catch((error: { toString: () => string | string[]; message: any; }) => {
            setENABLE("ENABLE")
            console.log("error", error);
            if (!error.toString().includes("-32601")) {
              return console.log(error)
            }
          })
      }
  }, [lending, account])
  
  const stakeSingle = useCallback(async () => {
    if (lending && account) {
      if (!Number(stakeValue)) {
        return false
      }
      setSTAKE('STAKING')
      setDisabledStakeBtn(true)
      lending.contracts.stake[`r${symbol}`] &&
        lending.contracts.stake[`r${symbol}`]
          .methods.stake(originalStakeValue.toString()).send({ from: account })
          .on('transactionHash', function (hash: string) {
            setStakeValue('')
            onDismiss && onDismiss()
            console.log(hash)
            add_Notifies({
              state: "pedding",
              // action: "Stake " + formatNumberNotCarry(originalStakeValue.toNumber(),5,5) + ' ' + symbol,
              action: "Stake " + stakeValue + ' ' + poolSymbol,
              transactionHash: hash,
              time: new Date().getTime()
            }, setNowNotifies, account as string, chainId as number);
          })
          .on('confirmation', function (_confirmationNumber: any, receipt: any) {
            console.log(_confirmationNumber, receipt)
          })
          .on('receipt', async(receipt: any)=>{
            console.log(receipt);
            await getAllowance(symbol,address);
            // setSTAKE('STAKE')
            setDisabledStakeBtn(false)
            update_Notifies(receipt, setNowNotifies, account as string, chainId as number);
            setTimeout(async () => {
              await del_Notify(receipt, setNowNotifies);
            }, 3000);
          })
          .on('error', function (error: { toString: () => string | string[]; message: any; }) {
            setSTAKE('STAKE')
            setDisabledStakeBtn(false)
            setShow__cancel(true);
            setTimeout(() => {
              setShow__cancel(false);
            }, 3000);
            if (!error.toString().includes("-32601")) {
              return console.log(error)
            }
          })
          .catch((error: { toString: () => string | string[]; message: any; }) => {
            setSTAKE('STAKE')
            setDisabledStakeBtn(false)
            setShow__cancel(true);
            setTimeout(() => {
              setShow__cancel(false);
            }, 3000);
            if (!error.toString().includes("-32601")) {
              return console.log(error)
            }
          })
    }
  }, [lending, account, symbol,poolSymbol,address, originalStakeValue,poolType])
  const stake = useCallback(async () => {
    switch (poolType) {
      case 'single':
        await stakeSingle()
        break;
      case 'iToken':
        await stakeSingle()
        break;
      default:
        break;
    }
  }, [lending, account, symbol,poolSymbol,address, originalStakeValue,poolType])
  
  
  const unStakeSingle = useCallback(async () => {
    if (lending && account) {
      if (!Number(unstakeValue)) {
        return false
      }
      setUNSTAKE('UNSTAKING')
      setDisabledUnStakeBtn(true)
      // isMax ? (lending.contracts.stake[`r${symbol}`] &&
      //   lending.contracts.stake[`r${symbol}`].methods.exit().send({ from: account })
      //     .on('transactionHash', function (hash: string) {
      //       console.log(hash)
      //       onDismiss && onDismiss()
      //       setUnstakeValue('')
      //       add_Notifies({
      //         state: "pedding",
      //         action: "UnStake " + poolSymbol,
      //         transactionHash: hash,
      //         time: new Date().getTime()
      //       }, setNowNotifies, account as string, chainId as number);
      //     })
      //     .on('confirmation', function (_confirmationNumber: any, receipt: any) {
      //       console.log("_confirmationNumber", _confirmationNumber, receipt)
      //     })
      //     .on('receipt', async(receipt: any)=>{
      //       await getAllowance(symbol,address);
      //       setUNSTAKE('UNSTAKE')
      //       setDisabledUnStakeBtn(false)
      //       update_Notifies(receipt, setNowNotifies, account as string, chainId as number);
      //       setTimeout(async () => {
      //         await del_Notify(receipt, setNowNotifies);
      //       }, 3000);
      //       console.log(receipt);
      //     })
      //     .on('error', function (error: { toString: () => string | string[]; message: any; }) {
      //       setUNSTAKE('UNSTAKE')
      //       setDisabledUnStakeBtn(false)
      //       setShow__cancel(true);
      //       setTimeout(() => {
      //         setShow__cancel(false);
      //       }, 3000);
      //       if (!error.toString().includes("-32601")) {
      //         return console.log(error)
      //       }
      //     })
      //     .catch((error: { toString: () => string | string[]; message: any; }) => {
      //       setUNSTAKE('UNSTAKE')
      //       setDisabledUnStakeBtn(false)
      //       setShow__cancel(true);
      //       setTimeout(() => {
      //         setShow__cancel(false);
      //       }, 3000);
      //       if (!error.toString().includes("-32601")) {
      //         return console.log(error)
      //       }
      //     }))
      //   :
        lending.contracts.stake[`r${symbol}`] &&
        lending.contracts.stake[`r${symbol}`].methods.withdraw(originalUnStakeValue.toString()).send({ from: account })
          .on('transactionHash', function (hash: string) {
            console.log(hash)
            onDismiss && onDismiss()
            setUnstakeValue('')
            add_Notifies({
              state: "pedding",
              action: "UnStake " + poolSymbol,
              transactionHash: hash,
              time: new Date().getTime()
            }, setNowNotifies, account as string, chainId as number);
          })
          .on('confirmation', function (_confirmationNumber: any, receipt: any) {
            console.log("_confirmationNumber", _confirmationNumber, receipt)
          })
          .on('receipt', function (receipt: any) {
            setUNSTAKE('UNSTAKE')
            setDisabledUnStakeBtn(false)
            update_Notifies(receipt, setNowNotifies, account as string, chainId as number);
            setTimeout(async () => {
              await del_Notify(receipt, setNowNotifies);
            }, 3000);
            console.log(receipt);
          })
          .on('error', function (error: { toString: () => string | string[]; message: any; }) {
            setUNSTAKE('UNSTAKE')
            setDisabledUnStakeBtn(false)
            setShow__cancel(true);
            setTimeout(() => {
              setShow__cancel(false);
            }, 3000);
            if (!error.toString().includes("-32601")) {
              return console.log(error)
            }
          })
          .catch((error: { toString: () => string | string[]; message: any; }) => {
            setUNSTAKE('UNSTAKE')
            setDisabledUnStakeBtn(false)
            setShow__cancel(true);
            setTimeout(() => {
              setShow__cancel(false);
            }, 3000);
            if (!error.toString().includes("-32601")) {
              return console.log(error)
            }
          })
    }
  }, [lending, account, symbol,poolSymbol,address, originalUnStakeValue,poolType])
  const unStake = useCallback(async () => {
    switch (poolType) {
      case 'single':
        await unStakeSingle()
        break;
      case 'iToken':
        await unStakeSingle()
        break;
      default:
        break;
    }
  }, [lending, account, symbol,poolSymbol,address, originalUnStakeValue,poolType])


  useEffect(() => {
    setSelectTab(action);
    // setSymbol(symbol)
    // set_poolSymbol(poolSymbol)
    // setAssets_icon(assetsIcon)
    setStakeValue('')
    setOriginalStakeValue(new BigNumber(0))
    setUnstakeValue('')
    setOriginalUnStakeValue(new BigNumber(0))
    setSTAKE('STAKE')
    setUNSTAKE('UNSTAKE')
    // if (bnToDec(new BigNumber(balance), decimals,4) === 0) {
    if (new BigNumber(balance).eq('0')) {
      setDisabledStakeBtn(true)
    } else {
      setDisabledStakeBtn(false)
    }
    // if (bnToDec(new BigNumber(MyStaked), decimals,4) === 0) {
    if (new BigNumber(MyStaked).eq('0')) {
      setDisabledUnStakeBtn(true)
    } else {
      setDisabledUnStakeBtn(false)
    }
  }, [isOpen, action, balance, MyStaked, symbol,poolSymbol])

  return (
    <Modal isOpen={isOpen}>
      <ModalTitle text={poolSymbol} icon={assetsIcon} onDismiss={onDismiss} />
      <ModalContent>
        <Tabs>
          <Tab
            className={selectTab === "STAKE" ? "active" : ""}
            onClick={() => setSelectTab("STAKE")}
          >
            {fmt({ id: "STAKE" })}
          </Tab>
          <Tab
            className={selectTab === "UNSTAKE" ? "active" : ""}
            onClick={() => setSelectTab("UNSTAKE")}
          >
            {fmt({ id: "UNSTAKE" })}
          </Tab>
        </Tabs>
        <FormWarp className={selectTab === "STAKE" ? "active" : ""}>
          <Balance>
            {fmt({ id: "_Balance" })}
            {balance === "..."
              ? "..."
              :
              bnToDec(new BigNumber(balance), decimals) === 0
                ? numeral(bnToDec(new BigNumber(balance), decimals, 4)).format('0,0.0000')
                : bnToDec(new BigNumber(balance), decimals, -1).toString().indexOf('.') !== -1
                  ? bnToDec(new BigNumber(balance), decimals, -1)
                  : numeral(bnToDec(new BigNumber(balance), decimals, 4)).format('0,0.0000')
            }
          </Balance>
          <InputWarp
          // className={rewardRate === '0' ? 'disabled' : ''}
          >
            <Input type="text"
              placeholder="Amount"
              value={stakeValue}
              // disabled={rewardRate === '0' ? true : false} 
              onChange={(e) => InputStakeChange(e.target.value)}
            />
            <Max onClick={StakeMax}>{fmt({ id: "MAX" })}</Max>
          </InputWarp>
          {
            ((_allowance === '0') || ((_allowance !== '0') && originalStakeValue.gt(new BigNumber(_allowance)))) ?
            <Enable className={(ENABLE === "ENABLING") ? "disabled" : ''} 
                onClick={(e) => 
                approveHandler(
                  e,
                  poolType, 
                  address, 
                  symbol,
                  poolSymbol
                  )}
                >
                {fmt({ id: 'ENABLE' })}
            </Enable>
            :
            <Btn
              className={disabledStakeBtn ? 'disabled' : ''}
              onClick={async () => await stake()}
            >
              {fmt({ id: `${STAKE}` })}
            </Btn>
          }
          
        </FormWarp>
        {
            <FormWarp className={selectTab === "UNSTAKE" ? "active" : ""}>
              <Balance>
                {fmt({ id: 'Staked' })}
                {MyStaked === "..."
                  ? "..."
                  : bnToDec(new BigNumber(MyStaked), decimals) === 0
                    ? numeral(bnToDec(new BigNumber(MyStaked), decimals, 4)).format('0,0.0000')
                    : bnToDec(new BigNumber(MyStaked), decimals, -1).toString().indexOf('.') !== -1
                      ? bnToDec(new BigNumber(MyStaked), decimals, -1)
                      : numeral(bnToDec(new BigNumber(MyStaked), decimals, 4)).format('0,0.0000')
                }
              </Balance>
              <InputWarp>
                <Input type="text" placeholder="Amount" value={unstakeValue} onChange={(e) => InputUnStakeChange(e.target.value)} />
                <Max onClick={UnStakeMax}>{fmt({ id: "MAX" })}</Max>
              </InputWarp>
              <Btn className={disabledUnStakeBtn ? 'disabled' : ''} onClick={async () => await unStake()}>{fmt({ id: `${UNSTAKE}` })}</Btn>
            </FormWarp>
        }
      </ModalContent>
    </Modal>
  );
};

const Tabs = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 50px;
  border-bottom: 1px solid ${(props) => props.theme.colors.lightTableBorder};
  @media(max-width: 1199px) {
    line-height:40px;
  }
`;
const Tab = styled.div`
  cursor: pointer;
  &:first-child {
    margin-right: 50px;
  }
  color: ${(props) => props.theme.colors.lightGrey};
  &.active {
    margin-bottom: -2.5px;
    border-bottom: 4px solid ${(props) => props.theme.colors.supply.dark};
    color:${(props) => props.theme.colors.supply.dark};
  }
  &.disabled {
    cursor: pointer;
    pointer-events:none;
    color: #a1a4b1;
    background: ${(props) => props.theme.colors.lightPageBg};
    .dark & {
      background: ${(props) => props.theme.colors.darkPageBg};
    }
  }
`;
const Balance = styled.div`
  text-align: right;
  color: ${(props) => props.theme.DarkfontColor[100]};
`;
const Input = styled.input`
  text-indent: 20px;
  border-radius: 25px;
  color: ${(props) => props.theme.DarkfontColor[100]};
`;
const Enable = styled.div`
  flex-basis: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  cursor: pointer;
  border-radius: 25px;
  color: ${(props) => props.theme.colors.white};
  background: ${(props) => props.theme.colors.supply.dark};
  background: linear-gradient(90deg, #5EAFE7 0%, #6E5DE7 100%);
  /* .dark & {
    background: ${(props) => props.theme.colors.supply.dark};
  } */
  &.disabled{
    cursor: not-allowed;
    pointer-events:none;
    color: #a1a4b1;
    background: ${(props) => props.theme.colors.lightPageBg};
  }
  @media(max-width: 1199px) {
    height: 40px;
    line-height:40px;
  }
`;
const InputWarp = styled.div`
  position: relative;
  margin-bottom: 25px;
  border-radius: 25px;
  border: 1px solid ${(props) => props.theme.colors.lightTableBorder};
  &.disabled {
    cursor: pointer;
    pointer-events:none;
    background: ${(props) => props.theme.colors.lightTableBorder};
    color: ${(props) => props.theme.colors.disabledBtn};
    ${Input}{
      background: ${(props) => props.theme.colors.lightTableBorder};
    }
  }
  @media(max-width: 1199px) {
    margin-bottom: 15px;
  }
`;

const Max = styled.div`
  width: 60px;
  height: 28px;
  line-height: 28px;
  position: absolute;
  right: 14px;
  top: 11px;
  cursor: pointer;
  text-align: center;
  color: ${(props) => props.theme.colors.supply.dark};
  background: ${(props) => props.theme.colors.supply.light};
  @media(max-width: 1199px) {
    top:8px;
  }
`;
const LockUp = styled.div`
  line-height:20px;
  span{
    display:block;
    color: ${(props) => props.theme.colors.disabledBtn};
    margin:10px 0;
  }
  b{
    display:block;
    font-weight:500;
    color:#FF4E4E;
    margin:10px 0;
  }
  @media(max-width: 1199px) {
    line-height:20px;
    span{
      margin:5px 0;
    }
    b{
      margin:5px 0;
    }
  }
`
const Btn = styled.div`
  width: 100%;
  height: 50px;
  border-radius: 25px;
  text-align: center;
  font-weight:600;
  background: ${(props) => props.theme.colors.supply.dark};
  color: ${(props) => props.theme.colors.white};
  cursor: pointer;
  &.disabled {
    pointer-events:none;
    background: ${(props) => props.theme.colors.lightTableBorder};
    color: ${(props) => props.theme.colors.disabledBtn};
  }
  @media(max-width: 1199px) {
    height: 40px;
    line-height:40px;
  }
`;
const FormWarp = styled.div`
  display: none;
  margin-top: 2px;
  line-height: 50px;
  box-sizing: border-box;
  &.active {
    display: flex;
    flex-direction: column;
  };
  &.locked{
    pointer-events:none;
    ${InputWarp}{
      background: ${(props) => props.theme.colors.lightTableBorder};
      color: ${(props) => props.theme.colors.disabledBtn};
      margin-bottom:0;
      ${Input}{
        background: ${(props) => props.theme.colors.lightTableBorder};
        color: ${(props) => props.theme.colors.disabledBtn};
      }
      ${Max}{
        background: ${(props) => props.theme.colors.lightPageBg};
        color: ${(props) => props.theme.colors.disabledBtn};
      }
    }
    
  }
  @media(max-width: 1199px) {
    line-height:40px;
  }
`;
export default StakeModal;
