"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ensureNonNegtive = exports.max = exports.minTuple = exports.min = exports.rdivup = exports.divup = exports.rdiv = exports.rmul = exports.chunkArray = void 0;
const ethers_1 = require("ethers");
function chunkArray(array, chunkSize) {
    let result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
        const chunk = array.slice(i, i + chunkSize);
        result.push(chunk);
    }
    return result;
}
exports.chunkArray = chunkArray;
// export class ExtendedBigNumber extends BigNumber {
//   rmul(y: BigNumber): BigNumber {
//     return this.mul(y).div(utils.parseEther("1"));
//   }
//   rdiv(y: BigNumber): BigNumber {
//     return this.mul(utils.parseEther("1")).div(y);
//   }
// }
// Math function
function rmul(a, b) {
    return a.mul(b).div(ethers_1.utils.parseEther("1"));
}
exports.rmul = rmul;
function rdiv(a, b) {
    return a.mul(ethers_1.utils.parseEther("1")).div(b);
}
exports.rdiv = rdiv;
function divup(a, b) {
    return a.add(b.sub(1)).div(b);
}
exports.divup = divup;
function rdivup(a, b) {
    return divup(a.mul(ethers_1.utils.parseEther("1")), b);
}
exports.rdivup = rdivup;
function min(...args) {
    if (args.length === 0) {
        throw new Error("min function requires at least one argument");
    }
    return args.reduce((acc, val) => (acc.lt(val) ? acc : val));
}
exports.min = min;
function minTuple(...args) {
    if (args.length === 0) {
        throw new Error("min function requires at least one argument");
    }
    return args.reduce((acc, val) => (acc[0].lt(val[0]) ? acc : val));
}
exports.minTuple = minTuple;
function max(...args) {
    if (args.length === 0) {
        throw new Error("max function requires at least one argument");
    }
    return args.reduce((acc, val) => (acc.gt(val) ? acc : val));
}
exports.max = max;
// Notice BigNumber is immutable, so returns a new array
function ensureNonNegtive(...args) {
    if (args.length === 0) {
        throw new Error("ensureNonNegtive function requires at least one argument");
    }
    return args.map((val) => (val.gt(0) ? val : ethers_1.BigNumber.from(0)));
}
exports.ensureNonNegtive = ensureNonNegtive;
