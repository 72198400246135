export const sModeConf = {
    11155111: {
        1: 'StableCoins',
        'StableCoins': 1,
        2: 'ETH',
        'ETH': 2,
    }
}

export const ModeMap = {
    'Stablecoins': 'StableCoins',
    'ETH derivatives': 'ETH',
}
export const ModeToMap = {
    'StableCoins': 'Stablecoins',
    'ETH': 'ETH derivatives',
}

export const ErrorCode = {
    1: 'You can only borrow assets allowed by this mode. Please repay any unsupported loans first.',
    2: 'You cannot enter Supercharage Mode when your position is insolvent.',
    3: 'You cannot enter Supercharage Mode due to invalid asset price or invalid equity',
}
