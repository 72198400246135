import React, { createContext, useEffect, useState } from 'react'

interface NotifyContext {
  notifies?: any
}
declare global {
  interface Window {
    notifies: any
  }
}

export const Context = createContext<NotifyContext>({
  notifies: undefined,
})

const NotifyProvider = ({ children }: {
  children: React.ReactNode
}) => {
  const [notifies, setNotifies] = useState<any>()

  useEffect(() => {
    const Notifies = [
      { tx: '123', action: 'Supply USDx' },
      { tx: '456', action: 'Borrow ETH' }
    ]

    setNotifies(Notifies)
    window.notifies = Notifies
  }, [])

  return (
    <Context.Provider value={{ notifies }}>
      {children}
    </Context.Provider>
  )
}


export default NotifyProvider
