import React from "react"
import styled from "styled-components"
import bannerBg from "assets/home/bannerBg.svg"


const Page = ({ children }: {
  children: React.ReactNode
}) => {
  return (
    <StyledPage>
      <StyleWrap id="scrollWarp">
        <StyledMain>
          {children}
        </StyledMain>
      </StyleWrap>
    </StyledPage>
  )
}


const StyledPage = styled.div`
  position: relative;
  display: flex;
  width: 100vw;
  /* overflow-x: hidden; */
  /* background: ${(props) => props.theme.body_background_light}; */
  /* background: url(${bannerBg}) no-repeat center ${(props) => props.theme.body_background_light}; */
  min-height: calc(100vh - 71px);
`
const StyleWrap = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
  /* height: calc(100vh - 70px); */
  padding-bottom: 50px;
  background: url(${bannerBg}) no-repeat center -70px ${(props) => props.theme.body_background_light};
  .dark & {
    background: url(${bannerBg}) no-repeat center -70px ${(props) => props.theme.body_background_night};
  }
  @media (min-width: 1920px) {
    background-size: 100% auto;
  }
  @media (max-width: 1199px) {
    width: 100%;
    /* height: calc(100vh - 60px); */
    /* padding: 0 10px 20px; */
  }
`
const StyledMain = styled.div`
  height: fit-content;
  align-items: inherit;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  width: 100%;
  /* background: red; */
  /* @media (min-width: 1200px) {
    width: 72%;
    min-width: 1200px;
  }*/
  @media (max-width: 1199px) {
    /* width: 100%;
    max-width: 100%;
    min-width: 100%; */
    margin-top: 0px;
  }
`

export default Page
