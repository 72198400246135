import React from 'react'
import styled, { keyframes } from 'styled-components'

export interface ModalProps {
  isOpen?: boolean,
  children?: React.ReactNode,
  onDismiss?: () => void
}

const Modal: React.FC<ModalProps> = ({ isOpen, children, onDismiss }) => {
  if (isOpen) {
    return (
      <Umbralayer>
        <StyledModal isOpen={isOpen}>
          {children}
        </StyledModal>
      </Umbralayer>
    )
  }
  return null
}


const slideUp = keyframes`
  0% {
    top:50%;
    transform: translateY(100%);
  }
  100% {
    top:50%;
    transform: translateY(-50%);
  }
`

interface StyledModalProps {
  isOpen?: boolean
}

const StyledModal = styled.div<StyledModalProps>`
  border-radius: ${(props) => props.theme.borderradius};
  position: absolute;
  background: #fff;
  width:472px;
  // will-change:transform;
  animation: ${slideUp} 0.3s forwards ease-out;
  box-shadow: 24px 24px 48px -24px ${props => props.theme.colors.darkGrey};
  @media(max-width: 1199px) {
    width:calc(100% - 64px);
    margin:0 auto;
    /* padding:20px 20px 25px */
  }
  .dark & {
    background:rgba(0, 0, 0, 1);
    box-shadow: 1px 1px 1px -24px ${props => props.theme.colors.darkGrey};
    border: 1px solid rgba(255, 255, 255, 0.16);
  }
`
const Umbralayer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  position: fixed;
  left:0;
  top:0;
  right:0;
  bottom:0;
  z-index: 9999;
  // backdrop-filter: blur(5px);
  inset: 0px;
  background: rgba(0,0,0, 0.7);
  background: rgba(0,0,0, 0.9);
`

export default Modal