import React, { useState } from 'react'
import styled from 'styled-components'
import ReactTooltip from 'react-tooltip';
import { useIntl } from 'react-intl'
// images
import tooltip from 'assets/supply/tooltip.svg'
import increase from 'assets/supply/increase.svg'
import Ugo from 'assets/supply/Ugo.svg'
import increase_green from 'assets/supply/increase-green.svg'
import { connectionURL, priceIdsConf, contractAddress } from './config/address'
import useLending from "hooks/useLending"
import pythABI from './config/pythABI.json'
import { useWeb3React } from '@web3-react/core';

// import { EvmPriceServiceConnection } from '@pythnetwork/pyth-evm-js';
const { EvmPriceServiceConnection } = require('@pythnetwork/pyth-evm-js');



interface FancyValueProps {
  i18n_text: string,
  value?: string,
  showIcon?: string,
  token?: string,
  collateral?: boolean,
  collateral_bool?: string,
  canbe_executed?: string,
  tobe?: string,
  tobe__tab2?: string,
  isChoosen__supply__tab?: boolean,
  borrow_checked?: boolean,
  contract?: any,
  dlTokenAddr?: any,
  is_green?: boolean,
  isTitle?: boolean,
  height?: string,
  showPriceUpdate?: boolean,
  add_Notifies?: any,
  setNowNotifies?: any,
  update_Notifies?: any,
  del_Notify?: any,
  setShow__cancel?: any,
  isSuperCharged?: boolean,
}

const ChangeableValue: React.FC<FancyValueProps> = ({
  i18n_text,
  showIcon,
  token,
  value,
  collateral,
  collateral_bool,
  canbe_executed,
  tobe,
  tobe__tab2,
  isChoosen__supply__tab,
  borrow_checked,
  contract,
  dlTokenAddr,
  is_green,
  isTitle,
  height,
  showPriceUpdate,
  add_Notifies,
  setNowNotifies,
  update_Notifies,
  del_Notify,
  setShow__cancel,
  isSuperCharged
}) => {
  const {
    connector,
    account,
    chainId,
    provider,
    ENSName,
    isActive,
    isActivating,
  } = useWeb3React();
  const [bool_Btn, setBool_Btn] = useState(false)
  const Lending = useLending()
  const intl = useIntl()
  const fmt = intl.formatMessage
  // console.log(value)

  const isSupportCurChain = chainId && connectionURL[chainId]
  // console.log(isSupportCurChain)

  const makeItAsCollateral = () => {
    console.log(contract)
    contract.methods[collateral_bool === 'true' ? 'exitMarkets' : 'enterMarkets']([dlTokenAddr]).send({ from: account })
      .on('transactionHash', function (hash: string) {
        console.log('transactionHash: ', hash)
      })
      .on('receipt', function (receipt: any) {
        console.log('receipt: ', receipt)
      })
  }

  const clickPriceUpdate = async () => {
    // console.log('clickPriceUpdate')
    // console.log(EvmPriceServiceConnection)
    // See Price Service endpoints section below for other endpoints
    // console.log(connectionURL[chainId], priceIdsConf[chainId])
    if (!chainId) return
    if (bool_Btn) return
    try {
      setBool_Btn(true)
      const connection = new EvmPriceServiceConnection(connectionURL[chainId])
      const priceIds = priceIdsConf[chainId]
      const address = contractAddress[chainId]
      const pythContract = new Lending.web3.eth.Contract(pythABI, address)

      const priceUpdateData = await connection.getPriceFeedsUpdateData(priceIds)
      const updateFee = await pythContract.methods.getUpdateFee(priceUpdateData).call()

      await pythContract.methods
        .updatePriceFeeds(priceUpdateData)
        .send({ from: account, value: updateFee })
        .on("transactionHash", function (hash: string) {
          console.log("transactionHash: ", hash);
          add_Notifies({
            state: "pedding",
            action: "Update Price",
            transactionHash: hash,
            time: new Date().getTime()
          }, setNowNotifies, account as string, chainId as number);
          setBool_Btn(false);
        })
        .on("receipt", function (receipt: any) {
          console.log("receipt: ", receipt);
          update_Notifies(receipt, setNowNotifies, account as string, chainId as number);
          setTimeout(async () => {
            await del_Notify(receipt, setNowNotifies);
          }, 3000);
          setBool_Btn(false);
        })
        .on("error", function (error: any) {
          console.log("error", error);
          setBool_Btn(false);
          setShow__cancel(true);
          setTimeout(() => {
            setShow__cancel(false);
          }, 3000);
        });
    } catch (error) { }
  }


  return (
    <StyledBalance isTitle={isTitle} height={height}>
      <StyledTitle isTitle={isTitle}>
        {token && <>{token}</>}
        <span>{fmt({ id: i18n_text })}</span>
        {
          showIcon &&
          <>
            <a data-tip data-for={showIcon} style={{ marginLeft: '5px' }}>
              <img src={tooltip} alt='' style={{ marginBottom: '4px' }} />
            </a>
            <ReactTooltip
              id={showIcon}
              place="top"
              type="light"
              effect="float"
              className="tooltip"
              textColor={'#9195A4'}
              borderColor={'#E8ECEF'}
              border={true}
            >
              <span>{showIcon}</span>
            </ReactTooltip>
          </>
        }
        {
          showPriceUpdate &&
          isSupportCurChain &&
          <PriceUpdate
            className={bool_Btn ? 'disable' : ''}
            onClick={() => { clickPriceUpdate() }}
            data-tip data-for={'showIcon'}>
            {fmt({ id: 'TextPriceUpdate' })}
            <ReactTooltip
              id={'showIcon'}
              place="top"
              type="light"
              effect="float"
              className="tooltip"
              textColor={'#9195A4'}
              borderColor={'#E8ECEF'}
              border={true}
            >
              <span>{fmt({ id: 'TextPriceUpdate__tips' })}</span>
            </ReactTooltip>
          </PriceUpdate>
        }
      </StyledTitle>

      {
        collateral ?
          <span className={borrow_checked ? 'borrow_checked' : ''}>
            {
              canbe_executed === 'true' ?
                <>
                  {collateral_bool === 'true' ? 'YES' : 'NO'}
                  <input
                    onClick={makeItAsCollateral}
                    className="switch"
                    type="checkbox"
                    checked={collateral_bool === 'true' ? true : false}
                  />
                </>
                :
                <>
                  {collateral_bool === 'true' ? 'YES' : 'NO'}
                  <input
                    data-tip
                    data-for="executed"
                    className="switch collateral_disabled"
                    type="checkbox"
                    checked={collateral_bool === 'true' ? true : false}
                  />
                  <ReactTooltip
                    id="executed"
                    place="top"
                    type="light"
                    effect="float"
                    className="tooltip"
                    textColor={'#9195A4'}
                    borderColor={'#E8ECEF'}
                    border={true}
                  >
                    <span>{fmt({ id: "isExecutedTips" })}</span>
                  </ReactTooltip>
                </>
            }
          </span>
          : isSuperCharged
            ? <StyledValue>
              <img className='ugo' src={Ugo} alt="" />
              {value}
            </StyledValue>
            : value
              ? isChoosen__supply__tab
                ? tobe
                  ? <>
                    <StyledValue>
                      <span style={{ color: '#9195A4' }}>{value}</span>
                      <StyledIncrease src={is_green ? increase_green : increase} alt="" />
                      <span style={{ color: is_green ? '#05C7A0' : `${(props: any) => props.theme.global_supply}` }}>{tobe}</span>
                    </StyledValue>
                  </>
                  : <StyledValue>{value}</StyledValue>
                : tobe__tab2
                  ? <>
                    <StyledValue>
                      <span style={{ color: '#9195A4' }}>{value}</span>
                      <StyledIncrease src={is_green ? increase_green : increase} alt="" />
                      <span style={{ color: is_green ? '#05C7A0' : `${(props: any) => props.theme.global_supply}` }}>{tobe__tab2}</span>
                    </StyledValue>
                  </>
                  : <StyledValue>{value}</StyledValue>
              : <StyledDot>...</StyledDot>
      }
    </StyledBalance>
  );
};

const PriceUpdate = styled.span`
  user-select: none;
  margin-left: 10px;
  text-align: center;
  background: linear-gradient(90deg,#5EAFE7 0%,#6E5DE7 100%);
  color: #ffffff;
  border-radius: 5px;
  padding: 4px;
  cursor: pointer;
  opacity: 0.9;
  -webkit-transition: 0.1s;
  transition: 0.1s;

  &.disable {
    background: #EFF1F4 !important;
    color: #9FA4B3 !important;
    pointer-events: none !important;
    cursor: not-allowed !important;
  }
`

const StyledIncrease = styled.img`
  width: 10px;
  margin-bottom: 2px;
  margin-left: 5px;
  margin-right: 5px;
`
interface StyledBalanceProps {
  isTitle?: boolean,
  height?: string
}
const StyledBalance = styled.div<StyledBalanceProps>`
  display: flex;
  justify-content: space-between;
  color: ${(props) => props.theme.global_grey};
  line-height: ${(props) => props.isTitle ? '24px' : props.height ? props.height : '46px'};
  font-size: ${(props) => props.isTitle ? '18px' : '16px'};
  font-weight: ${(props) => props.isTitle ? 'bold' : '500'};
  margin: '0 0 34px';
  @media (max-width: 1119px) {
    font-size: 13px;
    line-height: 30px;
    margin: '0 0 20px';
  }
`
interface StyledTitleProps {
  isTitle?: boolean
}
const StyledTitle = styled.div<StyledTitleProps>`
  font-weight: ${(props) => props.isTitle ? 'bold' : '400'};
`
const StyledDot = styled.div`

`
const StyledValue = styled.div`
  color: ${(props) => props.theme.operationPage.light.mainBlack};
  .dark & {
    color: ${(props) => props.theme.operationPage.dark.mainBlack};
  }
  .ugo {
    margin-right: 4px;
  }
`

interface ValueHintProps {
  darkMode?: boolean;
}



const StyledIcon = styled.span.attrs({ role: "img" })`
  font-size: 32px;
`;

const LabelWrapDisplay = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;

const ValueHint = styled.span<ValueHintProps>`
  cursor: default;
  display: block;
  position: absolute;
  top: -8px;
  right: -8px;
  background: #ae0e463b;
  color: ${(props) => props.theme.colors.primary.main};
  line-height: 16px;
  font-weight: bold;
  font-size: 12px;
  border: 1.5px solid ${(props) => props.theme.colors.primary.main};
  border-radius: 100px;
  padding: 0px 5px 1px 5px;
  opacity: 0.4;

  &:hover {
    opacity: 1;
    z-index: 10;
  }
`;

export default ChangeableValue;
