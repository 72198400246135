import { createContext } from 'react'

import { MarketsDate } from './types'
const Context = createContext<MarketsDate>({
    marketsList:[],
    totalAssets:{},
    totalSupplyValue: '',
    totalBorrowsValue: '',
    totalMintValue:''
})

export default Context