import React from "react";
import styled from "styled-components";


const ToHome = ({ children }: {
    children: React.ReactNode
  }) => {
    return (
        <BackToHome>
            {children}
        </BackToHome>
    );
}

const BackToHome = styled.b`
    display: flex;
    align-items: center;
    font-size: 14px;
    height: 30px;
    line-height: 30px;
    color: ${(props) => props.theme.colors.lightGrey};
    &:before {
        content: "";
        display: block;
        width: 8px;
        height: 8px;
        margin-right: 5px;
        border-right: 2px solid ${(props) => props.theme.colors.lightGrey};
        border-top: 2px solid ${(props) => props.theme.colors.lightGrey};
        -webkit-transform: rotate(-135deg); /*箭头方向可以自由切换角度*/
        transform: rotate(-135deg);
    }
`

export default ToHome

