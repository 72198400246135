import React from 'react'
import styled from 'styled-components'
import closeWallet from 'assets/header/close-wallet.svg'
interface ModalTitleProps {
  text?: string,
  onDismiss?: () => void;
}

const ModalTitle: React.FC<ModalTitleProps> = ({ text, onDismiss }) => (
    <StyledModalTitle>
      {text}
      <CloseModal src={closeWallet} onClick={onDismiss}/>
    </StyledModalTitle>
)

const CloseModal = styled.img`
  width:20px;
  height:20px;
  cursor: pointer;
  @media(max-width: 1199px) {
    width:16px;
    height:16px
  }
`
const StyledModalTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 700;
  height: 20px;
  line-height:20px;
  color: #333;
  @media(max-width: 1199px) {
    height: 20px;
    line-height:20px;
  }
`



export default ModalTitle