import React, { useCallback, useEffect, useState } from "react";
import { Link, NavLink, useNavigate,useParams } from "react-router-dom";
import styled from "styled-components";
import ReactApexChart from "react-apexcharts";
import { useIntl } from "react-intl";
import { useWeb3React } from '@web3-react/core'
import BigNumber from "bignumber.js";
import { ethers } from "ethers";
// components
import UnlockWalletModal from "components/UnlockWalletModal";
import BorderWrap from "components/BorderWrap";
import NoteTips from "components/NoteTips";
import PaddingWrap from "components/PaddingWrap";
import Logo_Token_number from "components/Logo_Token_number";
import Token_Number from "components/Token_Number";
import ChangeableValue from "components/ChangeableValue";
import Notification from "components/Notification";
import Notification__cancel from "components/Notification__cancel";
// hooks
import useLending from "hooks/useLending";
import useAllowance from "hooks/useAllowance";
// styledComponents
import StyledApproveFirst from 'styledComponents/StyledApproveFirst'
import StyledYouDid from 'styledComponents/StyledYouDid'
import BackToHome from 'styledComponents/BackToHome'
import Crumb from 'styledComponents/Crumb'
import StyledLeftSection from 'styledComponents/StyledLeftSection'
import StyleRightSection from 'styledComponents/StyleRightSection'
import ActionTab from 'styledComponents/ActionTab'
import { StyledChart, OverSafeMAX, InputWrap } from 'styledComponents/MulStyled'

import {
  format_bn,
  format_num_to_K,
  formatTimeStamp,
  deconstruction_wallet_source,
  postDataToServer,
  useQuery, DFApproveXArray
} from "utils";
import { network_Name, add_Notifies, update_Notifies, del_Notify, del__cancel } from 'lending-sdk/utils/index1'
import { fetchAPY } from "./fetchAPY";
// images
import metamask from "assets/wallet-MetaMask.svg";
import Brave from 'assets/walletLog/Brave.svg'
import coinbase from "assets/wallet-Coinbase.svg";
import icon_walletconnect from "assets/wallet-WalletConnect.svg";
import dForceLogo from "assets/home/loading.gif";

import { factor } from 'constants/constants.js';

import general_tokens from 'lending-sdk/lib/clean_build/contracts/general/IERC20.json';
import synthetic_tokens from 'lending-sdk/lib/clean_build/contracts/synthetic/IERC20.json';
import stocks_tokens from 'lending-sdk/lib/clean_build/contracts/stocks/IERC20.json';

const MintBURN: React.FC = () => {
  const {
    connector,
    account,
    chainId,
    provider,
    ENSName,
    isActive,
    isActivating,
  } = useWeb3React();
  const Lending = useLending();

  const cur_Pool: any = {
    general: general_tokens,
    synthetic: synthetic_tokens,
    stocks: stocks_tokens
  }

  const [unlockModalIsOpen, setUnlockModalIsOpen] = useState(false);
  const [metamaskActive, setMetamaskActive] = useState(false);
  const [walletConnectActive, setWalletConnectActive] = useState(false);
  const [walletIcon, setWalletIcon] = useState("");

  const [curPercentage, setCurPercentage] = useState(0);
  const [curPercentage__tab2, setCurPercentage__tab2] = useState(0);

  const [borrowTokenData, setBorrowTokenData] = useState<any>();
  const [accountBorrowData, setAccountBorrowData] = useState<any>();
  const [accountSupplyData, setAccountSupplyData] = useState<any>();

  const [accountBorrowInfo, setAccountBorrowInfo] = useState<any>();
  const [accountTotalValue, setAccountTotalValue] = useState<any>();

  const [borrow_number, setBorrow_number] = useState<any>();
  const [repay_number, setRepay_number] = useState<any>();
  const [is_borrow_max, setIs_borrow_max] = useState<boolean>(false);
  const [is_repay_max, setIs_repay_max] = useState<boolean>(false);

  const [isChoosen__borrow__tab, setIsChoosen__borrow__tab] = useState<boolean>(true);
  const { CurrencyPair } = useParams<any>();
  // 获取router URLParams
  const navigate = useNavigate()

  const [nowNotifies, setNowNotifies] = useState<any>([]);
  const [show__cancel, setShow__cancel] = useState<boolean>(false);
  const [chainID, setChainID] = useState<number>(1);

  const intl = useIntl();
  const intl_locale = intl.locale;
  // console.log(intl_locale==='cn')
  const fmt = intl.formatMessage;

  const local_account = window.localStorage.getItem('account') || ''

  const [enable_status, setEnable_status] = useState<string>(
    fmt({ id: "ENABLE" })
  );
  const [bool_Btn, setBool_Btn] = useState<boolean>(false);
  const [bool_Btn__enable, setBool_Btn__enable] = useState<boolean>(false);

  const [wallet_source, setWallet_source] = useState<string>("");
  const [chartState, setChartState] = useState<any>({
    series: [
      {
        name: "APY",
        data: [],
      },
    ],
    options: {
      chart: {
        toolbar: {
          show: false,
          tools: {
            download: false,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
          },
        },
        height: 350,
        type: "line",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 2,
      },
      xaxis: {
        type: "category",
        categories: [],
        min: null,
        tickAmount: 4,
        tickPlacement: "on",
        labels: {
          rotate: 0,
          style: {
            colors: "#9195A4",
          },
        },
      },
      fill: {
        type: "image",
        gradient: {
          shadeIntensity: 1,
          inverseColors: false,
          opacityFrom: 0.6,
          opacityTo: 0.55,
          stops: [20, 100, 100, 100],
        },
      },
      yaxis: {
        labels: {
          formatter: function (value: any) {
            return value.toFixed(2) + "%";
          },
          style: {
            colors: "#9195A4",
          },
        },
      },
      colors: ["#507BFC"],
    },
  });

  const ParamsArr = (CurrencyPair as string).split("-");
  const this__action = ParamsArr[0];
  const this__token = ParamsArr[1] + (ParamsArr[2] ? '-' + ParamsArr[2] : '');
  // console.log(this__action, this__token)

  const isGasToken = Lending?.contracts?.general[this__token]?.gasToken;

  useEffect(() => {
    if (chainId) {
      try {
        let curTokens = Object.keys(cur_Pool?.general.networks[`${chainId}`])
        curTokens.push('ETH')
        if (isGasToken) {
          curTokens.push('BNB')
        }
        // console.log(curTokens)
        // console.log(curTokens.includes(this__token))
        if (!curTokens.includes(this__token)) {
          console.log(curTokens.includes(this__token))
          navigate(`/lending`)
          return;
        }
      } catch (error) {
        console.log(error)
      }

    }
  }, [chainId])

  useEffect(() => {
    if (enable_status === "ENABLE" || "授权") {
      setEnable_status(fmt({ id: "ENABLE" }));
    } else {
      setEnable_status(fmt({ id: "ENABLING" }));
    }
  }, [intl_locale]);

  const fetchApi = useCallback(async () => {
    // console.log(Lending.contracts[`${query.get('currentPool')}`][this__token].address)
    const echartsAPY =
      Lending &&
      (await fetchAPY(
        chainId,
        Lending.contracts?.general["iM" + this__token]?.address,
        'borrow'
      ));
    const seriesData =
      echartsAPY.length > 0
        ? echartsAPY.map((item: any) => format_bn(item.apy, 16, 2))
        : [];
    const categories =
      echartsAPY.length > 0
        ? echartsAPY.map((item: any) => {
          return formatTimeStamp(
            new Date(parseInt(item.timeStamp) * 1000),
            "MM/dd"
          );
        })
        : [];
    setChartState({
      series: [
        {
          name: "APY",
          // data: [31, 40, 28, 51, 42, 109, 100]
          data: seriesData,
        },
      ],
      options: {
        chart: {
          toolbar: {
            show: false,
            tools: {
              download: false,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
            },
          },
          height: 350,
          type: "line",
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: 2,
        },
        xaxis: {
          type: "category",
          categories,
          min:
            echartsAPY &&
            (echartsAPY.length > 1 ? echartsAPY[0]?.timeStamp : null),
          tickAmount: 4,
          tickPlacement: "on",
          labels: {
            rotate: 0,
            style: {
              colors: "#9195A4",
            },
          },
        },
        fill: {
          type: "image",
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.6,
            opacityTo: 0.55,
            stops: [20, 100, 100, 100],
          },
        },
        yaxis: {
          labels: {
            formatter: function (value: any) {
              return value.toFixed(2) + "%";
            },
            style: {
              colors: "#9195A4",
            },
          },
        },
        colors: ["#507BFC"],
      },
    });
  }, [Lending, this__token]);

  useEffect(() => {
    if (Lending) {
      fetchApi();
    }
  }, [Lending, this__token]);

  const allowance = useAllowance(
    Lending?.contracts?.general[this__token]?.address,
    Lending?.contracts?.general[
      !Lending?.contracts?.general[this__token]?.gasToken ?
        "iM" + this__token : this__token
    ]?.address
  );
  // console.log(allowance?.toString())

  const handleDismissUnlockModal = useCallback(() => {
    setUnlockModalIsOpen(false);
    setMetamaskActive(false);
    setWalletConnectActive(false);
  }, [setUnlockModalIsOpen]);
  
  const handleUnlockWalletClick = useCallback(() => {
    setUnlockModalIsOpen(true);
  }, [setUnlockModalIsOpen]);

  const getStatesData = useCallback(() => {
    console.log("***** getStatesData *****");
    if (Lending && account) {
      // Liquidity (uint256)(scaled by 10**Decimals)  //  borrowTokenData[0]
      // Borrow Factor (uint256)  //  borrowTokenData[1]
      // Borrow APY (uint256) //  borrowTokenData[2]
      // Token Price (uint256) //  borrowTokenData[3]
      Lending?.contracts?.general[this__token] &&
        Lending.contracts.general.LendingData.methods
          .getBorrowTokenData(
            Lending.contracts.general[
              !Lending?.contracts.general[this__token]?.gasToken ?
                "iM" + this__token : this__token
            ]?.address
          )
          .call()
          .then((res: any) => {
            // console.log(res)
            setBorrowTokenData(res);
          });

      // Token Borrowed (uint256)  // accountBorrowData[0]
      // Available to Borrow (uint256) ====> update to Cap(市场可借数量)  // accountBorrowData[1]
      // SAFE MAX Available to Borrow (uint256)  // accountBorrowData[2]
      // Token Balance (uint256)  //  accountBorrowData[3]
      // Max BURN (uint256)  //  accountBorrowData[4]
      // Decimals (uint8)  // accountBorrowData[5]
      Lending?.contracts?.general[this__token] &&
        Lending.contracts.general.LendingData.methods
          .getAccountBorrowData(
            Lending.contracts.general[
              !Lending?.contracts.general[this__token]?.gasToken ?
                "iM" + this__token : this__token
            ]?.address,
            account,
            factor
          )
          .call()
          .then((res: any) => {
            // console.log(res)
            setAccountBorrowData(res);
          });

      // Max Borrow Value ($)(uint256)  // accountBorrowInfo[0]
      // SAFE Borrow Value ($)(uint256)  // accountBorrowInfo[1]
      // Token Value  ($)(uint256)  //  accountBorrowInfo[2]
      // markets_closed  //  accountBorrowInfo[3]
      Lending?.contracts?.general[this__token] &&
        Lending.contracts.general.LendingData.methods
          .getAccountBorrowInfo(
            Lending.contracts.general[
              !Lending?.contracts.general[this__token].gasToken ?
                "iM" + this__token : this__token
            ].address,
            account,
            factor
          )
          .call()
          .then((res: any) => {
            console.log(res)
            setAccountBorrowInfo(res);
          });

      // returns:
      //   Supply Balance ($) (uint256)  //  accountTotalValue[0]
      //   Collateral Balance ($) (uint256)  //  accountTotalValue[1]
      //   Borrow Balance ($) (uint256)  //  accountTotalValue[2]
      //   Collateralization ratio (%) (uint256)  //  accountTotalValue[3]
      Lending.contracts?.general.LendingData.methods
        .getAccountTotalValue(account)
        .call()
        .then((res: any) => {
          // console.log(res)
          setAccountTotalValue(res);
        });


    }
  }, [Lending, this__token, is_repay_max]);

  useEffect(() => {
    getStatesData();

    let cur_source = deconstruction_wallet_source();
    console.log(cur_source);
    setWallet_source(cur_source);

    Lending?.web3.eth.getChainId().then((chainID: any) => {
      console.log(chainID);
      setChainID(chainID);
    });

    let refreshInterval = setInterval(getStatesData, 1000 * 10);
    return () => clearInterval(refreshInterval);
  }, [Lending, this__token, is_repay_max]);

  const borrow_max = () => {
    setIs_borrow_max(true);
    setCurPercentage(100)

    setBorrow_number(format_bn(accountBorrowData[2], accountBorrowData[5], 6));
    console.log("borrow max");
  };
  const borrow_input_change = (val: string) => {
    val = val.trim();
    var reg_ZH_CN = new RegExp("[\\u4E00-\\u9FFF]+", "g");
    var reg_ABC = /[a-z]/g;
    // 限制只可输入小数点后6位
    let quantityScale = "6";
    let reg_maxLength = new RegExp("^(0|[1-9][0-9]*)(\\.[0-9]{0," + quantityScale + "})?$");
    // 限制只可输入小数点后6位
    if (reg_ZH_CN.test(val)) {
      return console.log("不可以输入中文");
    }
    if (reg_ABC.test(val)) {
      return console.log("English ABC prohibited");
    }
    if (!(accountBorrowData && borrowTokenData)) {
      return console.log("not ready: accountBorrowData && borrowTokenData");
    }
    if (accountBorrowData[2] === "0") {
      return console.log("safe to Borrow is 0");
    }
    if (!reg_maxLength.test(val) && val !== "") {
      return console.log(
        "The maximum number of digits after the decimal point is exceeded"
      );
    }
    setIs_borrow_max(false);
    console.log("borrow_changed");
    setCurPercentage(0)

    let bool_gt = new BigNumber(val)
      .multipliedBy(new BigNumber(10).pow(new BigNumber(accountBorrowData[5])))
      .gt(new BigNumber(accountBorrowData[2]));
    if (bool_gt) {
      return borrow_max();
    }

    setBorrow_number(val);
  };

  const repay_max = () => {
    if (!(accountBorrowData && borrowTokenData)) {
      return console.log("not ready: accountBorrowData && borrowTokenData");
    }
    // if (accountBorrowData[3] === "0") {
    //   return console.log("account balance is 0");
    // }

    setIs_repay_max(true);
    setCurPercentage__tab2(100)

    setRepay_number(format_bn(accountBorrowData[4], accountBorrowData[5], 6));
    console.log("BURN max");
  };
  const repay_input_change = (val: string) => {
    val = val.trim();
    var reg_ZH_CN = new RegExp("[\\u4E00-\\u9FFF]+", "g");
    var reg_ABC = /[a-z]/g;
    // 限制只可输入小数点后6位
    let quantityScale = "6";
    let reg_maxLength = new RegExp(
      "^(0|[1-9][0-9]*)(\\.[0-9]{0," + quantityScale + "})?$"
    );
    // 限制只可输入小数点后6位
    if (reg_ZH_CN.test(val)) {
      return console.log("不可以输入中文");
    }
    if (reg_ABC.test(val)) {
      return console.log("English ABC prohibited");
    }
    if (!(accountBorrowData && borrowTokenData)) {
      return console.log("not ready: accountBorrowData && borrowTokenData");
    }
    if (accountBorrowData[3] === "0") {
      return console.log("account balance is 0");
    }
    if (!reg_maxLength.test(val) && val !== "") {
      return console.log(
        "The maximum number of digits after the decimal point is exceeded"
      );
    }
    setIs_repay_max(false);
    console.log("repay_changed");
    setCurPercentage__tab2(0)

    let bool_gt = new BigNumber(val)
      .multipliedBy(new BigNumber(10).pow(new BigNumber(accountBorrowData[5])))
      .gt(new BigNumber(accountBorrowData[4]));
    if (bool_gt) {
      return repay_max();
    }

    setRepay_number(val);
  };


  const changePercentage = (val: number) => {
    setCurPercentage(val)

    if (val === 100) {
      return borrow_max();
    }

    setIs_borrow_max(false);

    let temp_val = new BigNumber(accountBorrowData[2])
      .multipliedBy(new BigNumber(val))
      .dividedBy(new BigNumber(100))
      .toFixed(0)
      .toString();

    let to_input = format_bn(temp_val, accountBorrowData[5], 6);
    setBorrow_number(to_input);
  }


  const changePercentage__tab2 = (val: number) => {
    setCurPercentage__tab2(val)

    if (val === 100) {
      return repay_max();
    }

    setIs_repay_max(false);

    let temp_val = new BigNumber(accountBorrowData[4])
      .multipliedBy(new BigNumber(val))
      .dividedBy(new BigNumber(100))
      .toFixed(0)
      .toString();

    let to_input = format_bn(temp_val, accountBorrowData[5], 6);
    setRepay_number(to_input);
  }

  useEffect(() => {
    if (this__action.toLowerCase() === "mint") {
      setIsChoosen__borrow__tab(true);
    } else if (this__action.toLowerCase() === "burn") {
      setIsChoosen__borrow__tab(false);
    }
    console.log(ParamsArr, isChoosen__borrow__tab);
  }, []);

  const click_borrow__spe = () => {
    if (!borrow_number) {
      return console.log("borrow_number undefined");
    }
    if (new BigNumber(borrow_number).eq(new BigNumber(0))) {
      return console.log("borrow_number is 0...");
    }
    if (bool_Btn) {
      return console.log("already borrowing...");
    }
    setBool_Btn(true);
    let to_bn__value = new BigNumber(borrow_number)
      .multipliedBy(new BigNumber(10).pow(new BigNumber(accountBorrowData[5])))
      .toString();

    Lending.contracts?.general[this__token].methods
      .borrow(is_borrow_max ? accountBorrowData[2] : to_bn__value)
      .send({ from: account })
      .on("transactionHash", function (hash: string) {
        console.log("transactionHash: ", hash);
        postDataToServer(
          wallet_source,
          "Mint",
          account || "",
          chainId ? network_Name[chainId] : "",
          Lending.contracts.general[this__token].address,
          accountBorrowData[5],
          borrowTokenData[3],
          is_borrow_max ? accountBorrowData[2] : to_bn__value
        );
        borrow_input_change("");
        add_Notifies({
          state: "pedding",
          action: "Mint " + format_num_to_K(format_bn(to_bn__value.toString(), accountBorrowData[5], 4)) + " " + this__token,
          transactionHash: hash,
          time: new Date().getTime()
        }, setNowNotifies, account as string, chainId as number);
        setBool_Btn(false);
      })
      .on("receipt", function (receipt: any) {
        console.log("receipt: ", receipt);
        getStatesData();
        update_Notifies(receipt, setNowNotifies, account as string, chainId as number);
        setTimeout(() => {
          del_Notify(receipt, setNowNotifies);
        }, 3000);
        setBool_Btn(false);
      })
      .on("error", function (error: any) {
        console.log("error", error);
        setBool_Btn(false);
        setShow__cancel(true);
        setTimeout(() => {
          setShow__cancel(false);
        }, 3000);
      });
  };
  const click_borrow = () => {
    if (!borrow_number) {
      return console.log("borrow_number undefined");
    }
    if (new BigNumber(borrow_number).eq(new BigNumber(0))) {
      return console.log("borrow_number is 0...");
    }
    if (bool_Btn) {
      return console.log("already borrowing...");
    }
    setBool_Btn(true);
    let to_bn__value = new BigNumber(borrow_number)
      .multipliedBy(new BigNumber(10).pow(new BigNumber(accountBorrowData[5])))
      .toString();

    Lending.contracts?.general["iM" + [this__token]].methods
      .borrow(is_borrow_max ? accountBorrowData[2] : to_bn__value)
      .send({ from: account })
      .on("transactionHash", function (hash: string) {
        console.log("transactionHash: ", hash);
        postDataToServer(
          wallet_source,
          "Mint",
          account || "",
          (chainId && network_Name[chainId]) || "",
          Lending.contracts?.general[this__token].address,
          accountBorrowData[5],
          borrowTokenData[3],
          is_borrow_max ? accountBorrowData[2] : to_bn__value
        );
        borrow_input_change("");
        add_Notifies({
          state: "pedding",
          action: "Mint " + format_num_to_K(format_bn(to_bn__value.toString(), accountBorrowData[5], 4)) + " " + this__token,
          transactionHash: hash,
          time: new Date().getTime()
        }, setNowNotifies, account as string, chainId as number);
        setBool_Btn(false);
      })
      .on("receipt", function (receipt: any) {
        console.log("receipt: ", receipt);
        getStatesData();
        update_Notifies(receipt, setNowNotifies, account as string, chainId as number);
        setTimeout(() => {
          del_Notify(receipt, setNowNotifies);
        }, 3000);
        setBool_Btn(false);
      })
      .on("error", function (error: any) {
        console.log("error", error);
        setBool_Btn(false);
        setShow__cancel(true);
        setTimeout(() => {
          setShow__cancel(false);
        }, 3000);
      });
  };
  const click_repay = () => {
    if (!repay_number) {
      return console.log("repay_number undefined");
    }
    if (new BigNumber(repay_number).eq(new BigNumber(0)) && !is_repay_max) {
      return console.log("repay_number is 0...");
    }
    if (
      new BigNumber(accountBorrowData[0]).eq(new BigNumber(0)) ||
      new BigNumber(accountBorrowData[4]).eq(new BigNumber(0))
    ) {
      return console.log("Token Borrowed is 0...");
    }
    if (bool_Btn) {
      return console.log("already repaying...");
    }
    setBool_Btn(true);
    let to_bn__value = new BigNumber(repay_number)
      .multipliedBy(new BigNumber(10).pow(new BigNumber(accountBorrowData[5])))
      .toString();
    let max_num = new BigNumber(2)
      .pow(new BigNumber(256))
      .minus(new BigNumber(1))
      .toString();

    let final_value = is_repay_max
      ? new BigNumber(accountBorrowData[3]).gt(
        new BigNumber(accountBorrowData[4])
      )
        ? max_num
        : accountBorrowData[4]
      : to_bn__value;

    console.log(final_value.toString());
    Lending.contracts?.general["iM" + [this__token]].methods
      .repayBorrow(final_value)
      .send({ from: account })
      .on("transactionHash", function (hash: string) {
        console.log("transactionHash: ", hash);
        postDataToServer(
          wallet_source,
          "Burn",
          account || "",
          (chainId && network_Name[chainId]) || "",
          Lending.contracts?.general[this__token].address,
          accountBorrowData[5],
          borrowTokenData[3],
          to_bn__value
        );
        repay_input_change("");
        add_Notifies({
          state: "pedding",
          action: "Burn " + format_num_to_K(format_bn(to_bn__value.toString(), accountBorrowData[5], 4)) + " " + this__token,
          transactionHash: hash,
          time: new Date().getTime()
        }, setNowNotifies, account as string, chainId as number);
        setBool_Btn(false);
      })
      .on("receipt", function (receipt: any) {
        console.log("receipt: ", receipt);
        getStatesData();
        update_Notifies(receipt, setNowNotifies, account as string, chainId as number);
        setTimeout(() => {
          del_Notify(receipt, setNowNotifies);
        }, 3000);
        setBool_Btn(false);
      })
      .on("error", function (error: any) {
        console.log("error", error);
        setBool_Btn(false);
        setShow__cancel(true);
        setTimeout(() => {
          setShow__cancel(false);
        }, 3000);
      });
  };
  const click_repay__spe = () => {
    if (!repay_number) {
      return console.log("repay_number undefined");
    }
    if (new BigNumber(repay_number).eq(new BigNumber(0)) && !is_repay_max) {
      return console.log("repay_number is 0...");
    }
    if (bool_Btn) {
      return console.log("already repaying...");
    }
    setBool_Btn(true);
    let to_bn__value = new BigNumber(repay_number)
      .multipliedBy(new BigNumber(10).pow(new BigNumber(accountBorrowData[5])))
      .toString();

    // let max_num = new BigNumber(2).pow(new BigNumber(256)).minus(new BigNumber(1)).toString()

    let final_value = is_repay_max
      ? new BigNumber(accountBorrowData[3]).gt(
        new BigNumber(accountBorrowData[4])
      )
        ? new BigNumber(accountBorrowData[3]).gt(
          new BigNumber(accountBorrowData[4]).multipliedBy(
            new BigNumber(1.03)
          )
        )
          ? new BigNumber(accountBorrowData[4])
            .multipliedBy(new BigNumber(1.03))
            .toFixed(0)
            .toString()
          : accountBorrowData[3]
        : accountBorrowData[4]
      : to_bn__value;

    console.log(final_value.toString());
    Lending.contracts?.general[this__token].methods
      .repayBorrow()
      .send({ from: account, value: final_value })
      .on("transactionHash", function (hash: string) {
        console.log("transactionHash: ", hash);
        postDataToServer(
          wallet_source,
          "Burn",
          account || "",
          (chainId && network_Name[chainId]) || "",
          Lending.contracts?.general[this__token].address,
          accountBorrowData[5],
          borrowTokenData[3],
          final_value
        );
        repay_input_change("");
        add_Notifies({
          state: "pedding",
          action: "Burn " + format_num_to_K(format_bn(to_bn__value.toString(), accountBorrowData[5], 4)) + " " + this__token,
          transactionHash: hash,
          time: new Date().getTime()
        }, setNowNotifies, account as string, chainId as number);
        setBool_Btn(false);
      })
      .on("receipt", function (receipt: any) {
        console.log("receipt: ", receipt);
        getStatesData();
        update_Notifies(receipt, setNowNotifies, account as string, chainId as number);
        setTimeout(() => {
          del_Notify(receipt, setNowNotifies);
        }, 3000);
        setBool_Btn(false);
      })
      .on("error", function (error: any) {
        console.log("error", error);
        setBool_Btn(false);
        setShow__cancel(true);
        setTimeout(() => {
          setShow__cancel(false);
        }, 3000);
      });
  };


  const click_approve_pre = () => {
    const curTokenAddress = Lending.contracts?.general[this__token].address
    if (DFApproveXArray.includes(curTokenAddress)) {
      click_approve(true)
    } else {
      click_approve()
    }
  }
  const click_approve = (reApprove?: boolean) => {
    if (bool_Btn__enable) {
      return console.log("already approving...");
    }
    setBool_Btn__enable(true);

    Lending.contracts?.general[this__token].methods
      .approve(
        Lending?.contracts.general[
          !Lending?.contracts.general[this__token].gasToken ?
            "iM" + this__token : this__token
        ].address,
        reApprove ? '0' : ethers.constants.MaxUint256
      )
      .send({ from: account })
      .on("transactionHash", function (hash: string) {
        console.log("transactionHash: ", hash);
        setEnable_status(fmt({ id: "ENABLING" }));
        add_Notifies({
          state: "pedding",
          action: "Enable " + this__token,
          transactionHash: hash,
          time: new Date().getTime()
        }, setNowNotifies, account as string, chainId as number);
      })
      .on("receipt", function (receipt: any) {
        console.log("receipt: ", receipt);
        getStatesData();
        update_Notifies(receipt, setNowNotifies, account as string, chainId as number);
        setTimeout(() => {
          del_Notify(receipt, setNowNotifies);
          if (reApprove) {
            click_approve()
          }
        }, 3000);
        // setBool_Btn__enable(false)
      })
      .on("error", function (error: any) {
        console.log("error", error);
        setBool_Btn__enable(false);
        setEnable_status(fmt({ id: "ENABLE" }));
        setShow__cancel(true);
        setTimeout(() => {
          setShow__cancel(false);
        }, 3000);
      });
    // if (DFApproveXArray.includes(Lending.contracts[`${query.get('currentPool')}`][this__token].address)) {
    //   Lending.contracts[`${query.get('currentPool')}`][this__token].methods
    //     .approvex(
    //       Lending?.contracts[`${query.get('currentPool')}`][
    //         !Lending?.contracts[`${query.get('currentPool')}`][this__token].gasToken ?
    //           "iM" + this__token : this__token
    //       ].address
    //     )
    //     .send({ from: account })
    //     .on("transactionHash", function (hash: string) {
    //       console.log("transactionHash: ", hash);
    //       setEnable_status(fmt({ id: "ENABLING" }));
    //       add_Notifies({
    //         state: "pedding",
    //         action: "Enable " + this__token,
    //         transactionHash: hash,
    //         time: new Date().getTime()
    //       }, setNowNotifies, account as string, chainId as number);
    //     })
    //     .on("receipt", function (receipt: any) {
    //       console.log("receipt: ", receipt);
    //       getStatesData();
    //       update_Notifies(receipt, setNowNotifies, account as string, chainId as number);
    //       setTimeout(() => {
    //         del_Notify(receipt, setNowNotifies);
    //       }, 3000);
    //       // setBool_Btn__enable(false)
    //     })
    //     .on("error", function (error: any) {
    //       console.log("error", error);
    //       setBool_Btn__enable(false);
    //       setEnable_status(fmt({ id: "ENABLE" }));
    //       setShow__cancel(true);
    //       setTimeout(() => {
    //         setShow__cancel(false);
    //       }, 3000);
    //     });
    // } else { }
  };

  return (
    <>
      <UnlockWalletModal
        isOpen={unlockModalIsOpen}
        onDismiss={handleDismissUnlockModal}
        metamaskActive={metamaskActive}
        walletConnectActive={walletConnectActive}
      />

      <Crumb>
        <span>
          <RouterLink to={`/lending`}>
            <BackToHome>{fmt({ id: "Home" })}</BackToHome>
          </RouterLink>
        </span>
        <Notification
          chainID={chainID}
          notifies={nowNotifies}
          setNowNotifies={setNowNotifies}
          del_Notify={del_Notify}
        />
        <Notification__cancel
          notifies={show__cancel}
          setShow__cancel={setShow__cancel}
          del_Notify={del__cancel}
        />
      </Crumb>

      <BorderWrap>
        <StyledLeftSection>
          <NoteTips>{fmt({ id: "mint_notes" })}</NoteTips>

          <PaddingWrap>
            <Logo_Token_number
              tab={"mint"}
              token={this__token}
              myWallet={account}
              value={
                accountBorrowData
                  ? format_bn(accountBorrowData[0], accountBorrowData[5], 2) ===
                    "0.00"
                    ? format_bn(accountBorrowData[0], accountBorrowData[5])
                    : format_num_to_K(
                      format_bn(accountBorrowData[0], accountBorrowData[5], 2)
                    )
                  : ""
              }
            />

            <ActionTab>
              <StyledTab onClick={() => { setIsChoosen__borrow__tab(true) }} className={isChoosen__borrow__tab ? 'isChoosen' : ''}>
                {fmt({ id: "MINT" })}
              </StyledTab>
              <StyledTab onClick={() => { setIsChoosen__borrow__tab(false) }} className={isChoosen__borrow__tab ? '' : 'isChoosen'}>
                {fmt({ id: "BURN" })}
              </StyledTab>
            </ActionTab>

            {
              !local_account ?
                <>
                  <StyledApproveFirst>
                    {fmt({ id: "connect_wallet_first" })}
                  </StyledApproveFirst>
                  <StyledSupply_btn onClick={handleUnlockWalletClick}>
                    {fmt({ id: "Connect_Wallet" })}
                  </StyledSupply_btn>
                </>
                :
                <>
                  {
                    !(borrowTokenData && accountBorrowData && accountBorrowInfo && accountTotalValue) ?
                      <LogoImgWrap>
                        <LogoImg src={dForceLogo}></LogoImg>
                      </LogoImgWrap>
                      :
                      <>
                        {
                          isChoosen__borrow__tab ?
                            <>
                              <Token_Number
                                tab={"mint"}
                                token={this__token}
                                myWallet={"account-0x"}
                                value={
                                  accountBorrowData ?
                                    format_num_to_K(format_bn(accountBorrowData[2], accountBorrowData[5], 2)) : ""
                                }
                              />

                              {
                                // markets closed ?
                                accountBorrowInfo && accountBorrowInfo[3] === false ?
                                  <StyledYouDid>
                                    {fmt({ id: "markets_closed" })}
                                  </StyledYouDid>
                                  :
                                  <>
                                    {
                                      accountSupplyData && new BigNumber(accountSupplyData[0]).gt(new BigNumber(0)) ?
                                        <StyledYouDid>
                                          {fmt({ id: "before_mint_tokens" })}
                                        </StyledYouDid>
                                        :
                                        <>
                                          <InputWrap>
                                            <Styledinput
                                              placeholder={fmt({ id: "Amount" })}
                                              onChange={(e) => { borrow_input_change(e.target.value) }}
                                              value={borrow_number || ""}
                                            />
                                            <SpanMax onClick={borrow_max}>
                                              {fmt({ id: "safe_max" })}
                                            </SpanMax>
                                          </InputWrap>

                                          <RangeWrap>
                                            <StyledPercentage>
                                              <PercentageItem
                                                onClick={() => { changePercentage(100) }}
                                                className={curPercentage === 100 ? 'isPercentageChoosen' : ''}>
                                                100%</PercentageItem>
                                              <PercentageItem
                                                onClick={() => { changePercentage(75) }}
                                                className={curPercentage === 75 ? 'isPercentageChoosen' : ''}>
                                                75%</PercentageItem>
                                              <PercentageItem
                                                onClick={() => { changePercentage(50) }}
                                                className={curPercentage === 50 ? 'isPercentageChoosen' : ''}>
                                                50%</PercentageItem>
                                              <PercentageItem
                                                onClick={() => { changePercentage(25) }}
                                                className={curPercentage === 25 ? 'isPercentageChoosen' : ''}>
                                                25%</PercentageItem>
                                            </StyledPercentage>
                                            {
                                              accountBorrowData && accountBorrowData[1] === accountBorrowData[2] &&
                                              <OverSafeMAX>
                                                {fmt(
                                                  { id: "borrow__warning" },
                                                  {
                                                    params:
                                                      format_num_to_K(format_bn(accountBorrowData[1], accountBorrowData[5], 2)) +
                                                      (intl_locale === "cn" ? "" : " ") +
                                                      this__token,
                                                  }
                                                )}
                                              </OverSafeMAX>
                                            }
                                          </RangeWrap>
                                        </>
                                    }
                                  </>
                              }

                              <StyledSupply_btn
                                className={
                                  (accountSupplyData && new BigNumber(accountSupplyData[0]).gt(new BigNumber(0))) ||
                                    (accountBorrowInfo && accountBorrowInfo[3] === false) ||
                                    bool_Btn ?
                                    "disabledBtn" : ""
                                }
                                onClick={isGasToken ? click_borrow__spe : click_borrow}
                              >
                                {fmt({ id: "MINT" })}
                              </StyledSupply_btn>
                            </>
                            :
                            <>
                              {
                                // allowance?.toNumber() === 0 ?
                                //   <>
                                //     <StyledApproveFirst>
                                //       {fmt({ id: "enable_first__mint" }, { token: this__token })}
                                //     </StyledApproveFirst>
                                //     <StyledSupply_btn className={bool_Btn__enable ? "disabledBtn" : ""} onClick={click_approve}>
                                //       {enable_status}
                                //     </StyledSupply_btn>
                                //   </>
                                //   :
                                <>
                                  <Token_Number
                                    tab={"supply"}
                                    token={this__token}
                                    myWallet={"account-0x"}
                                    value={
                                      accountBorrowData ?
                                        new BigNumber(accountBorrowData[3]).gt(new BigNumber(0)) &&
                                          new BigNumber(accountBorrowData[3]).div(new BigNumber(10).pow(new BigNumber(accountBorrowData[5]))).lt(new BigNumber(0.01)) ?
                                          format_num_to_K(format_bn(accountBorrowData[3], accountBorrowData[5]))
                                          :
                                          format_num_to_K(format_bn(accountBorrowData[3], accountBorrowData[5], 2))
                                        :
                                        ""
                                    }
                                  />
                                  <InputWrap>
                                    <Styledinput
                                      placeholder={fmt({ id: "Amount" })}
                                      onChange={(e) => { repay_input_change(e.target.value) }}
                                      value={repay_number || ""}
                                    />
                                    <SpanMax onClick={repay_max}>
                                      {fmt({ id: "MAX" })}
                                    </SpanMax>
                                  </InputWrap>

                                  <RangeWrap>
                                    <StyledPercentage>
                                      <PercentageItem
                                        onClick={() => { changePercentage__tab2(100) }}
                                        className={curPercentage__tab2 === 100 ? 'isPercentageChoosen' : ''}>
                                        100%</PercentageItem>
                                      <PercentageItem
                                        onClick={() => { changePercentage__tab2(75) }}
                                        className={curPercentage__tab2 === 75 ? 'isPercentageChoosen' : ''}>
                                        75%</PercentageItem>
                                      <PercentageItem
                                        onClick={() => { changePercentage__tab2(50) }}
                                        className={curPercentage__tab2 === 50 ? 'isPercentageChoosen' : ''}>
                                        50%</PercentageItem>
                                      <PercentageItem
                                        onClick={() => { changePercentage__tab2(25) }}
                                        className={curPercentage__tab2 === 25 ? 'isPercentageChoosen' : ''}>
                                        25%</PercentageItem>
                                    </StyledPercentage>
                                  </RangeWrap>

                                  {
                                    allowance?.toNumber() === 0
                                      ? <>
                                        <StyledSupply_btn className={bool_Btn__enable ? "disabledBtn" : ""} onClick={() => { click_approve() }}>
                                          {enable_status}
                                        </StyledSupply_btn>
                                      </>
                                      : allowance &&
                                        new BigNumber(repay_number)
                                          .multipliedBy(new BigNumber(10).pow(new BigNumber(accountBorrowData[5])))
                                          .gt(new BigNumber(allowance))
                                        ? <StyledSupply_btn className={bool_Btn__enable ? "disabledBtn" : ""} onClick={click_approve_pre}>
                                          {enable_status}
                                        </StyledSupply_btn>
                                        : <StyledSupply_btn
                                          className={bool_Btn ? "disabledBtn" : ""}
                                          onClick={isGasToken ? click_repay__spe : click_repay}
                                        >
                                          {fmt({ id: "BURN" })}
                                        </StyledSupply_btn>
                                  }
                                </>
                              }
                            </>
                        }
                      </>
                  }
                </>
            }


          </PaddingWrap>
        </StyledLeftSection>
        <StyleRightSection>
          <ChangeableValue
            i18n_text={"Borrow_Balance"}
            value={accountTotalValue ? "$" + format_num_to_K(format_bn(accountTotalValue[2], 18, 2)) : ""}
            isChoosen__supply__tab={isChoosen__borrow__tab}
            is_green={true}
          />
          <ChangeableValue
            i18n_text={"Health_Factor"}
            showIcon={fmt({ id: "Health_Factor_tips" })}
            value={
              accountTotalValue ?
                accountTotalValue[2] !== "0" ?
                  new BigNumber(accountTotalValue[3]).gt(new BigNumber(10).pow(new BigNumber(22))) ?
                    ">10,000" : format_num_to_K(format_bn(accountTotalValue[3], 18, 2)) : ""
                : ""
            }
            isChoosen__supply__tab={isChoosen__borrow__tab}
            is_green={true}
          />
          <ChangeableValue
            i18n_text={"mint_apy"}
            value={borrowTokenData ? format_num_to_K(format_bn(borrowTokenData[2], 16, 2)) + "%" : ""}
            showIcon={fmt({ id: "mint_apy_tips" })}
          />
          <ChangeableValue
            i18n_text={"price"}
            value={borrowTokenData ? "$" + format_num_to_K(format_bn(borrowTokenData[3], 18, 2)) : ""}
            token={this__token}
          />
          <StyledChart>
            <ReactApexChart
              options={chartState.options}
              series={chartState.series}
              type="area"
              height={320}
            />
          </StyledChart>
        </StyleRightSection>
      </BorderWrap>
    </>
  );
};


const StyledPercentage = styled.div`
  /* background: green; */
  display: flex;
  flex-direction: row-reverse;
`;
const PercentageItem = styled.div`
  width: 66px;
  text-align: center;
  height: 32px;
  line-height: 30px;
  border-radius: 30px;
  margin-left: 6px;
  font-size: 14px;
  font-weight: 500;
  color: #9598A4;
  cursor: pointer;
  border: 1px solid ${(props) => props.theme.operationPage.light.borderColor};

  .dark & {
    border: 1px solid ${(props) => props.theme.operationPage.dark.borderColor};

    &.isPercentageChoosen {
      background: #507BFC;
      color: #fff;
      opacity: 1;
    }
  }

  &.isPercentageChoosen {
    background: #507BFC;
    color: #fff;
    opacity: 0.8;
  }
`;

const StyledTab = styled.div`
  width: 50%;
  text-align: center;
  cursor: pointer;
  color: ${(props) => props.theme.operationPage.grey};
  position: relative;

  &.isChoosen {
    color: ${(props) => props.theme.operationPage.light.mainBlack};

    .dark & {
      color: ${(props) => props.theme.operationPage.dark.mainBlack};
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 28px;
      height: 5px;
      background-color: #507BFC;
      border-radius: 3px;
      left: calc(50% - 14px);
      bottom: -20px;

      @media (max-width: 1119px) {
        bottom: -14px;
        height: 3px;
      }
    }
  }
`

const LogoImgWrap = styled.div`
  text-align: center;
  padding-top: 90px;
  height: 279px;

  @media (max-width: 1119px) {
    height: 224px;
    padding-top: 70px;
  }
`;
const LogoImg = styled.img`
  width: 50px;
  height: 50px;
  @media (max-width: 1119px) {
    width: 40px;
    height: 40px;
  }
`;




// change height
const RangeWrap = styled.div`
  height: 120px;
  padding-top: 10px;
  position: relative;

  .input-range__slider {
    background: #507BFC !important;
    border: 1px solid #507BFC !important;
  }

  .input-range__track--active {
    background: #507BFC !important;
  }
`;
const StyledRange = styled.div`
  position: absolute;
  right: 0;
  color: #507BFC;
  font-size: 14px;
  font-weight: 500;
  top: 28px;
`;


const Styledinput = styled.input`
  height: 100%;
  width: 100%;
  font-size: 16px;
  padding-left: 20px;
  font-weight: bold;
  color: ${(props) => props.theme.operationPage.light.mainBlack};
  background: ${(props) => props.theme.operationPage.light.noteTipsBG};

  .dark & {
    color: ${(props) => props.theme.operationPage.dark.mainBlack};
    background: ${(props) => props.theme.operationPage.dark.noteTipsBG};
  }

  @media (max-width: 1119px) {
    font-size: 13px;
    padding-left: 14px;
  }
`;

const SpanMax = styled.span`
  position: absolute;
  right: 20px;
  top: 16px;
  height: 30px;
  line-height: 30px;
  color: #507BFC;
  font-weight: bold;
  border-radius: 8px;
  background: #deedeb;
  cursor: pointer;
  padding-left: 14px;
  padding-right: 14px;
  opacity: 0.8;
  transition: 0.1s;

  &:hover {
    opacity: 1;
  }

  @media (max-width: 1119px) {
    font-size: 13px;
    right: 14px;
    top: 9px;
    height: 24px;
    line-height: 24px;
    padding-left: 10px;
    padding-right: 10px;
  }
`;

const StyledSupply_btn = styled.div`
  height: 60px;
  line-height: 60px;
  text-align: center;
  background: #507BFC;
  color: #ffffff;
  border-radius: 12px;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  opacity: 0.9;
  transition: 0.1s;

  &:hover {
    opacity: 1;
  }

  @media (max-width: 1119px) {
    height: 42px;
    line-height: 42px;
    font-size: 13px;
  }
`;

const RouterLink = styled(NavLink)`
  color: ${(props) => props.theme.colors.primary.light};
  &:hover {
    color: ${(props) => props.theme.colors.primary.light};
  }
`;

export default MintBURN;
