import React from "react";
import styled from "styled-components";


const YouDid = ({ children }: {
    children: React.ReactNode
  }) => {
    return (
        <StyledYouDid>
            {children}
        </StyledYouDid>
    );
}

// change height
const StyledYouDid = styled.div`
    margin-top: 20px;
    height: 132px;
    background: #fff8f8;
    margin-bottom: 54px;
    font-size: 14px;
    font-weight: 400;
    color: #ff6565;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    text-align: center;
    padding: 0 60px;
`

export default YouDid

