import React from "react"
import styled, { keyframes } from "styled-components"

interface BarProgressProps {
  value?: number;
  invert?: boolean;
  bgColor?: string;
  innerBgColor?: string;
  type?: "buffer" | "normal";
}

const Bar: React.FC<BarProgressProps> = ({ value, invert, bgColor, innerBgColor, type }) => {
  if (invert) {
    value = 100 - (value || 0);
  }

  return (
    <>
      <StyledBar className="progressbar" bgColor={bgColor} innerBgColor={innerBgColor}>
        <StyledBarInner
          className={`${type === "buffer" ? "buffer" : ""}`}
          style={{ width: `${value}%` }}
          innerBgColor={innerBgColor} />
        {/* <StyledBarProgressText>{value}%</StyledBarProgressText> */}
        {/* <StyledBarProgressText></StyledBarProgressText> */}
      </StyledBar>
    </>
  );
};

interface StyledBarProps {
  bgColor?: string,
  innerBgColor?: string
}

interface StyledBarProgressTextProps {
  darkMode?: boolean;
}

interface StyledBarInnerProps {
  innerBgColor?: string
}
const buffermove = keyframes`
      0% {
       background-position: 0 0;
     }
     100% {
       background-position: 80px 0;
     }
`;

const StyledBar = styled.div<StyledBarProps>`
  background:${props => props.bgColor};
  border-radius: 15px;
  position: relative;
  overflow: hidden;
  width: 100%;
  height:6px;
  .buffer {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 6px;
    display: block;
    background-color: ${props => props.innerBgColor};
    background-size: 80px 50px;
    animation: ${buffermove} 2s linear infinite;
  }
  @media (max-width: 767px) {
    position:relative;
    display:block;
    overflow:inherit;
    height:4px;
    .buffer {
      height:4px;
    }
  }
`;

const StyledBarInner = styled.div<StyledBarInnerProps>`
  background:${(props) => props.innerBgColor};
  border-radius: 15px;
  height: 6px;
  transition: width 0.8s ease-in;
  @media (max-width: 767px) {
    height:6px;
  }
`;

const StyledBarProgressText = styled.div<StyledBarProgressTextProps>`
  cursor: default;
  position: relative;
  top: 0px;
  font-weight: 700;
  text-align: center;
  height:6px;
  line-height:6px;
  color: white;
  z-index: 90;
  opacity: 0.95;
`;

export default Bar;
