import React from "react"
import { useIntl } from "react-intl"
import styled from "styled-components"
import numeral from "numeral"
import BigNumber from "bignumber.js"
import { bnToDec, numberFormatter, format_bn, _formatNumberNotCarry } from "utils"
import LendTitle from "components/LendTitle"


interface TotalAssetsProps {
  totalAssets: any;
  totalAssetsTextList: any[]
  BLP?: any
}
const TotalAssets: React.FC<TotalAssetsProps> = ({ totalAssets, totalAssetsTextList, BLP }) => {
  const intl = useIntl()
  const fmt = intl.formatMessage
  let totalValue = totalAssets
  return (
    <AssetWrap>
      <StyledTitle>
        {fmt({ id: "My_Assets" })}
      </StyledTitle>

      <AssetsWarp>
        <AssetsItem>
          <h1>
            {
              totalValue
                ? '$' + numberFormatter(format_bn(totalValue[0], 18, 2), 2)
                // ? '$' + _formatNumberNotCarry(bnToDec(new BigNumber(totalValue[0]), 18,2),'abbr')
                : '...'
            }
          </h1>
          <p>
            <span>{fmt({ id: totalAssetsTextList[0] })}</span>
          </p>
        </AssetsItem>

        <AssetsItem>
          <h1>
            {
              totalValue
                ? '$' + numberFormatter(format_bn(totalValue[2], 18, 2), 2)
                // ? '$' + _formatNumberNotCarry(bnToDec(new BigNumber(totalValue[2]), 18,2),'abbr')
                : '...'
            }
          </h1>
          <p>
            <span>{fmt({ id: totalAssetsTextList[1] })}</span>
          </p>
        </AssetsItem>

        <AssetsItem>
          <h1>
            {
              totalValue
                ? bnToDec(new BigNumber(totalValue[3].toString()), 18) >= 10000
                  ? new BigNumber(totalValue[3].toString()).eq(new BigNumber(2).pow(new BigNumber(256)).minus(new BigNumber(1)))
                    ? '...'
                    : '>10,000'
                  : bnToDec(new BigNumber(totalValue[3].toString()), 18) === 0
                    ? '...'
                    : bnToDec(new BigNumber(totalValue[3].toString()), 18) < 0.01
                      ? '<0.01'
                      : numeral(bnToDec(new BigNumber(totalValue[3].toString()), 18)).format("0,0.00")
                // ? '<0.01' : _formatNumberNotCarry(bnToDec(new BigNumber(totalValue[3]), 18,2),'abbr'))
                : '...'
            }
          </h1>
          <p>
            <span>{fmt({ id: totalAssetsTextList[2] })}</span>
          </p>
        </AssetsItem>
        {
          BLP &&
          <AssetsItem>
            <Ad>
              {
                BLP["value"] ?
                  ((bnToDec(new BigNumber(BLP["value"]), 18) > 0 && bnToDec(new BigNumber(BLP["value"]), 18) < 0.01)
                    ? '<$0.01'
                    : BLP["value"] === '0' ?
                      '$0.00'
                      : `${BLP.dollar ? '$' : ''}${_formatNumberNotCarry(bnToDec(new BigNumber((BLP["value"])), 18, 4), 'abbr')}`)
                  : '...'
              }
              {
                BLP.thresholdStatus === 0 ?
                  <span className="red">({fmt({ id: 'Noemissions' })})</span>
                  : BLP.thresholdStatus === 1 ?
                    <span className="red">
                      (${BLP.needed && _formatNumberNotCarry(bnToDec(BLP.needed, 18, 4), 'abbr')}&nbsp;{fmt({ id: 'eligible' })})
                    </span>
                    : BLP.thresholdStatus === 2 ?
                      <span className="green">({fmt({ id: 'Eligibleforemissions' })})</span>
                      : ''
              }
            </Ad>
            <p>
              <span>{fmt({ id: BLP["title"] })}</span>
            </p>
          </AssetsItem>
        }
      </AssetsWarp>
    </AssetWrap>
  )
}



const AssetWrap = styled.div`
  height: 224px;
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 1199px) {
    height: auto;
    padding: 16px 0;
  }
`

const StyledTitle = styled.div`
  color: #FFF;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 16px;
  @media (max-width: 1199px) {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 0;
  }
`
const AssetsWarp = styled.div`
  width: 100%;
  /* height: 120px; */
  display: flex;
  color:#ffffff;
  flex-wrap: wrap;
`;
const AssetsItem = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 130px;
  box-sizing: border-box;
  &:last-child{
    margin-right: 0;
  }
  @media (max-width: 1199px) {
    flex: 1;
    flex-basis: max-content;
    margin-right: 0;
  }
  h1 {    
    color: #FFF;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    @media (max-width: 1199px) {
      font-size: 16px;
      line-height: 28px;
      margin-top: 10px;
      margin-bottom: 0px;
    }
  }
  p {
    color: ${(props) => props.theme.global_grey};
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    font-style: normal;
    @media (max-width: 1199px) {
      font-size: 12px;
      line-height: 12px;
    }
  }
`
const Ad = styled.h1`
    font-size: 32px;
    height: 32px;
    line-height: 32px;
    /* margin-bottom: 14px; */
    font-weight: 700;
    color: ${(props) => props.theme.fontColor[100]};
    span{
      margin-left: 5px;
      font-size: 18px;
    }
    .red{
      color:${(props) => props.theme.colors.red};
    }
    .green{
      color:${(props) => props.theme.colors.borrow.dark};
    }
    @media(max-width: 1119px){
      margin-top: 10px;
      margin-bottom: 0;
      height: 28px;
      line-height: 28px;
      font-size: 16px;
      color: ${(props) => props.theme.fontColor[100]};
      span{
        font-size: 14px;
      }
      /* color: ${(props) => props.theme.DarkfontColor[100]};
      .dark & {
        color: ${(props) => props.theme.fontColor[100]};
      } */
    }
`

export default TotalAssets
