"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EQUITY_DECIMALS = exports.DEBT_DECIMALS = exports.DEBT_DECIMAL_FACTOR = exports.INFINITY = exports.ZERO = exports.DAYS_PER_YEAR = exports.BASE = exports.UNITS_PER_DAY = void 0;
const ethers_1 = require("ethers");
exports.UNITS_PER_DAY = ethers_1.BigNumber.from("86400"); // 60 * 60 * 24
exports.BASE = ethers_1.utils.parseEther("1");
exports.DAYS_PER_YEAR = ethers_1.BigNumber.from("365");
exports.ZERO = ethers_1.BigNumber.from("0");
exports.INFINITY = ethers_1.constants.MaxUint256;
exports.DEBT_DECIMAL_FACTOR = ethers_1.BigNumber.from("100");
exports.DEBT_DECIMALS = 2;
exports.EQUITY_DECIMALS = ethers_1.BigNumber.from("18");
