/* eslint-disable react/jsx-pascal-case */
import React, { useCallback, useState, useEffect } from "react"
import styled from "styled-components"
import { useIntl } from "react-intl"
import Web3 from 'web3'
import BigNumber from "bignumber.js"
import ReactTooltip from 'react-tooltip'
import { isMobile } from 'react-device-detect'
// import { L1ToL2MessageGasEstimator } from "@arbitrum/sdk-nitro"
import { L1TransactionReceipt, L1ToL2MessageStatus,L1ToL2MessageGasEstimator } from "@arbitrum/sdk"
import {
  ethers,
  providers,
  BigNumber as BigNumber_ethers,
  utils as utils_ethers,
  Wallet,
} from "ethers"
import {
  format_bn, format_num_to_K,
  // getMaxSubmissionPrice, getMaxGas,
  // calculateL2TransactionHash, calculateL2RetryableTransactionHash,
  DFApproveXArray, setupNetwork,
} from "utils"
import {
  add_Notifies__bridge, update_Notifies__bridge, del_Notify,
  del__cancel, add__cbridge, del__cbridge, update__cbridge,
  add__local, update__local, add_Notifies__bridge__addApprove
} from 'lending-sdk/utils/index1'
import Notification_forBridge from "components/Notification__forBridge"
import Notification__cancel from "components/Notification__cancel"
import Notification__cbridge from "components/Notification__cbridge"
import Notification from "components/Notification"
import Modal from 'components/ModalToken'
import ModalTitle from 'components/ModalToken/components/ModalTitle'
import ModalContent from 'components/ModalToken/components/ModalContent'
import useLending from "hooks/useLending"
import { useWeb3React } from '@web3-react/core'
import useDebounce from '../../hooks/useDebounce'
import { Watcher } from '@eth-optimism/watcher'
// components
import { SelectChainId, NetTypeOptions } from './components/SelectChainId'
import { configs, chainId_map, chainInfos, addtokenConfigs, lzChainConfigs } from './configs'
import { add_Notifies, update_Notifies } from 'lending-sdk/utils/index1'
// images
import tooltip from 'assets/supply/tooltip.svg'
import img__USX from "assets/tokenList/USX.svg"
import img__UTS from "assets/tokenList/UTS.svg"
import img__DF from "assets/tokenList/DF.svg"
import img_AddNetType from "assets/Bridge/AddNetType.svg"
// import img_setSlipe from "assets/Bridge/setSlipe.svg"
import img_setSlipe from "assets/Bridge/btn-set.svg"
import img_warning from "assets/Bridge/warning.png"
import img_transfer from "assets/Bridge/transfer1.svg"
import img_transfer2 from "assets/Bridge/transfer2.svg"
import img_close from "assets/Bridge/close.svg"
import erc20ABI from 'abis/erc20.json'
import BridgeABI from 'abis/BridgeABI.json'
import cBridgeABI from 'abis/cBridgeABI.json'
import lzTokenABI from 'abis/lzTokenABI.json'
import { getMessagesBySrcTxHash, waitForMessageReceived } from '@layerzerolabs/scan-client'




const Bridge: React.FC = () => {
  const Lending = useLending()
  const {
    connector,
    account,
    chainId,
    provider,
    ENSName,
    isActive,
    isActivating,
  } = useWeb3React();
  const intl = useIntl()
  const fmt = intl.formatMessage

  // TODO
  const [curToken, setCurToken] = useState('UTS')
  const [chainId_src, setChainId_src] = useState(1)
  const [chainId_dst, setChainId_dst] = useState(42161)
  const [usingCBridge, setUsingCBridge] = useState(false)
  const [max_slippage, setMax_slippage] = useState(0)

  const [curSlippage, setCurSlippage] = useState(5000)
  const [curBridgeErr, setCurBridgeErr] = useState('')
  const [curBridgeErr_OVER_CAP, setCurBridgeErr_OVER_CAP] = useState(false)
  const [curBridgeMaxSend, setCurBridgeMaxSend] = useState('')
  const [curBridgeMinSend, setCurBridgeMinSend] = useState('')

  const [fetchReady, setFetchReady] = useState(false)
  const [showSlipe, setShowSlipe] = useState(false)
  const [isOpen_token, setIsOpen_token] = useState(false)
  const [isOpen_network__source, setIsOpen_network__source] = useState(false)
  const [isOpen_network__target, setIsOpen_network__target] = useState(false)

  const [value__send, setValue__send] = useState('')
  const [value__send__max, setValue__send__max] = useState(false)
  const [value__send__origin, setValue__send__origin] = useState('0')
  const [bridgeFee, setBridgeFee] = useState('0')
  const [estimated_receive, setEstimated_receive] = useState('0')

  const [allowance_USX, setAllowance_USX] = useState('0')
  const [allowance_DF, setAllowance_DF] = useState('0')

  const [myBalance_USX, setMyBalance_USX] = useState('0')
  const [myBalance_DF, setMyBalance_DF] = useState('0')
  const [myBalance_UTS, setMyBalance_UTS] = useState('0')

  const [isTransfering, setIsTransfering] = useState(false)
  const [isApproveing, setIsApproveing] = useState(false)

  const [nowNotifies, setNowNotifies] = useState<any>([])
  const [show__cancel, setShow__cancel] = useState<boolean>(false)

  const [nowNotifies__cbridge, setNowNotifies__cbridge] = useState<any>([])


  const selectToken_open = useCallback(() => {
    setIsOpen_token(true)
  }, [isOpen_token])
  const selectToken_dismiss = useCallback(() => {
    setIsOpen_token(false)
  }, [isOpen_token])

  const selectNetworkSrc_open = useCallback(() => {
    if (isMobile) {
      return false
    }
    setIsOpen_network__source(true)
  }, [isOpen_network__source])
  const selectNetworkSrc_dismiss = useCallback(() => {
    setIsOpen_network__source(false)
  }, [isOpen_network__source])

  const selectNetworkDst_open = useCallback(() => {
    setIsOpen_network__target(true)
  }, [isOpen_network__source])
  const selectNetworkDst_dismiss = useCallback(() => {
    setIsOpen_network__target(false)
  }, [isOpen_network__source])




  useEffect(() => {
    try {
      if (chainId && chainId !== chainId_src) {
        setChainId_src(chainId)
      }

      setValue__send('')
      setValue__send__max(false)

      updateMyBalance()

      const timerUpdate = setInterval(() => {
        updateMyBalance()
      }, 1000 * 5)
      return () => { clearInterval(timerUpdate) }
    } catch (err) {
      // console.log(err)
    }
  }, [Lending, account, curToken, chainId_dst, chainId, chainId_src])

  useEffect(() => {
    setMyBalance_USX('0')
    setMyBalance_DF('0')
    setMyBalance_UTS('0')
  }, [Lending, account])


  // clear local history (cbridge)
  useEffect(() => {
    window.localStorage.removeItem('my_notify__cbridge')
    window.localStorage.removeItem('my_notify')
  }, [])



  const debouncedValue = useDebounce(value__send, 600)
  useEffect(() => {
    if (debouncedValue && usingCBridge) {
      try {
        // console.log('debouncedValue usingCBridge: ', debouncedValue, usingCBridge)
        // _maxSlippage
        const slippageUrl = `https://cbridge-prod2.celer.app/v2/estimateAmt?src_chain_id=${chainId_src
          }&dst_chain_id=${chainId_dst
          }&token_symbol=${curToken
          }&amt=${(new BigNumber(debouncedValue).multipliedBy(new BigNumber(10 ** 18))).toString()
          }&usr_addr=${account
          }&slippage_tolerance=${curSlippage
          }`
        // const slippageUrl = `https://cbridge-prod2.celer.network/v2/estimateAmt?src_chain_id=${42161
        //   }&dst_chain_id=${56
        //   }&token_symbol=${'DF'
        //   }&amt=${(new BigNumber(debouncedValue).multipliedBy(new BigNumber(10 ** 18))).toString()
        //   }&usr_addr=${account
        //   }&slippage_tolerance=${curSlippage
        //   }`

        let slippage = 0
        fetch(slippageUrl, { method: "GET" })
          .then((response) => response.json())
          .then((res) => {
            // console.log(res)
            if (res && res.err && res.err.code === 1002) {
              setCurBridgeErr('1002')
            }
            if (res && res.err && res.err.code === 1004) {
              setCurBridgeErr('1004')
            }
            if (res && res.err && res.err.code === 1005) {
              setCurBridgeErr('1005')
            }
            // ERROR_CODE_LIQ_OVER_CAP // 1017
            if (res && res.err && res.err.code === 1017) {
              setCurBridgeErr('1017')
            }

            if (res && res.err === null) {
              setCurBridgeErr('')
              // console.log(res.err)
            }

            setFetchReady(true)

            slippage = res.max_slippage ? res.max_slippage : 0
            // console.log(slippage)
            setMax_slippage(slippage)

            // base_fee: "16 430975967828623239"
            // perc_fee: "0"
            const base_fee = res.base_fee ? res.base_fee : '0'
            const perc_fee = res.perc_fee ? res.perc_fee : '0'
            const fee = new BigNumber(base_fee).plus(new BigNumber(perc_fee)).toString()
            // console.log((new BigNumber(res.estimated_receive_amt)).toString())
            // console.log(fee)
            setBridgeFee(fee)

            const estimated_receive = res.estimated_receive_amt ? res.estimated_receive_amt : '0'
            setEstimated_receive(estimated_receive)
          })
          .catch((e) => {
            // console.log(e)
          })
      } catch (err) {
        // console.log(err)
        setFetchReady(true)
      }
    } else {
      setBridgeFee('0')
      setEstimated_receive('0')
      // console.log('...debouncedValue: noooooooooooooo')
    }
  }, [debouncedValue])

  useEffect(() => {
    if (debouncedValue && curToken === 'UTS') {
      return
    }

    if (debouncedValue && usingCBridge) {
      try {
        // console.log('debouncedValue usingCBridge: ', debouncedValue, usingCBridge)
        const network = chainId_map[chainId_src]
        const curAddress = configs[network][curToken].address
        const contract_cBridge = new Lending.web3.eth.Contract(cBridgeABI, configs[network].bridge)
        // console.log(configs[network].bridge,curAddress)
        contract_cBridge.methods.maxSend(curAddress).call()
          .then((res: any) => {
            // console.log('MaxSend: ', res)
            setCurBridgeMaxSend(res)
          })
        contract_cBridge.methods.minSend(curAddress).call()
          .then((res: any) => {
            // console.log('MinSend: ', res)
            setCurBridgeMinSend(res)
          })
      } catch (err) {
        console.log(err)
      }
    }
  }, [debouncedValue])


  // TODO
  const getTokenInfo_USX = () => {
    try {
      const network = chainId_map[chainId_src]
      const tokenFromTo = `${curToken}-${chainId_map[chainId_src]}-${chainId_map[chainId_dst]}`
      // console.log(chainId_dst)
      // console.log(tokenFromTo)

      const token_USX = new Lending.web3.eth.Contract(erc20ABI, configs[network].USX.address)
      token_USX.methods.balanceOf(account).call()
        .then((res: string) => {
          // console.log('usx balance: ', res)
          setMyBalance_USX(res)
        })
      // console.log(configs[network])
      // console.log(configs[network][tokenFromTo])
      // console.log(configs[network][tokenFromTo].approve_to)
      token_USX.methods.allowance(
        account,
        configs[network][tokenFromTo]
          ? configs[network][tokenFromTo].approve_to
          : configs[network].bridge
      )
        .call()
        .then((res: string) => {
          // console.log('usx allowance: ', res)
          setAllowance_USX(res)
        })
    } catch (err) {
      // console.log(err)
    }
  }
  const getTokenInfo_DF = () => {
    try {
      const network = chainId_map[chainId_src]
      const tokenFromTo = `${curToken}-${chainId_map[chainId_src]}-${chainId_map[chainId_dst]}`

      const token = new Lending.web3.eth.Contract(erc20ABI, configs[network].DF.address)
      token.methods.balanceOf(account).call()
        .then((res: string) => {
          // console.log('df balance: ', res)
          setMyBalance_DF(res)
        })
      token.methods.allowance(
        account,
        configs[network][tokenFromTo]
          ? configs[network][tokenFromTo].approve_to
          : configs[network].bridge
      )
        .call()
        .then((res: string) => {
          // console.log('df allowance: ', res)
          setAllowance_DF(res)
        })
    } catch (err) {
      // console.log(err)
    }
  }
  const getTokenInfo_UTS = () => {
    try {
      const network = chainId_map[chainId_src]
      const token = new Lending.web3.eth.Contract(lzTokenABI, configs[network].UTS.address)
      token.methods.balanceOf(account).call()
        .then((res: string) => {
          // console.log('uts balance: ', res)
          setMyBalance_UTS(res)
        })
    } catch (err) {
      // console.log(err)
    }
  }
  const updateMyBalance = () => {
    getTokenInfo_USX()
    getTokenInfo_DF()
    getTokenInfo_UTS()
  }


  const selectToken = (token: string) => {
    setCurToken(token)
    setValue__send('')
    selectToken_dismiss()
  }




  // *** send ***
  // TODO (add token need update configs)
  const send__change = (val: string) => {
    try {
      if (!val) {
        setCurBridgeErr('')
      }
      setFetchReady(false)

      if (curToken !== 'UTS') {
        const network = chainId_map[chainId_src]
        const tokenFromTo = `${curToken}-${chainId_map[chainId_src]}-${chainId_map[chainId_dst]}`
        const transfer_bridge = configs[network][tokenFromTo]
          ? configs[network][tokenFromTo].type // dForce_bridge / dForce_bridge__op / dForce_bridge__polygon
          : 'cBridge'
        if (transfer_bridge === 'cBridge') {
          // console.log('cBridge')
          setUsingCBridge(true)
        } else {
          // console.log('not use cBridge')
          setUsingCBridge(false)
        }
      }
      if (curToken === 'UTS') { setUsingCBridge(false) }

      // console.log(val)

      let value__send__origin;
      switch (curToken) {
        case 'USX':
          value__send__origin = myBalance_USX
          break;
        case 'DF':
          value__send__origin = myBalance_DF
          break;
        case 'UTS':
          value__send__origin = myBalance_UTS
          break;
        default:
          value__send__origin = '0'
        // console.log('cur token err.')
      }
      // console.log(value__send__origin)

      let quantityScale = "6"
      let reg_maxLength = new RegExp("^(0|[1-9][0-9]*)(\\.[0-9]{0," + quantityScale + "})?$")
      if (!reg_maxLength.test(val) && val !== "") {
        return console.log("the decimal point exceeded.")
      }

      if (
        (new BigNumber(val).multipliedBy(new BigNumber(10 ** 18)))
          .gt(new BigNumber(value__send__origin))
      ) {
        // console.log('bigger than your balance')
        return send_max()
      }


      setValue__send__max(false)
      setValue__send(val)
    } catch (err) {
      // console.log(err)
    }
  }
  const send_max = () => {
    // console.log('max: ', 'cur token: ', curToken)
    if (curToken !== 'UTS') {
      const network = chainId_map[chainId_src]
      const tokenFromTo = `${curToken}-${chainId_map[chainId_src]}-${chainId_map[chainId_dst]}`
      const transfer_bridge = configs[network][tokenFromTo]
        ? configs[network][tokenFromTo].type // dForce_bridge / dForce_bridge__op / dForce_bridge__polygon
        : 'cBridge'
      if (transfer_bridge === 'cBridge') {
        // console.log('cBridge')
        setUsingCBridge(true)
      } else {
        // console.log('not use cBridge')
        setUsingCBridge(false)
      }
    }
    if (curToken === 'UTS') { setUsingCBridge(false) }

    setValue__send__max(true)

    switch (curToken) {
      case 'USX':
        // console.log('cur token: ', curToken, myBalance_USX)
        const value__send__usx = format_bn(myBalance_USX, 18, 6)
        setValue__send(value__send__usx)
        setValue__send__origin(myBalance_USX)
        break;
      case 'DF':
        // console.log('cur token: ', curToken, myBalance_DF)
        const value__send__df = format_bn(myBalance_DF, 18, 6)
        setValue__send(value__send__df)
        setValue__send__origin(myBalance_DF)
        break;
      case 'UTS':
        // console.log('cur token: ', curToken, myBalance_DF)
        const value__send__uts = format_bn(myBalance_UTS, 18, 6)
        setValue__send(value__send__uts)
        setValue__send__origin(myBalance_UTS)
        break;
      default:
      // console.log('cur token err.')
    }
  }


  // *** network change ***
  const SwitchExchangeState = async () => {
    if (isMobile) {
      return false
    }
    const sourceNet = chainId_src
    const targetNet = chainId_dst
    const switchStatus = await setupNetwork(chainInfos[targetNet])
    if (switchStatus !== 'cancelSwitch') {
      setChainId_src(targetNet)
      setChainId_dst(sourceNet)
    }
  }
  useEffect(() => {
    if (chainId && chainId_dst) {
      try {
        setValue__send('')
        setValue__send__max(false)
        setCurBridgeErr('')
        setCurBridgeErr_OVER_CAP(false)
        setCurBridgeMaxSend('')
        setCurBridgeMinSend('')

        const origin_src = chainId_src
        setChainId_src(chainId)
        if (
          chainId === 2222 || chainId === 324
        ) { setCurToken('USX') }
        if (chainId_dst === chainId) {
          setChainId_dst(origin_src)
        }
      } catch (err) {
        // console.log(err)
      }
    }
  }, [chainId, chainId_dst])

  const select_ChainId__source = async (chain: number) => {
    // console.log(chain)
    if (isMobile) {
      return false
    }
    try {
      const origin_src = chainId_src
      const switchStatus = await setupNetwork(chainInfos[chain])  // cancelSwitch
      // console.log(switchStatus)
      if (switchStatus !== 'cancelSwitch') {
        setChainId_src(chain)
        if (
          chain === 2222 || chain === 324
        ) { setCurToken('USX') }
        if (chainId_dst === chain) {
          setChainId_dst(origin_src)
        }
        selectNetworkSrc_dismiss()
      } else {
        selectNetworkSrc_dismiss()
      }
    } catch (err) {
      // console.log(err)
    }
  }
  const select_ChainId__target = (chain: number) => {
    setChainId_dst(chain)
    selectNetworkDst_dismiss()
  }


  const pre_approve = () => {
    const network = chainId_map[chainId_src]
    const curTokenAddress = configs[network][curToken].address
    if (DFApproveXArray.includes(curTokenAddress)) {
      approve(true)
    } else {
      approve()
    }
  }

  const approve = (reApprove?: boolean) => {
    // console.log(Lending, account, chainId, curToken)
    try {
      setIsApproveing(true)

      const network = chainId_map[chainId_src]
      const tokenFromTo = `${curToken}-${chainId_map[chainId_src]}-${chainId_map[chainId_dst]}`

      const token = new Lending.web3.eth.Contract(erc20ABI, configs[network][curToken].address)
      // console.log('approveX',token,token._address)
      token.methods.approve(
        configs[network][tokenFromTo]
          ? configs[network][tokenFromTo].approve_to
          : configs[network].bridge,
        reApprove ? '0' : ethers.constants.MaxUint256
      )
        .send({ from: account })
        .on("transactionHash", function (hash: string) {
          console.log("transactionHash: ", hash)
          add_Notifies__bridge__addApprove({
            state: "pedding",
            action: "Approve " + " " + curToken,
            transactionHash: hash,
            time: new Date().getTime()
          }, setNowNotifies, account as string, chainId as number)
        })
        .on("receipt", async function (receipt: any) {
          console.log("receipt: ", receipt)
          setIsApproveing(false)
          updateMyBalance()
          update_Notifies__bridge(receipt, setNowNotifies, account as string, chainId as number)
          setTimeout(async () => {
            await del_Notify(receipt, setNowNotifies)
            if (reApprove) {
              approve()
            }
          }, 3000)
        })
        .on("error", function (error: any) {
          console.log("error", error)
          setIsApproveing(false)
          setShow__cancel(true)
          setTimeout(() => {
            setShow__cancel(false)
          }, 3000)
        })
        .catch((err: any) => {
          // console.log(err)
        })
    } catch (err) {
      setIsApproveing(false)
      // console.log(err)
    }
  }
  const transfer_by_dForce_bridge__polygon = async () => {
    if (Lending && account) {
      let sendValue__bn = new BigNumber(value__send).multipliedBy(new BigNumber(10 ** 18)).toString()
      sendValue__bn = value__send__max ? value__send__origin : sendValue__bn
      const sendAmount = sendValue__bn
      sendValue__bn = Lending.web3.eth.abi.encodeParameter('uint256', sendValue__bn)

      // gas_price_bid:
      const network = chainId_map[chainId_src]
      const tokenFromTo = `${curToken}-${chainId_map[chainId_src]}-${chainId_map[chainId_dst]}`

      const contract_Bridge = new Lending.web3.eth.Contract(BridgeABI, configs[network][tokenFromTo].bridge)

      contract_Bridge.methods
        .depositFor(
          account,    // 用户地址
          configs[network][curToken].address,    // df 地址
          sendValue__bn // 10进制的存款数量转换为16进制
        )
        .send({ from: account })
        .on("transactionHash", function (hash: string) {
          console.log("transactionHash: ", hash)
          setValue__send('')
          setIsTransfering(false)
          add_Notifies__bridge({
            state: "pedding",
            action: "Transfer " + format_num_to_K(format_bn(sendValue__bn.toString(), 18, 4)) + " " + curToken,
            transactionHash: hash,
            time: new Date().getTime()
          }, setNowNotifies, account as string, chainId as number)
        })
        .on("receipt", async function (receipt: any) {
          console.log("receipt: ", receipt)
          updateMyBalance()
          update_Notifies__bridge(receipt, setNowNotifies, account as string, chainId as number)
          setTimeout(async () => {
            await del_Notify(receipt, setNowNotifies)
          }, 3000)



          // Param2 - contract address on main chain
          const t_hash = '180f38aC5130101c6'
          add_Notifies__bridge({
            state: "pedding",
            action: "Receive " + format_num_to_K(format_bn(estimated_receive, 18, 4)) + " " + curToken,
            transactionHash: t_hash,
            time: new Date().getTime()
          }, setNowNotifies, account as string, chainId as number)

          checkDepositStatus(
            account, // user address
            configs[network][curToken].address,
            sendAmount, // amount
            "0xA6FA4fB5f76172d178d61B04b0ecd319C5d1C0aa" // test: "0xb5505a6d998549090530911180f38aC5130101c6"
          )
            .then((res) => {
              console.log(res)
              if (res) {
                let res_new: any = res
                res_new.transactionHash = t_hash
                update_Notifies__bridge(res_new, setNowNotifies, account as string, chainId as number)
                setTimeout(async () => {
                  await del_Notify(res_new, setNowNotifies)
                }, 3000)
              }
            })
            .catch((err) => {
              console.log(err)
            })
        })
        .on("error", function (error: any) {
          console.log("error", error)
          setIsTransfering(false)
          setShow__cancel(true)
          setTimeout(() => {
            setShow__cancel(false)
          }, 3000)
        })
    }
  }
  const transfer_by_dForce_bridge__op = async () => {
    if (Lending && account) {
      let sendValue__bn = new BigNumber(value__send).multipliedBy(new BigNumber(10 ** 18)).toString()
      sendValue__bn = value__send__max ? value__send__origin : sendValue__bn
      // gas_price_bid:
      const network = chainId_map[chainId_src]
      const tokenFromTo = `${curToken}-${chainId_map[chainId_src]}-${chainId_map[chainId_dst]}`

      const contract_Bridge = new Lending.web3.eth.Contract(BridgeABI, configs[network][tokenFromTo].bridge)
      let txHash = ''

      contract_Bridge.methods
        .depositERC20(
          configs[network][curToken].address, // L1 USX 地址
          configs[chainId_map[chainId_dst]][curToken].address,    // L2 USX 地址
          sendValue__bn,    // 转移token的数量
          '1000000',    // 1000000 写死该数值
          '0x'    // "0x"
        )
        .send({ from: account })
        .on("transactionHash", function (hash: string) {
          console.log("transactionHash: ", hash)
          txHash = hash
          setValue__send('')
          setIsTransfering(false)
          add_Notifies__bridge({
            state: "pedding",
            action: "Transfer " + format_num_to_K(format_bn(sendValue__bn.toString(), 18, 4)) + " " + curToken,
            transactionHash: hash,
            time: new Date().getTime()
          }, setNowNotifies, account as string, chainId as number)
        })
        .on("receipt", async function (receipt: any) {
          console.log("receipt: ", receipt)
          updateMyBalance()
          update_Notifies__bridge(receipt, setNowNotifies, account as string, chainId as number)
          setTimeout(async () => {
            await del_Notify(receipt, setNowNotifies)
          }, 3000)


          // get target hash:
          const l1RpcProvider = new ethers.providers.JsonRpcProvider(configs[network].rpc)
          const l2RpcProvider = new ethers.providers.JsonRpcProvider(configs[network][tokenFromTo].target_chain.rpc)
          const watcher = new Watcher({
            l1: {
              provider: l1RpcProvider,
              messengerAddress: configs[network][tokenFromTo].messenger // (Op-Kovan) messenger
            },
            l2: {
              provider: l2RpcProvider,
              messengerAddress: "0x4200000000000000000000000000000000000007" //(Op-Kovan)
            }
          })
          const [msgHash1] = await watcher.getMessageHashesFromL1Tx(txHash)
          const receipt_new = await watcher.getL2TransactionReceipt(msgHash1, true)
          console.log("receipt hash", receipt_new)

          add_Notifies__bridge({
            state: "pedding",
            action: "Receive " + format_num_to_K(format_bn(estimated_receive, 18, 4)) + " " + curToken,
            transactionHash: receipt_new.transactionHash,
            time: new Date().getTime()
          }, setNowNotifies, account as string, chainId as number)

          const l2_provider = new Web3(new Web3.providers.HttpProvider(configs[network][tokenFromTo].target_chain.rpc))
          const timerGetResult = setInterval(() => {
            l2_provider.eth.getTransactionReceipt(receipt_new.transactionHash, (err, res) => {
              console.log('err res: ', err, res)
              if (res && res.transactionHash === receipt_new.transactionHash && res.status) {
                clearInterval(timerGetResult)
                update_Notifies__bridge(res, setNowNotifies, account as string, chainId as number)
                setTimeout(async () => {
                  await del_Notify(res, setNowNotifies)
                }, 3000)
              }
            })
          }, 5000)

        })
        .on("error", function (error: any) {
          console.log("error", error)
          setIsTransfering(false)
          setShow__cancel(true)
          setTimeout(() => {
            setShow__cancel(false)
          }, 3000)
        })
    }
  }
  const transfer_by_dForce_bridge = async () => {
    if (Lending && account) {
      let sendValue__bn = new BigNumber(value__send).multipliedBy(new BigNumber(10 ** 18)).toString()
      sendValue__bn = value__send__max ? value__send__origin : sendValue__bn
      // gas_price_bid:
      const network = chainId_map[chainId_src]
      const tokenFromTo = `${curToken}-${chainId_map[chainId_src]}-${chainId_map[chainId_dst]}`
      // const gas_price_bid = await l2_provider.eth.getGasPrice()
      // console.log('gas_price_bid: ', gas_price_bid, l2_provider)





      const l2_provider_web3 = new Web3(new Web3.providers.HttpProvider(configs[network][tokenFromTo].target_chain.rpc))

      const l1_provider = new providers.JsonRpcProvider(configs[network].rpc)
      const l2_provider = new providers.JsonRpcProvider(configs[network][tokenFromTo].target_chain.rpc)
      const gasEstimator = new L1ToL2MessageGasEstimator(l2_provider)
      // debugger
      const baseFee: any = (await l1_provider.getBlock('latest')).baseFeePerGas
      // const baseFee: any = (await l2_provider_web3.eth.getBlock('latest'))
      // console.log(baseFee)
      // const baseFee: any = await l1_provider.getBlock('latest')
      const estimateGasCallValue = BigNumber_ethers.from('0')
      const depositAmount = utils_ethers.parseEther('3')
      const tokenGasOverrides = {
        gasLimit: { min: BigNumber_ethers.from(275000) },
      }

      const contract_Bridge = new Lending.web3.eth.Contract(BridgeABI, configs[network][tokenFromTo].bridge)
      const deposit_calldata = await contract_Bridge.methods.getOutboundCalldata(
        configs[network][curToken].address,  // l1 token address
        account, // from
        account, // to
        sendValue__bn, // transfer value
        '0x' // fixed
      ).call()

      const depositFunc = (depositParams:any) => {
        const innerData = utils_ethers.defaultAbiCoder.encode(
          ["uint256", "bytes"],
          [depositParams.maxSubmissionCost, "0x"],
        );
        const iGateway = new utils_ethers.Interface([
          "function outboundTransfer(address l1Token, address to, uint256 amount, uint256 maxGas, uint256 gasPriceBid, bytes calldata data)",
        ]);
    
        const functionData = iGateway.encodeFunctionData("outboundTransfer", [
          configs[network][curToken].address,
          account,
          sendValue__bn,
          depositParams.gasLimit,
          depositParams.maxFeePerGas,
          innerData,
        ]);
    
        return {
          data: functionData,
          to: configs[network][tokenFromTo].bridge,
          from: account,
          value: depositParams.gasLimit
            .mul(depositParams.maxFeePerGas)
            .add(depositParams.maxSubmissionCost),
        };
      };
      const estimates = await gasEstimator.populateFunctionParams(
        depositFunc,
        l1_provider,
        {}, // tokenGasOverrides,
      );

      const data = utils_ethers.defaultAbiCoder.encode(
        ["uint256", "bytes"],
        [estimates.estimates.maxSubmissionCost, "0x"],
      );
      // const estimates = await gasEstimator.estimateAll(
      //   configs[network][tokenFromTo].bridge,
      //   account,
      //   deposit_calldata,
      //   estimateGasCallValue,
      //   baseFee,
      //   account,
      //   account,
      //   l1_provider,
      //   // l2_provider_web3,
      //   tokenGasOverrides
      // )

      // const abiCoder = new ethers.utils.AbiCoder()
      // const data = abiCoder.encode(
      //   ['uint256', 'bytes'],
      //   [estimates.maxSubmissionFee, '0x']
      // )

      // console.log(
      //   configs[network][curToken].address,  // rinkeby - USX - address
      //   account,
      //   sendValue__bn, // transfer value
      //   estimates.gasLimit,
      //   estimates.maxFeePerGas,
      //   data
      // )

      let hash_src: string

      contract_Bridge.methods
        .outboundTransfer(
          configs[network][curToken].address,  // rinkeby - USX - address
          account,
          sendValue__bn, // transfer value
          estimates.estimates.gasLimit,
          estimates.estimates.maxFeePerGas,
          data
        )
        .send({ from: account, value: estimates.value.toString() })
        .on("transactionHash", function (hash: string) {
          console.log("transactionHash: ", hash)
          hash_src = hash
          setValue__send('')
          setIsTransfering(false)
          add_Notifies__bridge({
            state: "pedding",
            action: "Transfer " + format_num_to_K(format_bn(sendValue__bn.toString(), 18, 4)) + " " + curToken,
            transactionHash: hash,
            time: new Date().getTime()
          }, setNowNotifies, account as string, chainId as number)
        })
        .on("receipt", async function (receipt: any) {
          console.log("receipt: ", receipt)
          const t_time = new Date().getTime()
          updateMyBalance()
          update_Notifies__bridge(receipt, setNowNotifies, account as string, chainId as number)

          setTimeout(async () => {
            await del_Notify(receipt, setNowNotifies)
          }, 3000)

          setTimeout(async () => {
            add_Notifies__bridge({
              state: "pedding",
              action: "Receive " + format_num_to_K(format_bn(sendValue__bn.toString(), 18, 4)) + " " + curToken,
              transactionHash: '',
              time: new Date().getTime(),
              receiveingChainId: chainId_dst,
              isTmp: true,
              tmpId: t_time
            }, setNowNotifies, account as string, chainId as number)
          }, 4000)


          const walletPrivateKey = "5ea74d9d92fb7d2788bb85e001339de7422890fcf6ffd2bd0478ed49d7cf6797"
          const l2Wallet = new Wallet(walletPrivateKey, l2_provider)
          const _receipt = await l1_provider.getTransactionReceipt(receipt.transactionHash)
          // console.log(_receipt)
          const l1Receipt = new L1TransactionReceipt(_receipt)
          // console.log(l1Receipt)
          const message: any = await l1Receipt.getL1ToL2Messages(l2Wallet)
          // console.log('message: ', message)
          const status = await message[0].waitForStatus()
          // console.log('status: ', status, status.status)
          if (status.status === L1ToL2MessageStatus.REDEEMED) {
            // console.log(`L2 retryable txn executed 🥳 ${status.l2TxReceipt.transactionHash}`)

            // add_Notifies__bridge({
            //   state: "pedding",
            //   action: "Receive " + format_num_to_K(format_bn(sendValue__bn.toString(), 18, 4)) + " " + curToken,
            //   transactionHash: status.l2TxReceipt.transactionHash,
            //   time: new Date().getTime(),
            //   // isReceiveing: true,
            //   receiveingChainId: chainId_dst
            // }, setNowNotifies, account as string, chainId as number)

            add__local(account, {
              "bridge_type": "dforce_bridge",
              "chainId_src": chainId_src,
              "chainId_dst": chainId_dst,
              "hash_src": hash_src,
              "hash_dst": status.l2TxReceipt.transactionHash,
              "complete_src": true,
              "complete_dst": false,
              "action_send": "Transfer " + format_num_to_K(format_bn(sendValue__bn.toString(), 18, 4)) + " " + curToken,
              "action_receive": "Receive " + format_num_to_K(format_bn(sendValue__bn.toString(), 18, 4)) + " " + curToken,
              "provider_src": configs[chainId_map[chainId_src]].rpc,
              "provider_dst": configs[chainId_map[chainId_dst]].rpc,
              "time": new Date().getTime()
            })

            const timerGetResult = setInterval(() => {
              l2_provider_web3.eth.getTransactionReceipt(status.l2TxReceipt.transactionHash, (err, res) => {
                // console.log('err res: ', err, res)
                if (res && res.transactionHash === status.l2TxReceipt.transactionHash && res.status) {
                  clearInterval(timerGetResult)

                  const m_res = {
                    ...res,
                    isTmp: true,
                    tmpId: t_time,
                    transactionHash: status.l2TxReceipt.transactionHash,
                  }
                  // console.log(m_res)
                  update_Notifies__bridge(m_res, setNowNotifies, account as string, chainId as number)
                  update__local(account, status.l2TxReceipt.transactionHash)
                  setTimeout(async () => {
                    await del_Notify(res, setNowNotifies)
                  }, 3000)
                }
              })
            }, 1000 * 15)
          } else {
            // console.log(`L2 retryable txn failed with status ${status.l2TxReceipt.transactionHash}`)
          }
        })
        .on("error", function (error: any) {
          console.log("error", error)
          setIsTransfering(false)
          setShow__cancel(true)
          setTimeout(() => {
            setShow__cancel(false)
          }, 3000)
        })
    }
  }
  const transfer_by_cBridge = async () => {
    if (Lending && account && chainId) {
      let sendValue__bn = new BigNumber(value__send).multipliedBy(new BigNumber(10 ** 18)).toString()
      sendValue__bn = value__send__max ? value__send__origin : sendValue__bn
      // console.log('transfer value: ', sendValue__bn.toString())

      const network = chainId_map[chainId_src]
      const contract_cBridge = new Lending.web3.eth.Contract(cBridgeABI, configs[network].bridge)

      let timestamp = Number((Date.now() / 1000).toFixed(0))
      // console.log(timestamp)

      let fromhash: string

      contract_cBridge.methods
        .send(
          account,
          configs[network][curToken].address,
          sendValue__bn,
          chainId_dst, // _dstChainId
          timestamp, //_timestamp
          max_slippage //_maxSlippage
        )
        .send({ from: account })
        .on("transactionHash", function (hash: string) {
          console.log("transactionHash: ", hash)
          fromhash = hash
          setValue__send('')
          setIsTransfering(false)
          add_Notifies__bridge({
            state: "pedding",
            action: "Transfer " + format_num_to_K(format_bn(sendValue__bn.toString(), 18, 4)) + " " + curToken,
            transactionHash: hash,
            time: new Date().getTime()
          }, setNowNotifies, account as string, chainId as number)
        })
        .on("receipt", async function (receipt: any) {
          console.log("src-receipt: ", receipt)
          updateMyBalance()
          update_Notifies__bridge(receipt, setNowNotifies, account as string, chainId as number)
          setTimeout(async () => {
            await del_Notify(receipt, setNowNotifies)
          }, 3000)


          // bBridge res:
          // result = 0xfbb252266bf72bd50c20cce12911fc3dcb42af5d47ffe33d8e60491932fb5c6a  .slice(2)
          const result = Lending.web3.utils.soliditySha3(
            { type: 'address', value: account },  /// User's wallet address
            { type: 'address', value: account },  /// User's wallet address
            { type: 'address', value: configs[network][curToken].address },  /// ETH / ERC20 token address 
            { type: 'uint256', value: sendValue__bn },  /// Send amount in String
            { type: 'uint64', value: chainId_dst },  /// Destination chain id
            { type: 'uint64', value: timestamp },  /// timestamp
            { type: 'uint64', value: chainId_src } /// Source chain id
          )
          console.log('result: ', result, result.slice(2))
          var myHeaders = new Headers()
          myHeaders.append("Content-Type", "application/json")
          var raw = JSON.stringify({ "transfer_id": result.slice(2) })
          var requestOptions: any = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
          }
          let baseURL = 'https://cbridge-prod2.celer.app/v2/getTransferStatus'

          add__cbridge({
            state: "pedding",
            action: "Receive " + format_num_to_K(format_bn(estimated_receive, 18, 4)) + " " + curToken,
            transactionHash: '',
            result: result,
            time: new Date().getTime(),
          }, setNowNotifies__cbridge)


          // const l2_provider = new Web3(new Web3.providers.HttpProvider(configs[chainId_map[chainId_dst]].rpc))
          add__local(account, {
            "bridge_type": "c_bridge",
            "chainId_src": chainId_src,
            "chainId_dst": chainId_dst,
            "hash_src": fromhash,
            "hash_dst": '',
            "complete_src": true,
            "complete_dst": false,
            "action_send": "Transfer " + format_num_to_K(format_bn(sendValue__bn.toString(), 18, 4)) + " " + curToken,
            "action_receive": "Receive " + format_num_to_K(format_bn(estimated_receive.toString(), 18, 4)) + " " + curToken,
            "provider_src": configs[chainId_map[chainId_src]].rpc,
            "provider_dst": configs[chainId_map[chainId_dst]].rpc,
            "time": new Date().getTime(),
            "baseURL": baseURL,
            "requestOptions": requestOptions
          })

          let random = Math.random()
          let timer = {}

          timer[random] = setInterval(() => {
            fetch(baseURL, requestOptions)
              .then(response => response.json())
              .then(res => {
                console.log(timer[random], 'fetch-res: ', res)
                if (res.status >= 5) {
                  clearInterval(timer[random])

                  update__local(account, fromhash, res.dst_block_tx_link)

                  let toUpdateData = {
                    transactionHash: res.dst_block_tx_link,
                    result: result
                  }

                  update__cbridge(toUpdateData, setNowNotifies__cbridge)

                  setTimeout(async () => {
                    await del__cbridge(toUpdateData, setNowNotifies__cbridge)
                  }, 1000 * 10)
                }
              })
              .catch(error => console.log('error_add__bridge: ', error))
          }, 1000 * 15)

          // block_delay: 1
          // dst_block_tx_link: "https://etherscan.io/tx/0x0767502e9ce85ee6ad43c115720976d4bee661301ac8089c6ed398409d81b716"
          // err: null
          // powers: []
          // refund_reason: 2
          // signers: []
          // sorted_sigs: []
          // src_block_tx_link: "https://arbiscan.io/tx/0x3238508e9af5d459fcf886ff8f7a363e1329ec41362f36c62f278bfe090d9026"
          // status: 5
          // wd_onchain: null
          // [[Prototype]]: Object
        })
        .on("error", function (error: any) {
          // console.log("error", error)
          setIsTransfering(false)
          setShow__cancel(true)
          setTimeout(() => {
            setShow__cancel(false)
          }, 3000)
        })
    }
  }
  const UTSBridge = async () => {
    if (Lending && account && chainId) {
      // console.log('debounced Value: ', debouncedValue)
      // console.log(chainId, chainId_dst)
      // console.log(account)
      // console.log(value__send)
      // console.log(lzChainConfigs[chainId_dst].lzChainId)

      const network = chainId_map[chainId_src]
      const contract_UTS = new Lending.web3.eth.Contract(lzTokenABI, configs[network].UTS.address)
      const dstLzChainId = lzChainConfigs[chainId_dst].lzChainId
      const SafetyFactor = lzChainConfigs[chainId_dst].SafetyFactor
      const resMinDstGasLookup = await contract_UTS.methods.minDstGasLookup(dstLzChainId, 0).call()
      // console.log(resMinDstGasLookup)
      const SafetyFactorGasLookup = new BigNumber(resMinDstGasLookup)
        .multipliedBy(new BigNumber(SafetyFactor))
        .toFixed(0).toString()
      // console.log(SafetyFactorGasLookup)

      // const adapterParams = Lending.web3.eth.abi.encodeParameters(
      //   ["uint16", "uint256", "uint256", "address"],
      //   [2, SafetyFactorGasLookup, 0, account]
      // )
      const adapterParams = ethers.utils.solidityPack(
        ["uint16", "uint256", "uint256", "address"],
        [2, SafetyFactorGasLookup, 0, account]
      )
      // console.log(adapterParams)


      let sendValue__str = new BigNumber(value__send).multipliedBy(new BigNumber(10 ** 18)).toString()
      // sendValue__str = value__send__max ? value__send__origin : sendValue__str
      const bytes32Account = Lending.web3.utils.padLeft(account, 64)
      // console.log(sendValue__str)
      // console.log(dstLzChainId)
      // console.log(bytes32Account)
      // console.log(sendValue__str)
      // console.log(adapterParams)

      const resEstimateSendFee = await contract_UTS.methods
        .estimateSendFee(
          dstLzChainId,
          bytes32Account,
          sendValue__str,
          false,
          adapterParams
        )
        .call()
      // console.log(resEstimateSendFee)
      const resNativeFee = resEstimateSendFee.nativeFee
      // console.log(resNativeFee)
      let fromhash: string

      contract_UTS.methods
        .sendFrom(
          account,
          dstLzChainId,
          bytes32Account,
          sendValue__str,
          [account, '0x0000000000000000000000000000000000000000', adapterParams]
        )
        .send({ from: account, value: resNativeFee })
        .on("transactionHash", function (hash: string) {
          console.log("transactionHash: ", hash)
          fromhash = hash
          setValue__send('')
          setIsTransfering(false)
          add_Notifies__bridge({
            state: "pedding",
            action: `Send ${format_num_to_K(value__send)} UTS`,
            transactionHash: hash,
            time: new Date().getTime()
          }, setNowNotifies, account as string, chainId as number)
        })
        .on("receipt", async function (receipt: any) {
          console.log("receipt: ", receipt)
          updateMyBalance()
          update_Notifies__bridge(receipt, setNowNotifies, account as string, chainId as number)
          setTimeout(async () => {
            await del_Notify(receipt, setNowNotifies)
          }, 3000)

          setTimeout(async () => {
            add__cbridge({
              state: "pedding",
              action: `Receive ${format_num_to_K(value__send)} UTS`,
              transactionHash: '',
              result: fromhash,
              receiveingChainId: chainId_dst,
              time: new Date().getTime(),
            }, setNowNotifies__cbridge)
          }, 3000)

          add__local(account, {
            "bridge_type": "uts_bridge",
            "chainId_src": chainId_src,
            "chainId_dst": chainId_dst,
            "hash_src": fromhash,
            "hash_dst": '',
            "complete_src": true,
            "complete_dst": false,
            "action_send": `Send ${format_num_to_K(value__send)} UTS`,
            "action_receive": `Receive ${format_num_to_K(value__send)} UTS`,
            "provider_src": configs[chainId_map[chainId_src]].rpc,
            "provider_dst": configs[chainId_map[chainId_dst]].rpc,
            "time": new Date().getTime(),
            "baseURL": '',
            "requestOptions": ''
          })


          setTimeout(async () => {
            // console.log('message')
            // console.log(lzChainConfigs[chainId_src].lzChainId)
            // console.log(fromhash)
            // waitForMessageReceived(10121, '0xcc5ae2422974def215fd425db7e56afb5e166de5d64072c259871aed674b4717')
            waitForMessageReceived(
              lzChainConfigs[chainId_src].lzChainId,
              fromhash
            )
              .then((message: any) => {
                console.log(message)
                // created: 1700651862
                // dstChainId: 10143
                // dstTxHash: "0xd98b4af7572240152af63be7ca744719db5f7ca78d6f36b9015b82ffb5c73ccc"
                // dstUaAddress: "0x0a0968fbf7b44b38e981699ee21dd2347587b70b"
                // srcBlockHash: "0x2798b89767663ac6057f7293a41fb46bedf6be3f2681b66de387ad3fbcd56caf"
                // srcBlockNumber: "10087658"
                // srcChainId: 10121
                // srcTxHash: "0xcc5ae2422974def215fd425db7e56afb5e166de5d64072c259871aed674b4717"
                // srcUaAddress: "0xe2ad696a0548f5804077b1e2b079e0333b41070a"
                // srcUaNonce: 2
                // status: "DELIVERED"
                // updated: 1700651877

                update__local(account, message.srcTxHash, message.dstTxHash)

                let toUpdateData = {
                  transactionHash: message.dstTxHash,
                  result: message.srcTxHash
                }

                update__cbridge(toUpdateData, setNowNotifies__cbridge)

                setTimeout(async () => {
                  await del__cbridge(toUpdateData, setNowNotifies__cbridge)
                }, 1000 * 6)
              })
              .finally(() => { console.log('finally') })
          }, 1000 * 10)
        })
        .on("error", function (error: any) {
          // console.log("error", error)
          setIsTransfering(false)
          setShow__cancel(true)
          setTimeout(() => {
            setShow__cancel(false)
          }, 3000)
        })
    }
  }
  const transfer = () => {
    try {
      if (!value__send) {
        return console.log('invailid value__send')
      }
      if (new BigNumber(value__send).eq(new BigNumber(0))) {
        if (!(value__send__max && new BigNumber(value__send__origin).gt(new BigNumber(0)))) {
          return console.log('input 000000')
        }
      }

      // cBridge && estimated_receive = 0
      if (usingCBridge && new BigNumber(estimated_receive).lte(new BigNumber(0))) {
        return console.log('estimated receive 0')
      }

      if (usingCBridge && curBridgeErr !== '') {
        return console.log('cur Bridge Err')
      }


      setIsTransfering(true)

      if (curToken === 'UTS') {
        UTSBridge()
        return
      }

      const network = chainId_map[chainId_src]
      const tokenFromTo = `${curToken}-${chainId_map[chainId_src]}-${chainId_map[chainId_dst]}`

      const transfer_bridge = configs[network][tokenFromTo]
        ? configs[network][tokenFromTo].type // dForce_bridge / dForce_bridge__op / dForce_bridge__polygon
        : 'cBridge'

      // console.log('transfer: ', curToken)
      // console.log('bridge: ', transfer_bridge)
      // console.log('amount: ', value__send)

      if (transfer_bridge === 'dForce_bridge') {
        transfer_by_dForce_bridge()
      }
      if (transfer_bridge === 'dForce_bridge__op') {
        transfer_by_dForce_bridge__op()
      }
      if (transfer_bridge === 'dForce_bridge__polygon') {
        transfer_by_dForce_bridge__polygon()
      }
      if (transfer_bridge === 'cBridge') {
        transfer_by_cBridge()
      }
    } catch (err) {
      // console.log(err)
      setIsTransfering(false)
    }
  }
  const checkDepositStatus = async (
    userAccount: string,
    rootToken: string,
    depositAmount: string,
    childChainManagerProxy: string
  ) => {
    return new Promise((resolve, reject) => {
      if ('WebSocket' in window) {
        const ws = new WebSocket('wss://ws-mainnet.matic.network/')

        ws.onopen = () => {
          ws.send(
            `{"id": 1, "method": "eth_subscribe", "params": ["newDeposits", {"Contract": "${childChainManagerProxy}"}]}`
          )

          ws.onmessage = (msg: any) => {
            const parsedMsg = JSON.parse(msg)
            if (
              parsedMsg &&
              parsedMsg.params &&
              parsedMsg.params.result &&
              parsedMsg.params.result.Data
            ) {
              const fullData = parsedMsg.params.result.Data
              const { 0: syncType, 1: syncData } = Lending.web3.eth.abi.decodeParameters(["bytes32", "bytes"], fullData)

              // check if sync is of deposit type (keccak256("DEPOSIT"))
              const depositType = "0x87a7811f4bfedea3d341ad165680ae306b01aaeacc205d227629cf157dd9f821"
              if (syncType.toLowerCase() === depositType.toLowerCase()) {
                const {
                  0: userAddress,
                  1: rootTokenAddress,
                  2: depositData,
                } = Lending.web3.eth.abi.decodeParameters(["address", "address", "bytes"], syncData)

                // depositData can be further decoded to get amount, tokenId etc. based on token type
                // For ERC20 tokens
                const { 0: amount } = Lending.web3.eth.abi.decodeParameters(["uint256"], depositData)
                if (
                  userAddress.toLowerCase() === userAccount.toLowerCase() &&
                  rootToken.toLowerCase() === rootTokenAddress.toLowerCase() &&
                  depositAmount === amount
                ) {
                  resolve(true)
                }
              }
            }
          }

          ws.onerror = () => {
            reject(false)
          }

          ws.onclose = () => {
            reject(false)
          }
        }
      } else {
        alert("ws does not work in your browser.")
      }
    })
  }




  const renderButton = () => {
    if (curToken === 'USX') {
      return (
        <>
          {
            allowance_USX === '0' ||
              new BigNumber(value__send).multipliedBy(new BigNumber(10 ** 18)).gt(allowance_USX)
              ? <StyledBtn
                className={isApproveing ? 'disable' : ''}
                onClick={() => { approve() }}>
                {fmt({ id: "APPROVE" })}
              </StyledBtn>
              : <StyledBtn
                className={
                  isTransfering ||
                    new BigNumber(bridgeFee).gt(new BigNumber(value__send).multipliedBy(new BigNumber(10 ** 18))) ||
                    // (value__send && Number(value__send) > 0 && Number(value__send) <= 5) ||
                    (curBridgeMaxSend && value__send &&
                      new BigNumber(curBridgeMaxSend).lt(new BigNumber(value__send).multipliedBy(new BigNumber(10 ** 18)))) ||
                    (curBridgeMinSend && value__send &&
                      new BigNumber(curBridgeMinSend).gte(new BigNumber(value__send).multipliedBy(new BigNumber(10 ** 18)))) ||
                    curBridgeErr !== ''
                    ? 'disable' : ''
                }
                onClick={() => { transfer() }}>
                {fmt({ id: "TRANSFER" })}
              </StyledBtn>
          }
        </>
      )
    }

    if (curToken === 'DF') {
      return (
        <>
          {
            allowance_DF === '0'
              ? <StyledBtn
                className={isApproveing ? 'disable' : ''}
                onClick={() => { approve() }}>
                {fmt({ id: "APPROVE" })}
              </StyledBtn>
              : new BigNumber(value__send).multipliedBy(new BigNumber(10 ** 18)).gt(allowance_DF)
                ? <StyledBtn
                  className={isApproveing ? 'disable' : ''}
                  onClick={() => { pre_approve() }}>
                  {fmt({ id: "APPROVE" })}
                </StyledBtn>
                : <StyledBtn
                  className={
                    isTransfering ||
                      new BigNumber(bridgeFee).gt(new BigNumber(value__send).multipliedBy(new BigNumber(10 ** 18))) ||
                      // (value__send && Number(value__send) > 0 && Number(value__send) <= 10) ||
                      (curBridgeMaxSend && value__send &&
                        new BigNumber(curBridgeMaxSend).lt(new BigNumber(value__send).multipliedBy(new BigNumber(10 ** 18)))) ||
                      (curBridgeMinSend && value__send &&
                        new BigNumber(curBridgeMinSend).gte(new BigNumber(value__send).multipliedBy(new BigNumber(10 ** 18)))) ||
                      curBridgeErr !== ''
                      ? 'disable' : ''
                  }
                  onClick={() => { transfer() }}>
                  {fmt({ id: "TRANSFER" })}
                </StyledBtn>
          }
        </>
      )
    }

    if (curToken === 'UTS') {
      return (
        <>
          {
            <StyledBtn
              className={isTransfering ? 'disable' : ''}
              onClick={() => { transfer() }}>
              {fmt({ id: "TRANSFER" })}
            </StyledBtn>
          }
        </>
      )
    }
  }

  const renderBridgeType = () => {
    if (curToken === 'UTS') {
      return 'LayerZero'
    }

    const network = chainId_map[chainId_src]
    const tokenFromTo = `${curToken}-${chainId_map[chainId_src]}-${chainId_map[chainId_dst]}`

    let transfer_bridge = configs[network][tokenFromTo]
      ? configs[network][tokenFromTo].type // dForce_bridge / dForce_bridge__op / dForce_bridge__polygon
      : 'cBridge'

    if (transfer_bridge === 'dForce_bridge' || transfer_bridge === 'dForce_bridge__op') {
      transfer_bridge = curToken === 'DF'
        ? chainId_map[chainId_dst] === 'optimism'
          ? 'Optimism Bridge'
          : chainId_map[chainId_dst] === 'arbitrum' ? 'Arbitrum Bridge' : 'L2 Bridge'
        : 'dForce Bridge'
    }
    if (transfer_bridge === 'dForce_bridge__polygon') {
      transfer_bridge = curToken === 'DF' ? 'Polygon Bridge' : ''
    }

    return transfer_bridge
  }


  const AddTokenToMetamask = async (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.preventDefault()
    e.stopPropagation()
    // console.log('add token: ', curToken)

    const address = addtokenConfigs[curToken].address[chainId_src]
    const symbol = addtokenConfigs[curToken].symbol
    const decimals = addtokenConfigs[curToken].decimals
    let image
    try {
      image = `${require(`assets/tokenList/${curToken}.svg`).default}`
    } catch (err) {
      image = `${require(`assets/tokenList/default.svg`).default}`
    }

    try {
      const wasAdded = await window.Lendingsauce.web3._provider.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address,
            symbol,
            decimals,
            image: `${window.location.origin}${image}`, // A string url of the token logo
          },
        }
      })

      if (wasAdded) {
        console.log("Thanks for your interest!");
      } else {
        console.log("Your loss!");
      }
    } catch (error) {
      console.log(error);
    }
  }

  const switchSlipe = () => {
    // console.log('switchSlipe: ', showSlipe)
    setShowSlipe(!showSlipe)
  }


  const handleGetMessages = async () => {
    // const { messages } = await getMessagesBySrcTxHash(
    //   10121,
    //   '0xcc5ae2422974def215fd425db7e56afb5e166de5d64072c259871aed674b4717',
    // )

    // console.log(messages)


    // waitForMessageReceived(10121, '0xcc5ae2422974def215fd425db7e56afb5e166de5d64072c259871aed674b4717')
    waitForMessageReceived(lzChainConfigs[chainId_src].lzChainId, '0x744dbdd1bdce49553ac747b09e677a5a1dbcf72d6f07fd6226511d1a95093747')
      .then((message: any) => {
        console.log(message)
        // created: 1700651862
        // dstChainId: 10143
        // dstTxHash: "0xd98b4af7572240152af63be7ca744719db5f7ca78d6f36b9015b82ffb5c73ccc"
        // dstUaAddress: "0x0a0968fbf7b44b38e981699ee21dd2347587b70b"
        // srcBlockHash: "0x2798b89767663ac6057f7293a41fb46bedf6be3f2681b66de387ad3fbcd56caf"
        // srcBlockNumber: "10087658"
        // srcChainId: 10121
        // srcTxHash: "0xcc5ae2422974def215fd425db7e56afb5e166de5d64072c259871aed674b4717"
        // srcUaAddress: "0xe2ad696a0548f5804077b1e2b079e0333b41070a"
        // srcUaNonce: 2
        // status: "DELIVERED"
        // updated: 1700651877

        // updateTx({
        //   completed: true,
        //   confirmation: {
        //     chainId: message.dstChainId,
        //     txHash: message.dstTxHash,
        //   },
        // });
      })
      .finally(() => {
        console.log('finally')
      });
  }



  return (
    <>
      <StyledNotificationWrap>

        <Notification_forBridge
          chainID={chainId}
          notifies={nowNotifies}
          setNowNotifies={setNowNotifies}
          del_Notify={del_Notify}
        />

        <Notification__cbridge
          notifies={nowNotifies__cbridge}
          del_Notify={del__cbridge}
          setNowNotifies={setNowNotifies__cbridge}
        />

        <Notification
          chainID={chainId}
          notifies={nowNotifies}
          setNowNotifies={setNowNotifies}
          del_Notify={del_Notify}
        />

        <Notification__cancel
          notifies={show__cancel}
          setShow__cancel={setShow__cancel}
          del_Notify={del__cancel}
        />

      </StyledNotificationWrap>


      {/* change Token */}
      <Modal isOpen={isOpen_token} onDismiss={selectToken_dismiss}>
        <ModalTitle text={fmt({ id: "Select_a_Token" })} onDismiss={selectToken_dismiss} />
        <ModalContent>
          <StyledWalletsWrapper className={'tokenWrap'}>
            <TokenItem
              onClick={() => { selectToken('UTS') }}
              className={curToken === 'UTS' ? 'isChoosen' : ''}
            >
              <TokenItem_left>
                <TokenItem_logo><img src={img__UTS} alt="" /></TokenItem_logo>
                <TokenItem_name>{'Unitus'}</TokenItem_name>
              </TokenItem_left>
              <TokenItem_balance>
                {myBalance_UTS ? format_num_to_K(format_bn(myBalance_UTS, 18, 4)) : '0'}{' UTS'}
              </TokenItem_balance>
            </TokenItem>

            <TokenItem
              onClick={() => { selectToken('USX') }}
              className={curToken === 'USX' ? 'isChoosen' : ''}
            >
              <TokenItem_left>
                <TokenItem_logo><img src={img__USX} alt="" /></TokenItem_logo>
                <TokenItem_name>{'dForce USD'}</TokenItem_name>
              </TokenItem_left>
              <TokenItem_balance>
                {myBalance_USX ? format_num_to_K(format_bn(myBalance_USX, 18, 4)) : '0'}{' USX'}
              </TokenItem_balance>
            </TokenItem>
            {
              !(chainId_src === 2222 || chainId_dst === 2222) &&
              !(chainId_src === 324 || chainId_dst === 324) &&
              <TokenItem
                onClick={() => { selectToken('DF') }}
                className={curToken === 'DF' ? 'isChoosen' : ''}
              >
                <TokenItem_left>
                  <TokenItem_logo><img src={img__DF} alt="" /></TokenItem_logo>
                  <TokenItem_name>{'dForce'}</TokenItem_name>
                </TokenItem_left>
                <TokenItem_balance>
                  {myBalance_DF ? format_num_to_K(format_bn(myBalance_DF, 18, 4)) : '0'}{' DF'}
                </TokenItem_balance>
              </TokenItem>
            }
          </StyledWalletsWrapper>
        </ModalContent>
      </Modal>

      {/* change Network --- source */}
      <Modal isOpen={isOpen_network__source} onDismiss={selectNetworkSrc_dismiss}>
        <ModalTitle text={fmt({ id: "Select_Source_Chain" })} onDismiss={selectNetworkSrc_dismiss} />
        <ModalContent>
          <StyledWalletsWrapper>
            {
              Object.keys(configs).map((network, index) => {
                if (configs[network].isTest) {
                  return false
                }
                return (
                  <SelectChainId
                    key={index}
                    selectChainId={select_ChainId__source}
                    chainId={chainId}
                    config={configs[network]}
                    isSelected={configs[network].chainId === chainId}
                  />
                )
              })
            }
          </StyledWalletsWrapper>
        </ModalContent>
      </Modal>

      {/* change Network --- target */}
      <Modal isOpen={isOpen_network__target} onDismiss={selectNetworkDst_dismiss}>
        <ModalTitle text={fmt({ id: "Select_Target_Chain" })} onDismiss={selectNetworkDst_dismiss} />
        <ModalContent>
          <StyledWalletsWrapper>
            {
              Object.keys(configs).map((network, index) => {
                if (configs[network].chainId === chainId_src || configs[network].isTest) {
                  return false
                }
                if (
                  (curToken === 'DF' && network === 'kava') ||
                  (curToken === 'DF' && network === 'zkSyncEra') ||
                  (curToken === 'UTS' && network === 'kava') ||
                  (curToken === 'UTS' && network === 'zkSyncEra')
                ) return false
                return (
                  <SelectChainId
                    key={index}
                    selectChainId={select_ChainId__target}
                    chainId={chainId}
                    config={configs[network]}
                    isSelected={configs[network].chainId === chainId_dst}
                  />
                )
              })
            }
          </StyledWalletsWrapper>
        </ModalContent>
      </Modal>


      <TransferSection>
        <StyledTitle>{fmt({ id: "bridge__Bridge" })}</StyledTitle>
        <ChooseNetType>
          <p>{fmt({ id: "From" })}</p>
          <NetTypeOptions
            handleOpen={selectNetworkSrc_open}
            chainId_src={chainId_src}
            config={configs[chainId_map[chainId_src]]}
            isFrom={true}
          />
          <RightWrap>
            {
              // !isMobile &&
              false &&
              <AddNet>
                <img
                  data-tip data-for={"addToken_toMetamask"}
                  src={img_AddNetType} alt=""
                  onClick={(e) => AddTokenToMetamask(e)}
                />
                <>
                  <ReactTooltip
                    id={"addToken_toMetamask"}
                    place="top"
                    type="light"
                    effect="float"
                    className="tooltip"
                    textColor={`#9195A4`}
                    borderColor={"#E8ECEF"}
                    border={true}
                  >
                    <span>
                      {fmt({ id: "Add_to_MetaMask" })}
                    </span>
                  </ReactTooltip>
                </>
              </AddNet>
            }
            <SlipeSet
              onBlur={() => setShowSlipe(false)}
            >
              <img
                src={img_setSlipe} alt=""
                onClick={() => switchSlipe()}
              />
              {
                showSlipe &&
                <SlippageProp>
                  <SlippageTitle>
                    <p>{fmt({ id: "Slippage_Tolerance" })}</p>

                    <a data-tip data-for={"Slippage__tip"} style={{ marginLeft: "5px", display: 'flex' }}>
                      <img src={tooltip} alt='' style={{ marginBottom: "8px" }} />
                    </a>
                    <>
                      <ReactTooltip
                        id={"Slippage__tip"}
                        place="top"
                        type="light"
                        effect="float"
                        className="tooltip"
                        textColor={`#9195A4`}
                        borderColor={"#E8ECEF"}
                        border={true}
                      >
                        <span>
                          {fmt({ id: "Slippage__tips" })}
                        </span>
                      </ReactTooltip>
                    </>
                    <StyledClose onClick={() => setShowSlipe(false)}>
                      <img src={img_close} alt='' />
                    </StyledClose>
                  </SlippageTitle>
                  <ItemWrap>
                    <SlippageItem
                      className={curSlippage === 1000 ? 'isSelected' : ''}
                      onClick={() => { setCurSlippage(1000) }}
                    >
                      0.1%
                    </SlippageItem>
                    <SlippageItem
                      className={curSlippage === 2000 ? 'isSelected' : ''}
                      onClick={() => { setCurSlippage(2000) }}
                    >
                      0.2%
                    </SlippageItem>
                    <SlippageItem
                      className={curSlippage === 5000 ? 'isSelected' : ''}
                      onClick={() => { setCurSlippage(5000) }}
                    >
                      0.5%
                    </SlippageItem>
                    <SlippageItem
                      className={curSlippage === 10000 ? 'isSelected' : ''}
                      onClick={() => { setCurSlippage(10000) }}
                    >
                      1%
                    </SlippageItem>
                    <SlippageItem
                      className={curSlippage === 30000 ? 'isSelected' : ''}
                      onClick={() => { setCurSlippage(30000) }}
                    >
                      3%
                    </SlippageItem>
                    <SlippageItem
                      className={curSlippage === 50000 ? 'isSelected' : ''}
                      onClick={() => { setCurSlippage(50000) }}
                    >
                      5%
                    </SlippageItem>
                  </ItemWrap>
                </SlippageProp>
              }
            </SlipeSet>
          </RightWrap>
        </ChooseNetType>


        <MenuSec>
          <MenuSecTop>
            <p>{fmt({ id: "Send" })}</p>
            <MyBalanceWrap>
              <MAX onClick={() => { send_max() }}>{fmt({ id: "MAX" })}</MAX>
              {
                curToken === 'USX' &&
                <MyBalance>
                  {fmt({ id: "Balance" })}{': '}
                  {myBalance_USX ? format_num_to_K(format_bn(myBalance_USX, 18, 4)) : '0'}
                </MyBalance>
              }
              {
                curToken === 'DF' &&
                <MyBalance>
                  {fmt({ id: "Balance" })}{': '}
                  {myBalance_DF ? format_num_to_K(format_bn(myBalance_DF, 18, 4)) : '0'}
                </MyBalance>
              }
              {
                curToken === 'UTS' &&
                <MyBalance>
                  {fmt({ id: "Balance" })}{': '}
                  {myBalance_UTS ? format_num_to_K(format_bn(myBalance_UTS, 18, 4)) : '0'}
                </MyBalance>
              }
            </MyBalanceWrap>
          </MenuSecTop>

          <MenuSecBottom>
            <InputWrap>
              <input
                type="number"
                pattern="^[0-9]*[.,]?[0-9]*$"
                placeholder="0.00"
                value={value__send}
                onChange={(e) => { send__change(e.target.value) }}
              />
            </InputWrap>
            <ChooseToken onClick={() => { selectToken_open() }}>
              <TokenLogo>
                {curToken === 'USX' && <img src={img__USX} alt="" />}
                {curToken === 'DF' && <img src={img__DF} alt="" />}
                {curToken === 'UTS' && <img src={img__UTS} alt="" />}
              </TokenLogo>
              <TokenName>{curToken}</TokenName>
              <ArrowDownToken />
            </ChooseToken>
          </MenuSecBottom>
        </MenuSec>

        {/* ***** ***** ***** ***** ***** ***** ***** ***** ***** ***** ***** */}
        <ExchangeBtn>
          <img className="light" src={img_transfer} alt="" onClick={() => { SwitchExchangeState() }} />
          <img className="night" src={img_transfer2} alt="" onClick={() => { SwitchExchangeState() }} />
        </ExchangeBtn>
        {/* ***** ***** ***** ***** ***** ***** ***** ***** ***** ***** ***** */}

        <ChooseNetType>
          <p>{fmt({ id: "To" })}</p>
          <NetTypeOptions
            handleOpen={selectNetworkDst_open}
            chainId_src={chainId_dst}
            config={configs[chainId_map[chainId_dst]]}
          />
        </ChooseNetType>


        <MenuSec>
          <MenuSecTop>
            <StyledWW>
              <p>{fmt({ id: "Receive" })}</p>
              <a data-tip data-for={"Receive__tips"} style={{ marginLeft: "5px", display: 'flex' }}>
                <img src={tooltip} alt='' style={{ marginBottom: "8px" }} />
              </a>
              <>
                <ReactTooltip
                  id={"Receive__tips"}
                  place="top"
                  type="light"
                  effect="float"
                  className="tooltip"
                  textColor={`#9195A4`}
                  borderColor={"#E8ECEF"}
                  border={true}
                >
                  <span>
                    {fmt({ id: "Receive__info" })}
                  </span>
                </ReactTooltip>
              </>
            </StyledWW>
          </MenuSecTop>

          <MenuSecBottom>
            <InputWrap>
              <input
                className={value__send ? 'inputValid' : 'inputNotValid'}
                value={
                  usingCBridge
                    ? estimated_receive
                      ? new BigNumber(estimated_receive).gte(new BigNumber(0))
                        ? format_num_to_K(format_bn(estimated_receive, 18, 6)) : '...'
                      : '0.00'
                    : value__send ? value__send : '0.00'
                }
                disabled={true}
              />
            </InputWrap>
          </MenuSecBottom>
        </MenuSec>

        {
          // value__send && Number(value__send) > 0 &&
          //   (curToken === 'USX' && Number(value__send) <= 5 || curToken === 'DF' && Number(value__send) <= 10) ?
          //   <StyledTips>
          //     <img src={img_warning} alt="" />
          //     {fmt({ id: `${curToken === 'USX' ? 'atleast_5usx' : 'atleast_10df'}` })}
          //   </StyledTips>
          //   :
        }

        {
          value__send &&
          curBridgeMaxSend &&
          curBridgeMinSend &&
          bridgeFee &&
          fetchReady &&
          <>
            {
              value__send &&
                curBridgeMaxSend && curBridgeMinSend &&
                new BigNumber(bridgeFee).gt(new BigNumber(value__send).multipliedBy(new BigNumber(10 ** 18)))
                ? new BigNumber(curBridgeMinSend).gt(new BigNumber(bridgeFee)) &&
                  (curToken === 'USX' || curToken === 'DF')
                  ? <StyledTips>
                    <img src={img_warning} alt="" />
                    {fmt({ id: "atleast__heyue" }, { amount: format_bn(curBridgeMinSend, 18, 0), token: curToken })}
                  </StyledTips>
                  : <StyledTips>
                    <img src={img_warning} alt="" />
                    {fmt({ id: "atleast" }, { amount: format_num_to_K(format_bn(bridgeFee, 18, 4)) }) + ' ' + curToken}
                  </StyledTips>

                : curBridgeMaxSend && value__send &&
                  new BigNumber(curBridgeMaxSend).lt(new BigNumber(value__send).multipliedBy(new BigNumber(10 ** 18)))
                  ? <StyledTips>
                    <img src={img_warning} alt="" />
                    {fmt({ id: "curNetErr_maxSend" })}
                  </StyledTips>
                  : curBridgeMinSend && value__send &&
                    new BigNumber(curBridgeMinSend).gte(new BigNumber(value__send).multipliedBy(new BigNumber(10 ** 18))) &&
                    (curToken === 'USX' || curToken === 'DF')
                    ? <StyledTips>
                      <img src={img_warning} alt="" />
                      {fmt({ id: "atleast__heyue" }, { amount: format_bn(curBridgeMinSend, 18, 0), token: curToken })}
                    </StyledTips>
                    : <>
                      {
                        curBridgeErr && curBridgeErr === '1002' &&
                        <StyledTips>
                          <img src={img_warning} alt="" />
                          {fmt({ id: "curNetErr" }, { net: configs[chainId_map[chainId_src]].network_name })}
                        </StyledTips>
                      }
                      {
                        curBridgeErr && curBridgeErr === '1004' &&
                        <StyledTips>
                          <img src={img_warning} alt="" />
                          {fmt({ id: "curNetErr_1004" })}
                        </StyledTips>
                      }
                      {
                        curBridgeErr && curBridgeErr === '1005' &&
                        <StyledTips>
                          <img src={img_warning} alt="" />
                          {fmt({ id: "curNetErr_1005" })}
                        </StyledTips>
                      }
                      {
                        curBridgeErr && curBridgeErr === '1017' &&
                        <StyledTips>
                          <img src={img_warning} alt="" />
                          {fmt({ id: "curNetErr_1017" })}
                        </StyledTips>
                      }
                    </>
            }
          </>
        }


        {/* tmp */}
        {
          value__send &&
          new BigNumber(value__send).multipliedBy(new BigNumber(10 ** 18))
            .gt(curToken === 'USX' ? myBalance_USX : curToken === 'UTS' ? myBalance_UTS : myBalance_DF) &&
          <StyledTips>
            <img src={img_warning} alt="" />
            {fmt({ id: "Insufficient_Balance" })}
          </StyledTips>
        }



        {renderButton()}
      </TransferSection>


      {/* <StyledBtn
        onClick={() => { handleGetMessages() }}>
        test
      </StyledBtn> */}
      {
        // value__send && Number(value__send) > 0 &&
        <InfoSection>
          <InfoItem>
            <ItemLeft>
              <span>{fmt({ id: "Routing" })}</span>
              <a data-tip data-for={"bridge_routing"} style={{ marginLeft: "5px", display: 'flex' }}>
                <img src={tooltip} alt='' />
              </a>
              <>
                <ReactTooltip
                  id={"bridge_routing"}
                  place="top"
                  type="light"
                  effect="float"
                  className="tooltip"
                  textColor={`#9195A4`}
                  borderColor={"#E8ECEF"}
                  border={true}
                >
                  <span>
                    {fmt({ id: "bridge_routing__text" })}
                  </span>
                </ReactTooltip>
              </>
            </ItemLeft>
            <ItemRight>
              {renderBridgeType()}
            </ItemRight>
          </InfoItem>
          <InfoItem>
            <ItemLeft>
              <span>{fmt({ id: "Fee" })}</span>
              <a data-tip data-for={"bridge_Fee"} style={{ marginLeft: "5px", display: 'flex' }}>
                <img src={tooltip} alt='' />
              </a>
              <>
                <ReactTooltip
                  id={"bridge_Fee"}
                  place="top"
                  type="light"
                  effect="float"
                  className="tooltip"
                  textColor={`#9195A4`}
                  borderColor={"#E8ECEF"}
                  border={true}
                >
                  <span>
                    {fmt({ id: "bridge_Fee__text" })}
                  </span>
                </ReactTooltip>
              </>
            </ItemLeft>
            <ItemRight>
              {format_num_to_K(format_bn(bridgeFee, 18, 4)) + ' ' + curToken}
            </ItemRight>
          </InfoItem>
          <InfoItem>
            <ItemLeft>{fmt({ id: "Minimum_Received" })}</ItemLeft>
            <ItemRight>
              {
                usingCBridge
                  ? estimated_receive
                    ? new BigNumber(estimated_receive).lt(new BigNumber(0))
                      ? '0.00'
                      : format_num_to_K(format_bn(
                        new BigNumber(estimated_receive)
                          .multipliedBy(new BigNumber((1000000 - curSlippage) / 100))
                          .toString(), 22, 2
                      ))
                    : '0.00'
                  : value__send ? format_num_to_K(value__send) : '0.00'
              }
              {' ' + curToken}
            </ItemRight>
          </InfoItem>
          <InfoItem>
            <ItemLeft>{fmt({ id: "Estimated_Time" })}</ItemLeft>
            <ItemRight>5-20 {fmt({ id: "minutes" })}</ItemRight>
          </InfoItem>
        </InfoSection>
      }
    </>
  )
}



const StyledNotificationWrap = styled.div`
  position: fixed;
  right: 15px;
  top: 85px;
  user-select: none;
  z-index:99;
`
const StyledTips = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  height: 46px;
  background: rgba(252, 129, 129, 0.2);
  border-radius: 23px;
  font-size: 16px;
  font-weight: 500;
  color: #FA4437;

  img {
    margin-right: 10px;
    width: 15px;
  }
`
const StyledWalletsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media (max-width: 600px) {
    flex-direction: column;
    flex-wrap: none;
  }

  &.tokenWrap {
    flex-direction: column;
  }
`
const TokenItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  color: #0A1130;
  font-weight: 700;
  height: 81px;
  background: #dedfe1;
  border-radius: 12px;
  border: 1px solid #D1D2D7;
  margin-top: 16px;
  cursor: pointer;
  padding-left: 20px;
  padding-right: 20px;

  .dark & {
    color: #fff;
    /* background: #131B3D; */
    background: rgba(255, 255, 255, 0.08);
    /* border: 1px solid #2E344E; */
    border: none;
  }

  &.isChoosen,
  &:hover {
    border: 2px solid #30A9DE;
  }

  @media (max-width: 1119px) {
    font-size: 14px;
    padding-left: 10px;
    padding-right: 10px;
  }
`
const TokenItem_left = styled.div`
  display: flex;
  align-items: center;
`
const TokenItem_logo = styled.div`
  img {
    width: 42px;
    height: 42px;
    margin-right: 16px;

    @media (max-width: 1119px) {
      width: 34px;
      height: 34px;
      margin-right: 10px;
    }
  }
`
const TokenItem_name = styled.div`
`
const TokenItem_balance = styled.div`
`

const ExchangeBtn = styled.div`
  padding: 20px;
  text-align: center;
  display: flex;
  justify-content: center;

  @media (max-width: 1119px) {
    padding: 16px;
  }

  img {
    &.light {
      display: block;
    }
    &.night {
      display: none;
    }
    .dark &.light {
      display: none;
    }
    .dark &.night {
      display: block;
    }
    cursor: pointer;
  }
`
const StyledBtn = styled.div`
  margin-top: 20px;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: linear-gradient(90deg, #5EAFE7 0%, #6E5DE7 100%); */
  background: #FF764B;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
  cursor: pointer;

  @media (max-width: 1119px) {
    height: 64px;
    margin-top: 20px;
  }

  &.disable {
    background: #EFF1F4 !important;
    color: #9FA4B3 !important;
    pointer-events: none !important;
    cursor: not-allowed !important;
  }
`

const InfoSection = styled.div`
  width: 540px;
  margin: 0px auto 17px;
  background: #fff;
  border-radius: 0 0 16px 16px;
  border: none;
  padding: 20px;

  .dark & {
    border: 1px solid rgba(255, 255, 255, 0.16);
    background: #000;
  }

  @media (max-width: 1119px) {
    width: 100%;
  }
`
const InfoItem = styled.div`
  display: flex;
  justify-content: space-between;
  line-height: 38px;
  font-size: 14px;
`
const ItemLeft = styled.div`
  display: flex;
  font-weight: 500;
  color: #999CA2;

  img {
    width: 12px;
    margin-bottom: -2px;
  }
`
const ItemRight = styled.div`
  font-weight: 600;
  color: rgb(36,42,71);

  .dark & {
    color: #FFFFFF;
  }
`

const StyledTitle = styled.div`
  font-size: 22px;
  font-weight: 600;
  color: rgb(36, 42, 71);
  margin: 0;
  margin-bottom: 32px;
  text-align: center;

  .dark & {
    color: #FFFFFF;
  }
`

const TransferSection = styled.div`
  width: 640px;
  margin: 66px auto 0px;
  background: #fff;
  border-radius: 16px;
  border: none;
  padding: 20px 30px 30px;

  .dark & {
    background: #000;
    border: 1px solid rgba(255, 255, 255, 0.16);
  }

  @media (max-width: 1119px) {
    width: 96%;
    padding: 20px 15px 20px;
  }
`
const ChooseNetType = styled.div`
  display: flex;
  margin-bottom: 18px;
  position: relative;
  align-items: flex-end;

  p {
    margin: 0;
    line-height: 42px;
    font-size: 16px;
    font-weight: 600;
    color: #999CA2;
    margin-right: 6px;
  }
`

const RightWrap = styled.div`
  display: flex;
  position: absolute;
  right: 0;
  top: 2px;

  img {
    width: 40px;
  }
`
const SlipeSet = styled.button`
  cursor: pointer;
  background: transparent;
  position: relative;
  border-radius: 10px;

  img {
    margin-top: -2px;
  }

  &:hover {
    background: rgba(0,0,0,0.1);
    .dark & {
      background: rgba(255,255,255,0.1);
    }
  }
`
const SlippageProp = styled.div`
  position: absolute;
  width: 540px;
  right: 0;
  background: rgb(246,246,246);
  box-shadow: 0px 0px 6px 0px rgba(46, 52, 78, 0.8);
  border-radius: 20px;
  top: 44px;
  padding: 20px;
  padding-top: 0;

  @media (max-width: 1119px) {
    width: 90vw;
  }

  .dark & {
    border: 1px solid rgba(255, 255, 255, 0.16);
    background: #000;
  }
`
const SlippageTitle = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    line-height: 50px;
    font-size: 16px;
    font-weight: 600;
    color: rgb(36, 42, 71);

    .dark & {
      color: #D1D2D7;
    }
  }

  img {
    width: 12px;
  }
`
const StyledClose = styled.div`
  position: absolute;
  right: 0;
  top: 18px;
  padding: 10px;
  height: 20px;
  width: 20px;

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
  }
`
const ItemWrap = styled.div`
  display: flex;
  justify-content: space-between;
`
const SlippageItem = styled.div`
  width: 70px;
  height: 38px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 500;
  color: #9598A4;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(46, 52, 78, 0.4);

  &.isSelected {
    color: #fff;
    border: none;
    /* background: linear-gradient(90deg, #5EAFE7 0%, #6E5DE7 100%); */
    background: #FF764B;
  }

  .dark & {
    /* border: 1px solid #1D2340; */
    border: 1px solid rgba(255,255,255, 0.3);
  }
`
const AddNet = styled.div`
  cursor: pointer;
  border-radius: 10px;

  span {
    color: rgb(36,42,71)!important;
  }

  &:hover {
    background: rgba(0,0,0,0.1);
    .dark & {
      background: rgba(255,255,255,0.1);
    }
  }
`

const MenuSec = styled.div`
  width: 100%;
  height: 120px;
  background: rgb(246, 246, 246);
  border-radius: 16px;
  border: none;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  border: 1px solid #ededed;

  @media (max-width: 1119px) {
    height: 110px;
    padding: 0 10px;
  }

  .dark & {
    /* background: #131B3D; */
    /* border: 1px solid #1D2340; */
    background: rgba(255, 255, 255, 0.08);
    border: none;
  }
`
const MenuSecTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    font-size: 16px;
    font-weight: 600;
    color: rgb(36, 42, 71);

    .dark & {
      color: #ffffff;
    }

    @media (max-width: 1119px) {
      font-size: 14px;
    }
  }
`
const StyledWW = styled.div`
  display: flex;

  img {
    width: 12px;
  }
`
const MyBalanceWrap = styled.div`
  display: flex;
  align-items: center;
`
const MAX = styled.div`
  cursor: pointer;
  padding: 0 14px;
  height: 32px;
  /* background: rgba(48, 169, 222, 0.1); */
  background: #FF764B;
  border-radius: 16px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
`
const MyBalance = styled.div`
  font-size: 16px;
  color: #999CA2;

  @media (max-width: 1119px) {
    font-size: 16px;
  }
`

const MenuSecBottom = styled.div`
  height: 44px;
  display: flex;
  justify-content: space-between;
`
const InputWrap = styled.div`
  flex: 1;

  input {
    height: 100%;
    width: 100%;
    font-size: 24px;
    font-weight: bold;
    color: #333;
    background: none;

    .dark & {
      color: #FFFFFF;
    }

    &[type='number'] {
      -moz-appearance: textfield;
    }
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    &.inputNotValid {
      color: #9195A4;
    }
  }
`
const ChooseToken = styled.div`
  width: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;
  cursor: pointer;
  padding: 5px;

  &:hover {
    background: rgba(0,0,0,0.1);
    .dark & {
      background: rgba(255,255,255,0.1);
    }
  }
`
const TokenLogo = styled.div`
  img {
    width: 34px;
    height: 34px;
    margin-right: 6px;
  }
`
const TokenName = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: rgb(36, 42, 71);
  margin-right: 16px;

  .dark & {
    color: #FFFFFF;
  }

  @media (max-width: 1119px) {
    font-size: 16px;
  }
`
const ArrowDownToken = styled.div`
  width: 0;
  height: 0;
  border: 8px solid transparent;
  border-top: 6px solid rgb(36, 42, 71);
  margin-bottom: -6px;

  .dark & {
    border-top: 8px solid #fff;
  }
`

export default Bridge
