"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.abi = void 0;
exports.abi = [
    {
        inputs: [{ internalType: "address", name: "_controller", type: "address" }],
        stateMutability: "nonpayable",
        type: "constructor",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint256",
                name: "agreementId",
                type: "uint256",
            },
            {
                indexed: true,
                internalType: "address",
                name: "asset",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "tokenAmounts",
                type: "uint256",
            },
            {
                indexed: true,
                internalType: "address",
                name: "beneficiary",
                type: "address",
            },
        ],
        name: "AgreementClaimed",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint256",
                name: "agreementId",
                type: "uint256",
            },
            {
                indexed: true,
                internalType: "address",
                name: "asset",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "tokenAmounts",
                type: "uint256",
            },
            {
                indexed: true,
                internalType: "address",
                name: "beneficiary",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "releaseTime",
                type: "uint256",
            },
        ],
        name: "AgreementCreated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint256",
                name: "agreementId",
                type: "uint256",
            },
            { indexed: false, internalType: "bool", name: "value", type: "bool" },
        ],
        name: "AgreementFrozen",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint256",
                name: "agreementId",
                type: "uint256",
            },
        ],
        name: "AgreementReleased",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "previousOwner",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "newOwner",
                type: "address",
            },
        ],
        name: "NewOwner",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "oldPendingOwner",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "newPendingOwner",
                type: "address",
            },
        ],
        name: "NewPendingOwner",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            { indexed: false, internalType: "bool", name: "value", type: "bool" },
        ],
        name: "TimeLockFrozen",
        type: "event",
    },
    {
        inputs: [],
        name: "_acceptOwner",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address payable",
                name: "newPendingOwner",
                type: "address",
            },
        ],
        name: "_setPendingOwner",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "agreementCount",
        outputs: [{ internalType: "uint248", name: "", type: "uint248" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            { internalType: "uint256[]", name: "agreementIds", type: "uint256[]" },
        ],
        name: "claim",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "controller",
        outputs: [{ internalType: "address", name: "", type: "address" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            { internalType: "address", name: "_asset", type: "address" },
            { internalType: "uint256", name: "_tokenAmounts", type: "uint256" },
            { internalType: "address", name: "_beneficiary", type: "address" },
            { internalType: "uint256", name: "_releaseTime", type: "uint256" },
        ],
        name: "createAgreement",
        outputs: [
            { internalType: "uint256", name: "agreementId", type: "uint256" },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            { internalType: "uint256[]", name: "agreementIds", type: "uint256[]" },
        ],
        name: "freezeAgreements",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "freezeClaim",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "frozen",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{ internalType: "uint256", name: "agreementId", type: "uint256" }],
        name: "getAgreement",
        outputs: [
            {
                components: [
                    { internalType: "bool", name: "isFrozen", type: "bool" },
                    { internalType: "address", name: "asset", type: "address" },
                    { internalType: "address", name: "beneficiary", type: "address" },
                    { internalType: "uint256", name: "releaseTime", type: "uint256" },
                    { internalType: "uint256", name: "tokenAmounts", type: "uint256" },
                ],
                internalType: "struct IDefaultTimeLock.Agreement",
                name: "agreement",
                type: "tuple",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            { internalType: "address", name: "_beneficiary", type: "address" },
        ],
        name: "getAgreementIds",
        outputs: [
            {
                internalType: "uint256[]",
                name: "_allAgreementIds",
                type: "uint256[]",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{ internalType: "address", name: "_controller", type: "address" }],
        name: "initialize",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "owner",
        outputs: [{ internalType: "address payable", name: "", type: "address" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "pendingOwner",
        outputs: [{ internalType: "address payable", name: "", type: "address" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            { internalType: "uint256[]", name: "agreementIds", type: "uint256[]" },
        ],
        name: "releaseAgreements",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            { internalType: "uint256[]", name: "agreementIds", type: "uint256[]" },
        ],
        name: "unfreezeAgreements",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "unfreezeClaim",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    { stateMutability: "payable", type: "receive" },
];
