import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import img_cancel from 'assets/supply/fail.svg'
import img_close from 'assets/supply/close.svg'


interface NotificationProps {
  notifies?: any;
  del_Notify?: any;
  setShow__cancel: React.Dispatch<React.SetStateAction<boolean>>
}


const Notification__cancel: React.FC<NotificationProps> = ({ notifies, del_Notify, setShow__cancel }) => {
  const intl = useIntl()
  const fmt = intl.formatMessage

  useEffect(() => {
    // console.log('in item...', notifies)
  }, [notifies])

  return (
    <StyledNotificationWrap>
      {
        notifies &&
        <StyledNotification>
          <StyledStatusimg src={img_cancel} alt="" />
          <StyledTitle>{fmt({ id: 'cancelled_by_user' })}</StyledTitle>
          <StyledClose src={img_close} alt="" onClick={() => { del_Notify(setShow__cancel) }} />
        </StyledNotification>
      }
    </StyledNotificationWrap>
  )
}


const StyledView = styled.a`
  font-size: 16px;
  font-weight: bold;
  position: absolute;
  right: 45px;
  color:  ${(props) => props.theme.global_supply};
  &:hover{
    color: ${(props) => props.theme.global_supply}!important
  }
`
const StyledTitle = styled.span`
  font-size: 16px;
  font-weight: bold;
  color: #9195A4;
  padding-right: 120px;

  .dark & {
    color: #fff;
  }
`
const StyledClose = styled.img`
  position: absolute;
  right: 15px;
  top: 17px;
  cursor: pointer;
`
const StyledStatusimg = styled.img`
  width: 32px;
  margin-left: 15px;
  margin-right: 12px;
`
const StyledNotificationWrap = styled.div`
  position: fixed;
  right: 15px;
  top: 85px;
  user-select: none;
  z-index:9999;
`
interface NotiProps {
  state?: string
}
const StyledNotification = styled.div<NotiProps>`
  display:flex;
  align-items:center;
  min-width: 246px;
  height: 70px;
  user-select: none;
  margin-bottom: 10px;
  position: relative;
  /* line-height: 66px; */
  background-color:#fff;
  /* border: 2px solid #DFE0E5; */
  border: 2px solid #F84D41;
  border-radius: 8px;

  .dark & {
    background: #414763;
    /* border: 2px solid #1C2242; */
  }
`

export default Notification__cancel