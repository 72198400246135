import React from 'react'
import styled, { keyframes } from 'styled-components'

export interface ModalProps {
  isOpen?: boolean,
  children?: React.ReactNode,
  onDismiss?: () => void
}


const dismissProps = (onDismiss: any) => {
  try {
    onDismiss()
  } catch (err) { console.log(err) }
}
const stopClose = (e: any) => {
  try {
    // console.log(e)
    // e.preventDefault()
    e.stopPropagation()
  } catch (err) { console.log(err) }
}

const Modal: React.FC<ModalProps> = ({ isOpen, children, onDismiss }) => {
  if (isOpen) {
    return (
      <Umbralayer onClick={() => { dismissProps(onDismiss) }}>
        <StyledModal isOpen={isOpen} onClick={(e) => { stopClose(e) }}>
          {children}
        </StyledModal>
      </Umbralayer>
    )
  }
  return null
}


const slideUp = keyframes`
  0% {
    top:50%;
    transform: translateY(100%);
  }
  100% {
    top:50%;
    transform: translateY(-50%);
  }
`

interface StyledModalProps {
  isOpen?: boolean
}

const StyledModal = styled.div<StyledModalProps>`
  border-radius: 16px;
  box-shadow: 24px 24px 48px -24px ${props => props.theme.colors.darkGrey};
  position: absolute;
  background: #fff;
  padding:20px;
  animation: ${slideUp} 0.3s forwards ease-out;
  @media(max-width: 1199px) {
    width:calc(100% - 64px);
    max-width: 490px;
    margin:0 auto;
    padding:20px 20px 25px
  }
`
const Umbralayer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  position: fixed;
  left:0;
  top:0;
  right:0;
  bottom:0;
  z-index: 9990;
  inset: 0px;
  background: rgba(0,0,0, 0.7);
  background: rgba(0,0,0, 0.9);
`

export default Modal