import React,{ useEffect, useState } from 'react'

import { coinbaseWallet, hooks } from '../../connectors/coinbaseWallet'
import { Card } from '../Card'
import { getName } from '../../utils'

const { useChainId, useAccounts,useAccount, useIsActivating, useIsActive, useProvider, useENSNames,useENSName } = hooks

export default function CoinbaseWalletCard() {
  const chainId = useChainId()
  const account = useAccount()
  const accounts = useAccounts()
  const isActivating = useIsActivating()

  const isActive = useIsActive()

  const provider = useProvider()
  const ENSName = useENSName(provider)
  const ENSNames = useENSNames(provider)

  const [error, setError] = useState(undefined)

  // attempt to connect eagerly on mount
  // useEffect(() => {
  //   if(getName(coinbaseWallet) !== 'Coinbase Wallet') return;
  //   void coinbaseWallet.connectEagerly().catch(() => {
  //     console.debug('Failed to connect eagerly to coinbase wallet')
  //   })
  // }, [])

  return (
    <Card
      connector={coinbaseWallet}
      activeChainId={chainId}
      isActivating={isActivating}
      isActive={isActive}
      error={error}
      setError={setError}
      account={account}
      accounts={accounts}
      provider={provider}
      ENSName={ENSName}
      ENSNames={ENSNames}
    />
  )
}
