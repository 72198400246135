import React from 'react'
import styled from 'styled-components'

const PaddingWrap = ({ children }: {
  children: React.ReactNode
}) => (
  <StyledPaddingWrap>
    {children}
  </StyledPaddingWrap>
)

const StyledPaddingWrap = styled.div`
  padding: 0 30px;

  @media (max-width: 767px) {
    padding: 0px;
  }
`

export default PaddingWrap