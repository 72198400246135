import { useContext } from 'react'

import { MarketsContext } from 'contexts/Markets'

const useMarkets = () => {
  return {
    ...useContext(MarketsContext)
  }
}

export default useMarkets