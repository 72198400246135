import { aggregate } from "@makerdao/multicall"
import Web3 from 'web3'
const INFURA_KEY = process.env.REACT_APP_INFURA_KEY


export const sendTokenList = {
    5: [{
        address: '0xB09aF801c16E2d1E45F127549Ccc5AbdAbafE482',
        symbol: "USX",
        decimals: 18,
        price: 1,
        balance: ''
    }, {
        address: '0xBc29b8A6C71B3E20096219dC47442d0c03508d77',
        symbol: "USDC",
        decimals: 6,
        price: 1,
        balance: ''
    }, {
        address: '0x384AA79B0BE5907c3409f478E9eb94777F9C494C',
        symbol: "USDT",
        decimals: 6,
        price: 1,
        balance: ''
    }, {
        address: '0x956834b4a3Bd092150efcEd5289D84228000dF80',
        symbol: "DF",
        decimals: 18,
        price: '',
        balance: ''
    }, {
        address: '0x3541E7Ba6fa19b1d5064B943df148dC541365153',
        symbol: "UTS",
        decimals: 18,
        price: '',
        balance: ''
    }],
    42161: [{
        address: '0x641441c631e2F909700d2f41FD87F0aA6A6b4EDb',
        symbol: "USX",
        decimals: 18,
        price: 1,
        balance: ''
    }, {
        address: '0xaf88d065e77c8cC2239327C5EDb3A432268e5831',
        symbol: "USDC",
        decimals: 6,
        price: 1,
        balance: ''
    }, {
        address: '0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9',
        symbol: "USDT",
        decimals: 6,
        price: 1,
        balance: ''
    }, {
        address: '0xda10009cbd5d07dd0cecc66161fc93d7c9000da1',
        symbol: "DAI",
        decimals: 18,
        price: 1,
        balance: ''
    }, {
        address: '0xaE6aab43C4f3E0cea4Ab83752C278f8dEbabA689',
        symbol: "DF",
        decimals: 18,
        price: '',
        balance: ''
    }, {
        address: '0x3B6564b5DA73A41d3A66e6558a98FD0e9E1e77ad',
        symbol: "UTS",
        decimals: 18,
        price: '',
        balance: ''
    }],
    10: [{
        address: '0xbfD291DA8A403DAAF7e5E9DC1ec0aCEaCd4848B9',
        symbol: "USX",
        decimals: 18,
        price: 1,
        balance: ''
    }, {
        address: '0x0b2C639c533813f4Aa9D7837CAf62653d097Ff85',
        symbol: "USDC",
        decimals: 6,
        price: 1,
        balance: ''
    }, {
        address: '0x94b008aa00579c1307b0ef2c499ad98a8ce58e58',
        symbol: "USDT",
        decimals: 6,
        price: 1,
        balance: ''
    }, {
        address: '0xda10009cbd5d07dd0cecc66161fc93d7c9000da1',
        symbol: "DAI",
        decimals: 18,
        price: 1,
        balance: ''
    }, {
        address: '0x9e5AAC1Ba1a2e6aEd6b32689DFcF62A509Ca96f3',
        symbol: "DF",
        decimals: 18,
        price: '',
        balance: ''
    }, {
        address: '0x3B6564b5DA73A41d3A66e6558a98FD0e9E1e77ad',
        symbol: "UTS",
        decimals: 18,
        price: '',
        balance: ''
    }],
    56: [{
        address: '0xB5102CeE1528Ce2C760893034A4603663495fD72',
        symbol: "USX",
        decimals: 18,
        price: 1,
        balance: ''
    }, {
        address: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
        symbol: "USDC",
        decimals: 18,
        price: 1,
        balance: ''
    }, {
        address: '0x55d398326f99059fF775485246999027B3197955',
        symbol: "USDT",
        decimals: 18,
        price: 1,
        balance: ''
    }, {
        address: '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3',
        symbol: "DAI",
        decimals: 18,
        price: 1,
        balance: ''
    }, {
        address: '0x4A9A2b2b04549C3927dd2c9668A5eF3fCA473623',
        symbol: "DF",
        decimals: 18,
        price: '',
        balance: ''
    }, {
        address: '0x3B6564b5DA73A41d3A66e6558a98FD0e9E1e77ad',
        symbol: "UTS",
        decimals: 18,
        price: '',
        balance: ''
    }],
    1030: [{
        address: '0x422a86f57b6b6F1e557d406331c25EEeD075E7aA',
        symbol: "USX",
        decimals: 18,
        price: 1,
        balance: ''
    }, {
        address: '0x6963efed0ab40f6c3d7bda44a05dcf1437c44372',
        symbol: "USDC",
        decimals: 18,
        price: 1,
        balance: ''
    }, {
        address: '0xfe97e85d13abd9c1c33384e796f10b73905637ce',
        symbol: "USDT",
        decimals: 18,
        price: 1,
        balance: ''
    }, {
        address: '0x53aa2b7bead41614577ba5b636c482790c5f54c5',
        symbol: "DF",
        decimals: 18,
        price: '',
        balance: ''
    }, {
        address: '0x3B6564b5DA73A41d3A66e6558a98FD0e9E1e77ad',
        symbol: "UTS",
        decimals: 18,
        price: '',
        balance: ''
    }],
    137: [{
        address: '0xCf66EB3D546F0415b368d98A95EAF56DeD7aA752',
        symbol: "USX",
        decimals: 18,
        price: 1,
        balance: ''
    }, {
        address: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
        symbol: "USDC",
        decimals: 6,
        price: 1,
        balance: ''
    }, {
        address: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
        symbol: "USDT",
        decimals: 6,
        price: 1,
        balance: ''
    }, {
        address: '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
        symbol: "DAI",
        decimals: 18,
        price: 1,
        balance: ''
    }, {
        address: '0x08C15FA26E519A78a666D19CE5C646D55047e0a3',
        symbol: "DF",
        decimals: 18,
        price: '',
        balance: ''
    }, {
        address: '0x3B6564b5DA73A41d3A66e6558a98FD0e9E1e77ad',
        symbol: "UTS",
        decimals: 18,
        price: '',
        balance: ''
    }]
}

const multicallAddressConfigs = {
    1: '0x5ba1e12693dc8f9c48aad8770482f4739beed696',
    137: '0x11ce4B23bD875D7F5C6a31084f55fDe1e9A87507',
    56: '0x294Cf1d64599b5F56D63b3DBe461f985bc5e1254',
    10: '0x7e2Dc2b896b7AAc98D6ee8e954d3f5bDCC90076b',
    42161: '0xF3919fcEE9863E1Df6dAc2D1e661A3b7e540D1C9',
    1030: '0xF276c0F19C7619A61D0EbF06DB3EC7d98CA5B1F0',
    5: '0x77dca2c955b15e9de4dbbcf1246b4b85b651e50e',
    324: '0xF9cda624FBC7e059355ce98a31693d299FACd963',
    8453: '0xcA11bde05977b3631167028862bE2a173976CA11'
}

export const RPCconfigs = {
    1: `https://mainnet.infura.io/v3/${INFURA_KEY}`,
    42161: 'https://arb1.arbitrum.io/rpc',
    137: 'https://polygon-rpc.com',
    56: 'https://bsc-dataseed.binance.org/',
    10: 'https://opt-mainnet.g.alchemy.com/v2/HbPsT1ttVTCrEpwIVEKF1FZhuxJRNcUx',
    5: 'https://rpc.ankr.com/eth_goerli',
    1030: 'https://evm.confluxrpc.com',
}

export const multicallBalance = async (account: any, chainId: any, addressArray: any) => {
    const web3Provider = new Web3(new Web3.providers.HttpProvider(RPCconfigs[chainId]))
    const multicallAddress = multicallAddressConfigs[chainId]
    const config = { web3: web3Provider, multicallAddress }

    const calls = addressArray.map((address: any) => {
        // console.log(address)
        if (address.toLowerCase() === '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee') {
            return {
                target: multicallAddress,
                call: ['getEthBalance(address)(uint256)', account],
                returns: [[`${address}`, (val: any) => val.toString()]]
            }
        }
        return {
            target: address,
            call: ['balanceOf(address)(uint256)', account],
            returns: [[`${address}`, (val: any) => val.toString()]]
        }
    })

    // console.log(calls)
    const result = await aggregate(calls, config)
    // console.log(result.results.transformed)
    return result.results.transformed
}
export const multicallAllowance = async (account: any, chainId: any, addressArray: any) => {
    const web3Provider = new Web3(new Web3.providers.HttpProvider(RPCconfigs[chainId]))
    const multicallAddress = multicallAddressConfigs[chainId]
    const config = { web3: web3Provider, multicallAddress }

    const calls = addressArray.map((address: any) => {
        return {
            target: address,
            call: ['allowance(address,address)(uint256)', account, addressZAP[chainId]],
            returns: [[`${address}`, (val: any) => val.toString()]]
        }
    })

    // console.log(calls)
    const result = await aggregate(calls, config)
    // console.log(result.results.transformed)
    return result.results.transformed
}

export const dexTypeMap = {
    1: 2,     // UniV2Like
    5: 2,     // UniV2Like
    137: 2,   // UniV2Like
    56: 2,    // UniV2Like
    1030: 2,  // UniV2Like
    42161: 3, // Camelot
    10: 4,    // VelodromeV2
}

export const addressZAP = {
    5: '0xA0D342ee8fEBc71bb788f7Abd9c09cf695AF0015',
    42161: '0x0D66fa17FAC4b7d35240fF58D278DDd2F036451F',
    10: '0xC2696d15631FB455fEDc501E627B9E42795ed3dF',
    56: '0xC2696d15631FB455fEDc501E627B9E42795ed3dF',
    1030: '0xc7D598e4434D51273BBb0418A9e764b53dDc7D63',
    137: '0x9ba66d1dE3B604644cB45D5EDc41D6abf4c1bA24',
}
export const addressToken = {
    5: {
        USX: '0xB09aF801c16E2d1E45F127549Ccc5AbdAbafE482',
        DF: '0x956834b4a3Bd092150efcEd5289D84228000dF80',
        LPUSXDF: '0x82a851a916574a73d99e7812f5bdb27b208c5b38'
    },
    42161: {
        USX: '0x641441c631e2F909700d2f41FD87F0aA6A6b4EDb',
        DF: '0xaE6aab43C4f3E0cea4Ab83752C278f8dEbabA689',
        LPUSXDF: '0x05A7FcB0bDe0dd3ECA399d7C148A2a51bd8297B8'
    },
    10: {
        USX: '0xbfD291DA8A403DAAF7e5E9DC1ec0aCEaCd4848B9',
        DF: '0x9e5AAC1Ba1a2e6aEd6b32689DFcF62A509Ca96f3',
        LPUSXDF: '0x25C488e5F69aC334ac1e8f786D890A97ec1bF713'
    },
    56: {
        USX: '0xB5102CeE1528Ce2C760893034A4603663495fD72',
        DF: '0x4A9A2b2b04549C3927dd2c9668A5eF3fCA473623',
        LPUSXDF: '0xB69fdC6531e08B366616aB30b8481bf4148786cB'
    },
    1030: {
        USX: '0x422a86f57b6b6F1e557d406331c25EEeD075E7aA',
        DF: '0x53aa2b7bead41614577ba5b636c482790c5f54c5',
        LPUSXDF: '0x795de1c2060ce0c8d3d0db4f5f6a69aa59331d27'
    },
    137: {
        USX: '0xCf66EB3D546F0415b368d98A95EAF56DeD7aA752',
        DF: '0x08C15FA26E519A78a666D19CE5C646D55047e0a3',
        LPUSXDF: '0x464d14006c890235D0e3E38FD959b6F539F040F7'
    },
}
