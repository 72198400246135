import BigNumber from "bignumber.js";
import { bnToDec, reverseCurrentChainAddressMap } from "utils";
import { network_Id, network_Name } from 'lending-sdk/utils/index1'
// 读取 assetsToken 配置文件
import stake_rewardsJson from "lending-sdk/lib/clean_build/contracts/stake/rewards.json";

export const fetchStake = async (_ChainId, _account) => {
  let reverseTokenMap, liquidityList = [], stakeList = [], earned,ARBAPR=[];

  reverseTokenMap = { ...reverseCurrentChainAddressMap(stake_rewardsJson, _ChainId) }
  // try {
  //   const res = await requestAPI(`https://beta.unitus.finance/reward/stakedInfo?network=${network_Name[_ChainId]}`)
  //   if (res.status === 'OK') {
  //     if (res?.data?.TotalStakePrePair?.length > 0) {
  //       stakeList = res.data.TotalStakePrePair
  //     }
  //   }
  // } catch (error) {
  //   console.log(error)
  // }

  try {
    const response = await fetch(`https://beta.unitus.finance/reward/stakedInfo?network=${network_Name[_ChainId]}`)
    const res = await response.json();
    if(res?.status === 'OK'){
      if (res?.data?.TotalStakePrePair?.length > 0) {
        stakeList = res.data.TotalStakePrePair
      }
    }
  } catch (error) {
    console.log(error)
  }

  // try {
  //   const res = await requestAPI(`https://beta.unitus.finance/reward/earnedInfo?network=${network_Name[_ChainId]}&user=${_account}`)
  //   if (res?.status === 'OK') {
  //     earned = res?.data
  //   }
  // } catch (error) {
  //   console.log(error)
  // }

  try {
    const response = await fetch(`https://beta.unitus.finance/reward/earnedInfo?network=${network_Name[_ChainId]}&user=${_account}`)
    const res = await response.json();
    if(res?.status === 'OK'){
      earned = res?.data
    }
  } catch (error) {
    console.log(error)
  }

  // try {
  //   const res = await requestAPI(`https://beta.unitus.finance/reward/iTokenStakedInfo?network=${network_Name[_ChainId]}`)
  //   if (res.status === 'OK') {
  //     if (res?.data?.iTokenStakedList?.length > 0) {
  //       liquidityList = res.data.iTokenStakedList
  //     }
  //   }
  // } catch (error) {
  //   console.log(error)
  // }

  try {
    const response = await fetch(`https://beta.unitus.finance/reward/iTokenStakedInfo?network=${network_Name[_ChainId]}`)
    const res = await response.json();
    if (res.status === 'OK') {
          if (res?.data?.iTokenStakedList?.length > 0) {
            liquidityList = res.data.iTokenStakedList
          }
    }
  } catch (error) {
    console.log(error)
  }

  if(_ChainId === 42161){
    // try {
    //   const res = await requestAPI("https://beta.unitus.finance/staking/getRoiAllChain")
    //   ARBAPR = res
    // } catch (error) {
    //   console.log(error)
    // }
    try {
      const response = await fetch("https://beta.unitus.finance/staking/getRoiAllChain")
      const res = await response.json();
      ARBAPR = res
    } catch (error) {
      console.log(error)
    }
  }
  
  let localConfig_alltoken = []
  for (let [k, v] of Object.entries(reverseTokenMap)) {
    localConfig_alltoken.push({
      address: k,
      ...v
    })
  }

  const stakeDate = localConfig_alltoken.map((item) => {
    let icon, chainIcon;
    const crruteARBAPR = ARBAPR.filter(cru => cru.asset === item.address)[0];
    const crruteItem = stakeList.filter(cru => cru.Address === item.LPAddress)[0];
    // console.log(crruteItem)
    // if(!crruteItem) return undefined
    // const APY = crruteItem?.APY.map((item=>{
    //   let RewardTokenIcon;
    //   try {
    //     RewardTokenIcon = require(`assets/stake/rewardToken/${item.symbol}.svg`);
    //   } catch (error) {
    //     RewardTokenIcon = require(`assets/tokenList/default.svg`);
    //   }
    //   return {
    //     ...item,
    //     RewardTokenIcon
    //   }
    // }))
    // console.log(item.symbol)
    try {
      icon = require(`assets/stake/${item.symbol}.svg`);
      // chainIcon = require(`assets/chainIcon/${crruteItem.ChainId}.svg`);
    } catch (error) {
      icon = require(`assets/tokenList/default.svg`);
      // chainIcon = require(`assets/chainIcon/0.svg`);
    }
    return {
      reveal_symbol: item.symbol,
      poolSymbol: item.poolSymbol,
      decimals: item.decimals,
      icon,
      address: item.address,
      LPAddress: item.LPAddress,
      // chainIcon,
      poolType: item?.poolType,
      poolType2: item?.poolType2,
      assetsType: item?.assetsType,
      pid: item?.pid,
      heat: item?.heat,
      Link: item?.Link,
      LinkTo: item?.LinkTo,
      TradeLink: item?.TradeLink,
      ...crruteItem,
      APR:crruteARBAPR?.totalApy,
      earned: undefined,
      poolTokenPrice: crruteItem?.Price
    }
  })
  // console.log(stakeDate)
  return { stakeDate, localConfig_alltoken, threshold: earned?.threshold, liquidityList }
}
