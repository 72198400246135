import { base64, getAddress, hexlify } from "ethers/lib/utils"
import React, { useEffect, useState, useCallback } from 'react'
import Web3 from 'web3'
import moment from "moment";

import useLending from "hooks/useLending";
import { useIntl } from "react-intl";

import { transationLink, update__local } from 'lending-sdk/utils/index1'
import styled, { keyframes } from 'styled-components'
import linkTo from 'assets/walletModal/linkTo.svg'
import clean from 'assets/walletModal/clean.svg'
import fail from 'assets/walletModal/fail.svg'
import success from 'assets/walletModal/success.svg'
import pedding from 'assets/walletModal/pedding.svg'
import PeggedTokenBridgeABI from 'abis/PeggedTokenBridge.json'
import {
  history_local__update_swap, history_local__update_swap__REFUNDED,
  history_local__add, history_local__update, add_Notifies, update_Notifies, del_Notify
} from 'views/Swap/components/utils'
// import { configs, chainId_map } from "views/Swap/configs"
import Notification from "./Notification"
import Notification__cancel from "./Notification__cancel"
import { del__cancel } from 'lending-sdk/utils/index1'

import img_arbitrum from "assets/Bridge/net-arbitrum.svg"
import img_eth from "assets/Bridge/net-eth.svg"
import img_op from "assets/Bridge/net-op.svg"
import img_bsc from "assets/Bridge/net-bsc.svg"
import img_polygon from "assets/Bridge/Polygon.svg"
import img_kava from "assets/Bridge/KAVA.svg"
import img_Conflux from "assets/Bridge/net-conflux.svg"
import img_zksync from "assets/Bridge/zkSync.svg"
import { useWeb3React } from "@web3-react/core";


const TransactionsHistory: React.FC = () => {
  
  const statusObj = {
    'pedding': pedding,
    'success': success,
    'fail': fail
  }
  const imgObj = {
    1: img_eth,
    4: img_eth,
    5: img_eth,
    42161: img_arbitrum,
    421611: img_arbitrum,
    421613: img_arbitrum,
    10: img_op,
    56: img_bsc,
    137: img_polygon,
    2222: img_kava,
    71: img_Conflux,
    1030: img_Conflux,
    324: img_zksync,
  }
  const [showHistoryIndex, setHistoryIndex] = useState<number>(1)
  const [historyBridge, setHistoryBridge] = useState<any[]>([])
  const [historySwap, setHistorySwap] = useState<any[]>([])

  const [totalAssets, setTotalAssets] = useState<any>()
  const [historyList, setHistoryList] = useState<any[]>([])
  const [isCopy, setIsCopy] = useState<boolean>(false)
  const [isclear, setIsclear] = useState<boolean>(false)

  const [nowNotifies, setNowNotifies] = useState<any>([])
  const [show__cancel, setShow__cancel] = useState<boolean>(false)

  const {
    connector,
    account,
    chainId,
    provider,
    ENSName,
    isActive,
    isActivating,
  } = useWeb3React();

  const intl = useIntl();
  const fmt = intl.formatMessage;
  const lending = useLending();

  const fetchHistory = useCallback(() => {
    const history = JSON.parse(window.localStorage.getItem(`${account}`) ?? '{}')
    lending?.contracts?.web3 && history[chainId as number] && history[chainId as number].map((item: any) => {
      if (item.state === 'pedding') {
        lending.contracts.web3.eth.getTransactionReceipt(item.transactionHash).then((res: any) => {
          if (res === null) {
            item.state = "pedding";
          } else if (res.status) {
            item.state = "success";
          } else {
            item.state = "fail";
          }
          window.localStorage.setItem(`${account}`, JSON.stringify(history))
          setHistoryList(history[chainId as number] ?? [])
        })
      }
    });
    setHistoryList(history[chainId as number] ?? [])
  }, [account, chainId, lending])

  const clearHistory = useCallback(() => {
    setIsclear(true)
    setTimeout(() => {
      setIsclear(false)
    }, 500);
    const history = JSON.parse(window.localStorage.getItem(`${account}`) ?? '{}')
    delete history[chainId as number]
    window.localStorage.setItem(`${account}`, JSON.stringify(history))

    window.localStorage.setItem(`${account}_history__swap`, '[]')
    window.localStorage.setItem(`${account}_my_bridge_history`, '[]')
    setHistorySwap([])
    setHistoryBridge([])
    fetchHistory()
  }, [account, lending, chainId])

  useEffect(() => {
    if (account && chainId) {
      fetchHistory()
      const timerHistory = setInterval(() => {
        fetchHistory()
      }, 1000 * 5)
      return () => {
        clearInterval(timerHistory)
      }
    }
  }, [account, chainId, lending])


  useEffect(() => {
    if (account) {
      const history = JSON.parse(window.localStorage.getItem(`${account}_my_bridge_history`) ?? '[]')
      // console.log(history)
      setHistoryBridge(history)

      checkHistory(account, history)

      const timer = setInterval(() => {
        const history = JSON.parse(window.localStorage.getItem(`${account}_my_bridge_history`) ?? '[]')
        setHistoryBridge(history)
      }, 1000 * 15)
      return () => { clearInterval(timer) }
    }
  }, [account])


  useEffect(() => {
    if (account) {
      const history = JSON.parse(window.localStorage.getItem(`${account}_history__swap`) ?? '[]')
      // console.log(history)
      setHistorySwap(history)

      checkHistory_swap(account, history)

      const timer = setInterval(() => {
        const history = JSON.parse(window.localStorage.getItem(`${account}_history__swap`) ?? '[]')
        setHistorySwap(history)
      }, 1000 * 15)
      return () => { clearInterval(timer) }
    }
  }, [account])

  const checkHistory_swap = (account: string, history: any) => {
    try {
      history.map(async (item: any) => {
        if ((item.src_chain !== item.dst_chain) && (!item.dst_hash)) {
          // console.log('history-item.baseURL--: ', item.baseURL)

          if (item.local_status && item.local_status === 'TRANSFER_TO_BE_REFUNDED') {
            return
          }

          if (!(item && item.baseURL)) {
            return
            console.log('history-item.baseURL--: not exist')
          }
          fetch(item.baseURL, item.requestOptions)
            .then(response => response.json())
            .then(res => {
              if (res.status < 5) {
                console.log('history---status < 5: ', res)
              } else {
                console.log('history---status >= 5: ', res)

                // TRANSFER_COMPLETED  5
                if (res.status === 5) {
                  // item.dst_hash = res.dst_block_tx_link
                  // update__local(account, item.hash_src, res.dst_block_tx_link)
                  const searchURL = 'https://api.celerscan.com/scan/searchByTxHash?tx='
                  fetch(`${searchURL}${item.src_hash}`, { method: 'get' })
                    .then(response => response.json())
                    .then(res => {
                      console.log('searchByTxHash: ', res)
                      const target_hash = res.txSearchInfo[0].message[0].execution_tx
                      console.log('target_hash: ', target_hash)
                      const tmpObj = {
                        transactionHash: item.src_hash
                      }
                      history_local__update_swap(account as string, tmpObj, res, item.baseURL, item.requestOptions, target_hash)
                    })
                }

                // TRANSFER_TO_BE_REFUNDED  6
                if (res.status === 6) {
                  const target_status = 'TRANSFER_TO_BE_REFUNDED'
                  const tmpObj = { transactionHash: item.src_hash }
                  history_local__update_swap__REFUNDED(
                    account as string, tmpObj,
                    res, item.baseURL, item.requestOptions,
                    target_status
                  )
                }

                // TRANSFER_REFUNDED  10
                if (res.status === 10) {
                  const target_status = 'TRANSFER_REFUNDED'
                  const tmpObj = { transactionHash: item.src_hash }
                  history_local__update_swap__REFUNDED(
                    account as string, tmpObj,
                    res, item.baseURL, item.requestOptions,
                    target_status
                  )
                }
              }
            })
            .catch(error => console.log('error_swap: ', error))



          // const searchURL = 'https://api.celerscan.com/scan/searchByTxHash?tx='
          // fetch(`${searchURL}${item.src_hash}`, { method: 'get' })
          //   .then(response => response.json())
          //   .then(res => {
          //     // console.log('searchByTxHash: ', res)
          //     if (
          //       res && res.txSearchInfo &&
          //       res.txSearchInfo[0] && res.txSearchInfo[0].message &&
          //       res.txSearchInfo[0].message[0] &&
          //       res.txSearchInfo[0].message[0].execution_tx
          //     ) {
          //       const target_hash = res.txSearchInfo[0].message[0].execution_tx
          //       console.log('target_hash: ', target_hash)
          //       const tmpObj = {
          //         transactionHash: item.src_hash
          //       }
          //       history_local__update_swap(account as string, tmpObj, res, item.baseURL || null, item.requestOptions || null, target_hash)
          //     }
          //   })
          //   .catch(error => console.log('error_add: ', error))
        }
      })
    } catch (err) { console.log(err) }
  }


  const checkHistory = (account: string, history: any) => {
    try {
      history.map(async (item: any) => {
        if (!item.complete_dst) {
          if (item.bridge_type === 'dforce_bridge') {
            const l2_provider = new Web3(new Web3.providers.HttpProvider(item.provider_dst))
            await l2_provider.eth.getTransactionReceipt(item.hash_dst, (err: any, res: any) => {
              console.log('err res: ', err, res)
              if (res && res.transactionHash === item.hash_dst && res.status) {
                item.complete_dst = true
                update__local(account, item.hash_dst)
              }
            })
          }

          if (item.bridge_type === 'c_bridge') {
            await fetch(item.baseURL, item.requestOptions)
              .then(response => response.json())
              .then(res => {
                console.log('fetch-res: ', res)
                if (res.status >= 5) {
                  item.complete_dst = true
                  update__local(account, item.hash_src, res.dst_block_tx_link)
                }
              })
              .catch(error => console.log('error_add__bridge__activewallet: ', error))
          }
        }
      })
    } catch (err) {
      console.log(err)
    }
  }


  const reFund = async (history: any) => {
    console.log('re-Fund-history: ', history)

    const xfer_id = (JSON.parse(history.requestOptions)).body.transfer_id
    const timestamp = Math.floor(window.Date.now() / 1000)
    const originSendAmount = history.originSendAmount

    const WithdrawReq = {
      xfer_id,
      req_id: timestamp,
      withdraw_type: 'WITHDRAW_TYPE_REFUND_TRANSFER'
    }

    const withdrawURL = 'https://cbridge-prod2.celer.app/v2/withdrawLiquidity'
    const withdrawOptions: any = {
      withdraw_req: WithdrawReq,
      estimate_received_amt: originSendAmount,
      method_type: 'WD_METHOD_TYPE_ONE_RM'
    }
    const tmpResp = await fetch(withdrawURL, withdrawOptions)
    const response = await tmpResp.json()
    console.log('withdraw Liquidity resp: ', response)


    if (response) {
      let random = Math.random()
      let timer = {}
      timer[random] = setInterval(async () => {
        const tmpResp = await fetch(history.baseURL, history.requestOptions)
        const responseRefund = await tmpResp.json()
        console.log('re-Fund-response status: ', responseRefund)

        // TRANSFER_REFUND_TO_BE_CONFIRMED  8
        if (responseRefund && responseRefund.status === 8) {
          clearInterval(timer[random])
          reFundOnChain(history)
        }
      }, 1000 * 15)
    }
  }
  const bridgeAddress = {
    42161: '0xbdd2739AE69A054895Be33A22b2D2ed71a1DE778',
    56: '0xd443FE6bf23A4C9B78312391A30ff881a097580E',
    1: '0x16365b45EB269B5B5dACB34B4a15399Ec79b95eB',
    10: '0x61f85fF2a2f4289Be4bb9B72Fc7010B3142B5f41'
  }
  const reFundOnChain = async (history: any) => {
    const wd_onchain = history.wd_onchain
    const sorted_sigs = history.sorted_sigs
    const _signers = history.signers
    const _powers = history.powers

    const wdmsg = base64.decode(wd_onchain)
    const sigs = sorted_sigs.map((item: any) => { return base64.decode(item) })
    const powers = _powers.map((item: any) => { return base64.decode(item) })
    const signers = _signers.map((item: any) => {
      const decodeSigners = base64.decode(item)
      const hexlifyObj = hexlify(decodeSigners)
      return getAddress(hexlifyObj)
    })

    // const _web3 = new Web3(new Web3.providers.HttpProvider(configs[chainId_map[history.src_chain]].rpc))
    // const PeggedTokenBridge = new _web3.eth.Contract(PeggedTokenBridgeABI, bridgeAddress[history.src_chain])
    const PeggedTokenBridge = new lending.web3.eth.Contract(PeggedTokenBridgeABI, bridgeAddress[history.src_chain])
    PeggedTokenBridge.methods.mint(wdmsg, sigs, signers, powers)
      .send({ from: account })
      .on("transactionHash", function (hash: string) {
        console.log("transactionHash: ", hash)
        const tmpObj = {
          isRefund: true,
          action: "Refund " + history.swap_token,
          src_chain: chainId,
          state: "pedding",
          src_hash: hash,
          time: new Date().getTime()
        }
        add_Notifies(tmpObj, setNowNotifies, account as string, chainId as number)
        history_local__add(account as string, tmpObj)
      })
      .on("receipt", function (receipt: any) {
        console.log("receipt: ", receipt)
        update_Notifies(receipt, setNowNotifies, account as string, chainId as number)
        history_local__update(account as string, receipt)
        setTimeout(async () => {
          await del_Notify(receipt, setNowNotifies, account as string)
        }, 3000)
      })
      .on("error", function (error: any) {
        console.log("error", error)
        setShow__cancel(true)
        setTimeout(() => {
          setShow__cancel(false)
        }, 3000)
      })
  }
  return (
    <History>
      <>
        <Notification
          chainID={chainId}
          notifies={nowNotifies}
          setNowNotifies={setNowNotifies}
          del_Notify={del_Notify}
        />
        <Notification__cancel
          notifies={show__cancel}
          setShow__cancel={setShow__cancel}
          del_Notify={del__cancel}
        />
      </>
      <HistoryTitle>
        <Title>{fmt({ id: "Transactions" })}</Title>
        <ClearAll className={isclear ? "twinkling" : ""}>
          <ClearIcon src={clean} />
          <ClearText onClick={clearHistory}>
            {fmt({ id: "Clear_all" })}
          </ClearText>
        </ClearAll>
      </HistoryTitle>

      <HistoryType>
        <HistoryItem
          onClick={() => {
            setHistoryIndex(1);
          }}
          className={showHistoryIndex === 1 ? "selected" : ""}
        >
          General
        </HistoryItem>
        <HistoryItem
          onClick={() => {
            setHistoryIndex(2);
          }}
          className={showHistoryIndex === 2 ? "selected" : ""}
        >
          Bridge
        </HistoryItem>
        {/* <HistoryItem
                onClick={() => { setHistoryIndex(3) }}
                className={showHistoryIndex === 3 ? 'selected' : ''}>
                Trade
              </HistoryItem> */}
      </HistoryType>

      <HistoryScrollWrap>
        <HistoryList>
          {showHistoryIndex === 1 &&
            historyList.map((item) => (
              <Item key={item.transactionHash}>
                <StatusIcon
                  src={statusObj[item.state]}
                  className={item.state === "pedding" ? "rotate" : ""}
                />
                <DetailInfo>
                  <DateDIV>
                    {moment(item.time).format("YYYY/MM/DD HH:mm")}
                  </DateDIV>
                  <ActionInfo
                    href={`${transationLink[chainId as number]}${
                      item.transactionHash
                    }`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <ActionText>{item.action}</ActionText>
                    <TransationLink>
                      <TransationLinkTo src={linkTo} />
                    </TransationLink>
                  </ActionInfo>
                </DetailInfo>
              </Item>
            ))}
          {showHistoryIndex === 2 &&
            historyBridge.map((bridgeItem) => {
              let link_src, link_dst;
              if (bridgeItem.bridge_type === "dforce_bridge") {
                link_src = `${transationLink[bridgeItem.chainId_src]}${
                  bridgeItem.hash_src
                }`;
                link_dst = `${transationLink[bridgeItem.chainId_dst]}${
                  bridgeItem.hash_dst
                }`;
              }
              if (bridgeItem.bridge_type === "c_bridge") {
                link_src = `${transationLink[bridgeItem.chainId_src]}${
                  bridgeItem.hash_src
                }`;
                link_dst = bridgeItem.hash_dst;
              }
              if (bridgeItem.bridge_type === "uts_bridge") {
                link_src = `${transationLink[bridgeItem.chainId_src]}${
                  bridgeItem.hash_src
                }`;
                // link_dst = `${transationLink[bridgeItem.chainId_dst]}${bridgeItem.hash_dst}`
                link_dst = bridgeItem.hash_dst;
              }
              return (
                <Item key={bridgeItem.time}>
                  <StatusIcon
                    src={
                      bridgeItem.complete_dst
                        ? statusObj.success
                        : statusObj.pedding
                    }
                    className={bridgeItem.complete_dst ? "" : "rotate"}
                  />
                  <DetailInfo>
                    <DateDIV>
                      {moment(bridgeItem.time).format("YYYY/MM/DD HH:mm")}
                    </DateDIV>

                    <ActionInfo
                      href={link_src}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <TransationLink className={"nettype"}>
                        <TransationLinkTo
                          src={imgObj[bridgeItem.chainId_src]}
                        />
                      </TransationLink>
                      <ActionText>{bridgeItem.action_send}</ActionText>
                      <TransationLink>
                        <TransationLinkTo src={linkTo} />
                      </TransationLink>
                    </ActionInfo>

                    {link_dst ? (
                      <ActionInfo
                        href={link_dst}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <TransationLink className={"nettype"}>
                          <TransationLinkTo
                            src={imgObj[bridgeItem.chainId_dst]}
                          />
                        </TransationLink>
                        <ActionText>{bridgeItem.action_receive}</ActionText>
                        <TransationLink>
                          <TransationLinkTo src={linkTo} />
                        </TransationLink>
                      </ActionInfo>
                    ) : (
                      <ActionInfo href={"javasricpt:;"}>
                        <TransationLink className={"nettype"}>
                          <TransationLinkTo
                            src={imgObj[bridgeItem.chainId_dst]}
                          />
                        </TransationLink>
                        <ActionText>{bridgeItem.action_receive}</ActionText>
                        {/* <TransationLink>
                                  <TransationLinkTo src={linkTo} />
                                </TransationLink> */}
                      </ActionInfo>
                    )}
                  </DetailInfo>
                </Item>
              );
            })}
          {showHistoryIndex === 3 &&
            historySwap.map((swapItem) => {
              let link_src, link_dst;
              link_src = `${transationLink[swapItem.src_chain]}${
                swapItem.src_hash
              }`;
              // link_dst = (swapItem.dst_hash) && (swapItem.dst_hash).includes('https')
              //   ? (swapItem.dst_hash) : `${transationLink[swapItem.dst_chain]}${swapItem.dst_hash}`
              link_dst = swapItem.dst_hash
                ? swapItem.dst_hash.includes("https")
                  ? swapItem.dst_hash
                  : `${transationLink[swapItem.dst_chain]}${swapItem.dst_hash}`
                : "";
              if (swapItem.isApprove || swapItem.isRefund) {
                return (
                  <Item key={swapItem.time}>
                    <StatusIcon
                      src={statusObj[swapItem.state]}
                      className={swapItem.state === "pedding" ? "rotate" : ""}
                    />
                    <DetailInfo>
                      <DateDIV>
                        {moment(swapItem.time).format("YYYY/MM/DD HH:mm")}
                      </DateDIV>
                      <ActionInfo
                        href={link_src}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <TransationLink className={"nettype"}>
                          <TransationLinkTo src={imgObj[swapItem.src_chain]} />
                        </TransationLink>
                        <ActionText>{swapItem.action}</ActionText>
                        <TransationLink>
                          <TransationLinkTo src={linkTo} />
                        </TransationLink>
                      </ActionInfo>
                    </DetailInfo>
                  </Item>
                );
              }
              if (swapItem.src_chain === swapItem.dst_chain) {
                return (
                  <Item key={swapItem.time}>
                    <StatusIcon
                      src={statusObj[swapItem.state]}
                      className={swapItem.state === "pedding" ? "rotate" : ""}
                    />
                    <DetailInfo>
                      <DateDIV>
                        {moment(swapItem.time).format("YYYY/MM/DD HH:mm")}
                      </DateDIV>
                      <ActionInfo
                        href={link_src}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <TransationLink className={"nettype"}>
                          <TransationLinkTo src={imgObj[swapItem.src_chain]} />
                        </TransationLink>
                        <ActionText>
                          {"Send " +
                            swapItem.swap_amount +
                            " " +
                            swapItem.swap_token +
                            " "}
                          {"Receive " +
                            swapItem.to_amount +
                            " " +
                            swapItem.to_token}
                        </ActionText>
                        <TransationLink>
                          <TransationLinkTo src={linkTo} />
                        </TransationLink>
                      </ActionInfo>
                    </DetailInfo>
                  </Item>
                );
              }
              if (
                swapItem.src_chain !== swapItem.dst_chain &&
                swapItem.local_status &&
                (swapItem.local_status === "TRANSFER_TO_BE_REFUNDED" ||
                  swapItem.local_status === "TRANSFER_REFUNDED")
              ) {
                return (
                  <Item key={swapItem.time}>
                    <StatusIcon src={statusObj.fail} className={""} />
                    <DetailInfo>
                      <DateDIV>
                        {moment(swapItem.time).format("YYYY/MM/DD HH:mm")}
                        {swapItem.local_status ===
                          "TRANSFER_TO_BE_REFUNDED" && (
                          <RefundBtn
                            onClick={() => {
                              reFund(swapItem);
                            }}
                          >
                            Refund
                          </RefundBtn>
                        )}
                        {swapItem.local_status === "TRANSFER_REFUNDED" && (
                          <RefundBtn>Refunded</RefundBtn>
                        )}
                      </DateDIV>

                      <ActionInfo
                        href={link_src}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <TransationLink className={"nettype"}>
                          <TransationLinkTo src={imgObj[swapItem.src_chain]} />
                        </TransationLink>
                        <ActionText>
                          {"Send " +
                            swapItem.swap_amount +
                            " " +
                            swapItem.swap_token}
                        </ActionText>
                        <TransationLink>
                          <TransationLinkTo src={linkTo} />
                        </TransationLink>
                      </ActionInfo>

                      <ActionInfo href={"javasricpt:;"}>
                        <TransationLink className={"nettype"}>
                          <TransationLinkTo src={imgObj[swapItem.dst_chain]} />
                        </TransationLink>
                        <ActionText>
                          {"Receive " +
                            swapItem.to_amount +
                            " " +
                            swapItem.to_token}
                        </ActionText>
                      </ActionInfo>
                    </DetailInfo>
                  </Item>
                );
              }
              return (
                <Item key={swapItem.time}>
                  <StatusIcon
                    src={
                      swapItem.state === "fail"
                        ? statusObj.fail
                        : swapItem.state === "pedding" ||
                          (swapItem.src_chain !== swapItem.dst_chain &&
                            !swapItem.dst_hash)
                        ? statusObj.pedding
                        : statusObj.success
                    }
                    className={
                      swapItem.state === "pedding" ||
                      (swapItem.src_chain !== swapItem.dst_chain &&
                        !swapItem.dst_hash)
                        ? "rotate"
                        : ""
                    }
                  />
                  <DetailInfo>
                    <DateDIV>
                      {moment(swapItem.time).format("YYYY/MM/DD HH:mm")}
                    </DateDIV>

                    <ActionInfo
                      href={link_src}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <TransationLink className={"nettype"}>
                        <TransationLinkTo src={imgObj[swapItem.src_chain]} />
                      </TransationLink>
                      <ActionText>
                        {"Send " +
                          swapItem.swap_amount +
                          " " +
                          swapItem.swap_token}
                      </ActionText>
                      <TransationLink>
                        <TransationLinkTo src={linkTo} />
                      </TransationLink>
                    </ActionInfo>

                    {link_dst ? (
                      <ActionInfo
                        href={link_dst}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <TransationLink className={"nettype"}>
                          <TransationLinkTo src={imgObj[swapItem.dst_chain]} />
                        </TransationLink>
                        <ActionText>
                          {"Receive " +
                            swapItem.to_amount +
                            " " +
                            swapItem.to_token}
                        </ActionText>
                        <TransationLink>
                          <TransationLinkTo src={linkTo} />
                        </TransationLink>
                      </ActionInfo>
                    ) : (
                      <ActionInfo href={"javasricpt:;"}>
                        <TransationLink className={"nettype"}>
                          <TransationLinkTo src={imgObj[swapItem.dst_chain]} />
                        </TransationLink>
                        <ActionText>
                          {"Receive " +
                            swapItem.to_amount +
                            " " +
                            swapItem.to_token}
                        </ActionText>
                        {/* <TransationLink>
                                  <TransationLinkTo src={linkTo} />
                                </TransationLink> */}
                      </ActionInfo>
                    )}
                  </DetailInfo>
                </Item>
              );
            })}
        </HistoryList>
      </HistoryScrollWrap>
    </History>
  );
};

const HistoryType = styled.div`
  width: 160px;
  height: 28px;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #E8ECEF;
  display: flex;
  cursor: pointer;
  margin-top: 6px;
`
const HistoryItem = styled.div`
  width: 50%;
  color: #9598A4;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #E8ECEF;

  &:last-child {
    border-right: none;
  }
  &.selected {
    color: #E8ECEF;
    background: #5EAFE7;
    border-right: none;
  }
`
const twinkling = keyframes`
  0% {
      opacity: 0.2;
      filter: alpha(opacity=20);
      transform: scale(1);
  }
  50% {
      opacity: 0.5;
      filter: alpha(opacity=50);
      transform: scale(1.12);
  }
  100% {
      opacity: 0.2;
      filter: alpha(opacity=20);
      transform: scale(1);
  }
`
const History = styled.div`
  margin-top:20px;
`
const HistoryTitle = styled.div`
  display:flex;
  justify-content: space-between;
`
const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 700;
  height: 20px;
  line-height:20px;
  color: #333;
  @media(max-width: 1199px) {
    height: 20px;
    line-height:20px;
  };
`
const ClearAll = styled.div`
  display:flex;
  align-items:center;
  cursor:pointer;
  &.twinkling{
    animation: ${twinkling} 0.5s linear forwards;
  }
`
const ClearIcon = styled.img`
  width:18px;
  height:18px;
  margin-right:4px;
  @media(max-width: 1199px) {
    width:15px;
    height:15px;
  }
`
const ClearText = styled.span`
  color:#5EAFE7;
`
const HistoryScrollWrap = styled.div`
  height:auto;
  max-height:290px;
  overflow-y:auto;
  margin-top:20px;
  @media (max-width: 767px) {
    -webkit-overflow-scrolling: touch;
    max-height:200px;
    margin-top:10px;
  }
`
const HistoryList = styled.ul`
  display:flex;
  flex-direction:column;
  margin:0;
`
const Item = styled.li`
  display:flex;
  background: #E8ECEF;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 8px;
  overflow: hidden;
  /* height:40px; */
  /* line-height:14px; */
  @media(max-width: 1199px) {
    /* height:30px; */
    /* line-height:12px; */
    font-size: 12px;
  }
`
const StatusIcon = styled.img`
  width:20px;
  height:20px;
  margin-right:26px;
  align-self: center;
  @media(max-width: 1199px) {
    width:15px;
    height:15px;
    margin-right:13px;
  }
`
const DetailInfo = styled.div`
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  font-weight:500;
`
const DateDIV = styled.div`
  color: ${(props) => props.theme.colors.lightGrey};
`
const RefundBtn = styled.span``
const ActionInfo = styled.a`
  display:flex;
  flex-direction:rows;
  align-items:center;
`
const ActionText = styled.div`
  color: ${(props) => props.theme.colors.borrow.dark};
  margin-right:11px;
  @media(max-width: 1199px) {
    margin-right:6px;
  }
`
const TransationLink = styled.div`
  display:inline-block;
  width:18px;
  height:18px;

  &.nettype {
    margin-right: 7px;
  }

  @media(max-width: 1199px) {
    width:15px;
    height:15px;
  }
`
const TransationLinkTo = styled.img`
  width:18px;
  height:18px;
  margin-top: -7px;

  @media(max-width: 1199px) {
    width:15px;
    height:15px;
    margin-top: -3px;
  }
`
export default TransactionsHistory;
