"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LendingData = void 0;
const ethers_1 = require("ethers");
// import { SupplyData, BorrowData } from "./types";
const index_1 = require("./abi/index");
class LendingData {
    constructor(lendingData, lendingHelper, provider) {
        this.getSupplyTokenData = (token) => __awaiter(this, void 0, void 0, function* () {
            return this.lendingData.callStatic.getSupplyTokenData(token);
        });
        this.getBorrowTokenData = (token) => __awaiter(this, void 0, void 0, function* () {
            return this.lendingData.callStatic.getBorrowTokenData(token);
        });
        this.getAccountTotalValue = (account) => __awaiter(this, void 0, void 0, function* () {
            return this.lendingData.callStatic.getAccountTotalValue(account);
        });
        this.getAccountSupplyTokens = (account) => __awaiter(this, void 0, void 0, function* () {
            return this.lendingData.callStatic.getAccountSupplyTokens(account);
        });
        this.getAccountBorrowTokens = (account) => __awaiter(this, void 0, void 0, function* () {
            return this.lendingData.callStatic.getAccountBorrowTokens(account);
        });
        this.getAccountMSDTokens = (account) => __awaiter(this, void 0, void 0, function* () {
            return this.lendingData.callStatic.getAccountMSDTokens(account);
        });
        this.getAccountTokens = (account) => __awaiter(this, void 0, void 0, function* () {
            return this.lendingData.callStatic.getAccountTokens(account);
        });
        this.getAccountSupplyData = (token, account, safeFactor) => __awaiter(this, void 0, void 0, function* () {
            return this.lendingData.callStatic.getAccountSupplyData(token, account, safeFactor);
        });
        this.getAccountBorrowData = (token, account, safeFactor) => __awaiter(this, void 0, void 0, function* () {
            return this.lendingData.callStatic.getAccountBorrowData(token, account, safeFactor);
        });
        this.getAccountSupplyInfo = (token, account, safeFactor) => __awaiter(this, void 0, void 0, function* () {
            return this.lendingData.callStatic.getAccountSupplyInfo(token, account, safeFactor);
        });
        this.getAccountBorrowInfo = (token, account, safeFactor) => __awaiter(this, void 0, void 0, function* () {
            return this.lendingData.callStatic.getAccountBorrowInfo(token, account, safeFactor);
        });
        this.getLiquidationInfo = (borrower, liquidator, assetBorrowed, assetCollateral) => __awaiter(this, void 0, void 0, function* () {
            return this.lendingData.callStatic.getLiquidationInfo(borrower, liquidator, assetBorrowed, assetCollateral);
        });
        this.getAssetInterestData = (token) => __awaiter(this, void 0, void 0, function* () {
            return this.lendingHelper.callStatic.getAssetInterestData(token);
        });
        this.getAccountCurrentEquity = (token, account) => __awaiter(this, void 0, void 0, function* () {
            return this.lendingHelper.callStatic.getAccountCurrentEquity(token, account);
        });
        this.provider = provider;
        this.lendingData = new ethers_1.Contract(lendingData, index_1.abi.lendingData, this.provider);
        this.lendingHelper = new ethers_1.Contract(lendingHelper, index_1.abi.lendingHelper, this.provider);
    }
}
exports.LendingData = LendingData;
