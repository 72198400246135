import React from "react";
import ReactDOM from "react-dom/client";
import { HashRouter } from "react-router-dom";
import "preline";
import "./index.css";
import "antd/dist/antd.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import 'react-app-env.d.ts'
import type { CoinbaseWallet } from "@web3-react/coinbase-wallet";
import {
  useWeb3React,
  Web3ReactHooks,
  Web3ReactProvider,
} from "@web3-react/core";
import type { MetaMask } from "@web3-react/metamask";
import type { WalletConnect as WalletConnectV2 } from "@web3-react/walletconnect-v2";

import {
  coinbaseWallet,
  hooks as coinbaseWalletHooks,
} from "./connectors/coinbaseWallet";
import { hooks as metaMaskHooks, metaMask } from "./connectors/metaMask";
import {
  hooks as walletConnectV2Hooks,
  walletConnectV2,
} from "./connectors/walletConnectV2";
import LendingProvider from "contexts/LendingProvider";
import NotifyProvider from "contexts/NotifyProvider";
import { MarketsProvider } from "contexts/Markets";

const connectors: [
  MetaMask | WalletConnectV2 | CoinbaseWallet,
  Web3ReactHooks
][] = [
  [metaMask, metaMaskHooks],
  [walletConnectV2, walletConnectV2Hooks],
  [coinbaseWallet, coinbaseWalletHooks],
];
declare global {
  interface Window {
    clipboardData: any;
  }
}
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <HashRouter>
    <Web3ReactProvider connectors={connectors}>
      <LendingProvider>
        <NotifyProvider>
          <MarketsProvider>
            <App />
          </MarketsProvider>
        </NotifyProvider>
      </LendingProvider>
    </Web3ReactProvider>
  </HashRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
reportWebVitals();
