import React from 'react'
import type { BigNumber } from '@ethersproject/bignumber'
import { formatEther } from '@ethersproject/units'
import type { Web3ReactHooks } from '@web3-react/core'
import { useEffect, useState } from 'react'
import accountLog from "../assets/accountLog.svg"
import styled from 'styled-components'


function useBalance(
  provider?: ReturnType<Web3ReactHooks['useProvider']>,
  account?:string
): BigNumber[] | undefined {
  const [balance, setBalance] = useState<BigNumber[] | undefined>()

  useEffect(() => {
    if (provider && account) {
      let stale = false

      void Promise.all([account].map((account) => provider.getBalance(account))).then((balance) => {
        console.log(balance)
        if (stale) return
        setBalance(balance)
      })
      
      return () => {
        stale = true
        setBalance(undefined)
      }
    }
  }, [provider, account])

  return balance
}

export function Account({
  account,
  provider,
  ENSName,
}: {
  account: ReturnType<Web3ReactHooks['useAccount']>
  provider: ReturnType<Web3ReactHooks['useProvider']>
  ENSName: ReturnType<Web3ReactHooks['useENSName']>
}) {
  const balance = useBalance(provider, account)

  if (account === undefined) return null

  return (
    <AccountWarp>
      <img src={accountLog} width="40"/>
      <span>{ENSName ?? (account.substring(0,5) + '...' + account.substring(account?.length-4,account?.length))}</span>
    </AccountWarp>
  )
}
const AccountWarp = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 28px;
    gap: 8px;
    margin-right: 32px;
    @media (max-width: 1199px) {
      font-size:20px;
    }
`