

export const add_Notifies = async (
    item: any,
    setNowNotifies: React.Dispatch<React.SetStateAction<any>>,
    account: string,
    chainId: number
) => {
    let local_data: any = window.localStorage.getItem(`my_notify__swap-${account}`)
    local_data = local_data ? JSON.parse(local_data) : []
    local_data.push(item)
    window.localStorage.setItem(`my_notify__swap-${account}`, JSON.stringify(local_data))
    setNowNotifies(local_data)
}
export const add_Notifies__cross = async (
    item: any,
    setNowNotifies: React.Dispatch<React.SetStateAction<any>>,
    account: string,
    chainId: number
) => {
    let local_data: any = window.localStorage.getItem(`my_notify__swap-${account}--cross`)
    local_data = local_data ? JSON.parse(local_data) : []
    local_data.push(item)
    window.localStorage.setItem(`my_notify__swap-${account}--cross`, JSON.stringify(local_data))
    setNowNotifies(local_data)
}


export const update_Notifies_cross = async (
    receipt: any,
    setNowNotifies: React.Dispatch<React.SetStateAction<any>>,
    account: string,
    chainId: number,
    target_hash: string
) => {
    let local_data: any = window.localStorage.getItem(`my_notify__swap-${account}--cross`)
    local_data = local_data ? JSON.parse(local_data) : []
    local_data.map((item: any) => {
        if (item.src_hash === receipt.transactionHash) {
            if (receipt.status) {
                item.state = "success"
                item.dst_hash = target_hash
            } else {
                item.state = "fail"
            }
        }
    })
    window.localStorage.setItem(`my_notify__swap-${account}--cross`, JSON.stringify(local_data))
    setNowNotifies(local_data)
}

export const update_Notifies_cross__REFUNDED = async (
    receipt: any,
    setNowNotifies: React.Dispatch<React.SetStateAction<any>>,
    account: string,
    chainId: number,
    target_status: string
) => {
    // const local_status = 'TRANSFER_TO_BE_REFUNDED'
    let local_data: any = window.localStorage.getItem(`my_notify__swap-${account}--cross`)
    local_data = local_data ? JSON.parse(local_data) : []
    local_data.map((item: any) => {
        if (item.src_hash === receipt.transactionHash) {
            item.local_status = target_status
            item.local_status_num = 6
            item.state = "fail"
            // if (receipt.status) {
            //     item.state = "success"
            // } else { }
        }
    })
    window.localStorage.setItem(`my_notify__swap-${account}--cross`, JSON.stringify(local_data))
    setNowNotifies(local_data)
}

export const del_Notify_cross = async (
    item: any,
    setNowNotifies: React.Dispatch<React.SetStateAction<any>>,
    account: string,
) => {
    try {
        let local_data: any = window.localStorage.getItem(`my_notify__swap-${account}--cross`)
        local_data = local_data ? JSON.parse(local_data) : []
        let new__notifies__items
        new__notifies__items = local_data.filter((notify: any) => {
            if (notify.src_hash !== item.transactionHash) {
                return notify
            }
        })
        window.localStorage.setItem(`my_notify__swap-${account}--cross`, JSON.stringify(new__notifies__items))
        setNowNotifies(new__notifies__items)
    } catch (err) {
        console.log(err)
    }
}


export const update_Notifies = async (
    receipt: any,
    setNowNotifies: React.Dispatch<React.SetStateAction<any>>,
    account: string,
    chainId: number
) => {
    let local_data: any = window.localStorage.getItem(`my_notify__swap-${account}`)
    local_data = local_data ? JSON.parse(local_data) : []
    local_data.map((item: any) => {
        if (item.src_hash === receipt.transactionHash) {
            if (receipt.status) {
                item.state = "success"
            } else {
                item.state = "fail"
            }
        }
    })
    window.localStorage.setItem(`my_notify__swap-${account}`, JSON.stringify(local_data))
    setNowNotifies(local_data)
}


export const del_Notify = async (
    item: any,
    setNowNotifies: React.Dispatch<React.SetStateAction<any>>,
    account: string,
) => {
    try {
        let tmp_data: any = window.localStorage.getItem(`my_notify__swap-${account}--cross`)
        tmp_data = tmp_data ? JSON.parse(tmp_data) : []
        let isInCross = false
        tmp_data.map((notify: any) => {
            if (notify.src_hash === item.transactionHash) {
                isInCross = true
            }
        })
        if (isInCross) {
            return del_Notify_cross(item, setNowNotifies, account)
        }


        let local_data: any = window.localStorage.getItem(`my_notify__swap-${account}`)
        local_data = local_data ? JSON.parse(local_data) : []
        let new__notifies__items
        new__notifies__items = local_data.filter((notify: any) => {
            if (notify.src_hash !== item.transactionHash) {
                return notify
            }
        })
        window.localStorage.setItem(`my_notify__swap-${account}`, JSON.stringify(new__notifies__items))
        setNowNotifies(new__notifies__items)
    } catch (err) {
        console.log(err)
    }
}




export const sleep = (ms: number) => {
    return new Promise(resolve => setTimeout(resolve, ms))
}



export const history_local__add = (account: string, argsObj: any) => {
    let local_data: any = window.localStorage.getItem(`${account}_history__swap`)
    local_data = local_data ? JSON.parse(local_data) : []

    local_data.unshift(argsObj)

    window.localStorage.setItem(`${account}_history__swap`, JSON.stringify(local_data))
}
export const history_local__update = (account: string, receipt: any) => {
    let local_data: any = window.localStorage.getItem(`${account}_history__swap`)
    local_data = local_data ? JSON.parse(local_data) : []
    local_data.map((item: any) => {
        if (item.src_hash === receipt.transactionHash) {
            if (receipt.status) {
                item.state = "success"
            } else {
                item.state = "fail"
            }
        }
    })

    window.localStorage.setItem(`${account}_history__swap`, JSON.stringify(local_data))
}
export const history_local__update_swap0 = (
    account: string,
    receipt: any,
    baseURL: any,
    requestOptions: any,
) => {
    let local_data: any = window.localStorage.getItem(`${account}_history__swap`)
    local_data = local_data ? JSON.parse(local_data) : []

    const newItems = local_data.map((item: any) => {
        if (item.src_hash === receipt.transactionHash) {
            console.log(item)
            return {
                ...item,
                baseURL, requestOptions
            }
        }

        return item
    })

    window.localStorage.setItem(`${account}_history__swap`, JSON.stringify(newItems))
}

export const history_local__update_swap = (
    account: string,
    receipt: any,
    fetch_res: any,
    baseURL: any,
    requestOptions: any,
    target_hash: string
) => {
    let local_data: any = window.localStorage.getItem(`${account}_history__swap`)
    local_data = local_data ? JSON.parse(local_data) : []

    const newItems = local_data.map((item: any) => {
        if (item.src_hash === receipt.transactionHash) {
            console.log(item)
            return {
                ...item,
                ...fetch_res,
                // dst_hash: fetch_res.dst_block_tx_link,
                dst_hash: target_hash,
                baseURL, requestOptions
            }
        }

        return item
    })

    window.localStorage.setItem(`${account}_history__swap`, JSON.stringify(newItems))
}


export const history_local__update_swap__REFUNDED = (
    account: string,
    receipt: any,
    fetch_res: any,
    baseURL: any,
    requestOptions: any,
    target_status: string
) => {
    let local_data: any = window.localStorage.getItem(`${account}_history__swap`)
    local_data = local_data ? JSON.parse(local_data) : []
    const newItems = local_data.map((item: any) => {
        if (item.src_hash === receipt.transactionHash) {
            console.log(item)
            return {
                ...item,
                ...fetch_res,
                local_status: target_status,
                local_status_num: 6,
                // dst_hash: fetch_res.dst_block_tx_link,
                // dst_hash: target_hash,
                baseURL, requestOptions
            }
        }
        return item
    })
    window.localStorage.setItem(`${account}_history__swap`, JSON.stringify(newItems))
}