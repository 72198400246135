import React, { useContext, useMemo, useState, useEffect } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { useIntl } from "react-intl"
import { walletName } from 'lending-sdk/utils/index1'
import { isMobile } from 'react-device-detect'
import settings from 'assets/header/settings.svg'
import { setupNetwork, listConfigs, logoMap } from "utils"
import theme_night from "assets/home/theme-night.svg"
import theme_day from "assets/home/theme-day.svg"
import language from "assets/home/btn-language.svg"


interface ButtonProps {
  fontSize?: any,
  children?: React.ReactNode,
  disabled?: boolean,
  href?: string,
  onClick?: () => void,
  text?: string,
  to?: string,
  variant?: 'wallet' | 'darkSupply' | 'supply' | 'darkBorrow' | 'borrow',
  chainId?: number | null,
  account?: string | null,
  changeLanguage: (newLocale: string) => void;
  locale?: any;
}

const Button: React.FC<ButtonProps> = ({
  fontSize, children, disabled,
  href, onClick, text, to, variant,
  chainId, account, changeLanguage, locale
}) => {
  const [showList, setShowList] = useState(false)
  const [showListSetting, setShowListSetting] = useState(false)
  const [isChanging, setIsChanging] = useState(false)
  const [ThemeMode, setThemeMode] = useState(theme_day)

  const { colors } = useContext(ThemeContext)
  let bgColor, fontColor, walletText
  const intl = useIntl()
  const fmt = intl.formatMessage

  walletText = (chainId && walletName[chainId])
    ? walletName[chainId]
    : fmt({ id: "wrong_network_Text" })

  switch (variant) {
    case 'wallet':
      bgColor = colors.supply.normal
      fontColor = colors.supply.white
      break
    case 'darkSupply':
      bgColor = colors.supply.dark
      break
    case 'supply':
      fontColor = colors.supply.dark
      bgColor = colors.supply.light
      break
    case 'darkBorrow':
      bgColor = colors.borrow.dark
      break
    case 'borrow':
      fontColor = colors.borrow.dark
      bgColor = colors.borrow.light
      break
    default:
      break
  }

  const ButtonChild = useMemo(() => {
    if (to) {
      return <StyledExternalLink disabled={disabled} href={to}>{text}</StyledExternalLink>
    } else if (href) {
      return <StyledExternalLink disabled={disabled} href={href} target="__blank">{text}</StyledExternalLink>
    } else {
      return text
    }
  }, [href, text, to])

  const changeNetwork = async (e: any, chainId: number) => {
    // console.log(e)
    // console.log(chainId)
    e.preventDefault()
    e.stopPropagation()
    setIsChanging(true)
    await setupNetwork(listConfigs[chainId])
    setIsChanging(false)
    setShowList(false)
  }
  const buttonOnBlur = () => {
    setTimeout(() => {
      // console.log('set button onBlur')
      // console.log(isChanging)
      if (isChanging) {
        return false
      }
      setShowList(false)
    }, 300)
  }
  const buttonOnBlurSetting = () => {
    setShowListSetting(false)
  }

  useEffect(() => {
    const hasMode = window.localStorage.getItem("ThemeMode")
    hasMode
      ? (() => {
        window.document.querySelector("html")?.classList.toggle("dark")
        setThemeMode(theme_night)
      })()
      : (() => {
        setThemeMode(theme_day)
      })();
  }, [])
  const changeThemeMode = () => {
    const res = window.document.querySelector("html")?.classList.toggle("dark");
    res
      ? (() => {
        window.localStorage.setItem("ThemeMode", "dark");
        setThemeMode(theme_night);
      })()
      : (() => {
        window.localStorage.removeItem("ThemeMode");
        setThemeMode(theme_day);
      })();
  }



  return (
    <WalletWarp>
      {
        account &&
        <NetWorkName
          onBlur={() => { buttonOnBlur() }}
          onClick={() => {
            if (isMobile) { return false }
            setShowList(!showList)
          }}
          className={(chainId && walletName[chainId]) ? '' : 'wrong_network'}
        >
          {chainId && logoMap[chainId] && <img className='logo' src={logoMap[chainId]} alt="" />}
          {walletText}
          {!isMobile && <StyledArrow className={showList ? 're' : ''} />}
          {
            showList && !isMobile &&
            <NetWorkList className=''>
              {
                listConfigs.map((item, index) => {
                  // console.log(item.chainId,index)
                  if (item.isTest) {
                    return false
                  }
                  return (
                    <NetWorkItem key={item.chainId} onClick={(e) => { changeNetwork(e, index) }}>
                      <img src={item.imgSrc} alt="" />
                      <span className={item.chainId === chainId ? 'checked' : ''}>{item.netName}</span>
                    </NetWorkItem>
                  )
                })
              }
            </NetWorkList>
          }
        </NetWorkName>
      }

      <StyledButton
        fontColor={fontColor}
        bgColor={bgColor}
        disabled={disabled}
        fontSize={fontSize}
        variant={variant}
        onClick={onClick}
      >
        {children}
        {ButtonChild}
      </StyledButton>

      {/* <Settings
        onBlur={() => { buttonOnBlurSetting() }}
        onClick={() => { setShowListSetting(!showListSetting) }}>
        <img src={settings} alt="" />
        {
          showListSetting &&
          <NetWorkList className='networkList'>
            <NetWorkItem className='networkItem' onClick={() => changeThemeMode()}>
              <span>{fmt({ id: "Theme_Mode" })}</span>
              <img src={ThemeMode} alt="" />
            </NetWorkItem>

            <NetWorkItem className='networkItem' onClick={(): void => changeLanguage(locale === 'en' ? 'cn' : 'en')}>
              <span>
                {locale === 'cn' ? 'English' : '中文'}
              </span>
              <img src={language} alt="" />
            </NetWorkItem>
          </NetWorkList>
        }
      </Settings> */}

      <Settings
        onClick={() => { changeThemeMode() }}>
        <img src={ThemeMode} alt="" />
      </Settings>
    </WalletWarp>
  )
}

interface StyledButtonProps {
  fontColor: string,
  bgColor: string,
  disabled?: boolean,
  fontSize: number,
  variant?: string
}
const StyledArrow = styled.div`
  margin-left: 16px;
  width: 0;
  height: 0;
  border: 4px solid transparent;
  margin-bottom: -5px;
  border-top: 4px solid #fff;

  &.re {
    transform: rotate(180deg);
    margin-bottom: 2px;
  }
`
const Settings = styled.button`
  position: relative;
  border: none;
  background: none;
  margin-left: 10px;
  img {
    width: 22px;
  }
  @media(max-width: 1199px) {
    img {
      width: 18px;
    }
  }
`
const WalletWarp = styled.div`
  display:flex;
  height:100%;
  position: relative;
`
const NetWorkList = styled.div`
  z-index: 999;
  position: absolute;
  top: 54px;
  left: 0;
  min-width: 180px;
  border-radius: 8px;
  padding-top: 10px;
  padding-bottom: 10px;
  border: 1px solid rgba(19, 19, 19, 0.08);
  background: ${(props) => props.theme.colors.white};
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.08);
  .dark & {
    background: ${(props) => props.theme.colors.black};
    box-shadow:0px 16px 32px 0px rgb(255 255 255 / 0.08);
  }
  &.networkList {
    left: -140px;
  }
  @media(max-width: 1199px) {
    top: 34px;
  }
`
const NetWorkItem = styled.div`
  cursor: pointer;
  color: #000;
  display: flex;
  align-items: center;
  line-height: 34px;
  padding-left: 15px;
  padding-right: 10px;

  span.checked,
  &:hover span {
    color: #5EAFE7;
  }
  .dark & {
    color: #fff;
  }
  img {
    width: 20px;
    margin-right: 12px;
  }
  &.networkItem {
    justify-content: space-between;
  }
`
const NetWorkName = styled.button`
  cursor: pointer;
  height:100%;
  line-height:36px;
  padding: 0 10px;
  margin-right: 8px;
  font-size:14px;
  font-weight:bold;
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.08);
  .logo {
    width: 24px;
    margin-right: 8px;
  }
  &.wrong_network{
    color:${props => props.theme.colors.white};
    background:${props => props.theme.colors.red};
  }
  @media(max-width: 1199px) {
    font-size:12px;
    height:30px;
    line-height:30px;
    border-radius: 4px;
    /* margin-right: 8px; */
    .logo {
      width: 18px;
    }
  }
`
const StyledButton = styled.button<StyledButtonProps>`
  display:flex;
  justify-content: center;
  align-items:center;
  width:140px;
  text-align: center;
  color: #FFF;
  font-size:${props => props.fontSize || 14}px;
  font-weight:700;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  padding:0 10px;
  outline: none;
  z-index: 9;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.08);
  @media(max-width: 1199px) {
    width: max-content;
    font-size: 12px;
    /* margin:0 auto 0;
    font-weight:${props => (props.variant === 'wallet') ? '500' : 'normal'};
    background-color: ${props => (props.variant === 'wallet') ? '#FFF3E0' : props.bgColor || props.theme.colors.supply.dark}; */
  }
`

interface StyledLinkProps {
  disabled?: boolean,
}

const StyledExternalLink = styled.a<StyledLinkProps>`
  align-items: center;
  color: inherit;
  display: flex;
  flex: 1;
  height: 56px;
  justify-content: center;
  margin: 0 ${props => -props.theme.spacing[4]}px;
  padding: 0 ${props => props.theme.spacing[4]}px;
  text-decoration: none;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
`

export default Button