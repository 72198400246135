import React from 'react'
import styled from 'styled-components'


const FlexBox = ({ children }: {
  children: React.ReactNode
}) => {

  return (
    <StyledMain>
      {children}
    </StyledMain>
  )
}

const StyledMain = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  height: 62px;
  /* backdrop-filter: blur(50px); */
  z-index: 9;
  @media (max-width: 1199px) {
    height: 55px;
  }
`

export default FlexBox