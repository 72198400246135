import React, { useState, useCallback, useEffect, useMemo, useRef } from "react";
import {
  NavLink as RouterLink
} from "react-router-dom";
import Modal from "components/ModalAction";
import ReactTooltip from 'react-tooltip'
// import ModalTitle from "components/ModalAction/components/ModalTitle";
// import ModalContent from "components/ModalAction/components/ModalContent";
import { ethers } from 'ethers'
import img_warning from "assets/Bridge/warning.png"

import styled, { keyframes } from "styled-components";
import { useIntl } from "react-intl";
import {
  bnToDec, formate_round_up, useQuery, format_bn__new,
  getDeployAddressMap, DFApproveXArray,
  setupNetwork, listConfigs, _formatNumberNotCarry,
  format_bn, numberFormatter, format_num_to_K
} from "utils";
import numeral from "numeral";
import BigNumber from "bignumber.js";
import { etherscan, network_Name, add_Notifies, update_Notifies, del_Notify, del__cancel, getAccountTotalValue } from 'lending-sdk/utils/index1'
import { useWeb3React } from '@web3-react/core'
import useLending from "hooks/useLending";
import UnlockWalletModal from "components/UnlockWalletModal";
import StakeModal from "components/StakeModal";
import Notification from "components/Notification";
import Notification__cancel from "components/Notification__cancel";
import Link from "assets/stake/link.svg"
import Switch from "assets/stake/Switch.svg"
// import unselected from "assets/supply/img__off.svg";
// import select from "assets/supply/img__on.svg";
import img_all from "assets/Bridge/net-all.svg"
import img_eth from "assets/Bridge/net-eth.svg"
import img_arbitrum from "assets/Bridge/net-arbitrum.svg"
import img_op from "assets/Bridge/net-op.svg"
import img_bsc from "assets/Bridge/net-bsc.svg"
import img_polygon from "assets/Bridge/Polygon.svg"
import img_kava from "assets/Bridge/net-kava.svg"
import close from "assets/stake/closex.svg"
import transfer from "assets/Bridge/transferzap.svg"
import img_setSlipe from "assets/Bridge/btn-set.svg"
import tooltip from 'assets/supply/tooltip.svg'
import img_close from "assets/Bridge/close.svg"

import metamask from "assets/wallet-MetaMask.svg";
import Brave from 'assets/walletLog/Brave.svg'
import coinbase from "assets/wallet-Coinbase.svg";
import icon_walletconnect from "assets/wallet-WalletConnect.svg";
// import FlexBGFilter from "components/FlexBGFilter";
import FlexBoxWrap from "components/FlexBoxWrap";
import useDebounce from '../../../hooks/useDebounce'
import zapABI from "abis/zap.json"
import erc20ABI from "abis/erc20.json"
import {
  // defaultSendToken, 
  sendTokenList,
  multicallBalance, addressZAP,
  dexTypeMap, multicallAllowance,
  addressToken
} from './configs'

import useMarkets from "hooks/useMarkets";
import TotalAssets from "components/_TotalAssets";
import { Lending } from "lending-sdk/lib";


interface MarketDistributionProps {
  stake: any[]
  threshold: string | undefined
  localConfig_alltoken: any[]
  iUSXStaked: BigNumber
}

const MarketDistribution: React.FC<MarketDistributionProps> = ({
  stake, threshold, localConfig_alltoken, iUSXStaked
}) => {
  const intl = useIntl();
  const fmt = intl.formatMessage;
  const {
    connector,
    account,
    chainId,
    provider,
    ENSName,
    isActive,
    isActivating,
  } = useWeb3React();
  const lending = useLending();
  const [unlockModalIsOpen, setUnlockModalIsOpen] = useState(false);
  const [currentNetwork, setCurrentNetwork] = useState<string>('all')
  const [currentChainId, setCurrentChainId] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(true)
  const timeRef = useRef<any>()
  // const { totalAssets } = useMarkets()
  // console.log("totalAssets",totalAssets)

  const allStake = [...stake]
  const normal = [...stake].filter(item => item?.status !== 'ended')
  const assetsData = {
    "all": allStake,
    "normal": normal
  }

  const [fetchStake, setFetchStake] = useState<any[]>([])
  const [showDetail, setShowDetail] = useState<number>(-1);

  const [TotalStaked, setTotalStaked] = useState(0);
  const [LPShare, setLPShare] = useState(0);
  const [APR, setAPR] = useState(0);
  const [_MyStaked, _setMyStaked] = useState(0);

  const [currentSymbol, setCurrentSymbol] = useState("");
  const [poolSymbol, setPoolSymbol] = useState("");
  const [address, setAddress] = useState("");
  const [action, setAction] = useState("");
  const [assetsIcon, setAssetsIcon] = useState("");
  const [MyStaked, setMyStaked] = useState<string>('...')
  const [poolType, setPoolType] = useState<string>('...')
  const [balance, setBalance] = useState<string>('...')
  const [decimals, setDecimals] = useState<number>(18)
  const [stakeModalIsOpen, setStakeModalIsOpen] = useState(false);
  const [zapModalIsOpen, setZapModalIsOpen] = useState(false);
  const [isApproved, setIsApproved] = useState<boolean>(false)
  const [isMax, setisMax] = useState<boolean>(false)
  const [allowance, setAllowance] = useState<string>('0')
  const [showSlipe, setShowSlipe] = useState(false)

  const countDownNum = 15
  const [countDown, setCountDown] = useState<any>(null)

  const [curPriceImpactArg, setCurPriceImpactArg] = useState<number>(-5) // -90%
  const [DFPrice, setDFPrice] = useState<string>('')  // 3180498921110639000
  const [UTSPrice, setUTSPrice] = useState<string>('')
  const [sendTokenAmount, setSendTokenAmount] = useState<string>('')
  const [receiveValue, setReceiveValue] = useState<string>('')
  const [stabled, setStabled] = useState<boolean>(false)
  const [sendValue, setSendValue] = useState<string>('')
  const [receiveTokenAmount, setReceiveTokenAmount] = useState<string>('')
  const [currentReceive, setCurrentReceive] = useState<any>({})
  const [curSlippageTolerance, setCurSlippageTolerance] = useState<number>(9950)
  // const [isEnable, setIsEnable] = useState(false)
  // const [rewardToken,setRewardToken] = useState<string>('DF')
  // const [rewardTokenEarnVelo,setRewardTokenEarnVelo] = useState<string>('VELO')
  // notify tips

  const [nowNotifies, setNowNotifies] = useState<any>([]);
  const [show__cancel, setShow__cancel] = useState<boolean>(false);
  const [isApproving, setIsApproving] = useState<boolean>(false);
  const [isZaping, setIsZaping] = useState<boolean>(false);
  // notify tips

  const [sendToken, setSendToken] = useState<any>({});
  const [selectTokenList, setSelectTokenList] = useState<any>([]);
  const [showSelect, setShowSelect] = useState<boolean>(false);


  const [typeList, setTypeList] = useState(false)
  const [curType, setCurType] = useState<string>('0')

  const showStakeModle = (e: any, currentSymbol: string, poolSymbol: string, action: string, assetsIcon: string, poolType: string, _isApproved: boolean, _allowance: string, _address: string) => {
    setCurrentSymbol(currentSymbol);
    setPoolSymbol(poolSymbol)
    setAddress(_address)
    setIsApproved(_isApproved)
    setAllowance(_allowance)
    // console.log(currentSymbol,_allowance)
    setAction(action);
    setAssetsIcon(assetsIcon)
    const currentBalance = stake.filter(item => item?.reveal_symbol === currentSymbol)[0].balance
    setBalance(currentBalance)
    const currentStakeBalance = stake.filter(item => item?.reveal_symbol === currentSymbol)[0].MyStaked
    setMyStaked(currentStakeBalance)
    setPoolType(poolType)
    // const currentRewardRate = stake.filter(item => item?.reveal_symbol === currentSymbol)[0].rewardRate
    // setRewardRate(currentRewardRate)
    const currentDecimal = stake.filter(item => item?.reveal_symbol === currentSymbol)[0].decimals
    setDecimals(currentDecimal)
    setStakeModalIsOpen(true);
  }

  const showModleZap = (e: any, currentItem: any) => {
    // console.log(currentItem.poolSymbol, currentItem)
    setSendToken(selectTokenList[0])
    setPoolSymbol(currentItem.poolSymbol)
    setCurrentReceive(currentItem)
    setZapModalIsOpen(true)
  }
  const handleDismissZapModal = useCallback(() => {
    setSendToken({})
    setCurrentReceive({})
    setisMax(false)
    setSendTokenAmount('')
    setZapModalIsOpen(false)
  }, [zapModalIsOpen])


  const handleDismissStakeModal = useCallback(() => {
    setStakeModalIsOpen(false);
  }, [stakeModalIsOpen])

  let sort_data: any[]
  const [sortStakeData, setSortStakeData] = useState<any[]>([])

  const sortData = useCallback((current_status: number, current_Dispatch: React.Dispatch<React.SetStateAction<number>>, current_type: string) => {
    setTotalStaked(0)
    setAPR(0)
    setLPShare(0)
    _setMyStaked(0)
    const _fetchStake = [...stake]
    if (current_status === 0) {
      current_Dispatch(1)
      sort_data = _fetchStake.sort((a, b) => Number(b[current_type]) - Number(a[current_type]))
    } else if (current_status === 1) {
      current_Dispatch(-1)
      sort_data = _fetchStake.sort((a, b) => Number(a[current_type]) - Number(b[current_type]))
    } else if (current_status === -1) {
      current_Dispatch(0)
      sort_data = assetsData["all"]
    }
    console.log(sort_data)
    setSortStakeData(sort_data)
  }, [fetchStake])

  const handleUnlockWalletClick = useCallback(() => {
    setUnlockModalIsOpen(true);
  }, [setUnlockModalIsOpen]);



  useEffect(() => {
    if (stake.length > 0) {
      //每次轮询 取API最新数据进行更新
      const fetchStake = [...stake].filter((_it) => (currentChainId ? (_it.ChainId === currentChainId) : true)).filter((_item) => _item?.status !== 'ended');
      if (sortStakeData.length > 0) {
        const updateSortStakeData = sortStakeData.reduce((result: any[], item) => {
          const currentItem = fetchStake.find(item2 => item2.address === item?.address)
          if (currentItem) {
            result.push(currentItem)
          }
          return result
        }, [])
        setFetchStake(updateSortStakeData)
      } else {
        setFetchStake(assetsData['normal'].filter((_it) => (!!currentChainId ? (_it.ChainId === currentChainId) : true)))
      }
    }
  }, [stake, sortStakeData, currentChainId])

  const showDetailHandler = (e: any, index: number) => {
    // 点击tr行 显示 Detail 组件, 点击Detail组件禁止冒泡
    if (document.querySelectorAll('.detail')[index]?.contains(e.target)) {
      return
    }

    if (showDetail === index) {
      setShowDetail(-1);
    } else {
      setShowDetail(index);
    }
  }

  const switchNetwork = (e: any) => {
    const currentChain = e.target.dataset.chain;
    console.log(e.target)
    console.log("currentChain", currentChain)
    // console.log(e.target.tagName)
    // console.log(e.currentTarget.querySelector(".active"))
    setCurrentChainId(Number(currentChain))
    setCurrentNetwork(network_Name?.[currentChain] ?? 'all')
    if (e.target.tagName === 'A') {
      e.currentTarget.querySelector(".active")?.classList.remove("active");
      e.target.parentNode.classList.add('active')
    } else if (e.target.tagName === 'UL') {
      return;
    }
    // console.log(e.target.datasets("networkName"))
  };

  const [metamaskActive, setMetamaskActive] = useState(false);
  const [walletConnectActive, setWalletConnectActive] = useState(false);
  const [walletIcon, setWalletIcon] = useState("");
  const handleDismissUnlockModal = useCallback(() => {
    setUnlockModalIsOpen(false);
    setMetamaskActive(false);
    setWalletConnectActive(false);
  }, [setUnlockModalIsOpen]);

  useEffect(() => {
    setLoading(true)
    setShowDetail(-1)
    setStakeModalIsOpen(false);
    // console.log(showDetail)
  }, [account, chainId, currentChainId])
  useEffect(() => {
    if (sortStakeData.length > 0) {
      let allStake = [...stake].filter((_it) => (currentChainId ? (_it.ChainId === currentChainId) : true)).filter((_item) => _item?.status !== 'ended');

      // 判断是否 selectType
      switch (curType) {
        case '0':
          break;
        case '1':
          allStake = allStake.filter(item => ((item?.poolType !== 'Beefy') && (item?.poolType !== 'Vault')))
          break;
        default:
          break;
      }
      const updateSortStakeData = sortStakeData.reduce((result: any[], item) => {
        const currentItem = allStake.find(item2 => item2.address === item?.address)
        if (currentItem) {
          result.push(currentItem)
        }
        return result
      }, [])
      setFetchStake(updateSortStakeData)
      setLoading(false)
    } else {
      switch (curType) {
        case '0':
          setFetchStake(assetsData['normal'].filter((_it) => (!!currentChainId ? (_it.ChainId === currentChainId) : true)))
          setLoading(false)
          break;
        case '1':
          setFetchStake(assetsData['normal'].filter((_it) => (!!currentChainId ? (_it.ChainId === currentChainId) : true)).filter(item => ((item?.poolType !== 'Beefy') && (item?.poolType !== 'Vault'))))
          setLoading(false)
          break;
        default:
          break;
      }
    }

  }, [stake, sortStakeData, currentChainId, curType])

  useEffect(() => {
    // stakeModal isOpen 时候 轮询更新 balance
    if (currentSymbol !== '') {
      const currentBalance = stake.filter(item => item?.reveal_symbol === currentSymbol)[0].balance
      setBalance(currentBalance)
      const currentStakeBalance = stake.filter(item => item?.reveal_symbol === currentSymbol)[0].MyStaked
      setMyStaked(currentStakeBalance)
    }
  }, [currentSymbol])
  const [TotalStakeValue, setTotalStakeValue] = useState<BigNumber>(new BigNumber(0))
  const [MyTotalStakeValue, setMyTotalStakeValue] = useState<BigNumber>(new BigNumber(0))
  const [thresholdStatus, setThresholdStatus] = useState<number | undefined>(undefined)
  const [totalValue,setTotalValue] = useState<any | undefined>(undefined)
  // const [percent,setPercent] = useState<string | undefined>(undefined)
  const [needed, setNeeded] = useState<BigNumber>(new BigNumber(0))
  // const [MyTotalSupply,setMyTotalSupply] = useState<string>('0')
  const GetTotalValue = useCallback(async()=>{
    if(chainId && account && lending?.contracts){
      try {
        const totalValue = await getAccountTotalValue(lending.web3.currentProvider, chainId, account);
        setTotalValue(totalValue);
      } catch (error) {
        setTotalValue(undefined)
      }
      
    }
  },[lending,account,chainId])
  useEffect(() => {
    setMyTotalStakeValue(new BigNumber(0))
    setTotalStakeValue(new BigNumber(0))
    setThresholdStatus(undefined)
    setNeeded(new BigNumber(0))
    // debugger
    // if(chainId && stake.length>0 && new BigNumber(totalAssets?.totalValue?.[0]).plus(iUSXStaked).gt('0') && threshold){
    if (chainId && stake.length > 0 && threshold) {
      try {
        GetTotalValue();
        let MyStakeValue: BigNumber = new BigNumber(0)
        let _TotalStakeValue: BigNumber = new BigNumber(0)
        stake.map((item: any) => {
          // console.log(MyStakeValue,item?.MystakedValue)
          // console.log(item)
          MyStakeValue = new BigNumber(MyStakeValue).plus(new BigNumber(item?.MystakedValue))
          _TotalStakeValue = new BigNumber(_TotalStakeValue).plus(new BigNumber(item?.TotalStakedValue))
        })
        setMyTotalStakeValue(MyStakeValue)
        setTotalStakeValue(_TotalStakeValue)
        // setMyTotalSupply(totalAssets?.totalValue?.[0])
        // const MyStakeValue = stake.reduce((pre: any, cur: any,)=>{
        //   console.log(pre.MystakedValue,cur.MystakedValue)
        //   return (new BigNumber(pre.MystakedValue).plus(new BigNumber(cur.MystakedValue)))
        // },[{MystakedValue:'0'}])
        // console.log(totalAssets?.totalValue?.[0],MyStakeValue.toString(),_TotalStakeValue.toString(),threshold)
        // debugger

        const thresholdValue = new BigNumber(new BigNumber(totalValue?.[0]).plus(iUSXStaked).multipliedBy(new BigNumber(threshold)).div(new BigNumber(10).pow(18)).toFixed(0))
        if (isNaN(Number(MyTotalStakeValue)) || isNaN(Number(MyStakeValue))) {
          return;
        }
        // console.log(thresholdValue.toString(),totalAssets?.totalValue?.[0],iUSXStaked.toString())
        if (new BigNumber(totalValue?.[0]).plus(iUSXStaked).lte(new BigNumber(0))) {
          setThresholdStatus(0)
        } else {
          // console.log("MyStakeValue",MyStakeValue.dividedBy(new BigNumber(10).pow(18)).toString())
          // console.log("thresholdValue",thresholdValue.dividedBy(new BigNumber(10).pow(18)).toString())
          if (MyStakeValue.lte(thresholdValue)) {
            setThresholdStatus(1)
            // setPercent(_formatNumberNotCarry(bnToDec(MyStakeValue.multipliedBy(new BigNumber(10).pow(18)).div(new BigNumber(totalAssets?.totalValue?.[0])), 18,4),'percent'))
            setNeeded(thresholdValue.minus(MyStakeValue))
          } else {
            setThresholdStatus(2)
            // setPercent(_formatNumberNotCarry(bnToDec(MyStakeValue.multipliedBy(new BigNumber(10).pow(18)).div(new BigNumber(totalAssets?.totalValue?.[0])), 18,4),'percent'))
            setNeeded(thresholdValue)
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
  }, [stake, chainId,totalValue,threshold, iUSXStaked])

  const [showList, setShowList] = useState(false)

  const NetMap = {
    all: {
      net: 'All_Chains',
      icon: img_all
    },
    mainnet: {
      net: 'Ethereum',
      icon: img_eth
    },
    ArbitrumOne: {
      net: 'Arbitrum',
      icon: img_arbitrum
    },
    Optimism: {
      net: 'Optimism',
      icon: img_op
    },
    bsc: {
      net: 'BSC',
      icon: img_bsc
    },
    Polygon: {
      net: 'Polygon',
      icon: img_polygon
    },
    Kava: {
      net: 'Kava',
      icon: img_kava
    },
    Goerli: {
      net: 'Goerli',
      icon: img_eth
    },
  }

  // const buttonOnBlur = () => {
  //   setTimeout(() => {
  //     setShowList(false)
  //   }, 300)
  // }
  useEffect(() => {
    document.body.addEventListener("click", (e) => {
      // 如果点击了 网络切换 select 列表不消失
      const NetworkSelect = document
        .querySelector(".NetworkName")
        ?.contains(e.target as HTMLElement);
      if (NetworkSelect) {
        return;
      }
      if (showList) {
        setShowList(false)
      }
      // 如果点击了 网络切换 select 列表不消失
      const TypeSelect = document
        .querySelector(".SelectType")
        ?.contains(e.target as HTMLElement);
      if (TypeSelect) {
        return;
      }
      if (typeList) {
        setTypeList(false)
      }

    });
    return () => {
      document.body.removeEventListener("click", (event) => {
        event.preventDefault();
      }, false);
    };
  }, [showList, typeList]);



  useEffect(() => {
    if (account && chainId) {
      const addressArr = sendTokenList[chainId] &&
        sendTokenList[chainId].length > 0 &&
        sendTokenList[chainId].map((item: any) => {
          return item.address
        })
      const getBalance = async () => {
        const balances = await multicallBalance(account, chainId, addressArr)
        const allowance = await multicallAllowance(account, chainId, addressArr)
        // console.log(allowance)
        const originList = sendTokenList[chainId]
        const newList = originList.map((sendItem: any) => {
          return {
            ...sendItem,
            balance: balances[sendItem.address],
            allowance: allowance[sendItem.address]
          }
        })

        // console.log(newList)
        setSelectTokenList(newList)
      }
      getBalance()

      const t = setInterval(() => {
        getBalance()
      }, 1000 * 15)
      return () => { clearInterval(t) }
    }
  }, [chainId, account])


  useEffect(() => {
    if (!zapModalIsOpen) return
    if (account && chainId && lending) {
      // console.log('sendToken.symbol: ', sendToken)
      // console.log('pool symbol: ', poolSymbol)

      const getBalance = async () => {
        const contranct_sendToken = new lending.web3.eth.Contract(erc20ABI, sendToken.address)
        const contranct_receiveToken = new lending.web3.eth.Contract(erc20ABI, currentReceive.LPAddress)
        const balance_send = await contranct_sendToken.methods.balanceOf(account).call()
        const allowance_send = await contranct_sendToken.methods.allowance(account, addressZAP[chainId]).call()
        const balance_receive = await contranct_receiveToken.methods.balanceOf(account).call()

        // console.log(balance_send, balance_receive)
        // console.log('allowance_send: ', allowance_send)
        setSendToken({
          ...sendToken,
          balance: balance_send,
          allowance: allowance_send
        })
        setCurrentReceive({
          ...currentReceive,
          MyLPBalance: balance_receive
        })
      }
      getBalance()

      const t = setInterval(() => {
        getBalance()
      }, 1000 * 5)
      return () => { clearInterval(t) }
    }
  }, [sendToken.address, poolSymbol, chainId, account, currentReceive.address, zapModalIsOpen])


  useEffect(() => {
    if (!(currentReceive.poolTokenPrice && receiveTokenAmount)) {
      setReceiveValue('')
      return
    }

    const calcReceiveValue = async () => {
      const clacReceiveValue = new BigNumber(receiveTokenAmount)
        .multipliedBy(new BigNumber(currentReceive.poolTokenPrice))
        .dividedBy(new BigNumber(10 ** 18))
        .toFixed(0)
        .toString()
      // console.log('lp-receive Value: ', clacReceiveValue)
      setReceiveValue(clacReceiveValue)
      setStabled(true)
    }

    calcReceiveValue()

    const t = setInterval(() => {
      calcReceiveValue()
    }, 1000 * 15)
    return () => { clearInterval(t) }
  }, [receiveTokenAmount, currentReceive.address, currentReceive.poolTokenPrice])

  useEffect(() => {
    if (!chainId) return

    const getUTSPrice = async () => {
      // const resRewardInfo: any = await requestAPI(`https://beta.unitus.finance/reward/rewardInfo?network=${network_Name[chainId]}`)
      // if (resRewardInfo.data.rewardTokenPrice) {
      //   setUTSPrice(resRewardInfo.data.rewardTokenPrice)
      // } else {
      //   setUTSPrice('')
      // }

      try {
        const response = await fetch(`https://beta.unitus.finance/reward/rewardInfo?network=${network_Name[chainId]}`)
        const resRewardInfo = await response.json();
        if (resRewardInfo?.data?.rewardTokenPrice) {
          setUTSPrice(resRewardInfo.data.rewardTokenPrice)
        } else {
          setUTSPrice('')
        }
      } catch (error) {
        console.log(error)
      }
    }

    getUTSPrice()

    const t = setInterval(() => {
      getUTSPrice()
    }, 1000 * 15)
    return () => { clearInterval(t) }
  }, [chainId])

  useEffect(() => {
    if (chainId && lending) {
      const getDFPrice = async () => {
        // console.log('USX: ', addressToken[chainId].USX)
        // console.log('DF: ', addressToken[chainId].DF)
        // console.log('LP: ', addressToken[chainId].LPUSXDF)
        if (!addressToken[chainId].LPUSXDF) { return }

        const contract_USX = new lending.web3.eth.Contract(erc20ABI, addressToken[chainId].USX)
        const contract_DF = new lending.web3.eth.Contract(erc20ABI, addressToken[chainId].DF)

        const balanceUSX = await contract_USX.methods.balanceOf(addressToken[chainId].LPUSXDF).call()
        const balanceDF = await contract_DF.methods.balanceOf(addressToken[chainId].LPUSXDF).call()

        // console.log('balanceUSX: ', balanceUSX)
        // console.log('balanceDF: ', balanceDF)
        const calcDFPrice = new BigNumber(balanceUSX)
          .multipliedBy(new BigNumber(10 ** 18))
          .dividedBy(new BigNumber(balanceDF))
          .toFixed(0)
          .toString()
        // console.log('DFPrice: ', calcDFPrice)
        setDFPrice(calcDFPrice)
      }

      getDFPrice()

      const t = setInterval(() => {
        getDFPrice()
      }, 1000 * 15)
      return () => { clearInterval(t) }
    }
  }, [chainId, lending])

  useEffect(() => {
    if (sendTokenAmount && sendToken.symbol) {
      // console.log('send Amount: ', sendTokenAmount)
      // console.log('symbol: ', sendToken.symbol)
      // console.log('UTSPrice: ', UTSPrice)
      // console.log('DFPrice: ', DFPrice)
      // console.log('sendToken: ', sendToken)

      if (sendToken.price && sendToken.price === 1) {
        const calcSendValue = new BigNumber(sendTokenAmount)
          .multipliedBy(new BigNumber(10 ** 18))
          .toString()
        setSendValue(calcSendValue)
      }
      if (sendToken.symbol === 'UTS') {
        const calcSendValue = new BigNumber(sendTokenAmount)
          .multipliedBy(new BigNumber(10 ** 18))
          .multipliedBy(new BigNumber(UTSPrice))
          .dividedBy(new BigNumber(10 ** 18))
          .toFixed(0)
          .toString()
        setSendValue(calcSendValue)
      }
      if (sendToken.symbol === 'DF') {
        const calcSendValue = new BigNumber(sendTokenAmount)
          .multipliedBy(new BigNumber(10 ** 18))
          .multipliedBy(new BigNumber(DFPrice))
          .dividedBy(new BigNumber(10 ** 18))
          .toFixed(0)
          .toString()
        setSendValue(calcSendValue)
      }
    }
  }, [sendTokenAmount, sendToken.symbol, UTSPrice, DFPrice])


  const pre_approve = () => {
    const curTokenAddress = sendToken.address
    if (
      DFApproveXArray.includes(curTokenAddress) &&
      new BigNumber(sendToken.allowance).gt(new BigNumber(0))
    ) {
      approveToken(true)
    } else {
      approveToken()
    }
  }
  const approveToken = async (reApprove?: boolean) => {
    if (isApproving) return
    if (lending && account && chainId) {
      const curToken = new lending.web3.eth.Contract(erc20ABI, sendToken.address)
      setIsApproving(true)
      curToken.methods
        .approve(
          addressZAP[chainId],
          reApprove ? '0' : ethers.constants.MaxUint256
        )
        .send({ from: account })
        .on('transactionHash', function (hash: string) {
          console.log('hash: ', hash)
          add_Notifies({
            state: "pedding",
            action: "Enable " + sendToken.symbol,
            transactionHash: hash,
            time: new Date().getTime()
          }, setNowNotifies, account as string, chainId as number)
        })
        .on('receipt', async (receipt: any) => {
          console.log(receipt)
          update_Notifies(receipt, setNowNotifies, account as string, chainId as number)
          setTimeout(() => {
            del_Notify(receipt, setNowNotifies)
          }, 3000)
          setTimeout(() => {
            setIsApproving(false)
            if (reApprove) {
              approveToken()
            }
          }, 5000)
        })
        .on('error', function (error: any) {
          setShow__cancel(true)
          setIsApproving(false)
          setTimeout(() => {
            setShow__cancel(false)
          }, 3000)
        })
    }
  }
  const zapMax = () => {
    if (!sendToken.balance) return
    setSendTokenAmount(format_bn__new(sendToken.balance, sendToken.decimals, 6))
    setisMax(true)
  }
  const InputZapChange = (value: string) => {
    setisMax(false)
    setStabled(false)

    value = value.trim()
    var reg_ZH_CN = new RegExp("[\\u4E00-\\u9FFF]+", "g")
    var reg_ABC = /[a-z]/g
    // 限制只可输入小数点后6位
    let quantityScale = "6"
    let reg_maxLength = new RegExp("^(0|[1-9][0-9]*)(\\.[0-9]{0," + quantityScale + "})?$")
    // 限制只可输入小数点后6位
    if (reg_ZH_CN.test(value)) {
      return console.log("不可以输入中文")
    }
    if (reg_ABC.test(value)) {
      return console.log("English ABC prohibited");
    }
    if (!reg_maxLength.test(value) && value !== "") {
      return console.log("The maximum number of digits after the decimal point is exceeded")
    }
    const gtBalance = new BigNumber(value)
      .multipliedBy(new BigNumber(10 ** sendToken.decimals))
      .gt(new BigNumber(sendToken.balance))
    if (gtBalance) {
      zapMax()
    } else {
      setSendTokenAmount(value)
    }
  }


  const debouncedAmount = useDebounce(sendTokenAmount, 700)
  useEffect(() => {
    if (!sendTokenAmount) {
      setReceiveTokenAmount('')
      setReceiveValue('')
      return
    }
    if (!debouncedAmount || !chainId) {
      // console.log('sendToken.symbol: ', sendToken.symbol)
      // console.log('pool symbol: ', poolSymbol)
      setReceiveTokenAmount('')
      setReceiveValue('')
    } else {
      const getLiquidity = async () => {
        const contract_ZAP = new lending.web3.eth.Contract(zapABI, addressZAP[chainId])
        // const _path = currentReceive.poolSymbol.includes(sendToken.symbol)
        //   ? sendToken.address
        //   : addressToken[chainId][currentReceive.poolSymbol.split('-')[0]]
        const _path = sendToken.price === 1
          ? addressToken[chainId].DF
          : sendToken.symbol === 'DF'
            ? '0x0000000000000000000000000000000000000000'
            : sendToken.symbol === 'UTS'
              ? currentReceive.poolSymbol === 'USX-UTS'
                ? addressToken[chainId].DF
                : '0x0000000000000000000000000000000000000000'
              : ''
        // console.log(_path)
        const _liquidity = await contract_ZAP.methods
          .calcLiquidity(
            sendToken.address,  // one token of DAI/DF/USDC/USDT/UTS
            new BigNumber(debouncedAmount)
              .multipliedBy(new BigNumber(10 ** sendToken.decimals))
              .toString(), // input amount of input token
            dexTypeMap[chainId],
            _path,
            currentReceive.LPAddress // LP: DF-UTS LP / USX-UTS LP
          )
          .call()
        // console.log('_liquidity: ', _liquidity)
        setReceiveTokenAmount(_liquidity)
        setCountDown(countDownNum)
      }
      getLiquidity()


      const t = setInterval(() => {
        // console.log('debounced-Amount-1: ', debouncedAmount)
        // console.log('sendToken.symbol-1: ', sendToken.symbol)
        // console.log('pool symbol-1: ', poolSymbol)
        getLiquidity()
      }, 1000 * 15)
      return () => { clearInterval(t) }
    }
  }, [debouncedAmount, sendToken.symbol, poolSymbol, currentReceive.poolSymbol, chainId, sendTokenAmount])

  useEffect(() => {
    if (countDown && countDown !== 0) {
      timeRef.current = setTimeout(() => {
        setCountDown((countDown: any) => countDown - 1)
      }, 1000)
    }

    return () => { clearTimeout(timeRef.current) }
  }, [countDown])
  useEffect(() => {
    if (debouncedAmount && stabled) {
      setCountDown(countDownNum)
    }
  }, [stabled, debouncedAmount])


  const zap = async () => {
    // if (!(stabled && sendValue && receiveValue)) return
    // if (!(stabled)) return
    if (!receiveTokenAmount) return

    if (isZaping) return
    if (lending && account && chainId) {
      setIsZaping(true)
      const _inputAmount = new BigNumber(debouncedAmount)
        .multipliedBy(new BigNumber(10 ** sendToken.decimals))
        .toString()
      const _minReceived = new BigNumber(receiveTokenAmount)
        .multipliedBy(new BigNumber(curSlippageTolerance))
        .dividedBy(new BigNumber(10000))
        .toFixed(0)
        .toString()
      // const _path = currentReceive.poolSymbol.includes(sendToken.symbol)
      //   ? sendToken.address
      //   : addressToken[chainId][currentReceive.poolSymbol.split('-')[0]]
      const _path = sendToken.price === 1
        ? addressToken[chainId].DF
        : sendToken.symbol === 'DF'
          ? '0x0000000000000000000000000000000000000000'
          : sendToken.symbol === 'UTS'
            ? currentReceive.poolSymbol === 'USX-UTS'
              ? addressToken[chainId].DF
              : '0x0000000000000000000000000000000000000000'
            : ''
      const timestamp = Number((Date.now() / 1000).toFixed(0))
      const delay = 60 * 10 // 60s 10min
      const _deadline = timestamp + delay
      const abiCoder = new ethers.utils.AbiCoder()
      const _extraData = abiCoder.encode(["address"], [currentReceive.address])

      const contract_ZAP = new lending.web3.eth.Contract(zapABI, addressZAP[chainId])
      contract_ZAP.methods
        .zapIn({
          _inputToken: sendToken.address,
          _inputAmount,
          _dexType: dexTypeMap[chainId],
          _path,
          _outputToken: currentReceive.LPAddress,
          _minReceived: _minReceived,
          _deadline,
          _extraData
        })
        .send({ from: account })
        .on('transactionHash', function (hash: string) {
          console.log('hash: ', hash)
          add_Notifies({
            state: "pedding",
            action: "Send " + sendTokenAmount + ' ' + sendToken.symbol +
              ',Receive ' + format_bn__new(receiveTokenAmount, 18, 2) + ' ' + currentReceive.poolSymbol,
            transactionHash: hash,
            time: new Date().getTime()
          }, setNowNotifies, account as string, chainId as number)
          InputZapChange('')
          setIsZaping(false)
          setZapModalIsOpen(false)
        })
        .on('receipt', async (receipt: any) => {
          console.log(receipt)
          update_Notifies(receipt, setNowNotifies, account as string, chainId as number)
          setTimeout(() => {
            del_Notify(receipt, setNowNotifies)
          }, 3000)
        })
        .on('error', function (error: any) {
          setShow__cancel(true)
          setIsZaping(false)
          setTimeout(() => {
            setShow__cancel(false)
          }, 3000)
          if (!error.toString().includes("-32601")) {
            return console.log(error)
          }
        })
        .catch((error: any) => {
          setShow__cancel(true)
          setIsZaping(false)
          setTimeout(() => {
            setShow__cancel(false)
          }, 3000)
          if (!error.toString().includes("-32601")) {
            return console.log(error)
          }
        })
    }
  }


  const TokenLogo = (args: any) => {
    // console.log(args)
    let imgSrc
    try {
      imgSrc = require(`assets/tokenList/${args.logo}.svg`)
    } catch (error) {
      imgSrc = require(`assets/tokenList/default.svg`)
    }
    return (
      <StyledLogo src={imgSrc} alt="" />
    )
  }
  const TokenLogoDouble = () => {
    let imgSrc0, imgSrc1
    try {
      imgSrc0 = require(`assets/tokenList/${poolSymbol.split('-')[0]}.svg`)
    } catch (error) {
      imgSrc0 = require(`assets/tokenList/default.svg`)
    }
    try {
      imgSrc1 = require(`assets/tokenList/${poolSymbol.split('-')[1]}.svg`)
    } catch (error) {
      imgSrc1 = require(`assets/tokenList/default.svg`)
    }
    return (
      <NewLogoWrap>
        <StyledLogoNew src={imgSrc0} alt="" />
        <StyledLogoNew src={imgSrc1} alt="" className="spe" />
      </NewLogoWrap>
    )
  }


  const handlePriceImpact = () => {
    // receiveValue > sendValue
    if (new BigNumber(receiveValue).gte(new BigNumber(sendValue))) {
      return format_bn(new BigNumber(receiveValue)
        .minus(new BigNumber(sendValue))
        .multipliedBy(new BigNumber(10 ** 18))
        .dividedBy(new BigNumber(sendValue))
        .toFixed(0)
        .toString(), 16, 2) + '%'
    } else {
      // const tmpImpact = new BigNumber(sendValue)
      //   .minus(new BigNumber(receiveValue))
      //   .multipliedBy(new BigNumber(10 ** 24))
      //   .dividedBy(new BigNumber(sendValue))
      //   .dividedBy(new BigNumber(10 ** 18))
      //   .toFixed(0)
      // console.log(tmpImpact)
      const showImpact = '-' + format_bn(new BigNumber(sendValue)
        .minus(new BigNumber(receiveValue))
        .multipliedBy(new BigNumber(10 ** 18))
        .dividedBy(new BigNumber(sendValue))
        .toFixed(0)
        .toString(), 16, 2) + '%'
      // if (Number(tmpImpact) >= 50000) {
      //   return (
      //     <RedSpan>{showImpact}</RedSpan>
      //   )
      // }
      // if (Number(tmpImpact) >= 30000) {
      //   return (
      //     <OrangeSpan>{showImpact}</OrangeSpan>
      //   )
      // }
      return (
        <span>{showImpact}</span>
      )
    }
  }
  const handlePriceImpactTips = () => {
    const tmpImpact = new BigNumber(sendValue)
      .minus(new BigNumber(receiveValue))
      .multipliedBy(new BigNumber(10 ** 24))
      .dividedBy(new BigNumber(sendValue))
      .dividedBy(new BigNumber(10 ** 18))
      .toFixed(0)
    // console.log(tmpImpact)
    const showImpact = '-' + format_bn(new BigNumber(sendValue)
      .minus(new BigNumber(receiveValue))
      .multipliedBy(new BigNumber(10 ** 18))
      .dividedBy(new BigNumber(sendValue))
      .toFixed(0)
      .toString(), 16, 2) + '%'
    if (Number(tmpImpact) >= 100000) {
      return (
        <StyledTips>
          <img src={img_warning} alt="" />
          <RedSpan>{fmt({ id: "Price_Impact" }, { showImpact: showImpact })}</RedSpan>
        </StyledTips>
      )
    }
    if (Number(tmpImpact) >= 50000) {
      return (
        <StyledTips>
          <img src={img_warning} alt="" />
          <OrangeSpan>{fmt({ id: "Price_Impact" }, { showImpact: showImpact })}</OrangeSpan>
        </StyledTips>
      )
    }
    return (
      <StyledTips>
        <img src={img_warning} alt="" />
        {fmt({ id: "Price_Impact" }, { showImpact: showImpact })}
      </StyledTips>
    )
  }
  // function TokenLogoDouble(symbol: any) {
  //   const memo = useMemo(() => {
  //     let imgSrc0, imgSrc1
  //     try {
  //       imgSrc0 = require(`assets/tokenList/${symbol.split('-')[0]}.svg`)
  //     } catch (error) {
  //       imgSrc0 = require(`assets/tokenList/default.svg`)
  //     }
  //     try {
  //       imgSrc1 = require(`assets/tokenList/${symbol.split('-')[1]}.svg`)
  //     } catch (error) {
  //       imgSrc1 = require(`assets/tokenList/default.svg`)
  //     }
  //     return (
  //       <NewLogoWrap>
  //         <StyledLogoNew src={imgSrc0} alt="" />
  //         <StyledLogoNew src={imgSrc1} alt="" className="spe" />
  //       </NewLogoWrap>
  //     )
  //   }, [symbol])

  //   return memo
  // }

  const switchSlipe = () => {
    setShowSlipe(!showSlipe)
  }



  return (
    <>
      <UnlockWalletModal
        isOpen={unlockModalIsOpen}
        onDismiss={handleDismissUnlockModal}
        metamaskActive={metamaskActive}
        walletConnectActive={walletConnectActive}
      />
      <Notification
        chainID={chainId}
        notifies={nowNotifies}
        setNowNotifies={setNowNotifies}
        del_Notify={del_Notify}
      />
      <Notification__cancel
        notifies={show__cancel}
        setShow__cancel={setShow__cancel}
        del_Notify={del__cancel}
      />
      {/* <StakeTableTitle>{fmt({ id: "Stake_Title" })}</StakeTableTitle>
      <TitleSub>{fmt({ id: "Price_Title__sub" })}</TitleSub> */}
      <TopSection>
        <AssetWrap>
          <StyledTitle>
            {fmt({ id: "Stake_Title" })}
          </StyledTitle>
          <PriceTitleSub>{fmt({ id: "Title__sub" })}&nbsp;<a href='https://unitus.gitbook.io/unitus/overview/liquidity-mining-faq' target="_blank" rel="noreferrer">{fmt({ id: 'LearnMore' })}</a></PriceTitleSub>
        </AssetWrap>
        <TotalAssets
          totalAssets={[
            { title: "TotalStake", value: isNaN(Number(TotalStakeValue)) ? undefined : TotalStakeValue.toString(), dollar: true },
            { title: "MyBLPStake", value: isNaN(Number(MyTotalStakeValue)) ? undefined : MyTotalStakeValue.toString(), dollar: true, thresholdStatus, needed }
          ]}
        />
      </TopSection>
      {
        localConfig_alltoken && localConfig_alltoken.length > 0 &&
        <>
          <TableWrap>
            {
              (!loading && fetchStake.length > 0) ?
                <Table className="leftTable">
                  <thead>
                    <tr>
                      <th className="firstTh">{fmt({ id: "MiningAssets" })}</th>
                      {
                        (chainId === 42161) &&
                        <th>
                          <SortArea onClick={(): void => sortData(APR, setAPR, "APR")}>
                            {fmt({ id: "APR" })}
                            <i className={"active" + APR}></i>
                          </SortArea>
                        </th>
                      }
                      <th className={chainId === 42161 ? "TotalStakedClass" : ''}>
                        <SortArea onClick={(): void => sortData(TotalStaked, setTotalStaked, "TotalStaked")}>
                          {fmt({ id: "Total_Staked" })}
                          <i className={"active" + TotalStaked}></i>
                        </SortArea>
                      </th>
                      {/* <th>
                        <SortArea onClick={(): void => sortData(LPShare, setLPShare, "LPShare")}>
                          {fmt({ id: "LPShare" })}
                          <i className={"active" + LPShare}></i>
                        </SortArea>
                      </th> */}
                      <th>
                        <SortArea onClick={(): void => sortData(_MyStaked, _setMyStaked, "MyStaked")}>
                          {fmt({ id: "MyStaked" })}
                          <i className={"active" + _MyStaked}></i>
                        </SortArea>
                      </th>
                      <th className="switchBtn"> </th>
                    </tr>
                  </thead >
                  <tbody>
                    {fetchStake.map((item, index) => {
                      return (
                        // <tr key={item?.address} onClick={(e) => (item?.ChainId === chainId) ? showDetailHandler(e, index) : void (0)}>
                        <tr key={item?.address} onClick={(e) => showDetailHandler(e, index)}>
                          <td className="firstTd">
                            <TokenIconWrap>
                              <TokenIcon src={item?.icon} />
                            </TokenIconWrap>
                            <TokenSymbol>{item?.poolSymbol}</TokenSymbol>
                          </td>
                          {
                          (chainId === 42161) ?
                          <td className="specialApy">
                            {
                              item?.APR ?
                              <div>
                                <img src="/ARB.svg" />
                                <span className="highlight">
                                  {_formatNumberNotCarry(bnToDec(new BigNumber(item?.APR),18,4),'percent')}
                                </span>
                              </div>
                              :'...'
                            }
                          </td>
                          :''
                          }
                          <td className={chainId === 42161 ? "specialApy TotalStakedClass" : 'specialApy'}>
                            <span className="highlight">
                              {
                                (isNaN(Number(item?.TotalStaked)) || item?.TotalStaked === '...') ?
                                  '...'
                                  : _formatNumberNotCarry(bnToDec(new BigNumber(item?.TotalStaked), 18, 4), 'abbr')
                              }
                            </span>
                            <span>
                              {
                                (isNaN(Number(item?.TotalStakedValue)) || item?.TotalStakedValue === '...' || item?.TotalStakedValue === "NaN") ?
                                  '...'
                                  : '$' + _formatNumberNotCarry(bnToDec(new BigNumber(item?.TotalStakedValue), 18, 4), 'abbr')
                              }
                            </span>
                          </td>
                          {/* <td>
                            <span className="highlight">
                            {
                              (isNaN(Number(item?.LPShare)) || item?.LPShare === '...') ?
                              '...'
                              : _formatNumberNotCarry(bnToDec(new BigNumber(item?.LPShare),18,4),'percent')
                            }
                            </span>
                          </td> */}
                          <td className="specialApy">
                            <span className="highlight">
                              {
                                (isNaN(Number(item?.MyStaked)) || item?.MyStaked === '...') ?
                                  '...'
                                  // : _formatNumberNotCarry(bnToDec(new BigNumber(item?.MyStaked), 18, 4), 'abbr')
                                  : formate_round_up(
                                    numeral(
                                      bnToDec(new BigNumber(item?.MyStaked), 18)
                                    )
                                      .format("0.000a")
                                      .toLocaleUpperCase()
                                  )
                              }
                            </span>
                            <span>
                              {
                                (isNaN(Number(item?.MystakedValue)) || item?.MystakedValue === '...') ?
                                  '...'
                                  // :'$'+_formatNumberNotCarry(bnToDec(new BigNumber(item?.MystakedValue), 18, 4), 'abbr')
                                  : '$' + formate_round_up(
                                    numeral(
                                      bnToDec(new BigNumber(item?.MystakedValue), 18)
                                    )
                                      .format("0.000a")
                                      .toLocaleUpperCase()
                                  )
                              }
                            </span>
                          </td >
                          <td className="switchBtn">
                            {
                              (
                                item?.icon ?
                                  account ?
                                    <DetailLink className={showDetail === index ? "open" : ""}>
                                      <span>{fmt({ id: "Detail" })}</span>
                                    </DetailLink>
                                    : <SwitchNetwork onClick={(e) => handleUnlockWalletClick()}>{fmt({ id: "Connect_Wallet" })}</SwitchNetwork>
                                  : <DetailLink className="disabled"><span>Detail</span></DetailLink>
                              )
                            }
                          </td>
                          <td
                            className={showDetail === index ? "detail detailShow" : "detail"}
                            style={
                              showDetail === index
                                ? { height: "auto", visibility: "visible",marginTop:"8px" }
                                : { height: 0, visibility: "hidden" }
                            }
                          >
                            <Detail>
                              <Alink>
                                <a href={item?.TradeLink} target="_blank">{fmt({ id: 'AcquireUTS' })}</a>
                                <a href={item?.Link} target={item?.poolType === 'iToken' ? "_self" : "_blank"}>{fmt({ id: 'Get' })}{item?.poolSymbol} </a>
                                <a href={chainId ? `${etherscan[chainId]}${item?.address}` : ''} target="_blank">{fmt({ id: 'View_Contract' })}</a>
                              </Alink>
                              <Harvest>
                                <Earned>
                                  <StakeTitle>
                                    {fmt({ id: "Zap" })}
                                  </StakeTitle>
                                  {
                                    chainId && chainId === 1
                                      ? <Zap>
                                        <p className="mouseOut">{fmt({ id: "Zap" })}</p>
                                        <p className="mouseIn">{fmt({ id: "ComingSoon" })}</p>
                                      </Zap>
                                      : <Zap onClick={(e) => showModleZap(e, item)}>
                                        <p className="">{fmt({ id: "Zap" })}</p>
                                        {/* <p className="mouseIn">{fmt({ id: "ComingSoon" })}</p> */}
                                      </Zap>
                                  }
                                </Earned>
                              </Harvest>
                              <PerformAction>
                                <StakeTitle>{fmt({ id: "Stake_Farming" })}</StakeTitle>
                                <ActionBtn>
                                  <>
                                    <StakeBtn
                                      onClick={(e) =>
                                        showStakeModle(e, item?.reveal_symbol, item?.poolSymbol, "STAKE", item?.icon, item?.poolType, item?.isApproved, item?.allowance, item?.address)
                                      }
                                    >
                                      {fmt({ id: "STAKE" })}
                                    </StakeBtn>
                                    <UnStakeBtn
                                      onClick={(e) =>
                                        showStakeModle(e, item?.reveal_symbol, item?.poolSymbol, "UNSTAKE", item?.icon, item?.poolType, item?.isApproved, item?.allowance, item?.address)
                                      }
                                    >
                                      {fmt({ id: "UNSTAKE" })}
                                    </UnStakeBtn>
                                  </>
                                </ActionBtn>
                              </PerformAction>
                            </Detail>
                          </td>
                        </tr >
                      )
                    })
                    }
                  </tbody >
                </Table >
                : (!loading && fetchStake.length === 0) ?
                  // <NoData>{fmt({ id: 'noData' })}</NoData>
                  <NoData></NoData>
                  :
                  <Table className="leftTable">
                    <thead>
                      <tr>
                        <th className="firstTh">{fmt({ id: "MiningAssets" })}</th>
                        <th>
                        {/* <SortArea onClick={(): void => sortData(LPShare, setLPShare, "LPShare")}> */}
                          {fmt({ id: "APR" })}
                          <i className={"active" + APR}></i>
                        {/* </SortArea> */}
                        </th>
                        <th className={chainId === 42161 ? "TotalStakedClass" :''}>
                          {/* <SortArea onClick={(): void => sortData(TotalStaked, setTotalStaked, "TotalStaked")}> */}
                          {fmt({ id: "Total_Staked" })}
                          <i className={"active" + TotalStaked}></i>
                          {/* </SortArea> */}
                        </th>
                        {/* <th>
                          <SortArea onClick={(): void => sortData(LPShare, setLPShare, "LPShare")}>
                          {fmt({ id: "LPShare" })}
                          <i className={"active" + LPShare}></i>
                          </SortArea>
                        </th> */}
                        <th>
                          {/* <SortArea onClick={(): void => sortData(_MyStaked, _setMyStaked, "_MyStaked")}> */}
                          {fmt({ id: "MyStaked" })}
                          <i className={"active" + _MyStaked}></i>
                          {/* </SortArea> */}
                        </th>
                        <th className="switchBtn"> </th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        Array.from({ length: 5 }).map((key, index) => (
                          <tr key={index}>
                            <td className="firstTd">
                              <TokenIconWrap className="TokenIconBg" />
                              <TokenSymbol className="TokenSymbolBg"></TokenSymbol>
                            </td>
                            <td className="specialApy">
                              <span className="highlight">...</span>
                            </td>
                            <td className={chainId === 42161 ? "specialApy TotalStakedClass" : 'specialApy'}>
                              <div>
                                <span className="highlight">...</span>
                              </div>
                            </td>
                            <td className="specialApy">
                              <div>
                                <span className="highlight">...</span>
                              </div>
                            </td>
                            <td className="loadingDetail">
                              <DetailLink className="disabled"><span>Detail</span></DetailLink>
                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </Table>
            }
          </TableWrap >
          <StakeModal
            isOpen={stakeModalIsOpen}
            onDismiss={handleDismissStakeModal}
            address={address}
            symbol={currentSymbol}
            poolSymbol={poolSymbol}
            action={action}
            assetsIcon={assetsIcon}
            balance={balance}
            MyStaked={MyStaked}
            poolType={poolType}
            decimals={decimals}
            // rewardRate={rewardRate}
            add_Notifies={add_Notifies}
            update_Notifies={update_Notifies}
            del_Notify={del_Notify}
            setNowNotifies={setNowNotifies}
            setShow__cancel={setShow__cancel}
            isApproved={isApproved}
            allowance={allowance}
          />


          {
            zapModalIsOpen &&
            <Modal isOpen={zapModalIsOpen}>
              <StyledModalTitle>
                {'Zap into ' + poolSymbol}
                <StyledClosed>
                  <CloseModal src={close} onClick={handleDismissZapModal} />
                </StyledClosed>
                {
                  // stabled && <StyledClosedC>
                  //   {countDown}s
                  // </StyledClosedC>
                }
              </StyledModalTitle>

              <PaddingWrap>
                <OperateSec>
                  <SecTop>
                    <TopText>
                      {
                        sendValue && debouncedAmount
                          ? '$' + format_num_to_K(format_bn__new(sendValue, 18, 2))
                          : '...'
                      }
                    </TopText>
                    <TopText className="speHover" onClick={() => { zapMax() }}>
                      <span>Balance: </span>
                      {
                        sendToken.balance
                          ? <span>
                            {format_num_to_K(format_bn__new(sendToken.balance, sendToken.decimals, 2))}
                          </span>
                          : '...'
                      }
                    </TopText>
                  </SecTop>
                  <SecButtom>
                    <ButtomLeft>
                      <Input type="text"
                        placeholder="0.00"
                        value={sendTokenAmount}
                        onChange={(e) => InputZapChange(e.target.value)}
                      />
                    </ButtomLeft>
                    <ButtomRight
                      onBlur={() => { setShowSelect(false) }}
                      onClick={() => { setShowSelect(!showSelect) }}
                      className="pointer">
                      <TokenLogo logo={sendToken.symbol} />
                      <span>{sendToken.symbol}</span>
                      {<StyledArrowNew className={showSelect ? 're' : ''} />}
                      {showSelect &&
                        <TokenList>
                          {selectTokenList &&
                            selectTokenList.length > 0 &&
                            selectTokenList.map((token: any, index: number) => {
                              let icon: any
                              try {
                                icon = require(`assets/tokenList/${token.symbol}.svg`)
                              } catch (error) {
                                icon = require(`assets/tokenList/default.svg`)
                              }
                              return (
                                <StyledToken
                                  onClick={() => {
                                    setShowSelect(false)
                                    setisMax(false)
                                    setSendValue('')
                                    setSendTokenAmount('')
                                    setSendToken(token)
                                  }}
                                  key={index}>
                                  <StyLeft>
                                    <span><img src={icon} alt="" /></span>
                                    <span>{token.symbol}</span>
                                  </StyLeft>
                                  <StyLeft>
                                    <span>
                                      {format_num_to_K(format_bn__new(token.balance, token.decimals, 2))}
                                    </span>
                                  </StyLeft>
                                </StyledToken>
                              )
                            })}
                        </TokenList>
                      }
                    </ButtomRight>
                  </SecButtom>
                </OperateSec>

                <Transfer>
                  <img src={transfer} alt="" />
                </Transfer>

                <OperateSec>
                  <SecTop>
                    <TopText>
                      {
                        receiveValue
                          ? '$' + format_num_to_K(format_bn__new(receiveValue, 18, 2))
                          : '...'
                      }
                    </TopText>
                    <TopText>
                      <span>Balance: </span>
                      {
                        currentReceive.MyLPBalance
                          ? format_num_to_K(format_bn__new(currentReceive.MyLPBalance.toString(), 18, 2))
                          : '...'
                      }
                    </TopText>
                  </SecTop>
                  <SecButtom>
                    <ButtomLeft>
                      <Input type="text"
                        placeholder="0.00"
                        value={
                          receiveTokenAmount
                            ? format_bn__new(receiveTokenAmount, 18, 2)
                            : ''
                        }
                        disabled={true}
                      />
                    </ButtomLeft>
                    <ButtomRight>
                      <TokenLogoDouble />
                      <span>{poolSymbol}</span>
                    </ButtomRight>
                  </SecButtom>
                </OperateSec>

                {
                  stabled &&
                    sendValue && receiveValue &&
                    new BigNumber(receiveValue)
                      .minus(new BigNumber(sendValue))
                      .multipliedBy(new BigNumber(10 ** 18))
                      .dividedBy(new BigNumber(sendValue))
                      .lt(new BigNumber(curPriceImpactArg).multipliedBy(new BigNumber(10 ** 16)))
                    ? handlePriceImpactTips()
                    : ''
                }

                {
                  sendToken.allowance &&
                    new BigNumber(sendToken.allowance).gt(new BigNumber(0))
                    ? sendTokenAmount &&
                      new BigNumber(sendTokenAmount)
                        .multipliedBy(new BigNumber(10 ** sendToken.decimals))
                        .gt(new BigNumber(sendToken.allowance))
                      ? <Btn
                        className={isApproving ? 'disabled' : ''}
                        onClick={() => { pre_approve() }}>
                        {fmt({ id: 'ENABLE' })}
                      </Btn>
                      : <Btn className={isZaping ? 'disabled' : ''} onClick={async () => await zap()}>
                        {'Zap into ' + poolSymbol}
                      </Btn>
                    : <Btn
                      className={isApproving ? 'disabled' : ''}
                      onClick={() => { pre_approve() }}>
                      {fmt({ id: 'ENABLE' })}
                    </Btn>
                }

                {
                  <BLPStatus>
                    <BLPStatusL>BLP Staked</BLPStatusL>
                    <BLPStatusR>
                      <StakedValue>
                        {/* ${numberFormatter(format_bn__new(
                          receiveValue
                            ? MyTotalStakeValue.plus(new BigNumber(receiveValue)).toString()
                            : MyTotalStakeValue.toString()
                          , 18, 2), 2)} */}
                          {receiveValue ? 
                          _formatNumberNotCarry(bnToDec(MyTotalStakeValue.plus(new BigNumber(receiveValue)), 18,4),'abbr')
                          : _formatNumberNotCarry(bnToDec(MyTotalStakeValue, 18,4),'abbr')
                          }
                      </StakedValue>
                      {
                        thresholdStatus === 0 &&
                        <StakedValueDetail style={{ color: 'red' }}>
                          ($0 required to be eligible)
                        </StakedValueDetail>
                      }
                      {
                        thresholdStatus === 2 &&
                        <StakedValueDetail style={{ color: 'green' }}>
                          (Eligible for emissions)
                        </StakedValueDetail>
                      }
                      {
                        thresholdStatus === 1 &&
                        <>
                          {
                            receiveValue
                              ? new BigNumber(receiveValue).gt(needed)
                                ? <StakedValueDetail style={{ color: 'green' }}>
                                  (Eligible for emissions)
                                </StakedValueDetail>
                                : <StakedValueDetail style={{ color: 'red' }}>
                                  (${numberFormatter(format_bn__new(
                                    needed.minus(new BigNumber(receiveValue)).toString()
                                    , 18, 2), 2)} required to be eligible)
                                </StakedValueDetail>
                              : <StakedValueDetail style={{ color: 'red' }}>
                                (${numberFormatter(format_bn__new(
                                  needed.toString()
                                  , 18, 2), 2)} required to be eligible)
                              </StakedValueDetail>
                          }
                        </>
                      }
                    </BLPStatusR>
                  </BLPStatus>
                }

                <DetailsList>
                  {/* <DetailsItem>
                    <ItemLeft>{sendValue ? sendValue : '...'}</ItemLeft>
                    <ItemRight>{receiveValue ? receiveValue : '...'}</ItemRight>
                  </DetailsItem> */}

                  <DetailsItem>
                    <ItemLeft>Price Impact</ItemLeft>
                    <ItemRight>
                      {
                        stabled && sendValue && receiveValue
                          ? handlePriceImpact()
                          : '...'
                      }
                    </ItemRight>
                  </DetailsItem>
                  {/* <DetailsItem>
                    <ItemLeft>{sendToken.symbol} used</ItemLeft>
                    <ItemRight>
                      {
                        sendTokenAmount
                          ? format_num_to_K(sendTokenAmount)
                          : '...'
                      }
                    </ItemRight>
                  </DetailsItem> */}
                  <DetailsItem>
                    <ItemLeft>Minimum Received</ItemLeft>
                    <ItemRight>
                      {
                        receiveTokenAmount
                          ? format_num_to_K(format_bn__new(
                            (
                              new BigNumber(receiveTokenAmount.toString())
                                .multipliedBy(curSlippageTolerance)
                                .dividedBy(new BigNumber(10000))
                                .toFixed(0)
                            ).toString(),
                            18, 2)) + ' ' + poolSymbol
                          // ?receiveTokenAmount.toString()
                          : '...'
                      }
                    </ItemRight>
                  </DetailsItem>
                  <DetailsItem>
                    <ItemLeft>Slippage Tolerance</ItemLeft>
                    <ItemRight>
                      <span>{(10000 - curSlippageTolerance) / 100}%</span>
                      <SlipeSet
                        onBlur={() => setShowSlipe(false)}
                      >
                        <img src={img_setSlipe} alt="" onClick={() => switchSlipe()} />
                        {
                          showSlipe &&
                          <SlippageProp>
                            <SlippageTitle>
                              <p>{fmt({ id: "Slippage_Tolerance" })}</p>

                              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                              {/* <a data-tip data-for={"Slippage__tip"} style={{ marginLeft: "5px", display: 'flex' }}>
                                <img src={tooltip} alt='' style={{ marginBottom: "8px" }} />
                              </a>
                              <>
                                <ReactTooltip
                                  id={"Slippage__tip"}
                                  place="top"
                                  type="light"
                                  effect="float"
                                  className="tooltip"
                                  textColor={`#9195A4`}
                                  borderColor={"#E8ECEF"}
                                  border={true}
                                >
                                  <span>
                                    {fmt({ id: "Slippage__tips" })}
                                  </span>
                                </ReactTooltip>
                              </> */}
                              <StyledClose onClick={() => setShowSlipe(false)}>
                                <img className="close" src={img_close} alt='' />
                              </StyledClose>
                            </SlippageTitle>
                            <ItemWrap>
                              <SlippageItem
                                className={curSlippageTolerance === 9995 ? 'isSelected' : ''}
                                onClick={() => { setCurSlippageTolerance(9995) }}
                              >
                                0.05%
                              </SlippageItem>
                              <SlippageItem
                                className={curSlippageTolerance === 9990 ? 'isSelected' : ''}
                                onClick={() => { setCurSlippageTolerance(9990) }}
                              >
                                0.1%
                              </SlippageItem>
                              <SlippageItem
                                className={curSlippageTolerance === 9950 ? 'isSelected' : ''}
                                onClick={() => { setCurSlippageTolerance(9950) }}
                              >
                                0.5%
                              </SlippageItem>
                              <SlippageItem
                                className={curSlippageTolerance === 9900 ? 'isSelected' : ''}
                                onClick={() => { setCurSlippageTolerance(9900) }}
                              >
                                1%
                              </SlippageItem>
                              <SlippageItem
                                className={curSlippageTolerance === 9700 ? 'isSelected' : ''}
                                onClick={() => { setCurSlippageTolerance(9700) }}
                              >
                                3%
                              </SlippageItem>
                              <SlippageItem
                                className={curSlippageTolerance === 9500 ? 'isSelected' : ''}
                                onClick={() => { setCurSlippageTolerance(9500) }}
                              >
                                5%
                              </SlippageItem>
                              <SlippageItem
                                className={curSlippageTolerance === 9000 ? 'isSelected' : ''}
                                onClick={() => { setCurSlippageTolerance(9000) }}
                              >
                                10%
                              </SlippageItem>
                              <SlippageItem
                                className={curSlippageTolerance === 8500 ? 'isSelected' : ''}
                                onClick={() => { setCurSlippageTolerance(8500) }}
                              >
                                15%
                              </SlippageItem>
                            </ItemWrap>
                          </SlippageProp>
                        }
                      </SlipeSet>
                    </ItemRight>
                  </DetailsItem>
                </DetailsList>
              </PaddingWrap>
            </Modal>
          }
        </>
      }
    </>
  )
}




const BLPStatus = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
`
const BLPStatusL = styled.div`
  color: #020202;
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
`
const BLPStatusR = styled.div`
  display: flex;
  align-items: center;
`
const StakedValue = styled.div`
  color: #020202;
  font-size: 18px;
  font-weight: 400;
  line-height: 32px;
`
const StakedValueDetail = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 32px;
`

const ItemWrap = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`
const SlippageItem = styled.div`
  width: 60px;
  height: 30px;
  border-radius: 12px;
  font-size: 12px;
  margin-bottom: 6px;
  font-weight: 500;
  color: #9598A4;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: 1px solid rgba(46,52,78,0.4);
  &.isSelected {
    color: #fff;
    border: none;
    /* background: linear-gradient(90deg, #5EAFE7 0%, #6E5DE7 100%); */
    background: #FF764B;
  }
`
const StyledClose = styled.div`
  position: absolute;
  right: 0;
  top: 8px;
  padding: 10px;
  height: 20px;
  width: 20px;

  img.close {
    position: absolute;
    left: 0;
    top: 4px;
    width: 18px;
    height: 18px;
  }
`
const SlippageTitle = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    line-height: 40px;
    font-size: 13px;
    /* font-weight: 600; */
    color: rgb(36, 42, 71);
  }

  img {
    width: 12px;
  }
`
const SlippageProp = styled.div`
  position: absolute;
  width: 320px;
  right: -2px;
  background: #fff;
  bottom: 36px;
  padding: 16px;
  padding-top: 0;
  box-shadow: 0px 0px 6px 0px rgba(46,52,78,0.8);
  border-radius: 8px;
`
const SlipeSet = styled.button`
  cursor: pointer;
  background: transparent;
  position: relative;
  border-radius: 10px;

  img {
    margin-top: -2px;
    width: 30px;
    margin-right: -12px;
  }

  /* &:hover {
    background: rgba(0,0,0,0.1);
    .dark & {
      background: rgba(255,255,255,0.1);
    }
  } */
`

const OrangeSpan = styled.div`
  color: orange;
`
const RedSpan = styled.div`
  color: red;
`

const StyledTips = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  height: 46px;
  background: #f63d3d20;
  border-radius: 23px;
  font-size: 16px;
  font-weight: 500;
  color: #f63d3d;

  img {
    margin-right: 10px;
    width: 15px;
  }
`

const StyledArrowNew = styled.div`
  margin-left: 8px;
  width: 0;
  height: 0;
  border: 4px solid transparent;
  margin-bottom: -5px;
  border-top: 4px solid #000;
  &.re {
    transform: rotate(180deg);
    margin-bottom: 2px;
  }
`

const NewLogoWrap = styled.div`
  display: flex;
  margin-right: 8px;
`;
const StyledLogoNew = styled.img`
  width: 28px;
  height: 28px;
  &.spe {
    margin-left: -8px;
  }
`;
const StyledLogo = styled.img`
  width: 28px;
  height: 28px;
  margin-right: 8px;
`;

const TokenList = styled.div`
  position: absolute;
  right: 0;
  min-width: 240px;
  top: 30px;
  background: #fff;
  padding: 6px 12px;
  line-height: 26px;
  z-index: 9;
  border-radius: 4px;
  border: 1px solid #eee;
`;
const StyledToken = styled.div`
  color: #020202;
  display: flex;
  justify-content: space-between;
  line-height: 32px;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
    transform: scale(1.05);
  }
`;
const StyLeft = styled.div`
  img {
    width: 20px;
    margin-right: 10px;
  }
`;

const DetailsList = styled.div`
  margin-top: 8px;
  margin-bottom: 24px;
  border: 1px solid #dedede;
  padding: 8px 16px;
  border-radius: 8px;
`;
const DetailsItem = styled.div`
  display: flex;
  justify-content: space-between;
  font-style: normal;
`;
const ItemLeft = styled.div`
  color: #999;
  font-size: 14px;
  font-weight: 400;
  line-height: 32px;
`;
const ItemRight = styled.div`
  color: #131313;
  font-size: 14px;
  font-weight: 400;
  line-height: 32px;
`;

const PaddingWrap = styled.div`
  width: 100%;
  padding: 0px 24px;
`;
const OperateSec = styled.div`
  width: 100%;
  display: flex;
  padding: 24px 16px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: 16px;
  border-radius: 8px;
  background: #F5F6F9;
`;
const SecTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  color: #999;
`;
const TopText = styled.div`
  &.speHover:hover {
    cursor: pointer;
    opacity: 0.8;
    transform: scale(1.05);
  }
`;
const SecButtom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;
const ButtomLeft = styled.div`
  flex: 1;
  height: 28px;
`;
const ButtomRight = styled.button`
  display: flex;
  align-items: center;
  position: relative;
  background: none;
  span {
    color: #020202;
    font-size: 16px;
    font-weight: 600;
  }
  &.pointer {
    cursor: pointer;
  }
`;
const Transfer = styled.div`
  display: flex;
  justify-content: center;
  height: 8px;
  img {
    width: 40px;
    height: 40px;
    transform: translateY(-16px);
  }
`;
const Input = styled.input`
  background: none;
  height: 100%;
  width: 100%;
  color: #020202;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  /* border: 1px solid red; */
  ::placeholder {
		color:#999;
	}
  /* input::-webkit-input-placeholder{  
    color: #E0484B;
  }
  input:-moz-placeholder{    
    color: #E0484B;
  }              
  input::-moz-placeholder{    
    color: #E0484B;
  }              
  input:-ms-input-placeholder{  
    color: #E0484B;
  }  */
`;
const TopSection = styled.div`
  height: 284px;
  padding: 30px 0;
  width: 72%;
  min-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  @media (max-width: 1199px){
    width: 100%;
    min-width:inherit;
    padding: 10px 12px;
    height: auto;
    /* width: 100%; */
    margin: 0 auto;
    /* padding: 0 12px;
    gap: 4px; */
  }
`
const Btn = styled.div`
  width: 100%;
  height: 50px;
  border-radius: 8px;
  background: #FF764B;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  color: ${(props) => props.theme.colors.white};
  cursor: pointer;
  margin-top: 16px;
  font-size: 16px;
  &.disabled {
    pointer-events:none;
    background: ${(props) => props.theme.colors.lightTableBorder};
    color: ${(props) => props.theme.colors.disabledBtn};
  }
  @media(max-width: 1199px) {
    height: 40px;
    line-height:40px;
  }
`;
const Enable = styled.div`
  flex-basis: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  cursor: pointer;
  border-radius: 25px;
  color: ${(props) => props.theme.colors.white};
  background: ${(props) => props.theme.colors.supply.dark};
  background: linear-gradient(90deg, #5EAFE7 0%, #6E5DE7 100%);
  /* .dark & {
    background: ${(props) => props.theme.colors.supply.dark};
  } */
  &.disabled{
    cursor: not-allowed;
    pointer-events:none;
    color: #a1a4b1;
    background: ${(props) => props.theme.colors.lightPageBg};
  }
  @media(max-width: 1199px) {
    height: 40px;
    line-height:40px;
  }
`;
const Max = styled.div`
  width: 60px;
  height: 28px;
  line-height: 28px;
  position: absolute;
  right: 14px;
  top: 11px;
  cursor: pointer;
  text-align: center;
  color: ${(props) => props.theme.colors.supply.dark};
  background: ${(props) => props.theme.colors.supply.light};
  @media(max-width: 1199px) {
    top:8px;
  }
`;
const InputWarp = styled.div`
  position: relative;
  margin-bottom: 25px;
  border-radius: 25px;
  border: 1px solid ${(props) => props.theme.colors.lightTableBorder};
  &.disabled {
    cursor: pointer;
    pointer-events:none;
    background: ${(props) => props.theme.colors.lightTableBorder};
    color: ${(props) => props.theme.colors.disabledBtn};
    ${Input}{
      background: ${(props) => props.theme.colors.lightTableBorder};
    }
  }
  @media(max-width: 1199px) {
    margin-bottom: 15px;
  }
`;
const Balance = styled.div`
  text-align: right;
  color: ${(props) => props.theme.DarkfontColor[100]};
`;
const FormWarp = styled.div`
  display: none;
  margin-top: 2px;
  line-height: 50px;
  box-sizing: border-box;
  &.active {
    display: flex;
    flex-direction: column;
  };
  &.locked{
    pointer-events:none;
    ${InputWarp}{
      background: ${(props) => props.theme.colors.lightTableBorder};
      color: ${(props) => props.theme.colors.disabledBtn};
      margin-bottom:0;
      ${Input}{
        background: ${(props) => props.theme.colors.lightTableBorder};
        color: ${(props) => props.theme.colors.disabledBtn};
      }
      ${Max}{
        background: ${(props) => props.theme.colors.lightPageBg};
        color: ${(props) => props.theme.colors.disabledBtn};
      }
    }
    
  }
  @media(max-width: 1199px) {
    line-height:40px;
  }
`;
// const StakeTableTitle = styled.h1`
// display: flex;
// align-items: center;
// /* height: 60px; */
// font-size: 22px;
// font-weight: bold;
// /* line-height: 60px; */
// margin:0 auto;
// width: 72%;
// min-width: 1200px;
// height: 224px;
// font-family:'LendingFont';
// font-weight:500;
// color:${(props) => props.theme.colors.white};
// /* .dark & {
//   color:${(props) => props.theme.colors.white};
// } */
// @media(max-width: 1199px) {
//   width: calc(100% - 24px);
//   min-width:inherit;
//   font-size:18px;
//   /* height:auto; */
//   /* line-height: 24px; */
//   margin:0 auto;
// }
// `
// const TitleSub = styled.div`
//   color: #FFF;
//   font-size: 16px;
//   font-style: normal;
//   font-weight: 600;
//   line-height: 24px;
//   width: 590px;
//   @media (max-width: 1199px) {
//     width: 90%;
//     font-size: 12px;
//     line-height: 16px;
//   }
// `
const AssetWrap = styled.div`
  /* height: 224px; */
  /* background-color: red; */
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px 0 0;
  margin-bottom: 24px;
  @media (max-width: 1199px) {
    height: auto;
    padding: 10px 0;
    margin-bottom: 12px;
  }
`
const StyledTitle = styled.div`
  color: #FFF;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
  @media (max-width: 1199px) {
    font-size: 16px;
    line-height: 40px;
  }
`
const StyledModalTitle = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  padding-top: 24px;
  margin-bottom: 16px;
`
const StyledClosed = styled.div`
  position: absolute;
  right: 20px;
  top: 22px;
`
const StyledClosedC = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  position: absolute;
  right: 64px;
  top: 20px;
  color: #999;
  border: 1px solid #999;
  border-radius: 400px;
  padding: 6px;
  align-items: center;
  justify-content: center;
  font-weight: bold!important;
  font-size: 10px;
`
const CloseModal = styled.img`
  width: 24px;
  height: 24px;
`
const PriceTitleSub = styled.div`
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  width: 590px;
  a{
    color: ${(props) => props.theme.fontColor[100]};
    text-decoration: underline!important;
  }
  @media (max-width: 1199px) {
    width: 90%;
    font-size: 12px;
    line-height: 18px;
  }
`
// const TabWrap = styled.div`
//   display: flex;
//   justify-content: center;
//   width: 100%;
//   background: rgba(255, 255, 255, 0.08);
// `
const TabWrapInner = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  width: 72%;
  min-width: 1200px;
  height: 100%;
  /* border-radius: 20px; */
  @media (max-width: 1199px) {
    min-width: 100%;
    /* flex-direction: column; */
    align-items: flex-start;
    flex-wrap: wrap;
  }
`
const flash = keyframes`
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
`;
const TableWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin:16px auto 0;
  width: 72%;
  min-width: 1200px;
  table {
    flex-grow: 1;
  }
  @media (max-width: 1199px) {
    width: calc(100% - 24px);
    min-width:inherit;
    flex-direction: column;
    .leftTable {
      margin-bottom: 5px;
      thead tr {
        height: auto;
      }
      tr {
        padding: 8px 0;
        td {
          height: 55px;
          line-height: 55px;
          justify-content: center;
        }
      }
      /* LPshare */
      /* th:nth-child(5),
      td:nth-child(5){
        width:30px;
        flex:inherit
      }  */
      /* th:nth-child(5),
      td:nth-child(5){
        width:30px;
        flex:inherit
      }  */
    }
    td:last-child {
      display: none;
    }
  }
`;
const SortArea = styled.div`
  display:flex;
  align-items: center;
  justify-content:inherit;
  cursor: pointer;
`
const ApyGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const Table = styled.table`
  width: max-content;
  border-radius: 16px;
  border-spacing: inherit;
  color: ${(props) => props.theme.colors.black};
  background:${(props) => props.theme.TableBg};
  padding-bottom: 20px;
  @media (max-width: 1199px) {
    width: 100%;
    border-radius: 10px;
  }
  .dark & {
    color: ${(props) => props.theme.colors.white};
    background:${(props) => props.theme.DarkTableBg};
  }
  font-weight: 500;
  thead {
    tr{
      font-size:14px;
      @media (max-width: 1199px) {
        margin:0 10px;
      }
    }
    /* tr:first-child {
      border-bottom: 0;
    } */
    tr:nth-child(1) {
      line-height:54px;
      padding:8px 0;
      border-bottom: 1px solid ${(props) => props.theme.colors.lightTableBorder};
      .dark & {
        border-bottom: 1px solid
          ${(props) => props.theme.colors.darkTableBorder};
      }
      @media (max-width: 1199px) {
        padding:5px 0;
      }
    }
  }
  tr {
    padding: 8px 0;
    margin: 0 24px;
    text-align: right;
    height: auto;
    line-height: 82px;
    box-sizing: border-box;
    border-bottom: 1px solid ${(props) => props.theme.colors.lightTableBorder};
    flex-wrap: wrap;
    align-items:center;
    font-weight:600;
    .dark & {
      border-bottom: 1px solid ${(props) => props.theme.colors.darkTableBorder};
    }
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    @media (max-width: 1199px) {
      padding: 0;
      height: auto;
      margin: 0 10px 0;
      line-height: 55px;
      align-items:inherit;
    }
    th,
    td {
      flex: 1;
      &.loadingDetail{
          flex: none;
          flex-basis: 200px;
        }
        @media (max-width: 1199px) {
          &.TotalStakedClass{
            display: none!important;
          }
        }
        
    }
    th {
      /* font-size: 14px; */
      color:${(props) => props.theme.colors.lightGrey};
      font-weight: normal;
      text-align: right;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      i {
        display: inline-flex;
        flex-direction: column;
        margin-left: 5px;
        &:before {
          content: "";
          display: inline-block;
          height: 0px;
          width: 0px;
          border-right: 4px solid transparent;
          border-left: 4px solid transparent;
          border-bottom: 4px solid ${(props) => props.theme.colors.lightGrey};
          margin-bottom: 2px;
        }
        &:after {
          content: "";
          display: inline-block;
          height: 0px;
          width: 0px;
          border-right: 4px solid transparent;
          border-left: 4px solid transparent;
          border-top: 4px solid ${(props) => props.theme.colors.lightGrey};
        }
        &.active1 {
          &:after {
            border-top: 4px solid ${(props) => props.theme.colors.supply.dark};
          }
        }
        &.active-1 {
          &:before {
            border-bottom: 4px solid
              ${(props) => props.theme.colors.supply.dark};
          }
        }
      }    
      &.switchBtn{
        flex: none;
        flex-basis: 200px;
      }
      @media (max-width: 1199px) {
        display: flex;
        font-size: 13px;
        line-height: 20px;
        align-items: center;
        justify-content: flex-end;
        /* LPshare */
        /* &:nth-child(3){
          display: none;
        } */
        &.specialApy {
          display: flex;
          /* flex-direction: column; */
          align-items: center;
          min-width:110px;
          div {
            line-height: 20px;
            span {
              display: inline-block;
            }
            &.bottomAPY {
              color: #9195a4;
              /* font-size: 12px; */
              &:first-child {
                margin-left: 5px;
              }
            }
          }
        }
        &.switchBtn{
        flex: none;
        flex-basis: 30px;
      }
      }
    }
    td {
      &.specialApy {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        font-size: 14px;
        div {
            
            display: flex;
            justify-content: flex-end;
            align-items: center;
            line-height: 24px;
            img{
              display: inline-block;
              width: 15px;
              margin-right: 5px;
            }
        }
        /* justify-content: center; */
          span {
            display: inline-block;
            line-height: 24px;
          }
      }
      span{
        color:${(props) => props.theme.fontColor[999]};
      }
      .highlight{
          font-size: 16px;
          line-height: 24px;
          color: ${(props) => props.theme.DarkfontColor[100]};
          .dark & {
          color: ${(props) => props.theme.fontColor[100]};
          }
      }
      &.detail{
        flex-basis: 100%;
        height: 0;
        line-height: 54px;
        visibility: hidden;
        font-weight:normal;
        border-radius: 8px;
        /* transition:height ease-in-out .2s; */
        background: ${(props) => props.theme.colors.lightPageBg};
        .dark & {
          background: ${(props) => props.theme.colors.darkPageBg};
        }
      }
      &.switchBtn{
        flex: none;
        flex-basis: 200px;
      }
      .detailShow {
        /* transition:height ease-in-out .2s; */
      }
      @media (max-width: 1199px) {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: 14px;
        /* LPshare */
        /* &:nth-child(3){
          display: none;
        } */


        /* &.specialApy {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          min-width:110px;
          div {
            line-height: 20px;
            span {
              display: inline-block;
            }
            &.bottomAPY {
              color: #656a7f;
              &:first-child {
                margin-left: 5px;
              }
            }
          }
        } */
        &.switchBtn{
        flex: none;
        flex-basis: 30px;
      }
        &.detailShow {
          display:flex;
        }
        &.loadingDetail{
          display:flex;
        }
      }
    }
    .firstTh,
    .firstTd {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      text-align: left;
      /* min-width: 215px; */
      width: 150px;
      flex: none;
      position: relative;
      span{
        display: inline-block;
        width: 108px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      @media (max-width: 1199px) {
        width: 95px;
      }
    }
    &:last-child {
      border-bottom: 0;
      .detailShow {
        margin-bottom:20px;
        @media (max-width: 1199px) {
          margin-bottom:15px;
        }
      }
    }
  }
`;
const TokenSymbol = styled.span`
  display: inline-block;
  /* min-width: 50px; */
  height: 17px;
  line-height: 17px;
  color: ${(props) => props.theme.DarkfontColor[100]};
  .dark & {
    color: ${(props) => props.theme.fontColor[100]};
  }
  &.TokenSymbolBg {
    animation: ${flash} 1.2s linear infinite forwards;
    background: #e1e5ee;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 800px 100px;
    position: relative;
  }
  @media (max-width: 1199px) {
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content:center;
    margin-bottom:5px;
  }
`;
const TokenIconWrap = styled.div`
  width: 32px;
  /* min-width: 32px; */
  height: 32px;
  border-radius: 50%;
  margin-right: 10px;
  @media (max-width: 1199px) {
    width: 26px;
    /* min-width: 26px; */
    height: 26px;
    margin-bottom:5px;
    /* margin-right:0 */
  }
  &.TokenIconBg {
    border-radius: 50%;
    animation: ${flash} 1.2s linear infinite forwards;
    background: #e1e5ee;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 800px 100px;
    position: relative;
  }
`;
const TokenIcon = styled.img`
  display: block;
  width: 32px;
  height: 32px;
  @media (max-width: 1199px) {
    width: 26px;
    height: 26px;
  }
`;
const ChainIcon = styled.img`
  display: inline-block;
  width: 32px;
  height: 32px;
  @media (max-width: 1199px) {
    width: 26px;
    height: 26px;
  }
`;
// const ReawrdTokenIcon = styled.img`
//   display: inline-block;
//   width: 24px;
//   height: 24px;
//   margin-right:7px;
//   @media (max-width: 1199px) {
//     width: 20px;
//     height: 20px;
//   }
// `;
const SwitchNetwork = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  color: ${(props) => props.theme.colors.supply.dark};
  font-weight:normal;
  line-height: 70px;
  position: relative;
  @media (max-width: 1199px) {
    display: none;
  }
`;
const DetailLink = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  color: ${(props) => props.theme.colors.supply.dark};
  font-weight:normal;
  line-height: 70px;
  position: relative;
  &:after {
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    margin: 0 5px 2px 5px;
    transform: rotate(135deg);
    transform-origin: center;
    transition: all ease-in-out 0.2s;
    border-right: 2px solid ${(props) => props.theme.colors.supply.dark};
    border-top: 2px solid ${(props) => props.theme.colors.supply.dark};
    @media (max-width: 1199px) {
      position: absolute;
      right: -6px;
    }
  }
  &.open {
    &:after {
      transform: rotate(-45deg);
      transform-origin: center;
      margin: 7px 5px 0 5px;
      transition: all ease-in-out 0.2s;
    }
  }
  &.disabled {
    color: ${(props) => props.theme.colors.lightGrey};
    &:after {
      content: "";
      display: inline-block;
      width: 8px;
      height: 8px;
      margin: 0 5px 2px 5px;
      transform: rotate(135deg);
      transform-origin: center;
      transition: all ease-in-out 0.2s;
      border-right: 2px solid ${(props) => props.theme.colors.lightGrey};
      border-top: 2px solid ${(props) => props.theme.colors.lightGrey};
    }
  }
  @media (max-width: 1199px) {
    line-height: 54px;
    span{
      display:none
    }
  }
`;
const Detail = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 26px 20px 30px 26px; */
  padding: 24px;
  box-sizing: border-box;
  gap: 24px;
  @media (max-width: 1199px) {
    padding: 10px;
    flex-basis:100%;
    flex-direction:column;
    gap: 12px;
  }
`;
const Alink = styled.div`
  display: flex;
  /* flex-basis:200px; */
  flex: none;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 4px;
  margin-right: 50px;
  max-width: 350px;
  a {
    position:relative;
    word-spacing:5px;
    color: ${(props) => props.theme.colors.supply.dark};
    text-align:left;
    line-height: 45px;
    &:after{
      content:'';
      width:16px;
      height:16px;
      position:absolute;
      top:15px;
      margin-left:5px;
      background-image:url(${Link});
    }
  }
  @media (max-width: 1199px) {
    max-width: none;
    a{
      display:inline-block;
      text-align:left;
      line-height: 35px;
      &:after{
        top:10px;
        margin-left:5px;
      }
    }
    margin:10px 0 0;
    flex-direction:column;
    justify-content: space-evenly;
    flex-basis:auto;
    order:1;
    width:100%;
    line-height:30px;
  }
`;
// const LinkTo = styled.div`
//   width:14px;
//   height:14px;
//   background-image:url(${Link});
// `;
const SwitchTo = styled.div`
  width:16px;
  height:17px;
  margin-right:12px;
  background-image:url(${Switch});
`;
const SwitchChainIcon = styled.img`
  display: inline-block;
  width: 24px;
  height: 24px;
  @media (max-width: 1199px) {
    width: 24px;
    height: 24px;
  }
`;
const Harvest = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1.5;
  width: 100%;
  max-width: 450px;
  /* flex-basis: 400px; */
  padding: 0 20px 20px;
  box-sizing: border-box;
  border-radius: ${(props) => props.theme.borderradius};
  background: ${(props) => props.theme.fontColor[100]};
  .dark & {
    background: ${(props) => props.theme.DarkTableBg};
  }
  text-align: left;
  @media (max-width: 1199px) {
    width:100%;
    margin-bottom:10px;
    flex-basis:auto;
    padding: 0 10px 10px;
  }
`;
const Zap = styled.div`
  width: 100%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  border-radius: ${(props) => props.theme.borderradius};
  color: ${(props) => props.theme.colors.white};
  background: ${(props) => props.theme.supply};
  .mouseOut{
    display: inline-block;
  }
  .mouseIn{
    display: none;
  }
  &:hover{
    .mouseOut{
      display: none;
    
    }
    .mouseIn{
      display: inline-block;
    }
  }
  &.disabled {
    cursor: pointer;
    color: #a1a4b1;
    background: ${(props) => props.theme.colors.lightOpacityGrey};
    .dark & {
      background: ${(props) => props.theme.colors.darkOpacityGrey};
    }
  }
  @media (max-width: 1199px) {
    flex-basis:48%;
  }
`
const Earned = styled.div`
  width: 100%;
  margin: 0;
  border-radius: ${(props) => props.theme.borderradius};
  span {
    display: inline-block;
    line-height: 54px;
  }
  b {
    font-weight: normal;
    color: ${(props) => props.theme.colors.lightGrey};
  }
  div {
    /* font-size:20px; */
    font-family:LendingFont;
    /* height: 40px; */
    /* line-height: 40px; */
  }
  /* @media (max-width: 1199px) {
    div{
      font-size:16px;
    }
  } */
`;
// const EarnedList = styled.div`
//   height: 40px;
//   display:flex;
//   flex-direction: column;
//   justify-content: space-around;
// `
// const HarvestBtn = styled.div`
//   width: 48%;
//   height: 40px;
//   line-height: 40px;
//   text-align: center;
//   border-radius: ${(props) => props.theme.borderradius};
//   margin-top: 54px;
//   font-weight:600;
//   color: #a1a4b1;
//   /* background: ${(props) => props.theme.colors.lightPageBg}; */
//   background: linear-gradient(90deg, #5EAFE7 0%, #6E5DE7 100%);
//   /* .dark & {
//     background: ${(props) => props.theme.colors.darkPageBg};
//   }; */
//   cursor: pointer;
//   pointer-events:all;
//   color: ${(props) => props.theme.colors.white};
//   /* background: ${(props) => props.theme.colors.supply.dark}; */
//   /* .dark & {
//     background: ${(props) => props.theme.colors.supply.dark};
//   } */
//   &.disabled {
//     cursor: pointer;
//     pointer-events:none;
//     color: #a1a4b1;
//     background: ${(props) => props.theme.colors.lightPageBg};
//     .dark & {
//       background: ${(props) => props.theme.colors.darkPageBg};
//     }
//   }
// `;
const PerformAction = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 450px;
  /* flex-basis: 350px; */
  flex: 1.5;
  padding: 0 20px 20px;
  box-sizing: border-box;
  border-radius: ${(props) => props.theme.borderradius};
  background: ${(props) => props.theme.fontColor[100]};
  text-align: left;
  .dark & {
    background: ${(props) => props.theme.DarkTableBg};
  }
  @media (max-width: 1199px) {
    width:100%;
    flex-basis:auto;
    padding: 0 10px 10px;
  }
`;
const StakeTitle = styled.p`
font-size: 16px;
font-weight: 500;
font-style: normal;
  margin: 0;
  color:${(props) => props.theme.DarkfontColor[100]};
  .dark & {
    color:${(props) => props.theme.fontColor[100]};
  }
`;
const ActionBtn = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight:600;
  gap: 10px;
  @media (max-width: 1199px) {
    justify-content: space-between;
  }
`;
const StakeBtn = styled.div`
  /* flex-basis: 150px; */
  flex: 1;
  height: 40px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  border-radius: ${(props) => props.theme.borderradius};
  color: ${(props) => props.theme.colors.white};
  background: ${(props) => props.theme.supply};
  /* background: linear-gradient(90deg, #5EAFE7 0%, #6E5DE7 100%); */
  /* .dark & {
    background: ${(props) => props.theme.colors.supply.dark};
  } */
  &.disabled {
    cursor: pointer;
    pointer-events:none;
    color: #a1a4b1;
    background: ${(props) => props.theme.colors.lightPageBg};
    .dark & {
      background: ${(props) => props.theme.colors.darkPageBg};
    }
  }
  @media (max-width: 1199px) {
    flex-basis:48%;
  }
`;
const UnStakeBtn = styled.div`
  /* flex-basis: 150px; */
  flex: 1;
  height: 40px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  border-radius: ${(props) => props.theme.borderradius};
  background: ${(props) => props.theme.supplyLightBg};
  color: ${(props) => props.theme.supply};
  @media (max-width: 1199px) {
    flex-basis:48%;
  }
`;
// const Enable = styled.div`
//   flex-basis: 100%;
//   height: 40px;
//   line-height: 40px;
//   text-align: center;
//   cursor: pointer;
//   border-radius: ${(props) => props.theme.borderradius};
//   color: ${(props) => props.theme.colors.white};
//   background: ${(props) => props.theme.colors.supply.dark};
//   background: linear-gradient(90deg, #5EAFE7 0%, #6E5DE7 100%);
//   /* .dark & {
//     background: ${(props) => props.theme.colors.supply.dark};
//   } */
//   &.disabled{
//     cursor: not-allowed;
//     pointer-events:none;
//     color: #a1a4b1;
//     background: ${(props) => props.theme.colors.lightPageBg};
//   }
// `;
// const SelectWarp = styled.div`
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   gap: 16px;
//   /* .NetworkName{
//     margin-right: 16px;
//   } */
//   @media(max-width: 1199px) {
//     width: 100%;
//     justify-content: space-between;
//   }
// `
// const NetWorkName = styled.button`
//   display: none;

//   @media(max-width: 1199px) {
//     cursor: pointer;
//     /* border: 1px solid #5FAAE7; */
//     padding:0 10px 0 10px;
//     height:100%;
//     width: 160px;
//     line-height:36px;
//     font-size:14px;
//     font-weight:bold;
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     background: ${(props) => props.theme.colors.lightOpacityGrey};
//     position: relative;
//     margin: 14px 0;

//     b {
//       margin: 0;
//       color: rgb(36,42,71);
//       .dark & {
//         color: #fff;
//       }
//     }
//     .dark & {
//       background: ${(props) => props.theme.colors.darkOpacityGrey};
//     }
//     .l1img {
//       width: 24px;
//       margin-right: 12px;
//     }
//     border-radius:40px;
//   }
// `
const SelectType = styled.button`
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* background: ${(props) => props.theme.colors.lightOpacityGrey}; */
    background: ${(props) => props.theme.colors.darkOpacityGrey};
    position: relative;
    width: 200px;
    padding:0 10px 0 10px;
    color: #9195A4;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    line-height: 36px;
    b {
      margin: 0;
      font-weight: 500;
      color:${(props) => props.theme.colors.white};
      /* .dark & {
        color: #fff;
      } */
    }
    .l1img {
      width: 24px;
      margin-right: 12px;
    }
    /* .dark & {
      background: ${(props) => props.theme.colors.darkOpacityGrey};
    } */
    border-radius:40px;
  @media(max-width: 1199px) {
    cursor: pointer;
    /* border: 1px solid #5FAAE7; */
    padding:0 10px 0 10px;
    width: 160px;
    line-height:36px;
    font-size:14px;
    font-weight:bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: ${(props) => props.theme.colors.lightOpacityGrey};
    position: relative;
    margin: 7px 0;
    b {
      margin: 0;
      font-weight: 700;
      color:${(props) => props.theme.colors.white};
    }
    .dark & {
      background: ${(props) => props.theme.colors.darkOpacityGrey};
    }
    border-radius:40px;
  }
`
const LeftBlock = styled.div`
  display: flex;
  align-items: center;
`
const StyledArrow = styled.div`
  margin-left: 16px;
  width: 0;
  height: 0;
  border: 4px solid transparent;
  margin-bottom: -5px;
  border-top: 4px solid ${(props) => props.theme.colors.white};
  &.re {
    transform: rotate(180deg);
    margin-bottom: 2px;
  }

  /* .dark & {
    border-top: 4px solid #fff;
  } */
`
const NetWorkList = styled.ul`
  z-index: 999;
  position: absolute;
  top: 44px;
  left: 0;
  min-width: 160px;
  background: ${(props) => props.theme.colors.white};
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  &.selectList{
    width: 200px;
    @media(max-width: 1199px) {
      width: auto;
    }
  }
  .dark & {
    background: ${(props) => props.theme.colors.black};
    /* box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.15); */
    box-shadow:0px 16px 32px 0px rgb(255 255 255 / 0.08);
  }
`
const NetWorkItem = styled.li`
  cursor: pointer;
  color: #fff!important;
  display: flex;
  align-items: center;
  /* justify-content: space-around; */
  line-height: 34px;
  padding-left: 15px;
  padding-right: 10px;

  span {
    color: #000;
  }

  span.active,
  &:hover span {
    color: #5EAFE7;
  }
  .dark & {
    span {
      color: #fff;
    }
  }
  img {
    width: 24px;
    margin-right: 12px;
  }
`
const NavLink = styled.a`
  color: ${(props) => props.theme.colors.lightGrey};
  &:hover {
    color: ${(props) => props.theme.colors.lightGrey};
  }
`;
const NoData = styled.div`
  width: 100%;
  height:200px;
  font-size:18px;
  display: flex;
  justify-content: center;
  align-items: center;
  color:#000;
  .dark & {
    color:#fff;
  }
`
export default MarketDistribution;
