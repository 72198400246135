/* eslint-disable react-hooks/exhaustive-deps */

import React, {
  useState,
  useEffect,
  useCallback,
} from "react"
import { ethers } from "ethers";

import { NavLink } from "react-router-dom"
import styled, { keyframes } from "styled-components"
import { useIntl } from "react-intl"
import useLending from "hooks/useLending"
import ReactTooltip from "react-tooltip"
import img__on__notClick from "assets/supply/on__notClick.svg";
import cannotClick from "assets/supply/cannotClick.svg";
import img__off from "assets/supply/img__off.svg";
import img__on from "assets/supply/img__on.svg";
import Notification from "components/Notification";
import Notification__cancel from "components/Notification__cancel";

import { useWeb3React } from '@web3-react/core'
import { MarketsContextValues } from "contexts/Markets"
import BigNumber from "bignumber.js"
import {
  bnToDec,
  formate_round_up,
  useQuery,
  format_bn,
  numberFormatter,
  _formatNumberNotCarry
} from "utils"
import { network_Name, add_Notifies, update_Notifies, del_Notify, del__cancel } from 'lending-sdk/utils/index1'
// import { LendingData } from 'utils/lendingDataSDK/index'
import { LendingData } from 'lendingData'
import controller_json from 'lending-sdk/lib/clean_build/contracts/general/Controller.json'
import numeral from "numeral"

interface TokenListProps {
  supplyMarkets: any,
}
const TokenList: React.FC<TokenListProps> = ({ supplyMarkets }) => {
  const Lending = useLending()
  const intl = useIntl()
  const fmt = intl.formatMessage
  let query = useQuery()
  const {
    connector,
    account,
    chainId,
    provider,
    ENSName,
    isActive,
    isActivating,
  } = useWeb3React();

  // supply list
  const [supplyList, setSupplyList] = useState<MarketsContextValues[]>([])
  // const [lengthNormalAssetsCollateral, setLengthNormalAssetsCollateral] = useState(false)
  // const [lengthISOAssetsCollateral, setLengthISOAssetsCollateral] = useState(false)
  let sort_markets_data: MarketsContextValues[]
  const [supplyList__sort, setSupplyList__sort] = useState<MarketsContextValues[]>([])

  const [apy_type, setApy_type] = useState(0)
  const [supply_type, setSupply_type] = useState(0)

  const [nowNotifies, setNowNotifies] = useState<any>([])
  const [show__cancel, setShow__cancel] = useState<boolean>(false)


  // console.log(supplyMarkets)


  useEffect(() => {
    if (supplyMarkets.length > 0) {
      //每次轮询 取API最新数据进行更新
      const fetchMarkets = supplyMarkets

      if (supplyList__sort.length > 0) {
        const updateSortMarketsData = supplyList__sort.reduce((result: MarketsContextValues[], item) => {
          const currentItem = fetchMarkets.find((item2: any) => item2.address === item.address)
          if (currentItem) { result.push(currentItem) }
          return result
        }, [])
        setSupplyList(updateSortMarketsData)
      } else {
        setSupplyList(supplyMarkets)
      }
    }
  }, [supplyMarkets, supplyList__sort])


  const sortData = useCallback((
    current_status: number,
    current_Dispatch: React.Dispatch<React.SetStateAction<number>>,
    current_type: string
  ) => {
    // 每次先重置 所有排序
    setApy_type(0)
    setSupply_type(0)

    if (current_status === 0) {
      current_Dispatch(1)
      sort_markets_data = supplyList.sort((a, b) => Number(b[current_type]) - Number(a[current_type]))
    } else if (current_status === 1) {
      current_Dispatch(-1)
      sort_markets_data = supplyList.sort((a, b) => Number(a[current_type]) - Number(b[current_type]))
    } else if (current_status === -1) {
      current_Dispatch(0)
      // 切换tab资产类型之后 排序处理
      sort_markets_data = supplyMarkets
    }
    setSupplyList__sort(sort_markets_data)
  }, [supplyList])







  const clickEvent = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    status: number | undefined
  ) => {
    if (status === 0) {
      e.preventDefault()
      e.stopPropagation()
    }
  }


  const makeItAsCollateral = (item: any) => {
    console.log(item)

    const tokenSymbol = item.underlying_symbol

    // console.log(item.AsCollateralDetails[1])
    // if (hasClickCollateral) {
    //   return console.log('has Click btn')
    // }
    // setHasClickCollateral(true);

    Lending.contracts?.general.Controller.methods[
      item.AsCollateralDetails[1] ? "exitMarkets" : "enterMarkets"
    ]([Lending.contracts?.general["i" + tokenSymbol].address,])
      .send({ from: account })
      .on("transactionHash", function (hash: string) {
        console.log("transactionHash: ", hash)
        add_Notifies({
          state: "pedding",
          action: !item.AsCollateralDetails[1] ?
            "Use " + tokenSymbol + " As Collateral" : "Disable As Collateral",
          transactionHash: hash,
          time: new Date().getTime()
        }, setNowNotifies, account as string, chainId as number)
      })
      .on("receipt", function (receipt: any) {
        // setHasClickCollateral(false)
        console.log("receipt: ", receipt)
        update_Notifies(receipt, setNowNotifies, account as string, chainId as number)
        setTimeout(() => {
          del_Notify(receipt, setNowNotifies)
        }, 3000)
      })
      .on("error", function (error: any) {
        console.log("error", error)
        // setHasClickCollateral(false)
        setShow__cancel(true)
        setTimeout(() => {
          setShow__cancel(false)
        }, 3000)
      })
  }


  // useEffect(() => {
  //   if (supplyList && supplyList.length > 0) {
  //     const AsCollateralNormalAssetsArray: any = []
  //     const AsCollateralISOAssetsArray: any = []
  //     supplyList.map((item) => {
  //       // console.log(item.symbol, ' is-iso-', item.isSegregationAssert, 'executed-', item.AsCollateralDetails[2])
  //       // As-Collateral
  //       if (item.AsCollateralDetails[1]) {
  //         // Normal Assets
  //         if (!item.isSegregationAssert) {
  //           AsCollateralNormalAssetsArray.push(item)
  //         }
  //         // ISO Assets
  //         if (item.isSegregationAssert) {
  //           AsCollateralISOAssetsArray.push(item)
  //         }
  //       }
  //     })

  //     // console.log(AsCollateralNormalAssetsArray)
  //     // console.log(AsCollateralISOAssetsArray)
  //     // lengthNormalAssetsCollateral
  //     // lengthISOAssetsCollateral
  //     setLengthNormalAssetsCollateral(AsCollateralNormalAssetsArray.length > 0)
  //     setLengthISOAssetsCollateral(AsCollateralISOAssetsArray.length > 0)
  //   }
  // }, [supplyList])

  const renderCollateralDetails = (item: any) => {
    // s-Assert
    if (item.isSegregationAssert) {
      if (item.AsCollateralDetails[1]) {
        if (!item.AsCollateralDetails[2]) {
          // s-token asC non-exe
          return (
            <>
              <img
                className={"img__AsCollateral img__AsCollateral__pointer"}
                src={img__on}
                alt=""
                data-tip
                data-for={"tips004"}
              />
              < ReactTooltip
                id={"tips004"}
                place="top"
                type="light"
                effect="float"
                className="tooltip"
                textColor={`${(props: any) => props.theme.operationPage.grey}`
                }
                borderColor={"#E8ECEF"}
                border={true}
              >
                <TooltipSpan>
                  {'Closing this collateral asset will render your position undercollateralized.'}
                </TooltipSpan>
              </ReactTooltip >
            </>
          )
        } else {
          return (
            <img
              className={"img__AsCollateral img__AsCollateral__pointer"}
              src={img__on}
              alt=""
              onClick={() => { makeItAsCollateral(item) }}
            />
          )
        }
      } else {
        if (!item.AsCollateralDetails[2]) {
          return (
            <>
              <img className={"img__AsCollateral img__AsCollateral__pointer"} src={cannotClick} alt="" />
            </>
          )
        } else {
          return (
            <img
              className={"img__AsCollateral img__AsCollateral__pointer"}
              src={img__off}
              alt=""
              onClick={() => { makeItAsCollateral(item) }}
            />
          )
        }
      }
    }
    // Normal Assert
    else {
      if (item.AsCollateralDetails[1]) {
        if (!item.AsCollateralDetails[2]) {
          // n-token asC non-exe
          return (
            <>
              <img
                className={"img__AsCollateral img__AsCollateral__pointer"}
                src={img__on}
                alt=""
                data-tip
                data-for={"tips003"}
              />
              < ReactTooltip
                id={"tips003"}
                place="top"
                type="light"
                effect="float"
                className="tooltip"
                textColor={`${(props: any) => props.theme.operationPage.grey}`
                }
                borderColor={"#E8ECEF"}
                border={true}
              >
                <TooltipSpan>
                  {'Closing this collateral asset will render your position undercollateralized.'}
                </TooltipSpan>
              </ReactTooltip >
            </>
          )
        } else {
          return (
            <img
              className={"img__AsCollateral img__AsCollateral__pointer"}
              src={img__on}
              alt=""
              onClick={() => { makeItAsCollateral(item) }}
            />
          )
        }
      } else {
        if (!item.AsCollateralDetails[2]) {
          // n-token non-asC non-exe
          return (
            <>
              <img className={"img__AsCollateral img__AsCollateral__pointer"} src={cannotClick} alt="" />
            </>
          )
        } else {
          return (
            <img
              className={"img__AsCollateral img__AsCollateral__pointer"}
              src={img__off}
              alt=""
              onClick={() => { makeItAsCollateral(item) }}
            />
          )
        }
      }
    }
  }




  return (
    <>
      <Notification
        chainID={chainId}
        notifies={nowNotifies}
        setNowNotifies={setNowNotifies}
        del_Notify={del_Notify}
      />
      <Notification__cancel
        notifies={show__cancel}
        setShow__cancel={setShow__cancel}
        del_Notify={del__cancel}
      />

      <TableWrap>
        {/* --- PC --- */}
        <Table>
          <thead>
            <tr>
              {/* logo & symbol */}
              <th className="firstTh">
                {fmt({ id: "Markets_Assets" })}
              </th>

              <th>
                <SortArea
                // onClick={() => sortData(apy_type, setApy_type, "supplyAPY")}
                >
                  {/* {fmt({ id: "supply_APY" })} */}
                  <div className="specialApy">
                    <div>
                      <span>{fmt({ id: "Supply_APY" })}</span>
                    </div>
                    <div>
                      <span>{fmt({ id: "Reward_to_MAX" })}</span>
                    </div>
                  </div>
                  {/* <i className={"active" + apy_type}></i> */}
                </SortArea>
              </th>

              <th>
                <SortArea
                // onClick={() => sortData(supply_type, setSupply_type, "account_supply_value")}
                >
                  {fmt({ id: "top_Supplied" })}
                  {/* <i className={"active" + supply_type}></i> */}
                </SortArea>
              </th>

              <th>
                <SortArea>
                  {fmt({ id: "Collateral" })}
                  {/* <i className={"active" + supply_type}></i> */}
                </SortArea>
              </th>

              <th className="lastTh"></th>
            </tr>
          </thead>
          <tbody>
            {
              supplyList.length > 0 && supplyList.map((item) => (
                <tr key={item.address}>
                  <td className="firstTd">
                    <TokenIconWrap>
                      <TokenIcon src={item.icon} />
                    </TokenIconWrap>
                    <TokenSymbol>
                      {item.reveal_symbol}
                    </TokenSymbol>
                  </td>
                  <td className="marketSize specialApy">
                    <div className="supply">
                      <span>
                        {
                          item.supplyAPY ?
                            // format_bn(item.supplyAPY, 16, 2) + '%'
                            _formatNumberNotCarry(bnToDec(new BigNumber(item.supplyAPY), 18, 4), 'percent')
                            : '...'
                        }
                      </span>
                    </div>
                    <div className="APR">
                      <img src="/Unitus.svg" />
                      <span>
                        {
                          (item.userAPR && !isNaN(Number(item.userAPR))) ?
                            _formatNumberNotCarry(bnToDec(new BigNumber(item.userAPR), 18, 4), 'percent')
                            : '...'
                        }
                      </span>
                    </div>
                    {
                      (item.userAPRArb && !isNaN(Number(item.userAPRArb))) &&
                      <div className="APR">
                        <img src="/ARB.svg" />
                        <span>
                          {
                            _formatNumberNotCarry(bnToDec(new BigNumber(item.userAPRArb), 18, 4), 'percent')
                          }
                        </span>
                      </div>
                    }
                  </td>
                  <td className="liquidity">
                    <div>
                      {
                        bnToDec(new BigNumber(item.account_supply_amount), Number(item.decimals)) < 0.01 ?
                          "<0.01"
                          : numberFormatter(format_bn(item.account_supply_amount, Number(item.decimals), 2), 2)
                      }
                    </div>
                    <div className="bottomAPY">
                      {
                        bnToDec(new BigNumber(item.account_supply_value), Number(item.decimals)) < 0.01 ?
                          "<$0.01"
                          : '$' + numberFormatter(format_bn(item.account_supply_value, Number(item.decimals), 2), 2)
                      }
                    </div>
                  </td>
                  <td className="liquidity">
                    <div>
                      {

                        // item.AsCollateralDetails[1]
                        //   ? <div>✅</div>
                        //   : <div onClick={() => { console.log(item.AsCollateralDetails) }}>🔴</div>
                      }
                      {renderCollateralDetails(item)}
                      {
                        item.isSegregationAssert &&
                        <div className="bottomAPY">
                          Segregated
                        </div>
                      }
                    </div>
                  </td>
                  <td className="lastTd">
                    <RouterLink
                      className={"supply"}
                      to={`/lending/lend/supply-${item.reveal_symbol}`}
                    >
                      {fmt({ id: "SUPPLY" })}
                    </RouterLink>
                    <RouterLink
                      className={'OnlySupply'}
                      to={`/lending/lend/withdraw-${item.reveal_symbol}`}
                      onClick={(e) => clickEvent(e, item.status)}
                    >
                      {fmt({ id: "WITHDRAW" })}
                    </RouterLink>
                  </td>
                </tr>
              ))
              // : Array.from({ length: 5 }).map((index) => (
              //   <tr key={Math.random()}>
              //     <td className="firstTd">
              //       <TokenIconWrap className="TokenIconBg" />
              //       <TokenSymbol className="TokenSymbolBg"></TokenSymbol>
              //     </td>
              //     <td className="marketSize">
              //       <div>
              //         ...
              //       </div>
              //     </td>
              //     <td className="liquidity">
              //       <div>
              //         ...
              //       </div>
              //       <div className="bottomAPY">
              //         ...
              //       </div>
              //     </td>
              //     <td className="lastTd">
              //       <button>{fmt({ id: "SUPPLY" })}</button>
              //       <button>{fmt({ id: "WITHDRAW" })}</button>
              //     </td>
              //   </tr>
              // ))
            }
          </tbody>
        </Table>


        {/* --- Mobile --- */}
        {
          // false &&
          <TableMobile>
            <thead>
              <tr>
                {/* logo & symbol */}
                <th className="firstTh">
                  {fmt({ id: "Markets_Assets" })}
                </th>

                <th>
                  <SortArea
                  // onClick={() => sortData(apy_type, setApy_type, "supplyAPY")}
                  >
                    {/* {fmt({ id: "supply_APY" })} */}
                    <div className="specialApy">
                      <div>
                        <span>{fmt({ id: "Supply_APY" })}</span>
                      </div>
                      <div>
                        <span>{fmt({ id: "Reward_to_MAX" })}</span>
                      </div>
                    </div>
                    {/* <i className={"active" + apy_type}></i> */}
                  </SortArea>
                </th>

                <th>
                  <SortArea
                  // onClick={() => sortData(supply_type, setSupply_type, "account_supply_value")}
                  >
                    {fmt({ id: "top_Supplied" })}
                    {/* <i className={"active" + supply_type}></i> */}
                  </SortArea>
                </th>

                <th className="lastTh"></th>
              </tr>
            </thead>
            <tbody>
              {
                supplyList.length > 0 && supplyList.map((item) => (
                  <tr key={item.address}>
                    <td className="firstTd">
                      <TokenIconWrap>
                        <TokenIcon src={item.icon} />
                      </TokenIconWrap>
                      <TokenRight>
                        <TokenSymbol>
                          {item.reveal_symbol}
                        </TokenSymbol>
                      </TokenRight>
                    </td>
                    <td className="marketSize specialApy supply">
                      <div>
                        <span>
                          {
                            item.supplyAPY ?
                              // format_bn(item.supplyAPY, 16, 2) + '%'
                              _formatNumberNotCarry(bnToDec(new BigNumber(item.supplyAPY), 18, 4), 'percent')
                              : '...'
                          }
                        </span>
                      </div>
                      <div className="APR">
                        <img src="/Unitus.svg" />
                        <span>
                          {
                            (item.userAPR && !isNaN(Number(item.userAPR))) ?
                              _formatNumberNotCarry(bnToDec(new BigNumber(item.userAPR), 18, 4), 'percent')
                              : '...'
                          }
                        </span>
                      </div>
                      {
                        (item.userAPRArb && !isNaN(Number(item.userAPRArb))) &&
                        <div className="APR">
                          <img src="/ARB.svg" />
                          <span>
                            {
                              _formatNumberNotCarry(bnToDec(new BigNumber(item.userAPRArb), 18, 4), 'percent')
                            }
                          </span>
                        </div>
                      }
                    </td>
                    <td className="liquidity">
                      <div>
                        {numberFormatter(format_bn(item.account_supply_amount, Number(item.decimals), 2), 2)}
                      </div>
                      <div className="bottomAPY">
                        {'$' + numberFormatter(format_bn(item.account_supply_value, Number(item.decimals), 2), 2)}
                      </div>
                    </td>
                    <td className="lastTd">
                      <RouterLink
                        className={"supply"}
                        to={`/lending/lend/supply-${item.reveal_symbol}`}
                      >
                        {fmt({ id: "SUPPLY" })}
                      </RouterLink>
                      <RouterLink
                        className={'OnlySupply'}
                        to={`/lending/lend/withdraw-${item.reveal_symbol}`}
                        onClick={(e) => clickEvent(e, item.status)}
                      >
                        {fmt({ id: "WITHDRAW" })}
                      </RouterLink>
                    </td>
                  </tr>
                ))
                // : Array.from({ length: 5 }).map((index) => (
                //   <tr key={Math.random()}>
                //     <td className="firstTd">
                //       <TokenIconWrap className="TokenIconBg" />
                //       <TokenRight>
                //         <TokenSymbol className="TokenSymbolBg"></TokenSymbol>
                //       </TokenRight>
                //     </td>
                //     <td className="lastTd">
                //       <button>{fmt({ id: "SUPPLY" })}</button>
                //       <button>{fmt({ id: "WITHDRAW" })}</button>
                //     </td>
                //   </tr>
                // ))
              }
            </tbody>
          </TableMobile>
        }
      </TableWrap>
    </>
  )
}



const TooltipSpan = styled.span` 
  color: rgb(145, 149, 164);
  font-weight: 300;
  font-size: 12px;
  text-align: left!important;
`
const flash = keyframes`
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
`;
const TableWrap = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
  .pcTable {
    margin-right: 15px;
  }
  @media (max-width: 769px) {
    flex-direction: column;
    .mTable {
      margin-bottom: 15px;
      thead {
        padding-top: 10px;
        tr {
          th {
            font-size: 14px;
            line-height: inherit;
          }
        }
      }
      tr {
        padding: 0;
        th,
        td {
          line-height: 55px;
        }
      }
    }
  }
  table {
    flex-grow: 1;
  }
`;
const Table = styled.table`
  width: max-content;
  height: max-content;
  border-bottom: 0;
  /* border-radius: 16px; */
  border-spacing: inherit;
  color: ${(props) => props.theme.colors.black};
  background: ${(props) => props.theme.colors.white};
  .dark & {
    color: ${(props) => props.theme.colors.white};
    background: #131313;
  }
  font-size: 16px;
  font-weight: bold;
  overflow: auto !important;
  thead {
    color: ${(props) => props.theme.colors.lightGrey};
    height: 32px;
    border-bottom: 1px solid ${(props) => props.theme.colors.lightTableBorder};
    .dark & { border-bottom: 1px solid ${(props) => props.theme.colors.darkTableBorder}; };
    tr {
      border-bottom: none !important;
      /* border-bottom: 0; */
      .isHoverLink & {
        &:hover {
          background: inherit;
          &:before {
            content: "";
            border-left: 0;
            height: 0;
            position: absolute;
            left: 0;
            top: 0;
          }
        }
      }
      &:first-child {
        padding: 0;
        height: auto;
        line-height: inherit;
      }
      &:nth-child(2) {
        min-height: 54px;
        border-bottom: 1px solid
          ${(props) => props.theme.colors.lightTableBorder};
        .dark & {
          border-bottom: 1px solid
            ${(props) => props.theme.colors.darkTableBorder};
        }
      }
    }
  }
  /* 解决嵌套样式最后子元素 */
  a {
    display: block;
    position: relative;
    .isHoverLink & {
      &:hover {
        cursor: pointer;
        color: ${(props) => props.theme.colors.black};
        background: ${(props) => props.theme.colors.lightLinkBg};
        &:before {
          content: "";
          width: 4px;
          height: 70px;
          position: absolute;
          left: 0;
          top: 0;
          background: ${(props) => props.theme.colors.supply.dark};
          .dark & {
            background: ${(props) => props.theme.colors.navLinklinearBg};
          }
        }
        .dark & {
          color: ${(props) => props.theme.colors.white};
          background: ${(props) => props.theme.colors.darkLinkBg};
        }
      }
    }
  }
  tr:last-child {
    border-bottom: 0;
  }
  a tr {
    border-bottom: 1px solid
      ${(props) => props.theme.colors.lightTableBorder}!important;
    .dark & {
      border-bottom: 1px solid
        ${(props) => props.theme.colors.darkTableBorder}!important;
    }
  }
  a:last-child > tr {
    border-bottom: 0 !important;
  }
  /* 解决嵌套样式最后子元素 */
  tr {
    position: relative;
    padding: 8px 0;
    margin: 0 0px;
    text-align: right;
    height: auto;
    min-height: 74px;
    box-sizing: border-box;
    border-bottom: 1px solid ${(props) => props.theme.colors.lightTableBorder};
    .dark & {
      border-bottom: 1px solid ${(props) => props.theme.colors.darkTableBorder};
    }
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    th,
    td {
      flex: 1;
    }
    th {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-weight: normal;
      font-size: 14px;
      /* color: red; */
      font-weight: 600;
      i {
        display: inline-flex;
        flex-direction: column;
        margin-left: 5px;
        &:before {
          content: "";
          display: inline-block;
          height: 0px;
          width: 0px;
          border-right: 4px solid transparent;
          border-left: 4px solid transparent;
          border-bottom: 4px solid ${(props) => props.theme.colors.lightGrey};
          margin-bottom: 2px;
        }
        &:after {
          content: "";
          display: inline-block;
          height: 0px;
          width: 0px;
          border-right: 4px solid transparent;
          border-left: 4px solid transparent;
          border-top: 4px solid ${(props) => props.theme.colors.lightGrey};
        }
        &.active1 {
          &:after {
            border-top: 4px solid ${(props) => props.theme.colors.supply.dark};
          }
        }
        &.active-1 {
          &:before {
            border-bottom: 4px solid
              ${(props) => props.theme.colors.supply.dark};
          }
        }
      }
    }
    td{
      .supply{
        color:${(props) => props.theme.borrow};
        }
      &.specialApy,&.marketSize,&.liquidity {
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 14px;
        div {
          line-height: 27px;
          span {
            display: inline-block;
            &.green{
              color:${(props) => props.theme.colors.borrow.dark};
            }
            &.red{
              color:${(props) => props.theme.colors.red};
            }
          }
          &.bottomAPY {
            color: #999;
            font-size: 12px;
            &:first-child {
              margin-left: 5px;
            }
          }
          &.APR {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            font-size: 14px;
            img{
              display: inline-block;
              width: 15px;
              margin-right: 5px;
            }
          }
        }
      }
    }
    .lastTh,
    .lastTd {
      flex: 2;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-right: -8px;
      button {
        width: 40%;
        height: 40px;
        line-height: 35px;
        margin-right: 12px;
        background: ${(props) => props.theme.colors.borrow.normal};
        color: ${(props) => props.theme.colors.lightGrey};
        font-family:LendingFont;
        font-weight: 600;
        font-size: 14px;
        border-radius: 8px;
        @media (max-width: 1199px) {
          border-radius: 4px;
        }
        cursor: not-allowed;
      }
    }
    .firstTh,
    .firstTd {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      text-align: left;
      width: 110px;
      flex: none;
    }
  }
  @media (max-width: 769px) {
    display: none;
  }
`;
const SortArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: inherit;
  text-align:right;
  cursor: pointer;
  .specialApy {
        display: flex;
        flex-direction: column;
        justify-content: center;
        div {
          line-height: 27px;
          span {
            display: inline-block;
          }
          &.bottomAPY {
            color: #9195a4;
            font-size: 14px;
            &:first-child {
              margin-left: 5px;
            }
          }
        }
      }
      @media (max-width: 769px) {
        &.specialApy {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          div {
            line-height: 20px;
            span {
              display: inline-block;
            }
            &.bottomAPY {
              color: #9195a4;
              font-size: 12px;
              &:first-child {
                margin-left: 5px;
              }
            }
          }
        }
      }
`;
const TokenSymbol = styled.span`
  display: inline-block;
  min-width: 50px;
  /* height: 17px; */
  line-height: 17px;
  &.TokenSymbolBg {
    height: 17px;
    animation: ${flash} 1.2s linear infinite forwards;
    background: #e1e5ee;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 800px 100px;
    position: relative;
  }
  @media (max-width: 769px) {
    /* height: 17px; */
    line-height: 17px;
  }
`;
const TokenIconWrap = styled.div`
  width: 30px;
  min-width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
  &.TokenIconBg {
    border-radius: 50%;
    animation: ${flash} 1.2s linear infinite forwards;
    background: #e1e5ee;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 800px 100px;
    position: relative;
  }
`;
const TokenIcon = styled.img`
  display: block;
  width: 30px;
  height: 30px;
`;
const RouterLink = styled(NavLink)`
  width: 40%;
  height: 40px;
  line-height: 40px;
  margin-right: 12px;
  text-align: center;
  font-family:LendingFont;
  font-weight: 600;
  font-size: 14px;
  background: #eff1f4;
  color: #9fa4b3 !important;
  border-radius: 8px;
  @media (max-width: 1199px) {
    border-radius: 4px;
  }
  &.tooltip {
    font-size: 14px;
    line-height: 21px;
    max-width: 140px;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.12);
  }
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  will-change: transform;
  opacity: 1;
  &:hover {
    opacity: 0.8;
  }
  &.supply {
    color: ${(props) => props.theme.colors.white}!important;
    background-color: ${(props) => props.theme.colors.supply.dark};
  }
  &.borrow {
    color: ${(props) => props.theme.colors.white}!important;
    background-color: ${(props) => props.theme.colors.borrow.dark};
  }
  &.NoSupply {
    background: #eff1f4;
    color: #9fa4b3 !important;
  }
  &.OnlySupply {
    color: ${(props) => props.theme.colors.supply.dark}!important;
    background-color: ${(props) => props.theme.colors.supply.light};
    cursor: pointer;
    pointer-events: auto;
  }
  &.Supply_Borrow {
    color: ${(props) => props.theme.colors.borrow.dark}!important;
    background-color: ${(props) => props.theme.colors.borrow.light};
    cursor: pointer;
    pointer-events: auto;
  }
  @media (max-width: 769px) {
    margin-right: 0;
    width: 45%;
    height: 30px;
    line-height: 30px;
  }
`;
const TableMobile = styled.table`
  display: none;
  @media (max-width: 769px) {
    display: flex;
    flex-direction: column;
    border-bottom: 0;
    border-radius: ${(props) => props.theme.borderradius};
    border-spacing: inherit;
    color: ${(props) => props.theme.colors.black};
    background: ${(props) => props.theme.colors.white};
    .dark & {
      color: ${(props) => props.theme.colors.white};
      background: ${(props) => props.theme.colors.black};
    }
    font-size: 14px;
    font-weight: bold;
    overflow: auto !important;
    thead {
      color: ${(props) => props.theme.colors.lightGrey};
      border-bottom: 1px solid ${(props) => props.theme.colors.lightTableBorder};
      .dark & { border-bottom: 1px solid ${(props) => props.theme.colors.darkTableBorder}; };
      tr {
        border-bottom: none !important;
        padding: 8px 4px;
        font-size: 14px;
        cursor: pointer;
        .isHoverLink & {
          &:hover {
            background: inherit;
            &:before {
              content: "";
              border-left: 0;
              height: 0;
              position: absolute;
              left: 0;
              top: 0;
            }
          }
        }
        &:first-child {
          /* border-bottom: 0; */
          padding: 0;
          height: auto;
          line-height: inherit;
        }
        &:nth-child(2) {
          min-height: 54px;
          border-bottom: 1px solid
            ${(props) => props.theme.colors.lightTableBorder};
          .dark & {
            border-bottom: 1px solid
              ${(props) => props.theme.colors.darkTableBorder};
          }
        }
      }
    }
    /* 解决嵌套样式最后子元素 */
    a {
      display: block;
      position: relative;
      .isHoverLink & {
        &:hover {
          cursor: pointer;
          color: ${(props) => props.theme.colors.black};
          background: ${(props) => props.theme.colors.lightLinkBg};
          &:before {
            content: "";
            width: 4px;
            height: 55px;
            position: absolute;
            left: 0;
            top: 0;
            background: ${(props) => props.theme.colors.supply.dark};
            .dark & {
              background: ${(props) => props.theme.colors.navLinklinearBg};
            }
          }
          .dark & {
            color: ${(props) => props.theme.colors.white};
            background: ${(props) => props.theme.colors.darkLinkBg};
          }
        }
      }
    }
    tr:last-child {
      border-bottom: 0;
    }
    a tr {
      border-bottom: 1px solid
        ${(props) => props.theme.colors.lightTableBorder}!important;
      .dark & {
        border-bottom: 1px solid
          ${(props) => props.theme.colors.darkTableBorder}!important;
      }
    }
    a:last-child > tr {
      border-bottom: 0 !important;
    }
    /* 解决嵌套样式最后子元素 */
    tr {
      position: relative;
      padding: 14px 0 0;
      margin: 0 10px;
      text-align: right;
      height: auto;
      border-bottom: 1px solid ${(props) => props.theme.colors.lightTableBorder};
      .dark & {
        border-bottom: 1px solid
          ${(props) => props.theme.colors.darkTableBorder};
      }
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      box-sizing: border-box;
      th,
      td {
        flex: 1;
      }
      th {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        text-align: right;
        font-weight: normal;
        &.flex13 {
          flex: 1.5;
        }
        i {
          display: inline-flex;
          flex-direction: column;
          margin-left: 5px;
          &:before {
            content: "";
            display: inline-block;
            height: 0px;
            width: 0px;
            border-right: 4px solid transparent;
            border-left: 4px solid transparent;
            border-bottom: 4px solid ${(props) => props.theme.colors.lightGrey};
            margin-bottom: 2px;
          }
          &:after {
            content: "";
            display: inline-block;
            height: 0px;
            width: 0px;
            border-right: 4px solid transparent;
            border-left: 4px solid transparent;
            border-top: 4px solid ${(props) => props.theme.colors.lightGrey};
          }
          &.active1 {
            &:after {
              border-top: 4px solid ${(props) => props.theme.colors.supply.dark};
            }
          }
          &.active-1 {
            &:before {
              border-bottom: 4px solid
                ${(props) => props.theme.colors.supply.dark};
            }
          }
        }
      }
      td {
        .supply{
        color:${(props) => props.theme.borrow};
        }
        line-height: 30px;
        &.flex13 {
          flex: 1.5;
        }
        &.specialApy,&.marketSize,&.liquidity {
          display: flex;
          flex-direction: column;
          justify-content: center;
          font-size: 14px;
          div {
            line-height: 27px;
            span {
              display: inline-block;
              &.green{
                color:${(props) => props.theme.colors.borrow.dark};
              }
              &.red{
                color:${(props) => props.theme.colors.red};
              }
            }
            &.bottomAPY {
              color: #999;
              font-size: 12px;
              &:first-child {
                margin-left: 5px;
              }
            }
            &.APR {
              display: flex;
              justify-content: flex-end;
              align-items: center;
            font-size: 14px;
            img{
              display: inline-block;
              width: 15px;
              margin-right: 5px;
            }
          }
          }
        }
      }
      .lastTh,
      .lastTd {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: none;
        margin-top: 15px;
        padding-bottom: 15px;
        button {
          width: 45%;
          height: 30px;
          line-height: 20px;
          background: ${(props) => props.theme.colors.borrow.normal};
          color: ${(props) => props.theme.colors.lightGrey};
          font-family:LendingFont;
          font-weight: 600;
          font-size: 14px;
          cursor: not-allowed;
          border-radius: 8px;
          @media (max-width: 1199px) {
            border-radius: 4px;
          }
        }
      }
      .firstTh,
      .firstTd {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        text-align: left;
        width: 80px;
        flex: none;
      }
    }
  }
`;
const TokenRight = styled.div`
  height: 30px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
`;
export default TokenList;
