/* eslint-disable react/jsx-pascal-case */
import React, { useCallback, useEffect, useState } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import ReactApexChart from "react-apexcharts";
import { useIntl } from "react-intl";
import { useWeb3React } from '@web3-react/core'
import BigNumber from "bignumber.js";
import { ethers, BigNumber as BigNumberE } from "ethers";
// components
import UnlockWalletModal from "components/UnlockWalletModal";
import BorderWrap from "components/BorderWrap";
import NoteTips from "components/NoteTips";
import PaddingWrap from "components/PaddingWrap";
import Logo_Token_number from "components/Logo_Token_number";
import Token_Number from "components/Token_Number";
import ChangeableValue from "components/ChangeableValue";
import Notification from "components/Notification";
import Notification__cancel from "components/Notification__cancel";
import ChangeableValueToBe from "components/ChangeableValue/ChangeableValueToBe";
// hooks
import useLending from "hooks/useLending";
import useAllowance from "hooks/useAllowance";
import useDebounce from '../../hooks/useDebounce'
// styledComponents
import StyledApproveFirst from 'styledComponents/StyledApproveFirst'
import StyledYouDid from 'styledComponents/StyledYouDid'
import StyledLeftSection from 'styledComponents/StyledLeftSection'
import StyleRightSection from 'styledComponents/StyleRightSection'
import ActionTab from 'styledComponents/ActionTab'
import { StyledChart, OverSafeMAX, InputWrap } from 'styledComponents/MulStyled'
import FlexBoxWrap from "components/FlexBoxWrap"

import {
  format_bn,
  format_num_to_K,
  formatTimeStamp,
  deconstruction_wallet_source,
  postDataToServer,
  useQuery, DFApproveXArray,
  calcUR
} from "utils";
import { network_Name, add_Notifies, update_Notifies, del_Notify, del__cancel } from 'lending-sdk/utils/index1'
import { fetchAPY } from "./fetchAPY";
// images
import metamask from "assets/wallet-MetaMask.svg";
import Brave from 'assets/walletLog/Brave.svg'
import coinbase from "assets/wallet-Coinbase.svg";
import icon_walletconnect from "assets/wallet-WalletConnect.svg";
import dForceLogo from "assets/home/loading.gif";

import { factor } from 'constants/constants.js';

import general_tokens from 'lending-sdk/lib/clean_build/contracts/general/IERC20.json';
import synthetic_tokens from 'lending-sdk/lib/clean_build/contracts/synthetic/IERC20.json';
import stocks_tokens from 'lending-sdk/lib/clean_build/contracts/stocks/IERC20.json';

import LendingHelperABI from 'abis/LendingHelper.json';
import LendingHelperAddress from 'abis/LendingHelperAddress.json';
import { LendingDataV2 } from 'lendingData/gen'
import controller_json from 'lending-sdk/lib/clean_build/contracts/general/Controller.json'

import Modal from "components/ModalAction"
import close from "assets/stake/closex.svg"
import tanhao from "assets/Bridge/tanhao.svg"



const BorrowRepay: React.FC = () => {
  const {
    connector,
    account,
    chainId,
    provider,
    ENSName,
    isActive,
    isActivating,
  } = useWeb3React();
  const Lending = useLending();

  const cur_Pool: any = {
    general: general_tokens,
    synthetic: synthetic_tokens,
    stocks: stocks_tokens
  }

  const [unlockModalIsOpen, setUnlockModalIsOpen] = useState(false);
  const [metamaskActive, setMetamaskActive] = useState(false);
  const [walletConnectActive, setWalletConnectActive] = useState(false);
  const [walletIcon, setWalletIcon] = useState("");

  const [curPercentage, setCurPercentage] = useState(0);
  const [curPercentage__tab2, setCurPercentage__tab2] = useState(0);

  const [assetInterestData, setAssetInterestData] = useState<any>();
  const [toBeAPY, setToBeAPY] = useState<any>();
  const [toBeAPY_tab2, setToBeAPY_tab2] = useState<any>();
  const [accountEquity, setAccountEquity] = useState<any>();
  const [healthRatio, setHealthRatio] = useState<any>();
  const [healthRatio_tab2, setHealthRatio_tab2] = useState<any>();

  const [toBePower, setToBePower] = useState<any>();
  const [toBePower_tab2, setToBePower_tab2] = useState<any>();

  const [borrowTokenData, setBorrowTokenData] = useState<any>();
  const [accountBorrowData, setAccountBorrowData] = useState<any>();
  const [accountSupplyData, setAccountSupplyData] = useState<any>();

  const [accountBorrowInfo, setAccountBorrowInfo] = useState<any>();
  const [accountTotalValue, setAccountTotalValue] = useState<any>();
  const [marketsInfo, setMarketsInfo] = useState<any>();

  const [borrow_number, setBorrow_number] = useState<any>();
  const [repay_number, setRepay_number] = useState<any>();
  const [is_borrow_max, setIs_borrow_max] = useState<boolean>(false);
  const [is_repay_max, setIs_repay_max] = useState<boolean>(false);

  const [delayStrategyConfig, setDelayStrategyConfig] = useState<any>();
  const [delayTime, setDelayTime] = useState<any>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [isChoosen__borrow__tab, setIsChoosen__borrow__tab] = useState<boolean>(true);
  const { CurrencyPair } = useParams<any>();
  // 获取router URLParams
  const navigate = useNavigate()

  const [nowNotifies, setNowNotifies] = useState<any>([]);
  const [show__cancel, setShow__cancel] = useState<boolean>(false);
  const [chainID, setChainID] = useState<number>(1);

  const intl = useIntl();
  const intl_locale = intl.locale;
  // console.log(intl_locale==='cn')
  const fmt = intl.formatMessage;

  const local_account = window.localStorage.getItem('account') || ''

  const [enable_status, setEnable_status] = useState<string>(fmt({ id: "ENABLE" }));
  const [bool_Btn, setBool_Btn] = useState<boolean>(false);
  const [bool_Btn__enable, setBool_Btn__enable] = useState<boolean>(false);

  const [wallet_source, setWallet_source] = useState<string>("");
  const [chartState, setChartState] = useState<any>({
    series: [
      {
        name: "APY",
        data: [],
      },
    ],
    options: {
      chart: {
        toolbar: {
          show: false,
          tools: {
            download: false,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
          },
        },
        height: 350,
        type: "line",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 2,
      },
      xaxis: {
        type: "category",
        categories: [],
        min: null,
        tickAmount: 4,
        tickPlacement: "on",
        labels: {
          rotate: 0,
          style: {
            colors: "#9195A4",
          },
        },
      },
      fill: {
        type: "image",
        gradient: {
          shadeIntensity: 1,
          inverseColors: false,
          opacityFrom: 0.6,
          opacityTo: 0.55,
          stops: [20, 100, 100, 100],
        },
      },
      yaxis: {
        labels: {
          formatter: function (value: any) {
            return value.toFixed(2) + "%";
          },
          style: {
            colors: "#9195A4",
          },
        },
      },
      colors: ["#05C7A0"],
    },
  });

  const ParamsArr = (CurrencyPair as string).split("-");
  const this__action = ParamsArr[0];
  const this__token = ParamsArr[1] + (ParamsArr[2] ? '-' + ParamsArr[2] : '');
  // console.log(this__action, this__token)

  const isGasToken = Lending?.contracts?.general[this__token]?.gasToken || false;


  useEffect(() => {
    setBorrow_number(undefined)
    setRepay_number(undefined)
    setBool_Btn(false);
    setBool_Btn__enable(false)
  }, [chainId, account])


  useEffect(() => {
    if (chainId) {
      let curTokens = Object.keys(cur_Pool?.general.networks[`${chainId}`])

      curTokens.push('ETH')
      curTokens.push(chainId === 2221 ? 'KAVA' : '')
      curTokens.push(chainId === 56 || chainId === 97 ? 'BNB' : '')
      curTokens.push(chainId === 137 ? 'MATIC' : '')
      curTokens.push(chainId === 71 ? 'CFX' : '')
      curTokens.push(chainId === 1030 ? 'CFX' : '')
      if (!curTokens.includes(this__token)) {
        // console.log(curTokens.includes(this__token))
        navigate("/lending")
        return;
      }
    }
  }, [chainId])



  useEffect(() => {
    if (Lending && chainId && this__token && account) {
      const asyncGetData = async () => {
        const iTokenAddress = Lending.contracts?.general["i" + this__token].address
        // const helperContract = new Lending.web3.eth.Contract(LendingHelperABI, LendingHelperAddress[chainId])
        // const assetInterestData = await helperContract.methods.getAssetInterestData(iTokenAddress).call()
        // // console.log(assetInterestData)
        // setAssetInterestData(assetInterestData)


        // const controllerAddress = Lending.contracts?.general.Controller.address
        // console.log(controllerAddress)
        // const accountEquity = await helperContract.methods.getAccountCurrentEquity(iTokenAddress, account).call()
        // // uint256	euqity	      decimal(1e36)     [0]
        // // uint256	shortfall	    decimal(1e36)     [1]
        // // uint256	sumCollateral	decimal($)(1e18)  [2]
        // // uint256	sumBorrowed	  decimal($)(1e18)  [3]
        // // console.log(accountEquity)
        // setAccountEquity(accountEquity)

        const provider = new ethers.providers.Web3Provider(Lending.web3.currentProvider)
        const controller_address = controller_json.networks[chainId].Controller.address
        const ld = new LendingDataV2(controller_address, provider)

        const assetInterestData = await ld.getAssetInterestData(iTokenAddress)
        setAssetInterestData(assetInterestData)

        const accountEquity = await ld.getAccountCurrentEquity(iTokenAddress, account)
        setAccountEquity(accountEquity)
        // console.log(assetInterestData,accountEquity)

        // const contractController = Lending.contracts?.general.Controller
        // const marketsInfo = await contractController.methods.markets(iTokenAddress).call()
        // // console.log(marketsInfo)
        // // bool	mint paused	true: paused; false: unpause;    [4]
        // // bool	redeem paused	true: paused; false: unpause;  [5]
        // // bool	borrow paused	true: paused; false: unpause;  [6]
        // setMarketsInfo(marketsInfo)
      }
      asyncGetData()

      let t = setInterval(
        asyncGetData,
        // () => { },
        1000 * 10)
      return () => clearInterval(t)
    }
  }, [Lending, chainId, this__token, account])


  const borrow_number__debounced = useDebounce(borrow_number, 500)
  const repay_number__debounced = useDebounce(repay_number, 500)


  // Adequacy Ratio
  useEffect(() => {
    if (borrow_number__debounced && accountEquity && borrowTokenData) {
      const healthRatio = new BigNumber(accountEquity[2].toString())
        .multipliedBy(new BigNumber(10).pow(new BigNumber(18)))
        .dividedBy(
          new BigNumber(accountEquity[3].toString())
            .plus(new BigNumber(borrow_number__debounced).multipliedBy(new BigNumber(borrowTokenData[3].toString())))
        )
        .toFixed(0)
      setHealthRatio(healthRatio)

      // borrowTokenData[3] // price
      // accountEquity[0] borrow power
      const tmp = new BigNumber(accountEquity[0].toString())
        .minus(
          new BigNumber(borrow_number__debounced)
            .multipliedBy(new BigNumber(1e18))
            .multipliedBy(new BigNumber(borrowTokenData[3].toString()))
            .dividedBy(new BigNumber(10).pow(new BigNumber(18)))
        )
        .toFixed(0)
        .toString()
      setToBePower(tmp)
    }
  }, [borrow_number__debounced, accountEquity, borrowTokenData])
  useEffect(() => {
    if (repay_number__debounced && accountEquity && borrowTokenData) {
      const tmp_ratio_tab2 = new BigNumber(accountEquity[2].toString())
        .multipliedBy(new BigNumber(10).pow(new BigNumber(18)))
        .dividedBy(
          new BigNumber(accountEquity[3].toString())
            .minus(new BigNumber(repay_number__debounced).multipliedBy(new BigNumber(borrowTokenData[3].toString())))
        )
      if (tmp_ratio_tab2.lt(new BigNumber(0))) {
        return setHealthRatio_tab2(0)
      }
      setHealthRatio_tab2(tmp_ratio_tab2.toFixed(0))

      if (new BigNumber(tmp_ratio_tab2).lte(new BigNumber(1e18))) {
        return setToBePower_tab2('0')
      }

      if (new BigNumber(accountEquity[0].toString()).eq(new BigNumber('0'))) {
        const tmp = new BigNumber(repay_number__debounced)
          .multipliedBy(new BigNumber(borrowTokenData[3].toString()))
          // .plus(accountEquity[2].toString())
          // .minus(accountEquity[3].toString())
          .minus(accountEquity[1].toString())
          .toFixed(0)
          .toString()
        return setToBePower_tab2(new BigNumber(tmp).lt(new BigNumber(0)) ? '0' : tmp)
      }
      // Token Price (uint256) //  borrowTokenData[3]
      // if (
      //   new BigNumber(tmp_ratio_tab2).gt(new BigNumber(1e18)) &&
      //   new BigNumber(accountEquity[2].toString())
      //     .multipliedBy(new BigNumber(1e18))
      //     .dividedBy(new BigNumber(accountEquity[3].toString()))
      //     .lt(new BigNumber(1e18))
      // ) {
      //   const tmp = new BigNumber(repay_number__debounced)
      //     .multipliedBy(new BigNumber(borrowTokenData[3].toString()))
      //     .plus(accountEquity[2].toString())
      //     .minus(accountEquity[3].toString())
      //     .toFixed(0)
      //     .toString()
      //   return setToBePower_tab2(new BigNumber(tmp).lt(new BigNumber(0)) ? '0' : tmp)
      // }

      const tmp = new BigNumber(accountEquity[0].toString())
        .plus(
          new BigNumber(repay_number__debounced)
            .multipliedBy(new BigNumber(1e18))
            .multipliedBy(new BigNumber(borrowTokenData[3].toString()))
            .dividedBy(new BigNumber(10).pow(new BigNumber(18)))
        )
        .toFixed(0)
        .toString()
      setToBePower_tab2(tmp)
    }
  }, [repay_number__debounced, accountEquity, borrowTokenData])


  // Borrow APY
  useEffect(() => {
    if (borrow_number__debounced && assetInterestData && borrowTokenData) {
      const _cash = new BigNumber(assetInterestData[2].toString())
        .minus(new BigNumber(borrow_number__debounced).multipliedBy(new BigNumber(borrowTokenData[3].toString())))
      const _borrows = new BigNumber(assetInterestData[0].toString())
        .plus(new BigNumber(borrow_number__debounced).multipliedBy(new BigNumber(borrowTokenData[3].toString())))

      const _reserves = new BigNumber(assetInterestData[1].toString())
      let ur = calcUR(_cash, _borrows, _reserves)

      let borrow_rate
      if (ur.lt(new BigNumber(assetInterestData[5].toString()))) {
        borrow_rate = new BigNumber(assetInterestData[6].toString())
          .multipliedBy(ur)
          .dividedBy(new BigNumber(assetInterestData[5].toString()))
          .plus(new BigNumber(assetInterestData[4].toString()))
      } else {
        borrow_rate = new BigNumber(assetInterestData[7].toString())
          .multipliedBy(ur.minus(new BigNumber(assetInterestData[5].toString())))
          .dividedBy(new BigNumber(10).pow(new BigNumber(18)).minus(new BigNumber(assetInterestData[5].toString())))
          .plus(new BigNumber(assetInterestData[4].toString()))
          .plus(new BigNumber(assetInterestData[6].toString()))
      }
      // console.log('borrow_rate: ', borrow_rate.toString())
      const borrow_rate_day = borrow_rate.dividedBy(new BigNumber(365))
      // console.log('borrow_rate_day: ', borrow_rate_day.toString())
      // supply_rate = borrow_rate*ur*(1-reserve_ratio)
      const supply_rate_day = borrow_rate_day
        .multipliedBy(ur)
        .multipliedBy(new BigNumber(10).pow(new BigNumber(18)).minus(new BigNumber(assetInterestData[3].toString())))
        .dividedBy(new BigNumber(10).pow(new BigNumber(36)))

      const BASE = (new BigNumber('10')).pow(new BigNumber('18'))
      const supply_rate_year = rpow(supply_rate_day.plus(BASE), new BigNumber(365))
      const borrow_rate_year = rpow(borrow_rate_day.plus(BASE), new BigNumber(365))
      // console.log('supply_rate_year: ', supply_rate_year.toString())
      // console.log('borrow_rate_year: ', borrow_rate_year.toString())
      setToBeAPY(borrow_rate_year.toString())
      // console.log(borrow_number__debounced , assetInterestData , borrowTokenData.toString())
    }
  }, [borrow_number__debounced, assetInterestData, borrowTokenData])
  useEffect(() => {
    if (repay_number__debounced && assetInterestData && borrowTokenData) {
      const _cash = new BigNumber(assetInterestData[2].toString())
        .plus(new BigNumber(repay_number__debounced).multipliedBy(new BigNumber(borrowTokenData[3].toString())))

      const _borrows = new BigNumber(assetInterestData[0].toString())
        .minus(new BigNumber(repay_number__debounced).multipliedBy(new BigNumber(borrowTokenData[3].toString())))

      const _reserves = new BigNumber(assetInterestData[1].toString())

      let ur = calcUR(_cash, _borrows, _reserves)

      let borrow_rate
      if (ur.lt(new BigNumber(assetInterestData[5].toString()))) {
        borrow_rate = new BigNumber(assetInterestData[6].toString())
          .multipliedBy(ur)
          .dividedBy(new BigNumber(assetInterestData[5].toString()))
          .plus(new BigNumber(assetInterestData[4].toString()))
      } else {
        borrow_rate = new BigNumber(assetInterestData[7].toString())
          .multipliedBy(ur.minus(new BigNumber(assetInterestData[5].toString())))
          .dividedBy(new BigNumber(10).pow(new BigNumber(18)).minus(new BigNumber(assetInterestData[5].toString())))
          .plus(new BigNumber(assetInterestData[4].toString()))
          .plus(new BigNumber(assetInterestData[6].toString()))
      }
      // console.log('borrow_rate: ', borrow_rate.toString())
      const borrow_rate_day = borrow_rate.dividedBy(new BigNumber(365))
      // console.log('borrow_rate_day: ', borrow_rate_day.toString())
      // supply_rate = borrow_rate*ur*(1-reserve_ratio)
      const supply_rate_day = borrow_rate_day
        .multipliedBy(ur)
        .multipliedBy(new BigNumber(10).pow(new BigNumber(18)).minus(new BigNumber(assetInterestData[3].toString())))
        .dividedBy(new BigNumber(10).pow(new BigNumber(36)))

      const BASE = (new BigNumber('10')).pow(new BigNumber('18'))
      const supply_rate_year = rpow(supply_rate_day.plus(BASE), new BigNumber(365))
      const borrow_rate_year = rpow(borrow_rate_day.plus(BASE), new BigNumber(365))
      // console.log('supply_rate_year111: ', supply_rate_year.toString())
      // console.log('borrow_rate_year: ', borrow_rate_year.toString())
      setToBeAPY_tab2(borrow_rate_year.toString())
      // console.log(repay_number__debounced , assetInterestData , borrowTokenData.toString())
    }
  }, [repay_number__debounced, assetInterestData, borrowTokenData])
  const rpow = (a: any, b: any) => {
    const BASE = (new BigNumber('10')).pow(new BigNumber('18'))
    // const daysPerYear = new BN('365');
    return (
      a
        .pow(b)
        .dividedBy(BASE.pow(b.minus(new BigNumber('1'))))
        .minus(BASE)
        .toFixed(0)
    )
  }



  useEffect(() => {
    const getDelayDetails = async () => {
      if (Lending && this__token && chainId && account) {
        const tokenAddress = Lending.contracts?.general['i' + this__token]?.address
        const provider = new ethers.providers.Web3Provider(Lending.web3.currentProvider)
        const controller_address = controller_json.networks[chainId].Controller.address
        const ld = new LendingDataV2(controller_address, provider)
        const delayStrategyConfig = await ld.getDelayStrategyConfig(account, tokenAddress)
        setDelayStrategyConfig(delayStrategyConfig)
        // console.log(delayStrategyConfig) // delayStrategyConfig
      }
    }

    getDelayDetails()
    let t = setInterval(getDelayDetails, 1000 * 30);
    return () => clearInterval(t);
  }, [Lending, this__token, chainId, account])

  useEffect(() => {
    if (borrow_number__debounced && delayStrategyConfig && chainId && accountBorrowData) {
      const getDelayDetails = async () => {
        const provider = new ethers.providers.Web3Provider(Lending.web3.currentProvider)
        const controller_address = controller_json.networks[chainId].Controller.address
        const ld = new LendingDataV2(controller_address, provider)
        const amount = new BigNumber(borrow_number__debounced)
          .multipliedBy(new BigNumber(10).pow(new BigNumber(accountBorrowData[5])))
          .toString()
        const delayDetails = await ld.getDelayDetails(BigNumberE.from(amount), delayStrategyConfig)
        console.log(amount, delayDetails && delayDetails.delaySeconds.toString())
        setDelayTime(delayDetails.delaySeconds.toString())
      }
      getDelayDetails()
    }
  }, [borrow_number__debounced, delayStrategyConfig, chainId, accountBorrowData && accountBorrowData[5]])

  const borrow__pre = () => {
    console.log('delayTime: ', delayTime)
    if (delayTime === undefined) { return console.log(delayTime, 'undefined') }

    if (new BigNumber(delayTime).gte(new BigNumber(0))) {
      if (new BigNumber(delayTime).gt(new BigNumber(0))) {
        setIsModalOpen(true)
      } else if (delayTime !== undefined && new BigNumber(delayTime).eq(new BigNumber(0))) {
        if (isGasToken) {
          click_borrow__spe()
        } else {
          click_borrow()
        }
      }
    }
    // isGasToken ? click_withdraw__spe : click_withdraw
  }

  const handleDismiss_disable = () => {
    setIsModalOpen(false)
  }



  useEffect(() => {
    if (enable_status === "ENABLE" || "授权") {
      setEnable_status(fmt({ id: "ENABLE" }));
    } else {
      setEnable_status(fmt({ id: "ENABLING" }));
    }
  }, [intl_locale]);

  const fetchApi = useCallback(async () => {
    // console.log(Lending.contracts?.general[this__token].address)
    const echartsAPY =
      Lending &&
      (await fetchAPY(
        chainId,
        Lending.contracts?.general["i" + this__token].address,
        'borrow'
      ));
    const seriesData =
      echartsAPY.length > 0
        ? echartsAPY.map((item: any) => format_bn(item.apy, 16, 2))
        : [];
    const categories =
      echartsAPY.length > 0
        ? echartsAPY.map((item: any) => {
          return formatTimeStamp(
            new Date(parseInt(item.timeStamp) * 1000),
            "MM/dd"
          );
        })
        : [];
    setChartState({
      series: [
        {
          name: "APY",
          // data: [31, 40, 28, 51, 42, 109, 100]
          data: seriesData,
        },
      ],
      options: {
        chart: {
          toolbar: {
            show: false,
            tools: {
              download: false,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
            },
          },
          height: 350,
          type: "line",
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: 2,
        },
        xaxis: {
          type: "category",
          categories,
          min:
            echartsAPY &&
            (echartsAPY.length > 1 ? echartsAPY[0]?.timeStamp : null),
          tickAmount: 4,
          tickPlacement: "on",
          labels: {
            rotate: 0,
            style: {
              colors: "#9195A4",
            },
          },
        },
        fill: {
          type: "image",
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.6,
            opacityTo: 0.55,
            stops: [20, 100, 100, 100],
          },
        },
        yaxis: {
          labels: {
            formatter: function (value: any) {
              return value.toFixed(2) + "%";
            },
            style: {
              colors: "#9195A4",
            },
          },
        },
        colors: ["#05C7A0"],
      },
    });
  }, [Lending, this__token]);

  useEffect(() => {
    if (Lending) {
      fetchApi();
      setBorrow_number('')
      setRepay_number('')
    }
  }, [Lending, this__token]);

  const allowance = useAllowance(
    Lending?.contracts?.general[this__token]?.address,
    Lending?.contracts?.general[
      !Lending?.contracts?.general[this__token]?.gasToken
        ? "i" + this__token
        : this__token
    ]?.address
  );
  // console.log(allowance?.toString())

  const handleDismissUnlockModal = useCallback(() => {
    setUnlockModalIsOpen(false);
    setMetamaskActive(false);
    setWalletConnectActive(false);
  }, [setUnlockModalIsOpen]);

  const handleUnlockWalletClick = useCallback(() => {
    setUnlockModalIsOpen(true);
  }, [setUnlockModalIsOpen]);

  const getStatesData = useCallback(async () => {
    console.log("***** get States Data *****")
    if (Lending && account && chainId) {
      const provider = new ethers.providers.Web3Provider(Lending.web3.currentProvider)
      const controller_address = controller_json.networks[chainId].Controller.address
      const ld = new LendingDataV2(controller_address, provider)
      const iTokenAddress = Lending.contracts?.general["i" + this__token]?.address
      // const tmpIsGasToken = !Lending?.contracts?.general[this__token]?.gasToken
      // console.log(iTokenAddress)

      // Liquidity (uint256)(scaled by 10**Decimals)  //  borrowTokenData[0]
      // Borrow Factor (uint256)  //  borrowTokenData[1]
      // Borrow APY (uint256) //  borrowTokenData[2]
      // Token Price (uint256) //  borrowTokenData[3]
      const fn_getBorrowTokenData = new Promise((resolve, reject) => {
        const fn = async () => {
          const res = await ld.getBorrowTokenData(iTokenAddress, account as string, factor)
          return res
        }
        const res = fn()
        resolve(res)
      })


      // Token Borrowed (uint256)  // accountBorrowData[0]
      // Available to Borrow (uint256) ====> update to Cap(市场可借数量)  // accountBorrowData[1]
      // SAFE MAX Available to Borrow (uint256)  // accountBorrowData[2]
      // Token Balance (uint256)  //  accountBorrowData[3]
      // Max Repay (uint256)  //  accountBorrowData[4]
      // Decimals (uint8)  // accountBorrowData[5]
      // err type (num)
      const fn_getAccountBorrowData = new Promise((resolve, reject) => {
        const fn = async () => {
          const res = await ld.getAccountBorrowData(iTokenAddress, account, factor)
          return res
        }
        const res = fn()
        resolve(res)
      })


      // Max Borrow Value ($)(uint256)  // accountBorrowInfo[0]
      // SAFE Borrow Value ($)(uint256)  // accountBorrowInfo[1]
      // Token Value  ($)(uint256)  //  accountBorrowInfo[2]
      // markets_closed  //  accountBorrowInfo[3]
      // exe borrow (bool)
      const fn_getAccountBorrowInfo = new Promise((resolve, reject) => {
        const fn = async () => {
          const res = await ld.getAccountBorrowInfo(iTokenAddress, account, factor)
          return res
        }
        const res = fn()
        resolve(res)
      })


      //   Supply Balance ($) (uint256)  //  accountTotalValue[0]
      //   Collateral Balance ($) (uint256)  //  accountTotalValue[1]
      //   Borrow Balance ($) (uint256)  //  accountTotalValue[2]
      //   Collateralization ratio (%) (uint256)  //  accountTotalValue[3]
      const fn_getAccountTotalValue = new Promise((resolve, reject) => {
        const fn = async () => {
          const res = await ld.getAccountTotalValue(account)
          return res
        }
        const res = fn()
        resolve(res)
      })


      const [
        BorrowTokenData,
        AccountBorrowData,
        AccountBorrowInfo,
        AccountTotalValue
      ] = await Promise.all([
        fn_getBorrowTokenData,
        fn_getAccountBorrowData,
        fn_getAccountBorrowInfo,
        fn_getAccountTotalValue
      ])
      setBorrowTokenData(BorrowTokenData)
      setAccountBorrowData(AccountBorrowData)
      setAccountBorrowInfo(AccountBorrowInfo)
      setAccountTotalValue(AccountTotalValue)
      // console.log(BorrowTokenData, AccountBorrowData, AccountBorrowInfo, AccountTotalValue)
      // console.log(AccountBorrowData)
      // console.log('ratio: ', AccountTotalValue && AccountTotalValue[3].toString())
    }
  }, [Lending, this__token, is_repay_max, chainId]);

  useEffect(() => {
    getStatesData();

    let cur_source = deconstruction_wallet_source();
    console.log(cur_source);
    setWallet_source(cur_source);

    // Lending?.web3.eth.getChainId().then((chainID: any) => {
    //   console.log(chainID);
    //   setChainID(chainID);
    // });

    let refreshInterval = setInterval(getStatesData, 1000 * 10);
    return () => clearInterval(refreshInterval);
  }, [Lending, this__token, is_repay_max, chainId]);


  const borrow_max = () => {
    setIs_borrow_max(true);
    setCurPercentage(100)

    setBorrow_number(format_bn(accountBorrowData[2].toString(), accountBorrowData[5], 6));
    console.log("borrow max");
  };
  const borrow_input_change = (val: string) => {
    val = val.trim();
    var reg_ZH_CN = new RegExp("[\\u4E00-\\u9FFF]+", "g");
    var reg_ABC = /[a-z]/g;
    // 限制只可输入小数点后6位
    let quantityScale = "6";
    let reg_maxLength = new RegExp("^(0|[1-9][0-9]*)(\\.[0-9]{0," + quantityScale + "})?$");
    // 限制只可输入小数点后6位
    if (reg_ZH_CN.test(val)) {
      return console.log("不可以输入中文");
    }
    if (reg_ABC.test(val)) {
      return console.log("English ABC prohibited");
    }
    if (!(accountBorrowData && borrowTokenData)) {
      return console.log("not ready: accountBorrowData && borrowTokenData");
    }
    if (accountBorrowData[2].toString() === "0") {
      return console.log("safe to Borrow is 0");
    }
    if (!reg_maxLength.test(val) && val !== "") {
      return console.log("The maximum number of digits after the decimal point is exceeded");
    }
    setIs_borrow_max(false);
    console.log("borrow_changed");
    setCurPercentage(0)

    let bool_gt = new BigNumber(val)
      .multipliedBy(new BigNumber(10).pow(new BigNumber(accountBorrowData[5])))
      .gt(new BigNumber(accountBorrowData[2].toString()));
    if (bool_gt) {
      return borrow_max();
    }

    setBorrow_number(val);
  };

  const repay_max = () => {
    if (!(accountBorrowData && borrowTokenData)) {
      return console.log("not ready: accountBorrowData && borrowTokenData");
    }
    // if (accountBorrowData[3] === "0") {
    //   return console.log("account balance is 0");
    // }

    setIs_repay_max(true);
    setCurPercentage__tab2(100)

    setRepay_number(format_bn(accountBorrowData[4].toString(), accountBorrowData[5], 6));
    console.log("repay max");
  };
  const repay_input_change = (val: string) => {
    val = val.trim();
    var reg_ZH_CN = new RegExp("[\\u4E00-\\u9FFF]+", "g");
    var reg_ABC = /[a-z]/g;
    // 限制只可输入小数点后6位
    let quantityScale = "6";
    let reg_maxLength = new RegExp(
      "^(0|[1-9][0-9]*)(\\.[0-9]{0," + quantityScale + "})?$"
    );
    // 限制只可输入小数点后6位
    if (reg_ZH_CN.test(val)) {
      return console.log("不可以输入中文");
    }
    if (reg_ABC.test(val)) {
      return console.log("English ABC prohibited");
    }
    if (!(accountBorrowData && borrowTokenData)) {
      return console.log("not ready: accountBorrowData && borrowTokenData");
    }
    if (accountBorrowData[3] === "0") {
      return console.log("account balance is 0");
    }
    if (!reg_maxLength.test(val) && val !== "") {
      return console.log("The maximum number of digits after the decimal point is exceeded");
    }
    setIs_repay_max(false);
    console.log("repay_changed");
    setCurPercentage__tab2(0)

    let bool_gt = new BigNumber(val)
      .multipliedBy(new BigNumber(10).pow(new BigNumber(accountBorrowData[5].toString())))
      .gt(new BigNumber(accountBorrowData[4].toString()));
    if (bool_gt) {
      return repay_max();
    }

    setRepay_number(val);
  };


  const changePercentage = (val: number) => {
    setCurPercentage(val)

    if (val === 100) {
      return borrow_max();
    }

    setIs_borrow_max(false);

    let temp_val = new BigNumber(accountBorrowData[2].toString())
      .multipliedBy(new BigNumber(val))
      .dividedBy(new BigNumber(100))
      .toFixed(0)
      .toString();

    let to_input = format_bn(temp_val, accountBorrowData[5], 6);
    setBorrow_number(to_input);
  }


  const changePercentage__tab2 = (val: number) => {
    setCurPercentage__tab2(val)

    if (val === 100) {
      return repay_max();
    }

    setIs_repay_max(false);

    let temp_val = new BigNumber(accountBorrowData[4].toString())
      .multipliedBy(new BigNumber(val))
      .dividedBy(new BigNumber(100))
      .toFixed(0)
      .toString();

    let to_input = format_bn(temp_val, accountBorrowData[5], 6);
    setRepay_number(to_input);
  }

  useEffect(() => {
    if (this__action.toLowerCase() === "borrow") {
      setIsChoosen__borrow__tab(true);
    } else if (this__action.toLowerCase() === "repay") {
      setIsChoosen__borrow__tab(false);
    }
    console.log(ParamsArr, isChoosen__borrow__tab);
  }, []);

  const click_borrow__spe = () => {
    if (!borrow_number) {
      return console.log("borrow_number undefined");
    }
    if (new BigNumber(borrow_number).eq(new BigNumber(0))) {
      return console.log("borrow_number is 0...");
    }
    if (bool_Btn) {
      return console.log("already borrowing...");
    }
    setBool_Btn(true);
    let to_bn__value = new BigNumber(borrow_number)
      .multipliedBy(new BigNumber(10).pow(new BigNumber(accountBorrowData[5])))
      .toString();

    Lending.contracts?.general[this__token].methods
      .borrow(is_borrow_max ? accountBorrowData[2].toString() : to_bn__value)
      .send({ from: account })
      .on("transactionHash", function (hash: string) {
        console.log("transactionHash: ", hash);
        postDataToServer(
          wallet_source,
          "Borrow",
          account || "",
          chainId ? network_Name[chainId] : "",
          Lending.contracts?.general[this__token].address,
          accountBorrowData[5],
          borrowTokenData[3],
          is_borrow_max ? accountBorrowData[2].toString() : to_bn__value
        );
        borrow_input_change("");
        add_Notifies({
          state: "pedding",
          action: "Borrow " + format_num_to_K(format_bn(to_bn__value.toString(), accountBorrowData[5], 4)) + " " + this__token,
          transactionHash: hash,
          time: new Date().getTime()
        }, setNowNotifies, account as string, chainId as number);
        setBool_Btn(false);
        setIsModalOpen(false);
      })
      .on("receipt", function (receipt: any) {
        console.log("receipt: ", receipt);
        getStatesData();
        update_Notifies(receipt, setNowNotifies, account as string, chainId as number);
        setTimeout(() => {
          del_Notify(receipt, setNowNotifies);
        }, 3000);
        setBool_Btn(false);
      })
      .on("error", function (error: any) {
        console.log("error", error);
        setBool_Btn(false);
        setShow__cancel(true);
        setTimeout(() => {
          setShow__cancel(false);
        }, 3000);
      });
  };
  const click_borrow = () => {
    if (!borrow_number) {
      return console.log("borrow_number undefined");
    }
    if (new BigNumber(borrow_number).eq(new BigNumber(0))) {
      return console.log("borrow_number is 0...");
    }
    if (bool_Btn) {
      return console.log("already borrowing...");
    }
    setBool_Btn(true);
    let to_bn__value = new BigNumber(borrow_number)
      .multipliedBy(new BigNumber(10).pow(new BigNumber(accountBorrowData[5])))
      .toString();
    Lending.contracts?.general["i" + [this__token]].methods
      .borrow(is_borrow_max ? accountBorrowData[2].toString() : to_bn__value)
      .send({ from: account })
      .on("transactionHash", function (hash: string) {
        console.log("transactionHash: ", hash);
        postDataToServer(
          wallet_source,
          "Borrow",
          account || "",
          (chainId && network_Name[chainId]) || "",
          Lending.contracts?.general[this__token].address,
          accountBorrowData[5],
          borrowTokenData[3],
          is_borrow_max ? accountBorrowData[2].toString() : to_bn__value
        );
        borrow_input_change("");
        add_Notifies({
          state: "pedding",
          action: "Borrow " + format_num_to_K(format_bn(to_bn__value.toString(), accountBorrowData[5], 4)) + " " + this__token,
          transactionHash: hash,
          time: new Date().getTime()
        }, setNowNotifies, account as string, chainId as number);
        setBool_Btn(false);
        setIsModalOpen(false);
      })
      .on("receipt", function (receipt: any) {
        console.log("receipt: ", receipt);
        getStatesData();
        update_Notifies(receipt, setNowNotifies, account as string, chainId as number);
        setTimeout(() => {
          del_Notify(receipt, setNowNotifies);
        }, 3000);
        setBool_Btn(false);
      })
      .on("error", function (error: any) {
        console.log("error", error);
        setBool_Btn(false);
        setShow__cancel(true);
        setTimeout(() => {
          setShow__cancel(false);
        }, 3000);
      });
  };
  const click_repay = () => {
    if (!repay_number) {
      return console.log("repay_number undefined");
    }
    if (new BigNumber(repay_number).eq(new BigNumber(0)) && !is_repay_max) {
      return console.log("repay_number is 0...");
    }
    if (new BigNumber(accountBorrowData[4]).eq(new BigNumber(0))) {
      return console.log("Max Repay is 0...");
    }
    if (bool_Btn) {
      return console.log("already repaying...");
    }
    setBool_Btn(true);
    let to_bn__value = new BigNumber(repay_number)
      .multipliedBy(new BigNumber(10).pow(new BigNumber(accountBorrowData[5])))
      .toString();
    let max_num = new BigNumber(2)
      .pow(new BigNumber(256))
      .minus(new BigNumber(1))
      .toString();

    let final_value = is_repay_max
      ? new BigNumber(accountBorrowData[3].toString()).gt(new BigNumber(accountBorrowData[4].toString()))
        ? max_num
        : accountBorrowData[4].toString()
      : to_bn__value;

    // console.log(final_value.toString());
    Lending.contracts?.general["i" + [this__token]].methods
      .repayBorrow(final_value)
      .send({ from: account })
      .on("transactionHash", function (hash: string) {
        console.log("transactionHash: ", hash);
        postDataToServer(
          wallet_source,
          "Repay",
          account || "",
          (chainId && network_Name[chainId]) || "",
          Lending.contracts?.general[this__token].address,
          accountBorrowData[5],
          borrowTokenData[3],
          to_bn__value
        );
        repay_input_change("");
        add_Notifies({
          state: "pedding",
          action: "Repay " + format_num_to_K(format_bn(to_bn__value.toString(), accountBorrowData[5], 4)) + " " + this__token,
          transactionHash: hash,
          time: new Date().getTime()
        }, setNowNotifies, account as string, chainId as number);
        setBool_Btn(false);
      })
      .on("receipt", function (receipt: any) {
        console.log("receipt: ", receipt);
        getStatesData();
        update_Notifies(receipt, setNowNotifies, account as string, chainId as number);
        setTimeout(() => {
          del_Notify(receipt, setNowNotifies);
        }, 3000);
        setBool_Btn(false);
      })
      .on("error", function (error: any) {
        console.log("error", error);
        setBool_Btn(false);
        setShow__cancel(true);
        setTimeout(() => {
          setShow__cancel(false);
        }, 3000);
      });
  };
  const click_repay__spe = async () => {
    if (!repay_number) {
      return console.log("repay_number undefined");
    }
    // if (new BigNumber(repay_number).eq(new BigNumber(0)) && !is_repay_max) {
    //   return console.log("repay_number is 0...");
    // }
    if (new BigNumber(accountBorrowData[4]).eq(new BigNumber(0))) {
      return console.log("repay_number is 0...");
    }
    if (bool_Btn) {
      return console.log("already repaying...");
    }
    setBool_Btn(true);


    // change to before
    // const api = 'https://ethgasstation.info/api/ethgasAPI.json?'
    // const api = 'https://www.gasnow.org/api/v3/gas/price'
    const api =
      `https://api.zapper.fi/v1/gas-price?api_key=96e0cc51-a62e-42ca-acee-910ea7d2a241&network=${chainId === 42161
        ? 'arbitrum'
        : chainId === 56 || chainId === 97
          ? 'binance-smart-chain'
          : 'ethereum'
      }&eip1559`


    const fastPrice = await fetch(api)
      .then((response) => response.json())
      .then((res) => {
        console.log(res)
        return res.fast
      })
      .catch((e) => {
        console.log(e)
      })
    console.log("test-price", fastPrice);


    // change to before
    const curLimit = await Lending.contracts?.general[this__token].methods
      .repayBorrow()
      .estimateGas({ from: account, }, (err: any, gasLimit: any) => {
        if (gasLimit) {
          console.log(gasLimit)
          return gasLimit
        }
      })
    console.log("test-limit", curLimit);


    // change to before
    let gasNeeded = fastPrice ? new BigNumber(fastPrice).multipliedBy(new BigNumber(curLimit)).multipliedBy(new BigNumber(10 ** 9)).toString() : undefined
    console.log("test-gasNeeded", gasNeeded);

    gasNeeded = gasNeeded ? gasNeeded : '0'

    let to_bn__value = new BigNumber(repay_number)
      .multipliedBy(new BigNumber(10).pow(new BigNumber(accountBorrowData[5])))
      .toString();

    // let max_num = new BigNumber(2).pow(new BigNumber(256)).minus(new BigNumber(1)).toString()

    let myBalance = new BigNumber(accountBorrowData[3].toString()).minus(new BigNumber(gasNeeded))
    let maxToRepay = new BigNumber(accountBorrowData[4].toString())

    let final_value = is_repay_max ?
      myBalance.gt(maxToRepay.multipliedBy(new BigNumber(1.0003))) ?
        maxToRepay.multipliedBy(new BigNumber(1.0003)).toFixed(0).toString() : myBalance.toString()
      :
      to_bn__value;

    // change to before
    // let final_value = is_repay_max ?
    //   new BigNumber(accountBorrowData[3]).gt(new BigNumber(accountBorrowData[4])) ?
    //     new BigNumber(accountBorrowData[3]).gt(new BigNumber(accountBorrowData[4]).multipliedBy(new BigNumber(1.003))) ?
    //       new BigNumber(accountBorrowData[4]).multipliedBy(new BigNumber(1.003)).toFixed(0).toString()
    //       // -gas
    //       :
    //       new BigNumber(accountBorrowData[3]).toString()
    //     // -gas
    //     :
    //     new BigNumber(accountBorrowData[3]).toString()
    //   // -gas
    //   : to_bn__value;

    console.log(final_value.toString());
    Lending.contracts?.general[this__token].methods
      .repayBorrow()
      .send({ from: account, value: final_value })
      .on("transactionHash", function (hash: string) {
        console.log("transactionHash: ", hash);
        postDataToServer(
          wallet_source,
          "Repay",
          account || "",
          (chainId && network_Name[chainId]) || "",
          Lending.contracts?.general[this__token].address,
          accountBorrowData[5],
          borrowTokenData[3],
          final_value
        );
        repay_input_change("");
        add_Notifies({
          state: "pedding",
          action: "Repay " + format_num_to_K(format_bn(to_bn__value.toString(), accountBorrowData[5], 4)) + " " + this__token,
          transactionHash: hash,
          time: new Date().getTime()
        }, setNowNotifies, account as string, chainId as number);
        setBool_Btn(false);
      })
      .on("receipt", function (receipt: any) {
        console.log("receipt: ", receipt);
        getStatesData();
        update_Notifies(receipt, setNowNotifies, account as string, chainId as number);
        setTimeout(() => {
          del_Notify(receipt, setNowNotifies);
        }, 3000);
        setBool_Btn(false);
      })
      .on("error", function (error: any) {
        console.log("error", error);
        setBool_Btn(false);
        setShow__cancel(true);
        setTimeout(() => {
          setShow__cancel(false);
        }, 3000);
      });
  };

  const click_approve_pre = () => {
    const curTokenAddress = Lending.contracts?.general[this__token].address
    if (DFApproveXArray.includes(curTokenAddress)) {
      click_approve(true)
    } else {
      click_approve()
    }
  }
  const click_approve = (reApprove?: boolean) => {
    if (bool_Btn__enable) {
      return console.log("already approving...");
    }
    setBool_Btn__enable(true);

    Lending.contracts?.general[this__token].methods
      .approve(
        Lending?.contracts?.general[
          !Lending?.contracts?.general[this__token]?.gasToken
            ? "i" + this__token
            : this__token
        ].address,
        reApprove ? '0' : ethers.constants.MaxUint256
      )
      .send({ from: account })
      .on("transactionHash", function (hash: string) {
        console.log("transactionHash: ", hash);
        setEnable_status(fmt({ id: "ENABLING" }));
        add_Notifies({
          state: "pedding",
          action: "Enable " + this__token,
          transactionHash: hash,
          time: new Date().getTime()
        }, setNowNotifies, account as string, chainId as number);
      })
      .on("receipt", function (receipt: any) {
        console.log("receipt: ", receipt);
        getStatesData();
        update_Notifies(receipt, setNowNotifies, account as string, chainId as number);
        setTimeout(() => {
          del_Notify(receipt, setNowNotifies);
          if (reApprove) {
            click_approve()
          }
        }, 3000);
        setBool_Btn__enable(false)
        setEnable_status(fmt({ id: "ENABLE" }))
      })
      .on("error", function (error: any) {
        console.log("error", error);
        setBool_Btn__enable(false);
        setEnable_status(fmt({ id: "ENABLE" }));
        setShow__cancel(true);
        setTimeout(() => {
          setShow__cancel(false);
        }, 3000);
      })
  }

  const renderErrs = (errCode: any, remaining: string, tokenSymbol: string) => {
    if (errCode === 1) {
      return (<>{`remaining ${remaining} ${tokenSymbol} to reach the Borrow Capacity`}</>)
    } else if (errCode === 2) {
      return (<>{`remaining ${remaining}  ${tokenSymbol} to reach the Segragation Debt Ceiling`}</>)
    } else if (errCode === 3) {
      return (<>{`remaining ${remaining}  ${tokenSymbol} to reach depletion of Liquidity`}</>)
    } else if (errCode === 4) {
      return (<>{'NotAllowedInSegregatedMode'}</>)
    } else if (errCode === 5) {
      return (<>{'NotAllowedInSuperchargedMode'}</>)
    } else {
      return (<>{'Borrow Err'}</>)
    }
  }






  return (
    <>
      <UnlockWalletModal
        isOpen={unlockModalIsOpen}
        onDismiss={handleDismissUnlockModal}
        metamaskActive={metamaskActive}
        walletConnectActive={walletConnectActive}
      />
      <Notification
        chainID={chainId}
        notifies={nowNotifies}
        setNowNotifies={setNowNotifies}
        del_Notify={del_Notify}
      />
      <Notification__cancel
        notifies={show__cancel}
        setShow__cancel={setShow__cancel}
        del_Notify={del__cancel}
      />


      <FlexBoxWrap>
        <Crumb>
          <span>
            <RouterLink to={`/lending`}>
              <BackToHome>{fmt({ id: "Home" })}</BackToHome>
            </RouterLink>
          </span>
        </Crumb>

        <BorderWrap>
          <StyledLeftSection>
            <NoteTips>{fmt({ id: "borrow_notes" })}</NoteTips>

            <PaddingWrap>
              <Logo_Token_number
                tab={"borrow"}
                token={this__token}
                myWallet={account}
                value={
                  accountBorrowData ?
                    format_bn(accountBorrowData[0], accountBorrowData[5], 2) === "0.00" ?
                      format_bn(accountBorrowData[0], accountBorrowData[5])
                      :
                      format_num_to_K(format_bn(accountBorrowData[0], accountBorrowData[5], 2))
                    : ""
                }
              />

              <ActionTab>
                <StyledTab onClick={() => { setIsChoosen__borrow__tab(true) }} className={isChoosen__borrow__tab ? 'isChoosen' : ''}>
                  {fmt({ id: "BORROW" })}
                </StyledTab>
                <StyledTab onClick={() => { setIsChoosen__borrow__tab(false) }} className={isChoosen__borrow__tab ? '' : 'isChoosen'}>
                  {fmt({ id: "REPAY" })}
                </StyledTab>
              </ActionTab>

              {
                !local_account ?
                  <>
                    <StyledApproveFirst>
                      {fmt({ id: "connect_wallet_first" })}
                    </StyledApproveFirst>
                    <StyledSupply_btn onClick={handleUnlockWalletClick}>
                      {fmt({ id: "Connect_Wallet" })}
                    </StyledSupply_btn>
                  </>
                  :
                  <>
                    {
                      !(borrowTokenData && accountBorrowData && accountBorrowInfo && accountTotalValue) ?
                        <LogoImgWrap>
                          <LogoImg src={dForceLogo}></LogoImg>
                        </LogoImgWrap>
                        :
                        <>
                          {
                            isChoosen__borrow__tab ?
                              <>
                                <Token_Number
                                  tab={"borrow"}
                                  token={this__token}
                                  myWallet={"account-0x"}
                                  value={
                                    accountBorrowData ?
                                      new BigNumber(accountBorrowData[2].toString()).gt(new BigNumber(0)) &&
                                        new BigNumber(accountBorrowData[2].toString()).div(new BigNumber(10).pow(new BigNumber(accountBorrowData[5]))).lt(new BigNumber(0.01)) ?
                                        format_num_to_K(format_bn(accountBorrowData[2].toString(), accountBorrowData[5]))
                                        :
                                        format_num_to_K(format_bn(accountBorrowData[2].toString(), accountBorrowData[5], 2))
                                      :
                                      ""
                                  }
                                />

                                {
                                  // borrow close ?
                                  marketsInfo && marketsInfo[6] === true
                                    ? <StyledYouDid>
                                      {fmt({ id: "markets_closed_1__Borrowings" })}
                                      {fmt({ id: "markets_closed_1" })}
                                    </StyledYouDid>
                                    // markets closed ?
                                    : accountBorrowInfo && accountBorrowInfo[3] === false ?
                                      <StyledYouDid>
                                        {fmt({ id: "markets_closed" })}
                                      </StyledYouDid>
                                      :
                                      <>
                                        {
                                          accountSupplyData && new BigNumber(accountSupplyData[0]).gt(new BigNumber(0)) ?
                                            <StyledYouDid>
                                              {fmt({ id: "supplied_tokens" })}
                                            </StyledYouDid>
                                            :
                                            <>
                                              <InputWrap>
                                                <Styledinput
                                                  placeholder={fmt({ id: "Amount" })}
                                                  onChange={(e) => { borrow_input_change(e.target.value) }}
                                                  value={borrow_number || ""}
                                                />
                                                <SpanMax onClick={borrow_max}>
                                                  {fmt({ id: "safe_max" })}
                                                </SpanMax>
                                              </InputWrap>

                                              <RangeWrap>
                                                <StyledPercentage>
                                                  <PercentageItem
                                                    onClick={() => { changePercentage(100) }}
                                                    className={curPercentage === 100 ? 'isPercentageChoosen' : ''}>
                                                    100%</PercentageItem>
                                                  <PercentageItem
                                                    onClick={() => { changePercentage(75) }}
                                                    className={curPercentage === 75 ? 'isPercentageChoosen' : ''}>
                                                    75%</PercentageItem>
                                                  <PercentageItem
                                                    onClick={() => { changePercentage(50) }}
                                                    className={curPercentage === 50 ? 'isPercentageChoosen' : ''}>
                                                    50%</PercentageItem>
                                                  <PercentageItem
                                                    onClick={() => { changePercentage(25) }}
                                                    className={curPercentage === 25 ? 'isPercentageChoosen' : ''}>
                                                    25%</PercentageItem>
                                                </StyledPercentage>
                                                {
                                                  // accountBorrowData &&
                                                  // accountBorrowData[1] === accountBorrowData[2] &&
                                                  // <OverSafeMAX>
                                                  //   {fmt(
                                                  //     { id: "borrow__warning" },
                                                  //     {
                                                  //       params:
                                                  //         format_num_to_K(format_bn(accountBorrowData[1], accountBorrowData[5], 2)) +
                                                  //         (intl_locale === "cn" ? "" : " ") +
                                                  //         this__token,
                                                  //     }
                                                  //   )}
                                                  // </OverSafeMAX>
                                                }
                                                {
                                                  this__token &&
                                                    accountBorrowData &&
                                                    accountBorrowData[6] &&
                                                    Number(accountBorrowData[6].toString()) > 0
                                                    ? <OverSafeMAX>
                                                      {/* {'err code: ' + accountBorrowData[6].toString() + ' - '} */}
                                                      {
                                                        renderErrs(
                                                          accountBorrowData[6],
                                                          format_num_to_K(format_bn(accountBorrowData[2], accountBorrowData[5], 2)),
                                                          this__token
                                                        )
                                                      }
                                                    </OverSafeMAX>
                                                    : ''
                                                }
                                              </RangeWrap>
                                            </>
                                        }
                                      </>
                                }

                                <StyledSupply_btn
                                  className={
                                    (accountSupplyData && new BigNumber(accountSupplyData[0]).gt(new BigNumber(0))) ||
                                      (marketsInfo && marketsInfo[6] === true) ||
                                      bool_Btn
                                      ? "disabledBtn" : ""
                                  }
                                  // onClick={isGasToken ? click_borrow__spe : click_borrow}
                                  onClick={borrow__pre}
                                >
                                  {fmt({ id: "BORROW" })}
                                </StyledSupply_btn>
                              </>
                              :
                              <>
                                {
                                  // allowance?.toNumber() === 0 && !isGasToken ?
                                  //   <>
                                  //     <StyledApproveFirst>
                                  //       {fmt({ id: "enable_first" }, { token: this__token })}
                                  //     </StyledApproveFirst>
                                  //     <StyledSupply_btn
                                  //       className={bool_Btn__enable ? "disabledBtn" : ""}
                                  //       onClick={() => { click_approve() }}>
                                  //       {enable_status}
                                  //     </StyledSupply_btn>
                                  //   </>:
                                  <>
                                    <Token_Number
                                      tab={"supply"}
                                      token={this__token}
                                      myWallet={"account-0x"}
                                      value={
                                        accountBorrowData ?
                                          new BigNumber(accountBorrowData[3]).gt(new BigNumber(0)) &&
                                            new BigNumber(accountBorrowData[3]).div(new BigNumber(10).pow(new BigNumber(accountBorrowData[5]))).lt(new BigNumber(0.01)) ?
                                            format_num_to_K(format_bn(accountBorrowData[3], accountBorrowData[5]))
                                            :
                                            format_num_to_K(format_bn(accountBorrowData[3], accountBorrowData[5], 2))
                                          :
                                          ""
                                      }
                                    />
                                    <InputWrap>
                                      <Styledinput
                                        placeholder={fmt({ id: "Amount" })}
                                        onChange={(e) => { repay_input_change(e.target.value) }}
                                        value={repay_number || ""}
                                      />
                                      <SpanMax onClick={repay_max}>
                                        {fmt({ id: "MAX" })}
                                      </SpanMax>
                                    </InputWrap>

                                    <RangeWrap>
                                      <StyledPercentage>
                                        <PercentageItem
                                          onClick={() => { changePercentage__tab2(100) }}
                                          className={curPercentage__tab2 === 100 ? 'isPercentageChoosen' : ''}>
                                          100%</PercentageItem>
                                        <PercentageItem
                                          onClick={() => { changePercentage__tab2(75) }}
                                          className={curPercentage__tab2 === 75 ? 'isPercentageChoosen' : ''}>
                                          75%</PercentageItem>
                                        <PercentageItem
                                          onClick={() => { changePercentage__tab2(50) }}
                                          className={curPercentage__tab2 === 50 ? 'isPercentageChoosen' : ''}>
                                          50%</PercentageItem>
                                        <PercentageItem
                                          onClick={() => { changePercentage__tab2(25) }}
                                          className={curPercentage__tab2 === 25 ? 'isPercentageChoosen' : ''}>
                                          25%</PercentageItem>
                                      </StyledPercentage>
                                    </RangeWrap>

                                    {
                                      allowance?.toNumber() === 0 &&
                                        !isGasToken
                                        ? <>
                                          <StyledSupply_btn
                                            className={bool_Btn__enable ? "disabledBtn" : ""}
                                            onClick={() => { click_approve() }}>
                                            {enable_status}
                                          </StyledSupply_btn>
                                        </>
                                        : !isGasToken &&
                                          allowance &&
                                          new BigNumber(repay_number)
                                            .multipliedBy(new BigNumber(10).pow(new BigNumber(accountBorrowData[5])))
                                            .gt(new BigNumber(allowance))
                                          ? <StyledSupply_btn
                                            className={bool_Btn__enable ? "disabledBtn" : ""}
                                            onClick={() => { click_approve_pre() }}>
                                            {enable_status}
                                          </StyledSupply_btn>
                                          : <StyledSupply_btn
                                            className={bool_Btn ? "disabledBtn" : ""}
                                            onClick={isGasToken ? click_repay__spe : click_repay}>
                                            {fmt({ id: "REPAY" })}
                                          </StyledSupply_btn>
                                    }
                                  </>
                                }
                              </>
                          }
                        </>
                    }
                  </>
              }

            </PaddingWrap>
          </StyledLeftSection>
          <StyleRightSection>
            <ChangeableValueToBe
              i18n_text={"Borrow_Balance"}
              value={accountTotalValue ? "$" + format_num_to_K(format_bn(accountTotalValue[2].toString(), 18, 2)) : ""}
              isChoosen__supply__tab={isChoosen__borrow__tab}
              is_green={true}
              tobe={
                borrow_number__debounced && borrowTokenData && accountTotalValue
                  ? "$" + format_num_to_K(
                    format_bn(
                      new BigNumber(borrow_number__debounced)
                        .multipliedBy(new BigNumber(borrowTokenData[3].toString()))
                        .plus(new BigNumber(accountTotalValue[2].toString()))
                        .toFixed(0),
                      18,
                      2
                    )
                  )
                  : ''
              }
              tobe__tab2={
                repay_number__debounced && borrowTokenData && accountTotalValue
                  ? "$" + format_num_to_K(
                    format_bn(
                      new BigNumber(accountTotalValue[2].toString())
                        .minus(
                          new BigNumber(repay_number__debounced)
                            .multipliedBy(new BigNumber(borrowTokenData[3].toString()))
                        ).toFixed(0),
                      18,
                      2
                    )
                  )
                  : ''
              }
              isRed_right={true}
            />
            <ChangeableValueToBe
              i18n_text={"Health_Factor"}
              showIcon={fmt({ id: "Health_Factor_tips" })}
              value={
                accountTotalValue
                  ? accountTotalValue[2].toString() !== "0"
                    ? new BigNumber(accountTotalValue[3].toString()).gt(new BigNumber(10).pow(new BigNumber(22)))
                      ? new BigNumber(accountTotalValue[3].toString()).eq(new BigNumber(2).pow(new BigNumber(256)).minus(new BigNumber(1)))
                        ? '...'
                        : ">10,000"
                      : format_num_to_K(format_bn(accountTotalValue[3].toString(), 18, 2))
                    : ""
                  : ""
              }
              tobe={
                borrow_number__debounced && healthRatio
                  ? Number(borrow_number__debounced) === 0 && accountTotalValue[2].toString() === "0"
                    ? '0.00'
                    : new BigNumber(healthRatio).gt(new BigNumber(10).pow(new BigNumber(22)))
                      ? ">10,000" : format_num_to_K(format_bn(healthRatio, 18, 2))
                  : ''
              }
              tobe__tab2={
                repay_number__debounced && healthRatio_tab2
                  ? accountTotalValue[2].toString() === "0"
                    ? '0.00'
                    : new BigNumber(healthRatio_tab2).gt(new BigNumber(10).pow(new BigNumber(22)))
                      ? borrowTokenData && accountTotalValue &&
                        format_bn(
                          new BigNumber(accountTotalValue[2].toString())
                            .minus(
                              new BigNumber(repay_number__debounced)
                                .multipliedBy(new BigNumber(borrowTokenData[3].toString()))
                            ).toFixed(0), 18, 2
                        ) === '0.00'
                        ? '0.00'
                        : ">10,000"
                      : format_num_to_K(format_bn(healthRatio_tab2, 18, 2))
                  : repay_number__debounced && Number(healthRatio_tab2) === 0
                    ? '0.00'
                    : ''
              }
              isChoosen__supply__tab={isChoosen__borrow__tab}
              is_green={true}
              isRed={true}
            />

            <ChangeableValueToBe
              i18n_text={"Borrowing_Power"}
              value={
                accountEquity
                  ? accountEquity[0].toString() === "0"
                    ? '0.00'
                    : '$' + format_num_to_K(format_bn(accountEquity[0].toString(), 18, 2))
                  : ""
              }
              tobe={
                borrow_number__debounced && toBePower
                  ? '$' + format_num_to_K(format_bn(toBePower, 18, 2))
                  : ''
              }
              tobe__tab2={
                repay_number__debounced && toBePower_tab2 && healthRatio_tab2
                  ? new BigNumber(healthRatio_tab2).lt(new BigNumber(1e18))
                    ? '0.00'
                    : '$' + format_num_to_K(format_bn(toBePower_tab2, 18, 2))
                  : ''
              }
              isChoosen__supply__tab={isChoosen__borrow__tab}
              is_green={true}
              isRed={true}
              isPower={true}
            />


            <ChangeableValueToBe
              i18n_text={"Liquidity"}
              isChoosen__supply__tab={isChoosen__borrow__tab}
              value={
                borrowTokenData && accountBorrowData ?
                  format_num_to_K(format_bn(borrowTokenData[0].toString(), accountBorrowData[5].toString(), 2)) : ""
              }
              tobe={
                borrow_number__debounced && borrowTokenData && accountBorrowData
                  ? format_num_to_K(
                    format_bn(
                      new BigNumber(borrowTokenData[0].toString())
                        .minus(
                          new BigNumber(borrow_number__debounced)
                            .multipliedBy(new BigNumber(10).pow(new BigNumber(accountBorrowData[5])))
                        )
                        .toString(),
                      accountBorrowData[5].toString(),
                      2
                    )
                  )
                  : ''
              }
              tobe__tab2={
                repay_number__debounced && borrowTokenData && accountBorrowData
                  ? format_num_to_K(
                    format_bn(
                      new BigNumber(borrowTokenData[0].toString())
                        .plus(
                          new BigNumber(repay_number__debounced)
                            .multipliedBy(new BigNumber(10).pow(new BigNumber(accountBorrowData[5])))
                        )
                        .toString(),
                      accountBorrowData[5].toString(),
                      2
                    )
                  )
                  : ''
              }
              isRed={true}
            />


            <ChangeableValueToBe
              i18n_text={"borrow_apy_space"}
              value={borrowTokenData ? format_num_to_K(format_bn(borrowTokenData[2].toString(), 16, 2)) + "%" : ""}
              isChoosen__supply__tab={isChoosen__borrow__tab}
              tobe={
                borrow_number__debounced && toBeAPY
                  ? format_num_to_K(format_bn(toBeAPY, 16, 2)) + "%"
                  : ''
              }
              tobe__tab2={
                repay_number__debounced && toBeAPY_tab2
                  ? format_num_to_K(format_bn(toBeAPY_tab2, 16, 2)) + "%"
                  : ''
              }
              isRed_right={true}
              showIcon={fmt({ id: "borrow_apy_tips" })}
            />

            <ChangeableValue
              i18n_text={"price"}
              value={borrowTokenData ? "$" + format_num_to_K(format_bn(borrowTokenData[3].toString(), 18, 2)) : ""}
              token={this__token}
              showPriceUpdate={true}
              add_Notifies={add_Notifies}
              setNowNotifies={setNowNotifies}
              update_Notifies={update_Notifies}
              del_Notify={del_Notify}
              setShow__cancel={setShow__cancel}
            />

            <StyledChart>
              <ReactApexChart
                options={chartState.options}
                series={chartState.series}
                type="area"
                height={320}
              />
            </StyledChart>
          </StyleRightSection>
        </BorderWrap>
      </FlexBoxWrap>


      <Modal isOpen={isModalOpen}>
        <StyledModalTitle>
          {'Borrow ' + this__token}
          <StyledClosed>
            <CloseModal src={close} onClick={handleDismiss_disable} />
          </StyledClosed>
        </StyledModalTitle>

        <PaddingWrap>
          <OperateSec>
            <SecTop>
              <ChangeItem>
                <ChangeLeft>Borrow Amout</ChangeLeft>
                <ChangeRight>
                  {format_num_to_K(format_bn(borrow_number, 0, 2))}
                </ChangeRight>
              </ChangeItem>
              <ChangeItem>
                <ChangeLeft>Delay Time</ChangeLeft>
                <ChangeRight>
                  {
                    delayTime &&
                    <>{Math.floor(Number(delayTime) / 60) + ' min : ' + Number(delayTime) % 60 + ' Sec'}</>
                  }
                </ChangeRight>
              </ChangeItem>
            </SecTop>
          </OperateSec>

          <OperateSec className="spe">
            <TipsText>
              <LeftIcon>
                <img src={tanhao} alt="" />
              </LeftIcon>
              <RightText>
                The borrow will be delayed, and the user will claim the borrowed assets on the Dashboard page.
              </RightText>
            </TipsText>
          </OperateSec>

          <StyledSupply_btn
            className={
              (accountSupplyData && new BigNumber(accountSupplyData[0]).gt(new BigNumber(0))) ||
                (marketsInfo && marketsInfo[6] === true) ||
                bool_Btn
                ? "disabledBtn" : ""
            }
            onClick={isGasToken ? click_borrow__spe : click_borrow}
          >
            {fmt({ id: "BORROW" })}
          </StyledSupply_btn>
          <div style={{ marginBottom: '16px' }}></div>
        </PaddingWrap>
      </Modal>
    </>
  )
}


const StyledPercentage = styled.div`
  /* background: green; */
  display: flex;
  flex-direction: row-reverse;
`;
const PercentageItem = styled.div`
  width: 66px;
  text-align: center;
  height: 32px;
  line-height: 30px;
  border-radius: 30px;
  margin-left: 6px;
  font-size: 14px;
  font-weight: 500;
  color: ${(props) => props.theme.global_grey};
  cursor: pointer;
  border: 1px solid ${(props) => props.theme.operationPage.light.borderColor};

  .dark & {
    border: 1px solid ${(props) => props.theme.operationPage.dark.borderColor};

    &.isPercentageChoosen {
      background: #05C7A0;
      color: #fff;
      opacity: 1;
    }
  }

  &.isPercentageChoosen {
    background: #05C7A0;
    color: #fff;
    opacity: 0.8;
  }
`;
const StyledModalTitle = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  padding-top: 24px;
  margin-bottom: 16px;
  .dark & {
    color: #fff;
  }
`
const StyledClosed = styled.div`
  position: absolute;
  right: 20px;
  top: 22px;
`
const CloseModal = styled.img`
  width: 24px;
  height: 24px;
`
const OperateSec = styled.div`
  width: 100%;
  padding: 8px 8px;
  border-radius: 8px;
  background: #F5F6F9;
  &.mt16 {
    margin-top: 16px;
  }
  &.Nopadding {
    height: 44px;
    padding: 0 8px;
  }
  select {
    border: none;
    width: 100%;
    height: 100%;
    background: transparent;
  }
  .dark & {
    background: rgba(19, 19, 19, 1);
  }
  &.spe {
    margin-top: 16px;
    margin-bottom: 16px;
    background: rgba(255, 167, 38, 0.16);
  }
`;
const SecTop = styled.div`
  color: #999;
`;
const ChangeItem = styled.div`
display: flex;
justify-content: space-between;
margin-top: 6px;
margin-bottom: 6px;
font-size: 14px;
`
const ChangeLeft = styled.div`
display: flex;
`
const ChangeRight = styled.div`
display: flex;
font-weight:600;
.dark & {
  color: #fff;
}
`
const TipsText = styled.div`
  color: rgba(255, 167, 38, 1);
  font-size: 12px;
  display: flex;
  gap: 10px;
`
const LeftIcon = styled.div`
  display: flex;
  /* margin-right: 20px; */
  width: 40px;
  /* background-color: red; */
  img {
    width: 18px;
  }
`
const RightText = styled.div`
  display: flex;
`


const StyledTab = styled.div`
  width: 50%;
  text-align: center;
  cursor: pointer;
  color: ${(props) => props.theme.global_grey};
  position: relative;

  &.isChoosen {
    color: ${(props) => props.theme.operationPage.light.mainBlack};

    .dark & {
      color: ${(props) => props.theme.operationPage.dark.mainBlack};
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 28px;
      height: 5px;
      background-color: #05C7A0;
      border-radius: 3px;
      left: calc(50% - 14px);
      bottom: -20px;

      @media (max-width: 1119px) {
        bottom: -14px;
        height: 3px;
      }
    }
  }
`

const LogoImgWrap = styled.div`
  text-align: center;
  padding-top: 90px;
  height: 279px;

  @media (max-width: 1199px) {
    height: 224px;
    padding-top: 70px;
  }
`;
const LogoImg = styled.img`
  width: 50px;
  height: 50px;
  @media (max-width: 1199px) {
    width: 40px;
    height: 40px;
  }
`;




// change height
const RangeWrap = styled.div`
  height: 120px;
  padding-top: 10px;
  position: relative;

  .input-range__slider {
    background: #05C7A0 !important;
    border: 1px solid #05C7A0 !important;
  }

  .input-range__track--active {
    background: #05C7A0 !important;
  }
`;
const StyledRange = styled.div`
  position: absolute;
  right: 0;
  color: #05C7A0;
  font-size: 14px;
  font-weight: 500;
  top: 28px;
`;


const Styledinput = styled.input`
  height: 100%;
  width: 100%;
  font-size: 16px;
  padding-left: 20px;
  font-weight: bold;
  color: ${(props) => props.theme.operationPage.light.mainBlack};
  background: ${(props) => props.theme.body_background_light};

  .dark & {
    color: ${(props) => props.theme.operationPage.dark.mainBlack};
    background: ${(props) => props.theme.body_background_night};
  }

  @media (max-width: 1119px) {
    font-size: 13px;
    padding-left: 14px;
  }
`;

const SpanMax = styled.span`
  position: absolute;
  right: 20px;
  top: 16px;
  height: 30px;
  line-height: 30px;
  color: #05C7A0;
  font-weight: bold;
  border-radius: 8px;
  background: #deedeb;
  cursor: pointer;
  padding-left: 14px;
  padding-right: 14px;
  opacity: 0.8;
  transition: 0.1s;

  &:hover {
    opacity: 1;
  }

  @media (max-width: 1119px) {
    font-size: 13px;
    right: 14px;
    top: 9px;
    height: 24px;
    line-height: 24px;
    padding-left: 10px;
    padding-right: 10px;
  }
`;

const StyledSupply_btn = styled.div`
  height: 60px;
  line-height: 60px;
  text-align: center;
  background: #05C7A0;
  color: #ffffff;
  border-radius: 12px;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  opacity: 0.9;
  transition: 0.1s;

  &:hover {
    opacity: 1;
  }

  @media (max-width: 1119px) {
    height: 42px;
    line-height: 42px;
    font-size: 13px;
  }
`
const Crumb = styled.div`
  display:flex;
  justify-content:space-between;
  align-items:center;
  width: 70px;
  height:32px;
  margin-top: 64px;
  margin-bottom: 16px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.08);
  padding-left: 8px;
  @media(max-width: 1199px) {
    margin-top: 20px;
  }
`
const RouterLink = styled(NavLink)`
`
const BackToHome = styled.b`
  display:flex;
  align-items:center;
  font-size:16px;
  height:30px;
  line-height:30px;
  color: #fff;
  font-weight: 500;
  &:before {            
    content: '';            
    display: block;
    width: 8px;            
    height: 8px; 
    margin-right:5px;      
    border-right: 2px solid ${props => props.theme.colors.lightGrey};            
    border-top: 2px solid ${props => props.theme.colors.lightGrey};            
    -webkit-transform: rotate(-135deg); /*箭头方向可以自由切换角度*/            
    transform: rotate(-135deg);
}
`

export default BorrowRepay
