import type { CoinbaseWallet } from '@web3-react/coinbase-wallet'
import { useWeb3React,Web3ReactHooks } from '@web3-react/core'
import { GnosisSafe } from '@web3-react/gnosis-safe'
import type { MetaMask } from '@web3-react/metamask'
import { Network } from '@web3-react/network'
import { WalletConnect } from '@web3-react/walletconnect'
import { WalletConnect as WalletConnectV2 } from '@web3-react/walletconnect-v2'
import { useCallback, useContext, useEffect, useState } from 'react'
import { getName } from '../utils'
// import { Context } from '../App';
import { CHAINS, getAddChainParameters } from '../chains'
import browserWallet from 'assets/browserWallet.svg'
import MetamaskLogo from '../assets/wallet-MetaMask.svg'
import WalletConnectLogo from '../assets/wallet-WalletConnect.svg'
import CoinbaseLogo from '../assets/wallet-Coinbase.svg'
import IERC20 from "../lending-sdk/lib/clean_build/contracts/general/IERC20.json"
import { IconDisconnect } from '@arco-iconbox/react-lsd'
import React from 'react'
import styled from 'styled-components'
function ChainSelect({
  activeChainId,
  switchChain,
  chainIds,
}: {
  activeChainId: number
  switchChain: (chainId: number) => void
  chainIds: any[]
}) {
  return (
    <select
      value={activeChainId}
      onChange={(event) => {
        switchChain(Number(event.target.value))
      }}
      disabled={switchChain === undefined}
    >
      <option hidden disabled selected={activeChainId === undefined}>
        Select chain
      </option>
      <option value={-1} selected={activeChainId === -1}>
        Default
      </option>
      {chainIds.map((chainId) => (
        <option key={chainId} value={chainId} selected={chainId === activeChainId}>
          {CHAINS[chainId]?.name ?? chainId}
        </option>
      ))}
    </select>
  )
}

export function ConnectWithSelect({
  connector,
  activeChainId,
  chainIds = Object.keys(CHAINS).map(Number),
  isActivating,
  isActive,
  error,
  setError,
}: {
  connector: MetaMask | WalletConnect | WalletConnectV2 | CoinbaseWallet | Network | GnosisSafe
  activeChainId: ReturnType<Web3ReactHooks['useChainId']>
  chainIds?: ReturnType<Web3ReactHooks['useChainId']>[]
  isActivating: ReturnType<Web3ReactHooks['useIsActivating']>
  isActive: ReturnType<Web3ReactHooks['useIsActive']>
  error: any
  setError: (arg0: any) => void
}) {
  const { chainId } = useWeb3React();
  const [desiredChainId, setDesiredChainId] = useState<any>(undefined)
  const supportchain = activeChainId ? Object.keys(IERC20.networks).includes((activeChainId as number).toString()) : false;
  // const context = useContext(Context);
  /**
   * When user connects eagerly (`desiredChainId` is undefined) or to the default chain (`desiredChainId` is -1),
   * update the `desiredChainId` value so that <select /> has the right selection.
   */
  const GetInstallWallet = (ethereum:any) =>{
    let isCoinbaseWallet:boolean = false
    let isTrustWallet:boolean = false
    if(ethereum?.providers){
      isCoinbaseWallet = !!(ethereum?.providers.find((item:any) =>item?.isCoinbaseWallet))
      isTrustWallet = !!(ethereum?.providers.find((item:any) =>isTrustWallet = item?.isTrustWallet))
    }else{
      isCoinbaseWallet = !!ethereum?.isCoinbaseWallet;
      isTrustWallet = !!ethereum?.isTrustWallet;
    }
    return {
      isCoinbaseWallet,
      isTrustWallet
    }
  }
  useEffect(() => {
    if (activeChainId && (!desiredChainId || desiredChainId === -1)) {
      if(supportchain){
        setDesiredChainId(activeChainId)
      };
    }
  }, [desiredChainId, activeChainId])

  useEffect(()=>{
    setError(undefined)
  },[chainId])

  const ConnectWallet = useCallback(async()=>{
    try {
      // if(getName(connector) === 'Coinbase' && !(GetInstallWallet(window?.ethereum)?.isCoinbaseWallet)){
      //   window.open("https://www.coinbase.com/wallet/downloads")
      //   return;
      // }
      // if (
      //   // If we're already connected to the desired chain, return
      //   desiredChainId === activeChainId ||
      //   // If they want to connect to the default chain and we're already connected, return
      //   (desiredChainId === -1 && activeChainId !== undefined)
      // ) {
      //   setError(undefined)
      //   return
      // }
      // context.setVisible && context.setVisible(false)
      if ((desiredChainId === -1) || desiredChainId === undefined) {
          await connector.activate()
      } else if (
        connector instanceof WalletConnectV2 ||
        connector instanceof WalletConnect ||
        connector instanceof Network
      ) {
        await connector.activate(desiredChainId)
      } else {
        await connector.activate(getAddChainParameters(desiredChainId))
      }

      setError(undefined)
    } catch (error) {
      console.log(error)
      // console.log(JSON.stringify(error, null, 2))
      const str = JSON.parse(JSON.stringify(error, null, 2))
      // console.log(typeof error)
      if(str?.name==="NoMetaMaskError"){
        window.open("https://metamask.io/download/")
      }else if(str?.name==="NoMetaMaskError")
      setError(error)
    }
  },[connector, activeChainId, setError])
  const switchChain = useCallback(
    async (desiredChainId: number) => {
      setDesiredChainId(desiredChainId)

      try {
        // context.setVisible && context.setVisible(false)
        if (
          // If we're already connected to the desired chain, return
          desiredChainId === activeChainId ||
          // If they want to connect to the default chain and we're already connected, return
          (desiredChainId === -1 && activeChainId !== undefined)
        ) {
          setError(undefined)
          return
        }

        if (desiredChainId === -1 || connector instanceof GnosisSafe) {
          await connector.activate()
        } else if (
          connector instanceof WalletConnectV2 ||
          connector instanceof WalletConnect ||
          connector instanceof Network
        ) {
          await connector.activate(desiredChainId)
        } else {
          await connector.activate(getAddChainParameters(desiredChainId))
        }

        setError(undefined)
      } catch (error) {
        setError(error)
      }
    },
    [connector, activeChainId, setError]
  )

  return (
    <>
      {/* {!(connector instanceof GnosisSafe) && (
        <ChainSelect activeChainId={desiredChainId} switchChain={switchChain} chainIds={chainIds} />
      )} */}
      {/* <div style={{ marginBottom: '1rem' }} /> */}
      {isActive ? (
        error ? (
          // <button onClick={() => switchChain(desiredChainId)} style={{ fontSize:'18px',alignSelf:'flex-start' }}>Try again?</button>
          <ConnectBox>
          <img src={getName(connector) === 'MetaMask' ? browserWallet : getName(connector) === 'WalletConnect' ? WalletConnectLogo :CoinbaseLogo} alt="Metamask" />
          {getName(connector) === 'MetaMask' ? 'Browser Wallet' :getName(connector)}
          <button
            onClick={async() =>{
              switchChain(1)
              // debugger
              // try {
              //   if (desiredChainId === -1 || connector instanceof GnosisSafe) {
              //     await connector.activate()
              //   } else if (
              //     connector instanceof WalletConnectV2 ||
              //     connector instanceof WalletConnect ||
              //     connector instanceof Network
              //   ) {
              //     await connector.activate(desiredChainId)
              //   } else {
              //     await connector.activate(getAddChainParameters(desiredChainId))
              //   }
              //   setError(undefined)
              // } catch (error) {
              //   setError(error)
              // }
            }}
          >
        </button>
        </ConnectBox>
          ) :
        supportchain ? (
          // <div style={{ display: 'flex', flexDirection: 'column',fontSize:'18px',alignSelf:'flex-start' }}>
          <Disconnect
            onClick={() => {
              if (connector?.deactivate) {
                void connector.deactivate()
              } else {
                void connector.resetState()
              }
              setDesiredChainId(undefined)
            }}
          >
            <IconDisconnect onPointerEnterCapture={true} onPointerLeaveCapture={true}/>
            Disconnect
          </Disconnect>
          // </div>
        ) :
        <ConnectBox>
          <img src={getName(connector) === 'MetaMask' ? browserWallet : getName(connector) === 'WalletConnect' ? WalletConnectLogo :CoinbaseLogo} alt="Metamask" />
          {getName(connector)}
          <button
            onClick={async() =>{
              switchChain(1)
              // try {
              //   debugger
              //   if (desiredChainId === -1 || connector instanceof GnosisSafe) {
              //     await connector.activate()
              //   } else if (
              //     connector instanceof WalletConnectV2 ||
              //     connector instanceof WalletConnect ||
              //     connector instanceof Network
              //   ) {
              //     await connector.activate(desiredChainId)
              //   } else {
              //     await connector.activate(getAddChainParameters(desiredChainId))
              //   }
              //   setError(undefined)
              // } catch (error) {
              //   setError(error)
              // }
            }}
          >
        </button>
        </ConnectBox>
        ) : (
        <ConnectBox>
          <img src={getName(connector) === 'MetaMask' ? browserWallet : getName(connector) === 'WalletConnect' ? WalletConnectLogo :CoinbaseLogo} alt="Metamask" />
          {getName(connector) === 'MetaMask' ? 'Browser Wallet' :getName(connector)}
          <button
            onClick={() =>
              ConnectWallet()
              // connector instanceof GnosisSafe
              //   ? void connector
              //       .activate()
              //       .then(() => setError(undefined))
              //       .catch(setError)
              //   : switchChain(desiredChainId)
            }
            // disabled={isActivating || !desiredChainId}
          >
            {/* {error ? 'Try again?' : 'Connect'} */}
        </button>
        </ConnectBox>
      )}
    </>
  )
}
const ConnectBox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    img{
      display:block;
      width:40px;
      margin-bottom:8px;
    }
    button{
      position:absolute;
      left:0;
      top:0;
      right:0;
      bottom:0;
      cursor: pointer;
      background-color: transparent;
    }
`
const Disconnect = styled.button`
  display:flex;
  align-self:flex-start;
  margin-top:4px;
  gap:4px;
  font-size:18px;
  color:#7c8c9c;
  cursor: pointer;
  @media (max-width: 1199px) {
    font-size:14px
  }
`