/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable react-hooks/exhaustive-deps */

import React, {
  useState,
  useEffect,
  useCallback,
  ReactHTMLElement,
  useMemo,
} from "react";
import { NavLink } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { useIntl } from "react-intl";
import { useWeb3React } from '@web3-react/core'
import { MarketsContextValues } from "contexts/Markets";
import ReactTooltip from "react-tooltip";
import BigNumber from "bignumber.js";
import lido from "assets/home/lido.svg"
import tooltip from 'assets/supply/tooltip.svg'
import general_deployAssets from "lending-sdk/lib/clean_build/contracts/general/DL_Token.json";
import synthetic_deployAssets from "lending-sdk/lib/clean_build/contracts/synthetic/DL_Token.json";
import stocks_deployAssets from "lending-sdk/lib/clean_build/contracts/stocks/DL_Token.json";
import img__on__notClick from "assets/supply/on__notClick.svg";
import img__off from "assets/supply/img__off.svg";
import img__on from "assets/supply/img__on.svg";
import cannotClick from "assets/supply/cannotClick.svg";
import nike from "assets/supply/nike.svg";
import useLending from "hooks/useLending"
import {
  bnToDec,
  formate_round_up,
  GetUrlParam,
  useQuery,
  getDeployAddressMap,
  _formatNumberNotCarry,
  numberFormatter,
  format_bn,
} from "utils";
import { network_Name, add_Notifies, update_Notifies, del_Notify, del__cancel } from 'lending-sdk/utils/index1'
import { rewardRate } from 'constants/constants';
import addToken from "assets/home/addToken.svg";
import numeral from "numeral";
import Notification from "components/Notification";
import Notification__cancel from "components/Notification__cancel";

interface TokenListProps {
  supplyMarkets: MarketsContextValues[];
  marketsData: any[];
  accountSupplyList: any[];
}

// status = 0  supply 显示 borrow 置灰   .NoSupply
// status = 1  supply 显示 borrow 显示   .Supply_Borrow
// status = 2  supply 显示 withdraw 显示 .OnlySupply
// status = 3  borrow 显示 repay 显示    .Supply_Borrow
const TokenList: React.FC<TokenListProps> = ({ supplyMarkets, marketsData, accountSupplyList }) => {
  const intl = useIntl();
  const fmt = intl.formatMessage;
  // let query = useQuery();
  const Lending = useLending()
  const {
    connector,
    account,
    chainId,
    provider,
    ENSName,
    isActive,
    isActivating,
  } = useWeb3React();

  // const urlQueryAssetsType = query.get("AssetsType");
  // const deployAssets = {
  //   general: general_deployAssets,
  //   synthetic: synthetic_deployAssets,
  //   stocks: stocks_deployAssets,
  // };
  // const DeployAssetsType = getDeployAddressMap(
  //   deployAssets['general'],
  //   "assetsType",
  //   chainId
  // ).filter((item) => item);
  // const switchTabHandler = (e: any) => {
  //   if (e.target.tagName === 'LI') {
  //     e.currentTarget.querySelector(".active")?.classList.remove("active");
  //     // e.target.parentNode.classList.add('active')
  //     e.target.classList.add('active')
  //   }
  // };
  // 存储一下 fetch 轮询回来的markets ☆☆☆☆☆☆☆
  const fetchMarkets = supplyMarkets;
  // const MainCryptos = supplyMarkets ? [...supplyMarkets].filter(
  //   (item) => item.assetsType === "MainCryptos"
  // ) : [];
  // const Synth = supplyMarkets ? [...supplyMarkets].filter((item) => item.assetsType === "Synth") : [];
  // const LP = supplyMarkets ? [...supplyMarkets].filter((item) => item.assetsType === "LP") : [];
  // const Stable = supplyMarkets ? [...supplyMarkets].filter((item) => item.assetsType === "Stable") : [];
  // const assetsData = {
  //   all: fetchMarkets,
  //   MainCryptos,
  //   Synth,
  //   LP,
  //   Stable,
  // };
  // const { error, account } = useWallet();
  // supply markets
  const [render_markets, setRender_markets] = useState<MarketsContextValues[]>(
    []
  );
  const [supplyAPY, setSupplyAPY] = useState(0);
  const [borrowAPY, setBorrowAPY] = useState(0);
  const [marketSize, setMarketSize] = useState(0);
  const [cash, setCash] = useState(0);
  const [balance, setbalance] = useState(0);
  //mint markets
  const [render_MintMarkets, setRender_MintMarkets] = useState<
    MarketsContextValues[] | null
  >();
  const [assetsType, setAssetsType] = useState("all");
  const [mintAPY, setMintAPY] = useState(0);
  const [mintSize, setMintSize] = useState(0);
  const [WSTethAPY, setWSTethAPY] = useState('0');
  const [showFreeze, setShowFreeze] = useState(false);
  const [isHasFrozen, setIsHasFrozen] = useState(false);

  const [nowNotifies, setNowNotifies] = useState<any>([])
  const [show__cancel, setShow__cancel] = useState<boolean>(false)

  // useEffect(() => {
  //   const historyPool = window.localStorage.getItem("currentPool");
  //   const currentPool = query.get("currentPool");
  //   currentPool && window.localStorage.setItem("currentPool", currentPool);
  //   if (historyPool && historyPool !== query.get("currentPool")) {
  //     setRender_markets([]);
  //     setRender_MintMarkets(null);
  //     window.IntervalMarkets && clearInterval(window.IntervalMarkets)
  //     // window.location.reload();
  //   } else {
  //     if (urlQueryAssetsType === "Lend") {
  //       setRender_markets(fetchMarkets);
  //       setRender_MintMarkets(null);
  //     } else if (urlQueryAssetsType === "Synthetic") {
  //       setRender_markets(fetchMarkets);
  //       setRender_MintMarkets(fetchMintMarkets);
  //     }
  //   }
  //   // 清空 排序
  //   setSupplyAPY(0);
  //   setBorrowAPY(0);
  //   setMarketSize(0);
  //   setCash(0);
  //   setbalance(0);
  //   setMintAPY(0);
  //   setMintSize(0);
  //   setSortMarketsData([]);
  //   setSortMintMarketsData([]);
  //   // 重置资产类型选择
  //   setAssetsType("all");
  // }, [urlQueryAssetsType, query.get("currentPool")]);
  //supply markets
  let sort_markets_data: MarketsContextValues[];
  const [sortMarketsData, setSortMarketsData] = useState<
    MarketsContextValues[]
  >([]);

  // const [lengthNormalAssetsCollateral, setLengthNormalAssetsCollateral] = useState(false)
  // const [lengthISOAssetsCollateral, setLengthISOAssetsCollateral] = useState(false)


  const sortData = useCallback(
    (
      current_status: number,
      current_Dispatch: React.Dispatch<React.SetStateAction<number>>,
      current_type: string
    ) => {
      // 每次先重置 所有排序
      setSupplyAPY(0);
      setBorrowAPY(0);
      setMarketSize(0);
      setCash(0);
      setbalance(0);
      if (current_status === 0) {
        current_Dispatch(1);
        sort_markets_data = render_markets.sort(
          (a, b) => Number(b[current_type]) - Number(a[current_type])
        );
      } else if (current_status === 1) {
        current_Dispatch(-1);
        sort_markets_data = render_markets.sort(
          (a, b) => Number(a[current_type]) - Number(b[current_type])
        );
      } else if (current_status === -1) {
        current_Dispatch(0);
        // 切换tab资产类型之后 排序处理
        sort_markets_data = fetchMarkets;
      }
      setSortMarketsData(sort_markets_data);
      // setRender_markets(sort_markets_data);
    },
    [render_markets]
  );
  //mint markets
  let sort_mint_markets_data: MarketsContextValues[] | null;
  const [sortMintMarketsData, setSortMintMarketsData] = useState<
    MarketsContextValues[] | null
  >([]);

  const clickEvent = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    status: number | undefined
  ) => {
    if (status === 0) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  useMemo(() => {
    if (render_markets.length > 0) {
      let tmpBool = false
      render_markets.map((item) => {
        if (item.isFreeze) {
          // console.log(item)
          tmpBool = true
        }
      })
      setIsHasFrozen(tmpBool)
    }
  }, [render_markets])


  const StakeLido = useCallback((e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,) => {
    e.preventDefault();
    e.stopPropagation();
    window.open('https://stake.lido.fi/?ref=0x0Cf797324F358Aca98acFC9CB94D14C9738921F7', 'top')
  }, [])
  const ObtainsDAI = useCallback((e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,) => {
    e.preventDefault();
    e.stopPropagation();
    window.open('https://app.sparkprotocol.io/sdai/', 'top')
  }, [])
  const ObtainsRETH = useCallback((e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,) => {
    e.preventDefault();
    e.stopPropagation();
    window.open('https://stake.rocketpool.net/', 'top')
  }, [])
  const renderLink = (tokenType: string) => {
    if (tokenType === 'rETH') {
      return (
        <LidoLink target="_blank" rel="noreferrer" onClick={ObtainsRETH}>
          {'Get rETH'}
        </LidoLink>
      )
    }
    if (tokenType === 'sDAI') {
      return (
        <LidoLink target="_blank" rel="noreferrer" onClick={ObtainsDAI}>
          {'Get sDAI'}
        </LidoLink>
      )
    }
    if (tokenType === 'tToken') {
      return (
        <LidoLink target="_blank" rel="noreferrer" onClick={StakeLido}>
          {`${WSTethAPY}% on Stake`}
          <LidoIcon src={lido} />
        </LidoLink>
      )
    }
  }
  const showFreezeFun = () => {
    return (
      <FreezeText>
        {'Token Freezeed'}
      </FreezeText>
    )
  }
  const checkboxChange = (e: any) => {
    // console.log(e)
    setShowFreeze(!showFreeze);
  }
  const AddTokenToMetamask = async (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    address: string | undefined,
    symbol: string | undefined,
    decimals: React.ReactText,
    image: string | undefined
  ) => {
    e.preventDefault();
    e.stopPropagation();
    console.log(image);
    try {
      // wasAdded is a boolean. Like any RPC method, an error may be thrown.
      const wasAdded = await window.Lendingsauce.web3._provider.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20", // Initially only supports ERC20, but eventually more!
          options: {
            address, // The address that the token is at.
            symbol, // A ticker symbol or shorthand, up to 5 chars.
            decimals, // The number of decimals in the token
            image: `${window.location.origin}${image}`, // A string url of the token logo
          },
        },
      });

      if (wasAdded) {
        console.log("Thanks for your interest!");
      } else {
        console.log("Your loss!");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchWSTethAPY = useCallback(async () => {
    // const result: any = await requestAPI("https://yields.llama.fi/poolsEnriched?pool=747c1d2a-c668-4682-b9f9-296708a3dd90")
    // setWSTethAPY((result?.status === 'success') ? result?.["data"]?.[0]?.apy : '0')
    try {
      const response = await fetch("https://yields.llama.fi/poolsEnriched?pool=747c1d2a-c668-4682-b9f9-296708a3dd90")
      const res = await response.json();
      if (res.status === 'success') {
        setWSTethAPY(res?.["data"]?.[0]?.apy)
      } else {
        setWSTethAPY('0')
      }
    } catch (error) {
      console.log(error)
      setWSTethAPY('0')
    }
  }, [chainId])
  useEffect(() => {
    if (supplyMarkets.length > 0) {
      //每次轮询 取API最新数据进行更新
      const fetchMarkets = supplyMarkets
      // const MainCryptos = [...markets].filter(item=>item.assetsType === 'MainCryptos')
      // const Synth = [...markets].filter(item=>item.assetsType === 'Synth')
      // const LP = [...markets].filter(item=>item.assetsType === 'LP')
      // const Stable = [...markets].filter(item=>item.assetsType === 'Stable')
      if (sortMarketsData.length > 0) {
        const updateSortMarketsData = sortMarketsData.reduce(
          (result: MarketsContextValues[], item) => {
            const currentItem = fetchMarkets.find(
              (item2) => item2.address === item.address
            );
            if (currentItem) {
              result.push(currentItem);
            }
            return result;
          },
          []
        );
        setRender_markets(updateSortMarketsData);
      } else {
        setRender_markets(fetchMarkets);
      }
    } else {
      setRender_markets(supplyMarkets);
    }
    fetchWSTethAPY()
  }, [supplyMarkets, sortMarketsData]);






  const makeItAsCollateral = (item: any) => {
    console.log(item)
    //   Token Value ($)(uint256)  //  accountSupplyInfo[0]
    //   As Collateral (bool) (true: Already used as collateral; false: Not as collateral)  accountSupplyInfo[1]
    //   executed (bool) (true: Can be executed; false: Not executable)  accountSupplyInfo[2]
    //   markets_closed  //  accountSupplyInfo[3]
    //   is iso assert (bool)

    const tokenSymbol = item.underlying_symbol

    // console.log(item.AsCollateralDetails[1])
    // if (hasClickCollateral) {
    //   return console.log('has Click btn')
    // }
    // setHasClickCollateral(true);

    Lending.contracts?.general.Controller.methods[
      item.AsCollateralDetails[1] ? "exitMarkets" : "enterMarkets"
    ]([Lending.contracts?.general["i" + tokenSymbol].address,])
      .send({ from: account })
      .on("transactionHash", function (hash: string) {
        console.log("transactionHash: ", hash)
        add_Notifies({
          state: "pedding",
          action: !item.AsCollateralDetails[1] ?
            "Use " + tokenSymbol + " As Collateral" : "Disable As Collateral",
          transactionHash: hash,
          time: new Date().getTime()
        }, setNowNotifies, account as string, chainId as number)
      })
      .on("receipt", function (receipt: any) {
        // setHasClickCollateral(false)
        console.log("receipt: ", receipt)
        update_Notifies(receipt, setNowNotifies, account as string, chainId as number)
        setTimeout(() => {
          del_Notify(receipt, setNowNotifies)
        }, 3000)
      })
      .on("error", function (error: any) {
        console.log("error", error)
        // setHasClickCollateral(false)
        setShow__cancel(true)
        setTimeout(() => {
          setShow__cancel(false)
        }, 3000)
      })
  }

  // useEffect(()=>{
  //   console.log(supplyMarkets)
  // },[supplyMarkets])


  // useEffect(() => {
  //   if (supplyList && supplyList.length > 0) {
  //     // console.log(supplyList)
  //     const AsCollateralNormalAssetsArray: any = []
  //     const AsCollateralISOAssetsArray: any = []
  //     supplyList.map((item) => {
  //       // console.log(item.symbol, ' is-iso-', item.isSegregationAssert, 'executed-', item.AsCollateralDetails[2])
  //       // As-Collateral
  //       if (item.AsCollateralDetails[1]) {
  //         // Normal Assets
  //         if (!item.isSegregationAssert) {
  //           AsCollateralNormalAssetsArray.push(item)
  //         }
  //         // ISO Assets
  //         if (item.isSegregationAssert) {
  //           AsCollateralISOAssetsArray.push(item)
  //         }
  //       }
  //     })

  //     // console.log(AsCollateralNormalAssetsArray)
  //     // console.log(AsCollateralISOAssetsArray)
  //     // lengthNormalAssetsCollateral
  //     // lengthISOAssetsCollateral
  //     setLengthNormalAssetsCollateral(AsCollateralNormalAssetsArray.length > 0)
  //     setLengthISOAssetsCollateral(AsCollateralISOAssetsArray.length > 0)
  //   }
  // }, [supplyList])
  // useEffect(() => {
  //   if (accountSupplyList && accountSupplyList.length > 0) {
  //     console.log(accountSupplyList)
  //   }
  // }, [accountSupplyList])
  const renderCollateralDetails = (item: any) => {
    if (marketsData && marketsData[item.address]) {
      if (
        !(marketsData[item.address].asCollateral) &&
        !(marketsData[item.address].canChangeAsCollateral)
      ) {
        return (
          <>
            <img className={"img__AsCollateral img__AsCollateral__pointer"} src={cannotClick} alt="" />
          </>
        )
      }

      if (
        (marketsData[item.address].canChangeAsCollateral) &&
        (marketsData[item.address].asCollateral)
      ) {
        return (
          <img
            className={"img__AsCollateral img__AsCollateral__pointer"}
            src={img__on}
            alt=""
            onClick={() => { makeItAsCollateral(item) }}
          />
        )
      }

      return (
        <>
          <img className={"img__AsCollateral img__AsCollateral__pointer"} src={nike} alt="" />
        </>
      )
    } else {
      return (
        <> -- </>
      )
    }
  }




  return (
    <>
      <Notification
        chainID={chainId}
        notifies={nowNotifies}
        setNowNotifies={setNowNotifies}
        del_Notify={del_Notify}
      />
      <Notification__cancel
        notifies={show__cancel}
        setShow__cancel={setShow__cancel}
        del_Notify={del__cancel}
      />


      <TableWrap>
        <Table>
          <thead>
            <TypeTitle>
              {fmt({ id: "topbar_Markets" })}
              {
                isHasFrozen &&
                <FreezeBox>
                  <label htmlFor={"toggle-btn"}><span>Show Frozen Assets</span></label>
                  <input
                    className="switch"
                    type="checkbox"
                    id={"toggle-btn"}
                    checked={showFreeze ? true : false}
                    onChange={(e) => { checkboxChange(e.target.checked) }} />
                </FreezeBox>
              }
            </TypeTitle>
            {/* <tr>
              <TheadBar>
                <SwitchTabs onClick={(e) => switchTabHandler(e)}>
                  <SwitchItem className={"active"} data-type="all">
                    {fmt({ id: "ALL" })}
                  </SwitchItem>
                  {DeployAssetsType.filter((item) => item === "MainCryptos")
                    .length > 0 && (
                      <SwitchItem data-type="MainCryptos">
                        {fmt({ id: "MainCryptos" })}
                      </SwitchItem>
                    )}
                  {DeployAssetsType.filter((item) => item === "Synth").length >
                    0 && (
                      <SwitchItem data-type="Synth">
                        {fmt({ id: "Synth" })}
                      </SwitchItem>
                    )}
                  {DeployAssetsType.filter((item) => item === "LP").length >
                    0 && (
                      <SwitchItem data-type="LP">{fmt({ id: "LP" })}</SwitchItem>
                    )}
                  {DeployAssetsType.filter((item) => item === "Stable").length >
                    0 && (
                      <SwitchItem data-type="Stable">
                        {fmt({ id: "Stable" })}
                      </SwitchItem>
                    )}
                </SwitchTabs>
              </TheadBar>
            </tr> */}
            <tr>
              <th className="firstTh">{fmt({ id: "Markets_Assets" })}</th>
              <th>
                <SortArea
                  onClick={(): void =>
                    sortData(supplyAPY, setSupplyAPY, "supplyAPY")
                  }
                >
                  {/* {fmt({ id: "supply_apy" })} */}
                  <div className="specialApy">
                    <div>
                      <span>{fmt({ id: "Supply_APY" })}</span>
                    </div>
                    <div>
                      <span>{fmt({ id: "Reward_to_MAX" })}</span>
                    </div>
                  </div>
                  <i className={"active" + supplyAPY}></i>
                </SortArea>
              </th>
              <th>
                <SortArea
                  onClick={(): void =>
                    sortData(borrowAPY, setBorrowAPY, "borrowAPY")
                  }
                >
                  {/* {fmt({ id: "borrow_apy" })} */}
                  <div className="specialApy">
                    <div>
                      <span>{fmt({ id: "Borrow_APY" })}</span>
                    </div>
                    <div>
                      <span>{fmt({ id: "Reward_to_MAX" })}</span>
                    </div>
                  </div>
                  <i className={"active" + borrowAPY}></i>
                </SortArea>
              </th>
              <th>
                <SortArea
                  onClick={(): void =>
                    sortData(marketSize, setMarketSize, "supplyValue")
                  }
                >
                  {fmt({ id: "Market_Size" })}
                  <i className={"active" + marketSize}></i>
                </SortArea>
              </th>
              <th>
                <SortArea onClick={(): void => sortData(cash, setCash, "cash")}>
                  {fmt({ id: "Liquidity" })}
                  <i className={"active" + cash}></i>
                </SortArea>
              </th>
              <th className="balance">
                <SortArea
                  onClick={(): void => sortData(balance, setbalance, "balance")}
                >
                  {fmt({ id: "Wallet" })}
                  <i className={"active" + balance}></i>
                </SortArea>
              </th>
              <th className="Collateral">
                <SortArea
                // onClick={(): void => sortData(balance, setbalance, "balance")}
                >
                  {'Can be Collateral'}
                  {/* <i className={"active" + balance}></i> */}
                </SortArea>
              </th>
              <th className="lastTh"></th>
            </tr>
          </thead>
          <tbody>
            {render_markets.length > 0
              ? render_markets.map((item) => {
                if (item.isFreeze) { return false }

                let isSupplyed = false
                if (accountSupplyList && accountSupplyList.length > 0) {
                  accountSupplyList.map((supplyItem: any) => {
                    if (supplyItem.address === item.address) {
                      isSupplyed = true
                    }
                  })
                }
                if (isSupplyed) { return false }
                // let ticon = require(`../../../assets/tokenList/default.svg`).default;

                // console.log(item)
                return (
                  <tr key={item.address}>
                    <td className="firstTd">
                      <TokenIconWrap>
                        {/* {item.isFreeze && <StyledFreeze>Frozen</StyledFreeze>} */}
                        <TokenIcon src={item.icon} />
                        {/* <TokenIcon src={ticon} /> */}
                      </TokenIconWrap>
                      {
                        // item.isFreeze
                        // ?<TokenSymbol>
                        //   {item.reveal_symbol}
                        //   {showFreezeFun()}
                        // </TokenSymbol>
                        // :<TokenSymbol>
                        //   {item.reveal_symbol}
                        //   {item.tokenType && renderLink(item.tokenType)}
                        // </TokenSymbol>
                      }
                      <TokenSymbol>
                        {item.reveal_symbol}
                        {item.tokenType && renderLink(item.tokenType)}
                      </TokenSymbol>
                    </td>
                    <td className="specialApy">
                      <div className="supply">
                        <span>
                          {
                            item.supplyAPY ?
                              bnToDec(new BigNumber(item.supplyAPY).abs(), 18) > 9999.99
                                ?
                                '999,999%'
                                :
                                _formatNumberNotCarry(bnToDec(new BigNumber(item.supplyAPY), 18, 6), 'percent')
                              : '...'
                            // numeral(
                            //   numeral(
                            //     bnToDec(new BigNumber(item.mainingSupplyApy), 18, 4)
                            //   ).format("0.0000")
                            // ).format("0,0.00%")
                          }
                        </span>
                      </div>
                      {
                        (item.SupplyRewardAPY && item.MinimumSupplyRewardAPY && item.DailyDistribution !== '0') &&
                        <div className="APR">
                          <img src="/Unitus.svg" />
                          <span>
                            {
                              bnToDec(new BigNumber(item.SupplyRewardAPY).abs(), 18) > 9999.99
                                ?
                                <div>{_formatNumberNotCarry(bnToDec(new BigNumber(item.MinimumSupplyRewardAPY), 18, 6), 'percent')}~<i>999,999%</i></div>
                                :
                                <div>{_formatNumberNotCarry(bnToDec(new BigNumber(item.MinimumSupplyRewardAPY), 18, 6), 'percent')}~<i>{_formatNumberNotCarry(bnToDec(new BigNumber(item.SupplyRewardAPY), 18, 6), 'percent')}</i></div>
                            }
                          </span>
                        </div>
                        // :<div className="APR"><span>...</span></div>
                      }
                      {
                        (item.SupplyRewardAPYArb && item.MinimumSupplyRewardAPYArb && item.DailyDistributionArb !== '0') &&
                        <div className="APR">
                          <img src="/ARB.svg" />
                          <span>
                            {
                              bnToDec(new BigNumber(item.SupplyRewardAPYArb).abs(), 18) > 9999.99
                                ?
                                <div>{_formatNumberNotCarry(bnToDec(new BigNumber(item.MinimumSupplyRewardAPYArb), 18, 6), 'percent')}~<i>999,999%</i></div>
                                :
                                <div>{_formatNumberNotCarry(bnToDec(new BigNumber(item.MinimumSupplyRewardAPYArb), 18, 6), 'percent')}~<i>{_formatNumberNotCarry(bnToDec(new BigNumber(item.SupplyRewardAPYArb), 18, 6), 'percent')}</i></div>
                            }
                          </span>
                        </div>
                      }
                    </td>
                    <td className="specialApy">
                      <div className="borrow">
                        <span>
                          {
                            item.borrowAPY ?
                              bnToDec(new BigNumber(item.borrowAPY).abs(), 18) > 9999.99
                                ?
                                '999,999%'
                                :
                                _formatNumberNotCarry(bnToDec(new BigNumber(item.borrowAPY), 18, 6), 'percent')
                              : '...'
                            // numeral(
                            //   numeral(
                            //     bnToDec(new BigNumber(item.borrowAPY).abs(), 18, 4)
                            //   ).format("0.0000")
                            // ).format("0,0.00%")
                          }
                        </span>
                      </div>
                      {
                        (item.BorrowRewardAPY && item.MinimumBorrowRewardAPY && item.DailyDistribution !== '0') &&
                        <div className="APR">
                          <img src="/Unitus.svg" />
                          <span>
                            {
                              bnToDec(new BigNumber(item.BorrowRewardAPY).abs(), 18) > 9999.99
                                ?
                                <div>{_formatNumberNotCarry(bnToDec(new BigNumber(item.MinimumBorrowRewardAPY), 18, 6), 'percent')}~<i>999,999%</i></div>
                                :
                                <div>{_formatNumberNotCarry(bnToDec(new BigNumber(item.MinimumBorrowRewardAPY), 18, 6), 'percent')}~<i>{_formatNumberNotCarry(bnToDec(new BigNumber(item.BorrowRewardAPY), 18, 6), 'percent')}</i></div>
                            }
                          </span>
                        </div>
                        // :<div className="APR"><span>...</span></div>
                      }

                      {item.BorrowRewardAPYArb && item.MinimumBorrowRewardAPYArb && item.DailyDistributionArb !== '0' &&
                        <div className="APR">
                          <img src="/ARB.svg" />
                          <span>
                            {
                              bnToDec(new BigNumber(item.BorrowRewardAPYArb).abs(), 18) > 9999.99
                                ?
                                <div>{_formatNumberNotCarry(bnToDec(new BigNumber(item.MinimumBorrowRewardAPYArb), 18, 6), 'percent')}~<i>999,999%</i></div>
                                :
                                <div>{_formatNumberNotCarry(bnToDec(new BigNumber(item.MinimumBorrowRewardAPYArb), 18, 6), 'percent')}~<i>{_formatNumberNotCarry(bnToDec(new BigNumber(item.BorrowRewardAPYArb), 18, 6), 'percent')}</i></div>
                            }
                          </span>
                        </div>
                      }
                    </td>
                    <td className="marketSize">
                      <div>
                        {
                          item.supplyAmount ?
                            bnToDec(new BigNumber(item.supplyAmount), 18) > 0 &&
                              bnToDec(new BigNumber(item.supplyAmount), 18) < 0.01
                              ? "<0.01"
                              : formate_round_up(
                                numeral(
                                  bnToDec(new BigNumber(item.supplyAmount), 18)
                                )
                                  .format("0.000a")
                                  .toLocaleUpperCase()
                              )
                            : '...'
                        }
                      </div>
                      <div className="bottomAPY">
                        {
                          item.supplyAmount && item.price
                            ? '$' + formate_round_up(
                              numeral(bnToDec(new BigNumber(
                                new BigNumber(item.supplyAmount).multipliedBy(new BigNumber(item.price))
                              ), 36))
                                .format("0.000a")
                                .toLocaleUpperCase()
                            )
                            : '...'


                          // item.supplyValue ?
                          // item.supplyValue
                          // bnToDec(new BigNumber(item.supplyValue), 18) > 0 &&
                          //   bnToDec(new BigNumber(item.supplyValue), 18) < 0.01
                          //   ? "<0.01"
                          //   : formate_round_up(
                          //     numeral(
                          //       bnToDec(new BigNumber(item.supplyValue), 18)
                          //     )
                          //       .format("$0.000a")
                          //       .toLocaleUpperCase()
                          //   )
                        }
                      </div>
                    </td>
                    <td className="liquidity">
                      <div>
                        {
                          item.cashAmount ?
                            formate_round_up(
                              numeral(bnToDec(new BigNumber(item.cashAmount), 18))
                                .format("0.000a")
                                .toLocaleUpperCase()
                            )
                            : '...'
                        }
                      </div>
                      <div className="bottomAPY">
                        {
                          item.cash ?
                            formate_round_up(
                              numeral(bnToDec(new BigNumber(item.cash), 18))
                                .format("$0.000a")
                                .toLocaleUpperCase()
                            )
                            : '...'
                        }
                      </div>
                    </td>
                    <td className="balance">
                      {/* {
                        ? "..."
                        : item.balance === 0 ||
                          (item.balance && Number(item.balance) >= 0.01)
                          ? numeral(item.balance).format("0,0.00")
                          : "< 0.01"} */}
                      {
                        item.balance === "..."
                          ? "..."
                          : item.balance
                            ? _formatNumberNotCarry(bnToDec(new BigNumber(item.balance), 0, 2), 'abbr')
                            // ? numberFormatter((item.balance).toString(), 2)
                            // ? item.balance
                            : '0.00'
                      }
                    </td>
                    <td className="Collateral">
                      {/* {
                        item.AsCollateralDetails
                        ? item.AsCollateralDetails[1]
                          ? <div>✅</div>
                          : <div>🔴</div>
                        :''  
                      } */}
                      {renderCollateralDetails(item)}
                      {
                        item.isSegregationAssert &&
                        <FlexBox>
                          <div className="bottomAPY">
                            Segregated
                          </div>
                          <a data-tip data-for={'tips001111'} style={{ marginLeft: '5px' }}>
                            <img src={tooltip} alt='' style={{ marginBottom: '4px' }} />
                          </a>
                          <ReactTooltip
                            id={"tips001111"}
                            place="top"
                            type="light"
                            effect="float"
                            className="tooltip"
                            textColor={`${(props: any) => props.theme.operationPage.grey}`}
                            borderColor={"#E8ECEF"}
                            border={true}
                          >
                            <TooltipSpan>
                              {'Segregated assets have limited borrowing power and other assets cannot be used as collateral at the same time.'}
                            </TooltipSpan>
                          </ReactTooltip>
                        </FlexBox>
                      }
                    </td>
                    <td className="lastTd">
                      {
                        account &&
                          item.balance != "..."
                          ? <>
                            <RouterLink
                              className={'supply'}
                              to={`/lending/lend/supply-${item.reveal_symbol}`}
                            >
                              <>{fmt({ id: "SUPPLY" })}</>
                            </RouterLink>

                            <RouterLink
                              className={marketsData && marketsData[item.address]?.canBorrow ? "borrow" : "borrowBan"}
                              to={
                                item.status === 3
                                  ? `/lending/loan/repay-${item.reveal_symbol}`
                                  : `/lending/loan/borrow-${item.reveal_symbol}`
                              }
                              onClick={(e) => clickEvent(e, item.status)}
                              data-tip={item.status === 0 ? true : false}
                              data-for={item.status === 0 ? `${item.reveal_symbol}` : ""}
                            >
                              {/* {item.status + ' - '} */}
                              {/* {item.address} */}
                              {
                                item.status === 0
                                  ? <>
                                    <>{fmt({ id: "BORROW" })}</>
                                    <ReactTooltip
                                      className="tooltip"
                                      place="top"
                                      type="light"
                                      effect="float"
                                      textColor={"#9195A4"}
                                      borderColor={"#E8ECEF"}
                                      border={true}
                                      id={`${item.reveal_symbol}`}
                                    >
                                      <span>{fmt({ id: "toolTip" })}</span>
                                    </ReactTooltip>
                                  </>
                                  : item.status === 2
                                    ? fmt({ id: "WITHDRAW" })
                                    : item.status === 3
                                      ? fmt({ id: "REPAY" })
                                      : marketsData &&
                                        marketsData[item.address]?.canBorrow
                                        ? fmt({ id: "BORROW" })
                                        : fmt({ id: "BORROW" }) // 'no'
                              }
                            </RouterLink>
                          </>
                          : <>
                            <button>{fmt({ id: "SUPPLY" })}</button>
                            <button>{fmt({ id: "BORROW" })}</button>
                          </>
                      }
                    </td>
                  </tr>
                )
              })
              : Array.from({ length: 5 }).map((index) => (
                <tr key={Math.random()}>
                  <td className="firstTd">
                    <TokenIconWrap className="TokenIconBg" />
                    <TokenSymbol className="TokenSymbolBg"></TokenSymbol>
                  </td>
                  <td className="specialApy">
                    <div className="supply">
                      <span>...</span>
                    </div>
                    <div className="APR">
                      <span>...</span>
                    </div>
                  </td>
                  <td className="specialApy">
                    <div className="borrow">
                      <span>...</span>
                    </div>
                    <div className="APR">
                      <span>...</span>
                    </div>
                  </td>
                  <td className="marketSize">
                    <div>
                      ...
                    </div>
                    <div className="bottomAPY">
                      ...
                    </div>
                  </td>
                  <td className="liquidity">
                    <div>
                      ...
                    </div>
                    <div className="bottomAPY">
                      ...
                    </div>
                  </td>
                  <td className="balance">...</td>
                  <td className="lastTd">
                    <button>{fmt({ id: "SUPPLY" })}</button>
                    <button>{fmt({ id: "BORROW" })}</button>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        <TableMobile>
          <thead>
            <TypeTitle>
              {fmt({ id: "topbar_Markets" })}
              {
                isHasFrozen &&
                <FreezeBox>
                  <label htmlFor={"toggle-btn"}><span>Show Frozen Assets</span></label>
                  <input
                    className="switch"
                    type="checkbox"
                    id={"toggle-btn"}
                    checked={showFreeze ? true : false}
                    onChange={(e) => { checkboxChange(e.target.checked) }} />
                </FreezeBox>
              }
            </TypeTitle>
            {/* <tr>
              <TheadBar>
                <SwitchTabs onClick={(e) => switchTabHandler(e)}>
                  <SwitchItem className={"active"} data-type="all">
                    {fmt({ id: "ALL" })}
                  </SwitchItem>
                  {DeployAssetsType.filter((item) => item === "MainCryptos")
                    .length > 0 && (
                      <SwitchItem data-type="MainCryptos">
                        {fmt({ id: "MainCryptos" })}
                      </SwitchItem>
                    )}
                  {DeployAssetsType.filter((item) => item === "Synth").length >
                    0 && (
                      <SwitchItem data-type="Synth">
                        {fmt({ id: "Synth" })}
                      </SwitchItem>
                    )}
                  {DeployAssetsType.filter((item) => item === "LP").length >
                    0 && (
                      <SwitchItem data-type="LP">{fmt({ id: "LP" })}</SwitchItem>
                    )}
                  {DeployAssetsType.filter((item) => item === "Stable").length >
                    0 && (
                      <SwitchItem data-type="Stable">
                        {fmt({ id: "Stable" })}
                      </SwitchItem>
                    )}
                </SwitchTabs>
              </TheadBar>
            </tr> */}
            <tr>
              <th className="firstTh">{fmt({ id: "Markets_Assets" })}</th>
              <th>
                <SortArea
                  onClick={(): void =>
                    sortData(supplyAPY, setSupplyAPY, "supplyAPY")
                  }
                >
                  {/* {fmt({ id: "supply_apy" })} */}
                  <div className="specialApy">
                    <div>
                      <span>{fmt({ id: "Supply_APY" })}</span>
                    </div>
                    <div className="bottomAPY">
                      <span>{fmt({ id: "Reward_to_MAX" })}</span>
                    </div>
                  </div>
                  <i className={"active" + supplyAPY}></i>
                </SortArea>
              </th>
              <th>
                <SortArea
                  onClick={(): void =>
                    sortData(borrowAPY, setBorrowAPY, "borrowAPY")
                  }
                >
                  <div className="specialApy">
                    <div>
                      <span>{fmt({ id: "Borrow_APY" })}</span>
                    </div>
                    <div className="bottomAPY">
                      <span>{fmt({ id: "Reward_to_MAX" })}</span>
                    </div>
                  </div>
                  <i className={"active" + borrowAPY}></i>
                </SortArea>
              </th>
              {/* <th onClick={():void => sortData(cash,setCash,"cash")}>{fmt({ id: "Liquidity" })}<i className={"active"+cash}></i></th> */}
              {/* <th>
                <SortArea
                  onClick={(): void =>
                    sortData(marketSize, setMarketSize, "supplyValue")
                  }
                >
                  {fmt({ id: "Market_Size" })}
                  <i className={"active" + marketSize}></i>
                </SortArea>
              </th> */}
              {/* <th className="flex13" onClick={(): void => sortData(balance, setbalance, "balance")}>{fmt({ id: "Wallet" })}<i className={"active" + balance}></i></th> */}
            </tr>
          </thead>
          <tbody>
            {render_markets.length > 0
              ? render_markets.map((item) => {
                if (item.isFreeze) { return false }
                return (
                  <tr key={item.reveal_symbol}>
                    <td className="firstTd">
                      <TokenIconWrap>
                        {/* {item.isFreeze && <StyledFreeze>Frozen</StyledFreeze>} */}
                        <TokenIcon src={item.icon} />
                      </TokenIconWrap>
                      <TokenRight>
                        <TokenSymbol>
                          {item.reveal_symbol}
                          {item.tokenType && renderLink(item.tokenType)}
                        </TokenSymbol>
                      </TokenRight>
                    </td>
                    <td className="specialApy">
                      <div className="supply">
                        <span>
                          {
                            item.supplyAPY ?
                              bnToDec(new BigNumber(item.supplyAPY).abs(), 18) > 9999.99
                                ?
                                '999,999%'
                                :
                                _formatNumberNotCarry(bnToDec(new BigNumber(item.supplyAPY), 18, 6), 'percent')
                              : '...'
                            // numeral(
                            //   numeral(
                            //     bnToDec(new BigNumber(item.mainingSupplyApy), 18, 4)
                            //   ).format("0.0000")
                            // ).format("0,0.00%")
                          }
                        </span>
                      </div>
                      {
                        (item.SupplyRewardAPY && item.MinimumSupplyRewardAPY && item.DailyDistribution !== '0') &&
                        <div className="APR">
                          <img src="/Unitus.svg" />
                          <span>
                            {
                              bnToDec(new BigNumber(item.SupplyRewardAPY).abs(), 18) > 9999.99
                                ?
                                <div>{_formatNumberNotCarry(bnToDec(new BigNumber(item.MinimumSupplyRewardAPY), 18, 6), 'percent')}~<i>999,999%</i></div>
                                :
                                <div>{_formatNumberNotCarry(bnToDec(new BigNumber(item.MinimumSupplyRewardAPY), 18, 6), 'percent')}~<i>{_formatNumberNotCarry(bnToDec(new BigNumber(item.SupplyRewardAPY), 18, 6), 'percent')}</i></div>
                            }
                          </span>
                        </div>
                        // :<div className="APR"><span>...</span></div>
                      }
                      {
                        (item.SupplyRewardAPYArb && item.MinimumSupplyRewardAPYArb && item.DailyDistributionArb !== '0') &&
                        <div className="APR">
                          <img src="/ARB.svg" />
                          <span>
                            {
                              bnToDec(new BigNumber(item.SupplyRewardAPYArb).abs(), 18) > 9999.99
                                ?
                                <div>{_formatNumberNotCarry(bnToDec(new BigNumber(item.MinimumSupplyRewardAPYArb), 18, 6), 'percent')}~<i>999,999%</i></div>
                                :
                                <div>{_formatNumberNotCarry(bnToDec(new BigNumber(item.MinimumSupplyRewardAPYArb), 18, 6), 'percent')}~<i>{_formatNumberNotCarry(bnToDec(new BigNumber(item.SupplyRewardAPYArb), 18, 6), 'percent')}</i></div>
                            }
                          </span>
                        </div>
                      }
                    </td>
                    <td className="specialApy">
                      <div className="borrow">
                        <span>
                          {
                            item.borrowAPY ?
                              bnToDec(new BigNumber(item.borrowAPY).abs(), 18) > 9999.99
                                ?
                                '999,999%'
                                :
                                _formatNumberNotCarry(bnToDec(new BigNumber(item.borrowAPY ?? '0'), 18, 6), 'percent')
                              : '...'
                            // numeral(
                            //   numeral(
                            //     bnToDec(new BigNumber(item.mainingBorrowApy).abs(), 18, 4)
                            //   ).format("0.0000")
                            // ).format("0,0.00%")
                          }
                        </span>
                      </div>
                      {
                        (item.BorrowRewardAPY && item.MinimumBorrowRewardAPY && item.DailyDistribution !== '0') &&
                        <div className="APR">
                          <img src="/Unitus.svg" />
                          <span>
                            {
                              bnToDec(new BigNumber(item.BorrowRewardAPY).abs(), 18) > 9999.99
                                ?
                                <div>{_formatNumberNotCarry(bnToDec(new BigNumber(item.MinimumBorrowRewardAPY), 18, 6), 'percent')}~<i>999,999%</i></div>
                                :
                                <div>{_formatNumberNotCarry(bnToDec(new BigNumber(item.MinimumBorrowRewardAPY), 18, 6), 'percent')}~<i>{_formatNumberNotCarry(bnToDec(new BigNumber(item.BorrowRewardAPY), 18, 6), 'percent')}</i></div>
                            }
                          </span>
                        </div>
                        // :<div className="APR"><span>...</span></div>
                      }

                      {item.BorrowRewardAPYArb && item.MinimumBorrowRewardAPYArb && item.DailyDistributionArb !== '0' &&
                        <div className="APR">
                          <img src="/ARB.svg" />
                          <span>
                            {
                              bnToDec(new BigNumber(item.BorrowRewardAPYArb).abs(), 18) > 9999.99
                                ?
                                <div>{_formatNumberNotCarry(bnToDec(new BigNumber(item.MinimumBorrowRewardAPYArb), 18, 6), 'percent')}~<i>999,999%</i></div>
                                :
                                <div>{_formatNumberNotCarry(bnToDec(new BigNumber(item.MinimumBorrowRewardAPYArb), 18, 6), 'percent')}~<i>{_formatNumberNotCarry(bnToDec(new BigNumber(item.BorrowRewardAPYArb), 18, 6), 'percent')}</i></div>
                            }
                          </span>
                        </div>
                      }
                    </td>
                    {/* <td className="marketSize">
                          {bnToDec(new BigNumber(item.supplyValue), 18) > 0 &&
                          bnToDec(new BigNumber(item.supplyValue), 18) < 0.01
                            ? "<0.01"
                            : formate_round_up(
                                numeral(
                                  bnToDec(new BigNumber(item.supplyValue), 18)
                                )
                                  .format("$0.000a")
                                  .toLocaleUpperCase()
                              )}
                        </td> */}
                    <td className="lastTd">
                      {account && item.balance != "..." ? (
                        <>
                          <RouterLink
                            className={
                              item.status || item.status === 0
                                ? item.status === 3
                                  ? "borrow"
                                  : "supply"
                                : ""
                            }
                            to={
                              item.status === 3
                                ? `/lending/loan/borrow-${item.reveal_symbol}`
                                : `/lending/lend/supply-${item.reveal_symbol}`
                            }
                          >
                            {item.status === 3 ? (
                              <>{fmt({ id: "BORROW" })}</>
                            ) : (
                              <>{fmt({ id: "SUPPLY" })}</>
                            )}
                          </RouterLink>
                          <RouterLink
                            className={
                              item.status === undefined || item.status === 0
                                ? "NoSupply"
                                : item.status === 1
                                  ? "borrow"
                                  : item.status === 2
                                    ? "OnlySupply"
                                    : "Supply_Borrow"
                            }
                            to={
                              item.status === 1
                                ? `/lending/loan/borrow-${item.reveal_symbol}`
                                : item.status === 2
                                  ? `/lending/lend/withdraw-${item.reveal_symbol}`
                                  : item.status === 3
                                    ? `/lending/loan/repay-${item.reveal_symbol}`
                                    : ""
                            }
                            onClick={(e) => clickEvent(e, item.status)}
                            data-tip={item.status === 0 ? true : false}
                            data-for={
                              item.status === 0 ? `${item.reveal_symbol}` : ""
                            }
                          >
                            {item.status === 0 ? (
                              <>
                                <>{fmt({ id: "BORROW" })}</>
                                <ReactTooltip
                                  className="tooltip"
                                  place="top"
                                  type="light"
                                  effect="float"
                                  textColor={"#9195A4"}
                                  // borderColor={'#E8ECEF'}
                                  // border={true}
                                  id={`${item.reveal_symbol}`}
                                >
                                  <span>{fmt({ id: "toolTip" })}</span>
                                </ReactTooltip>
                              </>
                            ) : item.status === 2 ? (
                              fmt({ id: "WITHDRAW" })
                            ) : item.status === 3 ? (
                              fmt({ id: "REPAY" })
                            ) : (
                              fmt({ id: "BORROW" })
                            )}
                          </RouterLink>
                        </>
                      ) : (
                        <>
                          <button>{fmt({ id: "SUPPLY" })}</button>
                          <button>{fmt({ id: "BORROW" })}</button>
                        </>
                      )}
                    </td>
                  </tr>
                )
              })
              : Array.from({ length: 5 }).map((index) => (
                <tr key={Math.random()}>
                  <td className="firstTd">
                    <TokenIconWrap className="TokenIconBg" />
                    <TokenRight>
                      <TokenSymbol className="TokenSymbolBg"></TokenSymbol>
                      {/* <TokenBalance>...</TokenBalance> */}
                    </TokenRight>
                  </td>
                  <td className="specialApy">
                    <div className="supply">
                      <span>...</span>
                    </div>
                    <div className="APR">
                      <span>...</span>
                    </div>
                  </td>
                  <td className="specialApy">
                    <div className="borrow">
                      <span>...</span>
                    </div>
                    <div className="APR">
                      <span>...</span>
                    </div>
                  </td>
                  {/* <td>...</td> */}
                  <td className="lastTd">
                    <button>{fmt({ id: "SUPPLY" })}</button>
                    <button>{fmt({ id: "BORROW" })}</button>
                  </td>
                </tr>
              ))}
          </tbody>
        </TableMobile>
      </TableWrap>

      {
        showFreeze && isHasFrozen &&
        <TableWrap>
          <Table>
            <thead>
              <TypeTitle>
                {/* {fmt({ id: "topbar_Markets" })} */}
                {'Frozen Markets'}
              </TypeTitle>
              <TypeTitle>
                <FreezeBox className="moblie">
                  These assets are frozen or paused, further supply and borrow are unavailable, withdraw and repay are allowed.
                </FreezeBox>
              </TypeTitle>

              <tr>
                <th className="firstTh">{fmt({ id: "Markets_Assets" })}</th>
                <th>
                  <SortArea
                    onClick={(): void =>
                      sortData(supplyAPY, setSupplyAPY, "supplyAPY")
                    }
                  >
                    {/* {fmt({ id: "supply_apy" })} */}
                    <div className="specialApy">
                      <div>
                        <span>{fmt({ id: "Supply_APY" })}</span>
                      </div>
                      <div>
                        <span>{fmt({ id: "Reward_to_MAX" })}</span>
                      </div>
                    </div>
                    <i className={"active" + supplyAPY}></i>
                  </SortArea>
                </th>
                <th>
                  <SortArea
                    onClick={(): void =>
                      sortData(borrowAPY, setBorrowAPY, "borrowAPY")
                    }
                  >
                    {/* {fmt({ id: "borrow_apy" })} */}
                    <div className="specialApy">
                      <div>
                        <span>{fmt({ id: "Borrow_APY" })}</span>
                      </div>
                      <div>
                        <span>{fmt({ id: "Reward_to_MAX" })}</span>
                      </div>
                    </div>
                    <i className={"active" + borrowAPY}></i>
                  </SortArea>
                </th>
                <th>
                  <SortArea
                    onClick={(): void =>
                      sortData(marketSize, setMarketSize, "supplyValue")
                    }
                  >
                    {fmt({ id: "Market_Size" })}
                    <i className={"active" + marketSize}></i>
                  </SortArea>
                </th>
                <th>
                  <SortArea onClick={(): void => sortData(cash, setCash, "cash")}>
                    {fmt({ id: "Liquidity" })}
                    <i className={"active" + cash}></i>
                  </SortArea>
                </th>
                <th className="balance">
                  <SortArea
                    onClick={(): void => sortData(balance, setbalance, "balance")}
                  >
                    {fmt({ id: "Wallet" })}
                    <i className={"active" + balance}></i>
                  </SortArea>
                </th>
                <th className="lastTh"></th>
              </tr>
            </thead>
            <tbody>
              {render_markets.length > 0 &&
                render_markets.map((item) => {
                  if (item.isFreeze && showFreeze) {
                    // console.log(item)
                    return (
                      <tr key={item.address}>
                        <td className="firstTd">
                          <TokenIconWrap>
                            {/* {item.isFreeze && <StyledFreeze>Frozen</StyledFreeze>} */}
                            <TokenIcon src={item.icon} />
                          </TokenIconWrap>
                          <TokenSymbol>
                            {item.reveal_symbol}
                            {item.tokenType && renderLink(item.tokenType)}
                          </TokenSymbol>
                        </td>
                        <td className="specialApy">
                          <div className="supply">
                            <span>
                              {
                                item.supplyAPY ?
                                  bnToDec(new BigNumber(item.supplyAPY).abs(), 18) > 9999.99
                                    ?
                                    '999,999%'
                                    :
                                    _formatNumberNotCarry(bnToDec(new BigNumber(item.supplyAPY), 18, 6), 'percent')
                                  : '...'
                                // numeral(
                                //   numeral(
                                //     bnToDec(new BigNumber(item.mainingSupplyApy), 18, 4)
                                //   ).format("0.0000")
                                // ).format("0,0.00%")
                              }
                            </span>
                          </div>
                          {
                            (item.SupplyRewardAPY && item.MinimumSupplyRewardAPY && item.DailyDistribution !== '0') &&
                            <div className="APR">
                              <img src="/Unitus.svg" />
                              <span>
                                {
                                  bnToDec(new BigNumber(item.SupplyRewardAPY).abs(), 18) > 9999.99
                                    ?
                                    <div>{_formatNumberNotCarry(bnToDec(new BigNumber(item.MinimumSupplyRewardAPY), 18, 6), 'percent')}~<i>999,999%</i></div>
                                    :
                                    <div>{_formatNumberNotCarry(bnToDec(new BigNumber(item.MinimumSupplyRewardAPY), 18, 6), 'percent')}~<i>{_formatNumberNotCarry(bnToDec(new BigNumber(item.SupplyRewardAPY), 18, 6), 'percent')}</i></div>
                                }
                              </span>
                            </div>
                            // :<div className="APR"><span>...</span></div>
                          }
                          {
                            (item.SupplyRewardAPYArb && item.MinimumSupplyRewardAPYArb && item.DailyDistributionArb !== '0') &&
                            <div className="APR">
                              <img src="/ARB.svg" />
                              <span>
                                {
                                  bnToDec(new BigNumber(item.SupplyRewardAPYArb).abs(), 18) > 9999.99
                                    ?
                                    <div>{_formatNumberNotCarry(bnToDec(new BigNumber(item.MinimumSupplyRewardAPYArb), 18, 6), 'percent')}~<i>999,999%</i></div>
                                    :
                                    <div>{_formatNumberNotCarry(bnToDec(new BigNumber(item.MinimumSupplyRewardAPYArb), 18, 6), 'percent')}~<i>{_formatNumberNotCarry(bnToDec(new BigNumber(item.SupplyRewardAPYArb), 18, 6), 'percent')}</i></div>
                                }
                              </span>
                            </div>
                          }
                        </td>
                        <td className="specialApy">
                          <div className="borrow">
                            <span>
                              {
                                item.borrowAPY ?
                                  bnToDec(new BigNumber(item.borrowAPY).abs(), 18) > 9999.99
                                    ?
                                    '999,999%'
                                    :
                                    _formatNumberNotCarry(bnToDec(new BigNumber(item.borrowAPY), 18, 6), 'percent')
                                  : '...'
                                // numeral(
                                //   numeral(
                                //     bnToDec(new BigNumber(item.borrowAPY).abs(), 18, 4)
                                //   ).format("0.0000")
                                // ).format("0,0.00%")
                              }
                            </span>
                          </div>
                          {
                            (item.BorrowRewardAPY && item.MinimumBorrowRewardAPY && item.DailyDistribution !== '0') &&
                            <div className="APR">
                              <img src="/Unitus.svg" />
                              <span>
                                {
                                  bnToDec(new BigNumber(item.BorrowRewardAPY).abs(), 18) > 9999.99
                                    ?
                                    <div>{_formatNumberNotCarry(bnToDec(new BigNumber(item.MinimumBorrowRewardAPY), 18, 6), 'percent')}~<i>999,999%</i></div>
                                    :
                                    <div>{_formatNumberNotCarry(bnToDec(new BigNumber(item.MinimumBorrowRewardAPY), 18, 6), 'percent')}~<i>{_formatNumberNotCarry(bnToDec(new BigNumber(item.BorrowRewardAPY), 18, 6), 'percent')}</i></div>
                                }
                              </span>
                            </div>
                            // :<div className="APR"><span>...</span></div>
                          }

                          {item.BorrowRewardAPYArb && item.MinimumBorrowRewardAPYArb && item.DailyDistributionArb !== '0' &&
                            <div className="APR">
                              <img src="/ARB.svg" />
                              <span>
                                {
                                  bnToDec(new BigNumber(item.BorrowRewardAPYArb).abs(), 18) > 9999.99
                                    ?
                                    <div>{_formatNumberNotCarry(bnToDec(new BigNumber(item.MinimumBorrowRewardAPYArb), 18, 6), 'percent')}~<i>999,999%</i></div>
                                    :
                                    <div>{_formatNumberNotCarry(bnToDec(new BigNumber(item.MinimumBorrowRewardAPYArb), 18, 6), 'percent')}~<i>{_formatNumberNotCarry(bnToDec(new BigNumber(item.BorrowRewardAPYArb), 18, 6), 'percent')}</i></div>
                                }
                              </span>
                            </div>
                          }
                        </td>
                        <td className="marketSize">
                          <div>
                            {
                              item.supplyAmount ?
                                bnToDec(new BigNumber(item.supplyAmount), 18) > 0 &&
                                  bnToDec(new BigNumber(item.supplyAmount), 18) < 0.01
                                  ? "<0.01"
                                  : formate_round_up(
                                    numeral(
                                      bnToDec(new BigNumber(item.supplyAmount), 18)
                                    )
                                      .format("0.000a")
                                      .toLocaleUpperCase()
                                  )
                                : '...'
                            }
                          </div>
                          <div className="bottomAPY">
                            {
                              item.supplyValue ?
                                bnToDec(new BigNumber(item.supplyValue), 18) > 0 &&
                                  bnToDec(new BigNumber(item.supplyValue), 18) < 0.01
                                  ? "<0.01"
                                  : formate_round_up(
                                    numeral(
                                      bnToDec(new BigNumber(item.supplyValue), 18)
                                    )
                                      .format("$0.000a")
                                      .toLocaleUpperCase()
                                  )
                                : '...'
                            }
                          </div>
                        </td>
                        <td className="liquidity">
                          <div>
                            {
                              item.cashAmount ?
                                formate_round_up(
                                  numeral(bnToDec(new BigNumber(item.cashAmount), 18))
                                    .format("0.000a")
                                    .toLocaleUpperCase()
                                )
                                : '...'
                            }
                          </div>
                          <div className="bottomAPY">
                            {
                              item.cash ?
                                formate_round_up(
                                  numeral(bnToDec(new BigNumber(item.cash), 18))
                                    .format("$0.000a")
                                    .toLocaleUpperCase()
                                )
                                : '...'
                            }
                          </div>
                        </td>
                        <td className="balance">
                          {/* {
                        ? "..."
                        : item.balance === 0 ||
                          (item.balance && Number(item.balance) >= 0.01)
                          ? numeral(item.balance).format("0,0.00")
                          : "< 0.01"} */}
                          {
                            item.balance === "..."
                              ? "..."
                              : item.balance
                                ? _formatNumberNotCarry(bnToDec(new BigNumber(item.balance), 0, 2), 'abbr')
                                // ? numberFormatter((item.balance).toString(), 2)
                                : '0.00'
                          }
                        </td>
                        <td className="lastTd">
                          {account && item.balance != "..." ? (
                            <>
                              <RouterLink
                                className={
                                  item.status || item.status === 0
                                    ? item.status === 3
                                      ? "borrow"
                                      : "supply"
                                    : ""
                                }
                                to={
                                  item.status === 3
                                    ? `/lending/loan/borrow-${item.reveal_symbol}`
                                    : `/lending/lend/supply-${item.reveal_symbol}`
                                }
                              >
                                {item.status === 3 ? (
                                  <>{fmt({ id: "BORROW" })}</>
                                ) : (
                                  <>{fmt({ id: "SUPPLY" })}</>
                                )}
                              </RouterLink>
                              <RouterLink
                                className={
                                  item.status === undefined || item.status === 0
                                    ? "NoSupply"
                                    : item.status === 1
                                      ? "borrow"
                                      : item.status === 2
                                        ? "OnlySupply"
                                        : "Supply_Borrow"
                                }
                                to={
                                  item.status === 1
                                    ? `/lending/loan/borrow-${item.reveal_symbol}`
                                    : item.status === 2
                                      ? `/lending/lend/withdraw-${item.reveal_symbol}`
                                      : item.status === 3
                                        ? `/lending/loan/repay-${item.reveal_symbol}`
                                        : ""
                                }
                                onClick={(e) => clickEvent(e, item.status)}
                                data-tip={item.status === 0 ? true : false}
                                data-for={
                                  item.status === 0 ? `${item.reveal_symbol}` : ""
                                }
                              >
                                {item.status === 0 ? (
                                  <>
                                    <>{fmt({ id: "BORROW" })}</>
                                    <ReactTooltip
                                      className="tooltip"
                                      place="top"
                                      type="light"
                                      effect="float"
                                      textColor={"#9195A4"}
                                      borderColor={"#E8ECEF"}
                                      border={true}
                                      id={`${item.reveal_symbol}`}
                                    >
                                      <span>{fmt({ id: "toolTip" })}</span>
                                    </ReactTooltip>
                                  </>
                                ) : item.status === 2 ? (
                                  fmt({ id: "WITHDRAW" })
                                ) : item.status === 3 ? (
                                  fmt({ id: "REPAY" })
                                ) : (
                                  fmt({ id: "BORROW" })
                                )}
                              </RouterLink>
                            </>
                          ) : (
                            <>
                              <button>{fmt({ id: "SUPPLY" })}</button>
                              <button>{fmt({ id: "BORROW" })}</button>
                            </>
                          )}
                        </td>
                      </tr>
                    )
                  }
                })}
            </tbody>
          </Table>
          <TableMobile>
            <thead>
              <TypeTitle>
                {/* {fmt({ id: "topbar_Markets" })} */}
                {'Frozen Markets'}
              </TypeTitle>
              <TypeTitle>
                <FreezeBox className="moblie">
                  These assets are frozen or paused, further supply and borrow are unavailable, withdraw and repay are allowed.
                </FreezeBox>
              </TypeTitle>

              <tr>
                <th className="firstTh">{fmt({ id: "Markets_Assets" })}</th>
                <th>
                  <SortArea
                    onClick={(): void =>
                      sortData(supplyAPY, setSupplyAPY, "supplyAPY")
                    }
                  >
                    {/* {fmt({ id: "supply_apy" })} */}
                    <div className="specialApy">
                      <div>
                        <span>{fmt({ id: "Supply_APY" })}</span>
                      </div>
                      <div className="bottomAPY">
                        <span>{fmt({ id: "Reward_to_MAX" })}</span>
                      </div>
                    </div>
                    <i className={"active" + supplyAPY}></i>
                  </SortArea>
                </th>
                <th>
                  <SortArea
                    onClick={(): void =>
                      sortData(borrowAPY, setBorrowAPY, "borrowAPY")
                    }
                  >
                    <div className="specialApy">
                      <div>
                        <span>{fmt({ id: "Borrow_APY" })}</span>
                      </div>
                      <div className="bottomAPY">
                        <span>{fmt({ id: "Reward_to_MAX" })}</span>
                      </div>
                    </div>
                    <i className={"active" + borrowAPY}></i>
                  </SortArea>
                </th>
                {/* <th onClick={():void => sortData(cash,setCash,"cash")}>{fmt({ id: "Liquidity" })}<i className={"active"+cash}></i></th> */}
                {/* <th>
                <SortArea
                  onClick={(): void =>
                    sortData(marketSize, setMarketSize, "supplyValue")
                  }
                >
                  {fmt({ id: "Market_Size" })}
                  <i className={"active" + marketSize}></i>
                </SortArea>
              </th> */}
                {/* <th className="flex13" onClick={(): void => sortData(balance, setbalance, "balance")}>{fmt({ id: "Wallet" })}<i className={"active" + balance}></i></th> */}
              </tr>
            </thead>
            <tbody>
              {render_markets.length > 0 &&
                render_markets.map((item) => {
                  if (item.isFreeze && showFreeze) {
                    return (
                      <tr key={item.reveal_symbol}>
                        <td className="firstTd">
                          <TokenIconWrap>
                            {/* {item.isFreeze && <StyledFreeze>Frozen</StyledFreeze>} */}
                            <TokenIcon src={item.icon} />
                          </TokenIconWrap>
                          <TokenRight>
                            <TokenSymbol>
                              {item.reveal_symbol}
                              {item.tokenType && renderLink(item.tokenType)}
                            </TokenSymbol>
                          </TokenRight>
                        </td>
                        <td className="specialApy">
                          <div className="supply">
                            <span>
                              {
                                item.supplyAPY ?
                                  bnToDec(new BigNumber(item.supplyAPY).abs(), 18) > 9999.99
                                    ?
                                    '999,999%'
                                    :
                                    _formatNumberNotCarry(bnToDec(new BigNumber(item.supplyAPY), 18, 6), 'percent')
                                  : '...'
                                // numeral(
                                //   numeral(
                                //     bnToDec(new BigNumber(item.mainingSupplyApy), 18, 4)
                                //   ).format("0.0000")
                                // ).format("0,0.00%")
                              }
                            </span>
                          </div>
                          {
                            (item.SupplyRewardAPY && item.MinimumSupplyRewardAPY && item.DailyDistribution !== '0') &&
                            <div className="APR">
                              <img src="/Unitus.svg" />
                              <span>
                                {
                                  bnToDec(new BigNumber(item.SupplyRewardAPY).abs(), 18) > 9999.99
                                    ?
                                    <div>{_formatNumberNotCarry(bnToDec(new BigNumber(item.MinimumSupplyRewardAPY), 18, 6), 'percent')}~<i>999,999%</i></div>
                                    :
                                    <div>{_formatNumberNotCarry(bnToDec(new BigNumber(item.MinimumSupplyRewardAPY), 18, 6), 'percent')}~<i>{_formatNumberNotCarry(bnToDec(new BigNumber(item.SupplyRewardAPY), 18, 6), 'percent')}</i></div>
                                }
                              </span>
                            </div>
                            // :<div className="APR"><span>...</span></div>
                          }
                          {
                            (item.SupplyRewardAPYArb && item.MinimumSupplyRewardAPYArb && item.DailyDistributionArb !== '0') &&
                            <div className="APR">
                              <img src="/ARB.svg" />
                              <span>
                                {
                                  bnToDec(new BigNumber(item.SupplyRewardAPYArb).abs(), 18) > 9999.99
                                    ?
                                    <div>{_formatNumberNotCarry(bnToDec(new BigNumber(item.MinimumSupplyRewardAPYArb), 18, 6), 'percent')}~<i>999,999%</i></div>
                                    :
                                    <div>{_formatNumberNotCarry(bnToDec(new BigNumber(item.MinimumSupplyRewardAPYArb), 18, 6), 'percent')}~<i>{_formatNumberNotCarry(bnToDec(new BigNumber(item.SupplyRewardAPYArb), 18, 6), 'percent')}</i></div>
                                }
                              </span>
                            </div>
                          }
                        </td>
                        <td className="specialApy">
                          <div className="borrow">
                            <span>
                              {
                                item.borrowAPY ?
                                  bnToDec(new BigNumber(item.borrowAPY).abs(), 18) > 9999.99
                                    ?
                                    '999,999%'
                                    :
                                    _formatNumberNotCarry(bnToDec(new BigNumber(item.borrowAPY ?? '0'), 18, 6), 'percent')
                                  : '...'
                                // numeral(
                                //   numeral(
                                //     bnToDec(new BigNumber(item.mainingBorrowApy).abs(), 18, 4)
                                //   ).format("0.0000")
                                // ).format("0,0.00%")
                              }
                            </span>
                          </div>
                          {
                            (item.BorrowRewardAPY && item.MinimumBorrowRewardAPY && item.DailyDistribution !== '0') &&
                            <div className="APR">
                              <img src="/Unitus.svg" />
                              <span>
                                {
                                  bnToDec(new BigNumber(item.BorrowRewardAPY).abs(), 18) > 9999.99
                                    ?
                                    <div>{_formatNumberNotCarry(bnToDec(new BigNumber(item.MinimumBorrowRewardAPY), 18, 6), 'percent')}~<i>999,999%</i></div>
                                    :
                                    <div>{_formatNumberNotCarry(bnToDec(new BigNumber(item.MinimumBorrowRewardAPY), 18, 6), 'percent')}~<i>{_formatNumberNotCarry(bnToDec(new BigNumber(item.BorrowRewardAPY), 18, 6), 'percent')}</i></div>
                                }
                              </span>
                            </div>
                            // :<div className="APR"><span>...</span></div>
                          }

                          {item.BorrowRewardAPYArb && item.MinimumBorrowRewardAPYArb && item.DailyDistributionArb !== '0' &&
                            <div className="APR">
                              <img src="/ARB.svg" />
                              <span>
                                {
                                  bnToDec(new BigNumber(item.BorrowRewardAPYArb).abs(), 18) > 9999.99
                                    ?
                                    <div>{_formatNumberNotCarry(bnToDec(new BigNumber(item.MinimumBorrowRewardAPYArb), 18, 6), 'percent')}~<i>999,999%</i></div>
                                    :
                                    <div>{_formatNumberNotCarry(bnToDec(new BigNumber(item.MinimumBorrowRewardAPYArb), 18, 6), 'percent')}~<i>{_formatNumberNotCarry(bnToDec(new BigNumber(item.BorrowRewardAPYArb), 18, 6), 'percent')}</i></div>
                                }
                              </span>
                            </div>
                          }
                        </td>
                        {/* <td className="marketSize">
                          {bnToDec(new BigNumber(item.supplyValue), 18) > 0 &&
                          bnToDec(new BigNumber(item.supplyValue), 18) < 0.01
                            ? "<0.01"
                            : formate_round_up(
                                numeral(
                                  bnToDec(new BigNumber(item.supplyValue), 18)
                                )
                                  .format("$0.000a")
                                  .toLocaleUpperCase()
                              )}
                        </td> */}
                        <td className="lastTd">
                          {account && item.balance != "..." ? (
                            <>
                              <RouterLink
                                className={
                                  item.status || item.status === 0
                                    ? item.status === 3
                                      ? "borrow"
                                      : "supply"
                                    : ""
                                }
                                to={
                                  item.status === 3
                                    ? `/lending/loan/borrow-${item.reveal_symbol}`
                                    : `/lending/lend/supply-${item.reveal_symbol}`
                                }
                              >
                                {item.status === 3 ? (
                                  <>{fmt({ id: "BORROW" })}</>
                                ) : (
                                  <>{fmt({ id: "SUPPLY" })}</>
                                )}
                              </RouterLink>
                              <RouterLink
                                className={
                                  item.status === undefined || item.status === 0
                                    ? "NoSupply"
                                    : item.status === 1
                                      ? "borrow"
                                      : item.status === 2
                                        ? "OnlySupply"
                                        : "Supply_Borrow"
                                }
                                to={
                                  item.status === 1
                                    ? `/lending/loan/borrow-${item.reveal_symbol}`
                                    : item.status === 2
                                      ? `/lending/lend/withdraw-${item.reveal_symbol}`
                                      : item.status === 3
                                        ? `/lending/loan/repay-${item.reveal_symbol}`
                                        : ""
                                }
                                onClick={(e) => clickEvent(e, item.status)}
                                data-tip={item.status === 0 ? true : false}
                                data-for={
                                  item.status === 0 ? `${item.reveal_symbol}` : ""
                                }
                              >
                                {item.status === 0 ? (
                                  <>
                                    <>{fmt({ id: "BORROW" })}</>
                                    <ReactTooltip
                                      className="tooltip"
                                      place="top"
                                      type="light"
                                      effect="float"
                                      textColor={"#9195A4"}
                                      // borderColor={'#E8ECEF'}
                                      // border={true}
                                      id={`${item.reveal_symbol}`}
                                    >
                                      <span>{fmt({ id: "toolTip" })}</span>
                                    </ReactTooltip>
                                  </>
                                ) : item.status === 2 ? (
                                  fmt({ id: "WITHDRAW" })
                                ) : item.status === 3 ? (
                                  fmt({ id: "REPAY" })
                                ) : (
                                  fmt({ id: "BORROW" })
                                )}
                              </RouterLink>
                            </>
                          ) : (
                            <>
                              <button>{fmt({ id: "SUPPLY" })}</button>
                              <button>{fmt({ id: "BORROW" })}</button>
                            </>
                          )}
                        </td>
                      </tr>
                    )
                  }
                })
              }
            </tbody>
          </TableMobile>
        </TableWrap>
      }
    </>
  );
};


const FlexBox = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size:12px;
  color:#999;
`
const TooltipSpan = styled.span` 
  color: rgb(145, 149, 164);
  font-weight: 300;
  font-size: 12px;
  text-align: left!important;
`
const flash = keyframes`
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
`;
const TableWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  .pcTable {
    margin-right: 15px;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    margin-top: 15px;
    .mTable {
      margin-bottom: 15px;
      thead {
        padding-top: 10px;
        tr {
          th {
            font-size: 14px;
            line-height: inherit;
          }
        }
      }
      tr {
        padding: 0;
        th,
        td {
          line-height: 55px;
        }
      }
    }
  }
  table {
    flex-grow: 1;
  }
`;
const Table = styled.table`
  width: max-content;
  height: max-content;
  border-bottom: 0;
  border-radius: 16px;
  border-spacing: inherit;
  color: ${(props) => props.theme.DarkfontColor[100]};
  background: ${(props) => props.theme.colors.white};
  .dark & {
    color: ${(props) => props.theme.fontColor[100]};
    background: ${(props) => props.theme.box_bg_night};
  }
  font-size:14px;
  font-weight: bold;
  overflow: auto !important;
  thead {
    color: ${(props) => props.theme.colors.lightGrey};
    tr {
      /* border-bottom: 0; */
      .isHoverLink & {
        &:hover {
          background: inherit;
          &:before {
            content: "";
            border-left: 0;
            height: 0;
            position: absolute;
            left: 0;
            top: 0;
          }
        }
      }
      &:first-child {
        padding: 0;
        height: auto;
        line-height: inherit;
      }
      &:nth-child(2) {
        height:64px;
        border-bottom: 1px solid
          ${(props) => props.theme.colors.lightTableBorder};
        .dark & {
          border-bottom: 1px solid
            ${(props) => props.theme.colors.darkTableBorder};
        }
      }
    }
  }
  /* 解决嵌套样式最后子元素 */
  a {
    display: block;
    position: relative;
    .isHoverLink & {
      &:hover {
        cursor: pointer;
        color: ${(props) => props.theme.DarkfontColor[100]};
        background: ${(props) => props.theme.colors.lightLinkBg};
        &:before {
          content: "";
          width: 4px;
          height: 70px;
          position: absolute;
          left: 0;
          top: 0;
          background: ${(props) => props.theme.colors.supply.dark};
          .dark & {
            background: ${(props) => props.theme.colors.navLinklinearBg};
          }
        }
        .dark & {
          color: ${(props) => props.theme.fontColor[100]};
          background: ${(props) => props.theme.colors.darkLinkBg};
        }
      }
    }
  }
  tr:last-child {
    /* border-bottom: 0; */
  }
  a tr {
    border-bottom: 1px solid
      ${(props) => props.theme.colors.lightTableBorder}!important;
    .dark & {
      border-bottom: 1px solid
        ${(props) => props.theme.colors.darkTableBorder}!important;
    }
  }
  a:last-child > tr {
    border-bottom: 0 !important;
  }
  /* 解决嵌套样式最后子元素 */
  tr {
    position: relative;
    padding: 8px 0;
    margin: 0 24px;
    text-align: right;
    height: auto;
    min-height: 85px;
    /* line-height: 54px; */
    box-sizing: border-box;
    border-bottom: 1px solid ${(props) => props.theme.colors.lightTableBorder};
    .dark & {
      border-bottom: 1px solid ${(props) => props.theme.colors.darkTableBorder};
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    th,
    td {
      flex: 1;
    }
    th {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-weight: normal;
      font-size: 14px;
      /* color: red; */
      font-weight: 600;
      &.balance{
        flex: none;
        width: 100px;
      }
      &.Collateral{
        flex: none;
        width: 120px !important;
      }
      i {
        display: inline-flex;
        flex-direction: column;
        margin-left: 5px;
        &:before {
          content: "";
          display: inline-block;
          height: 0px;
          width: 0px;
          border-right: 4px solid transparent;
          border-left: 4px solid transparent;
          border-bottom: 4px solid ${(props) => props.theme.colors.lightGrey};
          margin-bottom: 2px;
        }
        &:after {
          content: "";
          display: inline-block;
          height: 0px;
          width: 0px;
          border-right: 4px solid transparent;
          border-left: 4px solid transparent;
          border-top: 4px solid ${(props) => props.theme.colors.lightGrey};
        }
        &.active1 {
          &:after {
            border-top: 4px solid ${(props) => props.theme.colors.supply.dark};
          }
        }
        &.active-1 {
          &:before {
            border-bottom: 4px solid
              ${(props) => props.theme.colors.supply.dark};
          }
        }
      }
    }
    td{
      .supply{
        color:${(props) => props.theme.borrow};
      }
      .borrow{
        color:rgb(246, 61, 61)
      }
      &.balance{
        flex: none;
        width: 100px;
      }
      &.Collateral{
        flex: none;
        width: 120px!important;
        /* background-color: red; */
        text-align: center;
      }
      &.specialApy,&.marketSize,&.liquidity {
        display: flex;
        flex-direction: column;
        justify-content: center;
        div {
          line-height: 24px;
          span {
            display: inline-block;
            &.green{
              color:${(props) => props.theme.colors.borrow.dark};
            }
            &.red{
              color:${(props) => props.theme.colors.red};
            }
          }
          &.bottomAPY {
            color:${(props) => props.theme.fontColor[999]};
            &:first-child {
              margin-left: 5px;
            }
          }
          &.APR {
            display: flex;
            justify-content: end;
            align-items: center;
            font-size: 12px;
            &:first-child {
              margin-left: 5px;
            }
            i{
              font-style: normal;
              /* color: ${(props) => props.theme.colors.black};
              .dark & {
                color: ${(props) => props.theme.colors.white};
              } */
            }
            img{
              display: inline-block;
              width: 15px;
              height: 15px;
              margin-right: 5px;
            }
          }
        }
      }
    }
    .lastTh,
    .lastTd {
      /* flex: 2; */
      width: 280px;
      flex: none;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-right: -8px;
      button {
        width: 40%;
        height: 40px;
        line-height: 35px;
        margin-right: 12px;
        background: ${(props) => props.theme.colors.borrow.normal};
        color: ${(props) => props.theme.colors.lightGrey};
        font-family:LendingFont;
        font-weight: 600;
        font-size: 14px;
        border-radius: 8px;
        @media (max-width: 1199px) {
          border-radius: 4px;
        }
        cursor: not-allowed;
      }
    }
    .firstTh,
    .firstTd {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      text-align: left;
      width: 120px;
      flex: none;
    }
  }
  @media (max-width: 767px) {
    display: none;
  }
`;
const TheadBar = styled.th`
  ${TableWrap} table tr & {
    width: 100%;
    padding: 18px 0 10px 0;
    line-height: 40px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    flex: inherit;
    align-items: inherit;
    @media (max-width: 767px) {
      /* flex-direction:column;
      padding: 14px 11px 0;
      line-height:30px; */
      flex-direction: column;
      padding: 0;
      text-align: left;
      p {
        margin: 0;
        line-height:35px;
      }
      ul {
        margin: 10px 0;
        line-height: 30px;
      }
    }
  }
`;
const SortArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: inherit;
  text-align:right;
  cursor: pointer;
  .specialApy {
        display: flex;
        flex-direction: column;
        justify-content: center;
        div {
          line-height: 20px;
          span {
            display: inline-block;
          }
          &.bottomAPY {
            color: #9195a4;
            font-size: 14px;
            &:first-child {
              margin-left: 5px;
            }
          }
        }
      }
      @media (max-width: 767px) {
        &.specialApy {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          div {
            line-height: 20px;
            span {
              display: inline-block;
            }
            &.bottomAPY {
              color: #9195a4;
              font-size: 12px;
              &:first-child {
                margin-left: 5px;
              }
            }
          }
        }
      }
`;
const StyledFreeze = styled.div`
  position: absolute;
  color: #FF0E0E;
  background-color: #FFDEDE;
  /* border: 2px solid tomato; */
  left: -20px;
  top: -20px;
  font-size: 12px;
  font-weight: 600;
  height: auto;
  line-height: 1;
  padding: 3px;
  font-weight: 700;
  border-radius: 2px;
  margin: 4px 0 0 10px;
`;
const BarTitle = styled.p`
  color: ${(props) => props.theme.DarkfontColor[100]};
  .dark & {
    color: ${(props) => props.theme.fontColor[100]};
  }
  font-weight: bold;
`;
const SwitchTabs = styled.ul`
  display: flex;
  height: 40px;
  /* color: ${(props) => props.theme.colors.lightGrey}; */
  background: ${(props) => props.theme.colors.lightOpacityGrey};
  .dark & {
    background: ${(props) => props.theme.colors.darkOpacityGrey};
  }
  border-radius: 20px;
  @media (max-width: 767px) {
    width: max-content;
    height: 30px;
  }
`;
const SwitchItem = styled.li`
  padding: 0 24px;
  background: transparent;
  cursor: pointer;
  &.active {
    font-weight: 600;
    background: ${(props) => props.theme.colors.linearBg};
    border-radius: 20px;
    color: ${(props) => props.theme.fontColor[100]};
  }
  @media (max-width: 767px) {
    padding: 0 8px;
  }
`;
const TokenSymbol = styled.span`
  display: inline-block;
  min-width: 50px;
  /* height: 17px; */
  line-height: 17px;
  &.TokenSymbolBg {
    height: 17px;
    animation: ${flash} 1.2s linear infinite forwards;
    background: #e1e5ee;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 800px 100px;
    position: relative;
  }
  @media (max-width: 767px) {
    /* height: 17px; */
    line-height: 17px;
  }
`;
const FreezeText = styled.span`
  display:flex!important;
  align-items:center;
  color:${(props) => props.theme.colors.supply.dark};
  font-size:12px;
  font-weight:normal;
  line-height:22px;
  cursor: pointer;
  @media (max-width: 767px) {
    font-size:14px;
  }
`
const LidoLink = styled.a`
  display:flex!important;
  align-items:center;
  color:${(props) => props.theme.colors.supply.dark};
  font-size:12px;
  font-weight:normal;
  line-height:22px;
  cursor: pointer;
  &:hover{
    color:${(props) => props.theme.colors.supply.dark}!important;
    background:none!important;
    opacity:0.8!important;
    &:before{
      display:none
    }
  }
  &:focus{
    color:${(props) => props.theme.colors.supply.dark};
  }
  @media (max-width: 767px) {
    font-size:14px;
  }
`
const LidoIcon = styled.img`
  width:9px;
  height:13px;
  margin-left:2px;
`
const AddToken = styled.img`
  display: inline-block;
  color:#fff;
  padding: 10px;
  box-sizing: border-box;
`;
const TokenIconWrap = styled.div`
  width: 32px;
  min-width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 10px;
  position: relative;
  &.TokenIconBg {
    border-radius: 50%;
    animation: ${flash} 1.2s linear infinite forwards;
    background: #e1e5ee;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 800px 100px;
    position: relative;
  }
`;
const TokenIcon = styled.img`
  display: block;
  width: 32px;
  height: 32px;
`;
const RouterLink = styled(NavLink)`
  width: 40%;
  height: 40px;
  line-height: 40px;
  margin-right: 12px;
  text-align: center;
  font-family:LendingFont;
  font-weight: 600;
  font-size: 14px;
  background: #eff1f4;
  color: #9fa4b3 !important;
  border-radius: 8px;
  @media (max-width: 1199px) {
    border-radius: 4px;
  }
  // cursor: not-allowed;
  // pointer-events: none;
  &.tooltip {
    font-size: 14px;
    line-height: 21px;
    max-width: 140px;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.12);
  }
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  will-change: transform;
  opacity: 1;
  &:hover {
    opacity: 0.8;
  }
  &.supply {
    color: ${(props) => props.theme.colors.white}!important;
    background-color: ${(props) => props.theme.colors.supply.dark};
  }
  &.borrow {
    color: ${(props) => props.theme.colors.white}!important;
    background-color: ${(props) => props.theme.colors.borrow.dark};
  }
  &.NoSupply {
    background: #eff1f4;
    color: #9fa4b3 !important;
  }
  &.borrowBan {
    background: #eff1f4;
    color: #9fa4b3 !important;
    pointer-events: none;
  }
  &.OnlySupply {
    color: ${(props) => props.theme.colors.supply.dark}!important;
    background-color: ${(props) => props.theme.colors.supply.light};
    cursor: pointer;
    pointer-events: auto;
  }
  &.Supply_Borrow {
    color: ${(props) => props.theme.colors.borrow.dark}!important;
    background-color: ${(props) => props.theme.colors.borrow.light};
    cursor: pointer;
    pointer-events: auto;
  }
  @media (max-width: 767px) {
    margin-right: 0;
    width: 45%;
    height: 30px;
    line-height: 30px;
  }
`;
const TableMobile = styled.table`
  display: none;
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    border-bottom: 0;
    border-radius: 10px;
    border-spacing: inherit;
    color: ${(props) => props.theme.DarkfontColor[100]};
    background: ${(props) => props.theme.colors.white};
    .dark & {
      color: ${(props) => props.theme.fontColor[100]};
      background: ${(props) => props.theme.DarkfontColor[100]};
    }
    font-size: 14px;
    font-weight: bold;
    overflow: auto !important;
    thead {
      color: ${(props) => props.theme.colors.lightGrey};
      tr {
        padding: 8px 4px;
        font-size: 14px;
        cursor: pointer;
        .isHoverLink & {
          &:hover {
            background: inherit;
            &:before {
              content: "";
              border-left: 0;
              height: 0;
              position: absolute;
              left: 0;
              top: 0;
            }
          }
        }
        &:first-child {
          /* border-bottom: 0; */
          padding: 0;
          height: auto;
          line-height: inherit;
        }
        &:nth-child(2) {
          height:64px;
          border-bottom: 1px solid
            ${(props) => props.theme.colors.lightTableBorder};
          .dark & {
            border-bottom: 1px solid
              ${(props) => props.theme.colors.darkTableBorder};
          }
        }
      }
    }
    /* 解决嵌套样式最后子元素 */
    a {
      display: block;
      position: relative;
      .isHoverLink & {
        &:hover {
          cursor: pointer;
          color: ${(props) => props.theme.DarkfontColor[100]};
          background: ${(props) => props.theme.colors.lightLinkBg};
          &:before {
            content: "";
            width: 4px;
            height: 55px;
            position: absolute;
            left: 0;
            top: 0;
            background: ${(props) => props.theme.colors.supply.dark};
            .dark & {
              background: ${(props) => props.theme.colors.navLinklinearBg};
            }
          }
          .dark & {
            color: ${(props) => props.theme.fontColor[100]};
            background: ${(props) => props.theme.colors.darkLinkBg};
          }
        }
      }
    }
    tr:last-child {
      /* border-bottom: 0; */
    }
    a tr {
      border-bottom: 1px solid
        ${(props) => props.theme.colors.lightTableBorder}!important;
      .dark & {
        border-bottom: 1px solid
          ${(props) => props.theme.colors.darkTableBorder}!important;
      }
    }
    a:last-child > tr {
      border-bottom: 0 !important;
    }
    /* 解决嵌套样式最后子元素 */
    tr {
      position: relative;
      padding: 14px 0 0;
      margin: 0 10px;
      text-align: right;
      height: auto;
      border-bottom: 1px solid ${(props) => props.theme.colors.lightTableBorder};
      .dark & {
        border-bottom: 1px solid
          ${(props) => props.theme.colors.darkTableBorder};
      }
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      box-sizing: border-box;
      th,
      td {
        flex: 1;
      }
      th {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        text-align: right;
        font-weight: normal;
        &.flex13 {
          flex: 1.5;
        }
        i {
          display: inline-flex;
          flex-direction: column;
          margin-left: 5px;
          &:before {
            content: "";
            display: inline-block;
            height: 0px;
            width: 0px;
            border-right: 4px solid transparent;
            border-left: 4px solid transparent;
            border-bottom: 4px solid ${(props) => props.theme.colors.lightGrey};
            margin-bottom: 2px;
          }
          &:after {
            content: "";
            display: inline-block;
            height: 0px;
            width: 0px;
            border-right: 4px solid transparent;
            border-left: 4px solid transparent;
            border-top: 4px solid ${(props) => props.theme.colors.lightGrey};
          }
          &.active1 {
            &:after {
              border-top: 4px solid ${(props) => props.theme.colors.supply.dark};
            }
          }
          &.active-1 {
            &:before {
              border-bottom: 4px solid
                ${(props) => props.theme.colors.supply.dark};
            }
          }
        }
      }
      td {
        line-height: 30px;
        &.flex13 {
          flex: 1.5;
        }
        .supply{
          color:${(props) => props.theme.borrow};
        }
        .borrow{
          color:rgb(246, 61, 61)
        }
        &.Collateral{
          /* width: 40px; */
        }
        &.specialApy,&.marketSize,&.liquidity {
          display: flex;
          flex-direction: column;
          justify-content: center;
          div {
            font-size: 10px;
            line-height: 24px;
            span {
              display: inline-block;
              &.green{
                color:${(props) => props.theme.colors.borrow.dark};
              }
              &.red{
                color:${(props) => props.theme.colors.red};
              }
            }
            &.bottomAPY {
              font-size: 16px;
              &:first-child {
                margin-left: 5px;
              }
            }
          }
        }
        .APR {
          display: flex;
            justify-content: end;
            align-items: center;
          font-size: 10px;
            i{
              font-style: normal;
            }
            img{
              display: inline-block;
              width: 15px;
              height: 15px;
              margin-right: 5px;
            }
          }
      }
      .lastTh,
      .lastTd {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: none;
        margin-top: 15px;
        padding-bottom: 15px;
        button {
          width: 45%;
          height: 30px;
          line-height: 20px;
          background: ${(props) => props.theme.colors.borrow.normal};
          color: ${(props) => props.theme.colors.lightGrey};
          font-family:LendingFont;
          font-weight: 600;
          font-size: 14px;
          cursor: not-allowed;
          border-radius: 8px;
          @media (max-width: 1199px) {
            border-radius: 4px;
          }
        }
      }
      .firstTh,
      .firstTd {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        text-align: left;
        width: 80px;
        flex: none;
      }
    }
  }
`;
const TokenRight = styled.div`
  height: 30px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
`;
const TokenBalance = styled.span`
  height: 15px;
  line-height: 15px;
  @media (max-width: 767px) {
    font-size: 12px;
    color: ${(props) => props.theme.colors.lightGrey};
  }
`;
const TrLink = styled(NavLink)`
  display: block;
  color: ${(props) => props.theme.DarkfontColor[100]};
  .dark & {
    color: ${(props) => props.theme.fontColor[100]};
  }
`;

const FreezeBox = styled.div`
  font-size: 14px;
  margin-left: 44px;

  &.moblie{
    margin-left: 0px;
    line-height: 22px;
    color: #9195a4;
    @media (max-width: 1199px) {
    }
  }
`
const TypeTitle = styled.div`
  color: #131313;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 60px;
  padding-left: 24px;
  display: flex;
  .dark & {
    color: #FFF;
  }
  @media (max-width: 1199px) {
    padding-left: 12px;
  }
`
export default TokenList;
