import React from "react";
import styled from "styled-components";


const StyledLeftSection = ({ children }: {
    children: React.ReactNode
  }) => {
    return (
        <StyledLeft>
            {children}
        </StyledLeft>
    );
}

const StyledLeft = styled.div`
    width: 736px;
    padding-right: 20px;
    border-right: 1px solid ${(props) => props.theme.operationPage.light.borderColor};
    .dark & {
        border-right: 1px solid ${(props) => props.theme.operationPage.dark.borderColor};
    }

    @media (max-width: 1119px) {
        width: 100%;
        border-right: none;
        padding-right: 0px;
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid ${(props) => props.theme.operationPage.light.borderColor};
        .dark & {
        border-right: none;
        border-bottom: 1px solid ${(props) => props.theme.operationPage.dark.borderColor};
        }
    }
`;

export default StyledLeftSection

