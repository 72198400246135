/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useState, useEffect } from 'react'
import { Link, NavLink, useLocation } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { useIntl } from "react-intl";
import { useWeb3React } from '@web3-react/core'
import { useQuery, _formatNumberNotCarry, bnToDec } from 'utils';
import { network_Name, add_Notifies, update_Notifies, del_Notify, del__cancel } from 'lending-sdk/utils/index1'
import { fetchMining } from "./fetchMining"
import Page from 'components/Page'
import general_DL_Token from 'lending-sdk/lib/clean_build/contracts/general/DL_Token.json';
import synthetic_DL_Token from 'lending-sdk/lib/clean_build/contracts/synthetic/DL_Token.json';
import stocks_DL_Token from 'lending-sdk/lib/clean_build/contracts/stocks/DL_Token.json';
import MarketDistribution from './components/MarketDistribution';
import AvailableClaim from './components/AvailableClaim'
import MiningVolume from './components/MiningVolume'
import TotalAssets from '../../components/_TotalAssets'
import TotalAssetsArb from '../../components/_TotalAssetsArb'
import Notification from "components/Notification";
import Notification__cancel from "components/Notification__cancel";
import linkTo from 'assets/MiningLink.svg'
import BigNumber from 'bignumber.js';

declare global {
  interface Window {
    IntervalFetchApi: any;
  }
}
const Mining: React.FC = () => {
  const intl = useIntl();
  const fmt = intl.formatMessage;
  const { pathname } = useLocation();
  const {
    connector,
    account,
    chainId,
    provider,
    ENSName,
    isActive,
    isActivating,
  } = useWeb3React();

  const [Distribution, setDistribution] = useState<any[]>([])

  const [rewardData, setRewardData] = useState<any>(undefined)
  const [rewardDataArb, setRewardDataArb] = useState<any>(undefined)

  const [decimals, setDecimals] = useState('')
  const [reward_TokenSymbol, setReward_TokenSymbol] = useState('')
  // const [disabeldClaim, setDisabeldClaim] = useState<boolean>(true)
  // notify tips
  const [nowNotifies, setNowNotifies] = useState<any>([]);
  const [show__cancel, setShow__cancel] = useState<boolean>(false);
  // notify tips
  let query = useQuery()
  const fetchApi = useCallback(async (chain_id: string | number) => {
    const {
      supplyMaining,
      rewardTokenDecimals,
      rewardTokenSymbol,
      // disabeldClaim,
      rewardData,
      rewardDataArb
    }: any = await fetchMining(network_Name[chain_id])
    setDistribution(supplyMaining)
    setRewardData(rewardData)
    setRewardDataArb(rewardDataArb)
    setDecimals(rewardTokenDecimals)
    setReward_TokenSymbol(rewardTokenSymbol)
    // setDisabeldClaim(disabeldClaim)
  }, [chainId])
  useEffect(() => {
      setDistribution([])
      setRewardData(undefined)
      setDecimals('')
      setReward_TokenSymbol('')
      // setDisabeldClaim(true)
      if (chainId) {
        fetchApi(chainId)
      }
      window.IntervalFetchApi = setInterval(() => {
        fetchApi(chainId as number)
      }, 1000 * 15)
      return () => {
        clearInterval(window.IntervalFetchApi)
      }
  }, [chainId, account])

  return (
    <>
      <Notification
        chainID={chainId}
        notifies={nowNotifies}
        setNowNotifies={setNowNotifies}
        del_Notify={del_Notify}
      />
      <Notification__cancel
        notifies={show__cancel}
        setShow__cancel={setShow__cancel}
        del_Notify={del__cancel}
      />
      {/* <MiningVolume 
      DailyDistribution={DailyDistribution} 
      TotalDistribution={TotalDistribution}
      TotalHasbeenDistributed={TotalHasbeenDistributed}
      Remaining={Remaining}  
      totalAssetsTextList={totalAssetsTextList} rewardTokenDecimals={decimals} 
      rewardTokenSymbol={reward_TokenSymbol}/> */}
      <TopSection>
        <TitleWrap>
          <Title>
            <MainTitle>{fmt({ id: 'Title1' })}</MainTitle>
            <SubTitle>{fmt({ id: 'Title2' })}
            &nbsp;<a href='https://unitus.gitbook.io/unitus/overview/liquidity-mining-faq' target="_blank" rel="noreferrer">{fmt({ id: 'LearnMore' })}</a>
            </SubTitle>
          </Title>
          {/* {
            (rewardData?.TotalStakePrePair?.length > 0) &&
            <TitleData>
              <>
                <Idata>
                  <Id>{
                    // formate_round_up(
                    //   numeral(
                    //     rewardData.TotalStake
                    //   ).format("0,0.000a")
                    //     .toLocaleUpperCase()
                    // )
                    '$' + _formatNumberNotCarry(bnToDec(new BigNumber(rewardData.TotalStake ?? '0'), 18, 4), 'abbr')
                  }</Id>
                  <It>{fmt({ id: 'TotalStake' })}</It>
                </Idata>
                {
                  rewardData.TotalStakePrePair?.filter((item: any) => (item.ChainId === chainId) || (item.ChainId === 0))?.map((item: any) =>
                  (
                    <Idata>
                      <Id>{
                        // formate_round_up(
                        //   numeral(
                        //     item.Value
                        //   ).format("0,0.000a")
                        //     .toLocaleUpperCase()
                        // )
                        '$' + _formatNumberNotCarry(bnToDec(new BigNumber(item.Value ?? '0'), item.Decimals, 4), 'abbr')
                      }</Id>
                      <It>{item.Symbol}</It>
                    </Idata>
                  )
                  )
                }
                <StakeLinkWarp>
                  <StakeLink to={"/BLP"}>
                    <span>{fmt({ id: 'Stake' })}</span>
                    <Icon src={linkTo} />
                  </StakeLink>
                </StakeLinkWarp>
              </>
            </TitleData>
          } */}
        </TitleWrap>
        {
          (chainId === 5 || chainId === 42161) ? 
          <TotalAssetsArb
            totalAssets={[
              { title: "DailyDistribution_Distributed", value1: rewardData?.DailyDistribution,value2:rewardData?.Distributed },
              { title: "DailyDistribution_Distributed", value1: rewardDataArb?.DailyDistribution,value2: rewardDataArb?.Distributed }
            ]}
          />
          :<TotalAssets
            totalAssets={[
              { title: "Daily_Distribution", value: rewardData?.DailyDistribution },
              { title: "Total_Distribution", value: rewardData?.Distributed }
            ]}
          />
        }
        
      </TopSection>
      <AvailableClaim
        rewardTokenDecimals={decimals}
        rewardTokenSymbol={reward_TokenSymbol}
        // disabeldClaim={disabeldClaim}
        add_Notifies={add_Notifies}
        update_Notifies={update_Notifies}
        del_Notify={del_Notify}
        setNowNotifies={setNowNotifies}
        setShow__cancel={setShow__cancel}
      />
      <MarketDistribution Distribution={Distribution} rewardTokenDecimals={decimals} />
    </>
  )
}
const TopSection = styled.div`
  height: 284px;
  padding: 30px 0;
  width: 72%;
  min-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  @media (max-width: 1199px){
    width: 100%;
    min-width:inherit;
    padding: 10px 12px;
    height: auto;
  }
`
const TitleWrap = styled.div`
  display: flex;
  flex-direction: row;
  padding: 30px 0 0;
  margin-bottom: 24px;
  color: ${(props) => props.theme.fontColor[100]};
  @media (max-width: 1199px){
    flex-direction: column;
    padding: 10px 0 0;
    margin-bottom: 12px;
  }
`
const Title = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 8px;
  @media (max-width: 1199px){
    width: 100%;
    margin: 0 auto 10px;
    /* padding: 0 12px; */
    gap: 4px;
  }
  
`
const MainTitle = styled.div`
  font-size: 24px;
  font-weight: 700;
  line-height: 40px; 
  @media (max-width: 1199px){
    font-size: 16px;
  }
`
const SubTitle = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  width: 590px;
  a{
    color: ${(props) => props.theme.fontColor[100]};
    text-decoration: underline!important;
  }
  @media (max-width: 1199px){
    font-size: 12px;
    line-height: 18px;
    width: 100%;
  }
`
const twinkling = keyframes`
  0% {
      opacity: 0.2;
      filter: alpha(opacity=20);
      transform: scale(1);
  }
  50% {
      opacity: 0.5;
      filter: alpha(opacity=50);
      transform: scale(1.12);
  }
  100% {
      opacity: 0.2;
      filter: alpha(opacity=20);
      transform: scale(1);
  }
`
const Icon = styled.img`
  display:inline-block;
  width:15px;
  height:15px;
  &.twinkling{
    animation: ${twinkling} 0.8s linear 1 forwards;
  }
`
const Idata = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  border-right: 1px solid ${(props) => props.theme.colors.darkTableBorder};
  
  /* @media (max-width: 1199px){
    flex: none;
    flex-basis: 31%;
  } */
`
const TitleData = styled.div`
  display: flex;
  flex: 1;
  padding: 24px 16px;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  /* color:${(props) => props.theme.fontColor[100]}; */
  background: ${(props) => props.theme.colors.darkTableBorder};
  ${Idata}:nth-last-of-type(2){
    border-right: none;
  }
  @media (max-width: 1199px){
    flex-wrap: wrap;
    justify-content: center;
    width: calc(100% - 24px);
    margin: 0 auto 10px;
    padding: 12px 8px;
    gap: 5px;
    background:${(props) => props.theme.TableBg};
    border-radius: 10px;
    .dark & {
      background:${(props) => props.theme.DarkTableBg};
    }
  }
`


const Id = styled.div`
  color: ${(props) => props.theme.fontColor[100]};
  font-size: 28px;
  font-weight: 700;
  line-height: 28px; /* 100% */
  @media (max-width: 1199px){
    font-size: 16px;
    color:${(props) => props.theme.DarkfontColor[100]};
    .dark & {
      color: ${(props) => props.theme.fontColor[100]};
    }
  }
  
`
const It = styled.div`
  color: ${(props) => props.theme.fontColor[60]};
  font-size: 16px;
  font-weight: 600;
  line-height: 16px; /* 100% */
  @media (max-width: 1199px){
    font-size: 14px;
    color:${(props) => props.theme.DarkfontColor[60]};
    .dark & {
      color: ${(props) => props.theme.fontColor[60]};
    }
  }
`
const StakeLinkWarp = styled.div`
  @media (max-width: 1199px){
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: 100%;
  }
`
const StakeLink = styled(NavLink)`
  display: flex;
  padding: 10px 16px;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  background: ${(props) => props.theme.supply};
  color: ${(props) => props.theme.fontColor[100]};
  font-weight: 500;
  &:hover{
    color: ${(props) => props.theme.fontColor[100]};
  }
  @media (max-width: 1199px){
    max-width: 130px;
    margin-top: 20px;
    padding: 6px 12px;
  }
`
const SwitchPool = styled.ul`
  display:flex;
  width:100%;
  height: 40px;
  line-height:40px;
  font-size:20px;
  border-bottom: 4px solid ${(props) => props.theme.colors.lightTableBorder};
  .dark & {
    border-bottom: 4px solid ${(props) => props.theme.colors.darkTableBorder};
  }
  margin:20px 0 0;
  @media (max-width: 1199px) {
    font-size:18px;
    height:34px;
    line-height:34px
  }
`
const SwitchPoolItem = styled.li`
  padding:0 24px;
  font-weight:500;
  margin-bottom:-4px;
  cursor: pointer;
  a{
    display:block;
    width:100%;
    height:100%;
    display: flex;
      justify-content: center;
      align-items: center;
  }
  &:hover{
    a{
      color: ${(props) => props.theme.colors.black};
      .dark & {
        color: ${(props) => props.theme.colors.white};
      }
      font-weight:bold
    }  
  }
  &.active{
    border-bottom: 4px solid ${(props) => props.theme.colors.supply.dark};
    a{
      color: ${(props) => props.theme.colors.black};
      .dark & {
        color: ${(props) => props.theme.colors.white};
      }
      font-weight:bold
    }
  }
  @media (max-width: 1199px) {
    padding:0 18px;
  }
`
const PoolLink = styled(NavLink)`
  color:${(props) => props.theme.colors.lightGrey};
  &:hover{
    color:${(props) => props.theme.colors.white};
  }
`
export default Mining