export const white = '#FFFFFF'
export const black = '#000'
export const red = '#F63D3D'
export const linearBg = 'linear-gradient(90deg, #5EAFE7 0%, #6E5DE7 100%)'
export const navLinklinearBg = 'linear-gradient(180deg, #5EAFE7 0%, #6E5DE7 100%)'
export const primary = '#FFBE3D'
export const alink = '#5EAFE7'

export const grey = {
  100: '#f7f2f4',
  200: '#F0E7EA',
  300: '#e2cfd5',
  400: '#476065',
  500: '#80495D',
  600: '#476065', 
  800: '#2D121B',
}

export const orange = {
  50:'#FFF3E0',
  100: '#FFF3E0',
  200: 'rgba(250, 155, 0, 0.8)',
  300: '#FF764B',
  400: '#FA8400'
}
export const green = {
  50: '#EBEDF2',
  100: '#DEEDEB',
  200: '#00BC8F',
  400: '#269D8F'
}
export const blue = {
  50: '#EBEDF2',
  100: '#5AA8E0',
  300: '#507BFC',
  400: '#507BFC'
}
export const darkGrey = '#555555'
export const lightGrey = '#9195A4'
export const titleGrey = '#F8F9FA'
export const footerGrey = '#656A7F'
export const disabledBtn = '#9fa4b3'
export const disabledBtnBg = '#eff1f4'

export const lightPageBg = '#F5F6F9'
export const darkPageBg = '#000000'
export const lightTableBorder = 'rgba(1,1,1,0.08)'
export const darkTableBorder = 'rgba(255, 255, 255, 0.08)'
export const lightOpacityGrey = 'rgba(0, 0, 0, 0.06)'
export const darkOpacityGrey = 'rgba(255, 255, 255, 0.06)'
export const lightLinkBg = 'rgba(0, 0, 0, 0.06)'
export const darkLinkBg = 'rgba(255, 255, 255, 0.06)'
export const stakeDF = '#5CACE4'
export const lockdisabledBtn = '#D1D2D7'

export const fontColor = {
  100:'#FFF',
  60:'rgba(255, 255, 255, 0.60)',
  999:'#999'
}
export const DarkfontColor = {
  100:'#131313',
  60:'rgba(0, 0, 0, 0.60)',
  999:'#999'
}
export const supply = '#FF764B'
export const borrow = '#00BC8F'
export const supplyLightBg = '#FDECE2'
export const TableBg = '#FFF'
export const DarkTableBg = '#131313'