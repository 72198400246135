import React from "react";
import styled from "styled-components";
import { useIntl } from "react-intl";
import Logo from "components/Logo";
import Twitter from "assets/footer/Twitter.svg";
import Telegram from "assets/footer/Telegram.svg";
import Medium from "assets/footer/Medium.svg";
import Discord from "assets/footer/Discord.svg";
import Github from "assets/footer/Github.svg";
interface FooterProps {
  locale: string;
}
const Footer: React.FC<FooterProps> = ({ locale }) => {
  const intl = useIntl();
  const fmt = intl.formatMessage;

  return (
    <StyledFooter>
      <StyledLogoWrapper>
        {/* <Logo /> */}
        <StyledLink>
        <a
          href="https://unitus.gitbook.io/unitus"
          target="_blank"
          rel="noopener noreferrer"
        >
          {fmt({ id: "Documentation" })}
        </a>
        <a
          href="https://github.com/dforce-network/documents/tree/master/audit_report/Lending"
          target="_blank"
          rel="noopener noreferrer"
        >
          {fmt({ id: "Audit" })}
        </a>
        <a
          href="https://unitus.gitbook.io/unitus/overview/faq"
          target="_blank"
          rel="noopener noreferrer"
        >
          {fmt({ id: "FAQ" })}
        </a>
        <a
          href="https://forum.unitus.finance/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {fmt({ id: "Forum" })}
        </a>
        </StyledLink>
      </StyledLogoWrapper>
      <StyledIconList>
                <a
                  href="https://twitter.com/unitusfi"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={Twitter} />
                </a>
                <a
                  href="https://t.me/unitusfinance"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={Telegram} />
                </a>
                <a
                  href="https://medium.com/@unitus"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={Medium} />
                </a>
                <a
                  href="https://discord.gg/b3xdKjRD3Q"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={Discord} />
                </a>
                <a
                  href="https://github.com/UnitusLabs"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={Github} />
                </a>
      </StyledIconList>
    </StyledFooter>
  );
};

const StyledFooter = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  border-top: 1px solid rgba(0, 0, 0, 0.08);
  background: ${(props) => props.theme.body_background_light};
  .dark & {
    border-top: 1px solid rgba(255, 255, 255, 0.08);
    background: ${(props) => props.theme.body_background_night};
  }
  
  @media (max-width: 1199px) {
    font-size: 16px;
    flex-direction: column;
    gap: 12px;
  }
`;
const StyledLogoWrapper = styled.div`
  display: flex;
  gap: 40px;
  @media (max-width: 1199px) {
    
  }
`
const StyledLink = styled.div`
  display: flex;
  gap: 24px;
  a{
    color: ${(props) => props.theme.fontColor[999]};
  }
  @media (max-width: 1199px) {
    
  }
`
const StyledIconList = styled.div`
  display: flex;
  gap: 16px;
  a{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: rgba(153, 153, 153, 0.2);
      .dark & {
        background: rgba(153, 153, 153, 0.2);
      }
  }
  @media (max-width: 1199px) {
    
  }
`
export default Footer;
