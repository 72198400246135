import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { useIntl } from "react-intl";
import { useWeb3React } from '@web3-react/core'
import { _formatNumberNotCarry, bnToDec, reverseCurrentChainAddressMap, useQuery } from "utils";
import numeral from "numeral";
import BigNumber from "bignumber.js";
import useLending from "hooks/useLending";
import { network_Name, multicall_target } from "lending-sdk/utils/index1";
import stake_rewardsJson from "lending-sdk/lib/clean_build/contracts/stake/rewards.json";
import multiSendAbi from "abis/multiSend.json"
import ReactTooltip from "react-tooltip";
import tooltip from "assets/supply/tooltip.svg";
// const MulticallABI = require("../../resource/abi/Multicall.json");
//         const Multicall = new web3.eth.Contract(MulticallABI, this.config.chainInfo[network].multicallAddress);

//         const RouterABI = require("../../resource/abi/Router.json");
//         const routerAddress = this.config.chainInfo[network].router;
//         const Router = new web3.eth.Contract(RouterABI, routerAddress);

//         const amount = ctx.query.amount;
//         const calls = routerPaths.map((path) => {
//             return {
//                 target: routerAddress,
//                 callData: Router.methods["getAmountsOutPath"](amount, path).encodeABI(),
//             };
//         });
//         const callRes = await Multicall.methods.tryAggregate(false, calls)

interface AvailableClaimProps {
  rewardTokenDecimals: string;
  rewardTokenSymbol: string;
  // disabeldClaim:boolean;
  add_Notifies: any;
  update_Notifies: any;
  del_Notify: any;
  setNowNotifies: React.Dispatch<React.SetStateAction<any>>;
  setShow__cancel: React.Dispatch<React.SetStateAction<boolean>>
}

const AvailableClaim: React.FC<AvailableClaimProps> = ({
  // rewardTokenDecimals,
  // rewardTokenSymbol,
  // disabeldClaim,
  add_Notifies,
  update_Notifies,
  del_Notify,
  setNowNotifies,
  setShow__cancel
}) => {
  const intl = useIntl();
  const fmt = intl.formatMessage;
  const {
    connector,
    account,
    chainId,
    provider,
    ENSName,
    isActive,
    isActivating,
  } = useWeb3React();
  const Lending = useLending();
  let query = useQuery();
  const [pendingAmount, setPendingAmount] = useState("...");
  const [RewardAmount, setRewardAmount] = useState("...");
  const initData: React.SetStateAction<any[]> = []
  const [data, setData] = useState(initData);
  const [disabledRewardBtn, setDisabledRewardBtn] = useState(false);
  // calim ARB
  const [pendingAmountArb, setPendingAmountArb] = useState<string | undefined>(undefined);
  const [RewardAmountArb, setRewardAmountArb] = useState<string | undefined>(undefined);

  const [pendingAmountLending, setPendingAmountLending] = useState<string>('0');
  const [RewardAmountLending, setRewardAmountLending] = useState<string | undefined>(undefined);
  const initDataLending: React.SetStateAction<any[]> = []
  const [dataLending, setDataLending] = useState(initDataLending);

  const [pendingAmountDFUTS, setPendingAmountDFUTS] = useState<string>('0');
  const [RewardAmountDFUTS, setRewardAmountDFUTS] = useState<string | undefined>(undefined);
  const initDataDFUTS: React.SetStateAction<any[]> = []
  const [dataDFUTS, setDataDFUTS] = useState(initDataDFUTS);

  const [pendingAmountUSXUTS, setPendingAmountUSXUTS] = useState<string>('0');
  const [RewardAmountUSXUTS, setRewardAmountUSXUTS] = useState<string | undefined>(undefined);
  const initDataUSXUTS: React.SetStateAction<any[]> = []
  const [dataUSXUTS, setDataUSXUTS] = useState(initDataUSXUTS);

  const [disabledRewardBtnArb, setDisabledRewardBtnArb] = useState(false);

  const fetchClaimAmount = useCallback(async () => {
    if (Lending && account && chainId) {
      const reverseTokenMap: Record<string, any> = { ...reverseCurrentChainAddressMap(stake_rewardsJson, chainId) }
      let localConfig_alltoken: any[] = []
      for (let [k, v] of Object.entries(reverseTokenMap)) {
        localConfig_alltoken.push({
          address: k,
          ...v
        })
      }
      // const getReward = await Lending.contracts[
      //   `${query.get("currentPool")}`
      // ]?.LendingData?.methods
      //   .getAccountRewardAmount(account)
      //   .call();
      // rewardTokenDecimals &&
      //   setRewardAmount(
      //     numeral(
      //       bnToDec(new BigNumber(getReward), Number(rewardTokenDecimals))
      //     ).format("0,0.00")
      //   );
      try {
        await fetch(`https://beta.unitus.finance/reward/earnedInfo?network=${network_Name[chainId]}&user=${account}`)
          .then(response => response.json())
          .catch(error => console.error('Error:', error))
          .then(async response => {
            // console.log('rewardData:', response);
            if (response.status === 'OK') {
              setData(response?.data)
              let ids = response?.data?.id
              let proofs = response?.data?.proof
              let amounts = response?.data?.amounts
              const dataIsValid = await Lending.contracts.mining["RewardDistributor"].methods.canClaim(ids, proofs, account, amounts).call();
              ids = ids.filter((_item: any, index: string | number) => { return dataIsValid[index] });
              proofs = proofs.filter((_item: any, index: string | number) => { return dataIsValid[index] });
              amounts = amounts.filter((_item: any, index: string | number) => { return dataIsValid[index] });
              setPendingAmount(response?.data?.unrealizedReward)
              const _rewardAmount = amounts.length > 0 ? amounts.reduce((prev: any, cur: any) => {
                return (new BigNumber(prev).plus(new BigNumber(cur))).toString();
              })
                : '0'
              setRewardAmount(_rewardAmount)
              setData([ids, proofs, account, amounts])
            } else {
              setData(initData)
            }
          });
      } catch (error) {
        console.log(error)
      }
      if (chainId === 5 || chainId === 42161) {
        try {
          await fetch(`https://beta.unitus.finance/rewardArb/earnedInfo?network=${network_Name[chainId]}&user=${account}`)
            .then(response => response.json())
            .catch(error => console.error('Error:', error))
            .then(async response => {
              // console.log('rewardData:', response);
              if (response.status === 'OK') {
                //1、 lending reward
                let amounts = response?.data?.amounts.filter((item: any) =>item!=='0')
                let ids = response?.data?.id.filter((item: any,index:number) =>response?.data?.amounts[index]!=='0')
                let proofs = response?.data?.proof.filter((item: any,index:number) =>response?.data?.amounts[index]!=='0')
                const dataIsValid = await Lending.contracts.mining["RewardDistributorArb"].methods.canClaim(ids, proofs, account, amounts).call();
                ids = ids.filter((_item: any, index: string | number) => { return dataIsValid[index] });
                proofs = proofs.filter((_item: any, index: string | number) => { return dataIsValid[index] });
                amounts = amounts.filter((_item: any, index: string | number) => { return dataIsValid[index] });
                const rewardAmount = amounts.length > 0 ? amounts.reduce((prev: any, cur: any) => {
                  return (new BigNumber(prev).plus(new BigNumber(cur))).toString();
                })
                  : '0'
                
                //2、 DF-UTS reward
                let _rewardAmount = '0',_dataDFUTS: React.SetStateAction<any[]> = [],__rewardAmount='0',_dataUSXUTS: React.SetStateAction<any[]> = [];
                const DFUTS = response?.data?.claimARBData.filter((item: any) => item.stakeAddress === localConfig_alltoken.filter(it => it.symbol === 'DF-UTS')[0]?.address)[0]
                if (DFUTS) {
                  let _amounts = DFUTS?.amounts.filter((item: any) =>item!=='0')
                  let _ids = DFUTS?.id.filter((item: any,index:number) =>DFUTS?.amounts[index]!=='0')
                  let _proofs = DFUTS?.proof.filter((item: any,index:number) =>DFUTS?.amounts[index]!=='0')
                  const _dataIsValid = await Lending.contracts.mining["RewardDistributorDF-UTS"].methods.canClaim(_ids, _proofs, account, _amounts).call();
                  _ids = _ids.filter((_item: any, index: string | number) => { return _dataIsValid[index] });
                  _proofs = _proofs.filter((_item: any, index: string | number) => { return _dataIsValid[index] });
                  _amounts = _amounts.filter((_item: any, index: string | number) => { return _dataIsValid[index] });
                  _dataDFUTS = [_ids, _proofs, account, _amounts]
                  _rewardAmount = _amounts.length > 0 ? _amounts.reduce((prev: any, cur: any) => {
                    return (new BigNumber(prev).plus(new BigNumber(cur))).toString();
                  })
                    : '0' 
                }

                //1、 USX-UTS reward
                const USXUTS = response?.data?.claimARBData.filter((item: any) => item.stakeAddress === localConfig_alltoken.filter(it => it.symbol === 'USX-UTS')[0]?.address)[0]
                if (USXUTS) {
                  let __amounts = USXUTS?.amounts?.filter((item: any) =>item!=='0')
                  let __ids = USXUTS?.id.filter((item: any,index:number) =>USXUTS?.amounts[index]!=='0')
                  let __proofs = USXUTS?.proof.filter((item: any,index:number) =>USXUTS?.amounts[index]!=='0')
                  const __dataIsValid = await Lending.contracts.mining["RewardDistributorUSX-UTS"].methods.canClaim(__ids, __proofs, account, __amounts).call();
                  __ids = __ids.filter((_item: any, index: string | number) => { return __dataIsValid[index] });
                  __proofs = __proofs.filter((_item: any, index: string | number) => { return __dataIsValid[index] });
                  __amounts = __amounts.filter((_item: any, index: string | number) => { return __dataIsValid[index] });
                  _dataUSXUTS = [__ids, __proofs, account, __amounts]
                  __rewardAmount = __amounts.length > 0 ? __amounts.reduce((prev: any, cur: any) => {
                    return (new BigNumber(prev).plus(new BigNumber(cur))).toString();
                  })
                    : '0'
                }
                  setPendingAmountLending(response?.data?.unrealizedReward)
                  setPendingAmountDFUTS(response?.data?.unrealizedArbReward.filter((item: any) => item.symbol === 'DF-UTS')[0]?.rewardAmount ?? '0')
                  setPendingAmountUSXUTS(response?.data?.unrealizedArbReward.filter((item: any) => item.symbol === 'USX-UTS')[0]?.rewardAmount ?? '0')
                  setRewardAmountLending(rewardAmount)
                  setDataLending([ids, proofs, account, amounts])
                  setRewardAmountDFUTS(_rewardAmount)
                  setDataDFUTS(_dataDFUTS)
                  setRewardAmountUSXUTS(__rewardAmount)
                  setDataUSXUTS(_dataUSXUTS)
                // }

              } else {
                setDataLending(initDataLending)
                setDataDFUTS(initDataDFUTS)
                setDataUSXUTS(initDataUSXUTS)
              }
            });
        } catch (error) {
          console.log(error)
        }
      } else {
        setPendingAmountArb(undefined)
        setRewardAmountArb(undefined)
        setPendingAmountDFUTS('0')
        setRewardAmountDFUTS(undefined)
        setPendingAmountUSXUTS('0')
        setRewardAmountUSXUTS(undefined)
      }
    } else {
      setData(initData);
      setDataLending(initDataLending)
      setDataDFUTS(initDataDFUTS)
      setDataUSXUTS(initDataUSXUTS)
    }
  }, [Lending, account, chainId]);

  useEffect(() => {
    // if (pendingAmountLending && pendingAmountDFUTS && pendingAmountUSXUTS) {
      const _pendingAmountArb = new BigNumber(pendingAmountLending ?? '0').plus(new BigNumber(pendingAmountDFUTS ?? '0')).plus(new BigNumber(pendingAmountUSXUTS ?? '0')).toString()
      setPendingAmountArb(_pendingAmountArb)
    // }
    // if (RewardAmountLending && RewardAmountDFUTS && RewardAmountUSXUTS) {
      const _RewardAmountArb = new BigNumber(RewardAmountLending ?? '0').plus(new BigNumber(RewardAmountDFUTS ?? '0')).plus(new BigNumber(RewardAmountUSXUTS ?? '0')).toString()
      setRewardAmountArb(_RewardAmountArb)
    // }
  }, [pendingAmountLending, RewardAmountLending, pendingAmountDFUTS, RewardAmountDFUTS, pendingAmountUSXUTS, RewardAmountUSXUTS])

  const ClaimUTS = useCallback(async () => {
    if (Lending && account) {
      setDisabledRewardBtn(true)
      // 获取 _suppliediTokens 和 _borrowediTokens
      Lending.contracts.mining["RewardDistributor"] && Lending.contracts.mining["RewardDistributor"].methods
        .claim(...data)
        .send({ from: account })
        .on("transactionHash", function (hash: string) {
          console.log("transactionHash: ", hash);
          add_Notifies({
            state: "pedding",
            action: `Claim UTS`,
            transactionHash: hash,
            time: new Date().getTime()
          }, setNowNotifies, account as string, chainId as number);
        })
        .on("receipt", function (receipt: any) {
          setDisabledRewardBtn(false)
          update_Notifies(receipt, setNowNotifies, account as string, chainId as number);
          setTimeout(async () => {
            await del_Notify(receipt, setNowNotifies);
          }, 3000);
          console.log("receipt: ", receipt);
        })
        .on("error", function (error: any) {
          setDisabledRewardBtn(false)
          setShow__cancel(true);
          setTimeout(() => {
            setShow__cancel(false);
          }, 3000);
          console.log("error", error);
        })
        .catch((error: { toString: () => string | string[]; message: any; }) => {
          setDisabledRewardBtn(false)
          console.log("error", error);
          if (!error.toString().includes("-32601")) {
            return console.log(error)
          }
        })
    }
  }, [Lending, account, data]);
  const ClaimARB = useCallback(async () => {
    if (Lending && account && chainId) {
      const Contract =  new Lending.web3.eth.Contract(multiSendAbi,multicall_target[chainId])
      const calls = [
        dataLending?.[0].length>0 && {
          target:Lending.contracts.mining["RewardDistributorArb"].address,
          // callData: ['claim(uint256[],bytes32[][],address,uint256[])()',...dataLending],
          callData:Lending.contracts.mining["RewardDistributorArb"].methods
          .claim(...dataLending).encodeABI()
        },
        dataDFUTS?.[0].length>0 && {
          target:Lending.contracts.mining["RewardDistributorDF-UTS"].address,
          // callData: ['claim(uint256[],bytes32[][],address,uint256[])()',...dataDFUTS],
          callData:Lending.contracts.mining["RewardDistributorDF-UTS"].methods
          .claim(...dataDFUTS).encodeABI()
        },
        dataUSXUTS?.[0].length>0 && {
          target:Lending.contracts.mining["RewardDistributorUSX-UTS"].address,
          // callData: ['claim(uint256[],bytes32[][],address,uint256[])()',...dataUSXUTS],
          callData:Lending.contracts.mining["RewardDistributorUSX-UTS"].methods
          .claim(...dataUSXUTS).encodeABI()
        }
      ].filter((item:any)=>item)
        setDisabledRewardBtnArb(true)
        pendingAmountArb && Contract.methods.tryAggregate(true, calls)
        .send({from:account})
        .on("transactionHash", function (hash: string) {
          console.log("transactionHash: ", hash);
          add_Notifies({
            state: "pedding",
            action: `Claim ARB`,
            transactionHash: hash,
            time: new Date().getTime()
          }, setNowNotifies, account as string, chainId as number);
        })
        .on("receipt", function (receipt: any) {
          setDisabledRewardBtnArb(false)
          update_Notifies(receipt, setNowNotifies, account as string, chainId as number);
          setTimeout(async () => {
            await del_Notify(receipt, setNowNotifies);
          }, 3000);
          console.log("receipt: ", receipt);
        })
        .on("error", function (error: any) {
          setDisabledRewardBtnArb(false)
          setShow__cancel(true);
          setTimeout(() => {
            setShow__cancel(false);
          }, 3000);
          console.log("error", error);
        })
        .catch((error: { toString: () => string | string[]; message: any; }) => {
          setDisabledRewardBtnArb(false)
          console.log("error", error);
          if (!error.toString().includes("-32601")) {
            return console.log(error)
          }
        })
    }
  }, [Lending, account,chainId, dataLending,dataDFUTS,dataUSXUTS,pendingAmountArb]);
  
  useEffect(() => {
    // setRewardAmount('...');
    // setPendingAmountArb(undefined)
    // setRewardAmountArb(undefined);
    setPendingAmountArb(undefined)
        setRewardAmountArb(undefined)
        setPendingAmountDFUTS('0')
        setRewardAmountDFUTS(undefined)
        setPendingAmountUSXUTS('0')
        setRewardAmountUSXUTS(undefined)
    fetchClaimAmount();
    const ClaimDFTimer = setInterval(() => {
      fetchClaimAmount();
    }, 1000 * 15);
    return () => {
      clearInterval(ClaimDFTimer);
    };
  }, [Lending, account, chainId]);
  return (
    <ClaimWarp>
      <PendingWarp>
        <Title>{fmt({ id: "Pending" })}:</Title>
        <PendingAmount>{(pendingAmount === '...') ? '...' : _formatNumberNotCarry(bnToDec(new BigNumber(pendingAmount), 18, 4), '', 2, 2)}<Symbol>{'UTS'}</Symbol></PendingAmount>
        {pendingAmountArb &&
          <PendingAmount>{(pendingAmountArb === '...') ? '...' : _formatNumberNotCarry(bnToDec(new BigNumber(pendingAmountArb), 18, 4), '', 2, 2)}
            <Symbol>{'ARB'}</Symbol>
            <>
              <a data-tip data-for="pendingAmountArb" style={{ marginLeft: "5px" }}>
                <img src={tooltip} alt="" className={"tipsImg"} />
              </a>
              <ReactTooltip
                id="pendingAmountArb"
                place="top"
                type="light"
                effect="float"
                className="tooltipBLP"
                textColor="#9195A4"
                borderColor={"#E8ECEF"}
                border={true}
              >
                <ul>
                  <li><span>Lending:</span>{(pendingAmountLending === '...') ? '...' : _formatNumberNotCarry(bnToDec(new BigNumber(pendingAmountLending as string), 18, 4), '', 2, 2)}</li>
                  <li><span>DF-UTS:</span>{(pendingAmountDFUTS === '...') ? '...' : _formatNumberNotCarry(bnToDec(new BigNumber(pendingAmountDFUTS as string), 18, 4), '', 2, 2)}</li>
                  <li><span>USX-UTS:</span>{(pendingAmountUSXUTS === '...') ? '...' : _formatNumberNotCarry(bnToDec(new BigNumber(pendingAmountUSXUTS as string), 18, 4), '', 2, 2)}</li>
                </ul>
              </ReactTooltip>
            </>
          </PendingAmount>}
      </PendingWarp>

      <PendingWarp>
        <Title style={{marginRight: "106px",textAlign: "right"}}>{fmt({ id: "Claimable" })}:</Title>
        <ClaimBlock>
          <ClaimAmount>{(RewardAmount === '...') ? '...' : _formatNumberNotCarry(bnToDec(new BigNumber(RewardAmount), 18, 4), '', 2, 2)}<Symbol>{'UTS'}</Symbol></ClaimAmount>
          <ClaimButton
            className={account && RewardAmount !== "..." && Number(RewardAmount) !== 0 && !disabledRewardBtn ? "" : "disabled"}
            onClick={ClaimUTS}
          >
            {fmt({ id: "Claim" })}
          </ClaimButton>
        </ClaimBlock>
        {RewardAmountArb &&
          <ClaimBlock>
            <ClaimAmount className="arb">
              {(RewardAmountArb === '...') ? '...' : _formatNumberNotCarry(bnToDec(new BigNumber(RewardAmountArb), 18, 4), '', 2, 2)}
              <Symbol>{'ARB'}</Symbol>
              <>
                <a data-tip data-for="RewardAmountArb" style={{ marginLeft: "5px" }}>
                  <img src={tooltip} alt="" className={"tipsImg"} />
                </a>
                <ReactTooltip
                  id="RewardAmountArb"
                  place="top"
                  type="light"
                  effect="float"
                  className="tooltipBLP"
                  textColor="#9195A4"
                  borderColor={"#E8ECEF"}
                  border={true}
                >
                  <ul>
                    <li><span>Lending:</span>{(RewardAmountLending === '...') ? '...' : _formatNumberNotCarry(bnToDec(new BigNumber(RewardAmountLending as string), 18, 4), '', 2, 2)}</li>
                    <li><span>DF-UTS:</span>{(RewardAmountDFUTS === '...') ? '...' : _formatNumberNotCarry(bnToDec(new BigNumber(RewardAmountDFUTS as string), 18, 4), '', 2, 2)}</li>
                    <li><span>USX-UTS:</span>{(RewardAmountUSXUTS === '...') ? '...' : _formatNumberNotCarry(bnToDec(new BigNumber(RewardAmountUSXUTS as string), 18, 4), '', 2, 2)}</li>
                  </ul>
                </ReactTooltip>
              </>
            </ClaimAmount>
            <ClaimButton
              className={account && RewardAmountArb !== "..." && Number(RewardAmountArb) !== 0 && !disabledRewardBtnArb ? "" : "disabled"}
              onClick={ClaimARB}
            >
              {fmt({ id: "Claim" })}
            </ClaimButton>
          </ClaimBlock>
        }
      </PendingWarp>
    </ClaimWarp>
  );
};
const ClaimWarp = styled.div`
  /* font-family: LendingFont; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 72%;
  min-width: 1200px;
  margin: 0 auto;
  height: auto;
  font-size: 18px;
  font-weight: 600;
  border-radius:16px;
  padding: 8px 0;
  gap: 30px;
  color: ${(props) => props.theme.colors.black};
  background:${(props) => props.theme.TableBg};
  .dark & {
    color: ${(props) => props.theme.colors.white};
    background:${(props) => props.theme.DarkTableBg};
  }
  margin-top: 16px;
  @media (max-width: 1199px) {
    width: calc(100% - 24px);
    min-width: inherit;
    margin: 0 auto;
    height: auto;
    padding: 10px 0;
    flex-wrap: wrap;
    font-size: 14px;
    border-radius: 10px;
    gap: 20px;
  }
`;
const PendingWarp = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  gap: 5px;
  @media (max-width: 1199px) {
    margin-bottom: 5px;
  }
`
const Title = styled.span`
&.arb{
  margin-right: 106px;
}
  @media (max-width: 1199px) {
    width: 40%;
    &.arb{
      margin-right: 132px;
    }
  }
`;
const ClaimAmount = styled.span`
  /* font-family: LendingFont; */
  /* font-size: 22px; */
  /* font-weight: bold; */
  display: flex;
  align-items: center;
  margin-right: 20px;
  color:${(props) => props.theme.supply};
  ul{
    margin: 0;
  }
  li{
    line-height: 25px;
    span{
      margin-right: 5px;
    }
  }
  a{
    display: inline-flex;
    align-items: center;
  }
  &.arb{
    margin-right: 5px;
  }
  @media (max-width: 1199px) {
    /* width: 40%; */
    /* font-size: 16px; */
    margin-right: 20px;
    &.arb{
      margin-right: 5px;
    }
  }
`;
const PendingAmount = styled.span`
  margin-right: 40px;
  color:${(props) => props.theme.supply};
  line-height: 38px;
  display: flex;
    align-items: center;
  ul{
    margin: 0;
  }
  li{
    line-height: 25px;
    span{
      margin-right: 5px;
    }
  }
  a{
    display: inline-flex;
    align-items: center;
  }
  @media (max-width: 1199px) {
    margin-right: 10px;
    line-height: 22px;
  }
`;

const Symbol = styled.b`
  font-weight: normal;
  color:${(props) => props.theme.DarkfontColor[100]};
  margin-left: 5px;
  .dark & {
    color:${(props) => props.theme.fontColor[100]};
  }
`
const ClaimBlock = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
  @media (max-width: 1199px) {
    display: flex;
    flex-direction: row;
  }
`
const ClaimButton = styled.div`
  width: 86px;
  height: 38px;
  line-height: 38px;
  /* font-family:LendingFont; */
  font-size: 14px;
  font-weight: bold;
  border-radius: 4px;
  text-align: center;
  background-color: ${(props) => props.theme.supply};
  color: ${(props) => props.theme.fontColor[100]};
  cursor: pointer;
  @media (max-width: 1199px) {
    font-size: 12px;
    width: 100px;
    height: 22px;
    line-height: 22px;
  }
  &.disabled {
    background: ${(props) => props.theme.colors.lightOpacityGrey};
    .dark & {
      background: ${(props) => props.theme.colors.darkOpacityGrey};
    }
    color: ${(props) => props.theme.colors.disabledBtn};
    .dark & {
    }
    pointer-events: none;
  }
`;
export default AvailableClaim;
