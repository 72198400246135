import React, { useCallback, useState, useEffect, useMemo } from "react";
import { useParams, useNavigate, useLocation, NavLink as RouterLink } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { useIntl } from "react-intl";
import { useQuery, setUrlParam, format_bn, format_num_to_K } from "utils";
import { network_Id, network_Name } from "lending-sdk/utils/index1"
import general_DL_Token from 'lending-sdk/lib/clean_build/contracts/general/DL_Token.json';
import synthetic_DL_Token from 'lending-sdk/lib/clean_build/contracts/synthetic/DL_Token.json';
import stocks_DL_Token from 'lending-sdk/lib/clean_build/contracts/stocks/DL_Token.json';
import { fetchPrice } from "./fetchPrice";
import LendTitle from "components/LendTitle";

import IERC20JSON_general from 'lending-sdk/lib/clean_build/contracts/general/IERC20.json';
import IERC20JSON_stocks from 'lending-sdk/lib/clean_build/contracts/stocks/IERC20.json';

import img_eth from "assets/Bridge/net-eth.svg"
import img_arbitrum from "assets/Bridge/net-arbitrum.svg"
import img_op from "assets/Bridge/net-op.svg"
import img_bsc from "assets/Bridge/net-bsc.svg"
import img_polygon from "assets/Bridge/Polygon.svg"
// import img_Avalanche from "assets/Bridge/Avalanche.svg"
import img_kava from "assets/Bridge/KAVA.svg"
import img_ConfluxTest from "assets/Bridge/net-conflux.svg"

import FlexBoxWrap from "components/FlexBoxWrap"
import FlexBGFilter from "components/FlexBGFilter"


const Price: React.FC = () => {
  const [priceTokens, setPriceTokens] = useState<any>([])
  const intl = useIntl();
  const fmt = intl.formatMessage;
  const { currentNetwork } = useParams<any>();
  const { pathname } = useLocation();
  const navigate = useNavigate()
  let fetchApiTimer: NodeJS.Timeout;
  const [generalLink, setGeneralLink] = useState<Record<string, any> | undefined>(undefined)
  const [syntheticLink, setSyntheticLink] = useState<Record<string, any> | undefined>(undefined)
  const [stocksLink, setStocksLink] = useState<Record<string, any> | undefined>(undefined)
  const [localList, setLocalList] = useState<any>([])
  const [showList, setShowList] = useState(false)
  const [_currentNetwork, setCurrentNetwork] = useState<string>(currentNetwork as string)

  const [showFreeze, setShowFreeze] = useState(false)
  const [isHasFrozen, setIsHasFrozen] = useState(false);

  // 获取本地token配置
  const deployAssets = {
    "general": general_DL_Token,
    "synthetic": synthetic_DL_Token,
    "stocks": stocks_DL_Token,
  }
  const SupportedNetMap = [
    'mainnet',
    'ArbitrumOne',
    'Optimism',
    'bsc',
    'Polygon',
    // 'Kava',
    'ConfluxeSpace',
    // 'Goerli',
    'Sepolia'
  ]
  const fetchApi = useCallback(async (NetworkName: string) => {
    const { underlyingToken } = await fetchPrice(
      SupportedNetMap.includes(NetworkName) ? NetworkName : ''
    )
    // console.log(underlyingToken)
    setPriceTokens(underlyingToken)
  }, [currentNetwork]);


  const switchTabHandler = (e: any) => {
    // e.currentTarget.querySelector(".active").classList.remove("active");
    // e.target.parentNode.classList.add("active");
    // if (e.target.tagName === "UL") {
    //   e.target.childNodes[1].classList.add("active");
    // } else {
    //   e.target.classList.add("active");
    // }
    // console.log(e.target.tagName)
    // console.log(e.currentTarget.querySelector(".active"))
    if (e.target.tagName === 'A') {
      e.currentTarget.querySelector(".active")?.classList.remove("active");
      e.target.parentNode.classList.add('active')
    } else if (e.target.tagName === 'UL') {
      return;
    }
  }

  const NET_to_Id = {
    mainnet: '1',
    bsc: '56',
    ArbitrumOne: '42161',
    Optimism: '10',
    Polygon: '137',
    // Kava: '2222',
    ConfluxTest: '71',
    ConfluxeSpace: '1030',
    Goerli: '5',
    Sepolia: '11155111',
  }
  const NetMap = {
    mainnet: {
      net: 'Ethereum',
      icon: img_eth
    },
    Sepolia: {
      net: 'Sepolia',
      icon: img_eth
    },
    Goerli: {
      net: 'Goerli',
      icon: img_eth
    },
    ArbitrumOne: {
      net: 'Arbitrum',
      icon: img_arbitrum
    },
    Optimism: {
      net: 'Optimism',
      icon: img_op
    },
    bsc: {
      net: 'BSC',
      icon: img_bsc
    },
    Polygon: {
      net: 'Polygon',
      icon: img_polygon
    },
    // Kava: {
    //   net: 'Kava',
    //   icon: img_kava
    // },
    ConfluxTest: {
      net: 'ConfluxTest',
      icon: img_ConfluxTest
    },
    ConfluxeSpace: {
      net: 'Conflux eSpace',
      icon: img_ConfluxTest
    }
  }
  const switchNetwork = (e: any) => {
    let currentChain;
    if (e.target.tagName === 'LI') {
      currentChain = e.target.querySelector('span').dataset.chain;
    } else if (e.target.tagName === 'A') {
      currentChain = e.target.querySelector('span').dataset.chain;
    } else if (e.target.tagName === 'IMG') {
      currentChain = e.target.parentNode.querySelector('span').dataset.chain;
    } else {
      currentChain = e.target.dataset.chain;
    }
    // console.log(e.target.tagName)
    // console.log(e.currentTarget.querySelector(".active"))
    // setCurrentChainId(Number(currentChain))
    setCurrentNetwork(network_Name?.[currentChain] ?? 'Ethereum')
    navigate(
      `/Price/${network_Name?.[currentChain]}`
    );
    if (e.target.tagName === 'A') {
      e.currentTarget.querySelector(".active")?.classList.remove("active");
      e.target.parentNode.classList.add('active')
    } else if (e.target.tagName === 'UL') {
      return;
    }
    // console.log(e.target.datasets("networkName"))
  };
  const setLocalListFn = (netType: string, poolType: any) => {
    // console.log(netType, poolType)
    let tokenJSON: any
    if (poolType === 'general') {
      tokenJSON = IERC20JSON_general.networks[NET_to_Id[netType]]
    } else {
      tokenJSON = IERC20JSON_stocks.networks[NET_to_Id[netType]]
    }
    // console.log(tokenJSON)

    if (!tokenJSON) {
      // console.log(11111)
      setLocalList([])
      return false
    }

    const tokenArr: any = []
    Object.keys(tokenJSON).map((item: any) => {
      tokenArr.push(tokenJSON[item].address.toLocaleLowerCase())
    })
    // console.log(tokenArr)
    setLocalList(tokenArr)
  }
  useEffect(() => {
    setLocalListFn(currentNetwork as string, 'general')

    setPriceTokens([])
    clearInterval(fetchApiTimer)
    fetchApi(currentNetwork as string)
    fetchApiTimer = setInterval(() => {
      fetchApi(currentNetwork as string)
    }, 1000 * 15)
    return () => { clearInterval(fetchApiTimer) }
  }, [currentNetwork]);



  // 动态路由链接
  useEffect(() => {
    let generalLink, syntheticLink, stocksLink
    if (currentNetwork) {
      generalLink = deployAssets["general"]['networks'][`${network_Id[currentNetwork]}`]
      setGeneralLink(generalLink)
      syntheticLink = deployAssets["synthetic"]['networks'][`${network_Id[currentNetwork]}`]
      setSyntheticLink(syntheticLink)
      stocksLink = deployAssets["stocks"]['networks'][`${network_Id[currentNetwork]}`]
      setStocksLink(stocksLink)
      // switchpool时候 如果不存在 stocks池子 则切换到 general池子

      if (!SupportedNetMap.includes(currentNetwork)) {
        navigate(`/Price/mainnet`)
      }
    }
  }, [currentNetwork])

  useEffect(() => {
    document.body.addEventListener("click", (e) => {
      // 如果点击了 网络切换 select 列表不消失
      const NetworkSelect = document
        .querySelector(".NetworkName")
        ?.contains(e.target as HTMLElement);
      if (NetworkSelect) {
        return;
      }
      if (showList) {
        setShowList(false)
      }

    });
    return () => {
      document.body.removeEventListener("click", (event) => {
        event.preventDefault();
      }, false);
    };
  }, [showList]);


  const checkboxChange = (e: any) => {
    // console.log(e)
    setShowFreeze(!showFreeze);
  }

  useMemo(() => {
    if (priceTokens.length > 0) {
      let tmpBool = false
      priceTokens.map((item: any) => {
        if (item.isFreeze) {
          // console.log(item)
          tmpBool = true
        }
      })
      setIsHasFrozen(tmpBool)
    }
  }, [priceTokens])


  return (
    <>
      <FlexBoxWrap>
        <AssetWrap>
          <StyledTitle>
            {fmt({ id: "Price_Title" })}
          </StyledTitle>
          <PriceTitleSub>{fmt({ id: "Price_Title__sub" })}</PriceTitleSub>
        </AssetWrap>
      </FlexBoxWrap>

      {/* network */}
      <FlexBGFilter>
        <FlexBoxWrap>

          <TabWrapInner>
            <SelectType
              className="NetworkName"
              // onBlur={() => { buttonOnBlur() }}
              onClick={() => { setShowList(!showList) }}
            >
              <LeftBlock>
                <img className="l1img" src={NetMap[_currentNetwork]?.icon} alt="" />
                <b>{NetMap[_currentNetwork]?.net}</b>
              </LeftBlock>
              {<StyledArrow className={showList ? 're' : ''} />}

              {
                showList &&
                <NetWorkList className='selectList' onClick={(e) => switchNetwork(e)}>
                  <NetWorkItem>
                    <NavLink>
                      <img src={NetMap['mainnet']?.icon} alt="" />
                      <span className={_currentNetwork === 'mainnet' ? 'active' : ''} data-chain={1}>
                        {NetMap['mainnet']?.net}
                      </span>
                    </NavLink>
                  </NetWorkItem>
                  <NetWorkItem>
                    <NavLink>
                      <img src={NetMap['ArbitrumOne']?.icon} alt="" />
                      <span className={_currentNetwork === 'ArbitrumOne' ? 'active' : ''} data-chain={42161}>
                        {NetMap['ArbitrumOne']?.net}
                      </span>
                    </NavLink>
                  </NetWorkItem>
                  <NetWorkItem>
                    <NavLink>
                      <img src={NetMap['Optimism']?.icon} alt="" />
                      <span className={_currentNetwork === 'Optimism' ? 'active' : ''} data-chain={10}>
                        {NetMap['Optimism']?.net}
                      </span>
                    </NavLink>
                  </NetWorkItem>
                  <NetWorkItem>
                    <NavLink>
                      <img src={NetMap['bsc']?.icon} alt="" />
                      <span className={_currentNetwork === 'bsc' ? 'active' : ''} data-chain={56}>
                        {NetMap['bsc']?.net}
                      </span>
                    </NavLink>
                  </NetWorkItem>
                  <NetWorkItem>
                    <NavLink>
                      <img src={NetMap['Polygon']?.icon} alt="" />
                      <span className={_currentNetwork === 'Polygon' ? 'active' : ''} data-chain={137}>
                        {NetMap['Polygon']?.net}
                      </span>
                    </NavLink>
                  </NetWorkItem>
                  {/* <NetWorkItem>
                    <NavLink>
                      <img src={NetMap['Kava']?.icon} alt="" />
                      <span className={_currentNetwork === 'Kava' ? 'active' : ''} data-chain={2222}>
                        {NetMap['Kava']?.net}
                      </span>
                    </NavLink>
                  </NetWorkItem> */}
                  <NetWorkItem>
                    <NavLink>
                      <img src={NetMap['ConfluxeSpace']?.icon} alt="" />
                      <span className={_currentNetwork === 'ConfluxeSpace' ? 'active' : ''} data-chain={1030}>
                        {NetMap['ConfluxeSpace']?.net}
                      </span>
                    </NavLink>
                  </NetWorkItem>
                  {/* <NetWorkItem>
                    <NavLink>
                      <img src={NetMap['Goerli']?.icon} alt="" />
                      <span className={_currentNetwork === 'Goerli' ? 'active' : ''} data-chain={5}>
                        {NetMap['Goerli']?.net}
                      </span>
                    </NavLink>
                  </NetWorkItem> */}
                  <NetWorkItem>
                    <NavLink>
                      <img src={NetMap['Sepolia']?.icon} alt="" />
                      <span className={_currentNetwork === 'Sepolia' ? 'active' : ''} data-chain={11155111}>
                        {NetMap['Sepolia']?.net}
                      </span>
                    </NavLink>
                  </NetWorkItem>
                </NetWorkList>
              }
            </SelectType>
          </TabWrapInner>
        </FlexBoxWrap>
      </FlexBGFilter>




      <FlexBoxWrap>
        <BGWrap>
          <InnerTitle>
            {fmt({ id: "Assets" })}
            {
              isHasFrozen &&
              <FreezeBox>
                <label htmlFor={"toggle-btn"}><span>Show Frozen Assets</span></label>
                <input
                  className="switch"
                  type="checkbox"
                  id={"toggle-btn"}
                  checked={showFreeze ? true : false}
                  onChange={(e) => { checkboxChange(e.target.checked) }} />
              </FreezeBox>
            }
          </InnerTitle>

          <AssetsItemWrap>
            {
              !(priceTokens && priceTokens.length > 0 && localList && localList.length > 0)
                ? <>
                  {
                    [0, 0, 0, 0].map((item: any, index: number) => {
                      return (
                        <AssetsItem className='' key={index}>
                          <IconWrap>
                            <div className='tokenIconBG'></div>
                          </IconWrap>
                          <TokenName>
                            <div className='tokenNameBG'></div>
                          </TokenName>
                          <TokenPrice>
                            <div className='tokenPriceBG'></div>
                          </TokenPrice>
                          <TokenPrice>
                            <div className='tokenPriceBGspe'></div>
                          </TokenPrice>
                        </AssetsItem>
                      )
                    })
                  }
                </>
                : <>
                  {
                    localList && localList.length > 0 && priceTokens &&
                    priceTokens.map((item: any, index: number) => {
                      // console.log(item.underlying)
                      // if(item.symbol==="GOLDx"){
                      //   console.log(item)
                      // }
                      const USDCeArr = ['0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8']
                      if (
                        localList.includes(item.underlying.toLocaleLowerCase()) ||
                        item.underlying === '0x0000000000000000000000000000000000000000'
                      ) {
                        if (item.isFreeze) {
                          if (showFreeze) {
                            return (
                              <AssetsItem key={index} className={'closed'}>
                                <IconWrap>
                                  {/* {item.isFreeze && <StyledFreeze>Frozen</StyledFreeze>} */}
                                  <TokenIcon src={item.icon}></TokenIcon>
                                </IconWrap>
                                <TokenName>
                                  {USDCeArr.includes(item.underlying) ? 'USDC.e' : item.symbol}
                                </TokenName>
                                <TokenPrice>
                                  {
                                    '$' + format_num_to_K(
                                      Number(format_bn(item.price, 18, 4)).toFixed(2)
                                    )
                                  }
                                </TokenPrice>
                                {
                                  // item.priceStatus === 'false'
                                  //   ? <PriceOracle className='closed'>{'Market Closed'}</PriceOracle>
                                  //   : item.symbol === 'DF'
                                  //     ? <PriceOracle>
                                  //       {currentNetwork === "ConfluxeSpace" ? 'Pyth Report' : fmt({ id: "dForce_Report" })}
                                  //     </PriceOracle>
                                  //     // USDC USDT on kava-net
                                  //     : (item.underlying === '0x23367BEA9B6931690960d8c59f6e708630f24E58') ||
                                  //       (item.underlying === '0xfB1af1baFE108906C0f1f3B36D15919B95ee95BD') ||
                                  //       item.symbol === 'USX'
                                  //       ? <PriceOracle>{'Fixed'}</PriceOracle>
                                  //       // : <PriceOracle>{'Chainlink Report'}</PriceOracle>
                                  //       : <PriceOracle>
                                  //         {currentNetwork === "ConfluxeSpace" ? 'Pyth Report' : 'Chainlink Report'}
                                  //       </PriceOracle>
                                }
                                <PriceOracle className='closed'>{'Frozen'}</PriceOracle>
                              </AssetsItem>
                            )
                          }
                        } else {
                          return (
                            <AssetsItem key={index} className={item.priceStatus === 'false' ? 'closed' : ''}>
                              <IconWrap>
                                {/* {item.isFreeze && <StyledFreeze>Frozen</StyledFreeze>} */}
                                <TokenIcon src={item.icon}></TokenIcon>
                              </IconWrap>
                              <TokenName>
                                {USDCeArr.includes(item.underlying) ? 'USDC.e' : item.symbol}
                              </TokenName>
                              <TokenPrice>
                                {
                                  '$' + format_num_to_K(
                                    Number(format_bn(item.price, 18, 4)).toFixed(2)
                                  )
                                }
                              </TokenPrice>
                              {
                                item.priceStatus === 'false'
                                  ? <PriceOracle className='closed'>{'Market Closed'}</PriceOracle>
                                  : item.symbol === 'DF'
                                    ? <PriceOracle>
                                      {currentNetwork === "ConfluxeSpace" ? 'Pyth Report' : fmt({ id: "dForce_Report" })}
                                    </PriceOracle>
                                    // USDC USDT on kava-net
                                    : (item.underlying === '0x23367BEA9B6931690960d8c59f6e708630f24E58') ||
                                      (item.underlying === '0xfB1af1baFE108906C0f1f3B36D15919B95ee95BD') ||
                                      item.symbol === 'USX'
                                      ? <PriceOracle>{'Fixed'}</PriceOracle>
                                      // : <PriceOracle>{'Chainlink Report'}</PriceOracle>
                                      : <PriceOracle>
                                        {currentNetwork === "ConfluxeSpace" ? 'Pyth Report' : 'Chainlink Report'}
                                      </PriceOracle>
                              }
                            </AssetsItem>
                          )
                        }
                      }
                    })
                  }
                </>
            }
          </AssetsItemWrap>
        </BGWrap>
      </FlexBoxWrap>
    </>
  )
}



const FreezeBox = styled.div`
  font-size: 14px;
  margin-left: 44px;

  &.moblie{
    margin-left: 0px;
    line-height: 22px;
    color: #9195a4;
    @media (max-width: 1199px) {
    }
  }
`

const StyledFreeze = styled.div`
  position: absolute;
  color: #FF0E0E;
  background-color: #FFDEDE;
  /* border: 2px solid tomato; */
  /* left: 80px;
  top: 20px; */
  left: 28%;
  top: 16%;
  font-size: 12px;
  font-weight: 600;
  height: auto;
  line-height: 1;
  padding: 3px;
  font-weight: 700;
  border-radius: 2px;
  margin: 4px 0 0 10px;
  @media (max-width: 1199px) {
    left: 30%;
    top: 4%;
  }
`;
const BGWrap = styled.div`
  /* background-color: red; */
  margin-top: 24px;
  border-radius: 12px;
  background: #fff;
  padding: 16px 24px;
  .dark & {
    background: #131313;
  }
  @media (max-width: 1199px) {
    margin-top: 16px;
    border-radius: 10px;
    padding: 8px 8px 0;
  }
`
const InnerTitle = styled.div`
  color: #131313;
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  padding-top: 16px;
  padding-bottom: 24px;
  display: flex;
  .dark & {
    color: #fff;
  }
  @media (max-width: 1199px) {
    font-size: 12px;
    padding-top: 4px;
    padding-bottom: 12px;
  }
`
const AssetWrap = styled.div`
  height: 224px;
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 1199px) {
    height: auto;
    padding: 16px 0;
  }
`
const StyledTitle = styled.div`
  color: #FFF;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
  @media (max-width: 1199px) {
    font-size: 16px;
    line-height: 28px;
  }
`

const TabWrapInner = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  @media (max-width: 1199px) {
    min-width: 100%;
    /* flex-direction: column; */
    flex-wrap: wrap;
  }
`
const SelectType = styled.button`
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* background: ${(props) => props.theme.colors.lightOpacityGrey}; */
    background: ${(props) => props.theme.colors.darkOpacityGrey};
    position: relative;
    width: max-content;
    min-width: 200px;
    padding:0 10px 0 10px;
    color: #9195A4;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    line-height: 36px;
    b {
      margin: 0;
      font-weight: 500;
      color:${(props) => props.theme.colors.white};
      /* .dark & {
        color: #fff;
      } */
    }
    .l1img {
      width: 24px;
      margin-right: 12px;
    }
    /* .dark & {
      background: ${(props) => props.theme.colors.darkOpacityGrey};
    } */
    border-radius:40px;
  @media(max-width: 1199px) {
    cursor: pointer;
    /* border: 1px solid #5FAAE7; */
    padding:0 10px 0 10px;
    min-width: 160px;
    line-height:36px;
    font-size:14px;
    font-weight:bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* background: ${(props) => props.theme.colors.lightOpacityGrey}; */
    position: relative;
    margin: 7px 0;
    b {
      margin: 0;
      font-weight: 700;
      /* color: rgb(36,42,71);
      .dark & {
        color: #fff;
      } */
    }
    /* .dark & {
      background: ${(props) => props.theme.colors.darkOpacityGrey};
    } */
    border-radius:40px;
  }
`
const LeftBlock = styled.div`
  display: flex;
  align-items: center;
`
const StyledArrow = styled.div`
  margin-left: 16px;
  width: 0;
  height: 0;
  border: 4px solid transparent;
  margin-bottom: -5px;
  border-top: 4px solid ${(props) => props.theme.colors.white};
  &.re {
    transform: rotate(180deg);
    margin-bottom: 2px;
  }

  /* .dark & {
    border-top: 4px solid #fff;
  } */
`
const NetWorkList = styled.ul`
  z-index: 999;
  position: absolute;
  top: 44px;
  left: 0;
  min-width: 200px;
  width: max-content;
  background: ${(props) => props.theme.colors.white};
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  .dark & {
    background: ${(props) => props.theme.colors.black};
    box-shadow:0px 16px 32px 0px rgb(255 255 255 / 0.08);
  }
  @media (max-width: 1199px) {
    min-width: 160px;
  }
  
`
const NetWorkItem = styled.li`
  cursor: pointer;
  color: #fff!important;
  display: flex;
  align-items: center;
  /* justify-content: space-around; */
  line-height: 34px;
  padding-left: 15px;
  padding-right: 10px;

  span {
    color: #000;
  }

  span.active,
  &:hover span {
    color: #5EAFE7;
  }
  .dark & {
    span {
      color: #fff;
    }
  }
  img {
    width: 24px;
    margin-right: 12px;
  }
`
const NavLink = styled.a`
  color: ${(props) => props.theme.colors.lightGrey};
  &:hover {
    color: ${(props) => props.theme.colors.lightGrey};
  }
`;

const flash = keyframes`
  0% {
      background-position: -468px 0
    }
  100% {
      background-position: 468px 0
  }
`;

const PriceTitleSub = styled.div`
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  width: 720px;
  @media (max-width: 1199px) {
    width: 90%;
    font-size: 12px;
    line-height: 16px;
  }
`
const AssetsItemWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
`
const AssetsItem = styled.div`
  width: 24%;
  border-radius: 8px;
  margin-bottom: 16px;
  margin-right: 1.3333%;
  border: 1px solid rgba(19, 19, 19, 0.08);

  .dark & {
    border: 1px solid rgba(255, 255, 255, 0.08);
  }

  &:nth-child(4n){
    margin-right: 0px;
  }

  &.closed {
    box-shadow: 0px 0px 8px 8px rgba(246, 61, 61, 0.18);
    border: 1px solid rgba(246, 61, 61, 0.6);
  }

  @media (max-width: 1199px) {
    /* height: calc(100vh - 60px); */
    width: 48%;
    height: auto;
    margin-right: 4%;
    margin-bottom: 10px;

    &:nth-child(2n){
      margin-right: 0px;
    }
  }

  & .tokenIconBG {
    width: 56px;
    height: 56px;

    @media (max-width: 1199px) {
      width: 28px;
      height: 28px;
    }

    animation: ${flash} 1.2s linear infinite forwards;
    background: #e1e5ee;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 800px 100px;
    border-radius: 50%;
  }
  & .tokenNameBG {
    width: 60px;
    height: 25px;

    @media (max-width: 1199px) {
      width: 50px;
      height: 16px;
    }

    animation: ${flash} 1.2s linear infinite forwards;
    background: #e1e5ee;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 800px 100px;
  }
  & .tokenPriceBG {
    width: 90px;
    height: 25px;

    @media (max-width: 1199px) {
      width: 80px;
      height: 16px;
    }

    animation: ${flash} 1.2s linear infinite forwards;
    background: #e1e5ee;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 800px 100px;
  }
  & .tokenPriceBGspe {
    width: 90px;
    height: 18px;
    margin-bottom: 11px;

    @media (max-width: 1199px) {
      width: 80px;
      height: 16px;
    }

    animation: ${flash} 1.2s linear infinite forwards;
    background: #e1e5ee;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 800px 100px;
  }
`
const TokenName = styled.div`
  color: #999;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  display: flex;
  justify-content: center;
  @media (max-width: 1199px) {
    font-size: 12px;
  }
`
const TokenPrice = styled.div`
  color: #131313;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  display: flex;
  justify-content: center;
  padding-top: 16px;
  padding-bottom: 8px;
  .dark & {
    color: #fff;
  }
  @media (max-width: 1199px) {
    font-size: 14px;
    padding-top: 8px;
    padding-bottom: 4px;
  }
`
const PriceOracle = styled.div`
  color: #999;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  display: flex;
  justify-content: center;
  padding-bottom: 32px;
  &.closed {
    color: #F63D3D;
  }
  @media (max-width: 1199px) {
    font-size: 12px;
    padding-bottom: 16px;
  }
`
const IconWrap = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 32px;
  padding-bottom: 16px;
  position: relative;
  @media (max-width: 1199px) {
    padding-top: 16px;
    padding-bottom: 8px;
  }
`
const TokenIcon = styled.img`
  width: 56px;
  height: 56px;

  @media (max-width: 767px) {
    width: 28px;
    height: 28px;
  }
`

const SwitchPool = styled.ul`
  display: flex;
  width: 100%;
  height: 40px;
  line-height: 40px;
  font-size: 20px;
  border-bottom: 4px solid ${(props) => props.theme.colors.lightTableBorder};
  .dark & {
    border-bottom: 4px solid ${(props) => props.theme.colors.darkTableBorder};
  }
  margin: 5px 0 0;
  @media (max-width: 767px) {
    font-size: 18px;
    height: 34px;
    line-height: 34px;
  }
`;
const SwitchPoolItem = styled.li`
  padding: 0 24px;
  font-weight: 500;
  margin-bottom: -4px;
  cursor: pointer;
  a {
    display: block;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &:hover {
    a {
      color: ${(props) => props.theme.colors.black};
      .dark & {
        color: ${(props) => props.theme.colors.white};
      }
      font-weight: bold;
    }
  }
  &.active {
    border-bottom: 4px solid ${(props) => props.theme.colors.supply.dark};
    a {
      color: ${(props) => props.theme.colors.black};
      .dark & {
        color: ${(props) => props.theme.colors.white};
      }
      font-weight: bold;
    }
  }
  @media (max-width: 767px) {
    padding: 0 18px;
  }
`;
const SwitchTabs = styled.ul`
  display: flex;
  flex-wrap:wrap;
  width: fit-content;
  min-height: 40px;
  line-height: 40px;
  border-radius: 20px;
  margin: 30px 0 0;
  cursor: pointer;
  @media (max-width: 767px) {
    display: none;
    width:100%;
    min-height: 30px;
    line-height: 30px;
    margin: 20px 0 0;
  }
`;
const PoolLink = styled(RouterLink)`
  color: ${(props) => props.theme.colors.lightGrey};
  &:hover {
    color: ${(props) => props.theme.colors.white};
  }
`;
const SwitchItem = styled.li`
  font-weight: 500;
  border-radius: 20px;
  margin:0 10px 10px 0;
  background: ${(props) => props.theme.colors.lightOpacityGrey};
  .dark & {
    background: ${(props) => props.theme.colors.darkOpacityGrey};
  }
  a {
    display: block;
    padding: 0 20px;
  }
  &.active {
    background: ${(props) => props.theme.colors.linearBg};
    border-radius: 20px;
    a {
      color: ${(props) => props.theme.colors.white};
      font-weight: bold;
    }
  }
  @media (max-width: 767px) {
    padding: 0 10px;
    a {
      padding: 0 10px;
    }
  }
`;

export default Price;
