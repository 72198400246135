"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.abi = void 0;
exports.abi = [
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "iToken",
                type: "address",
            },
            {
                indexed: false,
                internalType: "bool",
                name: "paused",
                type: "bool",
            },
        ],
        name: "BorrowPaused",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "iToken",
                type: "address",
            },
            {
                indexed: false,
                internalType: "address",
                name: "account",
                type: "address",
            },
        ],
        name: "BorrowedAdded",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "iToken",
                type: "address",
            },
            {
                indexed: false,
                internalType: "address",
                name: "account",
                type: "address",
            },
        ],
        name: "BorrowedRemoved",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "iToken",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "collateralFactor",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "borrowFactor",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "supplyCapacity",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "borrowCapacity",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "distributionFactor",
                type: "uint256",
            },
        ],
        name: "MarketAdded",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "iToken",
                type: "address",
            },
            {
                indexed: false,
                internalType: "address",
                name: "account",
                type: "address",
            },
        ],
        name: "MarketEntered",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "iToken",
                type: "address",
            },
            {
                indexed: false,
                internalType: "address",
                name: "account",
                type: "address",
            },
        ],
        name: "MarketExited",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "iToken",
                type: "address",
            },
            {
                indexed: false,
                internalType: "bool",
                name: "paused",
                type: "bool",
            },
        ],
        name: "MintPaused",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "iToken",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "oldBorrowCapacity",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "newBorrowCapacity",
                type: "uint256",
            },
        ],
        name: "NewBorrowCapacity",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "iToken",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "oldBorrowFactorMantissa",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "newBorrowFactorMantissa",
                type: "uint256",
            },
        ],
        name: "NewBorrowFactor",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint256",
                name: "oldCloseFactorMantissa",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "newCloseFactorMantissa",
                type: "uint256",
            },
        ],
        name: "NewCloseFactor",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "iToken",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "oldCollateralFactorMantissa",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "newCollateralFactorMantissa",
                type: "uint256",
            },
        ],
        name: "NewCollateralFactor",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint256",
                name: "oldLiquidationIncentiveMantissa",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "newLiquidationIncentiveMantissa",
                type: "uint256",
            },
        ],
        name: "NewLiquidationIncentive",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "previousOwner",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "newOwner",
                type: "address",
            },
        ],
        name: "NewOwner",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "oldPauseGuardian",
                type: "address",
            },
            {
                indexed: false,
                internalType: "address",
                name: "newPauseGuardian",
                type: "address",
            },
        ],
        name: "NewPauseGuardian",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "oldPendingOwner",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "newPendingOwner",
                type: "address",
            },
        ],
        name: "NewPendingOwner",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "oldPriceOracle",
                type: "address",
            },
            {
                indexed: false,
                internalType: "address",
                name: "newPriceOracle",
                type: "address",
            },
        ],
        name: "NewPriceOracle",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "oldRewardDistributor",
                type: "address",
            },
            {
                indexed: false,
                internalType: "address",
                name: "_newRewardDistributor",
                type: "address",
            },
        ],
        name: "NewRewardDistributor",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "iToken",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "oldSupplyCapacity",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "newSupplyCapacity",
                type: "uint256",
            },
        ],
        name: "NewSupplyCapacity",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "iToken",
                type: "address",
            },
            {
                indexed: false,
                internalType: "bool",
                name: "paused",
                type: "bool",
            },
        ],
        name: "RedeemPaused",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "bool",
                name: "paused",
                type: "bool",
            },
        ],
        name: "SeizePaused",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "bool",
                name: "paused",
                type: "bool",
            },
        ],
        name: "TransferPaused",
        type: "event",
    },
    {
        inputs: [],
        name: "_acceptOwner",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_iToken",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_collateralFactor",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_borrowFactor",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_supplyCapacity",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_borrowCapacity",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_distributionFactor",
                type: "uint256",
            },
        ],
        name: "_addMarket",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bool",
                name: "_paused",
                type: "bool",
            },
        ],
        name: "_setAllBorrowPaused",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bool",
                name: "_paused",
                type: "bool",
            },
        ],
        name: "_setAllMintPaused",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bool",
                name: "_paused",
                type: "bool",
            },
        ],
        name: "_setAllRedeemPaused",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_iToken",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_newBorrowCapacity",
                type: "uint256",
            },
        ],
        name: "_setBorrowCapacity",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_iToken",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_newBorrowFactorMantissa",
                type: "uint256",
            },
        ],
        name: "_setBorrowFactor",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_iToken",
                type: "address",
            },
            {
                internalType: "bool",
                name: "_paused",
                type: "bool",
            },
        ],
        name: "_setBorrowPaused",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_newCloseFactorMantissa",
                type: "uint256",
            },
        ],
        name: "_setCloseFactor",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_iToken",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_newCollateralFactorMantissa",
                type: "uint256",
            },
        ],
        name: "_setCollateralFactor",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_newLiquidationIncentiveMantissa",
                type: "uint256",
            },
        ],
        name: "_setLiquidationIncentive",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_iToken",
                type: "address",
            },
            {
                internalType: "bool",
                name: "_paused",
                type: "bool",
            },
        ],
        name: "_setMintPaused",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_newPauseGuardian",
                type: "address",
            },
        ],
        name: "_setPauseGuardian",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address payable",
                name: "newPendingOwner",
                type: "address",
            },
        ],
        name: "_setPendingOwner",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_newOracle",
                type: "address",
            },
        ],
        name: "_setPriceOracle",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bool",
                name: "_paused",
                type: "bool",
            },
        ],
        name: "_setProtocolPaused",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_iToken",
                type: "address",
            },
            {
                internalType: "bool",
                name: "_paused",
                type: "bool",
            },
        ],
        name: "_setRedeemPaused",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_newRewardDistributor",
                type: "address",
            },
        ],
        name: "_setRewardDistributor",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bool",
                name: "_paused",
                type: "bool",
            },
        ],
        name: "_setSeizePaused",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_iToken",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_newSupplyCapacity",
                type: "uint256",
            },
        ],
        name: "_setSupplyCapacity",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bool",
                name: "_paused",
                type: "bool",
            },
        ],
        name: "_setTransferPaused",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_iToken",
                type: "address",
            },
            {
                internalType: "bool",
                name: "_paused",
                type: "bool",
            },
        ],
        name: "_setiTokenPaused",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_iToken",
                type: "address",
            },
            {
                internalType: "address",
                name: "_borrower",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_borrowedAmount",
                type: "uint256",
            },
        ],
        name: "afterBorrow",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_iToken",
                type: "address",
            },
            {
                internalType: "address",
                name: "_to",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_amount",
                type: "uint256",
            },
        ],
        name: "afterFlashloan",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_iTokenBorrowed",
                type: "address",
            },
            {
                internalType: "address",
                name: "_iTokenCollateral",
                type: "address",
            },
            {
                internalType: "address",
                name: "_liquidator",
                type: "address",
            },
            {
                internalType: "address",
                name: "_borrower",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_repaidAmount",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_seizedAmount",
                type: "uint256",
            },
        ],
        name: "afterLiquidateBorrow",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_iToken",
                type: "address",
            },
            {
                internalType: "address",
                name: "_minter",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_mintAmount",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_mintedAmount",
                type: "uint256",
            },
        ],
        name: "afterMint",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_iToken",
                type: "address",
            },
            {
                internalType: "address",
                name: "_redeemer",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_redeemAmount",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_redeemedUnderlying",
                type: "uint256",
            },
        ],
        name: "afterRedeem",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_iToken",
                type: "address",
            },
            {
                internalType: "address",
                name: "_payer",
                type: "address",
            },
            {
                internalType: "address",
                name: "_borrower",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_repayAmount",
                type: "uint256",
            },
        ],
        name: "afterRepayBorrow",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_iTokenCollateral",
                type: "address",
            },
            {
                internalType: "address",
                name: "_iTokenBorrowed",
                type: "address",
            },
            {
                internalType: "address",
                name: "_liquidator",
                type: "address",
            },
            {
                internalType: "address",
                name: "_borrower",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_seizedAmount",
                type: "uint256",
            },
        ],
        name: "afterSeize",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_iToken",
                type: "address",
            },
            {
                internalType: "address",
                name: "_from",
                type: "address",
            },
            {
                internalType: "address",
                name: "_to",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_amount",
                type: "uint256",
            },
        ],
        name: "afterTransfer",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_iToken",
                type: "address",
            },
            {
                internalType: "address",
                name: "_borrower",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_borrowAmount",
                type: "uint256",
            },
        ],
        name: "beforeBorrow",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_iToken",
                type: "address",
            },
            {
                internalType: "address",
                name: "_to",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_amount",
                type: "uint256",
            },
        ],
        name: "beforeFlashloan",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_iTokenBorrowed",
                type: "address",
            },
            {
                internalType: "address",
                name: "_iTokenCollateral",
                type: "address",
            },
            {
                internalType: "address",
                name: "_liquidator",
                type: "address",
            },
            {
                internalType: "address",
                name: "_borrower",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_repayAmount",
                type: "uint256",
            },
        ],
        name: "beforeLiquidateBorrow",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_iToken",
                type: "address",
            },
            {
                internalType: "address",
                name: "_minter",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_mintAmount",
                type: "uint256",
            },
        ],
        name: "beforeMint",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_iToken",
                type: "address",
            },
            {
                internalType: "address",
                name: "_redeemer",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_redeemAmount",
                type: "uint256",
            },
        ],
        name: "beforeRedeem",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_iToken",
                type: "address",
            },
            {
                internalType: "address",
                name: "_payer",
                type: "address",
            },
            {
                internalType: "address",
                name: "_borrower",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_repayAmount",
                type: "uint256",
            },
        ],
        name: "beforeRepayBorrow",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_iTokenCollateral",
                type: "address",
            },
            {
                internalType: "address",
                name: "_iTokenBorrowed",
                type: "address",
            },
            {
                internalType: "address",
                name: "_liquidator",
                type: "address",
            },
            {
                internalType: "address",
                name: "_borrower",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_seizeAmount",
                type: "uint256",
            },
        ],
        name: "beforeSeize",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_iToken",
                type: "address",
            },
            {
                internalType: "address",
                name: "_from",
                type: "address",
            },
            {
                internalType: "address",
                name: "_to",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_amount",
                type: "uint256",
            },
        ],
        name: "beforeTransfer",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_account",
                type: "address",
            },
        ],
        name: "calcAccountEquity",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "closeFactorMantissa",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_market",
                type: "address",
            },
            {
                internalType: "address",
                name: "_account",
                type: "address",
            },
        ],
        name: "enterMarketFromiToken",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address[]",
                name: "_iTokens",
                type: "address[]",
            },
        ],
        name: "enterMarkets",
        outputs: [
            {
                internalType: "bool[]",
                name: "_results",
                type: "bool[]",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address[]",
                name: "_iTokens",
                type: "address[]",
            },
        ],
        name: "exitMarkets",
        outputs: [
            {
                internalType: "bool[]",
                name: "_results",
                type: "bool[]",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "getAlliTokens",
        outputs: [
            {
                internalType: "address[]",
                name: "_alliTokens",
                type: "address[]",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_account",
                type: "address",
            },
        ],
        name: "getBorrowedAssets",
        outputs: [
            {
                internalType: "address[]",
                name: "_borrowedAssets",
                type: "address[]",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_account",
                type: "address",
            },
        ],
        name: "getEnteredMarkets",
        outputs: [
            {
                internalType: "address[]",
                name: "_accountCollaterals",
                type: "address[]",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_account",
                type: "address",
            },
            {
                internalType: "address",
                name: "_iToken",
                type: "address",
            },
        ],
        name: "hasBorrowed",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_account",
                type: "address",
            },
            {
                internalType: "address",
                name: "_iToken",
                type: "address",
            },
        ],
        name: "hasEnteredMarket",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_iToken",
                type: "address",
            },
        ],
        name: "hasiToken",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "initialize",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "isController",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_iTokenBorrowed",
                type: "address",
            },
            {
                internalType: "address",
                name: "_iTokenCollateral",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_actualRepayAmount",
                type: "uint256",
            },
        ],
        name: "liquidateCalculateSeizeTokens",
        outputs: [
            {
                internalType: "uint256",
                name: "_seizedTokenCollateral",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "liquidationIncentiveMantissa",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        name: "markets",
        outputs: [
            {
                internalType: "uint256",
                name: "collateralFactorMantissa",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "borrowFactorMantissa",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "borrowCapacity",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "supplyCapacity",
                type: "uint256",
            },
            {
                internalType: "bool",
                name: "mintPaused",
                type: "bool",
            },
            {
                internalType: "bool",
                name: "redeemPaused",
                type: "bool",
            },
            {
                internalType: "bool",
                name: "borrowPaused",
                type: "bool",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "owner",
        outputs: [
            {
                internalType: "address payable",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "pauseGuardian",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "pendingOwner",
        outputs: [
            {
                internalType: "address payable",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "priceOracle",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "rewardDistributor",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "seizePaused",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "transferPaused",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_iToken",
                type: "address",
            },
        ],
        name: "marketsV2",
        outputs: [
            {
                components: [
                    {
                        internalType: "uint256",
                        name: "collateralFactorMantissa",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "borrowFactorMantissa",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "borrowCapacity",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "supplyCapacity",
                        type: "uint256",
                    },
                    {
                        internalType: "bool",
                        name: "mintPaused",
                        type: "bool",
                    },
                    {
                        internalType: "bool",
                        name: "redeemPaused",
                        type: "bool",
                    },
                    {
                        internalType: "bool",
                        name: "borrowPaused",
                        type: "bool",
                    },
                    {
                        internalType: "uint8",
                        name: "sModeID",
                        type: "uint8",
                    },
                    {
                        internalType: "bool",
                        name: "borrowableInSegregation",
                        type: "bool",
                    },
                    {
                        internalType: "uint256",
                        name: "debtCeiling",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "currentDebt",
                        type: "uint256",
                    },
                ],
                internalType: "struct ControllerStorageV2Extra.MarketV2",
                name: "_market",
                type: "tuple",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_account",
                type: "address",
            },
        ],
        name: "getSegregationModeState",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        name: "accountsSMode",
        outputs: [
            {
                internalType: "uint8",
                name: "",
                type: "uint8",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_iToken",
                type: "address",
            },
        ],
        name: "getLTV",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_iToken",
                type: "address",
            },
        ],
        name: "getSModeLTV",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_iToken",
                type: "address",
            },
        ],
        name: "getLiquidationThreshold",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_iToken",
                type: "address",
            },
        ],
        name: "getSModeLiquidationThreshold",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "getSModeLength",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        name: "sModes",
        outputs: [
            {
                internalType: "uint256",
                name: "liquidationIncentive",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "closeFactor",
                type: "uint256",
            },
            {
                internalType: "string",
                name: "label",
                type: "string",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "timeLockStrategy",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "timeLock",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
];
