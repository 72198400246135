
export const contractAddress = {
    "71": "0xA2aa501b19aff244D90cc15a4Cf739D2725B5729",
    "1030": "0xe9d69CdD6Fe41e7B621B4A688C5D1a68cB5c8ADc"
}

export const connectionURL = {
    "71": "https://xc-testnet.pyth.network",
    "1030": "https://xc-mainnet.pyth.network"
}

export const priceIdsConf = {
    "71": [
        // You can find the ids of prices at https://pyth.network/developers/price-feed-ids#pyth-evm-testnet
        "0xf9c0172ba10dfa4d19088d94f5bf61d3b54d5bd7483a322a982e1373ee8ea31b", // BTC/USD price id in testnet
        "0xca80ba6dc32e08d06f1aa886011eed1d77c77be9eb761cc10d72b7d0a2fd57a6", // ETH/USD price id in testnet
        "0x1fc18861232290221461220bd4e2acd1dcdfbc89c84092c93c18bdc7756c1588", // USDT/USD price id in testnet
        "0x41f3625971ca2ed2263e78573fe5ce23e13d2558ed3f2e47ab0f84fb9e7ae722", // USDC/USD price id in testnet
        "0xeba2144f04b3af59382d92b8cbc3170008b4a2945a01f36e81f92dfdeb8cc519", // CFX/USD price id in testnet
    ],
    "1030": [
        // You can find the ids of prices at https://pyth.network/developers/price-feed-ids#pyth-evm-mainnet
        // "0x8879170230c9603342f3837cf9a8e76c61791198fb1271bb2552c9af7b33c933", // CFX/USD price id in mainnet
        "0xe62df6c8b4a85fe1a67db44dc12de5db330f7ac66b72dc658afedf0f4a415b43", // BTC/USD price id in mainnet
        "0xff61491a931112ddf1bd8147cd1b641375f79f5825126d665480874634fd0ace", // ETH/USD price id in mainnet
        "0x2b89b9dc8fdf9f34709a5b106b472f0f39bb6ca9ce04b0fd7f2e971688e2e53b", // USDT/USD price id in mainnet
        "0xeaa020c61cc479712813461ce153894a96a6c00b21ed0cfc2798d1f9a9e9c94a", // USDC/USD price id in mainnet
    ]
}