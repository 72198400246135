import React from "react";
import type { CoinbaseWallet } from "@web3-react/coinbase-wallet";
import type { Web3ReactHooks } from "@web3-react/core";
import type { GnosisSafe } from "@web3-react/gnosis-safe";
import type { MetaMask } from "@web3-react/metamask";
import type { Network } from "@web3-react/network";
import type { WalletConnect } from "@web3-react/walletconnect";
import type { WalletConnect as WalletConnectV2 } from "@web3-react/walletconnect-v2";
import IERC20 from "../lending-sdk/lib/clean_build/contracts/general/IERC20.json";
import styled from "styled-components";
// import CorePrimaryConfig from "../contracts/CorePrimary.json"
import { getName } from "../utils";
import { Account } from "./Account";
import { Chain } from "./Chain";
import { ConnectWithSelect } from "./ConnectWithSelect";
import { Status } from "./Status";
// import { ADDRESS_LINK } from "../constants/index";
import { etherscan } from "../lending-sdk/utils/index1";

// import { IconLaunch,IconCopy } from '@arco-design/web-react/icon'
import { useCallback, useState } from "react";
import { IconExternalLink, IconCopy } from "@arco-iconbox/react-lsd";
import TransactionsHistory from "components/TransactionsHistory";

interface Props {
  connector:
    | MetaMask
    | WalletConnect
    | WalletConnectV2
    | CoinbaseWallet
    | Network
    | GnosisSafe;
  activeChainId: ReturnType<Web3ReactHooks["useChainId"]>;
  chainIds?: ReturnType<Web3ReactHooks["useChainId"]>[];
  isActivating: ReturnType<Web3ReactHooks["useIsActivating"]>;
  isActive: ReturnType<Web3ReactHooks["useIsActive"]>;
  error: any;
  setError: (arg0: any) => void;
  ENSName: ReturnType<Web3ReactHooks["useENSName"]>;
  ENSNames: ReturnType<Web3ReactHooks["useENSNames"]>;
  provider?: ReturnType<Web3ReactHooks["useProvider"]>;
  accounts?: string[];
  account?: string;
}

export function Card({
  connector,
  activeChainId,
  chainIds,
  isActivating,
  isActive,
  error,
  setError,
  ENSName,
  ENSNames,
  account,
  accounts,
  provider,
}: Props) {
  const supportchain = activeChainId
    ? Object.keys(IERC20.networks).includes(
        (activeChainId as number).toString()
      )
    : false;

  const [isCopy, setIsCopy] = useState<boolean>(false);
  const copyToClipboard = useCallback((s: string) => {
    setIsCopy(true);
    if (window.clipboardData) {
      window.clipboardData.setData("text", s);
    } else {
      (function (s) {
        document.oncopy = (e: any) => {
          e.clipboardData.setData("text", s);
          e.preventDefault();
          document.oncopy = null;
        };
      })(s);
      document.execCommand("Copy");
    }
    setTimeout(() => {
      setIsCopy(false);
    }, 500);
  }, []);
  return (
    <ModalBox>
    <_Card>
      {account && activeChainId && supportchain && (
        <_CardLeft>
          {/* <b>{getName(connector)}</b> */}
          <ConnectText>
            {/* <Status isActivating={isActivating} isActive={isActive} error={error} /> */}
            Connected with {getName(connector) === 'MetaMask' ? 'Browser Wallet' : getName(connector)}
          </ConnectText>
          <AccountWarp>
            <Account account={account} provider={provider} ENSName={ENSName} />
            {/* <Chain chainId={activeChainId} /> */}
          </AccountWarp>
          <CopyWarp>
            <CopyAddress
              className={
                isCopy ? "twinkling" : ""
              }
              onClick={() => copyToClipboard(account as string)}
            >
              {/* <IconCopy /> */}
              <IconCopy onPointerEnterCapture={true} onPointerLeaveCapture={true}/>
              <span className="ml-1">Copy address</span>
            </CopyAddress>
            <a
              href={
                activeChainId
                  ? `${(etherscan as any)[activeChainId as number]}${account}`
                  : "#"
              }
              target="_blank"
              rel="noreferrer"
            >
              {/* <IconLaunch /> */}
              <IconExternalLink onPointerEnterCapture={true} onPointerLeaveCapture={true}/>
              <span className="ml-1">View on Explorer</span>
            </a>
          </CopyWarp>
        </_CardLeft>
      )}

      <ConnectWithSelect
        connector={connector}
        activeChainId={activeChainId}
        chainIds={chainIds}
        isActivating={isActivating}
        isActive={isActive}
        error={error}
        setError={setError}
      />
    </_Card>
    {account && <TransactionsHistory/>}
    </ModalBox>
  );
}
const ModalBox = styled.div`
  display:flex;
  flex-direction: column;
  flex: 1 1 0%;
`
const _Card = styled.div`
  display: flex;
  justify-content: center;
  width: auto;
  position: relative;
  /* flex: 1 1 0%; */
  background-color: #f4f6fa;
  border-radius: 16px;
  padding: 24px;
  @media (max-width: 1199px) {
    padding: 6px;
  }
`;
const _CardLeft = styled.div`
  display:flex;
  justify-content: space-between;
  flex-direction: column;
`;
const ConnectText = styled.div`
  font-size:18px;
  color:#7c8c9c;
  @media (max-width: 1199px) {
    font-size:14px
  }
`
const AccountWarp = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin:12px 0;
`
const CopyWarp = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  font-weight:500;
  a{
    display: flex;
      align-items: center;
      color: rgb(59, 130, 246);
      margin-left:12px;
      span{
        margin-left:4px;
      }
  }
  @media (max-width: 1199px) {
    width: 125% !important;
    font-size:14px
  }
`
const CopyAddress = styled.div`
      display: flex;
      align-items: center;
      color: rgb(59, 130, 246);
      span{
        margin-left:4px;
      }
`
