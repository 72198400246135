import React, { useEffect } from 'react'
import { useQuery } from 'utils'

const scrollToTop = () => {
  const c = document.querySelector('#scrollWarp')?.scrollTop
  if (c && c > 0) {
    window.requestAnimationFrame(scrollToTop)
    document.querySelector('#scrollWarp')?.scrollTo(0, c - c / 1)
  }
}

const ScrollToTop = ({ children }: {
  children: React.ReactNode
}) => {
  const query = useQuery()
  useEffect(() => {
    scrollToTop()
  }, [query])
  return (
    <>{children}</>
  )
}
export default ScrollToTop