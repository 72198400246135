import React, { useState, useEffect, useCallback } from "react";
import styled, { keyframes } from "styled-components";
import { useIntl } from "react-intl";
import numeral from "numeral";
import { MarketContextValues } from "contexts/MarketOverview";
import Bar from "./Bar";
interface MarketOverviewProps {
  supplyOverview: MarketContextValues[];
  mintOverview: MarketContextValues[];
  hasSynthsLink: boolean
}
const MarketOverview: React.FC<MarketOverviewProps> = ({
  supplyOverview,
  mintOverview,
  hasSynthsLink
}) => {
  const intl = useIntl();
  const fmt = intl.formatMessage;
  const supply_markets = [...supplyOverview];
  const borrow_markets = [...supplyOverview];
  const mint_markets = [...mintOverview];
  const [supplyList, setSupplyList] = useState<MarketContextValues[]>([]);
  const [borrowList, setBorrowList] = useState<MarketContextValues[]>([]);
  const [mintList, setMintList] = useState<MarketContextValues[]>([]);
  const sortSupply = useCallback(() => {
    const newSupplyList = supply_markets.sort(
      (a, b) => Number(b.supply_progress) - Number(a.supply_progress)
    );
    setSupplyList(newSupplyList);
  }, [supplyOverview]);
  const sortBorrow = useCallback(() => {
    const newBorrowList = borrow_markets.sort(
      (a, b) => Number(b.borrow_progress) - Number(a.borrow_progress)
    );
    setBorrowList(newBorrowList);
  }, [supplyOverview]);
  const sortMint = useCallback(() => {
    const newMintList = mint_markets.sort(
      (a, b) => Number(b.borrow_progress) - Number(a.borrow_progress)
    );
    setMintList(newMintList);
  }, [mintOverview]);
  useEffect(() => {
    sortSupply();
    sortBorrow();
    sortMint();
  }, [supplyOverview, mintOverview, setSupplyList, setBorrowList, setMintList]);


  return (
    <>
      {supplyOverview.length > 0 ? (
        <>
          <Warp>
            <Overview>
              <OverviewTitle>{fmt({ id: "Top_Supply" })}</OverviewTitle>
              <OverFlow>
                <OverviewList>
                  {supplyList.map((item) => (
                    <OverviewItem key={item.address}>
                      <TokenWrap>
                        <TokenLeft>
                          <TokenIcon src={item.icon} />
                          <TokenSymbol>{item.reveal_symbol}</TokenSymbol>
                        </TokenLeft>
                        <Progress className="supply">
                          {item.supply_progress &&
                            item.supply_progress > 0 &&
                            item.supply_progress < 0.0001
                            ? "<0.01%"
                            : numeral(item.supply_progress).format("0.00%")}
                        </Progress>
                      </TokenWrap>
                      <Bar
                        type={"buffer"}
                        value={Number(item.supply_progress) * 100}
                        bgColor="rgba(250, 155, 0, 0.15)"
                        innerBgColor={'#FF764B'}
                      ></Bar>
                    </OverviewItem>
                  ))}
                </OverviewList>
              </OverFlow>
            </Overview>
            <Overview>
              <OverviewTitle>{fmt({ id: "Top_Borrow" })}</OverviewTitle>
              <OverFlow>
                <OverviewList>
                  {borrowList.map((item) => (
                    <OverviewItem key={item.address}>
                      <TokenWrap>
                        <TokenLeft>
                          <TokenIcon src={item.icon} />
                          <TokenSymbol>{item.reveal_symbol}</TokenSymbol>
                        </TokenLeft>
                        <Progress className="borrow">
                          {item.borrow_progress &&
                            item.borrow_progress > 0 &&
                            item.borrow_progress < 0.0001
                            ? "<0.01%"
                            : numeral(item.borrow_progress).format("0.00%")}
                        </Progress>
                      </TokenWrap>
                      <Bar
                        type={"buffer"}
                        value={Number(item.borrow_progress) * 100}
                        bgColor="rgba(59, 160, 146, 0.15)"
                        innerBgColor="#05C7A0"
                      ></Bar>
                    </OverviewItem>
                  ))}
                </OverviewList>
              </OverFlow>
            </Overview>
            {
              hasSynthsLink &&
              <Overview>
                <OverviewTitle>{fmt({ id: "Top_Mint" })}</OverviewTitle>
                <OverFlow>
                  <OverviewList>
                    {mintList.map((item) => (
                      <OverviewItem key={item.address}>
                        <TokenWrap>
                          <TokenLeft>
                            <TokenIcon src={item.icon} />
                            <TokenSymbol>{item.reveal_symbol}</TokenSymbol>
                          </TokenLeft>
                          <Progress className="mint">
                            {item.borrow_progress &&
                              item.borrow_progress > 0 &&
                              item.borrow_progress < 0.0001
                              ? "<0.01%"
                              : numeral(item.borrow_progress).format("0.00%")}
                          </Progress>
                        </TokenWrap>
                        <Bar
                          type={"buffer"}
                          value={Number(item.borrow_progress) * 100}
                          bgColor="rgba(78,121,247,0.15)"
                          innerBgColor="#507BFC"
                        ></Bar>
                      </OverviewItem>
                    ))}
                  </OverviewList>
                </OverFlow>
              </Overview>
            }
          </Warp>
        </>
      ) : (
        <>
          <Warp>
            <Overview>
              <OverviewTitle>{fmt({ id: "Top_Supply" })}</OverviewTitle>
              <OverFlow>
                <OverviewList>
                  {Array.from({ length: 4 }).map((key, index) => (
                    <OverviewItem key={index}>
                      <TokenWrap>
                        <TokenLeft>
                          <TokenIconBg className="TokenIconBg" />
                          <TokenSymbol className="TokenSymbolBg" />
                        </TokenLeft>
                        <Progress className="supply">...</Progress>
                      </TokenWrap>
                      <Bar
                        type={"buffer"}
                        value={0}
                        bgColor="#FFF0D9"
                        innerBgColor={`${(props: any) => props.theme.global_supply}`}
                      ></Bar>
                    </OverviewItem>
                  ))}
                </OverviewList>
              </OverFlow>
            </Overview>
            <Overview>
              <OverviewTitle>{fmt({ id: "Top_Borrow" })}</OverviewTitle>
              <OverFlow>
                <OverviewList>
                  {Array.from({ length: 4 }).map((key, index) => (
                    <OverviewItem key={index}>
                      <TokenWrap>
                        <TokenLeft>
                          <TokenIconBg className="TokenIconBg" />
                          <TokenSymbol className="TokenSymbolBg" />
                        </TokenLeft>
                        <Progress className="borrow">...</Progress>
                      </TokenWrap>
                      <Bar
                        type={"buffer"}
                        value={0}
                        bgColor="#DEEDEB"
                        innerBgColor="#05C7A0"
                      ></Bar>
                    </OverviewItem>
                  ))}
                </OverviewList>
              </OverFlow>
            </Overview>
            {
              hasSynthsLink &&
              <Overview>
                <OverviewTitle>{fmt({ id: "Top_Mint" })}</OverviewTitle>
                <OverFlow>
                  <OverviewList>
                    {Array.from({ length: 4 }).map((key, index) => (
                      <OverviewItem key={index}>
                        <TokenWrap>
                          <TokenLeft>
                            <TokenIconBg className="TokenIconBg" />
                            <TokenSymbol className="TokenSymbolBg" />
                          </TokenLeft>
                          <Progress className="mint">...</Progress>
                        </TokenWrap>
                        <Bar
                          type={"buffer"}
                          value={0}
                          bgColor="#dee4f8"
                          innerBgColor="#507BFC"
                        ></Bar>
                      </OverviewItem>
                    ))}
                  </OverviewList>
                </OverFlow>
              </Overview>
            }
          </Warp>
        </>
      )}
    </>
  )
}



const flash = keyframes`
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
`;
const Warp = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  /* background-color: red; */
  /* padding-top: 16px; */
  @media (max-width: 767px) {
    width: 100%;
    box-sizing: border-box;
    flex-direction: column;
    margin-bottom: 10px;
  }
`;
const Overview = styled.section`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right:16px;
  height: 346px;
  overflow-y: auto;
  border-radius: 16px;
  padding: 0 24px 5px;
  box-sizing: border-box;
  color: ${(props) => props.theme.colors.black};
  background-color: #fff;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.1);
  .dark & {
    color: ${(props) => props.theme.colors.white};
    background-color: #131313;
  }
  &:last-child{
    margin-right: 0;
  }
  @media (max-width: 767px) {
    width: 100%;
    height: 277px;
    -webkit-overflow-scrolling: touch;
    padding: 0 10px 0;
    box-sizing: border-box;
    margin:0;
    margin-bottom: 10px;
    flex: none;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;
const OverFlow = styled.div`
  @media (max-width: 767px) {
    /* min-height: 150px; */
    /* margin-bottom: 20px; */
  }
`;
const OverviewTitle = styled.h2`
  font-size: 16px;
  font-weight: bold;
  height: 66px;
  line-height: 66px;
  color: ${(props) => props.theme.DarkfontColor[100]};
  border-bottom: 1px solid ${(props) => props.theme.colors.lightTableBorder};
      .dark & {
        border-bottom: 1px solid ${(props) => props.theme.colors.darkTableBorder};
      }
  margin: 0;
  .dark & {
    color: ${(props) => props.theme.fontColor[100]};
  }
  @media (max-width: 767px) {
    height: 50px;
    line-height: 50px;
    margin: 0;
    font-size: 14px;
  }
`;
const OverviewList = styled.ul`
  display: flex;
  flex-direction: column;
`;
const OverviewItem = styled.li`
  display: flex;
  flex-direction: column;
`;
const TokenSymbol = styled.span`
  display: block;
  min-width: 50px;
  line-height: 60px;
  font-size: 14px;
  font-weight: 500;
  /* position: relative; */
  &.TokenSymbolBg {
    width: 50px;
    height: 18px;
    animation: ${flash} 1.2s linear infinite forwards;
    background: #e1e5ee;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 800px 100px;
    /* position: relative; */
  }
  @media (max-width: 767px) {
    height: 18px;
    line-height: 18px;
    font-size: 12px;
  }
`;
const TokenWrap = styled.div`
  display: flex;
  justify-content: space-between;
  /* position: relative; */
`;
const TokenLeft = styled.div`
  height: auto;
  overflow: hidden;
  margin-right: 10px;
  display: flex;
  align-items: center;
  @media (max-width: 767px) {
    min-height: 50px;
    line-height: 50px;
  }
`;
const TokenIcon = styled.img`
  display: block;
  width: 26px;
  height: 26px;
  margin-right: 12px;
`;
const TokenIconBg = styled.div`
  display: block;
  width: 26px;
  height: 26px;
  margin-right: 12px;
  /* position: relative; */
  &.TokenIconBg {
    border-radius: 50%;
    animation: ${flash} 1.2s linear infinite forwards;
    background: #e1e5ee;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 800px 100px;
    /* position: relative; */
  }
`;
const Progress = styled.div`
  height: 60px;
  font-size: 14px;
  font-weight: 600;
  line-height: 60px;
  &.supply {
    color: ${(props) => props.theme.colors.supply.dark};
  }
  &.borrow {
    color: ${(props) => props.theme.colors.borrow.dark};
  }
  &.mint {
    color: ${(props) => props.theme.colors.mint.dark};
  }
  @media (max-width: 767px) {
    height: 50px;
    line-height: 50px;
  }
`;
export default MarketOverview;
