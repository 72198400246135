import React from 'react'
import styled from 'styled-components'

interface ModalContentProps {
  text?: string,
  children: React.ReactNode
}

const ModalContent: React.FC<ModalContentProps> = ({ text, children }) => (
  <StyledModalContent>
    {children}
    {text}
  </StyledModalContent>
)

const StyledModalContent = styled.div`
  display: flex;
  flex-direction: column;
`

export default ModalContent