import Web3 from 'web3';
import {
  Contracts
} from './lib/contracts.js';

export class Lending {
  constructor(
    provider,
    networkId,
    options
  ) {
    var realProvider;
    if (typeof provider === 'string') {
      if (provider.includes("wss")) {
        realProvider = new Web3.providers.WebsocketProvider(
          provider,
          options.ethereumNodeTimeout || 15000,
        );
      } 
      // else {
      //   realProvider = new Web3.providers.HttpProvider(
      //     provider,
      //     options.ethereumNodeTimeout || 15000,
      //   );
      // }
    } else {
      realProvider = Web3.givenProvider;
    }
    // this.web3 = new Web3(Web3.givenProvider) 
    this.web3 = new Web3(realProvider || new Web3.providers.WebsocketProvider(
      provider,
      options.ethereumNodeTimeout || 15000,
    ));
    // this.web3 = new Web3(window?.ethereum);
    
    if (options.defaultAccount) {
      this.web3.eth.defaultAccount = options.defaultAccount;
    }
    this.contracts = new Contracts(realProvider, networkId, this.web3, options)
    this.accounts = [];
    // this.markets = [];
    this.markets = [];
    this.prices = {};
    this.allocations = {};
    this.rates = {};
    this.aprs = {};
    this.poolWeis = {};
    this.platformInfo = {};
  }

  setProvider(
    provider,
    networkId
  ) {
    this.web3.setProvider(provider);
    this.contracts.setProvider(provider, networkId);
    this.operation.setNetworkId(networkId);
  }

  setDefaultAccount(
    account
  ) {
    this.web3.eth.defaultAccount = account;
    this.contracts.setDefaultAccount(account);
  }

  getDefaultAccount() {
    return this.web3.eth.defaultAccount;
  }

}
