import { useCallback, useEffect, useState } from 'react'
import BigNumber from 'bignumber.js'
import { useWeb3React } from '@web3-react/core'
import { getAllowance } from 'utils'
import Web3 from 'web3';


const useAllowance = (tokenAddress?: string, spenderAddress?: string) => {
  const [allowance, setAllowance] = useState<BigNumber>()
  const {
    connector,
    account,
    chainId,
    provider,
    ENSName,
    isActive,
    isActivating,
  } = useWeb3React();


  const fetchAllowance = useCallback(async (userAddress: string, provider: any) => {
    // console.log(userAddress, spenderAddress, tokenAddress, provider)
    if (!spenderAddress || !tokenAddress || !userAddress || !provider) {
      return
    }
    const allowance = await getAllowance(userAddress, spenderAddress, tokenAddress, provider)
    // console.log('allowance: ',allowance)
    setAllowance(new BigNumber(allowance))
  }, [setAllowance, spenderAddress, tokenAddress])


  useEffect(() => {
    // console.log(account, ethereum, spenderAddress, tokenAddress)
    if (account && spenderAddress && tokenAddress) {
      fetchAllowance(account, Web3.givenProvider)
      // console.log(account, ethereum)

      let refreshInterval = setInterval(() => {
        fetchAllowance(account, Web3.givenProvider)
      }, 1000 * 10)
      return () => clearInterval(refreshInterval)
    }
  }, [account, spenderAddress, tokenAddress])

  return allowance
}

export default useAllowance