import React, {
  useState,
  useEffect,
  useCallback,
  useMemo
} from "react";
import { useParams, useNavigate, useLocation, NavLink as RouterLink } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { useIntl } from "react-intl";
import { _formatNumberNotCarry, bnToDec, formate_round_up } from "utils";
import { rewardRate } from 'constants/constants';
import numeral from "numeral";
import BigNumber from "bignumber.js";
import { MarketContextValues } from "contexts/MarketOverview";

interface AllMarketsListProps {
  supplyMarkets: MarketContextValues[];
  // mintMarkets: MarketContextValues[];
  hasSynthsLink: boolean
}

const AllMarketsList: React.FC<AllMarketsListProps> = ({
  supplyMarkets,
  // mintMarkets,
  hasSynthsLink
}) => {
  const fetchMarkets = JSON.parse(window.sessionStorage.getItem("marketsList") ?? '[]')
  const intl = useIntl();
  const fmt = intl.formatMessage;
  const [supplyAPY, setSupplyAPY] = useState(0);
  const [borrowAPY, setBorrowAPY] = useState(0);
  const [marketSize, setMarketSize] = useState(0);
  const [borrowValue, setBorrowValue] = useState(0);
  const [showFreeze, setShowFreeze] = useState(false);
  const [isHasFrozen, setIsHasFrozen] = useState(false);

  const navigate = useNavigate()
  const { currentNetwork } = useParams<any>()

  const [render_markets, setRender_markets] = useState<MarketContextValues[]>(
    []
  );
  let sort_markets_data: MarketContextValues[];
  const [sortMarketsData, setSortMarketsData] = useState<
    MarketContextValues[]
  >([]);
  const sortData = useCallback(
    (
      current_status: number,
      current_Dispatch: React.Dispatch<React.SetStateAction<number>>,
      current_type: string
    ) => {
      // 每次先重置 所有排序
      setSupplyAPY(0);
      setBorrowAPY(0);
      setMarketSize(0);
      setBorrowValue(0);
      if (current_status === 0) {
        current_Dispatch(1);
        sort_markets_data = render_markets.sort(
          (a, b) => Number(b[current_type]) - Number(a[current_type])
        );
      } else if (current_status === 1) {
        current_Dispatch(-1);
        sort_markets_data = render_markets.sort(
          (a, b) => Number(a[current_type]) - Number(b[current_type])
        );
      } else if (current_status === -1) {
        current_Dispatch(0);
        // 切换tab资产类型之后 排序处理
        sort_markets_data = fetchMarkets;
      }
      setSortMarketsData(sort_markets_data);
      // setRender_markets(sort_markets_data);
    },
    [render_markets]
  );
  useEffect(() => {
    if (supplyMarkets.length > 0) {
      //每次轮询 取API最新数据进行更新
      const _fetchMarkets = supplyMarkets
      // const MainCryptos = [...markets].filter(item=>item.assetsType === 'MainCryptos')
      // const Synth = [...markets].filter(item=>item.assetsType === 'Synth')
      // const LP = [...markets].filter(item=>item.assetsType === 'LP')
      // const Stable = [...markets].filter(item=>item.assetsType === 'Stable')
      if (sortMarketsData.length > 0) {
        const updateSortMarketsData = sortMarketsData.reduce(
          (result: MarketContextValues[], item) => {
            const currentItem = _fetchMarkets.find(
              (item2) => item2.address === item.address
            );
            if (currentItem) {
              result.push(currentItem);
            }
            return result;
          },
          []
        );
        setRender_markets(updateSortMarketsData);
      } else {
        setRender_markets(supplyMarkets);
      }
      // else {
      //   switch (assetsType) {
      //     case "all":
      //       // const fetchMarkets = [...markets];
      //       setRender_markets(assetsData[assetsType]);
      //       break;
      //     case "MainCryptos":
      //       setRender_markets(assetsData[assetsType]);
      //       break;
      //     case "Synth":
      //       setRender_markets(assetsData[assetsType]);
      //       break;
      //     case "LP":
      //       setRender_markets(assetsData[assetsType]);
      //       break;
      //     case "Stable":
      //       setRender_markets(assetsData[assetsType]);
      //       setAssetsType("Stable");
      //       break;
      //     default:
      //       break;
      //   }
      // }
    } else {
      setRender_markets(supplyMarkets);
    }
  }, [supplyMarkets, sortMarketsData]);
  const checkboxChange = (e: any) => {
    // console.log(e)
    setShowFreeze(!showFreeze);
  }

  useMemo(() => {
    if (render_markets.length > 0) {
      let tmpBool = false
      render_markets.map((item) => {
        if (item.isFreeze) {
          // console.log(item)
          tmpBool = true
        }
      })
      setIsHasFrozen(tmpBool)
    }
  }, [render_markets])


  const pathToDetails = (arg: any) => {
    // console.log(arg)
    // console.log(currentNetwork)
    navigate(`/MarketsDetails/${currentNetwork}/${arg.underlying_symbol}`)
  }


  return (
    <>
      <TableWrap>
        <Table>
          <thead>
            <tr>
              <TheadBar className="spe">
                {fmt({ id: "Supply_Markets" })}
                {
                  isHasFrozen &&
                  <FreezeBox>
                    <label htmlFor={"toggle-btn"}><span>Show Frozen Assets</span></label>
                    <input
                      className="switch"
                      type="checkbox"
                      id={"toggle-btn"}
                      checked={showFreeze ? true : false}
                      onChange={(e) => { checkboxChange(e.target.checked) }} />
                  </FreezeBox>
                }
              </TheadBar>
            </tr>
            <tr>
              <th className="firstTh">{fmt({ id: "Markets_Assets" })}</th>
              <th>
                <SortArea
                  onClick={(): void =>
                    sortData(supplyAPY, setSupplyAPY, "mainingSupplyApy")
                  }
                >
                  {/* {fmt({ id: "supply_apy" })} */}
                  <div className="specialApy">
                    <div>
                      <span>{fmt({ id: "Supply_APY" })}</span>
                    </div>
                    <div className="bottomAPY">
                      <span>{fmt({ id: "Reward_to_MAX" })}</span>
                    </div>
                  </div>
                  <i className={"active" + supplyAPY}></i>
                </SortArea>
              </th>
              <th>
                <SortArea
                  onClick={(): void =>
                    sortData(borrowAPY, setBorrowAPY, "mainingBorrowApy")
                  }
                >
                  {/* {fmt({ id: "borrow_apy" })} */}
                  <div className="specialApy">
                    <div>
                      <span>{fmt({ id: "Borrow_APY" })}</span>
                    </div>
                    <div className="bottomAPY">
                      <span>{fmt({ id: "Reward_to_MAX" })}</span>
                    </div>
                  </div>
                  <i className={"active" + borrowAPY}></i>
                </SortArea>
              </th>
              <th className="marketSize">
                <SortArea
                  onClick={(): void =>
                    sortData(marketSize, setMarketSize, "supplyValue")
                  }
                >
                  {fmt({ id: "Total_Supply" })}
                  <i className={"active" + marketSize}></i>
                </SortArea>
              </th>
              <th className="marketSize">
                <SortArea
                  onClick={(): void =>
                    sortData(borrowValue, setBorrowValue, "borrowValue")
                  }
                >
                  {fmt({ id: "Total_Borrow" })}
                  <i className={"active" + borrowValue}></i>
                </SortArea>
              </th>
            </tr>
          </thead>
          <tbody>
            {render_markets.length > 0
              ? render_markets.map((item) => {
                if (item.isFreeze) { return false }
                // console.log(item)
                return (
                  <tr
                    onClick={() => { pathToDetails(item) }}
                    className="hoverstyle"
                    key={item.address}>
                    <td className="firstTd">
                      <TokenIconWrap>
                        {/* {item.isFreeze && <StyledFreeze>Frozen</StyledFreeze>} */}
                        <TokenIcon src={item.icon} />
                      </TokenIconWrap>
                      <TokenSymbol>{item.reveal_symbol}</TokenSymbol>
                    </td>
                    <td className="specialApy">
                      <div className="supply">
                        <span>
                          {
                            item.supplyAPY ?
                              bnToDec(new BigNumber(item.supplyAPY).abs(), 18) > 9999.99
                                ?
                                '999,999%'
                                :
                                _formatNumberNotCarry(bnToDec(new BigNumber(item.supplyAPY), 18, 4), 'percent')
                              : '...'
                            // numeral(
                            //   numeral(
                            //     bnToDec(new BigNumber(item.mainingSupplyApy), 18, 4)
                            //   ).format("0.0000")
                            // ).format("0,0.00%")
                          }
                        </span>
                      </div>
                      {
                        (item.SupplyRewardAPY && item.MinimumSupplyRewardAPY && item.DailyDistribution !== '0') &&
                        <div className="APR">
                          <img src="/Unitus.svg" />
                          <span>
                            {
                              bnToDec(new BigNumber(item.SupplyRewardAPY).abs(), 18) > 9999.99
                                ?
                                <div>{_formatNumberNotCarry(bnToDec(new BigNumber(item.MinimumSupplyRewardAPY), 18, 6), 'percent')}~<i>999,999%</i></div>
                                :
                                <div>{_formatNumberNotCarry(bnToDec(new BigNumber(item.MinimumSupplyRewardAPY), 18, 6), 'percent')}~<i>{_formatNumberNotCarry(bnToDec(new BigNumber(item.SupplyRewardAPY), 18, 6), 'percent')}</i></div>
                            }
                          </span>
                        </div>
                        // :<div className="APR"><span>...</span></div>
                      }

                      {
                        (item.SupplyRewardAPYArb && item.MinimumSupplyRewardAPYArb && item.DailyDistributionArb !== '0') &&
                        <div className="APR">
                          <img src="/ARB.svg" />
                          <span>
                            {

                              bnToDec(new BigNumber(item.SupplyRewardAPYArb).abs(), 18) > 9999.99
                                ?
                                <div>{_formatNumberNotCarry(bnToDec(new BigNumber(item.MinimumSupplyRewardAPYArb), 18, 6), 'percent')}~<i>999,999%</i></div>
                                :
                                <div>{_formatNumberNotCarry(bnToDec(new BigNumber(item.MinimumSupplyRewardAPYArb), 18, 6), 'percent')}~<i>{_formatNumberNotCarry(bnToDec(new BigNumber(item.SupplyRewardAPYArb), 18, 6), 'percent')}</i></div>
                            }
                          </span>
                        </div>
                      }
                    </td>
                    <td className="specialApy">
                      <div className="borrow">
                        <span>
                          {
                            item.borrowAPY ?
                              bnToDec(new BigNumber(item.borrowAPY).abs(), 18) > 9999.99
                                ?
                                '999,999%'
                                :
                                _formatNumberNotCarry(bnToDec(new BigNumber(item.borrowAPY), 18, 4), 'percent')
                              : '...'
                            // numeral(
                            //   numeral(
                            //     bnToDec(new BigNumber(item.mainingBorrowApy).abs(), 18, 4)
                            //   ).format("0.0000")
                            // ).format("0,0.00%")
                          }
                        </span>
                      </div>
                      {
                        (item.BorrowRewardAPY && item.MinimumBorrowRewardAPY && item.DailyDistribution !== '0') &&
                        <div className="APR">
                          <img src="/Unitus.svg" />
                          <span>
                            {
                              bnToDec(new BigNumber(item.BorrowRewardAPY).abs(), 18) > 9999.99
                                ?
                                <div>{_formatNumberNotCarry(bnToDec(new BigNumber(item.MinimumBorrowRewardAPY), 18, 6), 'percent')}~<i>999,999%</i></div>
                                :
                                <div>{_formatNumberNotCarry(bnToDec(new BigNumber(item.MinimumBorrowRewardAPY), 18, 6), 'percent')}~<i>{_formatNumberNotCarry(bnToDec(new BigNumber(item.BorrowRewardAPY), 18, 6), 'percent')}</i></div>
                            }
                          </span>
                        </div>
                        // :<div className="APR"><span>...</span></div>
                      }

                      {
                        (item.BorrowRewardAPYArb && item.MinimumBorrowRewardAPYArb && item.DailyDistributionArb !== '0') &&
                        <div className="APR">
                          <img src="/ARB.svg" />
                          <span>
                            {
                              bnToDec(new BigNumber(item.BorrowRewardAPYArb).abs(), 18) > 9999.99
                                ?
                                <div>{_formatNumberNotCarry(bnToDec(new BigNumber(item.MinimumBorrowRewardAPYArb), 18, 6), 'percent')}~<i>999,999%</i></div>
                                :
                                <div>{_formatNumberNotCarry(bnToDec(new BigNumber(item.MinimumBorrowRewardAPYArb), 18, 6), 'percent')}~<i>{_formatNumberNotCarry(bnToDec(new BigNumber(item.BorrowRewardAPYArb), 18, 6), 'percent')}</i></div>
                            }
                          </span>
                        </div>
                      }
                    </td>
                    <td className="marketSize">
                      <div>
                        {
                          item.supplyAmount ?
                            new BigNumber(item.supplyAmount).gt('0') &&
                              bnToDec(new BigNumber(item.supplyAmount), 18) < 0.01
                              ? "<0.01"
                              :
                              _formatNumberNotCarry(bnToDec(new BigNumber(item.supplyAmount), 18, 4), 'abbr')
                            : '...'
                          // formate_round_up(
                          //     numeral(
                          //       bnToDec(new BigNumber(item.supplyAmount), 18)
                          //     )
                          //       .format("0.000a")
                          //       .toLocaleUpperCase()
                          //   )
                        }
                      </div>
                      <div className="bottomAPY">
                        {
                          item.totalSupply ?
                            new BigNumber(item.totalSupply).gt('0') &&
                              bnToDec(new BigNumber(item.totalSupply), 18) < 0.01
                              ? "<$0.01"
                              :
                              '$' + _formatNumberNotCarry(bnToDec(new BigNumber(item.totalSupply), 18, 4), 'abbr')
                            : '...'
                          // formate_round_up(
                          //     numeral(
                          //       bnToDec(new BigNumber(item.supplyValue), 18)
                          //     )
                          //       .format("$0.000a")
                          //       .toLocaleUpperCase()
                          //   )
                        }
                      </div>
                    </td>
                    <td className="marketSize">
                      <div>
                        {
                          item.borrowAmount ?
                            new BigNumber(item.borrowAmount).eq('0') ?
                              '0.00'
                              :
                              bnToDec(new BigNumber(item.borrowAmount), 18) < 0.01
                                ? "<0.01"
                                :
                                _formatNumberNotCarry(bnToDec(new BigNumber(item.borrowAmount), 18, 4), 'abbr')
                            : '...'
                          // formate_round_up(
                          //     numeral(bnToDec(new BigNumber(item.borrowAmount), 18))
                          //       .format("0.000a")
                          //       .toLocaleUpperCase()
                          //   )
                        }
                      </div>
                      <div className="bottomAPY">
                        {
                          item.totalBorrows ?
                            new BigNumber(item.totalBorrows).eq('0') ?
                              '$0.00'
                              :
                              bnToDec(new BigNumber(item.totalBorrows), 18) < 0.01
                                ? "<$0.01"
                                :
                                '$' + _formatNumberNotCarry(bnToDec(new BigNumber(item.totalBorrows), 18), 'abbr')
                            : '...'
                          // formate_round_up(
                          //     numeral(bnToDec(new BigNumber(item.borrowValue), 18))
                          //       .format("$0.000a")
                          //       .toLocaleUpperCase()
                          //   )
                        }
                      </div>
                    </td>
                  </tr>
                )
              })
              : Array.from({ length: 5 }).map((key, index) => (
                <tr key={index}>
                  <td className="firstTd">
                    <TokenIconWrap className="TokenIconBg" />
                    <TokenSymbol className="TokenSymbolBg"></TokenSymbol>
                  </td>
                  <td className="specialApy">
                    <div className="supply">
                      <span>...</span>
                    </div>
                    <div className="APR">
                      <span>...</span>
                    </div>
                  </td>
                  <td className="specialApy">
                    <div className="borrow">
                      <span>...</span>
                    </div>
                    <div className="APR">
                      <span>...</span>
                    </div>
                  </td>
                  <td className="marketSize">
                    <div>
                      ...
                    </div>
                    <div className="bottomAPY">
                      ...
                    </div>
                  </td>
                  <td className="marketSize">
                    <div>
                      ...
                    </div>
                    <div className="bottomAPY">
                      ...
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        {/* {
      hasSynthsLink &&
      <>
      <Table>
        <thead>
          <tr>
              <TheadBar>{fmt({ id: "Mint_Markets" })}</TheadBar>
          </tr>
          <tr>
            <th className="firstTh">{fmt({ id: "Markets_Assets" })}</th>
            <th>{fmt({ id: "mint_apy" })}</th>
            <th>{fmt({ id: "Mint_Size" })}</th>
          </tr>
        </thead>
        <tbody>
          {mintMarkets.length > 0
            ? mintMarkets.map((item) => (
                <tr key={item.address}>
                  <td className="firstTd">
                    <TokenIconWrap>
                      <TokenIcon src={item.icon} />
                    </TokenIconWrap>
                    <TokenSymbol>{item.reveal_symbol}</TokenSymbol>
                  </td>

                  <td>
                    {bnToDec(new BigNumber(item.borrowAPY), 18) > 0 &&
                    bnToDec(new BigNumber(item.borrowAPY), 18) < 0.0001
                      ? "<0.01%"
                      : numeral(
                          numeral(bnToDec(new BigNumber(item.borrowAPY), 18,4))
                            .format("0.0000")
                        ).format("0.00%")}
                  </td>
                  <td>
                    {bnToDec(new BigNumber(item.borrowValue), 18) > 0 &&
                    bnToDec(new BigNumber(item.borrowValue), 18) < 0.01
                      ? "<$0.01"
                      : formate_round_up(
                          numeral(bnToDec(new BigNumber(item.borrowValue), 18))
                            .format("$0.000a")
                            .toLocaleUpperCase()
                        )}
                  </td>
                </tr>
              ))
            : Array.from({ length: 5 }).map((key, index) => (
                <tr key={index}>
                  <td className="firstTd">
                    <TokenIconWrap className="TokenIconBg" />
                    <TokenSymbol className="TokenSymbolBg"></TokenSymbol>
                  </td>
                  <td>...</td>
                  <td>...</td>
                </tr>
              ))}
        </tbody>
      </Table>
      </>
      } */}
      </TableWrap>

      {
        showFreeze && isHasFrozen &&
        <TableWrap>
          <Table>
            <thead>
              <tr>
                <TheadBar>
                  {/* {fmt({ id: "Supply_Markets" })} */}
                  {'Frozen Markets'}
                </TheadBar>
              </tr>

              <>
                <FreezeBox className="moblie">
                  These assets are frozen or paused, further supply and borrow are unavailable, withdraw and repay are allowed.
                </FreezeBox>
              </>

              <tr>
                <th className="firstTh">{fmt({ id: "Markets_Assets" })}</th>
                <th>
                  <SortArea
                    onClick={(): void =>
                      sortData(supplyAPY, setSupplyAPY, "mainingSupplyApy")
                    }
                  >
                    {/* {fmt({ id: "supply_apy" })} */}
                    <div className="specialApy">
                      <div>
                        <span>{fmt({ id: "Supply_APY" })}</span>
                      </div>
                      <div className="bottomAPY">
                        <span>{fmt({ id: "Reward_to_MAX" })}</span>
                      </div>
                    </div>
                    <i className={"active" + supplyAPY}></i>
                  </SortArea>
                </th>
                <th>
                  <SortArea
                    onClick={(): void =>
                      sortData(borrowAPY, setBorrowAPY, "mainingBorrowApy")
                    }
                  >
                    {/* {fmt({ id: "borrow_apy" })} */}
                    <div className="specialApy">
                      <div>
                        <span>{fmt({ id: "Borrow_APY" })}</span>
                      </div>
                      <div className="bottomAPY">
                        <span>{fmt({ id: "Reward_to_MAX" })}</span>
                      </div>
                    </div>
                    <i className={"active" + borrowAPY}></i>
                  </SortArea>
                </th>
                <th className="marketSize">
                  <SortArea
                    onClick={(): void =>
                      sortData(marketSize, setMarketSize, "supplyValue")
                    }
                  >
                    {fmt({ id: "Total_Supply" })}
                    <i className={"active" + marketSize}></i>
                  </SortArea>
                </th>
                <th className="marketSize">
                  <SortArea
                    onClick={(): void =>
                      sortData(borrowValue, setBorrowValue, "borrowValue")
                    }
                  >
                    {fmt({ id: "Total_Borrow" })}
                    <i className={"active" + borrowValue}></i>
                  </SortArea>
                </th>
              </tr>
            </thead>
            <tbody>
              {render_markets.length > 0 &&
                render_markets.map((item) => {
                  if (item.isFreeze && showFreeze) {
                    // console.log(item)
                    return (
                      <tr key={item.address}>
                        <td className="firstTd">
                          <TokenIconWrap>
                            {/* {item.isFreeze && <StyledFreeze>Frozen</StyledFreeze>} */}
                            <TokenIcon src={item.icon} />
                          </TokenIconWrap>
                          <TokenSymbol>{item.reveal_symbol}</TokenSymbol>
                        </td>
                        <td className="specialApy">
                          <div className="supply">
                            <span>
                              {
                                item.supplyAPY ?
                                  bnToDec(new BigNumber(item.supplyAPY).abs(), 18) > 9999.99
                                    ?
                                    '999,999%'
                                    :
                                    _formatNumberNotCarry(bnToDec(new BigNumber(item.supplyAPY), 18, 4), 'percent')
                                  : '...'
                                // numeral(
                                //   numeral(
                                //     bnToDec(new BigNumber(item.mainingSupplyApy), 18, 4)
                                //   ).format("0.0000")
                                // ).format("0,0.00%")
                              }
                            </span>
                          </div>
                          {
                            (item.SupplyRewardAPY && item.MinimumSupplyRewardAPY && item.DailyDistribution !== '0') &&
                            <div className="APR">
                              <img src="/Unitus.svg" />
                              <span>
                                {
                                  bnToDec(new BigNumber(item.SupplyRewardAPY).abs(), 18) > 9999.99
                                    ?
                                    <div>{_formatNumberNotCarry(bnToDec(new BigNumber(item.MinimumSupplyRewardAPY), 18, 6), 'percent')}~<i>999,999%</i></div>
                                    :
                                    <div>{_formatNumberNotCarry(bnToDec(new BigNumber(item.MinimumSupplyRewardAPY), 18, 6), 'percent')}~<i>{_formatNumberNotCarry(bnToDec(new BigNumber(item.SupplyRewardAPY), 18, 6), 'percent')}</i></div>
                                }
                              </span>
                            </div>
                            // :<div className="APR"><span>...</span></div>
                          }

                          {
                            (item.SupplyRewardAPYArb && item.MinimumSupplyRewardAPYArb && item.DailyDistributionArb !== '0') &&
                            <div className="APR">
                              <img src="/ARB.svg" />
                              <span>
                                {

                                  bnToDec(new BigNumber(item.SupplyRewardAPYArb).abs(), 18) > 9999.99
                                    ?
                                    <div>{_formatNumberNotCarry(bnToDec(new BigNumber(item.MinimumSupplyRewardAPYArb), 18, 6), 'percent')}~<i>999,999%</i></div>
                                    :
                                    <div>{_formatNumberNotCarry(bnToDec(new BigNumber(item.MinimumSupplyRewardAPYArb), 18, 6), 'percent')}~<i>{_formatNumberNotCarry(bnToDec(new BigNumber(item.SupplyRewardAPYArb), 18, 6), 'percent')}</i></div>
                                }
                              </span>
                            </div>
                          }
                        </td>
                        <td className="specialApy">
                          <div className="borrow">
                            <span>
                              {
                                item.borrowAPY ?
                                  bnToDec(new BigNumber(item.borrowAPY).abs(), 18) > 9999.99
                                    ?
                                    '999,999%'
                                    :
                                    _formatNumberNotCarry(bnToDec(new BigNumber(item.borrowAPY), 18, 4), 'percent')
                                  : '...'
                                // numeral(
                                //   numeral(
                                //     bnToDec(new BigNumber(item.mainingBorrowApy).abs(), 18, 4)
                                //   ).format("0.0000")
                                // ).format("0,0.00%")
                              }
                            </span>
                          </div>
                          {(item.BorrowRewardAPY && item.MinimumBorrowRewardAPY && item.DailyDistribution !== '0') &&
                            <div className="APR">
                              <img src="/Unitus.svg" />
                              <span>
                                {
                                  bnToDec(new BigNumber(item.BorrowRewardAPY).abs(), 18) > 9999.99
                                    ?
                                    <div>{_formatNumberNotCarry(bnToDec(new BigNumber(item.MinimumBorrowRewardAPY), 18, 6), 'percent')}~<i>999,999%</i></div>
                                    :
                                    <div>{_formatNumberNotCarry(bnToDec(new BigNumber(item.MinimumBorrowRewardAPY), 18, 6), 'percent')}~<i>{_formatNumberNotCarry(bnToDec(new BigNumber(item.BorrowRewardAPY), 18, 6), 'percent')}</i></div>
                                }
                              </span>
                            </div>
                          }
                          {
                            (item.BorrowRewardAPYArb && item.MinimumBorrowRewardAPYArb && item.DailyDistributionArb !== '0') &&
                            <div className="APR">
                              <img src="/ARB.svg" />
                              <span>
                                {
                                  bnToDec(new BigNumber(item.BorrowRewardAPYArb).abs(), 18) > 9999.99
                                    ?
                                    <div>{_formatNumberNotCarry(bnToDec(new BigNumber(item.MinimumBorrowRewardAPYArb), 18, 6), 'percent')}~<i>999,999%</i></div>
                                    :
                                    <div>{_formatNumberNotCarry(bnToDec(new BigNumber(item.MinimumBorrowRewardAPYArb), 18, 6), 'percent')}~<i>{_formatNumberNotCarry(bnToDec(new BigNumber(item.BorrowRewardAPYArb), 18, 6), 'percent')}</i></div>
                                }
                              </span>
                            </div>
                          }
                        </td>
                        <td className="marketSize">
                          <div>
                            {
                              item.supplyAmount ?
                                new BigNumber(item.supplyAmount).gt('0') &&
                                  bnToDec(new BigNumber(item.supplyAmount), 18) < 0.01
                                  ? "<0.01"
                                  :
                                  _formatNumberNotCarry(bnToDec(new BigNumber(item.supplyAmount), 18, 4), 'abbr')
                                : '...'
                              // formate_round_up(
                              //     numeral(
                              //       bnToDec(new BigNumber(item.supplyAmount), 18)
                              //     )
                              //       .format("0.000a")
                              //       .toLocaleUpperCase()
                              //   )
                            }
                          </div>
                          <div className="bottomAPY">
                            {
                              item.supplyValue ?
                                new BigNumber(item.supplyValue).gt('0') &&
                                  bnToDec(new BigNumber(item.supplyValue), 18) < 0.01
                                  ? "<$0.01"
                                  :
                                  '$' + _formatNumberNotCarry(bnToDec(new BigNumber(item.supplyValue), 18, 4), 'abbr')
                                : '...'
                              // formate_round_up(
                              //     numeral(
                              //       bnToDec(new BigNumber(item.supplyValue), 18)
                              //     )
                              //       .format("$0.000a")
                              //       .toLocaleUpperCase()
                              //   )
                            }
                          </div>
                        </td>
                        <td className="marketSize">
                          <div>
                            {
                              item.borrowAmount ?
                                new BigNumber(item.borrowAmount).eq('0') ?
                                  '0.00'
                                  :
                                  bnToDec(new BigNumber(item.borrowAmount), 18) < 0.01
                                    ? "<0.01"
                                    :
                                    _formatNumberNotCarry(bnToDec(new BigNumber(item.borrowAmount), 18, 4), 'abbr')
                                : '...'
                              // formate_round_up(
                              //     numeral(bnToDec(new BigNumber(item.borrowAmount), 18))
                              //       .format("0.000a")
                              //       .toLocaleUpperCase()
                              //   )
                            }
                          </div>
                          <div className="bottomAPY">
                            {
                              item.borrowValue ?
                                new BigNumber(item.borrowValue).eq('0') ?
                                  '$0.00'
                                  :
                                  bnToDec(new BigNumber(item.borrowValue), 18) < 0.01
                                    ? "<$0.01"
                                    :
                                    '$' + _formatNumberNotCarry(bnToDec(new BigNumber(item.borrowValue), 18), 'abbr')
                                : '...'
                              // formate_round_up(
                              //     numeral(bnToDec(new BigNumber(item.borrowValue), 18))
                              //       .format("$0.000a")
                              //       .toLocaleUpperCase()
                              //   )
                            }
                          </div>
                        </td>
                      </tr>
                    )
                  }
                })}
            </tbody>
          </Table>
        </TableWrap>
      }
    </>
  );
};

const FreezeBox = styled.div`
  font-weight: 400;
  font-size: 14px;
  margin-left: 44px;

  &.moblie{
    margin-left: 20px;
    line-height: 22px;
    color: #9195a4;
    @media (max-width: 1199px) {
    }
  }
`
const StyledFreeze = styled.div`
  position: absolute;
  color: #FF0E0E;
  background-color: #FFDEDE;
  /* border: 2px solid tomato; */
  left: -20px;
  top: -20px;
  font-size: 12px;
  font-weight: 600;
  height: auto;
  line-height: 1;
  padding: 3px;
  font-weight: 700;
  border-radius: 2px;
  margin: 4px 0 0 10px;
`;

const flash = keyframes`
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
`;
const TableWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  @media (max-width: 767px) {
    flex-direction: column;
  }
`;
const Table = styled.table`
  width: max-content;
  height: max-content;
  border-radius: 16px;
  @media (max-width: 767px) {
    width:100%;
    border-radius: 10px;
  }
  border-bottom: 0;
  border-spacing: inherit;
  color: ${(props) => props.theme.colors.black};
  background-color: #fff;
  font-size:14px;
  .dark & {
    color: ${(props) => props.theme.colors.white};
    background-color: #131313;
  }
  &.noSynthsLink{
    margin-right:0!important;
  }
  flex-grow: 1;
  /* &:first-child {
    margin-right: 15px;
    margin-bottom:0;
    @media (max-width: 767px) {
      margin-right:0;
      margin-bottom: 15px;
    }
  } */
  font-weight: bold;
  thead {
    color: ${(props) => props.theme.colors.lightGrey};
    tr:nth-child(1){
      border-bottom: none;
      height: 60px;
    }
    tr:nth-child(2){
      border-bottom:1px solid ${(props) => props.theme.colors.lightTableBorder};
      .dark & {
        border-bottom:1px solid ${(props) => props.theme.colors.darkTableBorder};
      }
    }
    @media (max-width: 767px) {
      line-height:25px;
      tr,th{
        /* line-height: initial; */
      }
    }
  }
  tr {
    padding: 8px 0;
    margin:0 20px;
    text-align: right;
    height: auto;
    min-height: 85px;
    line-height: 54px;
    box-sizing: border-box;
    border-bottom:1px solid ${(props) => props.theme.colors.lightTableBorder};
    .dark & {
      border-bottom:1px solid ${(props) => props.theme.colors.darkTableBorder};
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    @media (max-width: 767px) {
      margin:0 10px;
      /* height:60px;
      line-height: 60px; */
    }
    &.hoverstyle {
      :hover {
        background-color: rgb(246, 246, 246);
        cursor: pointer;
        .dark & {
          /* background-color: rgb(31, 39, 74); */
          background-color: rgb(0,0,0,0.5);
        }
      }
    }
    th {
      font-weight:normal;
      flex:1;
      text-align: right;
      display: flex;
      justify-content: flex-end;
      i {
        display: inline-flex;
        flex-direction: column;
        margin-left: 5px;
        &:before {
          content: "";
          display: inline-block;
          height: 0px;
          width: 0px;
          border-right: 4px solid transparent;
          border-left: 4px solid transparent;
          border-bottom: 4px solid ${(props) => props.theme.colors.lightGrey};
          margin-bottom: 2px;
        }
        &:after {
          content: "";
          display: inline-block;
          height: 0px;
          width: 0px;
          border-right: 4px solid transparent;
          border-left: 4px solid transparent;
          border-top: 4px solid ${(props) => props.theme.colors.lightGrey};
        }
        &.active1 {
          &:after {
            border-top: 4px solid ${(props) => props.theme.colors.supply.dark};
          }
        }
        &.active-1 {
          &:before {
            border-bottom: 4px solid
              ${(props) => props.theme.colors.supply.dark};
          }
        }
      }
      @media (max-width: 767px) {
        font-size: 14px;
        &.marketSize{
        display: none;
      }
      }
    }
    td {
      .supply{
        color:${(props) => props.theme.borrow};
      }
      .borrow{
        color:rgb(246, 61, 61);
      }
      &.specialApy,&.marketSize,&.liquidity {
        display: flex;
        flex-direction: column;
        justify-content: center;
        div {
          line-height: 24px;
          span {
            display: inline-block;
          }
          &.bottomAPY {
            color:${(props) => props.theme.fontColor[999]};
            &:first-child {
              margin-left: 5px;
            }
          }
          &.APR {
            display: flex;
            justify-content: end;
            align-items: center;
            font-size: 14px;
            &:first-child {
              margin-left: 5px;
            }
            i{
              font-style: normal;
              /* color: ${(props) => props.theme.colors.black};
              .dark & {
                color: ${(props) => props.theme.colors.white};
              } */
            }
            img{
              display: inline-block;
              width: 15px;
              height: 15px;
              margin-right: 5px;
            }
          }
        }
      }
      flex:1;
      @media (max-width: 767px) {
        display: flex;
        /* align-items: center; */
        justify-content: flex-end;
        &.marketSize{
          display: none;
        }
        &.specialApy,&.liquidity {
          display: flex;
          flex-direction: column;
          justify-content: center;
          div {
            font-size: 10px;
            line-height: 24px;
            &.APR {
            font-size: 10px;
            }
            span {
              display: inline-block;
              &.green{
                color:${(props) => props.theme.colors.borrow.dark};
              }
              &.red{
                color:${(props) => props.theme.colors.red};
              }
            }
            &.bottomAPY {
              font-size: 16px;
              &:first-child {
                margin-left: 5px;
              }
            }
          }
        }
      }
    }
    .firstTh,
    .firstTd {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      text-align: left;
      width: 80px;
      flex: none;
      @media (max-width: 767px) {
        width: 40px;
        flex-direction:column;
      }
    }
    &:last-child{
      /* border-bottom:0 */
    }
  }
`;
const TheadBar = styled.th`
  font-size: 18px;
  ${TableWrap} table tr & {
    width:100%;
    display:flex;
    justify-content:space-between;
    justify-content: flex-start;
    flex:inherit;
    align-items:inherit;
    color: ${(props) => props.theme.colors.black};
    .dark & {
      color: ${(props) => props.theme.colors.white};
    }
  font-weight:bold;
    font-weight:bold;
    @media (max-width: 767px) {
      flex-direction:column;
      padding:0;
      align-items:flex-start;
      p{
        margin:0!important
      }
      ul{
        margin:0;
        line-height:30px;
      }
    }
  }
  justify-content: flex-start;
  &.spe{
    @media (max-width: 767px) {
      display: flex!important;
      flex-direction: row!important;
      align-items: center!important;
      line-height: none!important;
    }
  }
`
const TokenSymbol = styled.span`
  display: inline-block;
  min-width: 40px;
  height: 17px;
  line-height: 17px;
  text-align:center;
  &.TokenSymbolBg {
    animation: ${flash} 1.2s linear infinite forwards;
    background: #e1e5ee;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 800px 100px;
    position: relative;
  }
  @media (max-width: 767px) {
    height: 15px;
    line-height: 15px;
    font-size: 12px;
  }
`;
const TokenIconWrap = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 12px;
  position: relative;
  &.TokenIconBg {
    border-radius: 50%;
    animation: ${flash} 1.2s linear infinite forwards;
    background: #e1e5ee;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 800px 100px;
    position: relative;
  }
  @media (max-width: 767px) {
    margin-bottom:5px;
    margin-right:0;
  }
`;
const TokenIcon = styled.img`
  display: block;
  width: 32px;
  height: 32px;
`;
const SortArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: inherit;
  text-align:right;
  cursor: pointer;
  .specialApy {
        display: flex;
        flex-direction: column;
        justify-content: center;
        div {
          line-height: 20px;
          span {
            display: inline-block;
          }
          &.bottomAPY {
            color: #9195a4;
            font-size: 14px;
            &:first-child {
              margin-left: 5px;
            }
          }
        }
      }
      @media (max-width: 767px) {
        &.specialApy {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          div {
            line-height: 20px;
            span {
              display: inline-block;
            }
            &.bottomAPY {
              color: #9195a4;
              font-size: 12px;
              &:first-child {
                margin-left: 5px;
              }
            }
          }
        }
      }
`;
export default AllMarketsList;
