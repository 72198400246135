import React from 'react'
import styled from 'styled-components'
import { isMobile } from 'react-device-detect'

import img_arbitrum from "assets/Bridge/net-arbitrum.svg"
import img_eth from "assets/Bridge/net-eth.svg"
import img_op from "assets/Bridge/net-op.svg"
import img_bsc from "assets/Bridge/net-bsc.svg"
import img_polygon from "assets/Bridge/Polygon.svg"
import img_Avalanche from "assets/Bridge/Avalanche.svg"
import img_kava from "assets/Bridge/KAVA.svg"
import img_Conflux from "assets/Bridge/net-conflux.svg"
import img_zksync from "assets/Bridge/zkSync.svg"

const support_network = {
  1: img_eth,
  42161: img_arbitrum,
  56: img_bsc,
  97: img_bsc,
  10: img_op,
  69: img_op,
  137: img_polygon,
  43114: img_Avalanche,
  2222: img_kava,
  1030: img_Conflux,
  324: img_zksync,
}


interface SelectChainIdProps {
  config: any,
  isSelected: boolean,
  chainId: number | undefined,
  selectChainId: (id: number) => void,
}
export const SelectChainId: React.FC<SelectChainIdProps> = ({ chainId, selectChainId, config, isSelected }) => {
  return (
    <TokenItem
      onClick={() => { selectChainId(config.chainId) }}
      className={isSelected ? 'isChoosen' : ''}
    >
      <TokenItem_left>
        <TokenItem_logo>
          {
            Object.keys(support_network).includes((config.chainId).toString()) &&
            <img src={support_network[config.chainId]} alt="" />
          }
        </TokenItem_logo>
        <TokenItem_name>
          {config.network_name}
        </TokenItem_name>
      </TokenItem_left>
    </TokenItem>
  )
}


interface NetTypeOptionsProps {
  handleOpen: () => void,
  chainId_src: number,
  config: any,
  isFrom?: boolean
}
export const NetTypeOptions: React.FC<NetTypeOptionsProps> = ({ handleOpen, chainId_src, config, isFrom }) => {
  return (
    <NetTypeOptionsWrap onClick={() => { handleOpen() }}>
      {
        !(config && config.network_name) ?
          <NetLogo>
            <img src={img_eth} alt="" />
          </NetLogo>
          :
          <NetLogo>
            {
              Object.keys(support_network).includes(chainId_src.toString()) &&
              <img src={support_network[chainId_src]} alt="" />
            }
          </NetLogo>
      }
      <NetName>
        {config ? config.network_name : 'Ethereum'}
      </NetName>

      {!(isMobile && isFrom) && <ArrowDown />}
    </NetTypeOptionsWrap>
  )
}



const TokenItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  color: #0A1130;
  font-weight: 700;
  height: 64px;
  background: #dedfe1;
  border-radius: 12px;
  border: 1px solid #D1D2D7;
  margin-top: 12px;
  cursor: pointer;
  padding-left: 20px;
  padding-right: 20px;
  width: 48%;
  @media (max-width: 1119px) {
    margin-top: 10px;
    width: 100%;
    height: 40px;
    font-size: 14px;
  }
  .dark & {
    color: #fff;
    /* background: #131B3D; */
    background: rgba(255,255,255,0.08);
    border: 1px solid #2E344E;
  }

  &.isChoosen,
  &:hover {
    border: 2px solid #30A9DE;
  }
`
const TokenItem_left = styled.div`
  display: flex;
  align-items: center;
`
const TokenItem_logo = styled.div`
  img {
    width: 42px;
    margin-right: 16px;
    @media (max-width: 1119px) {
      width: 26px;
      margin-right: 10px;
    }
  }
`
const TokenItem_name = styled.div`
`

const NetTypeOptionsWrap = styled.div`
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 20px;
  height: 42px;
  background: rgb(246, 246, 246);
  border-radius: 100px;
  cursor: pointer;

  .dark & {
    /* background: #212A52; */
    background: rgba(255, 255, 255, 0.08);
  }
`
const NetLogo = styled.div`
  img {
    width: 30px;
    margin-right: 10px;
  }
`
const NetName = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: rgb(36, 42, 71);
  margin-right: 25px;

  .dark & {
    color: #FFFFFF;
  }
`
const ArrowDown = styled.div`
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-top: 6px solid rgb(36, 42, 71);
  margin-bottom: -4px;

  .dark & {
    border-top: 6px solid #fff;
  }
`