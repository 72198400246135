const zh_CN = {
  Price_Impact: 'Price Impact',
  Zap: 'Zap',
  Price_Title__sub: 'Unitus借贷协议中的资产价格来源于Chainlink和喂价服务，喂价服务暂时由Unitus维护，未来将由去中心化治理决定价格源',
  Price_Title: '价格源',
  dForce_Report: 'Unitus Report',
  supply_APY: '存款利率',
  borrow_APY: '借款利率',
  top_Supplied: '存款余额',
  top_borrowed: '借款余额',
  Supplies: '存款',
  Borrows: '借款',
  Theme_Mode: '主题',
  topbar_Dashboard: '总览',
  topbar_Markets: '市场',
  topbar_Bridge: 'Bridge',
  Emission: 'Emission',
  BLP: '流动性',
  topbar_Price: '价格',
  topbar_Liquidation: '清算',
  topbar_More: '更多',
  ComingSoon: 'Coming Soon',
  atleast__heyue: '交易数量需要大于{amount} {token}。',
  curNetErr_1004: '流动性不足',
  curNetErr_1005: '超过Epoch阈值',
  curNetErr_1017: '超过Pool阈值',
  curNetErr_maxSend: '单笔超阈值',
  // db
  Integrations__Exchanges_and_Bridge: '交易和跨链',
  TextPriceUpdate__tips: '更新价格到最新',
  TextPriceUpdate: '更新',
  markets_closed_1__Borrowings: '借款',
  markets_closed_1__Reedem_collaterals: '取回抵押物',
  markets_closed_1__Mintings: '存款',
  markets_closed_1: '已暂停，请稍后再试',
  out_of_service: '服务已暂停, 请稍后再试',
  Total_USX_Circulating_Supply: 'USX流通量',
  totalMinted: '总铸币',
  totalCollateral: '总抵押',
  Liquidity__swap: '流动性交易',
  Liquidity__Total_Debt: '总借款',
  Liquidity__Remaining_Balance: '剩余额度',
  Liquidity__Utilization: '使用率',
  Liquidity__Total_Amount: '购买总量',
  Liquidity__sDF_Amount: 'sDF数量',
  Liquidity__Orders: '订单',
  Liquidity__USX_Utilized: '已用USX',
  Liquidity__DF_Buybacked: '已买DF',
  Liquidity__Price: 'DF均价',
  Liquidity__USX_in_Utilization: '挂单USX',
  DB_Source: '铸币来源',
  Whereabouts: '铸币分布',
  USX_Distribution: '流动性（按不同网络）',
  DF_Multi_chain: '流动性分析',
  Total_Supply__db: '发行总量',
  Circulating_Supply: '市场流通量',
  DF_Price: 'DF价格',
  Buy: '购买',
  db_Lending: '借贷',
  db_USX_Suppied: 'USX抵押量',
  db_ETH_Borrowed: 'ETH已借数量',
  db_Adequacy_Ratio: '资金充足率',
  db_USX_Supply_APY: 'USX存款利率',
  db_ETH_Borrow_APY: 'ETH借款利率',
  Protocol_Liquidity__sub: '提供DF/USX流动性到DEX',
  db_Others: '其它',
  db_Staked: '已存数量',
  POO_Treasury: 'dForce财库',
  Protocol_Liquidity_1: '协议流动性',
  Protocol_Liquidity: '流动性分布',
  ETH_Markets: 'ETH市场',
  DF_Markets: 'DF代币',
  db_Bridge_Liquidity: 'Layer2',
  db_DEX_Liquidity: 'DEX',
  USX_Markets: 'USX',
  menu_Dashboard: '数据',
  MintSupplyBorrow: 'Mint USX-Supply USX-Borrow ETH',
  MintSupplyBorrow_sub: '从dForce借贷中借出ETH，存入高回报产品，赚取收益',
  Revenue: '年化平台收益',
  Income: '年化协议收入',
  Income_detail: '盈利分析（按月份）',
  Total_Income_30d__detail: '包括借贷息差（借款利息减去存款里系），以及协议通过各种运营策略获取并分配给dForce Treasury的协议收入',
  Total_Income_30d: '年化协议收入',
  Total_revenue_30d: '年化平台收益',
  Total_revenue_30d__detail: '包括借款人支付的借款利息，以及协议通过各种运营策略获取的平台收益',
  Total_Value: '总市值',
  Total_USX: 'USX总量',
  Total_Accumulated_Borrow: '累计借款',
  Total_Assets: '资产总额',
  Protocol_Income: '收益分析',

  // bridge
  atleast_10df: '交易数量需要大于10 DF。',
  atleast_5usx: '交易数量需要大于5 USX。',
  bridge_routing__text: '跨链路径来源于cBridge、dForce Bridge、Layer2 Bridge。',
  bridge_Fee__text: 'dForce不收取任何费用，但用户需为跨链转账支付少量gas费用。',
  Fee: '费用',
  Slippage_Tolerance: '滑点',
  minutes: '分钟',
  Estimated_Time: '预计收到时间',
  Minimum_Received: '最小接收',
  Routing: '路径',
  TRANSFER: '交易',
  APPROVE: '授权',
  Add_to_MetaMask: '添加到MetaMask',
  From: '从',
  bridge__Bridge: '跨链桥',
  To: '到',
  Send: '发送',
  Receive__info: '该金额是根据当前的跨链桥费率和费用估算的。',
  Receive: '接收（预计）',
  Receive_swap: '接收',
  curNetErr: '{net}上流动性不足，请减少跨链金额',
  atleast: '输入数量不能小于{amount}',
  Slippage__tips: '如果跨链费用在交易过程中超过事先设定的百分比，那么该交易不予通过。',
  Routing__info: '该金额是根据当前的跨链桥费率和费用估算的。',

  // mini pool
  vault__Supply_APR: '存款利率',
  vault__Borrow_APR: '借款利率',
  Debt_Ceiling: '借款额度',
  Vaults: '金库',
  Claim_df: '取出: ',
  User_Receive: '用户接收',
  Yields: '生息池',
  REPAY_USX_AND_WITHDRAW: '偿还并取款',
  ADD_COLLATERAL_AND_BORROW: '存款并借款',
  ADD_COLLATERAL: '存款',
  NOTHING_TO_DO: '没有执行动作',
  Borrow_fee__tips: '借款手续费率',
  Interest__tips__show: '年化借款利率',
  Liquidation_fee__tips: '清算者获得罚金比例',
  Liquidation_Price__tips: '发生清算时的抵押资产价格',
  LTV__tips: '借款抵押率代表的是借出资产的美元价值与抵押资产的美元价值的比率，最高为100%',
  ltv_popup_tips: '代表抵押物的借款能力（百分比形式），数值越高借款能力越大',
  Borrow_APY: '借款利率',
  Collateral: '抵押物',
  Interest: '借款利率',
  Total_Borrowed: '已借总额',
  Left_to_Borrow: '可借总额',
  LTV: '借款抵押率',
  Reward_APR: '挖矿利率',
  My_Open_Position: '我的仓位',
  Collateral_Supplied: '已存数量',
  Collateral_Value: '抵押价值',
  Liquidation_Price: '清算价格',
  Total_Collateral: '总抵押额',
  Borrow_fee: '借款费用',
  Borrow_fee_rate: '借款费率',
  Liquidation_fee: '清算罚金比例',
  Price: '价格',
  Repay: '偿还',
  Pool_Info: '池子详情',
  Borrowed: "已借数量",
  Supply_Collateral: "存入抵押物",
  Withdraw_Collateral: "取出抵押物",
  poweredByDebank: '数据来源DeBank',
  YourTotalAssets: '你的多链总资产: ',
  Mintage: '铸造',
  Combined_APY: '综合利率',
  connect_wallet_first: '请先连接钱包',
  YES: '是',
  NO: '否',
  MAX: "最大值",
  Amount: "输入数量",
  language: "CN",
  Markets_title: "市场信息",
  LEND: "借贷",
  ASSETS: "资产",
  Assets__Yield_Markets: "生息市场",
  Assets__usdx: "USDx",
  Assets__goldx: "GOLDx",
  TRADE: "交易",
  FARM: "挖矿",
  Governance: "治理",
  Governance__vote: "投票",
  Governance__air: "空投",
  Governance__forum: "论坛",
  Documents: "文档",
  Community: "社区",
  Connect_Wallet: "连接钱包",
  Select: "选择",
  wrong_network: "网络错误，请切换到主网",
  mainnet_network: "你目前连接的是以太坊主网",
  kovan_network: "你目前连接的是Kovan测试网络",
  bsc_network: "你目前连接的是币安智能链",
  bsctest_network: "你目前连接的是币安智能链测试网",
  heco_network: "你目前连接的是火币生态链",
  wrong_network_Text: "错误网络",
  // wallet modal
  Connected_with_MetaMask: '已与 MetaMask 连接',
  Connected_with_Brave: '已与 Brave 连接',
  Connected_with_BitKeep: '已与 BitKeep 连接',
  Connected_with_Rabby: '已与 Rabby 连接',
  Connected_with_OKX: '已与 OKX 连接',
  Connected_with_Coinbase: '已与 Coinbase 连接',
  Connected_with_WalletConnect: '已与 WalletConnect连接',
  Connected_with_TrustWallet: '已与 Trust Wallet连接',
  Change: '更改',
  Disconnect: '断开',
  Transactions: '交易记录',
  Clear_all: '清除记录',
  //home
  //My Assets
    Available_to_borrow: 'Available to borrow',
  Assets: '资产',
  My_Assets: "我的资产",
  Total_personal_deposits: "存款总额",
  Total_personal_loans: "总负债",
  Collateral_Ratio: "资产充足率",
  // Assets_Card
  Supplying_Balance: "存款余额",
  Borrowing_Balance: "借款余额",
  Minting_Balance: "铸造余额",
  Interest_Earned: "存款利息",
  Interest_Accrued: "借款利息",
  Interest_Accrued_mint: "累计利息",
  // All Markets
  ALL: "所有资产",
  MainCryptos: "主流资产",
  Synth: "合成资产",
  LP: "LP资产",
  Stable: "稳定资产",
  Markets_Assets: "资产",
  Market_Size: "市场总额",
  Mint_Size: "铸造市场总额",
  Liquidity: "流动性",
  Wallet: "钱包余额",
  SUPPLY: "存款",
  BORROW: "借款",
  WITHDRAW: "取款",
  REPAY: "还款",
  MINT: "铸造",
  minted: "已铸数量",
  toolTip: "请先存入抵押资产",
  // Markets Page
  Multi_Chain_Markets: '多链资产',
  Markets_Total_Assets: '资产总额',
  Market_Overview: "市场概览",
  All_Markets: "所有资产",
  Supply_Markets: "存款市场",
  Mint_Markets: "铸造市场",
  Markets_Total_Supply: "存款总额",
  Markets_Total_Collateral: "市场总抵押量",
  Markets_Total_Borrow: "借款总额",
  Markets_Collateralization_ratio: "市场总抵押率",
  Markets_Total_Mint: "铸造总额",
  Accumulated_Borrow: '累积借款',
  Total_Supply: "存款总额",
  Total_Borrow: "借款总额",
  Top_Supply: "存款资产排名",
  Top_Borrow: "借款资产排名",
  Total_Mint: "铸造总额",
  Top_Mint: "铸造资产排名",
  // Mining Page
  Title1: 'Stake BLP to earn UTS',
  Title2: 'Users will be required to supply liquidity for BLP (either DF/UTS or USX/UTS), equivalent to 1% of the total value of their deposit and staked iUSX in US dollars, to qualify for UTS rewards on their deposits and borrowings.',
  TotalStake: '总质押量',
  MyBLPStake: '我的BLP质押量',
  Daily_Distribution: "每天分发量",
  Total_Distribution: "已分发总量",
  DailyDistribution_Distributed: 'Daily Distribution / Distributed',
  Remaining: "剩余分发量",
  Available_to_claim: "可取数量 (DF):",
  Claim: "取出",
  Claimable: 'Claimable',
  Pending: 'Pending',
  Supply_APY: '存款利率',
  Borrow_APY: '借款利率',
  Reward_to_MAX: '最大挖矿利率',
  Supply: "存款",
  Borrow: "借款",
  Reward: "挖矿",
  Gauge: "市场分发",
  MiningAssets: "资产",
  Paid_Day: "分发/天",
  // Liquidation History
  LiquidationHistory: "历史记录",
  seizeTokens: 'Seize Tokens',
  repayAmount: 'Repay Amount',
  borrower: 'Borrower',
  liquidator: 'Liquidator',
  timestamp: 'Timestamp',
  blockNumber: 'BlockNumber',
  // Liquidation
  Liquidation: "清算",
  Liquidate: "清算",
  liquidation_list: "清算列表",
  Shortfall: "资金缺口量（$）",
  Account: "账户",
  supplyValue: "存款总额（$）",
  collateralValue: "抵押总额（$）",
  borrowedValue: "总负债（$）",
  collateralRatio: "资产充足率",
  Supply_Assets: "存款资产",
  liquidation_amount: "数量",
  Borrow_Assets: "借款资产",
  MAX_Liquidation: "最大清算值",
  Wallet_Balance: "钱包余额",
  RequestedAmountClose: "被清算资产",
  // supply
  ENABLE: "授权",
  ENABLING: "授权中…",
  cancelled_by_user: "已取消交易",
  Home: "返回",
  Supply_Balance: "存款总额",
  Health_Factor: "资产充足率",
  Supplied: "已存数量",
  supply_notes: "请手动输入或选择比例来填写存入资产的数量",
  Health_Factor_tips: "小于1时将触发借款清算",
  supply_apy: "存款利率",
  supply_apy_tips: "存款年化收益率",
  loan_to_value: "借款抵押率",
  loan_to_value_tips: "借款抵押率代表的是借出资产的美元价值与抵押资产的美元价值的比率，最高为100%",
  price: "价格",
  balance: "余额",
  borrow_apy_space: " 借款利率",
  as_collateral: "是否作为抵押物",
  as_collateral__how: "该资产是否可作为抵押物",
  as_collateral__tips: "请先偿还你的借款，或提供另一种资产作为抵押物来支持你的借款",
  as_collateral__tips__notopen: "该资产不支持作为抵押物",
  Collateral_Balance_tips: "当前资产用户抵押的总数量",
  Available_to_Withdraw: "安全可取数量",
  isExecutedTips: "需要此资产作为抵押物满足借款抵押率，偿还借款，或提供其他资产作为抵押",
  supplied_tokens: "请于贷款前取出你的存款（同一种资产不能同时进行存款和借款）",
  withdraw__warning: "还可以存入 {params} 达到存款上限",
  //borrow
  safe_max: "安全值",
  Borrow_Balance: "总负债",
  borrow_notes: "请手动输入或选择比例来填写借出资产的数量",
  borrow_apy: "借款利率",
  borrow_apy_tips: "借款年化收益率",
  borrowed: "已借数量",
  max_available_borrow: "最大可借数额",
  safe_available_borrow: "安全可借数额",
  max_available_borrow_tips: "当前资产用户可借最大数量",
  safe_available_borrow_tips: "当前资产用户可借安全数量",
  Borrow_Factor: "借款系数",
  Borrow_Factor_tips: "借当前资产时的借款价值折扣",
  Available_to_Borrow: "安全可借数量",
  Available_to_Borrow_a: '安全可借数量',
  borrowed_tokens: "请于存款前还清你的借款或销毁你的合成资产（同一种资产不能同时进行存款和借款/铸造）",
  borrow__warning: "还可以借出{params}达到借款上限",
  //mint
  Borrowing_Power: 'Borrowing Power',
  Available_to_Mint: "安全可铸数量",
  Segregated_Asset: 'Segregated Asset',
  mint_apy: "铸造利率",
  mint_notes: "请手动输入或选择比例来填写铸造资产的数量",
  BURN: "销毁",
  mint_apy_tips: "合成资产的年化铸币利率",
  before_mint_tokens: "请于铸造前取出你的存款（同一种资产不能同时进行存款和铸造）",
  //faq
  faq: "常见问题",
  //footer
  Documentation: 'Documentation',
  Audit: 'Audit',
  FAQ: 'FAQ',
  Forum: 'Forum',
  // spe
  enable_first: "首次还款{token}前，请先授权",
  enable_first__supply: "首次存入{token}前，请先授权",
  enable_first__mint: "首次销毁{token}前，请先授权",

  // menu
  menu__Synths: '通用池',
  menu_Legacy: '旧版',
  menu_Analytics: '分析',
  menu_Token_Terminal: 'Token Terminal',
  menu_Loan: '借贷',
  menu_Sec: '安全',
  menu_Audit_Reports: '审计报告',
  menu_Buy_Bounty: '赏金计划',
  menu_Markets: "借贷市场",
  menu_Lend: "借贷",
  menu_Synths: "合成",
  menu__General: "通用池",
  menu__Synthetic: "合成池",
  menu__Stocks: "股票池",
  menu_Staking: '质押',
  menu_Trade: "交易",
  menu_Earn: "生息",
  menu_Farm: "挖矿",
  menu_Governance: "治理",
  menu_Delegate: '委托',
  menu_Proposals: "投票",
  menu_markets: 'Yield',
  menu_usdx: 'USDx',
  menu_goldx: 'GOLDx',
  menu_Vote: "链上投票",
  menu_StakeDF: '活期',
  menu_LockDF: '定期',
  menu_Tutorials: "教程",
  menu_Liquidation: '清算',
  menu_Stake: '流动性挖矿',
  menu_Lending: '借贷挖矿',
  menu_Tools: '工具',
  menu_Price: '价格',
  menu_Bridge: '跨链桥',
  menu_Docs: '文档',
  menu_Integrations: '集成',

  // trade
  SEND: '发送',
  fee_tips: 'dForce不收取任何费用，但用户需为跨链转账支付少量gas费用。',
  min_swap_notes: '交易数量需要大于 {amount} {symbol}。',
  max_swap_notes: '交易数量需要小于 {amount} {symbol}。',
  RECEIVE: '接收',
  Amount: '数量',
  Balance: '余额',
  MAX: '最大值',
  search_placeholder: '搜索名称或地址',
  no_date: '没有数据',
  Enable_Gas_Token: '授权Gas Token',
  Enable_Gas_Token_tips: '使用Gas Token，有效降低交易成本',
  Advanced: '高级选项',
  Gas_Fee: 'Gas费用（GWEI）',
  slippage: '滑点设置',
  Minimum_received: '最小接收',
  Standard: '一般',
  Fast: '快速',
  Instant: '立即',
  SWAP: '兑换',
  No_Supported: '不支持',
  Unlocking: '解锁中...',
  Insufficient_Balance: '余额不足',
  No_liquidity: '流动性不足',
  Approve: '授权',
  crossTokenTooLess: '交易数量太小',
  crossTokenTooMore: '交易数量太大',
  // stake
  Dual_Rewards: '双挖奖励',
  Dual_Rewards__sub: '质押LP代币获得DF/DODO挖矿奖励',
  Stake_Title: '质押',
  Title__sub: 'Users will be required to supply liquidity for BLP (either DF/UTS or USX/UTS), equivalent to 1% of the total value of their deposit and staked iUSX in US dollars, to qualify for UTS rewards on their deposits and borrowings.',
  Noemissions: '$0 required to be eligible',
  eligible: 'required to be eligible',
  Eligibleforemissions: 'Eligible for emissions',
  Single_Reward: '单挖奖励',
  Single_Reward__sub: '质押LP代币获得DF挖矿奖励',
  Single_Reward__iusx: '质押代币获得挖矿奖励',
  All_Chains: '多链',
  Chain: '网络',
  AllType: '所有类型',
  YieldAggregator: '聚合',
  LiquidityMining: '流动性',
  _Vault: '金库',
  APY: '年化利率',
  APR: 'APR',
  MyBoost: 'My Boost',
  AssetsBoost: 'Assets Boost',
  ended: '已结束',
  normal: 'Normal',
  comingSoon: 'Soon',
  Zap: 'Zap',
  AcquireUTS: 'Acquire UTS',
  Pancake: 'Pancake',
  dmm: 'dmm',
  dodo: 'DODO',
  ShowEndedPools: '展示已结束',
  noData: '无数据',
  Combined_APY: '年化利率',
  Mining: '挖矿',
  Yielding: '底层利息',
  Simply: '单币资产',
  Earned: '收益',
  zap: 'zap',
  zapintoLP: 'zap into LP',
  Total_Staked: '总质押量',
  LPShare: 'LP Share',
  MyStaked: 'My Staked',
  Get: '获取',
  View_Contract: '查看合约',
  HARVEST: '提取',
  HARVESTING: '提取中...',
  STAKE: '质押',
  STAKING: '质押中...',
  UNSTAKE: '提取质押',
  Staked: '已质押： ',
  _Balance: '余额： ',
  UNSTAKING: '提取质押中...',
  Stake_Farming: '质押挖矿',
  Staking: 'Staking',
  Detail: '详情',
  Switch_to: '切换到 ',
  markets_closed: '非纳斯达克股票交易时间，取回抵押物、借款和铸币暂停',
  lock_up: '抵押资产解锁期限为：',
  // Liquidity
  Liquidity_Title: 'Stake iUSX to earn UTS',
  Liquidity_Title__sub: 'Users will be required to supply liquidity for BLP (either DF/UTS or USX/UTS), equivalent to 1% of the total value of their deposit and staked iUSX in US dollars, to qualify for UTS rewards on their deposits and borrowings.',
  // stakeDF
  _Title: '活期质押',
  _SubTitle: '活期质押的收益和投票权重均低于锁仓质押，但您可以随时退出质押。',
  LearnMore: '更多',
  Market_Staked_DF: '活期质押总量',
  // Market_Staked_DF_tips:'质押DF总量 / DF发行总量',
  As_of_total: '质押率',
  My_Staked_DF: '我的质押',
  Total_Circulating_sDF: 'sDF流通量',
  sDF_DF: 'sDF : DF',
  Staking_APY: '质押年化利率',
  enable_first__stake: '首次质押{token}前，请先授权',
  Stake: '质押 ',
  Unstake: '退出 ',
  UNSTAKE_sDF: '退出',
  Available_to_Unstake: '可取数量',
  Receive_DF: '可接收 : ',
  Receive_sDF: '可接收 : ',
  brewing_info1: '你有 ',
  brewing_info2: ' DF正在质押',
  lock_up_tips: '参与DF固定期限锁仓，获取投票权限以及更高的回报。',
  lock_up_Staking: '质押并锁仓',
  have_sDF: '你已持有sDF，',
  lock_up: '参与锁仓',
  // lockveDF
  Back: '返回',
  DF_veDF_Title: '锁仓质押',
  DF_veDF_SubTitle: '根据锁仓期限，获取更高的锁仓收益和治理投票权',
  Total_Locked: '锁仓质押总量',
  Total_DF_Locked_Tips: '锁仓DF总量 / 质押DF总量',
  veDF_Total_Supply: 'veDF流通总量',
  lock_As_of_total: '锁仓率',
  Average_Lock_Time: '平均锁仓时长',
  veDF_Holder_APY: '平均年化利率',
  APYfor6: '半年年化利率：',
  APYfor12: '1年年化利率：',
  APYfor24: '2年年化利率：',
  APYfor48: '4年年化利率',
  enable_first__stakeAndlock: '首次锁仓{token}前，请先授权',
  enable_first__lock: '首次锁仓{token}前，请先授权',
  Select_lock_title: '填写锁仓数量和选择锁仓时间',
  Re_create_Lock: '重新创建锁仓',
  Re_create_Lock_tips: '你可以用当前已解锁数量重新创建锁仓，也可以追加数量到新的锁仓仓位',
  Choose_lock_time: '选择锁仓时长 (年化收益率: ',
  ApyTips: '质押年化利率 + 锁仓年化利率',
  LockTips: '新的锁仓时长要大于现有锁仓时长',
  Unlock_by: '解锁时间: ',
  Receive_veDF: '可获得 : ',
  STAKE_AND_LOCK: '质押并锁仓',
  CREATE: '创建锁仓',
  ADD_AMOUNT: '新增锁仓数量',
  EXPEND_TIME: '增加锁仓时间',
  RECREATE_LOCK: '重新创建锁仓',
  CLAIM: '取出',
  CLAIM_AND_UNSTAKE: '取出并退出质押',
  claim_and_unwrap_DF: '取出并转化成DF',
  CLAIM_DF_TIPS: '选择该选项将取出激励并转成DF，或者继续享受活期质押收益',
  EXIT: '退出锁仓',
  ExitEnableTips: '首次退出，请先授权',
  My_Staking_Dashboard: '我的锁仓信息',
  Your_DF_Locked: '锁仓DF数量',
  My_Apy: '个人综合年化利率',
  Start_Time: '开始锁仓时间',
  Unlock_Time: '解锁时间',
  Unlockby_tips: '锁仓质押到期后，将自动转为活期质押',
  Staked_veDF: 'veDF余额',
  Available_to_Claim: '可取数量',
  // Proposals
  Delegate: '委托',
  veDFBalance: 'veDF余额',
  ProposalsList: '提案列表',
  Recipient: '接收者',
  Check_the_list: '检查列表',
  Self: '自己',
  // delegate List
  d_Title: '委托治理',
  d_SubTitle: 'veDF代币的持币人可以直接投票，或将投票权委托给指定代表，由其代为参与日常的治理事务。',
  list_title: '选择委托地址进行委托',
  Delegating_To: '委托给',
  change: '更换',
  Rank: '排名',
  Address: '地址',
  VoteWeight: '投票权重',
  TotalVotes: '总票数',
  // delegate modal
  ChooseDelegationType: '委托',
  sec1: '激活DF治理权，参与治理投票',
  Activate: '激活',
  sec2: '选择委托列表地址委托或输入地址进行委托',
  WalletAddress: '钱包地址',
  // Price
  PriceTitle: '价格源',
  PriceTitle__sub: 'Unitus的清算向所有为借款人偿还拖欠贷款并因此获得丰厚奖金的人开放，这有助于保持系统的偿付能力和稳健性。',
  Select_a_Token: '选择资产',
  Select_Source_Chain: 'Select Source Chain',
  Select_Target_Chain: 'Select Target Chain',
  not_support_token_pair: '没有交易路径',
  // LSR
  Mint: '铸币',
  Title: '使用支持的稳定币以1:1的汇率铸造或销毁USX',
  Swap_Tokens: '铸币 / 销币',
  _Send: '发送',
  _Receive: '接收',
  Fee: '费用',
  Reserves: '库存',
  Reserves_new: '流动性',
  Collaterals: '抵押品',
  Debt_Ceiling: '债务上限',
  Mintage: '铸币量 (使用率)',
  Mintage_new: '库存 (使用率)',
  Ethereum: 'Ethereum',
  Arbitrum: 'Arbitrum',
  Optimism: 'Optimism',
  BSC: 'BSC',
  Polygon: 'Polygon',
  // swap
  swap__Swap: '兑换',
  Insufficient_Liquidity: '流动性不足',
  swap_Price: '价格',
  Advanced_settings: '设置',
  Minimum_Received: '最小接收',
  Gas_Price: '交易速度',
  Gas_Normal: '标准',
  Gas_Fast: '快速',
  Gas_Instant: '极速',
  swap__title: '聚合不同平台的流动性，确保交易深度和最佳价格',
};
export default zh_CN;