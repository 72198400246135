import React, { useEffect, useState } from 'react'
import { useIntl } from "react-intl"
import { GetUrlParam, getName } from 'utils'
import Modal from 'components/Modal'
import ModalTitle from 'components/Modal/components/ModalTitle'
import ModalContent from 'components/Modal/components/ModalContent'
import styled from 'styled-components'
import { useWeb3React } from '@web3-react/core'

import metamask from 'assets/wallet-MetaMask.svg'
import coinbase from 'assets/wallet-Coinbase.svg'
import walletConnectLogo from 'assets/wallet-WalletConnect.svg'
import BitKeep from 'assets/walletLog/BitKeep.svg'
import Rabby from 'assets/walletLog/Rabby.svg'
import Brave from 'assets/walletLog/Brave.svg'
import OKX from 'assets/walletLog/OKX.svg'
import imtoken from 'assets/walletLog/imToken.svg'
import Maths from 'assets/walletLog/Maths.svg'
import MYKEY from 'assets/walletLog/MYKEY.svg'
import ONTO from 'assets/walletLog/ONTO.svg'
import TokenPocket from 'assets/walletLog/TokenPocket.svg'
import SafePal from 'assets/walletLog/SafePal.svg'
import TrustWallet from 'assets/walletLog/TrustWallet.svg'
import WalletProviderCard from './components/WalletProviderCard'

import { format_num_to_K } from "utils"
import ReactTooltip from 'react-tooltip'
import IERC20 from "../../lending-sdk/lib/clean_build/contracts/general/IERC20.json"
import MetaMaskCard from 'walletCards/connectorCards/MetaMaskCard'
import WalletConnectV2Card from 'walletCards/connectorCards/WalletConnectV2Card'
import CoinbaseWalletCard from 'walletCards/connectorCards/CoinbaseWalletCard'
import { IconLaunch } from '@arco-design/web-react/icon'
declare global {
  interface Window {
    isBitKeep:boolean,
    bitkeep:{
      ethereum:boolean,
    },
    okxwallet:boolean,
    new_web3: any;
  }
}

export interface ModalProps {
  isOpen?: boolean;
  onDismiss?: () => void;
  metamaskActive?: boolean;
  walletConnectActive?: boolean
}

const GetInstallWallet = (ethereum:any) =>{
  let isCoinbaseWallet:boolean = false
  let isTrustWallet:boolean = false
  if(ethereum?.providers){
    isCoinbaseWallet = !!(ethereum?.providers.find((item:any) =>item?.isCoinbaseWallet))
    isTrustWallet = !!(ethereum?.providers.find((item:any) =>isTrustWallet = item?.isTrustWallet))
  }else{
    isCoinbaseWallet = !!ethereum?.isCoinbaseWallet;
    isTrustWallet = !!ethereum?.isTrustWallet;
  }
  return {
    isCoinbaseWallet,
    isTrustWallet
  }
}

const UnlockWalletModal: React.FC<ModalProps> = ({
  isOpen,
  onDismiss,
  metamaskActive,
  walletConnectActive
}) => {
  const [totalAssets, setTotalAssets] = useState<any>()
  const {
    connector,
    account,
    chainId,
    provider,
    ENSName,
    isActive,
    isActivating,
  } = useWeb3React();
  const intl = useIntl();
  const fmt = intl.formatMessage;

  const supportchain = chainId ? Object.keys(IERC20.networks).includes((chainId as number).toString()) : false;

  const InjectLogArr = {
    metamask,
    Brave,
    BitKeep,
    Rabby,
    OKX,
    imtoken,
    Maths,
    MYKEY,
    ONTO,
    TokenPocket,
    SafePal,
    TrustWallet
  }
  const InjectNameArr = {
    metamask: 'MetaMask',
    Brave:'Brave',
    BitKeep: 'BitKeep',
    Rabby:'Rabby',
    OKX:'OKX',
    imtoken: 'imtoken',
    Maths: 'Maths',
    MYKEY: 'MYKEY',
    ONTO: 'ONTO',
    TokenPocket: 'TokenPocket',
    SafePal:'SafePal',
    TrustWallet:'TrustWallet'
  }
  const [injectLog, setInjectLog] = useState(metamask)
  const [injectName, setInjectName] = useState('Metamask')

  useEffect(() => {
    const getWalletChannel = GetUrlParam("utm_source")
    InjectLogArr[getWalletChannel] ? setInjectLog(InjectLogArr[getWalletChannel]) : setInjectLog(InjectLogArr["metamask"])
    InjectLogArr[getWalletChannel] ? setInjectName(InjectNameArr[getWalletChannel]) : setInjectName(InjectNameArr["metamask"])
  }, [])

  useEffect(() => {
    if (account) {
      onDismiss && onDismiss()
    }
    // if (connector) {
    //   localStorage.setItem("walletProvider", connector);
    // }
  }, [account, onDismiss])


  // const fetchTotalAssets = () => {
  //   if (!account) { return console.log('account not available...') }
  //   const url = 'https://openapi.debank.com/v1/user/total_balance?id=' + account

  //   try {
  //     fetch(url, { method: 'get' })
  //       .then(e => e.json())
  //       .then(function (response) {
  //         // console.log(response, response.total_usd_value, typeof (response.total_usd_value))
  //         setTotalAssets(response)
  //       })
  //       .catch(function (err) {
  //         console.log(err)
  //       })
  //   } catch (err) {
  //     console.log(err)
  //   }
  // }
  // useEffect(() => {
  //   fetchTotalAssets()
  //   const timer = setInterval(() => {
  //     fetchTotalAssets()
  //   }, 1000 * 15)
  //   return () => {
  //     clearInterval(timer)
  //   }
  // }, [account])

  return (
    <Modal isOpen={isOpen}>
      <ModalTitle text={fmt({ id: "Connect_Wallet" })} onDismiss={onDismiss} />
      <ModalContent>
        <StyledWalletsWrapper>
        <WalletWarp>
            {!chainId && <WalletModalText className="gray">Connect wallet in one click to start using Aspida</WalletModalText>}
            <WalletList>
              {((getName(connector) === "MetaMask" && chainId) ||
                !chainId ||
                (chainId && !supportchain)) && <MetaMaskCard />}
              {((getName(connector) === "WalletConnect" && chainId) ||
                !chainId ||
                (chainId && !supportchain)) && <WalletConnectV2Card />}
              {((getName(connector) === "Coinbase" && chainId) ||
                !chainId ||
                (chainId && !supportchain)) && <CoinbaseWalletCard />}
            </WalletList>
            {!chainId && <WalletModalbtm className="grayNoFont walletBg">View only permissions. We cannot do anything without your approval,How to use Aspida? <a href="#" style={{ color: '#FBBF24' }}>Learn more <IconLaunch className="cursor-pointer ml-1" /></a></WalletModalbtm>}
          </WalletWarp>
          {/* {
            window.ethereum?.isMetaMask ?
            <WalletProviderCard
            isOpen={isOpen}
            icon={injectLog}
            name={injectName}
            // onSelect={onConnectMetamask}
            onSelect={()=>{}}
            active={metamaskActive}
            />
            :
            <InstallMetamask href="https://metamask.io/" target="_blank" rel="noopener noreferrer">
              <CardContent>
                <StyledName>MetaMask</StyledName>
                <CardIcon src={metamask}></CardIcon>
              </CardContent>
            </InstallMetamask>
          } */}
        </StyledWalletsWrapper>
      </ModalContent>
    </Modal>
  )
}

const WalletWarp = styled.div`
  
`
const WalletModalText = styled.p`
  margin-top: -12px;
  margin-bottom: 12px;
`
const WalletList = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
`
const WalletModalbtm = styled.p`
  font-size: 15px;
  margin-top: 12px;
  padding: 16px;
`
const RouterLink = styled.a`
  color: #333;
  &:hover {
    color: #333;
  }
`
const ColorText = styled.span`
  color: rgb(33, 190, 130);
`
const YourAssetsWrap = styled.div`
  margin-top: 20px;
  font-size: 16px;
  font-weight: 600;
`
const StyledWalletsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 600px) {
    flex-direction: column;
    flex-wrap: none;
  }
`
const InstallMetamask = styled.a`
  display: block;
  width: 380px;
  height: 68px;
  display:flex;
  align-items:center;
  border-radius: ${(props) => props.theme.borderradius};
  margin-top:10px;
  cursor: pointer;
  border:2px transparent solid;
  border:1px solid ${(props)=>props.theme.colors.lightTableBorder};
  border-image:'none';
  &.disabled{
    opacity:0.5;
    background: #EBEDF2;
  }
  @media(max-width: 1199px) {
    width:100%
  }
`
const CardContent = styled.div`
  width:100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items:center;
  padding:20px;
  @media(max-width: 1199px) {
    padding:10px;
  }
`
const CardIcon = styled.img`
  height:30px;
  @media(max-width: 1199px) {
    height:20px
  }
`
const StyledName = styled.div`
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  color: ${(props) => props.theme.DarkfontColor[100]};
`
export default UnlockWalletModal