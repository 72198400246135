import React, { useState, useEffect, useCallback } from 'react'
import { useParams, NavLink } from 'react-router-dom'
import { useWeb3React } from '@web3-react/core'
import styled from 'styled-components'
import { useIntl } from 'react-intl'
import { ethers } from 'ethers'
import { format_bn, format_num_to_K, useQuery, DFApproveXArray } from 'utils'
import { add_Notifies, update_Notifies, del_Notify, del__cancel } from 'lending-sdk/utils/index1'
import UnlockWalletModal from "components/UnlockWalletModal"
import controller_json from 'lending-sdk/lib/clean_build/contracts/general/Controller.json'
import { LendingDataV2 } from 'lendingData/gen'

import useLending from 'hooks/useLending'
import BigNumber from 'bignumber.js'
import Notification from 'components/Notification'
import Notification__cancel from 'components/Notification__cancel'

// images
import lock from 'assets/lock.svg'
import metamask from "assets/wallet-MetaMask.svg"
import Brave from 'assets/walletLog/Brave.svg'
import coinbase from "assets/wallet-Coinbase.svg";
import icon_walletconnect from "assets/wallet-WalletConnect.svg"

import FlexBoxWrap from "components/FlexBoxWrap"
import FlexBGFilter from 'components/FlexBGFilter'

const Liquidate: React.FC = () => {
  const [input_number, setInput_number] = useState<any>()
  const [isMAX, setIsMAX] = useState<any>()

  const local_account = window.localStorage.getItem('account') || ''
  const [unlockModalIsOpen, setUnlockModalIsOpen] = useState(false)
  const [metamaskActive, setMetamaskActive] = useState(false)
  const [walletConnectActive, setWalletConnectActive] = useState(false)
  const [walletIcon, setWalletIcon] = useState("")

  const [nowNotifies, setNowNotifies] = useState<any>([])
  const [show__cancel, setShow__cancel] = useState<boolean>(false)
  const [chainID, setChainID] = useState<number>(1)
  const [bool_Btn, setBool_Btn] = useState<boolean>(false)
  const [has_click_approve, setHas_click_approve] = useState<boolean>(false)

  const [accountTokens, setAccountTokens] = useState<any>()
  const [left_activeIndex, setLeft_activeIndex] = useState<number>(0)
  const [right_activeIndex, setRight_activeIndex] = useState<number>(0)

  const [arr_liquidationInfo, setArr_liquidationInfo] = useState<any>()
  const [arr_allowance, setArr_allowance] = useState<any>()
  const [willRepayToken, setWillRepayToken] = useState<string>('')

  const [address_map, setaddress_map] = useState<any>({})
  const [contract_map, setcontract_map] = useState<any>({})

  const intl = useIntl()
  const fmt = intl.formatMessage
  const { Liquidate_account } = useParams<any>()
  const {
    connector,
    account,
    chainId,
    provider,
    ENSName,
    isActive,
    isActivating,
  } = useWeb3React();
  const Lending = useLending()
  // 获取router URLParams
  let query = useQuery()


  const getStatus = useCallback(async () => {
    // console.log('*** getStatus ***')
    if (Object.keys(contract_map).length > 0 && Object.keys(address_map).length > 0) {
      const provider = new ethers.providers.Web3Provider(Lending.web3.currentProvider)
      const controller_address = controller_json.networks[chainId as number].Controller.address
      const ld = new LendingDataV2(controller_address, provider)
      const res = await ld.getAccountTokens(Liquidate_account as string)

      if (res) {
        setAccountTokens(res)
        // console.log(res)
        let _supplyTokensArray = res && res[0]
        const _borrowTokensArray = res && res[3]
        // console.log('_supplyTokensArray: ', _supplyTokensArray)
        console.log('_borrowTokensArray: ', _borrowTokensArray)

        let t_arr: any = []
        let t_arr__allowance: any = []

        if (!(_borrowTokensArray.length > 0)) {
          return console.log('***** data not ready *****')
        }

        if (!(_supplyTokensArray.length > 0)) {
          _supplyTokensArray = [_borrowTokensArray[0]]
        }

        for (let i = 0; i < _borrowTokensArray.length; i++) {
          // 右侧列表 token
          // console.log('borrowed token:', i, address_map[_borrowTokensArray[i]])
          const liquidationInfo: any = await ld.getLiquidationInfo(
            Liquidate_account as string,
            account as string,
            _borrowTokensArray[i],
            _supplyTokensArray[left_activeIndex]
          )
          if (liquidationInfo) {
            t_arr.push(liquidationInfo)

            // console.log(Lending?.contracts?.general)
            let isGasToken = Lending?.contracts?.general[
              address_map[_borrowTokensArray[i]].includes('iM')
                ? address_map[_borrowTokensArray[i]].includes('iMATIC')
                  ? address_map[_borrowTokensArray[i]].slice(1)
                  : address_map[_borrowTokensArray[i]].slice(2)
                : address_map[_borrowTokensArray[i]].slice(1)
            ].gasToken
            if (isGasToken) {
              t_arr__allowance.push(true)
            } else {
              await Lending?.contracts?.general[
                address_map[_borrowTokensArray[i]].includes('iM')
                  ? address_map[_borrowTokensArray[i]].includes('iMATIC')
                    ? address_map[_borrowTokensArray[i]].slice(1)
                    : address_map[_borrowTokensArray[i]].slice(2)
                  : address_map[_borrowTokensArray[i]].slice(1)
              ].methods.allowance(
                account, Lending.contracts?.general[address_map[_borrowTokensArray[i]]].address
              )
                .call()
                .then((res_allowance: any) => {
                  t_arr__allowance.push(new BigNumber(res_allowance).gt(new BigNumber(0)))
                })
            }
          }
          // await Lending?.contracts?.general.LendingData.methods
          //   .getLiquidationInfo(
          //     Liquidate_account,
          //     account,
          //     _borrowTokensArray[i],
          //     _supplyTokensArray[left_activeIndex]
          //   )
          //   .call()
          //   .then(async (liquidationInfo: any) => {})
        }
        // console.log('AccountTokens: ', res)
        // console.log('left_activeIndex: ', left_activeIndex)
        // console.log('right_activeIndex: ', right_activeIndex)

        // 0: '2708541869508962692', 
        // 1: '2708541869508962692', 
        // 2: '0'
        // console.log('liquidation信息: ', t_arr) // []-0: 
        // console.log('is-approved: ', t_arr__allowance)

        setArr_liquidationInfo(t_arr)
        setArr_allowance(t_arr__allowance)
        setWillRepayToken(address_map[_borrowTokensArray[right_activeIndex]])
      }
      // Lending?.contracts?.general.LendingData. methods
      //   .getAccountTokens(Liquidate_account)
      //   .call()
      //   .then(async (res: any) => )
    }
  }, [left_activeIndex, right_activeIndex, contract_map])


  const handleDismissUnlockModal = useCallback(() => {
    setUnlockModalIsOpen(false);
    setMetamaskActive(false);
    setWalletConnectActive(false);
  }, [setUnlockModalIsOpen]);
  const handleUnlockWalletClick = useCallback(() => {
    setUnlockModalIsOpen(true);
  }, [setUnlockModalIsOpen]);


  useEffect(() => {
    // console.log('*** ***', Lending)
    if (Lending && Lending.contracts?.general.liqudateAddressMap) {
      let new_address_map = {}
      for (let k of Object.keys(Lending.contracts?.general.liqudateAddressMap)) {
        new_address_map[Lending.contracts?.general.liqudateAddressMap[k]] = k;
      }
      const new_contract_map = Lending.contracts?.general.liqudateAddressMap
      setcontract_map(new_contract_map)
      setaddress_map(new_address_map)
      console.log('address_map: ', new_address_map)
      // console.log('contract_map: ', new_contract_map)
    }
    getStatus()
    Lending?.web3.eth.getChainId().then((chainID: any) => {
      // console.log(chainID)
      setChainID(chainID)
    })

    let refreshInterval = setInterval(getStatus, 1000 * 10)
    return () => clearInterval(refreshInterval)
  }, [Lending, contract_map, left_activeIndex, right_activeIndex])

  useEffect(() => {
    clearLocalStorage();
  })

  const clearLocalStorage = () => {
    let localdata: any = window.localStorage.getItem("my_notify");

    if (localdata) {
      console.log("*** removeItem---my_notify ***");
      window.localStorage.removeItem("my_notify");
    }
  }

  const click_left = (index: number) => {
    console.log(index)
    setLeft_activeIndex(index)
    setInput_number('')
  }
  const click_right = (index: number, token: string) => {
    console.log(index, token)
    console.log(contract_map[token])

    setRight_activeIndex(index)
    setWillRepayToken(token)
    setInput_number('')
  }

  const click_approve = (address: string) => {
    let Cur_tokenSymbol = address_map[address]
    if (has_click_approve) {
      return console.log('already click approve btn...')
    }
    setHas_click_approve(true)

    if (DFApproveXArray.includes(Lending?.contracts?.general[
      Cur_tokenSymbol.includes('iM')
        ? Cur_tokenSymbol.includes('iMATIC')
          ? Cur_tokenSymbol.slice(1)
          : Cur_tokenSymbol.slice(2)
        : Cur_tokenSymbol.slice(1)
    ].address)) {
      Lending?.contracts?.general[
        Cur_tokenSymbol.includes('iM')
          ? Cur_tokenSymbol.includes('iMATIC')
            ? Cur_tokenSymbol.slice(1)
            : Cur_tokenSymbol.slice(2)
          : Cur_tokenSymbol.slice(1)
      ].methods
        .approvex(address)
        .send({ from: account })
        .on('transactionHash', function (hash: string) {
          console.log('transactionHash: ', hash)
          add_Notifies({
            state: 'pedding',
            action: Cur_tokenSymbol.includes('iM')
              ? Cur_tokenSymbol.includes('iMATIC')
                ? 'Enable ' + Cur_tokenSymbol.slice(1)
                : 'Enable ' + Cur_tokenSymbol.slice(2)
              : 'Enable ' + Cur_tokenSymbol.slice(1),
            transactionHash: hash,
            time: new Date().getTime()
          }, setNowNotifies, account as string, chainId as number)
          setBool_Btn(false)
        })
        .on('receipt', function (receipt: any) {
          console.log('receipt: ', receipt)
          getStatus()
          update_Notifies(receipt, setNowNotifies, account as string, chainId as number);
          setTimeout(async () => {
            await del_Notify(receipt, setNowNotifies);
          }, 3000);
        })
        .on('error', function (error: any) {
          console.log('error', error)
          setHas_click_approve(false)
          setShow__cancel(true)
          // update_Notifies(error)
          setTimeout(() => {
            setShow__cancel(false)
            // del_Notify(error)
          }, 3000)
        })
    } else {
      Lending?.contracts?.general[
        Cur_tokenSymbol.includes('iM')
          ? Cur_tokenSymbol.includes('iMATIC')
            ? Cur_tokenSymbol.slice(1)
            : Cur_tokenSymbol.slice(2)
          : Cur_tokenSymbol.slice(1)
      ].methods
        .approve(address, ethers.constants.MaxUint256)
        .send({ from: account })
        .on('transactionHash', function (hash: string) {
          console.log('transactionHash: ', hash)
          add_Notifies({
            state: 'pedding',
            action: Cur_tokenSymbol.includes('iM')
              ? Cur_tokenSymbol.includes('iMATIC')
                ? 'Enable ' + Cur_tokenSymbol.slice(1)
                : 'Enable ' + Cur_tokenSymbol.slice(2)
              : 'Enable ' + Cur_tokenSymbol.slice(1),
            transactionHash: hash,
            time: new Date().getTime()
          }, setNowNotifies, account as string, chainId as number)
          setBool_Btn(false)
        })
        .on('receipt', function (receipt: any) {
          console.log('receipt: ', receipt)
          getStatus()
          update_Notifies(receipt, setNowNotifies, account as string, chainId as number);
          setTimeout(async () => {
            await del_Notify(receipt, setNowNotifies);
          }, 3000);
        })
        .on('error', function (error: any) {
          console.log('error', error)
          setHas_click_approve(false)
          setShow__cancel(true)
          // update_Notifies(error)
          setTimeout(() => {
            setShow__cancel(false)
            // del_Notify(error)
          }, 3000)
        })
    }
  }
  const input_change = (val: string) => {
    val = val.trim();
    var reg_ZH_CN = new RegExp("[\\u4E00-\\u9FFF]+", "g");
    var reg_ABC = /[a-z]/g;

    // 限制只可输入小数点后6位
    let quantityScale = "6";
    let reg_maxLength = new RegExp("^(0|[1-9][0-9]*)(\\.[0-9]{0," + quantityScale + "})?$");
    if (!reg_maxLength.test(val) && val !== "") {
      return console.log("The maximum number of digits after the decimal point is exceeded")
    }

    if (reg_ZH_CN.test(val)) {
      return console.log("不可以输入中文");
    }
    if (reg_ABC.test(val)) {
      return console.log("English ABC prohibited");
    }

    let bn_string = new BigNumber(val)
      .multipliedBy(new BigNumber(10).pow(new BigNumber(accountTokens[5][right_activeIndex].toString())))
      .toString()
    console.log(bn_string)

    if (new BigNumber(bn_string).gt(new BigNumber(arr_liquidationInfo[right_activeIndex][1].toString()))) {
      console.log(' ***** to MAX *****')
      return click_max()
    }

    setInput_number(val)
    setIsMAX(false)
    console.log(val)
    console.log(
      'status: ', '\n',
      'MAX Liquidation: ', arr_liquidationInfo[right_activeIndex][0], '\n',
      'cur decimal: ', accountTokens[5][right_activeIndex], '\n',
      'could Liquidation: ', arr_liquidationInfo[right_activeIndex][1], '\n',
    )
  }


  const click_liquidate__natural = () => {
    let tokenSymbol = address_map[accountTokens[3][right_activeIndex]]
    let tokenDecimal = accountTokens[5][right_activeIndex].toString()

    // 取小值
    let temp_num = new BigNumber(arr_liquidationInfo[right_activeIndex][1].toString())
      .lt(new BigNumber(arr_liquidationInfo[right_activeIndex][2].toString()))
      ? arr_liquidationInfo[right_activeIndex][1].toString()
      : arr_liquidationInfo[right_activeIndex][2].toString()

    let bn__input_number = isMAX
      ? temp_num
      : new BigNumber(input_number).multipliedBy(new BigNumber(10).pow(new BigNumber(tokenDecimal))).toString()
    console.log(
      'data: \n',
      'token: ' + tokenSymbol, '\n',
      'decimal: ' + tokenDecimal, '\n',
      'input_number: ' + bn__input_number, '\n',
      'assetCollateral: ' + accountTokens[0][left_activeIndex]
    )
    // return tokenSymbol === 'ETH' ?

    Lending?.contracts?.general[tokenSymbol].methods
      .liquidateBorrow(Liquidate_account, bn__input_number, accountTokens[0][left_activeIndex])
      .send({ from: account })
      .on('transactionHash', function (hash: string) {
        console.log('transactionHash: ', hash)
        input_change('')
        add_Notifies({
          state: 'pedding',
          action: tokenSymbol.includes('iM')
            ? tokenSymbol.includes('iMATIC')
              ? 'Liquidate ' + tokenSymbol.slice(1)
              : 'Liquidate ' + tokenSymbol.slice(2)
            : 'Liquidate ' + tokenSymbol.slice(1),
          transactionHash: hash,
          time: new Date().getTime()
        }, setNowNotifies, account as string, chainId as number)
        setBool_Btn(false)
      })
      .on('receipt', function (receipt: any) {
        console.log('receipt: ', receipt)
        getStatus()
        update_Notifies(receipt, setNowNotifies, account as string, chainId as number);
        setTimeout(async () => {
          await del_Notify(receipt, setNowNotifies);
        }, 3000);
        setBool_Btn(false)
      })
      .on('error', function (error: any) {
        console.log('error', error)
        setBool_Btn(false)
        setShow__cancel(true)
        // update_Notifies(error)
        setTimeout(() => {
          setShow__cancel(false)
          // del_Notify(error)
        }, 3000)
      })
  }
  const click_liquidate__spe = () => {
    let tokenSymbol = address_map[accountTokens[3][right_activeIndex]]
    let tokenDecimal = accountTokens[5][right_activeIndex].toString()

    // 取小值
    let temp_num = new BigNumber(arr_liquidationInfo[right_activeIndex][1].toString())
      .lt(new BigNumber(arr_liquidationInfo[right_activeIndex][2].toString()))
      ? arr_liquidationInfo[right_activeIndex][1].toString()
      : arr_liquidationInfo[right_activeIndex][2].toString()

    let bn__input_number = isMAX
      ? temp_num
      : new BigNumber(input_number).multipliedBy(new BigNumber(10).pow(new BigNumber(tokenDecimal))).toString()
    console.log(
      'data: \n',
      'token: ' + tokenSymbol, '\n',
      'decimal: ' + tokenDecimal, '\n',
      'input_number: ' + bn__input_number, '\n',
      'assetCollateral: ' + accountTokens[0][left_activeIndex]
    )
    // return tokenSymbol === 'ETH' ?

    Lending?.contracts?.general[
      tokenSymbol.includes('iM')
        ? tokenSymbol.includes('iMATIC')
          ? tokenSymbol.slice(1)
          : tokenSymbol.slice(2)
        : tokenSymbol.slice(1)
    ].methods
      .liquidateBorrow(Liquidate_account, accountTokens[0][left_activeIndex])
      .send({ from: account, value: bn__input_number })
      .on('transactionHash', function (hash: string) {
        console.log('transactionHash: ', hash)
        input_change('')
        add_Notifies({
          state: 'pedding',
          action: tokenSymbol.includes('iM')
            ? tokenSymbol.includes('iMATIC')
              ? 'Liquidate ' + tokenSymbol.slice(1)
              : 'Liquidate ' + tokenSymbol.slice(2)
            : 'Liquidate ' + tokenSymbol.slice(1),
          transactionHash: hash,
          time: new Date().getTime()
        }, setNowNotifies, account as string, chainId as number)
        setBool_Btn(false)
      })
      .on('receipt', function (receipt: any) {
        console.log('receipt: ', receipt)
        getStatus()
        update_Notifies(receipt, setNowNotifies, account as string, chainId as number);
        setTimeout(async () => {
          await del_Notify(receipt, setNowNotifies);
        }, 3000);
        setBool_Btn(false)
      })
      .on('error', function (error: any) {
        console.log('error', error)
        setBool_Btn(false)
        setShow__cancel(true)
        // update_Notifies(error)
        setTimeout(() => {
          setShow__cancel(false)
          // del_Notify(error)
        }, 3000)
      })
  }
  const click_liquidate = () => {
    console.log('l: ', left_activeIndex, 'r: ', right_activeIndex)
    if (bool_Btn) {
      return console.log('already liquidating...')
    }
    if (!input_number) {
      return console.log('input_number undefined...')
    }
    if (!arr_allowance[right_activeIndex]) {
      return console.log('right_activeIndex not approved yet...')
    }
    if (new BigNumber(input_number).eq(new BigNumber(0))) {
      return console.log('input_number is 0...')
    }

    let tokenSymbol = address_map[accountTokens[3][right_activeIndex]]
    let tokenDecimal = accountTokens[5][right_activeIndex].toString()
    let bn__input_number = isMAX
      ? arr_liquidationInfo[right_activeIndex][1].toString()
      : new BigNumber(input_number).multipliedBy(new BigNumber(10).pow(new BigNumber(tokenDecimal))).toString()
    console.log(tokenSymbol)
    console.log(
      'data: \n',
      'token: ' + tokenSymbol, '\n',
      'decimal: ' + tokenDecimal, '\n',
      'input_number: ' + bn__input_number, '\n',
      'assetCollateral: ' + accountTokens[0][left_activeIndex]
    )
    if (bn__input_number === '0') {
      return console.log('bn__input_number is 0...')
    }

    let isGasToken = Lending?.contracts?.general[
      tokenSymbol.includes('iM')
        ? tokenSymbol.includes('iMATIC')
          ? tokenSymbol.slice(1)
          : tokenSymbol.slice(2)
        : tokenSymbol.slice(1)
    ].gasToken
    console.log(isGasToken)

    setBool_Btn(true)
    if (isGasToken) {
      click_liquidate__spe()
    } else {
      click_liquidate__natural()
    }
  }


  const click_max = () => {
    console.log(
      'status: ', '\n',
      'MAX Liquidation: ', arr_liquidationInfo[right_activeIndex][0], '\n',
      'cur decimal: ', accountTokens[5][right_activeIndex], '\n',
      'could Liquidation: ', arr_liquidationInfo[right_activeIndex][1], '\n',
    )

    // 取小值
    let temp_num = new BigNumber(arr_liquidationInfo[right_activeIndex][1].toString())
      .lt(new BigNumber(arr_liquidationInfo[right_activeIndex][2].toString()))
      ? arr_liquidationInfo[right_activeIndex][1].toString()
      : arr_liquidationInfo[right_activeIndex][2].toString()
    let to_show = format_bn(temp_num, accountTokens[5][right_activeIndex].toString(), 6)
    setInput_number(to_show)
    setIsMAX(true)
  }

  return (
    <>
      <UnlockWalletModal
        isOpen={unlockModalIsOpen}
        onDismiss={handleDismissUnlockModal}
        metamaskActive={metamaskActive}
        walletConnectActive={walletConnectActive}
      />
      <Notification chainID={chainID} notifies={nowNotifies} setNowNotifies={setNowNotifies} del_Notify={del_Notify} />
      <Notification__cancel notifies={show__cancel} setShow__cancel={setShow__cancel} del_Notify={del__cancel} />

      <FlexBoxWrap>
        <AssetWrap>
          <StyledTitle>
            {fmt({ id: "Liquidation" })}
          </StyledTitle>
          <PriceTitleSub>{fmt({ id: "PriceTitle__sub" })}</PriceTitleSub>
        </AssetWrap>
      </FlexBoxWrap>

      <FlexBGFilter>
        <FlexBoxWrap>
          <TabWrapInner>
            <Crumb>
              <span>
                <RouterLink to={`/Liquidation`}>
                  <BackToList>{fmt({ id: 'liquidation_list' })}</BackToList>
                </RouterLink>
              </span>
            </Crumb>
          </TabWrapInner>
        </FlexBoxWrap>
      </FlexBGFilter>

      <FlexBoxWrap>
        <StyledWrap>
          <SupplyList>
            <Supply_title>
              <span>{fmt({ id: 'Supply_Assets' })}</span>
              <span>{fmt({ id: 'liquidation_amount' })}</span>
            </Supply_title>
            {
              accountTokens && (accountTokens[0].length > 0) && accountTokens[0].map((address: string, index: number) => {
                return (
                  <Supply_item
                    key={index}
                    className={left_activeIndex === index ? 'is_choosen_item' : ''}
                    onClick={() => { click_left(index) }}
                  >
                    <span>{address_map[address]}</span>
                    <span>{format_bn(accountTokens[1][index].toString(), accountTokens[2][index], 4)}</span>
                  </Supply_item>
                )
              })
            }
          </SupplyList>

          <BorrowList>
            <Borrow_title>
              <span>{fmt({ id: 'Borrow_Assets' })}</span>
              <span>{fmt({ id: 'liquidation_amount' })}</span>
              <span>{fmt({ id: 'MAX_Liquidation' })}</span>
              <span>{fmt({ id: 'Wallet_Balance' })}</span>
            </Borrow_title>
            {
              arr_allowance &&
              arr_liquidationInfo &&
              accountTokens &&
              (accountTokens[3].length > 0) &&
              // accountTokens[3]:  _borrowTokens <array>
              accountTokens[3].map((address: string, index: number) => {
                return (
                  <Borrow_item
                    key={index}
                    className={right_activeIndex === index ? 'is_choosen_item' : ''}
                    onClick={() => { click_right(index, address_map[address]) }}
                  >
                    {/* Borrow_Assets */}
                    <span>
                      {
                        address_map[address]?.includes('iM')
                          ? address_map[address]?.includes('iMATIC')
                            ? address_map[address]?.slice(1)
                            : address_map[address]?.slice(2)
                          : address_map[address]?.slice(1)
                      }
                    </span>
                    <span>{format_bn(accountTokens[4][index].toString(), accountTokens[5][index], 4)}</span>
                    {/* MAX_Liquidation */}
                    <span>{format_bn(arr_liquidationInfo?.[index]?.[1].toString(), accountTokens[5][index], 4)}</span>
                    <span>
                      {format_bn(arr_liquidationInfo?.[index]?.[2].toString(), accountTokens[5][index], 4)}
                      {
                        !arr_allowance[index] &&
                        <StyledImg src={lock} alt="" onClick={() => { click_approve(address) }} />
                      }
                    </span>
                  </Borrow_item>
                )
              })
            }
          </BorrowList>
        </StyledWrap>

        <StyledOperation>
          <RepayToken>
            {fmt({ id: 'RequestedAmountClose' })}
            ({
              willRepayToken.includes('iM')
                ? willRepayToken.includes('iMATIC')
                  ? willRepayToken.slice(1)
                  : willRepayToken.slice(2)
                : willRepayToken.slice(1)
            })
          </RepayToken>

          {
            arr_liquidationInfo && arr_liquidationInfo[right_activeIndex][3] === false ?
              <StyledYouDid>
                {fmt({ id: "markets_closed" })}
              </StyledYouDid>
              :
              <InputWrap>
                <StyledInput
                  placeholder={fmt({ id: 'Amount' })}
                  onChange={(e) => { input_change(e.target.value) }}
                  value={input_number || ''}
                />
                <SpanMAX onClick={click_max}>{fmt({ id: 'MAX' })}</SpanMAX>
              </InputWrap>
          }

          {
            !local_account ?
              <>
                <StyledButton onClick={handleUnlockWalletClick}>
                  {fmt({ id: "Connect_Wallet" })}
                </StyledButton>
              </>
              :
              <StyledButton
                className={bool_Btn ? 'disabledBtn' : ''}
                onClick={click_liquidate}>
                {fmt({ id: 'Liquidate' })}
              </StyledButton>
          }

        </StyledOperation>
      </FlexBoxWrap>
    </>
  )
}



const StyledYouDid = styled.div`
  background: #fff8f8;
  font-size: 14px;
  font-weight: 400;
  color: #ff6565;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  text-align: center;
  padding: 10px 60px;

  .dark & {
    background: none;
  }
`

const SpanMAX = styled.span`
  position: absolute;
  right: 15px;
  top: 10px;
  cursor: pointer;

  .dark & {
    color: #fff;
  }
`
const InputWrap = styled.div`
  position: relative;
  margin-right: 20px;
`
const RepayToken = styled.div`
  line-height: 42px;
  margin-right: 20px;

  .dark & {
    color: #fff;
  }
`
const StyledOperation = styled.div`
  display: flex;
  margin-top: 70px;
`
const StyledInput = styled.input`
  height: 100%;
  width: 270px;
  padding-left: 15px;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.operationPage.light.borderColor};

  .dark & {
    color: #fff;
    background: #091032;
    border: 1px solid ${(props) => props.theme.operationPage.dark.borderColor};
  }
`
const StyledButton = styled.button`
  width: 140px;
  height: 40px;
  background: #FF764B;
  color: #ffffff;
  border-radius: 4px;
  font-weight: 700;
  &.disabledBtn {
    background: #EBEDF2 !important;
    color: #9195A4 !important;
  }
`
const StyledImg = styled.img`
  width: 22px;
  z-index: 9999;
  cursor: pointer;
`
const Supply_item = styled.div`
  display:flex;
  justify-content: space-between;
  line-height: 50px;
  font-size: 14px;
  font-weight: 500;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  cursor: pointer;
  color: #242a47;
  border-bottom: 1px solid ${(props) => props.theme.operationPage.light.borderColor};

  .dark & {
    color: #fff;
    border-bottom: 1px solid ${(props) => props.theme.operationPage.dark.borderColor};

    &:hover{
      background: #091032;
    }
  }

  &:hover{
    background: #FFFAF2;
  }

  &:last-child{
    border-bottom: none;
  }
`
const Borrow_item = styled.div`
  display:flex;
  line-height: 50px;
  font-size: 14px;
  font-weight: 500;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  cursor: pointer;
  color: #242a47;
  border-bottom: 1px solid ${(props) => props.theme.operationPage.light.borderColor};

  .dark & {
    color: #fff;
    border-bottom: 1px solid ${(props) => props.theme.operationPage.dark.borderColor};

    &:hover{
      background: #091032;
    }
  }

  &:hover{
    background: #FFFAF2;
  }

  &:last-child{
    border-bottom: none;
  }

  span{
    flex: 1;

    &:last-child{
      flex: 0.8;
    }
  }
`
const Supply_title = styled.div`
  display:flex;
  justify-content: space-between;
  color: #9195a4;
  font-size: 14px;
  font-weight: 700;
  line-height: 40px;
  padding-left: 10px;
  padding-right: 10px;
  background: #fff;
  border-bottom: 1px solid ${(props) => props.theme.operationPage.light.borderColor};

  .dark & {
    background: #091032;
    border-bottom: 1px solid ${(props) => props.theme.operationPage.dark.borderColor};
  }
`
const Borrow_title = styled.div`
  display:flex;
  color: #9195a4;
  font-size: 14px;
  font-weight: 700;
  line-height: 40px;
  background: #fff;
  padding-left: 10px;
  padding-right: 10px;
  border-bottom: 1px solid ${(props) => props.theme.operationPage.light.borderColor};

  .dark & {
    background: #091032;
    border-bottom: 1px solid ${(props) => props.theme.operationPage.dark.borderColor};
  }

  span{
    flex: 1;

    &:last-child{
      flex: 0.8;
    }
  }
`
const BorrowList = styled.div`
  flex: 1;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.operationPage.light.borderColor};

  .dark & {
    border: 1px solid ${(props) => props.theme.operationPage.dark.borderColor};
  }
`
const SupplyList = styled.div`
  width: 340px;
  margin-right: 40px;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.operationPage.light.borderColor};

  .dark & {
    border: 1px solid ${(props) => props.theme.operationPage.dark.borderColor};
  }
`
const StyledWrap = styled.div`
  display:flex;
  align-items: baseline;
  margin-top: 16px;
`
const PriceTitleSub = styled.div`
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  width: 600px;
  @media (max-width: 1199px) {
    width: 90%;
    font-size: 12px;
    line-height: 16px;
  }
`
const AssetWrap = styled.div`
  height: 224px;
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 1199px) {
    height: auto;
    padding: 16px 0;
  }
`
const StyledTitle = styled.div`
  color: #FFF;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
  @media (max-width: 1199px) {
    font-size: 16px;
    line-height: 28px;
  }
`
const TabWrapInner = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  @media (max-width: 1199px) {
    min-width: 100%;
    /* flex-direction: column; */
    flex-wrap: wrap;
  }
`
const Crumb = styled.div`
  display:flex;
  justify-content:space-between;
  align-items:center;
  width:126px;
  height:32px;
  /* margin-top: 15px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.08); */
  padding-left: 8px;
  @media (max-width: 1199px) {
    height: 24px;
    margin-top: 3px;
  }
`
const RouterLink = styled(NavLink)`
`
const BackToList = styled.b`
  display:flex;
  align-items:center;
  font-size:16px;
  height:30px;
  line-height:30px;
  color: #fff;
  font-weight: 500;
  &:before {            
    content: '';            
    display: block;
    width: 8px;            
    height: 8px; 
    margin-right:5px;      
    border-right: 2px solid ${props => props.theme.colors.lightGrey};            
    border-top: 2px solid ${props => props.theme.colors.lightGrey};            
    -webkit-transform: rotate(-135deg); /*箭头方向可以自由切换角度*/            
    transform: rotate(-135deg);
}
`

export default Liquidate