"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.abi = void 0;
exports.abi = [
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "previousAdmin",
                type: "address",
            },
            {
                indexed: false,
                internalType: "address",
                name: "newAdmin",
                type: "address",
            },
        ],
        name: "AdminChanged",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "implementation",
                type: "address",
            },
        ],
        name: "Upgraded",
        type: "event",
    },
    {
        stateMutability: "payable",
        type: "fallback",
    },
    {
        inputs: [],
        name: "admin",
        outputs: [
            {
                internalType: "address",
                name: "admin_",
                type: "address",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "newAdmin",
                type: "address",
            },
        ],
        name: "changeAdmin",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "implementation",
        outputs: [
            {
                internalType: "address",
                name: "implementation_",
                type: "address",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "newImplementation",
                type: "address",
            },
        ],
        name: "upgradeTo",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "newImplementation",
                type: "address",
            },
            {
                internalType: "bytes",
                name: "data",
                type: "bytes",
            },
        ],
        name: "upgradeToAndCall",
        outputs: [],
        stateMutability: "payable",
        type: "function",
    },
    {
        stateMutability: "payable",
        type: "receive",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "previousOwner",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "newOwner",
                type: "address",
            },
        ],
        name: "NewOwner",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "oldPendingOwner",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "newPendingOwner",
                type: "address",
            },
        ],
        name: "NewPendingOwner",
        type: "event",
    },
    {
        inputs: [],
        name: "_acceptOwner",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_asset",
                type: "address",
            },
            {
                components: [
                    {
                        internalType: "uint256",
                        name: "minSingleLimit",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "midSingleLimit",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "minDailyLimit",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "midDailyLimit",
                        type: "uint256",
                    },
                ],
                internalType: "struct TimeLockStrategy.AssetLimitConfig",
                name: "_newLimitConfig",
                type: "tuple",
            },
        ],
        name: "_setAssetLimitConfig",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address payable",
                name: "newPendingOwner",
                type: "address",
            },
        ],
        name: "_setPendingOwner",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_asset",
                type: "address",
            },
            {
                internalType: "address",
                name: "_account",
                type: "address",
            },
            {
                components: [
                    {
                        internalType: "uint256",
                        name: "minSingleLimit",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "midSingleLimit",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "minDailyLimit",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "midDailyLimit",
                        type: "uint256",
                    },
                ],
                internalType: "struct TimeLockStrategy.AssetLimitConfig",
                name: "_newLimitConfig",
                type: "tuple",
            },
        ],
        name: "_setWhitelistExtraConfig",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        name: "assetData",
        outputs: [
            {
                internalType: "uint256",
                name: "currentDailyAmount",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "dailyStartTime",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        name: "assetLimitConfig",
        outputs: [
            {
                internalType: "uint256",
                name: "minSingleLimit",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "midSingleLimit",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "minDailyLimit",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "midDailyLimit",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_asset",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_amount",
                type: "uint256",
            },
            {
                internalType: "address",
                name: "_caller",
                type: "address",
            },
        ],
        name: "calculateTimeLockParams",
        outputs: [
            {
                internalType: "uint256",
                name: "_delaySeconds",
                type: "uint256",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "controller",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_asset",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_amount",
                type: "uint256",
            },
            {
                internalType: "address",
                name: "_caller",
                type: "address",
            },
        ],
        name: "getDelayDetails",
        outputs: [
            {
                internalType: "uint256",
                name: "_delaySeconds",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_currentTime",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_currentDailyAmount",
                type: "uint256",
            },
            {
                internalType: "bool",
                name: "_toUpdate",
                type: "bool",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_controller",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_minSingleWaitSeconds",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_midSingleWaitSeconds",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_maxSingleWaitSeconds",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_minDailyWaitSeconds",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_midDailyWaitSeconds",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_maxDailyWaitSeconds",
                type: "uint256",
            },
        ],
        name: "initialize",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "maxDailyWaitSeconds",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "maxSingleWaitSeconds",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "midDailyWaitSeconds",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "midSingleWaitSeconds",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "minDailyWaitSeconds",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "minSingleWaitSeconds",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "owner",
        outputs: [
            {
                internalType: "address payable",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "pendingOwner",
        outputs: [
            {
                internalType: "address payable",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        name: "whitelistExtra",
        outputs: [
            {
                internalType: "uint256",
                name: "minSingleLimit",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "midSingleLimit",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "minDailyLimit",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "midDailyLimit",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_logic",
                type: "address",
            },
            {
                internalType: "address",
                name: "admin_",
                type: "address",
            },
            {
                internalType: "bytes",
                name: "_data",
                type: "bytes",
            },
        ],
        stateMutability: "payable",
        type: "constructor",
    },
];
