import React, { useCallback, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core'
import moment from "moment";
import useLending from "hooks/useLending";
import { useQuery } from 'utils';
import stake_LP_Token from "lending-sdk/lib/clean_build/contracts/stake/IERC20.json"
import {
  _getEarned, _getstakedBalance, multicall_lsr, multicall_target
} from "lending-sdk/utils/index1"
import { fetchStake } from "./fetchStake"
import MarketDistribution from './components/MarketDistribution';
import BigNumber from 'bignumber.js';

// 获取本地token配置
const deployAssets = {
  stake: stake_LP_Token,
}
const Stake: React.FC = () => {
  const {
    connector,
    account,
    chainId,
    provider,
    ENSName,
    isActive,
    isActivating,
  } = useWeb3React();
  const lending = useLending();
  const [stake, setStake] = useState<any[]>([])
  const navigate = useNavigate()
  const [localConfig, setLocalConfig] = useState<any[]>([])
  const [_threshold,set_Threshold] = useState<string | undefined>(undefined)
  const [iUSXStaked,setiUSXStaked] = useState<BigNumber>(new BigNumber(0))
  let query = useQuery()
  const fetchApi = useCallback(async (_chainId:number,_account:string) => {
    const {
      stakeDate,
      localConfig_alltoken,
      threshold,
      liquidityList
    } = await fetchStake(_chainId,_account)
    set_Threshold(threshold)
    setLocalConfig(localConfig_alltoken)
    if (lending?.contracts && account && chainId) {
      const updateStakeDate = await Promise.all(
        stakeDate && stakeDate.map(async (item: any) => {
          let underlying_address,earned:any[],balance,allowance,harvestStatus,Locked,TotalStaked = '...',TotalSupply,LPShare = '...',TotalStakedValue='...',MyStaked='...',MystakedValue='...'
          switch (item.poolType) {
            case 'single':
              MyStaked = await _getstakedBalance(lending.contracts['stake'][`r${item.reveal_symbol}`], account)
              balance = await _getstakedBalance(lending.contracts['stake'][`${item.reveal_symbol}`], account)
              allowance = lending.contracts['stake'][`${item.reveal_symbol}`] && await lending.contracts['stake'][`${item.reveal_symbol}`]?.methods
              .allowance(account, item.address)
              .call();
              try {
                const multical_params = [
                  {
                    target:lending.contracts['stake'][`r${item.reveal_symbol}`]?.address,
                    call:['balanceOf(address)(uint256)',account],
                    key:'MyStaked'
                  },
                  {
                    target:lending.contracts['stake'][`${item.reveal_symbol}`]?.address,
                    call:['balanceOf(address)(uint256)',account],
                    key:'balance'
                  },
                  {
                    target:lending.contracts['stake'][`${item.reveal_symbol}`]?.address,
                    call:['allowance(address,address)(uint256)',account,item.address],
                    key:'allowance'
                  },
                  {
                    target:lending.contracts['stake'][`r${item.reveal_symbol}`]?.address,
                    call:['totalSupply()(uint256)'],
                    key:'TotalStaked'
                  },
                  {
                    target:lending.contracts['stake'][`${item.reveal_symbol}`]?.address,
                    call:['totalSupply()(uint256)'],
                    key:'TotalSupply'
                  }
                ]
  
                const res = await multicall_lsr(lending?.contracts?.web3,multicall_target[chainId], multical_params)
                if(!res) return;
                MyStaked = res.results.transformed.MyStaked;
                balance = res.results.transformed.balance;
                allowance = res.results.transformed.allowance;
                TotalStaked = res.results.transformed.TotalStaked;
                TotalSupply = res.results.transformed.TotalSupply;
                LPShare = new BigNumber(res.results.transformed.TotalStaked).multipliedBy(new BigNumber(10).pow(18)).div(new BigNumber(res.results.transformed.TotalSupply)).toFixed(0);
                TotalStakedValue = new BigNumber(res.results.transformed.TotalStaked).multipliedBy(item.Price).div(new BigNumber(10).pow(18)).toFixed(0).toString();
                MystakedValue = new BigNumber(res.results.transformed.MyStaked).multipliedBy(item.Price).div(new BigNumber(10).pow(18)).toFixed(0).toString();
                underlying_address = lending.contracts['stake'][`${item.reveal_symbol}`]?.address
              } catch (error) {
                MyStaked = '...'
                balance = undefined
                allowance = '0'
                TotalStaked = '...';
                TotalSupply = '...';
                LPShare = '...';
                TotalStakedValue = '...';
                MystakedValue = '...';
              }

              break;
            default:
              MyStaked = '...'
              balance = undefined
              allowance = '0'
              TotalStaked = '...';
              TotalSupply = '...';
              LPShare = '...';
              TotalStakedValue = '...';
              MystakedValue = '...';
              break;
          }
          const isApproved = (Number(allowance) === 0) ? false : true

          return {
            ...item,
            underlying_address,
            balance,
            MyStaked,
            TotalStaked,
            TotalStakedValue,
            LPShare,
            MystakedValue,
            // earned,
            // harvestStatus,
            isApproved,
            allowance,
            // Locked
          }
        })
      )
      // console.log("updateStakeDate",updateStakeDate)
      setStake(updateStakeDate)
      try {
        const multical_params = [
          {
            target:lending.contracts['liquidity'][`riUSX`]?.address,
            call:['balanceOf(address)(uint256)',account],
            key:'iUSXStaked'
          }
        ]
        const res = await multicall_lsr(lending?.contracts?.web3,multicall_target[chainId], multical_params)
        if(!res) return;
        const hasiUSX = liquidityList.filter(((item:any)=>item?.Address === lending.contracts['liquidity'][`riUSX`]?.address))[0]
        if(hasiUSX){
          const _iUSXStakedValue = new BigNumber(new BigNumber(res.results.transformed.iUSXStaked).multipliedBy(hasiUSX?.Price).div(new BigNumber(10).pow(18)).toFixed(0));
          setiUSXStaked(_iUSXStakedValue)
        }else{
          setiUSXStaked(new BigNumber(0))
        }
        
      } catch (error) {
        setiUSXStaked(new BigNumber(0))
      }
    } else {
      setStake(stakeDate)
    }
  }, [lending, account,chainId])


  useEffect(() => {
    if(chainId && account){
      const hasStakeLP = deployAssets["stake"]['networks'][`${chainId}`]
      if(hasStakeLP){
        fetchApi(chainId,account)
      }else{
        navigate("/lending")
      }
      const Timer = setInterval(() => {
        fetchApi(chainId,account)
      }, 1000*15)
      return () => {
        clearInterval(Timer)
      }
    }
    
    
  }, [chainId, account, lending])



  return (
    <>
      <MarketDistribution
        stake={stake}
        threshold={_threshold}
        localConfig_alltoken={localConfig}
        iUSXStaked={iUSXStaked}
      />
    </>
  )
}
export default Stake