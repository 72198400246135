import React from "react";
import styled from "styled-components";


const ApproveFirst = ({ children }: {
    children: React.ReactNode
  }) => {
    return (
        <StyledApproveFirst>
            {children}
        </StyledApproveFirst>
    );
}

const StyledApproveFirst = styled.div`
    margin-top: 20px;
    height: 152px;
    margin-bottom: 54px;
    font-size: 16px;
    font-weight: 400;
    color: ${(props) => props.theme.operationPage.grey};
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    text-align: center;
    padding: 0 60px;
`;

export default ApproveFirst

