/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-loop-func */
/* eslint-disable eqeqeq */
/* eslint-disable no-unreachable */
import BigNumber from "bignumber.js";
import BN from 'bn.js';
import { ethers } from "ethers";
import Web3 from "web3";
// import request from "request";
import { provider, TransactionReceipt } from "web3-core";
import { AbiItem } from "web3-utils";
import { useLocation } from "react-router-dom";

import ERC20ABI from "constants/abi/ERC20.json";

import { CoinbaseWallet } from '@web3-react/coinbase-wallet'
import { GnosisSafe } from '@web3-react/gnosis-safe'
import { MetaMask } from '@web3-react/metamask'
import { Network } from '@web3-react/network'
import type { Connector } from '@web3-react/types'

import { WalletConnect as WalletConnect } from '@web3-react/walletconnect'
import { WalletConnect as WalletConnectV2 } from '@web3-react/walletconnect-v2'

// images
import img_eth from "assets/Bridge/net-eth.svg"
import img_arbitrum from "assets/Bridge/net-arbitrum.svg"
import img_op from "assets/Bridge/net-op.svg"
import img_bsc from "assets/Bridge/net-bsc.svg"
import img_polygon from "assets/Bridge/Polygon.svg"
import img_Avalanche from "assets/Bridge/Avalanche.svg"
import img_kava from "assets/Bridge/KAVA.svg"
import img_EVMOS from "assets/Bridge/EVMOS.svg"
import img_Conflux from "assets/Bridge/net-conflux.svg"
import img_zkSyncEra from "assets/Bridge/zkSync.svg"
import img_Base from "assets/Bridge/Base.svg"

export const logoMap = {
  1: img_eth,
  42161: img_arbitrum,
  10: img_op,
  56: img_bsc,
  137: img_polygon,
  43114: img_Avalanche,
  2222: img_kava,
  1030: img_Conflux,
  324: img_zkSyncEra,
  8453: img_Base,
}

export const getName = (connector: Connector) => {
  if (connector instanceof MetaMask) return 'MetaMask'
  if (connector instanceof WalletConnectV2) return 'WalletConnect'
  if (connector instanceof WalletConnect) return 'WalletConnect V1'
  if (connector instanceof CoinbaseWallet) return 'Coinbase'
  if (connector instanceof Network) return 'Network'
  if (connector instanceof GnosisSafe) return 'Gnosis Safe'
  return 'Unknown'
}

export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

// export const waitTransaction = async (provider: provider, txHash: string) => {
//   const web3 = new Web3(provider);
//   let txReceipt: TransactionReceipt | null = null;
//   while (txReceipt === null) {
//     const r = await web3.eth.getTransactionReceipt(txHash);
//     txReceipt = r;
//     await sleep(2000);
//   }
//   return txReceipt.status;
// };

// export const approve = async (
//   userAddress: string,
//   spenderAddress: string,
//   tokenAddress: string,
//   provider: any,
//   onTxHash?: (txHash: string) => void
// ): Promise<boolean> => {
//   try {
//     const tokenContract = getERC20Contract(provider, tokenAddress);
//     return tokenContract.methods
//       .approve(spenderAddress, ethers.constants.MaxUint256)
//       .send(
//         { from: userAddress, gas: 80000 },
//         async (error: any, txHash: string) => {
//           if (error) {
//             console.log("ERC20 could not be approved", error);
//             onTxHash && onTxHash("");
//             return false;
//           }
//           if (onTxHash) {
//             onTxHash(txHash);
//           }
//           const status = await waitTransaction(provider, txHash);
//           if (!status) {
//             console.log("Approval transaction failed.");
//             return false;
//           }
//           return true;
//         }
//       );
//   } catch (e) {
//     console.log("error", e);
//     return false;
//   }
// };

export const getAllowance = async (
  userAddress: string,
  spenderAddress: string,
  tokenAddress: string,
  provider: provider
): Promise<string> => {
  // console.log(userAddress,spenderAddress,tokenAddress,provider)
  try {
    const tokenContract = getERC20Contract(provider, tokenAddress);
    const allowance: string = await tokenContract.methods
      .allowance(userAddress, spenderAddress)
      .call();
    // console.log("try: ", allowance)
    return allowance;
  } catch (e) {
    // console.log("catch: eeeee",e)
    return "0";
  }
};

export const getBalance = async (
  provider: provider,
  tokenAddress: string,
  userAddress: string
): Promise<string> => {
  const tokenContract = getERC20Contract(provider, tokenAddress);
  try {
    const balance: string = await tokenContract.methods
      .balanceOf(userAddress)
      .call();
    return balance;
  } catch (e) {
    return "0";
  }
};

export const getERC20Contract = (provider: provider, address: string) => {
  const web3 = new Web3(provider);
  const contract = new web3.eth.Contract(
    (ERC20ABI.abi as unknown) as AbiItem,
    address
  );
  return contract;
};

// export const bnToDec = (bn: BigNumber, decimals = 18, decimalPlace = 2) => {
//   let result:number;
//   const thisStr = bn
//     .dividedBy(new BigNumber(10).pow(new BigNumber(decimals)))
//     .toString();
//   // num.substring(0,s.indexOf(".")+3); alert(result); }
//   if(decimalPlace === -1){
//     result = parseFloat(thisStr)
//   }else{
//     result =
//     thisStr.indexOf(".") !== -1
//       ? parseFloat(
//           thisStr.substring(0, thisStr.indexOf(".") + decimalPlace + 1)
//         )
//       : parseFloat(thisStr);
//   }
//   return result;
// };

export const bn = (str: string) => new BN(str)

export const bnToDec = (bn: BigNumber, decimals = 18, decimalPlace = 2) => {
  let result: number;
  const thisStr = bn
    .dividedBy(new BigNumber(10).pow(new BigNumber(decimals)))
    .toString();
  // num.substring(0,s.indexOf(".")+3); alert(result); }
  if (decimalPlace === -1) {
    result = parseFloat(thisStr)
  } else {
    result =
      thisStr.indexOf(".") !== -1
        ? parseFloat(
          thisStr.substring(0, thisStr.indexOf(".") + decimalPlace + 1)
        )
        : parseFloat(thisStr);
  }
  return result;
};

export const decToBn = (dec: number, decimals = 18) => {
  return new BigNumber(dec).multipliedBy(
    new BigNumber(10).pow(new BigNumber(decimals))
  );
};

export const getFullDisplayBalance = (balance: BigNumber, decimals = 18) => {
  return balance.dividedBy(new BigNumber(10).pow(decimals)).toFixed();
};

export const getNearestBlock = (from: Array<any>, target: number) => {
  return from.reduce(function (prev: any, curr: any) {
    return Math.abs(curr - target) < Math.abs(prev - target) ? curr : prev;
  });
};

export const getAMPM = (date: any) => {
  const hours = date.getHours();
  const ampm = hours >= 12 ? "PM" : "AM";
  return ampm;
};

export const getTimestampDate = (obj: { ts: number; ap?: boolean }) => {
  const d = new Date(obj.ts * 1000);
  const s = ".";
  const day = d.getDate();
  const month = d.getMonth() + 1;
  const year =
    d.getFullYear().toString().substring(0, 2) +
    (obj.ap ? " " + getAMPM(d) : "");
  return (
    (day < 9 ? "0" + day : day) +
    s +
    (month <= 9 ? "0" + month : month) +
    s +
    year
  );
};

export const format_bn = (
  numStr: string,
  decimals: number,
  decimalPlace = decimals
) => {
  if (!numStr) {
    return Number(0).toFixed(decimalPlace)
  }
  numStr = numStr.toLocaleString().replace(/,/g, "");
  // decimals = decimals.toString();

  // var str = (10 ** decimals).toLocaleString().replace(/,/g, '').slice(1);
  var str = Number(`1e+${decimals}`)
    .toLocaleString()
    .replace(/,/g, "")
    .slice(1);

  var res = (numStr.length > decimals
    ? numStr.slice(0, numStr.length - decimals) +
    "." +
    numStr.slice(numStr.length - decimals)
    : "0." + str.slice(0, str.length - numStr.length) + numStr
  ).replace(/(0+)$/g, "");

  res = res.slice(-1) === "." ? res + "00" : res;

  if (decimalPlace === 0) return res.slice(0, res.indexOf("."));

  var length = res.indexOf(".") + 1 + decimalPlace;
  return res.slice(0, length >= res.length ? res.length : length);
  // return res.slice(-1) == '.' ? res + '00' : res;
};

export const format_bn__new = (stringNumber: string, decimals: number, decimalShow = decimals) => {
  if (!stringNumber) {
    return '0.00'
  }

  let resNumber = '0.00'
  let strZero = Array(Number(decimals)).fill(0).join('')

  resNumber = stringNumber.length > decimals
    ? stringNumber.slice(0, stringNumber.length - decimals) + "." + stringNumber.slice(stringNumber.length - decimals)
    : "0." + strZero.slice(0, strZero.length - stringNumber.length) + stringNumber

  resNumber = resNumber.slice(-1) === "." ? resNumber + "00" : resNumber

  if (decimalShow === 0) return resNumber.slice(0, resNumber.indexOf("."))

  return resNumber.slice(0, resNumber.indexOf(".") + decimalShow + 1)

  // var length = res.indexOf(".") + 1 + decimalPlace; 1.000000
  // return res.slice(0, length >= res.length ? res.length : length);

};


// 正则 formate number  不进位
export const formateNormalNumber = (num: any, precision: number = 4) => {
  try {
    // 保留俩位小数
    const re = /([0-9]+\.[0-9]{2})[0-9]*/
    num = (num?.toString()).replace(re, "$1")
    const xsd = num.split('.')
    if (xsd.length == 1) {
      num = num + '.00'
    } else if (xsd.length > 1) {
      if (xsd[1].length < 2) {
        num = num + '0'
      }
    }
    // const str = number?.toString()
    switch (precision) {
      case 2:
        return num.replace(/^(.*\..{2}).*$/, "$1");
        break;
      case 4:
        return num.replace(/^(.*\..{4}).*$/, "$1");
        break;
      case 6:
        return num.replace(/^(.*\..{6}).*$/, "$1");
        break;
      default:
        return num.replace(/^(.*\..{4}).*$/, "$1");
        break;
    }
  } catch (error) {
    console.log(error)
  }
}
// 显示千分位 不进位
export const formatNumber = (num: any, point: number) => {
  try {
    // 保留俩位小数
    let re
    switch (point) {
      case 2:
        re = /([0-9]+\.[0-9]{2})[0-9]*/
        break;
      case 4:
        re = /([0-9]+\.[0-9]{4})[0-9]*/
        break;
      case 6:
        re = /([0-9]+\.[0-9]{6})[0-9]*/
        break;
      default:
        re = /([0-9]+\.[0-9]{2})[0-9]*/
        break;
    }
    // const re = /([0-9]+\.[0-9]{2})[0-9]*/ 
    num = (num?.toString()).replace(re, "$1")
    // console.log(num)
    const xsd = num.split('.')
    if (xsd.length == 1) {
      num = num + '.'.padEnd(point + 1, '0')
    } else if (xsd.length > 1) {
      if (xsd[1].length < 2) {
        num = num + '0'
      } else if (xsd[1].length > point) {
        num = num.toFixed(point)
      }
    }
    // console.log(num)
    let reg = num.indexOf('.') > -1 ? /(\d)(?=(\d{3})+\.)/g : /(\d)(?=(\d{3})+$)/g
    // return num.replace(reg, '$1,').replace(/(?<=\.\d{0}).*$/, '')
    switch (point) {
      case 2:
        return num.replace(reg, '$1,').replace(/(?<=\.\d{2}).*$/, '');
        break;
      case 4:
        return num.replace(reg, '$1,').replace(/(?<=\.\d{4}).*$/, '');
        break;
      case 6:
        return num.replace(reg, '$1,').replace(/(?<=\.\d{6}).*$/, '');
        break;
      case 8:
        return num.replace(reg, '$1,').replace(/(?<=\.\d{8}).*$/, '');
        break;
      default:
        return num.replace(reg, '$1,').replace(/(?<=\.\d{2}).*$/, '');
        break;
    }
  } catch (error) {
    console.log(error)
  }

}

export const _bnToDec = (bn: BigNumber, decimals = 18, decimalPlace = 2) => {
  const thisStr = bn
    .dividedBy(new BigNumber(10).pow(new BigNumber(decimals)))
    .toString();
  return Number(thisStr);
};


/**
 * @description: 不进位 保留小数位，支持 abbr 简写 和 percent百分数
 * @param {number} number 处理的数值
 * @param {*} minPrecision 最小位数
 * @param {*} maxPrecision 最多位数
 * @param {*} type 默认为 千分位格式化 || type = 'abbr' 为简写表示 || type= 'percent' 为百分比表示
 * @return {*}
 */
export const _formatNumberNotCarry = (number: number, type: string = '', minPrecision: number = 2, maxPrecision: number = 2): string => {
  const options = {
    minimumFractionDigits: minPrecision,
    maximumFractionDigits: maxPrecision,
  }

  if (type === 'abbr') {
    if (number === 0) {
      // return number.toLocaleString(undefined, options)
      return '0.00'
    }
    const SI_SYMBOLS = ["", "K", "M", "G", "T", "P", "E"];
    const tier = Math.floor(Math.log10(Math.abs(number)) / 3);
    let suffix = SI_SYMBOLS[tier];
    if (tier === -1) {
      suffix = ""
    }
    const scale = 10 ** ((tier === -1 ? 0 : tier) * 3);
    const scaled = number / scale;
    // return scaled.toLocaleString(undefined, options).slice(0, -1) + suffix;
    return formatNumber(scaled, minPrecision) + suffix
  }
  if (type === 'percent') {
    // if (Math.abs(number) < 0.0001) {
    //   return '0.00%'
    // }
    if (Math.abs(number) > 9999.99) {
      return '>999,999%'
      // if (number > 0) {
      //   return '> 999,999%'
      // } else {
      //   return '< -999,999%'
      // }
    }
    // return number.toLocaleString('zh', { ...options, style: 'percent' }).slice(0, -2) + '%'
    return formatNumber(number * 10000 / 100, minPrecision) + '%'
  }
  // return number.toLocaleString(undefined, options).slice(0, -1)
  // console.log(number,minPrecision,maxPrecision)
  if (Number(number) === 0) {
    return '0.00'
  }
  return formatNumber(number, minPrecision)
}
export const formatNumberNotCarry = (number: number, minPrecision: number = 3, maxPrecision: number = 3, type: string = ''): string => {
  const options = {
    minimumFractionDigits: minPrecision,
    maximumFractionDigits: maxPrecision,
  }
  if (type === 'abbr') {
    if (number === 0) {
      return number.toLocaleString(undefined, options).slice(0, -1)
    }
    const SI_SYMBOLS = ["", "k", "m", "g", "t", "p", "e"];
    const tier = Math.floor(Math.log10(Math.abs(number)) / 3);
    const suffix = SI_SYMBOLS[tier];
    const scale = 10 ** (tier * 3);
    const scaled = number / scale;
    return scaled.toLocaleString(undefined, options).slice(0, -1) + suffix;
  }
  if (type === 'percent') {
    if (Math.abs(number) < 0.0001) {
      if (number > 0) {
        return '< 0.01%'
      } else {
        return '> -0.01%'
      }
    }
    if (Math.abs(number) > 9999.99) {
      if (number > 0) {
        return '> 999,999%'
      } else {
        return '< -999,999%'
      }
    }
    return number.toLocaleString('zh', { ...options, style: 'percent' }).slice(0, -2) + '%'
  }
  return number.toLocaleString(undefined, options).slice(0, -1)
}

export const reg_test = (val: string) => {
  val = val.trim();
  var reg_ZH_CN = new RegExp("[\\u4E00-\\u9FFF]+", "g");
  var reg_ABC = /[a-z]/g;

  if (reg_ZH_CN.test(val)) {
    return console.log("不可以输入中文");
  }
  if (reg_ABC.test(val)) {
    return console.log("English ABC prohibited");
  }

  // var reg = /^\d+$|^\d*\.\d+$/g
  // console.log(reg.test(val))
};

// 根据 Name字段 去重json 对象
export const FilterByName = (data: any, Name: any) => {
  var dest = [];
  for (var i = 0; i < data.length; i++) {
    var ai = data[i];
    if (i == 0) {
      dest.push(ai);
    } else {
      var filterData = dest.filter(function (e) {
        return e[Name] == ai[Name];
      });
      if (filterData.length == 0) {
        dest.push(ai);
      }
    }
  }
  return dest;
};

//将 obj 类型转化为jsonArr类型
export const objToJsonArr = (obj: any) => {
  let arr: any[] = [];
  for (var i in obj) {
    var keys = obj?.[0];
    var values = obj?.[1];
    var decimals = obj?.[2];
  }
  keys &&
    keys.forEach((item: any, index: number) => {
      arr.push({
        address: item,
        balance: values[index],
        decimals: decimals[index],
      });
    });
  return arr;
};
// 封装Api请求函数
// export const requestAPI: <T>(url: string) => Promise<T> = (url) => {
//   return new Promise((resolve, reject) => {
//     request(
//       {
//         url,
//         json: true,
//         timeout: 12 * 1000
//       },
//       (error, response, body) => {
//         // console.log(response,error)
//         if (error) {
//           console.log(error)
//           reject(error);
//         } else {
//           if (response.statusCode === 200) {
//             resolve(body);
//           } else {
//             reject(error)
//           }
//         }
//       }
//     );
//   });
// };
// 截断数值，不进行四舍五入
export const formate_round_up = (val: string) => {
  const Reg = new RegExp(".*[a-zA-Z]");
  const result = Reg.test(val)
    ? val.substring(0, val.indexOf(".") + 3) + val.charAt(val.length - 1)
    : val.substring(0, val.indexOf(".") + 3);
  return result;
};

// 格式化数字千分位
export const format_num_to_K = (str_num: string) => {
  var part_a = str_num.split(".")[0];
  var part_b = str_num.split(".")[1];

  var reg = /\d{1,3}(?=(\d{3})+$)/g;
  part_a = (part_a + "").replace(reg, "$&,");
  //处理 整数的判断返回
  return (part_b ? part_a + "." + part_b : part_a);
};
// 读取 Contracts 配置文件 获取address 数组 ( 有chainId传入，则只筛选 chainId字段数组 
export const getDeployAddressMap = (
  fileJson: any,
  name: string = "address",
  chainId?: number | null
) => {
  let that = [];
  if (chainId) {
    for (const keyChainId in fileJson["networks"][chainId]) {
      const address = fileJson["networks"][chainId][keyChainId][name];
      that.push(address);
    }
  } else {
    for (const key in fileJson["networks"]) {
      for (const keyChainId in fileJson["networks"][key]) {
        const address = fileJson["networks"][key][keyChainId][name];
        that.push(address);
      }
    }
  }
  return that;
};

// reverse addressMap 反转配置文件，转化为 address 作为key
export const reverseAddressMap = (
  fileJson: {
    networks: { [x: string]: { [x: string]: { [x: string]: any } } };
  },
  networkId: string | number
) => {
  let that = {};
  for (const key in fileJson.networks[networkId]) {
    that[fileJson.networks[networkId][key]["address"]] = {
      symbol: fileJson.networks[networkId][key]["symbol"],
      assetsType: fileJson.networks[networkId][key]["assetsType"],
      decimals: fileJson.networks[networkId][key]["decimals"],
      hasLocked: fileJson.networks[networkId][key]["hasLocked"],
    };
  }
  return that;
};

// reverse addressMap 反转配置文件，转化为 address 作为key
export const reverseAllChainAddressMap = (
  fileJson: {
    networks: { [x: string]: { [x: string]: { [x: string]: any } } };
  }
) => {
  let that = {};
  for (const network in fileJson.networks) {
    for (const key in fileJson.networks[network]) {
      that[fileJson.networks[network][key]["address"]] = {
        LPAddress: fileJson.networks[network][key]?.["LPAddress"],
        symbol: fileJson.networks[network][key]["symbol"],
        poolSymbol: fileJson.networks[network][key]["poolSymbol"],
        assetsType: fileJson.networks[network][key]["assetsType"],
        poolType: fileJson.networks[network][key]?.["poolType"],
        poolType2: fileJson.networks[network][key]?.["poolType2"],
        pid: fileJson.networks[network][key]?.["pid"],
        heat: fileJson.networks[network][key]?.["heat"],
        decimals: fileJson.networks[network][key]["decimals"],
        hasLocked: fileJson.networks[network][key]["hasLocked"],
        Link: fileJson.networks[network][key]["Link"],
        LinkTo: fileJson.networks[network][key]?.["LinkTo"]
      };
    }
  }
  return that;
};
// reverse addressMap 反转配置文件，转化为 address 作为key
export const reverseCurrentChainAddressMap = (
  fileJson: {
    networks: { [x: string]: { [x: string]: { [x: string]: any } } };
  },
  chainId: number
) => {
  let that = {};
  for (const network in fileJson.networks) {
    if (network === chainId.toString()) {
      for (const key in fileJson.networks[network]) {
        that[fileJson.networks[network][key]["address"]] = {
          LPAddress: fileJson.networks[network][key]?.["LPAddress"],
          symbol: fileJson.networks[network][key]["symbol"],
          poolSymbol: fileJson.networks[network][key]["poolSymbol"],
          assetsType: fileJson.networks[network][key]["assetsType"],
          poolType: fileJson.networks[network][key]?.["poolType"],
          poolType2: fileJson.networks[network][key]?.["poolType2"],
          pid: fileJson.networks[network][key]?.["pid"],
          heat: fileJson.networks[network][key]?.["heat"],
          decimals: fileJson.networks[network][key]["decimals"],
          hasLocked: fileJson.networks[network][key]["hasLocked"],
          Link: fileJson.networks[network][key]["Link"],
          LinkTo: fileJson.networks[network][key]?.["LinkTo"],
          TradeLink: fileJson.networks[network][key]?.["TradeLink"]
        };
      }
    }

  }
  return that;
};

export const reverseBLPCurrentChainAddressMap = (
  fileJson: {
    networks: { [x: string]: { [x: string]: { [x: string]: any } } };
  },
  chainId: number
) => {
  let that: {
    LPAddress: string,
    symbol: string,
    poolSymbol: string,
    assetsType: string,
    poolType: string,
    poolType2: string,
    pid: string,
    heat: string,
    decimals: string,
    hasLocked: string,
    Link: string,
    LinkTo: string,
    TradeLink: string
  } = {
    LPAddress: '',
    symbol: '',
    poolSymbol: '',
    assetsType: '',
    poolType: '',
    poolType2: '',
    pid: '',
    heat: '',
    decimals: '',
    hasLocked: '',
    Link: '',
    LinkTo: '',
    TradeLink: ''
  };
  for (const network in fileJson.networks) {
    if (network === chainId.toString()) {
      for (const key in fileJson.networks[network]) {
        that[fileJson.networks[network][key]["address"]] = {
          LPAddress: fileJson.networks[network][key]?.["LPAddress"],
          symbol: fileJson.networks[network][key]["symbol"],
          poolSymbol: fileJson.networks[network][key]["poolSymbol"],
          assetsType: fileJson.networks[network][key]["assetsType"],
          poolType: fileJson.networks[network][key]?.["poolType"],
          poolType2: fileJson.networks[network][key]?.["poolType2"],
          pid: fileJson.networks[network][key]?.["pid"],
          heat: fileJson.networks[network][key]?.["heat"],
          decimals: fileJson.networks[network][key]["decimals"],
          hasLocked: fileJson.networks[network][key]["hasLocked"],
          Link: fileJson.networks[network][key]["Link"],
          LinkTo: fileJson.networks[network][key]?.["LinkTo"],
          TradeLink: fileJson.networks[network][key]?.["TradeLink"]
        };
      }
    }

  }
  return that;
};
// 将clean_build 里 资产配置 转化为JSON数组格式,并筛选过滤
export const JSON_objToArray = (obj: any, field?: string, bool?: boolean) => {
  let arr = Object.values(obj)
  if (field) {
    arr = arr.filter((item: any) => !(item[`${field}`]) === bool)
  }
  return arr
}
// 获取配置文件中 underlying_addressMap ，
export const underlyingAddressMap = (
  fileJson: {
    networks: { [x: string]: { [x: string]: { [x: string]: any } } };
  },
  networkId: string | number
) => {
  let that = {};
  for (const key in fileJson.networks[networkId]) {
    that[key] = {
      address: fileJson.networks[networkId][key]["address"]
    };
  }
  return that;
};

// 格式化 时间戳
export const formatTimeStamp = (date: Date, fmt: string) => {
  if (!date) return;
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  }
  let o = {
    "M+": addZero(date.getMonth() + 1),
    "d+": addZero(date.getDate()),
    "h+": date.getHours(),
    "m+": date.getMinutes(),
    "s+": date.getSeconds(),
  };
  for (let k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      let str = o[k] + "";
      fmt = fmt.replace(RegExp.$1, str);
    }
  }
  function addZero(num: string | number) {
    return (Number(num) >= 10) ? num : "0" + num;
  }
  return fmt;
};

export const deconstruction_wallet_source = () => {
  const wallet_list = [
    "imToken",
    "TokenPocket",
    "Maths",
    "Bitpie",
    "Huobi",
    "Trust",
    "BitKeep",
    "ONTO",
    "MYKEY",
    "F2Pool",
    "Poolin",
    "Hoo",
    "SparkPool",
    "Amber",
    "Babel",
    "MatrixPort",
    "Cobo",
    "Legend",
    "Cyberx",
    "Satafi",
    "Hashquark",
    "Hotbit",
  ];
  let cur_url = window.location.href;
  let arr_url = cur_url.split("/");
  let source;

  for (let i = 0; i < arr_url.length; i++) {
    if (arr_url[i].toLowerCase().includes("utm_source=")) {
      for (let j = 0; j < wallet_list.length; j++) {
        if (arr_url[i].toLowerCase().includes(wallet_list[j].toLowerCase())) {
          return (source = wallet_list[j]);
        }
      }
    }
  }

  return (source = "web");
};

// 获取url后边 指定参数的值
export const GetUrlParam = (paraName: string) => {
  var url = document.location.toString();
  var arrObj = url.split("?");

  if (arrObj.length > 1) {
    var arrPara = arrObj[1].split("&");
    var arr;

    for (var i = 0; i < arrPara.length; i++) {
      arr = arrPara[i].split("=");

      if (arr != null && arr[0] == paraName) {
        return arr[1];
      }
    }
    return "";
  } else {
    return "";
  }
};
// js 点击复制到剪贴板函数
export const copyToClipboard = (s: string) => {
  // callback(true)
  if (window.clipboardData) {
    window.clipboardData.setData('text', s);
  } else {
    (function (s) {
      document.oncopy = (e: any) => {
        e.clipboardData.setData('text', s);
        e.preventDefault();
        document.oncopy = null;
      }
    })(s);
    document.execCommand('Copy');
  }
  // setTimeout(() => {
  //   callback(false)
  // }, 500)
}
//获取 react-router URLSearchParams
export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};
//Page.tsx页面动态替换 URL参数
export const setUrlParam = (
  location: string,
  name: string[],
  value: string[],
  route: string
) => {
  var url = location;
  var splitIndex = url.indexOf("?") + 1;
  var paramStr = url.substr(splitIndex, url.length);

  // var newUrl = url.substr(0, splitIndex);
  var newUrl = route + "?";

  // - if exist , replace
  var arr = paramStr.split("&");
  for (var i = 0; i < arr.length; i++) {
    var kv = arr[i].split("=");
    if (name.includes(kv[0])) {
      newUrl += kv[0] + "=" + value[name.indexOf(kv[0])];
    } else {
      if (kv[1] != undefined) {
        newUrl += kv[0] + "=" + kv[1];
      }
    }
    if (i != arr.length - 1) {
      newUrl += "&";
    }
  }
  return newUrl;
};
//获取渠道链接并跳转初始化路由参数
export const setUrlParamWith_utm_source = (
  location: string,
  name: string[],
  value: string[],
  route: string
) => {
  // var newUrl = url.substr(0, splitIndex);
  var newUrl = route + "?";
  for (let i = 0; i < name.length; i++) {
    newUrl += name[i] + "=" + value[i];
    if (i != name.length - 1) {
      newUrl += "&";
    }
  }
  return newUrl;
};
// 验证图片链接是否有效
export const checkImgExists = (imgurl: string) => {
  return new Promise(function (resolve, reject) {
    var ImgObj = new Image()
    ImgObj.src = imgurl
    ImgObj.onload = function (res) {
      resolve(res)
    }
    ImgObj.onerror = function (err) {
      reject(err)
    }
  })
}
export const postDataToServer = (
  source_wallet: string,
  action: string,
  account: string,
  networkName: string,
  token: string,
  decimal: string,
  price: string,
  value: string
) => {
  let url = "https://analytics.dforce.network/update";
  let data = {
    sources: source_wallet,
    operation: action,
    platforms: "Lending",
    address: account,
    network: networkName,
    token: token,
    decimal: decimal,
    price: price,
    value: value
  };
  console.log(url, data);
  fetch(url, {
    method: "POST",
    mode: "cors",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((res) => {
      console.log(res);
    })
    .catch((e) => {
      console.log(e);
    });
};
export const isObject = (obj: any) => {
  return (Object.prototype.toString.call(obj) === "[object Object]")
}

export const network_gasPrice = {
  '1': 'ethereum',
  '56': 'binance-smart-chain',
  '42161': 'arbitrum'
}
export interface gasPriceDate {
  "standard": number,
  "fast": number,
  "instant": number
}
export const _getGasPrice = async (chain_id: number = 1) => {
  try {
    const url = `https://api.zapper.fi/v1/gas-price?api_key=96e0cc51-a62e-42ca-acee-910ea7d2a241&network=${network_gasPrice[chain_id]}&eip1559=false`
    // return await requestAPI<gasPriceDate>(url);
    const response = await fetch(url)
    const res = await response.json();
    return res

  } catch (e) {
    console.log(e)
  }
}


// swap get gas-price
export const network_type = {
  '1': 'mainnet',
  '137': 'polygon',
}
export const FetchGasPrice = async (chain_id: number, lendingSDK: any) => {
  try {
    if (chain_id === 1 || chain_id === 137) {
      const url = `https://blocknative-api.herokuapp.com/data?chain=${network_type[chain_id]}`
      // const gas_price: any = await requestAPI(url)
      const response = await fetch(url)
      const gas_price = await response.json();

      // console.log('get price:', gas_price.estimatedPrices)
      const gasArr: any = [
        ((gas_price.estimatedPrices[2].price) * (1e9)).toFixed(0),
        ((gas_price.estimatedPrices[1].price) * (1e9)).toFixed(0),
        ((gas_price.estimatedPrices[0].price) * (1e9)).toFixed(0),
      ]
      return gasArr
    } else {
      const gas_price = await lendingSDK.web3.eth.getGasPrice()
      // console.log('get price:', gas_price)
      const gasArr: any = [
        (gas_price * 1).toFixed(0),
        (gas_price * 1.2).toFixed(0),
        (gas_price * 1.4).toFixed(0)
      ]
      return gasArr
    }
  } catch (e) {
    // console.log('FetchGasPrice err: ', e)
  }
}


// Bridge tools
export async function getMaxSubmissionPrice(
  l2: any,
  calldataOrCalldataLength: any
) {
  const calldataLength =
    typeof calldataOrCalldataLength === 'string'
      ? calldataOrCalldataLength.length
      : calldataOrCalldataLength
  // console.log(calldataLength)
  const submissionPrice = await getArbitrumCoreContracts(l2).arbRetryableTx.methods.getSubmissionPrice(calldataLength).call()
  // console.log("submissionPrice", submissionPrice)
  // const maxSubmissionPrice = (submissionPrice[0]).mul(4)
  const maxSubmissionPrice = l2.utils.toBN(submissionPrice[0]).mul(l2.utils.toBN(4))
  return maxSubmissionPrice
}

export const arbitrumL2CoreContracts = {
  arbRetryableTx: '0x000000000000000000000000000000000000006E',
  nodeInterface: '0x00000000000000000000000000000000000000C8',
}

export function getArbitrumCoreContracts(l2: any) {
  const abi__ArbRetryableTx = [
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "calldataSize",
          "type": "uint256"
        }
      ],
      "name": "getSubmissionPrice",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    }
  ]
  const abi__NodeInterface = [
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "sender",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "deposit",
          "type": "uint256"
        },
        {
          "internalType": "address",
          "name": "destAddr",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "l2CallValue",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "maxSubmissionCost",
          "type": "uint256"
        },
        {
          "internalType": "address",
          "name": "excessFeeRefundAddress",
          "type": "address"
        },
        {
          "internalType": "address",
          "name": "callValueRefundAddress",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "maxGas",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "gasPriceBid",
          "type": "uint256"
        },
        {
          "internalType": "bytes",
          "name": "data",
          "type": "bytes"
        }
      ],
      "name": "estimateRetryableTicket",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "pure",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "batchNum",
          "type": "uint256"
        },
        {
          "internalType": "uint64",
          "name": "index",
          "type": "uint64"
        }
      ],
      "name": "lookupMessageBatchProof",
      "outputs": [
        {
          "internalType": "bytes32[]",
          "name": "proof",
          "type": "bytes32[]"
        },
        {
          "internalType": "uint256",
          "name": "path",
          "type": "uint256"
        },
        {
          "internalType": "address",
          "name": "l2Sender",
          "type": "address"
        },
        {
          "internalType": "address",
          "name": "l1Dest",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "l2Block",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "l1Block",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "timestamp",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "amount",
          "type": "uint256"
        },
        {
          "internalType": "bytes",
          "name": "calldataForL1",
          "type": "bytes"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    }
  ]
  // return {
  //   arbRetryableTx: new ethers.Contract(
  //     arbitrumL2CoreContracts.arbRetryableTx,
  //     // require('./abis/ArbRetryableTx.json').abi,
  //     abi__ArbRetryableTx,
  //     l2,
  //   ),
  //   nodeInterface: new ethers.Contract(
  //     arbitrumL2CoreContracts.nodeInterface,
  //     // require('./abis/NodeInterface.json').abi,
  //     abi__NodeInterface,
  //     l2,
  //   ),
  // }
  return {
    arbRetryableTx: new l2.eth.Contract(
      abi__ArbRetryableTx,
      arbitrumL2CoreContracts.arbRetryableTx
    ),
    nodeInterface: new l2.eth.Contract(
      abi__NodeInterface,
      arbitrumL2CoreContracts.nodeInterface
    ),
  }
}

export async function getMaxGas(
  l2: any,
  sender: any,
  destination: any,
  refundDestination: any,
  maxSubmissionPrice: any,
  gasPriceBid: any,
  calldata: any,
) {
  const web3 = new Web3()
  const estimatedGas = await getArbitrumCoreContracts(l2).nodeInterface.methods.estimateRetryableTicket(
    sender,
    web3.utils.toWei('0.05', 'ether'),
    destination,
    0,
    maxSubmissionPrice,
    refundDestination,
    refundDestination,
    0,
    gasPriceBid,
    calldata,
  ).call()
  // const maxGas = estimatedGas.mul(4)
  const maxGas = l2.utils.toBN(estimatedGas[0]).mul(l2.utils.toBN(4))

  return maxGas
}




interface WindowChain {
  ethereum?: {
    isMetaMask?: true
    request?: (...args: any[]) => void
  }
}
// export const setupNetwork = async () => {
//   const provider = (<WindowChain>window).ethereum
//   if (provider && provider.request) {
//     // const chainId = parseInt(process.env.REACT_APP_CHAIN_ID, 10)
//     try {
//       await provider.request({
//         method: 'wallet_addEthereumChain',
//         params: [
//           {
//             chainId: `0x${(56).toString(16)}`,
//             chainName: 'Binance Smart Chain Mainnet',
//             nativeCurrency: {
//               name: 'BNB',
//               symbol: 'bnb',
//               decimals: 18,
//             },
//             rpcUrls: ['https://bscscan.com/'],
//             blockExplorerUrls: ['https://bscscan.com/'],
//           },
//         ],
//       })
//       return true
//     } catch (error) {
//       console.error(error)
//       return false
//     }
//   } else {
//     console.error("Can't setup the BSC network on metamask because window.ethereum is undefined")
//     return false
//   }
// }
export const listConfigs = [
  {
    isTest: true,
    chainId: 1,
    netName: 'Ethereum',
    imgSrc: img_eth,
  },
  {
    isTest: true,
    chainId: 42161,
    netName: 'Arbitrum',
    imgSrc: img_arbitrum,
    chainName: 'Arbitrum',
    rpcUrls: ['https://arb1.arbitrum.io/rpc'],
    blockExplorerUrls: ['https://arbiscan.io'],
    name: 'ETH',
    symbol: 'ETH'
  },
  {
    isTest: true,
    chainId: 10,
    netName: 'Optimism',
    imgSrc: img_op,
    chainName: 'Optimism',
    rpcUrls: ['https://mainnet.optimism.io/'],
    blockExplorerUrls: ['https://optimistic.etherscan.io/'],
    name: '',
    symbol: ''
  },
  {
    isTest: true,
    chainId: 56,
    netName: 'BSC',
    imgSrc: img_bsc,
    chainName: 'BSC',
    rpcUrls: ['https://bsc-dataseed.binance.org/'],
    blockExplorerUrls: ['https://bscscan.com'],
    name: 'BNB',
    symbol: 'BNB'
  },
  {
    isTest: true,
    chainId: 97,
    netName: 'BSC TestNet',
    imgSrc: img_bsc,
    chainName: 'BSC TestNet',
    rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
    blockExplorerUrls: ['https://testnet.bscscan.com'],
    name: 'BNB',
    symbol: 'BNB'
  },
  {
    isTest: true,
    chainId: 137,
    netName: 'Polygon',
    imgSrc: img_polygon,
    chainName: 'Polygon',
    rpcUrls: ['https://polygon-rpc.com'],
    blockExplorerUrls: ['https://polygonscan.com/'],
    name: 'MATIC',
    symbol: 'MATIC'
  },
  {
    isTest: true,
    chainId: 43114,
    netName: 'Avalanche',
    imgSrc: img_Avalanche,
    chainName: 'Avalanche',
    rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
    blockExplorerUrls: ['https://api.avax.network/ext/bc/C/rpc'],
    name: 'AVAX',
    symbol: 'AVAX'
  },
  {
    isTest: true,
    chainId: 2221,
    netName: 'KavaTestnet',
    imgSrc: img_kava,
    chainName: 'KavaTestnet',
    rpcUrls: ['https://evm.evm-alpha.kava.io'],
    blockExplorerUrls: ['https://explorer.evm-alpha.kava.io'],
    name: 'KavaTestnet',
    symbol: 'TKAVA'
  },
  // {
  //   isTest: false,
  //   chainId: 2222,
  //   netName: 'Kava',
  //   imgSrc: img_kava,
  //   chainName: 'Kava',
  //   rpcUrls: ['https://evm.kava.io'],
  //   blockExplorerUrls: ['https://explorer.kava.io/'],
  //   name: 'Kava',
  //   symbol: 'KAVA'
  // },
  {
    isTest: true,
    chainId: 71,
    netName: 'ConfluxTest',
    imgSrc: img_Conflux,
    chainName: 'ConfluxTest',
    rpcUrls: ['https://evmtestnet.confluxrpc.com'],
    blockExplorerUrls: ['https://evmtestnet.confluxscan.net/'],
    name: 'ConfluxTest',
    symbol: 'CFX'
  },
  {
    isTest: true,
    chainId: 1030,
    netName: 'Conflux eSpace',
    imgSrc: img_Conflux,
    chainName: 'ConfluxeSpace',
    rpcUrls: ['https://evm.confluxrpc.com'],
    blockExplorerUrls: ['https://evm.confluxscan.net/'],
    name: 'ConfluxeSpace',
    symbol: 'CFX'
  },
  {
    isTest: true,
    chainId: 324,
    netName: 'zkSync Era',
    imgSrc: img_zkSyncEra,
    chainName: 'zkSyncEra',
    rpcUrls: ['https://mainnet.era.zksync.io'],
    blockExplorerUrls: ['https://explorer.zksync.io/'],
    name: 'zkSyncEra',
    symbol: 'ETH'
  },
  {
    isTest: true,
    chainId: 8453,
    netName: 'Base',
    imgSrc: img_Base,
    chainName: 'Base',
    rpcUrls: ['https://developer-access-mainnet.base.org'],
    blockExplorerUrls: ['https://basescan.org/'],
    name: 'Base',
    symbol: 'ETH'
  },
  {
    isTest: false,
    chainId: 1115511,
    netName: 'Sepolia',
    imgSrc: img_eth,
    chainName: 'Sepolia',
    rpcUrls: ['https://rpc-sepolia.rockx.com'],
    blockExplorerUrls: ['https://sepolia.etherscan.io/'],
    name: 'Sepolia',
    symbol: 'ETH'
  }
  // ,{
  //   isTest: false,
  //   chainId: 5,
  //   netName: 'Goerli',
  //   imgSrc: img_eth,
  // }
]
export const setupNetwork = async (chainInfo: any, callback?: (_e: any) => void) => {
  const provider = (<WindowChain>window).ethereum
  if (provider && provider.request) {
    try {
      await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: `0x${(chainInfo.chainId).toString(16)}` }],
      })
      callback && callback({ code: 200 })
    } catch (switchError: any) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError.code === 4902) {
        try {
          await provider.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: `0x${(chainInfo.chainId).toString(16)}`,
                chainName: chainInfo.chainName,
                nativeCurrency: {
                  name: chainInfo.name,
                  symbol: chainInfo.symbol,
                  decimals: 18,
                },
                rpcUrls: chainInfo.rpcUrls,
                blockExplorerUrls: chainInfo.blockExplorerUrls,
              },
            ]
          })
        } catch (addError) {
          // handle "add" error
          console.error("handle add error")
        }
      }
      // handle other "switch" errors  // cancelSwitch: switchError.code === 4001
      console.error("handle other switch errors")
      return 'cancelSwitch'
    }
  } else {
    console.error("Can't setup the network on metamask because window.ethereum is undefined")
    return false
  }
}

export async function calculateL2TransactionHash(inboxSequenceNumber: BigNumber, l2ChainId: string) {
  const web3 = new Web3()

  const PendingData = web3.utils.padLeft((web3.utils.toBN(l2ChainId)).toString(16), 64) +
    web3.utils.padLeft(bitFlipSeqNum(inboxSequenceNumber), 64)

  return web3.utils.keccak256(`0x${PendingData}`)
}

export function bitFlipSeqNum(seqNum: BigNumber) {
  const web3 = new Web3()

  return (
    web3.utils.toBN(seqNum.toString(16))
      .add(web3.utils.toBN(1).shln(255))
      .toString(16)
  )
}

export function calculateL2RetryableTransactionHash(requestID: string) {
  const web3 = new Web3()

  const PendingData = web3.utils.padLeft(requestID, 32) +
    web3.utils.padLeft('00', 64)

  return web3.utils.keccak256(PendingData)
}


export function numberFormatter(num: string, digits: number) {
  let numb = Number(num)
  const si = [
    { value: 1, symbol: "" },
    { value: 1E3, symbol: "K" },
    { value: 1E6, symbol: "M" },
    { value: 1E9, symbol: "B" },
    { value: 1E12, symbol: "T" },
    { value: 1E15, symbol: "P" },
    { value: 1E18, symbol: "E" }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i;
  for (i = si.length - 1; i > 0; i--) {
    if (numb >= si[i].value) {
      break;
    }
  }
  // return (numb / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
  return (numb / si[i].value).toFixed(digits) + si[i].symbol;
}


// "networks": {
//   "42": {
//     "DF": {
//       "address": "0x79E40d67DA6eAE5eB4A93Fc6a56A7961625E15F3",
//       "symbol": "DF"
//     }
//   },
//   "1": {
//     "DF": {
//       "address": "0x431ad2ff6a9C365805eBaD47Ee021148d6f7DBe0",
//       "symbol": "DF"
//     }
//   }
// }
export const DFApproveXArray = [
  '0x431ad2ff6a9C365805eBaD47Ee021148d6f7DBe0',  // 1  DF
  '0x956834b4a3Bd092150efcEd5289D84228000dF80',  // 5  DF
  '0x79E40d67DA6eAE5eB4A93Fc6a56A7961625E15F3',  // 42 DF
  '0x384AA79B0BE5907c3409f478E9eb94777F9C494C',  // 5  USDT

  '0x778d20CE2f51B3b882b76b8268F32F6211E69834',// 11155111  USDT
  '0x4ed818eDd4a0c36A3F43826A60630879b8164401', // 11155111  DF
]


export const calcUR = (_cash: BigNumber, _borrows: BigNumber, _reserves: BigNumber) => {
  const bn_ZORE = new BigNumber(0)
  const bn_ONE = new BigNumber(10).pow(new BigNumber(18))

  if (_borrows.eq(bn_ZORE)) {
    return bn_ZORE
  }
  const _grossSupply = _cash.plus(_borrows)
  if (_grossSupply.lte(_reserves)) {
    return bn_ONE
  }
  const _supply = _grossSupply.minus(_reserves)
  if (_borrows.gt(_supply)) {
    return bn_ONE
  }

  return (_borrows.multipliedBy(bn_ONE).dividedBy(_supply))
}


export const Identify_domain = () => {
  const domain = window.location.host
  if (domain.includes("dforce.eth.limo")) {
    return `https://beta.dforce.eth.limo/`
  } else {
    return `https://beta.unitus.finance/`

  }
}