import React from 'react'
import styled from 'styled-components'

const NoteTips = ({ children }: {
  children: React.ReactNode
}) => (
  <StyledNoteTips>
    {children}
  </StyledNoteTips>
)

const StyledNoteTips = styled.div`
  background: ${(props) => props.theme.body_background_light};
  border-radius: 12px;
  padding: 20px;
  color: ${(props) => props.theme.global_grey};
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  
  .dark & {
    background: ${(props) => props.theme.body_background_night};
  }

  @media (max-width: 767px) {
    font-size: 12px;
    line-height: 18px;
    padding: 14px;
  }
`

export default NoteTips