"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.abi = void 0;
exports.abi = [
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_base",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "_optimal",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "_slope_1",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "_slope_2",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "_blocksPerYear",
                "type": "uint256"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "constructor"
    },
    {
        "inputs": [],
        "name": "base",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "blocksPerYear",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_balance",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "_borrows",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "_reserves",
                "type": "uint256"
            }
        ],
        "name": "getBorrowRate",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "_borrowRate",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "isInterestRateModel",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "pure",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "optimal",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "slope_1",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "slope_2",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    }
];
