"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.abi = void 0;
const lendingData_1 = require("./lendingData");
const lendingHelper_1 = require("./lendingHelper");
const controller_1 = require("./controller");
const interestModel_1 = require("./interestModel");
const iToken_1 = require("./iToken");
const priceOracle_1 = require("./priceOracle");
const timeLockStrategy_1 = require("./timeLockStrategy");
const timeLock_1 = require("./timeLock");
exports.abi = {
    lendingData: lendingData_1.abi,
    lendingHelper: lendingHelper_1.abi,
    controller: controller_1.abi,
    interestModel: interestModel_1.abi,
    iToken: iToken_1.abi,
    priceOracle: priceOracle_1.abi,
    timeLockStrategy: timeLockStrategy_1.abi,
    timeLock: timeLock_1.abi,
};
