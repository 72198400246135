import BigNumber from 'bignumber.js';
import * as Types from "./types.js";
import { SUBTRACT_GAS_LIMIT } from './constants.js';

import general_ERC20Json from '../clean_build/contracts/general/IERC20.json';
import general_ETHJson from '../clean_build/contracts/general/ETH.json';
import general_LendingData from '../clean_build/contracts/general/LendingData.json'
import general_Controller from '../clean_build/contracts/general/Controller.json'
import general_DL_Token from '../clean_build/contracts/general/DL_Token.json';
import general_rewardDistributor from '../clean_build/contracts/general/rewardDistributor.json'

import synthetic_ERC20Json from '../clean_build/contracts/synthetic/IERC20.json';
import synthetic_ETHJson from '../clean_build/contracts/synthetic/ETH.json';
import synthetic_LendingData from '../clean_build/contracts/synthetic/LendingData.json'
import synthetic_Controller from '../clean_build/contracts/synthetic/Controller.json'
import synthetic_DL_Token from '../clean_build/contracts/synthetic/DL_Token.json';
import synthetic_rewardDistributor from '../clean_build/contracts/synthetic/rewardDistributor.json'

import stocks_ERC20Json from '../clean_build/contracts/stocks/IERC20.json';
import stocks_ETHJson from '../clean_build/contracts/stocks/ETH.json';
import stocks_LendingData from '../clean_build/contracts/stocks/LendingData.json'
import stocks_Controller from '../clean_build/contracts/stocks/Controller.json'
import stocks_DL_Token from '../clean_build/contracts/stocks/DL_Token.json';
import stocks_rewardDistributor from '../clean_build/contracts/stocks/rewardDistributor.json'

import stake_ERC20Json from '../clean_build/contracts/stake/IERC20.json';
import stake_rewardsJson from '../clean_build/contracts/stake/rewards.json';
import stake_VaultData from '../clean_build/contracts/stake/VaultData.json';

import Liquidity_ERC20Json from '../clean_build/contracts/Liquidity/IERC20.json';
import Liquidity_rewardsJson from '../clean_build/contracts/Liquidity/rewards.json';
import Liquidity_VaultData from '../clean_build/contracts/Liquidity/VaultData.json';

import earnVelo_ERC20Json from '../clean_build/contracts/earnVelo/IERC20.json';
import earnVelo_rewardsJson from '../clean_build/contracts/earnVelo/rewards.json';
import earnVelo_rewardToken from '../clean_build/contracts/earnVelo/rewardToken.json';

import Fish_ERC20Json from '../clean_build/contracts/FishSwap/IERC20.json';
import Fish_rewardsJson from '../clean_build/contracts/FishSwap/rewards.json';

import stakeDouble_ERC20Json from '../clean_build/contracts/stakeDouble/IERC20.json';
import stakeDouble_rewardsJson from '../clean_build/contracts/stakeDouble/rewards.json';
import stake_DFJson from '../clean_build/contracts/stake/DF.json'

import borrowAddressJson from '../clean_build/contracts/borrow/address.json';
import borrowLendingData from '../clean_build/contracts/borrow/LendingData.json'
import MTokenData from '../clean_build/contracts/borrow/MTokenData.json'
import borrowERC20 from '../clean_build/contracts/borrow/ERC20.json'
import borrowIERC20 from '../clean_build/contracts/borrow/IERC20.json'

import trade_dforceSplitWrap from '../clean_build/contracts/trade/dforceSplitWrap.json'
// import trade_SFGhelper from '../clean_build/contracts/trade/SFGhelper.json'
import trade_searchBestReturn from '../clean_build/contracts/trade/searchBestReturn.json'
import trade_gst2 from '../clean_build/contracts/trade/gst2.json'
import trade_tokenList from '../clean_build/contracts/trade/tokenList.json'
import trade_SENDTOKEN from '../clean_build/contracts/trade/SENDTOKEN.json'
import trade_RECEIVETOKEN from '../clean_build/contracts/trade/RECEIVETOKEN.json'

// import stakeDF_DFJson from '../clean_build/contracts/stakeDF/DF.json';
// import stakeDF_sDFJson from '../clean_build/contracts/stakeDF/sDF.json';

import lock_DFJson from '../clean_build/contracts/lock/DF.json'
import lock_veDFJson from '../clean_build/contracts/lock/veDF.json';
import lock_sDFJson from '../clean_build/contracts/lock/sDF.json';
import lock_veDFManagerJson from '../clean_build/contracts/lock/veDFManager.json';

import vote_governorDelegatorJson from '../clean_build/contracts/vote/governorDelegator.json';
import vote_veDFJson from '../clean_build/contracts/vote/veDF.json';

import lsr_sendTokenJson from '../clean_build/contracts/lsr/sendToken.json'
import lsr_msdJson from '../clean_build/contracts/lsr/msd.json';
import lsr_Json from '../clean_build/contracts/lsr/lsr.json';

import RewardDistributorJson from "../clean_build/contracts/mining/RewardDistributor.json"
import RewardDistributorJsonArb from "../clean_build/contracts/mining/RewardDistributorArb.json"
import RewardDistributorUSXUTS from "../clean_build/contracts/mining/RewardDistributorUSX-UTS.json"
import RewardDistributorDFUTS from "../clean_build/contracts/mining/RewardDistributorDF-UTS.json"



// liqudate addressMap
const assignAddressMap = (fileJson, networkId) => {
  let that = {}
  for (const key in fileJson.networks[networkId]) {
    // const convertKey = fileJson.networks[networkId][key]["symbol"]
    that[key] = fileJson.networks[networkId][key]["address"]
  }
  return that
}

const assignObj = (cthis, that, fileJson, networkId, account) => {
  for (const key in fileJson.networks[networkId]) {
    that[key] = {
      ...fileJson.networks[networkId][key],
      "abi": fileJson["abi"],
      "gasToken": fileJson.networks[networkId][key]["gasToken"],
      "symbol": fileJson.networks[networkId][key]["symbol"],
      "assetsType": fileJson.networks[networkId][key]["assetsType"],
      "tokenType":fileJson.networks[networkId][key]["tokenType"],
      ...new cthis.web3.eth.Contract(fileJson.abi, fileJson.networks[networkId][key]["address"]),
      "contract":new cthis.web3.eth.Contract(fileJson.abi, fileJson.networks[networkId][key]["address"]),
      "json": fileJson,
      "options": {
        "address": fileJson.networks[networkId][key]["address"],
        "from": account
      }
    }
  }
  return that
}

// stake assignObj
const assignStakeObj = (cthis, that, fileJson, networkId, account) => {
  for (const key in fileJson.networks[networkId]) {
    that[key] = {
      ...fileJson.networks[networkId][key],
      "abi": fileJson["abi"],
      "symbol": fileJson.networks[networkId][key]["symbol"],
      "poolSymbol": fileJson.networks[networkId][key]?.["poolSymbol"],
      "assetsType": fileJson.networks[networkId][key]["assetsType"],
      "pid": fileJson.networks[networkId][key]?.["pid"],
      "heat": fileJson.networks[networkId][key]["heat"],
      "Link": fileJson.networks[networkId][key]["Link"],
      "LinkTo": fileJson.networks[networkId][key]?.["LinkTo"],
      ...new cthis.web3.eth.Contract(fileJson.abi, fileJson.networks[networkId][key]["address"]),
      "json": fileJson,
      "options": {
        "address": fileJson.networks[networkId][key]["address"],
        "from": account
      }
    }
  }
  return that
}


// borrow assignObj
const assignBorrowObject = (cthis, that, fileJson, networkId, account) => {
  for (const key in fileJson.networks[networkId]) {
    that[key] = {
      address: {
        ...fileJson.networks[networkId][key],
      },
      contracts: fileJson.networks[networkId][key]['type'] && fileJson.networks[networkId][key]['type'] === 'onlyBorrow'
        ? {
          lendingData: new cthis.web3.eth.Contract(borrowLendingData.abi, fileJson.networks[networkId][key]['lendingData']["address"]),
          supplyToken: new cthis.web3.eth.Contract(borrowERC20.abi, fileJson.networks[networkId][key]['supplyToken']["address"]),
          iToken: new cthis.web3.eth.Contract(borrowIERC20.abi, fileJson.networks[networkId][key]['iToken']["address"]),
          borrowToken: new cthis.web3.eth.Contract(borrowERC20.abi, fileJson.networks[networkId][key]['borrowToken']["address"]),
          mToken: new cthis.web3.eth.Contract(MTokenData.abi, fileJson.networks[networkId][key]['mToken']["address"]),
        }
        : {
          lendingData: new cthis.web3.eth.Contract(borrowLendingData.abi, fileJson.networks[networkId][key]['lendingData']["address"]),
          supplyToken: new cthis.web3.eth.Contract(borrowERC20.abi, fileJson.networks[networkId][key]['supplyToken']["address"]),
          iToken: new cthis.web3.eth.Contract(borrowIERC20.abi, fileJson.networks[networkId][key]['iToken']["address"]),
          borrowToken: new cthis.web3.eth.Contract(borrowERC20.abi, fileJson.networks[networkId][key]['borrowToken']["address"]),
          mToken: new cthis.web3.eth.Contract(MTokenData.abi, fileJson.networks[networkId][key]['mToken']["address"]),
        },
      "account": account,
      "type": fileJson.networks[networkId][key]['type']
    }
  }
  return that
}


// trade assignObj
const assignTradeObj = (cthis, that, fileJson, networkId, account) => {
  for (const key in fileJson.networks[networkId]) {
    that[key] = !fileJson.networks[networkId][key]?.gasToken ? {
      ...fileJson.networks[networkId][key],
      "abi": fileJson["abi"],
      "symbol": fileJson.networks[networkId][key]?.symbol,
      "icon": fileJson.networks[networkId][key]?.icon,
      "balance": fileJson.networks[networkId][key]?.balance,
      "decimals": fileJson.networks[networkId][key]?.decimals,
      "gasToken": fileJson.networks[networkId][key]?.gasToken,
      ...new cthis.web3.eth.Contract(fileJson.abi, fileJson.networks[networkId][key]["address"]),
      "json": fileJson,
      "options": {
        "address": fileJson.networks[networkId][key]["address"],
        "from": account
      }
    }
      :
      {
        ...fileJson.networks[networkId][key],
        "abi": fileJson["abi"],
        "symbol": fileJson.networks[networkId][key]?.symbol,
        "icon": fileJson.networks[networkId][key]?.icon,
        "balance": fileJson.networks[networkId][key]?.balance,
        "decimals": fileJson.networks[networkId][key]?.decimals,
        "gasToken": fileJson.networks[networkId][key]?.gasToken,
        "json": fileJson,
        "options": {
          "address": fileJson.networks[networkId][key]["address"],
          "from": account
        }
      }
  }
  return that
}

const assignStakeDF = (cthis, that, fileJson, networkId, account) => {
  for (const key in fileJson.networks[networkId]) {
    that[key] = {
      ...fileJson.networks[networkId][key],
      "abi": fileJson["abi"],
      "symbol": fileJson.networks[networkId][key]["symbol"],
      ...new cthis.web3.eth.Contract(fileJson.abi, fileJson.networks[networkId][key]["address"]),
      "json": fileJson,
      "options": {
        "address": fileJson.networks[networkId][key]["address"],
        "from": account
      }
    }
  }
  return that
}

const assignVote = (cthis, that, fileJson, networkId, account) => {
  for (const key in fileJson.networks[networkId]) {
    that[key] = {
      ...fileJson.networks[networkId][key],
      "abi": fileJson["abi"],
      "symbol": fileJson.networks[networkId][key]?.["symbol"] ?? undefined,
      ...new cthis.web3.eth.Contract(fileJson.abi, fileJson.networks[networkId][key]["address"]),
      "contract":new cthis.web3.eth.Contract(fileJson.abi, fileJson.networks[networkId][key]["address"]),
      "json": fileJson,
      "options": {
        "address": fileJson.networks[networkId][key]["address"],
        "from": account
      }
    }
  }
  return that
}

// PSM - start

// lsr assignObj
const assignSendToken = (cthis, that, fileJson, networkId, account) => {
  for (const key in fileJson.networks[networkId]) {
    that[key] = {
      ...fileJson.networks[networkId][key],
      "abi": fileJson["abi"],
      "symbol": fileJson.networks[networkId][key]["symbol"],
      "assetsType": fileJson.networks[networkId][key]["assetsType"],
      "Link": fileJson.networks[networkId][key]["Link"],
      "contracts":new cthis.web3.eth.Contract(fileJson.abi, fileJson.networks[networkId][key]["address"]),
      "json": fileJson,
      "options": {
        "address": fileJson.networks[networkId][key]["address"],
        "from": account
      }
    }
  }
  return that
}
const assignReceiveToken = (cthis, that, fileJson, networkId, account) => {
  for (const key in fileJson.networks[networkId]) {
    that[key] = {
      ...fileJson.networks[networkId][key],
      "abi": fileJson["abi"],
      "symbol": fileJson.networks[networkId][key]["symbol"],
      "assetsType": fileJson.networks[networkId][key]["assetsType"],
      "Link": fileJson.networks[networkId][key]["Link"],
      "contracts":new cthis.web3.eth.Contract(fileJson.abi, fileJson.networks[networkId][key]["address"]),
      "json": fileJson,
      "options": {
        "address": fileJson.networks[networkId][key]["address"],
        "from": account
      }
    }
  }
  return that
}
const assignLSR = (cthis, that, fileJson, networkId, account) => {
  for (const key in fileJson.networks[networkId]) {
    that[key] = {
      ...fileJson.networks[networkId][key],
      "abi": fileJson["abi"],
      "symbol": fileJson.networks[networkId][key]["symbol"],
      "assetsType": fileJson.networks[networkId][key]["assetsType"],
      "Link": fileJson.networks[networkId][key]["Link"],
      "contracts":new cthis.web3.eth.Contract(fileJson.abi, fileJson.networks[networkId][key]["address"]),
      "json": fileJson,
      "options": {
        "address": fileJson.networks[networkId][key]["address"],
        "from": account
      }
    }
  }
  return that
}
// PSM---end
export class Contracts {
  constructor(
    provider,
    networkId,
    web3,
    options
  ) {
    try {
      this.web3 = web3;
      this.defaultConfirmations = options.defaultConfirmations;
      this.autoGasMultiplier = options.autoGasMultiplier || 1.5;
      this.confirmationType = options.confirmationType || Types.ConfirmationType.Confirmed;
      this.defaultGas = options.defaultGas;
      this.defaultGasPrice = options.defaultGasPrice;
      const account = this.web3.eth.defaultAccount
      // general pool
      this.general = {}
      const contractsArr1 = {
        ...assignObj(this, this.general, general_Controller, networkId, account),
        ...assignObj(this, this.general, general_LendingData, networkId, account),
        ...assignObj(this, this.general, general_ERC20Json, networkId, account),
        ...assignObj(this, this.general, general_DL_Token, networkId, account),
        ...assignObj(this, this.general, general_ETHJson, networkId, account),
        ...assignObj(this, this.general, general_rewardDistributor, networkId, account)
      }
      // liqudate addressMap
      this.general.liqudateAddressMap = {
        ...assignAddressMap(general_DL_Token, networkId)
        // ...assignAddressMap(general_ETHJson,networkId)
      }
      // synthetic pool
      this.synthetic = {}
      const contractsArr2 = {
        ...assignObj(this, this.synthetic, synthetic_Controller, networkId, account),
        ...assignObj(this, this.synthetic, synthetic_LendingData, networkId, account),
        ...assignObj(this, this.synthetic, synthetic_ERC20Json, networkId, account),
        ...assignObj(this, this.synthetic, synthetic_DL_Token, networkId, account),
        ...assignObj(this, this.synthetic, synthetic_ETHJson, networkId, account),
        ...assignObj(this, this.synthetic, synthetic_rewardDistributor, networkId, account)
      }
      // liqudate addressMap
      this.synthetic.liqudateAddressMap = {
        ...assignAddressMap(synthetic_DL_Token, networkId)
        // ...assignAddressMap(synthetic_ETHJson,networkId)
      }
      // stocks pool
      this.stocks = {}
      const contractsArr3 = {
        ...assignObj(this, this.stocks, stocks_Controller, networkId, account),
        ...assignObj(this, this.stocks, stocks_LendingData, networkId, account),
        ...assignObj(this, this.stocks, stocks_ERC20Json, networkId, account),
        ...assignObj(this, this.stocks, stocks_DL_Token, networkId, account),
        ...assignObj(this, this.stocks, stocks_ETHJson, networkId, account),
        ...assignObj(this, this.stocks, stocks_rewardDistributor, networkId, account)
      }
      // stocks addressMap
      this.stocks.liqudateAddressMap = {
        ...assignAddressMap(stocks_DL_Token, networkId)
        // ...assignAddressMap(stocks_ETHJson,networkId)
      }
      //stake 
      this.stake = {}
      const contractsArr4 = {
        ...assignStakeObj(this, this.stake, stake_ERC20Json, networkId, account),
        ...assignStakeObj(this, this.stake, stake_rewardsJson, networkId, account),
        ...assignStakeObj(this, this.stake, stake_VaultData, networkId, account)
        // ...assignStakeObj(this,this.stake,stake_DFJson,networkId,account)
      }

      this.stake.liqudateAddressMap = {
        ...assignAddressMap(stake_rewardsJson, networkId),
        ...assignAddressMap(stake_VaultData, networkId) 
        // ...assignAddressMap(stocks_ETHJson,networkId) 
        // key: symbol
        // value: address
      }

      // borrow 
      this.borrow = {}
      const contractsArr7 = {
        ...assignBorrowObject(this, this.borrow, borrowAddressJson, networkId, account),
      }


      //trade 
      this.trade = {}
      const contractsArr6 = {
        ...assignTradeObj(this, this.trade, trade_dforceSplitWrap, networkId, account),
        // ...assignTradeObj(this,this.trade,trade_SFGhelper,networkId,account),
        ...assignTradeObj(this, this.trade, trade_searchBestReturn, networkId, account),
        ...assignTradeObj(this, this.trade, trade_gst2, networkId, account),
        ...assignTradeObj(this, this.trade, trade_tokenList, networkId, account),
        ...assignTradeObj(this, this.trade, trade_SENDTOKEN, networkId, account),
        ...assignTradeObj(this, this.trade, trade_RECEIVETOKEN, networkId, account)
      }
      // trade addressMap
      this.trade.liqudateAddressMap = {
        ...assignAddressMap(trade_tokenList, networkId)
        // ...assignAddressMap(stocks_ETHJson,networkId)
      }

      // stakeDF 
      // this.stakeDF = {}
      // const contractsArr8 = {
      //   ...assignStakeDF(this,this.stakeDF,stakeDF_DFJson,networkId,account),
      //   ...assignStakeDF(this,this.stakeDF,stakeDF_sDFJson,networkId,account),
      // }
      // // trade addressMap
      // this.stakeDF.liqudateAddressMap = {
      //   ...assignAddressMap(stakeDF_DFJson,networkId),
      //   ...assignAddressMap(stakeDF_sDFJson,networkId)
      // }

      // lock DF 
      this.lockDF = {}
      const contractsArr10 = {
        ...assignStakeDF(this,this.lockDF,lock_DFJson,networkId,account),
        ...assignStakeDF(this,this.lockDF,lock_veDFJson,networkId,account),
        ...assignStakeDF(this,this.lockDF,lock_sDFJson,networkId,account),
        ...assignStakeDF(this,this.lockDF,lock_veDFManagerJson,networkId,account)
      }
      // lockDF addressMap
      this.lockDF.liqudateAddressMap = {
        ...assignAddressMap(lock_DFJson,networkId),
        ...assignAddressMap(lock_veDFJson,networkId),
        ...assignAddressMap(lock_sDFJson,networkId),
        ...assignAddressMap(lock_veDFManagerJson,networkId)
      }

      // vote 
      this.vote = {}
      const contractsArr9 = {
        ...assignVote(this,this.vote,vote_governorDelegatorJson,networkId,account),
        ...assignVote(this,this.vote,vote_veDFJson,networkId,account),
      }
      // trade addressMap
      this.vote.liqudateAddressMap = {
        ...assignAddressMap(vote_governorDelegatorJson,networkId),
        ...assignAddressMap(vote_veDFJson,networkId)
      }

      // lsr 
      this.lsr = {
        "send":{},
        "msd":{}
      }
      const contractsArr11 = {
        ...assignSendToken(this,this.lsr.send,lsr_sendTokenJson,networkId,account),
        ...assignReceiveToken(this,this.lsr.msd,lsr_msdJson,networkId,account),
        ...assignLSR(this,this.lsr,lsr_Json,networkId,account),
      }
      // lsr addressMap
      this.lsr.liqudateAddressMap = {
        "send":assignAddressMap(lsr_sendTokenJson,networkId),
        "msd":assignAddressMap(lsr_msdJson,networkId),
        "lsr":assignAddressMap(lsr_Json,networkId),
      }

      // lsr 
      this.mining = {}
      const contractsArr12 = {
        ...assignObj(this,this.mining,RewardDistributorJson,networkId),
        ...assignObj(this,this.mining,RewardDistributorJsonArb,networkId),
        ...assignObj(this,this.mining,RewardDistributorUSXUTS,networkId),
        ...assignObj(this,this.mining,RewardDistributorDFUTS,networkId),
      }
      this.networkId = networkId
      // console.log("Contracts", this)
      // this.setProvider(provider, networkId);

      //Liquidity 
      this.liquidity = {}
      const contractsArr13 = {
        ...assignStakeObj(this, this.liquidity, Liquidity_ERC20Json, networkId, account),
        ...assignStakeObj(this, this.liquidity, Liquidity_rewardsJson, networkId, account),
        ...assignStakeObj(this, this.liquidity, Liquidity_VaultData, networkId, account)
        // ...assignStakeObj(this,this.stake,stake_DFJson,networkId,account)
      }

      this.liquidity.liqudateAddressMap = {
        ...assignAddressMap(Liquidity_rewardsJson, networkId),
        ...assignAddressMap(Liquidity_VaultData, networkId) 
        // ...assignAddressMap(stocks_ETHJson,networkId) 
        // key: symbol
        // value: address
      }

      console.log("Contracts", this)
      
      // this.stakeDouble = {}
      // const contractsArr5 = {
      //   ...assignStakeObj(this, this.stakeDouble, stakeDouble_ERC20Json, networkId, account),
      //   ...assignStakeObj(this, this.stakeDouble, stakeDouble_rewardsJson, networkId, account)
      // }

      // //farming earned Velo 
      // this.earnVelo = {}
      // const contractsArr8 = {
      //   ...assignStakeObj(this, this.earnVelo, earnVelo_ERC20Json, networkId, account),
      //   ...assignStakeObj(this, this.earnVelo, earnVelo_rewardsJson, networkId, account)
      // }

      // this.earnVelo.liqudateAddressMap = {
      //   ...assignAddressMap(earnVelo_rewardsJson, networkId)
      // }

      // //FishSwp 
      // this.FishSwap = {}
      // const contractsArr14 = {
      //   ...assignStakeObj(this, this.FishSwap, Fish_ERC20Json, networkId, account),
      //   ...assignStakeObj(this, this.FishSwap, Fish_rewardsJson, networkId, account)
      // }
      // // stocks addressMap
      // this.FishSwap.liqudateAddressMap = {
      //   ...assignAddressMap(Fish_rewardsJson, networkId)
      // }
    } catch (error) {
      console.log(error)
    }
  }


  setProvider(
    provider,
    networkId
  ) {
    let contracts = Object.keys(this.general).map(key => {
      if (this.general[key] instanceof Object && (key !== 'web3') && (key !== 'liqudateAddressMap')) {
        return {
          "contract": this.general[key],
          "json": this.general[key]["abi"]
        }
      }
    })

    // 过滤掉 不是 key => tokenContract 的 key
    contracts = contracts.filter((contract) => contract !== undefined)
    contracts.forEach(contract => this.setContractProvider(
      contract.contract,
      contract.json,
      provider,
      networkId,
    ),
    );
  }

  async callContractFunction(
    method,
    options
  ) {
    const { confirmations, confirmationType, autoGasMultiplier, ...txOptions } = options;

    if (!this.blockGasLimit) {
      await this.setGasLimit();
    }

    if (!txOptions.gasPrice && this.defaultGasPrice) {
      txOptions.gasPrice = this.defaultGasPrice;
    }

    if (confirmationType === Types.ConfirmationType.Simulate || !options.gas) {
      let gasEstimate;
      if (this.defaultGas && confirmationType !== Types.ConfirmationType.Simulate) {
        txOptions.gas = this.defaultGas;
      } else {
        try {
          console.log("estimating gas");
          gasEstimate = await method.estimateGas(txOptions);
        } catch (error) {
          const data = method.encodeABI();
          const { from, value } = options;
          const to = method._parent._address;
          error.transactionData = { from, value, data, to };
          throw error;
        }

        const multiplier = autoGasMultiplier || this.autoGasMultiplier;
        const totalGas = Math.floor(gasEstimate * multiplier);
        txOptions.gas = totalGas < this.blockGasLimit ? totalGas : this.blockGasLimit;
      }

      if (confirmationType === Types.ConfirmationType.Simulate) {
        let g = txOptions.gas;
        return { gasEstimate, g };
      }
    }

    if (txOptions.value) {
      txOptions.value = new BigNumber(txOptions.value).toFixed(0);
    } else {
      txOptions.value = '0';
    }

    const promi = method.send(txOptions);

    const OUTCOMES = {
      INITIAL: 0,
      RESOLVED: 1,
      REJECTED: 2,
    };

    let hashOutcome = OUTCOMES.INITIAL;
    let confirmationOutcome = OUTCOMES.INITIAL;

    const t = confirmationType !== undefined ? confirmationType : this.confirmationType;

    if (!Object.values(Types.ConfirmationType).includes(t)) {
      throw new Error(`Invalid confirmation type: ${t}`);
    }

    let hashPromise;
    let confirmationPromise;

    if (t === Types.ConfirmationType.Hash || t === Types.ConfirmationType.Both) {
      hashPromise = new Promise(
        (resolve, reject) => {
          promi.on('error', (error) => {
            if (hashOutcome === OUTCOMES.INITIAL) {
              hashOutcome = OUTCOMES.REJECTED;
              reject(error);
              const anyPromi = promi;
              anyPromi.off();
            }
          });

          promi.on('transactionHash', (txHash) => {
            if (hashOutcome === OUTCOMES.INITIAL) {
              hashOutcome = OUTCOMES.RESOLVED;
              resolve(txHash);
              if (t !== Types.ConfirmationType.Both) {
                const anyPromi = promi;
                anyPromi.off();
              }
            }
          });
        },
      );
    }

    if (t === Types.ConfirmationType.Confirmed || t === Types.ConfirmationType.Both) {
      confirmationPromise = new Promise(
        (resolve, reject) => {
          promi.on('error', (error) => {
            if (
              (t === Types.ConfirmationType.Confirmed || hashOutcome === OUTCOMES.RESOLVED)
              && confirmationOutcome === OUTCOMES.INITIAL
            ) {
              confirmationOutcome = OUTCOMES.REJECTED;
              reject(error);
              const anyPromi = promi;
              anyPromi.off();
            }
          });

          const desiredConf = confirmations || this.defaultConfirmations;
          if (desiredConf) {
            promi.on('confirmation', (confNumber, receipt) => {
              if (confNumber >= desiredConf) {
                if (confirmationOutcome === OUTCOMES.INITIAL) {
                  confirmationOutcome = OUTCOMES.RESOLVED;
                  resolve(receipt);
                  const anyPromi = promi;
                  anyPromi.off();
                }
              }
            });
          } else {
            promi.on('receipt', (receipt) => {
              confirmationOutcome = OUTCOMES.RESOLVED;
              resolve(receipt);
              const anyPromi = promi;
              anyPromi.off();
            });
          }
        },
      );
    }

    if (t === Types.ConfirmationType.Hash) {
      const transactionHash = await hashPromise;
      if (this.notifier) {
        this.notifier.hash(transactionHash)
      }
      return { transactionHash };
    }

    if (t === Types.ConfirmationType.Confirmed) {
      return confirmationPromise;
    }

    const transactionHash = await hashPromise;
    if (this.notifier) {
      this.notifier.hash(transactionHash)
    }
    return {
      transactionHash,
      confirmation: confirmationPromise,
    };
  }

  async callConstantContractFunction(
    method,
    options
  ) {
    const m2 = method;
    const { blockNumber, ...txOptions } = options;
    return m2.call(txOptions, blockNumber);
  }

  async setGasLimit() {
    const block = await this.web3.eth.getBlock('latest');
    this.blockGasLimit = block.gasLimit - SUBTRACT_GAS_LIMIT;
  }

  setContractProvider(
    contract,
    contractJson,
    provider,
    networkId,
  ) {
    contract.setProvider(provider);
    // console.log(this)
    try {
      // contract.options.address = contractJson.networks[networkId]
      //   && contractJson.networks[networkId].address;
    } catch (error) {
      console.log(error)
    }
  }
}
