import React from "react";
import styled from "styled-components";

interface CrumbProps {
    children: React.ReactNode
    height?:string
}

const Crumb: React.FC<CrumbProps> = ({ children,height }) => {
    return (
        <CrumbBox height={height}>
            {children}
        </CrumbBox>
    );
}

interface CrumbBoxProps {
    height?:string
}

const CrumbBox = styled.div<CrumbBoxProps>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: ${(props) => props.height ? props.height : '60px'};
    position: relative;
`

export default Crumb

