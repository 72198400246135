/* eslint no-mixed-operators: 0 */
/* eslint no-param-reassign: 0 */
import {network_Name} from 'lending-sdk/utils/index1'

// https://beta.unitus.finance/synthetic/chart?network=kovan&asset=0x66b46e78E93E5aFC1E3766ae755036491F190Ab1&type=supply
// https://beta.unitus.finance/synthetic/chart?network=kovan&asset=0x34f9634Edc0a2344756a7481Db3789f7Dc5eBFb6&type=supply
export const fetchAPY = async (chainId, address, type) => {
  // const data = await requestAPI(`https://beta.unitus.finance/general/chart?network=${network_Name[chainId]}&asset=${address}&type=${type}`)
  // return data
  try {
    const response = await fetch(`https://beta.unitus.finance/general/chart?network=${network_Name[chainId]}&asset=${address}&type=${type}`)
    const res = await response.json();
    return res
  } catch (error) {
    console.log(error)
    return null
  }
}