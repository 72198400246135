import { ethers } from 'ethers'
import Web3 from 'web3'
import BigNumber from 'bignumber.js'
// import request from "request";
import { _getGasPrice } from 'utils';
import { provider, TransactionReceipt } from 'web3-core'
import { Contract } from 'web3-eth-contract'
import { AbiItem } from 'web3-utils'
import './multicall.d.ts'
import { aggregate, createWatcher } from "@makerdao/multicall"
import { LendingDataV2 } from 'lendingData/gen'
import controller_json from 'lending-sdk/lib/clean_build/contracts/general/Controller.json'

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
});

const GAS_LIMIT = {
  STAKING: {
    DEFAULT: 200000,
    SNX: 850000,
  }
};

// networkConfig  支持网络切换
export const network_Name = {
  '1': 'mainnet',
  '56': 'bsc',
  '97': 'bsctest',
  '10': 'Optimism',
  '42161': 'ArbitrumOne',
  '137': 'Polygon',
  '2221': 'KavaTestnet',
  '2222': 'Kava',
  '71': 'ConfluxTest',
  '1030': 'ConfluxeSpace',
  '324': 'zkSyncEra',
  '8453': 'Base',
  '11155111': 'Sepolia',
}
export const network_Id = {
  'mainnet': "1",
  'bsc': "56",
  'bsctest': "97",
  'Optimism': '10',
  'ArbitrumOne': '42161',
  'Polygon': '137',
  'KavaTestnet': '2221',
  'Kava': '2222',
  'ConfluxTest': '71',
  'ConfluxeSpace': '1030',
  'zkSyncEra': '324',
  'Base': '8453',
  'Sepolia': '11155111',
}
export const network_Arr = [
  'mainnet',
  'bsc',
  'bsctest',
  'Optimism',
  'ArbitrumOne',
  'Polygon',
  'KavaTestnet',
  'Kava',
  'ConfluxTest',
  'ConfluxeSpace',
  'zkSyncEra',
  'Base',
  'Goerli',
  'Sepolia',
]
export const etherscan = {
  '1': 'https://etherscan.io/address/',
  '56': 'https://bscscan.com/address/',
  '97': 'https://testnet.bscscan.com/address/',
  '10': 'https://optimistic.etherscan.io/address/',
  '42161': 'https://arbiscan.io/address/',
  '137': 'https://polygonscan.com/address/',
  '2221': 'https://explorer.evm-alpha.kava.io/address/',
  '2222': 'https://explorer.kava.io/address/',
  '71': 'https://evmtestnet.confluxscan.net/address/',
  '1030': 'https://evm.confluxscan.net/address/',
  '324': 'https://explorer.zksync.io/address/',
  '11155111': 'https://sepolia.etherscan.io/address/',
  '8453': 'https://basescan.org/address/'
}
export const transationLink = {
  '1': 'https://etherscan.io/tx/',
  '56': 'https://bscscan.com/tx/',
  '97': 'https://testnet.bscscan.com/tx/',
  '10': 'https://optimistic.etherscan.io/tx/',
  '42161': 'https://arbiscan.io/tx/',
  '137': 'https://polygonscan.com/tx/',
  '2221': 'https://explorer.evm-alpha.kava.io/tx/',
  '2222': 'https://explorer.kava.io/tx/',
  '71': 'https://evmtestnet.confluxscan.net/tx/',
  '1030': 'https://evm.confluxscan.net/tx/',
  '324': 'https://explorer.zksync.io/tx/',
  '11155111': 'https://sepolia.etherscan.io/tx/',
  '8453': 'https://basescan.org/tx/'
}
export const blockLink = {
  '1': 'https://etherscan.io/block/',
  '56': 'https://bscscan.com/block/',
  '10': 'https://etherscan.io/block/',
  '42161': 'https://etherscan.io/block/',
  '137': 'https://polygonscan.com/block/',
  '2221': 'https://explorer.evm-alpha.kava.io/block/',
  '2222': 'https://explorer.kava.io/block/',
  '71': 'https://evmtestnet.confluxscan.net/block/',
  '1030': 'https://evm.confluxscan.net/block/',
  '324': 'https://explorer.zksync.io/block/',
  '8453': 'https://basescan.org/block/',
  '11155111': 'https://sepolia.etherscan.io/block',
}
export const walletName = {
  '1': 'Ethereum',
  '56': 'BSC',
  '97': 'BSC-Test',
  '10': 'Optimism',
  '42161': 'ArbitrumOne',
  '137': 'Polygon',
  '2221': 'KavaTestnet',
  '2222': 'Kava',
  '71': 'ConfluxTest',
  '1030': 'Conflux eSpace',
  '324': 'zkSync Era',
  '8453': 'Base',
  '11155111': 'Sepolia',
}
//lendingDate Contract interface
export const getAccountTotalValue = async (currentProvider: any, chainId: any, account: string) => {
  try {
    const provider = new ethers.providers.Web3Provider(currentProvider)
    const controller_address = controller_json.networks[chainId].Controller.address
    const ld = new LendingDataV2(controller_address, provider)
    const tmpData = await ld.getAccountTotalValue(account)
    return tmpData
  } catch (error) {
    console.log(error)
  }
}

export const getAccountSupplyTokens = async (currentProvider: any, chainId: any, account: string) => {
  try {
    const provider = new ethers.providers.Web3Provider(currentProvider)
    const controller_address = controller_json.networks[chainId].Controller.address
    const ld = new LendingDataV2(controller_address, provider)
    const tmpData = await ld.getAccountSupplyTokens(account)
    return tmpData
  } catch (error) {
    console.log(error)
  }
}

export const getAccountBorrowTokens = async (currentProvider: any, chainId: any, account: string) => {
  try {
    const provider = new ethers.providers.Web3Provider(currentProvider)
    const controller_address = controller_json.networks[chainId].Controller.address
    const ld = new LendingDataV2(controller_address, provider)
    const tmpData = await ld.getAccountBorrowTokens(account)
    return tmpData
  } catch (error) {
    console.log(error)
  }
}

export const getAccountMSDTokens = async (currentProvider: any, chainId: any, account: string) => {
  try {
    const provider = new ethers.providers.Web3Provider(currentProvider)
    const controller_address = controller_json.networks[chainId].Controller.address
    const ld = new LendingDataV2(controller_address, provider)
    const tmpData = await ld.getAccountMSDTokens(account)
    return tmpData
  } catch (error) {
    console.log(error)
  }
}
export const getAccountTokens = async (currentProvider: any, chainId: any, account: string) => {
  try {
    const provider = new ethers.providers.Web3Provider(currentProvider)
    const controller_address = controller_json.networks[chainId].Controller.address
    const ld = new LendingDataV2(controller_address, provider)
    const tmpData = await ld.getAccountTokens(account)
    return tmpData
  } catch (error) {
    console.log(error)
  }
}

// staking Contract interface
export const approve = async (tokenContract: Contract, poolContract: Contract, account: string) => {
  try {
    return tokenContract.methods
      .approve(poolContract.options.address, ethers.constants.MaxUint256)
      .send({ from: account })
  } catch (error) {
    return console.log(error)
  }
}
export const _getEarned = async (rewardsContract: Contract, account: string) => {
  try {
    return await rewardsContract?.methods.earned(account).call({ from: account });
  } catch (error) {
    // console.log(error)
    return undefined
  }
}

export const _getTotalStaked = async (rewardsContract: Contract, rewardsAddress: string) => {
  try {
    return await rewardsContract.methods.balanceOf(rewardsAddress).call();
  } catch (error) {
    return console.log(error)
  }
}

export const _getstakedBalance = async (rewardsContract: Contract, account: string) => {
  try {
    return await rewardsContract?.methods.balanceOf(account).call({ from: account });
  } catch (error) {
    return '0'
  }
}
export const _harvest = async (rewardsContract: Contract, account: string, lending: any) => {
  try {
    return rewardsContract.methods.getReward().send({ from: account, gasPrice: lending.contracts.web3.utils.toWei(await _getGasPrice(), 'gwei') })
  } catch (error) {
    return console.log(error)
  }
}

export const _getFishSwapstakedBalance = async (rewardsContract: Contract, account: string, pid: string) => {
  try {
    return await rewardsContract?.methods.userInfo(pid, account).call({ from: account });
  } catch (error) {
    return '0'
  }
}
export const _getEarned__double = async (rewardsContract: Contract, account: string, rewardToken: string) => {
  try {
    return await rewardsContract?.methods.getPendingRewardByToken(account, rewardToken).call({ from: account });
  } catch (error) {
    // console.log(error)
    return undefined
  }
}

export const _getEarnedVelo = async (rewardsContract: Contract, account: string, rewardToken: string,) => {
  try {
    return await rewardsContract?.methods.earned(rewardToken, account).call({ from: account });
  } catch (error) {
    // console.log(error)
    return undefined
  }
}
export const _getEarnedFishSwap = async (rewardsContract: Contract, pid: string, account: string) => {
  try {
    return await rewardsContract?.methods.pendingCake(pid, account).call({ from: account });
  } catch (error) {
    // console.log(error)
    return undefined
  }
}

interface gasDate {
  "health": boolean,
  "block_number": number,
  "slow": number,
  "standard": number,
  "fast": number,
  "instant": number,
  "block_time": number
}

// export const requestAPI = (url: string) => {
//   return new Promise<gasDate>((resolve, reject) => {
//     request(
//       {
//         url,
//         json: true,
//       },
//       (error, response, body) => {
//         if (error) {
//           reject(error);
//         } else {
//           resolve(body);
//         }
//       }
//     )
//   })
// };
export const multicall_target = {
  1: '0xcA11bde05977b3631167028862bE2a173976CA11',
  137: '0x11ce4B23bD875D7F5C6a31084f55fDe1e9A87507',
  56: '0x294Cf1d64599b5F56D63b3DBe461f985bc5e1254',
  10: '0x7e2Dc2b896b7AAc98D6ee8e954d3f5bDCC90076b',
  42161: '0xcA11bde05977b3631167028862bE2a173976CA11',
  1030: '0xF276c0F19C7619A61D0EbF06DB3EC7d98CA5B1F0',
  324: '0xF9cda624FBC7e059355ce98a31693d299FACd963',
  8453: '0xcA11bde05977b3631167028862bE2a173976CA11',
  11155111: '0xcA11bde05977b3631167028862bE2a173976CA11',
}
// multicall methods
export const multicall = async (web3: any, chainId: number, Lending: Contract, target: string, multicallAddress: string, calls_array: string[]) => {
  const config = {
    web3,
    multicallAddress
  };
  const calls = calls_array.map(item => {
    return {
      target,
      call: ['balanceOf(address)(uint256)', item],
      returns: [[`${item}`, (val: any) => val.toString()]]
    }
  })
  const result = await aggregate(calls, config);

  return result
}

// multicall methods
export const multicall_lsr = async (web3: any, multicallAddress: string, calls_array: Record<string, any>[]) => {
  const config = {
    web3,
    multicallAddress
  };
  const calls = calls_array.map(item => {
    // const returns = item.key.map((_item: any)=>{
    //   return [`${_item}`, (val: any) => val]
    // })
    return {
      target: item.target,
      call: item.call,
      // returns: [[`${item.key}`, (val: any) => val]]
      // returns:[returns]
      returns: item.returns ? item.returns : [[`${item.key}`, (val: any) => val]]
    }
  })
  // console.log(calls,config)
  try {
    const result = await aggregate(calls, config);

    return result
  } catch (error) {
    console.log(error)
  }

}
// transaction Notifiles & history List
export const add_Notifies = async (item: any, setNowNotifies: React.Dispatch<React.SetStateAction<any>>, account: string, chainId: number) => {
  let history: any = window.localStorage.getItem(`${account}`) ?? '{}'
  let history_data: any = JSON.parse(history)
  let local_data: any = window.localStorage.getItem('my_notify')
  local_data = local_data ? JSON.parse(local_data) : []

  local_data.push(item)
  // await nowNotifies.push(item);
  window.localStorage.setItem('my_notify', JSON.stringify(local_data))
  // 存储历史记录
  history_data[chainId] = history_data[chainId] ? [local_data[0], ...history_data[chainId]] : local_data
  window.localStorage.setItem(`${account}`, JSON.stringify(history_data))
  setNowNotifies(local_data);
};

export const add__cbridge = async (item: any, setNotifies: any) => {
  let local_data: any = window.localStorage.getItem('my_notify__cbridge')
  local_data = local_data ? JSON.parse(local_data) : []

  local_data.push(item)

  window.localStorage.setItem('my_notify__cbridge', JSON.stringify(local_data))
  setNotifies(local_data)
}

export const add_Notifies__bridge = async (
  item: any,
  setNowNotifies: React.Dispatch<React.SetStateAction<any>>,
  account: string,
  chainId: number,
) => {
  let local_data: any = window.localStorage.getItem('my_notify')
  local_data = local_data ? JSON.parse(local_data) : []

  local_data.push(item)

  window.localStorage.setItem('my_notify', JSON.stringify(local_data))
  setNowNotifies(local_data)
}

export const add_Notifies__bridge__addApprove = async (
  item: any,
  setNowNotifies: React.Dispatch<React.SetStateAction<any>>,
  account: string,
  chainId: number,
) => {
  let local_data: any = window.localStorage.getItem('my_notify')
  local_data = local_data ? JSON.parse(local_data) : []
  local_data.push(item)
  window.localStorage.setItem('my_notify', JSON.stringify(local_data))
  setNowNotifies(local_data)

  let history: any = window.localStorage.getItem(`${account}`) ?? '{}'
  let history_data: any = JSON.parse(history)
  history_data[chainId] = history_data[chainId] ? [local_data[0], ...history_data[chainId]] : local_data
  window.localStorage.setItem(`${account}`, JSON.stringify(history_data))
}

export const update_Notifies__bridge = async (
  receipt: any,
  setNowNotifies: React.Dispatch<React.SetStateAction<any>>,
  account: string, chainId: number
) => {
  let local_data: any = window.localStorage.getItem('my_notify')
  local_data = local_data ? JSON.parse(local_data) : []

  if (receipt.isTmp) {
    local_data.map((item: any) => {
      if (item.tmpId === receipt.tmpId) {
        item.state = "success"
        item.transactionHash = receipt.transactionHash
      }
    })

    window.localStorage.setItem('my_notify', JSON.stringify(local_data))
    setNowNotifies(local_data)
  } else {
    local_data.map((item: any) => {
      if (item.transactionHash === receipt.transactionHash) {
        if (receipt.status) {
          item.state = "success";
        } else {
          item.state = "fail";
        }
      }
    })

    window.localStorage.setItem('my_notify', JSON.stringify(local_data))
    setNowNotifies(local_data)
  }
}

export const update_Notifies = async (receipt: any, setNowNotifies: React.Dispatch<React.SetStateAction<any>>, account: string, chainId: number) => {
  let history: any = window.localStorage.getItem(`${account}`) ?? '{}'
  let history_data: any = JSON.parse(history)
  let local_data: any = window.localStorage.getItem('my_notify')
  local_data = local_data ? JSON.parse(local_data) : []

  local_data.map((item: any) => {
    if (item.transactionHash === receipt.transactionHash) {
      if (receipt.status) {
        item.state = "success";
      } else {
        item.state = "fail";
      }
    }
  });

  window.localStorage.setItem('my_notify', JSON.stringify(local_data))
  // 存储历史记录
  history_data[chainId].map((item: any) => {
    if (item.transactionHash === receipt.transactionHash) {
      if (receipt.status) {
        item.state = "success";
      } else {
        item.state = "fail";
      }
    }
  });
  window.localStorage.setItem(`${account}`, JSON.stringify(history_data))
  setNowNotifies(local_data);
}

export const update__cbridge = async (args: any, setNotifies: any) => {
  let local_data: any = window.localStorage.getItem('my_notify__cbridge')
  local_data = local_data ? JSON.parse(local_data) : []

  local_data.map((item: any) => {
    if (item.result === args.result) {
      // console.log(item)
      item.state = "success"
      item.transactionHash = item.receiveingChainId
        ? `${transationLink[item.receiveingChainId]}${args.transactionHash}`
        : args.transactionHash
    }
    // else {
    //   item.state = "fail"
    // }
  })

  window.localStorage.setItem('my_notify__cbridge', JSON.stringify(local_data))
  setNotifies(local_data)
}

export const del__cbridge = async (item: any, setNotifies: any) => {
  try {
    let local_data: any = window.localStorage.getItem('my_notify__cbridge')
    local_data = local_data ? JSON.parse(local_data) : []

    let new__notifies__items
    new__notifies__items = local_data.filter((notify: any) => {
      if (notify.result !== item.result) {
        return notify
      }
    })

    window.localStorage.setItem('my_notify__cbridge', JSON.stringify(new__notifies__items))
    setNotifies(new__notifies__items)
  } catch (err) {
    console.log(err)
  }
}

export const del_Notify = async (item: any, setNowNotifies: React.Dispatch<React.SetStateAction<any>>) => {
  try {
    let local_data: any = window.localStorage.getItem('my_notify')
    local_data = local_data ? JSON.parse(local_data) : []

    // let new__notifies__items = local_data.filter((notify: any) => {
    //   if (notify.transactionHash !== item.transactionHash) {
    //     return notify;
    //   }
    // })
    let new__notifies__items
    new__notifies__items = local_data.filter((notify: any) => {
      if (notify.transactionHash !== item.transactionHash) {
        return notify;
      }
    })

    // debugger
    window.localStorage.setItem('my_notify', JSON.stringify(new__notifies__items))
    setNowNotifies(new__notifies__items)
  } catch (err) {
    console.log(err)
  }
}
export const del__cancel = (setShow__cancel: React.Dispatch<React.SetStateAction<boolean>>) => {
  setShow__cancel(false);
}



interface PropsArgs {
  bridge_type: string,
  complete_src: boolean,
  complete_dst: boolean,
  chainId_src: number,
  chainId_dst: number,
  hash_src: string,
  hash_dst: string,
  action_send: string,
  action_receive: string,
  provider_src: any,
  provider_dst: any,
  time: number,
  baseURL?: string,
  requestOptions?: any
}

export const add__local = (account: string, argsItem: PropsArgs) => {
  // console.log('*** add__local add__local add__local')
  let local_data: any = window.localStorage.getItem(`${account}_my_bridge_history`)
  local_data = local_data ? JSON.parse(local_data) : []
  // console.log('*** local_data: ', local_data)
  // console.log('*** argsItem: ', argsItem)

  local_data.unshift(argsItem)
  // console.log('*** local_data: ', local_data)
  // console.log('*** local_data --- JSON.stringify: ', JSON.stringify(local_data))

  window.localStorage.setItem(`${account}_my_bridge_history`, JSON.stringify(local_data))
  // console.log('*** finish setItem')
}

export const update__local = (account: string, args: string, dst_block_tx_link?: string) => {
  let local_data: any = window.localStorage.getItem(`${account}_my_bridge_history`)
  local_data = local_data ? JSON.parse(local_data) : []

  local_data.map((item: any) => {
    if (item.bridge_type === 'dforce_bridge') {
      if (item.hash_dst === args) {
        item.complete_dst = true
      }
    }

    if (item.bridge_type === 'c_bridge') {
      if (item.hash_src === args) {
        item.complete_dst = true
        item.hash_dst = dst_block_tx_link
      }
    }

    if (item.bridge_type === 'uts_bridge') {
      if (item.hash_src === args) {
        item.complete_dst = true
        // item.hash_dst = dst_block_tx_link
        item.hash_dst = `${transationLink[item.chainId_dst]}${dst_block_tx_link}`
      }
    }
  })

  window.localStorage.setItem(`${account}_my_bridge_history`, JSON.stringify(local_data))
}