import BigNumber from "bignumber.js";
import {bnToDec,reverseCurrentChainAddressMap } from "utils";
import { network_Id,network_Name } from 'lending-sdk/utils/index1'
import useMarkets from "hooks/useMarkets"
// 读取 assetsToken 配置文件
import liquidity_rewardsJson from "lending-sdk/lib/clean_build/contracts/Liquidity/rewards.json";

export const fetchLiquidity = async (_ChainId,_account) => {
  let reverseTokenMap,liquidityList=[],earned,rewardData,earnedArb,rewardDataArb;

  reverseTokenMap = { ...reverseCurrentChainAddressMap(liquidity_rewardsJson,_ChainId) }
  // try {
  //   const res = await requestAPI(`https://beta.unitus.finance/reward/iTokenStakedInfo?network=${network_Name[_ChainId]}`)
  //   if(res.status === 'OK'){
  //     if(res?.data?.iTokenStakedList?.length>0){
  //       liquidityList = res.data.iTokenStakedList
  //     }
  //   }
  // } catch (error) {
  //   console.log(error)
  // }

  try {
    const response = await fetch(`https://beta.unitus.finance/reward/iTokenStakedInfo?network=${network_Name[_ChainId]}`)
    const res = await response.json();
    if(res.status === 'OK'){
      if(res?.data?.iTokenStakedList?.length>0){
        liquidityList = res.data.iTokenStakedList
      }
    }
  } catch (error) {
    console.log(error)
  }

  // try {
  //   const res = await requestAPI(`https://beta.unitus.finance/reward/earnedInfo?network=${network_Name[_ChainId]}&user=${_account}`)
  //   if(res?.status === 'OK'){
  //     earned = res?.data
  //   }
  // } catch (error) {
  //   console.log(error)
  // }

  try {
    const response = await fetch(`https://beta.unitus.finance/reward/earnedInfo?network=${network_Name[_ChainId]}&user=${_account}`)
    const res = await response.json();
    if(res?.status === 'OK'){
      earned = res?.data
    }
  } catch (error) {
    console.log(error)
  }

  // try {
  //   let res = await requestAPI(`https://beta.unitus.finance/reward/rewardInfo?network=${network_Name[_ChainId]}`)
  //   if(res?.status === 'OK'){
  //     rewardData = res?.data
  //   }
  // } catch (error) {
  //   console.log(error)
  // }

  try {
    const response = await fetch(`https://beta.unitus.finance/reward/rewardInfo?network=${network_Name[_ChainId]}`)
    const res = await response.json();
    if(res?.status === 'OK'){
      rewardData = res?.data
    }
  } catch (error) {
    console.log(error)
  }

  if(_ChainId === 5 || _ChainId === 42161){
    // try {
    //   const res = await requestAPI(`https://beta.unitus.finance/rewardArb/earnedInfo?network=${network_Name[_ChainId]}&user=${_account}`)
    //   if(res?.status === 'OK'){
    //     earnedArb = res?.data
    //   }
    // } catch (error) {
    //   console.log(error)
    // }
    try {
      const response = await fetch(`https://beta.unitus.finance/rewardArb/earnedInfo?network=${network_Name[_ChainId]}&user=${_account}`)
      const res = await response.json();
      if(res?.status === 'OK'){
        earnedArb = res?.data
      }
    } catch (error) {
      console.log(error)
    }
  
    // try {
    //   let res = await requestAPI(`https://beta.unitus.finance/rewardArb/rewardInfo?network=${network_Name[_ChainId]}`)
    //   if(res?.status === 'OK'){
    //     rewardDataArb = res?.data
    //   }
    // } catch (error) {
    //   console.log(error)
    // }

    try {
      const response = await fetch(`https://beta.unitus.finance/rewardArb/rewardInfo?network=${network_Name[_ChainId]}`)
      const res = await response.json();
      if(res?.status === 'OK'){
        rewardDataArb = res?.data
      }
    } catch (error) {
      console.log(error)
    }
  }
  



  // let markets = await requestAPI(`https://beta.unitus.finance/general/markets?network=${network_Name[_ChainId]}`)


  let localConfig_alltoken = []
  for (let [k, v] of Object.entries(reverseTokenMap)) {
    localConfig_alltoken.push({
      address: k,
      ...v
    })
  }

  const liquidityDate = localConfig_alltoken.map((item) => {
    let icon,_SupplyRewardAPY,_BorrowRewardAPY,_SupplyRewardAPYArb,_BorrowRewardAPYArb;
    const crruteItem = liquidityList.filter(cru => cru.Address === item.address)[0];
        const currentItemReward = rewardData?.tokenList?.filter(fetchItem => fetchItem.Address === item.address)[0]
        if(currentItemReward){
            _SupplyRewardAPY = new BigNumber(currentItemReward?.SupplyRewardAPY).multipliedBy(new BigNumber(10).pow(18).plus(new BigNumber(rewardData?.magnification))).dividedBy(new BigNumber(10).pow(18));
            _BorrowRewardAPY = new BigNumber(currentItemReward?.BorrowRewardAPY).multipliedBy(new BigNumber(10).pow(18).plus(new BigNumber(rewardData?.magnification))).dividedBy(new BigNumber(10).pow(18));
        }
        const currentItemRewardArb = rewardDataArb?.tokenList?.filter(fetchItem => fetchItem.Address === item.address)[0]
        if(currentItemRewardArb){
          _SupplyRewardAPYArb = new BigNumber(currentItemRewardArb?.SupplyRewardAPY).multipliedBy(new BigNumber(10).pow(18).plus(new BigNumber(rewardDataArb?.magnification))).dividedBy(new BigNumber(10).pow(18));
          _BorrowRewardAPYArb = new BigNumber(currentItemRewardArb?.BorrowRewardAPY).multipliedBy(new BigNumber(10).pow(18).plus(new BigNumber(rewardDataArb?.magnification))).dividedBy(new BigNumber(10).pow(18));
        }
    try {
      icon = require(`assets/Liquidity/${item.symbol}.svg`);
    } catch (error) {
      icon = require(`assets/tokenList/default.svg`);
    }
    return {
      reveal_symbol: item.symbol,
      poolSymbol: item.poolSymbol,
      decimals: item.decimals,
      icon,
      address: item.address,
      LPAddress:item.LPAddress,
      // chainIcon,
      poolType:item?.poolType,
      poolType2:item?.poolType2,
      assetsType:item?.assetsType,
      pid:item?.pid,
      heat:item?.heat,
      Link:item?.Link,
      LinkTo:item?.LinkTo,
      TradeLink:item?.TradeLink,
      ...crruteItem,
      ...currentItemReward,
      SupplyRewardAPY:_SupplyRewardAPY,
      BorrowRewardAPY:_BorrowRewardAPY,
      SupplyRewardAPYArb:_SupplyRewardAPYArb,
      BorrowRewardAPYArb:_BorrowRewardAPYArb,
      MinimumSupplyRewardAPYArb:currentItemRewardArb?.MinimumSupplyRewardAPY,
      MinimumBorrowRewardAPYArb:currentItemRewardArb?.MinimumBorrowRewardAPY,
      DailyDistributionArb:currentItemRewardArb?.DailyDistribution,
      DistributedArb:currentItemRewardArb?.Distributed
    }
  })

  // let res_threshold
  // try {
  //   res_threshold = await requestAPI(`https://beta.unitus.finance/reward/earnedInfo?network=${network_Name[_ChainId]}&user=${_account}`)
  // } catch (error) {
  //   res_threshold = undefined
  // }
  // .filter(it => it).sort((a,b)=>Number(b.totalApy) - Number(a.toatalApy))
  // console.log(liquidityDate)
  return { liquidityDate,localConfig_alltoken,threshold:earned?.data?.threshold,earned,rewardData,earnedArb,rewardDataArb }
}
