/*
 * @Author: your name
 * @Date: 2020-12-01 19:04:42
 * @LastEditTime: 2021-07-27 14:09:52
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \web-frontd:\a\curly-telegram\web-front\src\contexts\LendingProvider\LendingProvider.tsx
 */
import React, { createContext, useEffect, useState } from 'react'
import {
  useWeb3React
} from "@web3-react/core";
import { Lending } from 'lending-sdk/lib'

export interface LendingContext {
  lending?: any
}

export const Context = createContext<LendingContext>({
  lending: undefined,
})

declare global {
  interface Window {
    Lendingsauce: any
  }
}

const LendingProvider = ({ children }: {
  children: React.ReactNode
}) => {
  const {
    connector,
    account,
    chainId,
    provider,
    ENSName,
    isActive,
    isActivating,
  } = useWeb3React();
  window.localStorage.removeItem('my_notify')
  chainId && window.localStorage.setItem("chainId",chainId.toString())
  // debugger
  // if(connector && !(connector instanceof WalletConnectConnector)){
  //   window.location.reload()
  // }
  // const {active} = useWeb3React('NETWORK')
  // const { ethereum, chainId, account, connect, connector, status,reset } = useWallet()
  const [lending, setLending] = useState<any>()
  useEffect(() => {
    if (provider) {
      const LendingLib = new Lending(
        provider,
        chainId ?? 1,
        {
          defaultAccount: account,
          defaultConfirmations: 1,
          autoGasMultiplier: 1.5,
          testing: false,
          defaultGas: "6000000",
          defaultGasPrice: "1000000000000",
          accounts: [],
          ethereumNodeTimeout: 15000
        }
      )
      setLending(LendingLib)
      window.Lendingsauce = LendingLib
    }else{
      setLending(undefined)
    }
  }, [provider,account,chainId,connector])

  return (
    <Context.Provider value={{ lending }}>
      {children}
    </Context.Provider>
  )
}

export default LendingProvider
