import React, { useCallback, useState, useEffect } from "react";
import { useParams, useNavigate, useLocation, NavLink as RouterLink } from "react-router-dom";
import styled from "styled-components";
import { useIntl } from "react-intl";
import BigNumber from "bignumber.js";
import numeral from "numeral";
import { bnToDec, formate_round_up, _formatNumberNotCarry, numberFormatter, format_bn, formatTimeStamp } from "utils";
import ReactApexChart from "react-apexcharts";
// import { fetchMarkets } from "./fetchMarkets";
// import { Market } from "contexts/MarketOverview/types";
import { fetchAPY } from "../SupplyWithdraw/fetchAPY";
import useLending from "hooks/useLending";
import { StyledChart, OverSafeMAX, StyledBool, InputWrap } from 'styledComponents/MulStyled'
import FlexBoxWrap from "components/FlexBoxWrap"
import img_eth from "assets/Bridge/net-eth.svg"
import img_arbitrum from "assets/Bridge/net-arbitrum.svg"
import img_op from "assets/Bridge/net-op.svg"
import img_bsc from "assets/Bridge/net-bsc.svg"
import img_polygon from "assets/Bridge/Polygon.svg"
// import img_kava from "assets/Bridge/KAVA.svg"
import img_ConfluxTest from "assets/Bridge/net-conflux.svg"
import img_view_to from 'assets/supply/View.svg'
import { network_Id, etherscan } from 'lending-sdk/utils/index1'
import general_DL_Token from 'lending-sdk/lib/clean_build/contracts/general/DL_Token.json';


const Markets: React.FC = () => {
  const intl = useIntl();
  const fmt = intl.formatMessage;
  const { currentNetwork, curToken } = useParams<any>();
  const { pathname } = useLocation();
  const navigate = useNavigate()
  const [currentLogo, setCurrentLogo] = useState(null)
  const [baseData, setBaseData] = useState<any>({})
  const Lending = useLending()

  const [chartState_supply, setChartState_supply] = useState<any>({
    options: {
      chart: {
        toolbar: {
          show: false,
          tools: {
            download: false,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
          },
        },
        height: 350,
        type: "line",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 2,
      },
      xaxis: {
        type: "category",
        categories: [],
        min: null,
        tickAmount: 4,
        tickPlacement: "on",
        labels: {
          rotate: 0,
          style: {
            colors: "#9195A4",
          },
        },
      },
      // fill: {
      //   type: 'gradient',
      //   colors: ['#ff0000'],
      //   opacity: 0.9,
      //   // type: "image",
      //   // gradient: {
      //   //   shadeIntensity: 1,
      //   //   inverseColors: false,
      //   //   opacityFrom: 0.6,
      //   //   opacityTo: 0.45,
      //   //   stops: [25, 100, 100, 100],
      //   // },
      // },
      grid: {
        // show: true,
        // borderColor: '#ff0000',
        // strokeDashArray: 0,
        // position: 'back',
        // row: {
        //   colors: ['#999999'],
        //   opacity: 0
        // }
      },
      yaxis: {
        labels: {
          formatter: function (value: any) {
            return value.toFixed(2) + "%";
          },
          style: {
            colors: "#9195A4",
          },
        },
      },
      colors: ['#FF764B'],
    },
    series: [{
      name: "APY",
      data: []
    }]
  })
  const [chartState_borrow, setChartState_borrow] = useState<any>({
    series: [
      {
        name: "APY",
        data: [],
      },
    ],
    options: {
      chart: {
        toolbar: {
          show: false,
          tools: {
            download: false,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
          },
        },
        height: 350,
        type: "line",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 2,
      },
      xaxis: {
        type: "category",
        categories: [],
        min: null,
        tickAmount: 4,
        tickPlacement: "on",
        labels: {
          rotate: 0,
          style: {
            colors: "#9195A4",
          },
        },
      },
      fill: {
        type: "image",
        gradient: {
          shadeIntensity: 1,
          inverseColors: false,
          opacityFrom: 0.6,
          opacityTo: 0.55,
          stops: [20, 100, 100, 100],
        },
      },
      yaxis: {
        labels: {
          formatter: function (value: any) {
            return value.toFixed(2) + "%";
          },
          style: {
            colors: "#9195A4",
          },
        },
      },
      colors: ["#05C7A0"],
    },
  })
  const [chartState_pie, setChartState_pie] = useState<any>({
    series: [0],
    options: {
      chart: {
        height: 350,
        type: 'radialBar',
      },
      plotOptions: {
        radialBar: {
          // 底环
          track: {
            show: true,
            background: 'rgb(34, 34, 34)',
            strokeWidth: '100%',
          },
          // 空洞
          hollow: {
            size: '45%',
            background: 'transparent',
          },
          dataLabels: {
            show: true,
            value: {
              show: true,
              fontSize: '14px',
              fontWeight: 400,
              color: '#00BC8F',
              offsetY: -10,
              formatter: function (val: any) {
                return val + '%'
              }
            }
          }
        },
      },
      // labels: ['Cricket'],
      labels: [''],
      // labels: 'none',
    }
  })

  const NetMap = {
    mainnet: {
      net: 'Ethereum',
      icon: img_eth
    },
    Goerli: {
      net: 'Goerli',
      icon: img_eth
    },
    ArbitrumOne: {
      net: 'Arbitrum',
      icon: img_arbitrum
    },
    Optimism: {
      net: 'Optimism',
      icon: img_op
    },
    bsc: {
      net: 'BSC',
      icon: img_bsc
    },
    Polygon: {
      net: 'Polygon',
      icon: img_polygon
    },
    ConfluxTest: {
      net: 'ConfluxTest',
      icon: img_ConfluxTest
    },
    ConfluxeSpace: {
      net: 'Conflux eSpace',
      icon: img_ConfluxTest
    },
    Sepolia: {
      net: 'Sepolia',
      icon: img_eth
    }
  }

  const fetchApi_supply = useCallback(async () => {
    // console.log(currentNetwork,network_Id[currentNetwork as string],curToken)
    const echartsAPY =
      (await fetchAPY(
        network_Id[currentNetwork as string],
        // Lending.contracts?.general["i" + curToken]?.address,
        general_DL_Token.networks[network_Id[currentNetwork as string]]["i" + curToken]?.address,
        'supply'
      ))
    // console.log(echartsAPY)
    const seriesData = echartsAPY.length > 0 ?
      echartsAPY.map((item: any) => format_bn(item.apy, 16, 2)) : [];
    const categories = echartsAPY.length > 0 ?
      echartsAPY.map((item: any) => { return formatTimeStamp(new Date(parseInt(item.timeStamp) * 1000), "MM/dd") }) : [];
    // console.log(seriesData,categories)
    setChartState_supply({
      series: [
        {
          name: "APY",
          // data: [31, 40, 38, 79]
          data: seriesData,
        },
      ],
      options: {
        chart: {
          toolbar: {
            show: false,
            tools: {
              download: false,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
            },
          },
          height: 350,
          type: "line",
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: 2,
        },
        xaxis: {
          type: "category",
          categories,
          min: echartsAPY && (echartsAPY.length > 1 ? echartsAPY[0]?.timeStamp : null),
          tickAmount: 4,
          tickPlacement: "on",
          labels: {
            rotate: 0,
            style: {
              colors: "#9195A4",
            },
          },
        },
        // fill: {
        //   type: 'gradient',
        //   colors: ['#ff0000'],
        //   opacity: 0.9,
        //   // type: "image",
        //   // gradient: {
        //   //   shadeIntensity: 1,
        //   //   inverseColors: false,
        //   //   opacityFrom: 0.6,
        //   //   opacityTo: 0.45,
        //   //   stops: [25, 100, 100, 100],
        //   // },
        // },
        grid: {
          borderColor: '#999999',
          // borderColor: '#fff000',
        },
        yaxis: {
          labels: {
            formatter: function (value: any) {
              return value.toFixed(2) + "%";
            },
            style: {
              colors: "#9195A4",
            },
          },
        },
        colors: ['#FF764B'],
      },
    });
  }, [curToken, currentNetwork])


  const fetchApi_borrow = useCallback(async () => {
    const echartsAPY =
      (await fetchAPY(
        network_Id[currentNetwork as string],
        // Lending.contracts?.general["i" + curToken].address,
        general_DL_Token.networks[network_Id[currentNetwork as string]]["i" + curToken]?.address,
        'borrow'
      ));
    const seriesData =
      echartsAPY.length > 0
        ? echartsAPY.map((item: any) => format_bn(item.apy, 16, 2))
        : [];
    const categories =
      echartsAPY.length > 0
        ? echartsAPY.map((item: any) => {
          return formatTimeStamp(
            new Date(parseInt(item.timeStamp) * 1000),
            "MM/dd"
          );
        })
        : [];
    setChartState_borrow({
      series: [
        {
          name: "APY",
          // data: [31, 40, 28, 51, 42, 109, 100]
          data: seriesData,
        },
      ],
      options: {
        chart: {
          toolbar: {
            show: false,
            tools: {
              download: false,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
            },
          },
          height: 350,
          type: "line",
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: 2,
        },
        xaxis: {
          type: "category",
          categories,
          min:
            echartsAPY &&
            (echartsAPY.length > 1 ? echartsAPY[0]?.timeStamp : null),
          tickAmount: 4,
          tickPlacement: "on",
          labels: {
            rotate: 0,
            style: {
              colors: "#9195A4",
            },
          },
        },
        fill: {
          type: "image",
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.6,
            opacityTo: 0.55,
            stops: [20, 100, 100, 100],
          },
        },
        grid: {
          borderColor: '#999999',
          // borderColor: '#fff000',
        },
        yaxis: {
          labels: {
            formatter: function (value: any) {
              return value.toFixed(2) + "%";
            },
            style: {
              colors: "#9195A4",
            },
          },
        },
        colors: ["#05C7A0"],
      },
    })
  }, [curToken, currentNetwork])


  useEffect(() => {
    if (Lending && curToken && currentNetwork) {
      fetchApi_supply()
      fetchApi_borrow()
    }
  }, [Lending, curToken, currentNetwork])




  useEffect(() => {
    if (curToken) {
      let icon
      try {
        icon = require(`assets/tokenList/${curToken}.svg`)
      } catch (error) {
        icon = require(`../../assets/tokenList/default.svg`)
      }
      setCurrentLogo(icon)
    }
  }, [curToken])

  useEffect(() => {
    if (currentNetwork && curToken) {
      const getBannarData = () => {
        const api = `https://beta.unitus.finance/general/marketsV2?network=${currentNetwork}`
        fetch(api, { method: 'get' })
          .then(response => response.json())
          .then((res) => {
            res.marketsList &&
              res.marketsList.length > 0 &&
              res.marketsList.map((tokenItem: any) => {
                if (curToken === tokenItem.underlying_symbol) {
                  setBaseData(tokenItem)
                  if (tokenItem.debtCeiling && Number(tokenItem.debtCeiling) > 0) {
                    const oriBN = new BigNumber(tokenItem.currentDebt)
                      .multipliedBy(new BigNumber(1e18))
                      .div(new BigNumber(tokenItem.debtCeiling))
                    const per = format_bn(oriBN.toString(), 16, 2)
                    // console.log(per)
                    setChartState_pie({
                      series: [Number(per)],
                      // series: [90],
                      options: {
                        chart: {
                          height: 350,
                          type: 'radialBar',
                        },
                        colors: ["#00BC8F"],
                        plotOptions: {
                          radialBar: {
                            // 底环
                            track: {
                              show: true,
                              background: 'rgb(34, 34, 34)',
                              strokeWidth: '100%',
                            },
                            // 空洞
                            hollow: {
                              size: '45%',
                              background: 'transparent',
                            },
                            dataLabels: {
                              show: true,
                              value: {
                                show: true,
                                fontSize: '14px',
                                fontWeight: 400,
                                color: '#00BC8F',
                                offsetY: -10,
                                formatter: function (val: any) {
                                  return val + '%'
                                }
                              }
                            }
                          },
                        },
                        // labels: ['Cricket'],
                        labels: [''],
                        stroke: {
                          lineCap: "round",
                        },
                        // labels: 'none',
                      },
                    })
                  }
                }
              })
          })
      }

      getBannarData()
      let t = setInterval(getBannarData, 1000 * 60)
      return () => clearInterval(t)
    }
  }, [currentNetwork, curToken])




  return (
    <>
      <FlexBoxWrap>
        <AssetWrap>
          <Crumb>
            <span>
              <RouterLink to={`/Markets/${currentNetwork}`}>
                <BackToHome>{fmt({ id: "Back" })}</BackToHome>
              </RouterLink>
            </span>

            <NetType>
              <img src={NetMap[currentNetwork as string].icon} alt="" />
              <span>{currentNetwork}</span>
            </NetType>
          </Crumb>

          <AssetsWarp>
            <AssetsItem>
              <TokenInfo>
                {currentLogo && <img className="logo" src={currentLogo} alt="" />}
                <span>{curToken}</span>
                <StyledView
                  target='_blank'
                  href={`${etherscan?.[network_Id?.[currentNetwork as string]] + Lending?.contracts?.general[curToken as string]?.address}`}>
                  <img src={img_view_to} alt="" />
                </StyledView>
              </TokenInfo>
            </AssetsItem>

            <AssetsItem>
              <h1>
                {baseData.totalSupply ? '$' + numberFormatter(format_bn(baseData.totalSupply, 18, 2), 2) : '...'}
              </h1>
              <p>
                <span>{'Total Supply'}</span>
              </p>
            </AssetsItem>

            <AssetsItem>
              <h1>
                {baseData.totalBorrows ? '$' + numberFormatter(format_bn(baseData.totalBorrows, 18, 2), 2) : '...'}
              </h1>
              <p>
                <span>{'Total Borrow'}</span>
              </p>
            </AssetsItem>

            <AssetsItem>
              <h1>
                {baseData.cash ? '$' + numberFormatter(format_bn(baseData.cash, 18, 2), 2) : '...'}
              </h1>
              <p>
                <span>{'Liquidity'}</span>
              </p>
            </AssetsItem>

            <AssetsItem>
              <h1>
                {
                  baseData.totalSupply &&
                    baseData.totalBorrows
                    ? format_bn(
                      new BigNumber(baseData.totalBorrows)
                        .multipliedBy(new BigNumber(1e18))
                        .div(new BigNumber(baseData.totalSupply))
                        .toFixed(0)
                        .toString()
                      , 16, 2
                    ) + "%"
                    : '...'
                }
              </h1>
              <p>
                <span>{'Utilization Rate'}</span>
              </p>
            </AssetsItem>

            <AssetsItem>
              <h1>
                {baseData.price ? '$' + numberFormatter(format_bn(baseData.price, 18, 2), 2) : '...'}
              </h1>
              <p><span>{'Price'}</span></p>
            </AssetsItem>
          </AssetsWarp>
        </AssetWrap>
      </FlexBoxWrap>



      <FlexBoxWrap>
        <BlockStyle>
          <Title>Supply Info</Title>
          <Content>
            <SubTitle>Risk Parameters</SubTitle>
            <ItemWrap>
              <Item>
                <Value>{format_bn(baseData.collateralFactor, 16, 2) + '%'}</Value>
                <ValueDetail>LTV</ValueDetail>
              </Item>
              <Item>
                <Value>{format_bn(baseData.liquidationThreshold, 16, 2) + '%'}</Value>
                <ValueDetail>Liquidation threshold</ValueDetail>
              </Item>
              <Item>
                <Value>{format_bn(new BigNumber(baseData.liquidationIncentive).minus(new BigNumber(1e18)).toString(), 16, 2) + '%'}</Value>
                <ValueDetail>Liquidation penalty</ValueDetail>
              </Item>
            </ItemWrap>
          </Content>

          {
            baseData &&
            Number(baseData.sModeID) > 0 &&
            <Content>
              <SubTitle>SuperCharged Risk Parameters</SubTitle>
              <ItemWrap>
                <Item>
                  <Value>{format_bn(baseData.sModeLTV, 16, 2) + '%'}</Value>
                  <ValueDetail>LTV</ValueDetail>
                </Item>
                <Item>
                  <Value>{format_bn(baseData.sModeLiquidationThreshold, 16, 2) + '%'}</Value>
                  <ValueDetail>Liquidation threshold</ValueDetail>
                </Item>
                <Item>
                  <Value>{format_bn(new BigNumber(baseData.sModeLiquidationIncentive).minus(new BigNumber(1e18)).toString(), 16, 2) + '%'}</Value>
                  <ValueDetail>Liquidation penalty</ValueDetail>
                </Item>
              </ItemWrap>
            </Content>
          }
          {
            baseData &&
            new BigNumber(baseData.debtCeiling).gt(new BigNumber(0)) &&
            <Content className="no-p-left">
              <PieWrap>
                <ReactApexChart
                  options={chartState_pie.options}
                  series={chartState_pie.series}
                  type="radialBar"
                  height={180} />
                <RightAmount>
                  <TopValue>
                    {numberFormatter(format_bn(baseData.currentDebt, 2, 2), 2)}
                    {' / '}
                    {numberFormatter(format_bn(baseData.debtCeiling, 2, 2), 2)}
                  </TopValue>
                  <TopValueText>Segregated Debt Ceiling</TopValueText>
                </RightAmount>
              </PieWrap>
            </Content>
          }

          <Content>
            <TopBox>
              <TopBoxSon className="border">
                <ChartDetail>
                  {'Supply Cap: '}
                  <span>{numberFormatter(format_bn(baseData.supplyAmount, 18, 2), 2)}</span>
                  {' / '}
                  <span>{numberFormatter(format_bn(baseData.supplyCapacity, 18, 2), 2)}</span>
                </ChartDetail>
                <ChartDetail>
                  {'Supply APY: '}
                  <span className="Green">{format_bn(baseData.supplyAPY, 16, 2)}%</span>
                </ChartDetail>
              </TopBoxSon>
            </TopBox>
            <StyledChart>
              <ReactApexChart
                options={chartState_supply.options}
                series={chartState_supply.series}
                type="area"
                height={320}
              />
            </StyledChart>
          </Content>


          <Title className="padding">Borrow Info</Title>
          <Content>
            <TopBox>
              <TopBoxSon>
                <ChartDetail>
                  {'Reserve Ratio: '}
                  <span>{format_bn(baseData.reserveRatio, 16, 2)}%</span>
                </ChartDetail>
                <ChartDetail>
                  {'Borrow Cap: '}
                  <span>{numberFormatter(format_bn(baseData.borrowAmount, 18, 2), 2)}</span>
                  {' / '}
                  <span>{numberFormatter(format_bn(baseData.borrowCapacity, 18, 2), 2)}</span>
                </ChartDetail>
                <ChartDetail>
                  {'Borrow APY: '}
                  <span className="Red">{format_bn(baseData.borrowAPY, 16, 2)}%</span>
                </ChartDetail>
              </TopBoxSon>
            </TopBox>
            <StyledChart>
              <ReactApexChart
                options={chartState_borrow.options}
                series={chartState_borrow.series}
                type="area"
                height={320}
              />
            </StyledChart>
          </Content>
        </BlockStyle>
      </FlexBoxWrap>
    </>
  )
}


const PieWrap = styled.div`
  /* width: 200px; */
  /* background-color: red; */
  display: flex;
  align-items: center;
  @media (max-width: 1119px) {
    margin-left: -60px;
  }
`
const RightAmount = styled.div`
  margin-left: -40px;
  @media (max-width: 1119px) {
  }
`
const TopValue = styled.div`
  font-size: 18px;
  color: #333;
  font-weight: 700;
  margin-bottom: 8px;
  .dark & {
    color:#FFFFFF;
  }
  @media (max-width: 1119px) {
  }
`
const TopValueText = styled.div`
  font-size: 14px;
  color:rgb(153, 153, 153);
  @media (max-width: 1119px) {
  }
`

const ChartDetail = styled.div`
  min-width: 200px;
  font-size: 14px;
  font-weight: 500;
  margin-right: 65px;
  color:rgb(153, 153, 153);
  span {
    font-size: 16px;
    font-weight: 600;
    color: #333;
    .dark & {
      color:#FFFFFF;
    }
    &.Green {
      color: #00BC8F;
    }
    &.Red {
      color: #FF3F3F;
    }
  }
  @media (max-width: 1119px) {
    width: auto;
    font-size: 14px;
    font-weight: 500;
    margin-right: 0;
  }
`
const TopBox = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 32px;
  @media (max-width: 1119px) {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 16px;
  }
`
const TopBoxSon = styled.div`
  display: flex;
  border: none;
  &.border {
    border-top: 1px solid #eeeeee99;
    padding-top: 24px;
    .dark & {
      border-top: 1px solid #FFFFFF14;
    }
  }
  @media (max-width: 1119px) {
    flex-direction: column;
    line-height: 24px;
    &.border {
      padding-top: 12px;
    }
  }
`
const Content = styled.div`
  padding-left: 150px;
  @media (max-width: 1119px) {
    padding-left: 30px;
  }
  &.no-p-left {
    padding-left: 70px;
    @media (max-width: 1119px) {
      padding-left: 0px;
    }
  }
`
const SubTitle = styled.div`
  padding-left: 20px;
  margin-top: 24px;
  margin-bottom: 16px;
  font-size: 16px;
  color: #333;
  font-weight: 700;
  .dark & {
    color:#FFFFFF;
  }
  @media (max-width: 1119px) {
    padding-left: 10px;
    margin-top: 12px;
    margin-bottom: 12px;
    font-size: 14px;
  }
`
const ItemWrap = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  padding-right: 24px;
  padding-left: 20px;
  @media (max-width: 1119px) {
    gap: 10px;
    padding-right: 12px;
    padding-left: 10px;
  }
`
const Item = styled.div`
  flex: 1;
  border: 1px solid #cccccc99;
  padding: 16px;
  border-radius: 6px;
  .dark & {
    border: 1px solid rgba(255, 255, 255, 0.08);
  }
  @media (max-width: 1119px) {
    padding: 8px;
    border-radius: 4px;
  }
`
const Value = styled.div`
  font-size: 18px;
  color:#333;
  margin-bottom: 8px;
  font-weight: 600;
  .dark & {
    color:#FFFFFF;
  }
  @media (max-width: 1119px) {
    font-size: 16px;
    margin-bottom: 4px;
  }
`
const ValueDetail = styled.div`
  color:rgb(153, 153, 153);
  font-size: 14px;
  @media (max-width: 1119px) {
    font-size: 12px;
  }
`
const Title = styled.div`
  font-size: 18px;
  font-weight: 700;
  color: #333;
  .dark & {
    color:#FFFFFF;
  }
  &.padding {
    margin-top: 24px;
    padding-right: 20px;
    border-top: 1px solid #eeeeee99;
    padding-top: 24px;
    .dark & {
      border-top: 1px solid #FFFFFF14;
    }
  }
  @media (max-width: 1119px) {
    font-size: 16px;
      &.padding {
        margin-top: 12px;
        padding-right: 10px;
        border-top: 1px solid #eeeeee99;
        padding-top: 12px;
      }
  }
`
const StyledView = styled.a`
  img {
    margin-left: 12px;
    width: 20px;
  }
  @media (max-width: 1199px) {
    img {
      margin-left: 12px;
      width: 16px;
    }
  }
`
const Crumb = styled.div`
  display:flex;
  justify-content:space-between;
  align-items:center;
  width: 70px;
  height:32px;
  margin-top: 44px;
  margin-bottom: 36px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.08);
  padding-left: 8px;
  @media(max-width: 1199px) {
    margin-top: 20px;
  }
`
const TokenInfo = styled.div`
  display: flex;
  align-items: center;
  span {
    margin-left: 12px;
    font-size: 16;
    font-weight: 600;
  }
  img.logo {
    width: 40px;
  }
  @media (max-width: 1199px) {
    img.logo {
      width: 24px;
    }
  }
`
const NetType = styled.div`
  display: flex;
  align-items: center;
  margin-left: 28px;
  img {
    width: 32px;
  }
  span {
    font-weight: 600;
    margin-left: 8px;
    color: #fff;
  }
  @media (max-width: 1199px) {
    img {
      width: 22px;
    }
  }
`
const BackToHome = styled.b`
  display:flex;
  align-items:center;
  font-size:16px;
  height:30px;
  line-height:30px;
  color: #fff;
  font-weight: 500;
  &:before {            
    content: '';            
    display: block;
    width: 8px;            
    height: 8px; 
    margin-right:5px;      
    border-right: 2px solid ${props => props.theme.colors.lightGrey};            
    border-top: 2px solid ${props => props.theme.colors.lightGrey};            
    -webkit-transform: rotate(-135deg); /*箭头方向可以自由切换角度*/            
    transform: rotate(-135deg);
}
`
const BlockStyle = styled.div`
  color:#ffffff;
  margin: 16px 0;
  padding: 24px 0 24px 24px;
  border-radius: 12px;
  background:${(props) => props.theme.TableBg};
  @media (max-width: 1199px) {
    margin: 16px 0;
    border-radius:10px;
  }
  .dark & {
    color: ${(props) => props.theme.colors.white};
    background:${(props) => props.theme.DarkTableBg};
  }
`
const AssetWrap = styled.div`
  height: 224px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 1199px) {
    height: auto;
    padding: 16px 0;
  }
`
const AssetsWarp = styled.div`
  width: 100%;
  /* height: 120px; */
  display: flex;
  color:#ffffff;
  @media (max-width: 1199px) {
    flex-wrap: wrap;
  }
`
const AssetsItem = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 70px;
  box-sizing: border-box;
  @media (max-width: 1199px) {
    margin-right: 30px;
  }
  h1 {    
    color: #FFF;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    @media (max-width: 1199px) {
      font-size: 16px;
      line-height: 28px;
      margin-top: 10px;
      margin-bottom: 0px;
    }
  }
  p {
    color: ${(props) => props.theme.global_grey};
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    font-style: normal;
    @media (max-width: 1199px) {
      font-size: 12px;
      line-height: 12px;
    }
  }
`

export default Markets
