import React, { useCallback, useEffect, useState, useReducer } from "react";
import { useLocation } from "react-router-dom"
import BigNumber from 'bignumber.js'
import { useWeb3React } from '@web3-react/core'
import { bnToDec, objToJsonArr, JSON_objToArray, underlyingAddressMap, useQuery, isObject, reverseBLPCurrentChainAddressMap } from 'utils';
import { factor } from 'constants/constants.js';
import numeral from "numeral";
import useLending from "hooks/useLending";
// 读取 assetsToken 配置文件
import ERC20abi from "abis/iToken.json"
import { ethers } from "ethers"
import { LendingDataV2 } from 'lendingData/gen'
import controller_json from 'lending-sdk/lib/clean_build/contracts/general/Controller.json'
import general_DL_Token from 'lending-sdk/lib/clean_build/contracts/general/DL_Token.json';
import synthetic_DL_Token from 'lending-sdk/lib/clean_build/contracts/synthetic/DL_Token.json';
import stocks_DL_Token from 'lending-sdk/lib/clean_build/contracts/stocks/DL_Token.json';
import general_underlying_Token from 'lending-sdk/lib/clean_build/contracts/general/IERC20.json';
import synthetic_underlying_Token from 'lending-sdk/lib/clean_build/contracts/synthetic/IERC20.json';
import stocks_underlying_Token from 'lending-sdk/lib/clean_build/contracts/stocks/IERC20.json';
import stake_rewardsJson from "lending-sdk/lib/clean_build/contracts/stake/rewards.json";
import Context from "./Context";
import { MarketsDate, Market, Assets, AssetsPortfolio, BLPDate } from "./types";
import {
  network_Name,
  getAccountTotalValue,
  getAccountSupplyTokens,
  getAccountBorrowTokens,
  getAccountMSDTokens,
  multicall_lsr,
  multicall_target
} from "lending-sdk/utils/index1";

declare global {
  interface Window {
    IntervalMarkets: any;
    abortController: any;
  }
}
const deployAssets = {
  "general": general_DL_Token,
  "synthetic": synthetic_DL_Token,
  "stocks": stocks_DL_Token
}
interface TokenMap {
  LPAddress: string,
  symbol: string,
  poolSymbol: string,
  assetsType: string,
  poolType: string,
  poolType2: string,
  pid: string,
  heat: string,
  decimals: string,
  hasLocked: string,
  Link: string,
  LinkTo: string,
  TradeLink: string
}
const Provider = ({ children }: {
  children: React.ReactNode
}) => {
  let totalValue, earnData: any, earnDataArb: any,
    liquidityList: any = [],
    iUSXStaked: BigNumber = new BigNumber(0),
    reverseTokenMap: TokenMap,
    rewardData: any = {
      "magnification": "0",
      "threshold": "0",
      "tokenList": []
    },
    rewardDataArb: any = {
      "magnification": "0",
      "threshold": "0",
      "tokenList": []
    },
    supplyTokenList: any, borrowTokenList: any, mintTokenList: any, status: number, underlyingTokenMap: any, currentChainId: number;
  // 获取router URLParams
  let query = useQuery()
  const { pathname } = useLocation();
  const [marketsListEffect, setMarketsList] = useState<Market[]>([]);
  const [mintMarketsList, setmintMarketsList] = useState<Market[]>([]);
  const [markets_supplyValue, setMarkets_supplyValue] = useState('');
  const [markets_borrowValue, setMarkets_borrowValue] = useState('');
  const [markets_mintValue, setMarkets_mintValue] = useState('');
  const [totalAssetsList, setTotalAssetsList] = useState<Assets>({});
  const initBLP = { title: 'MyBLPStake', value: undefined, dollar: true, thresholdStatus: undefined, needed: undefined }
  const [BLP, setBLP] = useState<BLPDate>(initBLP);
  const [allList, setAllList] = useState<AssetsPortfolio[]>([]);
  const [allList_supply, setAllList_supply] = useState<AssetsPortfolio[]>([]);
  const [allList_borrow, setAllList_borrow] = useState<AssetsPortfolio[]>([]);
  const [allSyntheticList, setAllSyntheticList] = useState<AssetsPortfolio[]>([]);
  const [blockNumber_link, setBlockNumber_link] = useState<string>('#')
  const [blockNumber, setBlockNumber] = useState<string>('...')
  const lending = useLending();
  const {
    connector,
    account,
    chainId,
    provider,
    ENSName,
    isActive,
    isActivating,
  } = useWeb3React();
  // const account = "0xEe594e1f937aD55a682Baf84f857b0F393641C22"
  // const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
  const initMarketsList = {
    marketsList: [],
    totalSupplyValue: '',
    totalBorrowsValue: '',
    totalMintValue: '',
    allList_supply: '',
    allList_borrow: '',
  }
  const fetchMarketsList = useCallback(async (chain_Id: number = 1) => {
    console.log(chain_Id)
    return new Promise<MarketsDate>(
      async (resolve: (value: { marketsList: Market[], totalSupplyValue: string, totalBorrowsValue: string, totalMintValue: string }) => void, reject) => {
        try {
          // const data = await requestAPI<MarketsDate>(`https://beta.unitus.finance/general/markets?network=${network_Name[chain_Id]}`)
          const response = await fetch(`https://beta.unitus.finance/general/marketsV2?network=${network_Name[chain_Id]}`)
          const data = await response.json();
          isObject(data) ? resolve(data) : resolve(initMarketsList)
        } catch (error) {
          console.log("fetch markets api" + error)
          resolve(initMarketsList)
        }
      }
    );
  }, []);

  // 获取 Interest Earned & Interest Accrued
  // const fetchEarned = useCallback(async (chain_Id: number = 1, _account) => {
  //   return new Promise<Earned[]>(
  //     async (resolve, reject) => {
  //       try {
  //         const data = await requestAPI<Earned[]>(`https://beta.unitus.finance/general/users?network=${network_Name[chain_Id]}&address=${_account}`)
  //         Array.isArray(data) ? resolve(data) : resolve([])
  //       } catch (error) {
  //         console.log("fetch interest Earned" + error)
  //         resolve([]);
  //       }
  //     }
  //   );
  // }, []);

  // 获取 current BlockNumber
  // const fetchBlockNumber = useCallback(async (chain_Id: number = 1) => {
  //   return new Promise<BlockNumberDate>(
  //     // (resolve:(value: { Earned:Earned[] }) => void, reject) => {
  //     async (resolve, reject) => {
  //       try {
  //         // if(chainId && blockLink[chainId]){
  //         const data = await requestAPI<BlockNumberDate>(`https://beta.unitus.finance/general/blockStatus?network=${network_Name[chain_Id]}`)
  //         resolve(data)
  //       } catch (error) {
  //         console.log("fetch BlockNumber" + error)
  //         const data = await requestAPI<BlockNumberDate>(`https://beta.unitus.finance/general/blockStatus?network=mainnet`)
  //         resolve(data)
  //       }
  //     }
  //   );
  // }, []);

  const MarketsCallback = useCallback(async () => {
    if (lending?.contracts?.networkId === chainId && account) {
      await fetch(`https://beta.unitus.finance/reward/rewardInfo?network=${network_Name[chainId as number] ?? 'mainnet'}`)
        .then(response => response.json())
        .then(response => {
          // console.log('supplyMaining:', response);
          if (response && response.status === 'OK') {
            rewardData = response?.data
          } else {
            rewardData = {
              "magnification": "0",
              "threshold": "0",
              "tokenList": []
            }
          }
        })
        .catch(error => console.error('Error:', error));
      if ((chainId === 5) || (chainId === 42161)) {
        await fetch(`https://beta.unitus.finance/rewardArb/rewardInfo?network=${network_Name[chainId as number]}`)
          .then(response => response.json())
          .then(response => {
            // console.log('supplyMaining:', response);
            if (response && response.status === 'OK') {
              rewardDataArb = response?.data
            } else {
              rewardDataArb = {
                "magnification": "0",
                "threshold": "0",
                "tokenList": []
              }
            }
          })
          .catch(error => console.error('Error:', error));
        await fetch(`https://beta.unitus.finance/rewardArb/earnedInfo?network=${network_Name[chainId as number] ?? 'mainnet'}&user=${account}`)
          .then(response => response.json())
          .then(response => {
            if (response && response.status === 'OK') {
              earnDataArb = response?.data
            } else {
              earnDataArb = {

              }
            }
          })
          .catch(error => console.error('Error:', error))
      }
      await fetch(`https://beta.unitus.finance/reward/earnedInfo?network=${network_Name[chainId as number] ?? 'mainnet'}&user=${account}`)
        .then(response => response.json())
        .then(response => {
          if (response && response.status === 'OK') {
            earnData = response?.data
          } else {
            earnData = {

            }
          }
        })
        .catch(error => console.error('Error:', error))

      try {
        // const res:any = await requestAPI(`https://beta.unitus.finance/reward/iTokenStakedInfo?network=${network_Name[chainId as number]}`)
        const response = await fetch(`https://beta.unitus.finance/reward/iTokenStakedInfo?network=${network_Name[chainId as number]}`)
        const res = await response.json();
        if (res.status === 'OK' && chainId === 42161) {
          if (res?.data?.iTokenStakedList?.length > 0) {
            liquidityList = res.data.iTokenStakedList
            try {
              const multical_params = [
                {
                  target: lending.contracts['liquidity'][`riUSX`]?.address,
                  call: ['balanceOf(address)(uint256)', account],
                  key: 'iUSXStaked'
                }
              ]
              const res = await multicall_lsr(lending?.contracts?.web3, multicall_target[chainId as number], multical_params)
              if (!res) return;
              const hasiUSX = liquidityList.filter(((item: any) => item?.Address === lending.contracts['liquidity'][`riUSX`]?.address))[0]
              if (hasiUSX) {
                iUSXStaked = new BigNumber(new BigNumber(res.results.transformed.iUSXStaked).multipliedBy(hasiUSX?.Price).div(new BigNumber(10).pow(18)).toFixed(0));
              }
            } catch (error) {
              console.log(error)
            }
          }
        }
      } catch (error) {
        console.log(error)
      }

      totalValue = await getAccountTotalValue(window?.ethereum, chainId, account);
      supplyTokenList = await getAccountSupplyTokens(window?.ethereum, chainId, account);
      borrowTokenList = await getAccountBorrowTokens(window?.ethereum, chainId, account);
      mintTokenList = await getAccountMSDTokens(window?.ethereum, chainId, account);
      try {
        // const res:any = await requestAPI(`https://beta.unitus.finance/reward/stakedInfo?network=${network_Name[chainId as number]}`)
        const response = await fetch(`https://beta.unitus.finance/reward/stakedInfo?network=${network_Name[chainId as number]}`)
        const res = await response.json();
        if (res.status === 'OK' && chainId === 42161) {
          if (res?.data?.TotalStakePrePair?.length > 0 && chainId && rewardData?.threshold) {
            const BLPstakeList = res.data.TotalStakePrePair
            reverseTokenMap = { ...reverseBLPCurrentChainAddressMap(stake_rewardsJson, chainId as number) }
            let localConfig_alltoken = Object.entries(reverseTokenMap).map(item => {
              const re = item[1]
              return {
                address: item[0],
                ...re
              }
            })
            const BLPStake = await Promise.all(BLPstakeList.map(async (item: { Address: string; Symbol: string; Price: BigNumber.Value; }) => {
              let TotalStaked = '...', TotalStakedValue = '...', MyStaked = '...', MystakedValue = '...'
              const crruteItem = localConfig_alltoken.filter(cru => cru.LPAddress === item.Address)[0];
              if (crruteItem) {
                const multical_params = [
                  {
                    target: lending.contracts['stake'][`r${crruteItem.symbol}`]?.address,
                    call: ['balanceOf(address)(uint256)', account],
                    key: 'MyStaked'
                  },
                  {
                    target: lending.contracts['stake'][`r${crruteItem.symbol}`]?.address,
                    call: ['totalSupply()(uint256)'],
                    key: 'TotalStaked'
                  }
                ]

                const res = await multicall_lsr(lending?.contracts?.web3, multicall_target[chainId], multical_params)
                if (!res) return;
                MyStaked = res.results.transformed.MyStaked;
                TotalStaked = res.results.transformed.TotalStaked;
                TotalStakedValue = new BigNumber(res.results.transformed.TotalStaked).multipliedBy(item.Price).div(new BigNumber(10).pow(18)).toFixed(0).toString();
                MystakedValue = new BigNumber(res.results.transformed.MyStaked).multipliedBy(item.Price).div(new BigNumber(10).pow(18)).toFixed(0).toString();
                return {
                  TotalStakedValue,
                  MystakedValue,
                }
              }
            }))
            let MyStakeValue: BigNumber = new BigNumber(0), TotalStakeValue: BigNumber = new BigNumber(0), thresholdStatus, needed
            BLPStake.map((item: any) => {
              MyStakeValue = new BigNumber(MyStakeValue).plus(new BigNumber(item?.MystakedValue))
              TotalStakeValue = new BigNumber(TotalStakeValue).plus(new BigNumber(item?.TotalStakedValue))
            })
            const thresholdValue:any =totalValue?.[0] && new BigNumber(
              new BigNumber(totalValue[0].toString())
                .plus(iUSXStaked)
                .multipliedBy(new BigNumber(rewardData?.threshold))
                .div(new BigNumber(10)
                  .pow(18))
                .toFixed(0)
            )
            if (isNaN(Number(TotalStakeValue)) || isNaN(Number(MyStakeValue))) {
              return;
            }
            // console.log(thresholdValue.toString(),totalAssets?.totalValue?.[0],iUSXStaked.toString())
            if (
              totalValue?.[0]&&
              new BigNumber(totalValue[0].toString()).plus(iUSXStaked).lte(new BigNumber(0))
            ) {
              thresholdStatus = 0
            } else {
              // console.log("MyStakeValue",MyStakeValue.dividedBy(new BigNumber(10).pow(18)).toString())
              // console.log("thresholdValue",thresholdValue.dividedBy(new BigNumber(10).pow(18)).toString())
              if (MyStakeValue.lte(new BigNumber(thresholdValue.toString()))) {
                thresholdStatus = 1
                // setPercent(_formatNumberNotCarry(bnToDec(MyStakeValue.multipliedBy(new BigNumber(10).pow(18)).div(new BigNumber(totalAssets?.totalValue?.[0])), 18,4),'percent'))
                needed = thresholdValue.minus(MyStakeValue)
              } else {
                thresholdStatus = 2
                // setPercent(_formatNumberNotCarry(bnToDec(MyStakeValue.multipliedBy(new BigNumber(10).pow(18)).div(new BigNumber(totalAssets?.totalValue?.[0])), 18,4),'percent'))
                needed = thresholdValue
              }
            }
            setBLP({ title: 'MyBLPStake', value: isNaN(Number(MyStakeValue)) ? undefined : MyStakeValue.toString(), dollar: true, thresholdStatus, needed })
          }
        }
      } catch (error) {
        console.log(error)
      }
    } else {
      totalValue = null;
      supplyTokenList = null;
      borrowTokenList = null;
    }

    let newAssets: Assets = {
      totalValue,
      supplyTokenList,
      borrowTokenList,
      mintTokenList,
    };
    // console.log(newAssets)
    // setTotalAssetsList(newAssets);
    setTotalAssetsList(newAssets);

    if (chainId) {
      underlyingTokenMap = {
        "general": underlyingAddressMap(general_underlying_Token, chainId),
        "synthetic": underlyingAddressMap(synthetic_underlying_Token, chainId),
        "stocks": underlyingAddressMap(stocks_underlying_Token, chainId)
      }
      currentChainId = chainId
    } else {
      underlyingTokenMap = {
        "general": underlyingAddressMap(general_underlying_Token, 1),
        "synthetic": underlyingAddressMap(synthetic_underlying_Token, 1),
        "stocks": underlyingAddressMap(stocks_underlying_Token, 1)
      }
      currentChainId = 1
    }

    const { marketsList, totalSupplyValue, totalBorrowsValue, totalMintValue } = await fetchMarketsList(currentChainId as number);
    // debugger
    let localConfig_itoken = deployAssets.general['networks'][`${currentChainId}`]
    localConfig_itoken = localConfig_itoken ? JSON_objToArray(localConfig_itoken, 'assetsType', false) : []



    // all token list
    // const provider = new ethers.providers.Web3Provider(lending.web3.currentProvider)
    const provider = new ethers.providers.Web3Provider(window?.ethereum)
    const controller_address = controller_json.networks[chainId as number].Controller.address
    const ld = new LendingDataV2(controller_address, provider)

    const newMarkets: Market[] = marketsList && await Promise.all(
      localConfig_itoken.map(async (item: any) => {
        let icon, underlying_address, balance = '...', _SupplyRewardAPY, _BorrowRewardAPY, _SupplyRewardAPYArb, _BorrowRewardAPYArb

        const currentItem = marketsList.filter(fetchItem => item.address === fetchItem.address)[0]
        const currentItemReward = rewardData?.tokenList?.filter((fetchItem: any) => fetchItem.Address === item.address)[0]
        if (currentItemReward) {
          _SupplyRewardAPY = new BigNumber(currentItemReward?.SupplyRewardAPY).multipliedBy(new BigNumber(10).pow(18).plus(new BigNumber(rewardData?.magnification))).dividedBy(new BigNumber(10).pow(18));
          _BorrowRewardAPY = new BigNumber(currentItemReward?.BorrowRewardAPY).multipliedBy(new BigNumber(10).pow(18).plus(new BigNumber(rewardData?.magnification))).dividedBy(new BigNumber(10).pow(18));
        }

        // reward arb
        const currentItemRewardArb = rewardDataArb?.tokenList?.filter((fetchItem: any) => fetchItem.Address === item.address)[0]
        if (currentItemRewardArb) {
          _SupplyRewardAPYArb = new BigNumber(currentItemRewardArb?.SupplyRewardAPY).multipliedBy(new BigNumber(10).pow(18).plus(new BigNumber(rewardDataArb?.magnification))).dividedBy(new BigNumber(10).pow(18));
          _BorrowRewardAPYArb = new BigNumber(currentItemRewardArb?.BorrowRewardAPY).multipliedBy(new BigNumber(10).pow(18).plus(new BigNumber(rewardDataArb?.magnification))).dividedBy(new BigNumber(10).pow(18));
        }
        // reward arb
        underlying_address = underlyingTokenMap?.general[item.symbol]?.address

        const iToken_address = item?.address;
        const AsCollateralDetails = await ld.getAccountSupplyInfo(iToken_address, account as string, factor)
        // console.log(iToken_address)
        // console.log(item.symbol,' - executed: ',AsCollateralDetails[2])
        // console.log(lending.contracts?.general.Controller.methods.marketsV2)
        const marketsV2 = await lending.contracts?.general.Controller.methods
          .marketsV2(iToken_address)
          .call()
        // console.log(item.symbol, ':', marketsV2.borrowableInSegregation)
        // console.log(item.underlying_symbol)
        // const marketsV2 = {
        //   collateralFactorMantissa: '',
        //   borrowFactorMantissa: '',
        //   borrowCapacity: '',
        //   supplyCapacity: '',
        //   mintPaused: '',
        //   redeemPaused: '',
        //   borrowPaused: '',
        //   eModeID: '',  // (uint8)
        //   borrowableInSegregation: '',
        //   debtCeiling: item.symbol === 'UNI' || item.symbol === 'stETH' ? 222 : 0,
        //   currentDebt: '',
        // }

        try {
          icon = require(`assets/tokenList/${item.symbol}.svg`);
          // icon = require(`assets/tokenList/USDT.svg`);
        } catch (error) {
          // icon = require(`assets/tokenList/DF.svg`);
          // icon = require(`assets/tokenList/default.svg`);
          // icon = require(`assets/tokenList/${'default'}.svg`);
          icon = require(`../../assets/tokenList/default.svg`);
          // icon = require(`../../assets/tokenList/default.svg`).default;
        }
        // if(){[object Module]}
        // const isDeploy = reverseTokenMap[`${query.get('currentPool')}`][item.address] ? true : false;
        if (lending?.contracts?.networkId === chainId && account) {
          const token = new lending.web3.eth.Contract(ERC20abi, underlying_address)
          // console.log(underlying_address)
          // console.log(token)
          if (
            underlying_address !== '0x0000000000000000000000000000000000000000'
          ) {
            balance = (
              bnToDec(new BigNumber(await token.methods.balanceOf(account).call()), Number(currentItem?.decimals))
                .toString()
            ) ?? '...'
            // balance = (await token.methods.balanceOf(account).call()) ?? '...'
            // if (
            //   item.symbol === 'ARB' ||
            //   item.symbol === 'MAI' ||
            //   item.symbol === 'rETH'
            // ) {
            //   console.log(underlyingTokenMap?.general[item.symbol], balance)
            // }
          } else {
            balance = (
              bnToDec(new BigNumber(await lending.contracts.web3.eth.getBalance(account)), Number(currentItem?.decimals))
                .toString()
            ) ?? '...'
          }
          // console.log(balance)

          /*
              status = 0  supply 显示 borrow 置灰
              status = 1  supply 显示 borrow 显示
              status = 2  supply 显示 withdraw 显示
              status = 3  borrow 显示 repay 显示
          */
          // Collateral Balance 为0
          if (newAssets?.totalValue?.[1] === "0") {
            if (newAssets?.supplyTokenList) {
              if (newAssets.supplyTokenList[0].length === 0) {
                status = 0
              } else {
                if (newAssets.supplyTokenList[0].includes(item.address)) {
                  status = 2
                } else {
                  status = 0
                }
              }
            }
          } else {
            //Collateral Balance 有值
            if (newAssets?.supplyTokenList && newAssets?.borrowTokenList) {
              if (newAssets.supplyTokenList[0].includes(item.address)) {
                if (newAssets.borrowTokenList[0].includes(item.address)) {
                  status = 1
                } else {
                  status = 2
                }
              } else {
                if (newAssets.borrowTokenList[0].includes(item.address)) {
                  status = 3
                } else {
                  status = 1
                }
              }
            }
          }
        } else {
          balance = '...'
        }
        return {
          reveal_symbol: item.symbol,
          assetsType: item.assetsType,
          tokenType: item.tokenType,
          underlying_address,
          // Msdtoken_itoken_pairing,
          icon,
          balance,
          status,
          ...currentItem,
          ...currentItemReward,
          SupplyRewardAPY: _SupplyRewardAPY,
          BorrowRewardAPY: _BorrowRewardAPY,
          AsCollateralDetails: AsCollateralDetails,
          DailyDistribution: currentItemReward?.DailyDistribution,
          MinimumSupplyRewardAPYArb: currentItemRewardArb?.MinimumSupplyRewardAPY,
          MinimumBorrowRewardAPYArb: currentItemRewardArb?.MinimumBorrowRewardAPY,
          SupplyRewardAPYArb: _SupplyRewardAPYArb,
          BorrowRewardAPYArb: _BorrowRewardAPYArb,
          DailyDistributionArb: currentItemRewardArb?.DailyDistribution,
          isFreeze: item.isFreeze ?? false,
          marketsV2,
          isSegregationAssert: AsCollateralDetails[4] ?? false,
        }
      })
    );
    // console.log("new Markets",newMarkets)

    //mintMarketsList
    let localConfig_imtoken = deployAssets?.general.networks[`${currentChainId}`]
    localConfig_imtoken = localConfig_imtoken ? JSON_objToArray(localConfig_imtoken, 'assetsType', true) : []

    // console.log("newMintMarkets",newMintMarkets)
    // console.log("newMintMarkets", newMintMarkets)

    if ((localStorage.getItem('account') === account) || !account) {
      // setTotalAssetsList(newAssets);
      setMarketsList(newMarkets);
      setMarkets_supplyValue(totalSupplyValue)
      setMarkets_borrowValue(totalBorrowsValue)
      setMarkets_mintValue(totalMintValue)
    } else {
      setBLP(initBLP)
      setTotalAssetsList({})
      setMarketsList([])
      setMarkets_supplyValue("")
      setMarkets_borrowValue("")
      setMarkets_mintValue("")
    }
    // 封装 supply Assets Portfolio

    let localConfig_alltoken = deployAssets?.general['networks'][`${currentChainId}`]
    localConfig_alltoken = localConfig_alltoken ? JSON_objToArray(localConfig_alltoken) : []
    let supplyJsonArr = objToJsonArr(supplyTokenList)


    // *** account supply list ***
    const newSupplyList = await Promise.all(
      supplyJsonArr.map(async (item, index) => {
        let _userAPR: string = '0', _userAPRArb: string | undefined = undefined;
        const crruteToken: any = marketsList.filter(cru => cru.address === item.address)[0];
        // console.log(crruteToken)
        let icon
        let symbol = localConfig_alltoken.filter((cru: any) => cru.address === item.address)[0]['symbol']
        try {
          icon = require(`assets/tokenList/${symbol}.svg`);
        } catch (error) {
          icon = require(`assets/tokenList/default.svg`);
        }
        const balance = bnToDec(new BigNumber(item.balance), Number(item.decimals));
        const supply_balance: any = item.balance;
        const iToken_address = crruteToken?.address;

        // const marketsV2 = await lending.contracts?.general.Controller.methods
        // .marketsV2(iToken_address).call()

        //   Token Value ($)(uint256)  //  accountSupplyInfo[0]
        //   As Collateral (bool) (true: Already used as collateral; false: Not as collateral)  accountSupplyInfo[1]
        //   executed (bool) (true: Can be executed; false: Not executable)  accountSupplyInfo[2]
        //   markets_closed  //  accountSupplyInfo[3]
        //   is iso assert (bool)
        const AsCollateralDetails = await ld.getAccountSupplyInfo(iToken_address, account as string, factor)
        // console.log(AsCollateralDetails)


        const marketsV2 = {
          collateralFactorMantissa: '',
          borrowFactorMantissa: '',
          borrowCapacity: '',
          supplyCapacity: '',
          mintPaused: '',
          redeemPaused: '',
          borrowPaused: '',
          eModeID: '',  // (uint8)
          borrowableInSegregation: '',
          debtCeiling: symbol === 'UNI' || symbol === 'stETH' ? 111 : 0,
          currentDebt: '',
        }

        //   USDx Price (uint256)  //  supplyTokenData[2]
        let supplyTokenData
        let account_supply_value
        try {
          supplyTokenData = await ld.getSupplyTokenData(iToken_address, account as string)
          // console.log('eee: ', supplyTokenData[2].toString() , supply_balance.toString())
          account_supply_value = supplyTokenData && supplyTokenData[2] && supply_balance
            ? new BigNumber(supplyTokenData[2].toString())
              .multipliedBy(new BigNumber(supply_balance.toString()))
              .dividedBy(new BigNumber(10 ** 18))
              .toFixed(0)
              .toString()
            : '0'
          // console.log('eee: ', account_supply_value)
        } catch (error) {
          console.log('eee: ', error)
          account_supply_value = '0'
        }

        // 计算user UTS APR
        // 门槛计算  userTotalSupply * threshold <=  weightingValue
        const isWeightingToken = earnData?.rewardInfo?.[item.address]
        if (!isWeightingToken || new BigNumber(earnData?.BLPStakedValue).lt(new BigNumber(newAssets?.totalValue[0]).plus(iUSXStaked).multipliedBy(new BigNumber(rewardData?.threshold)).dividedBy(new BigNumber(10).pow(18)).toFixed(0)) || new BigNumber(earnData?.rewardInfo?.[item.address]?.["supplyRewardRate"]).eq(0) || new BigNumber(earnData?.rewardInfo?.[item.address]?.["weightingSupplyValue"]).eq(0)) {
          _userAPR = '0'
        } else {
          // _userAPR
          let userWeightingValue: string;
          if (new BigNumber(earnData?.weightingValue).gte(new BigNumber(account_supply_value).multipliedBy(new BigNumber(rewardData?.magnification)).dividedBy(new BigNumber(10).pow(item.decimals)).toFixed(0))) {
            userWeightingValue = new BigNumber(account_supply_value).plus(new BigNumber(account_supply_value).multipliedBy(new BigNumber(rewardData?.magnification).dividedBy(new BigNumber(10).pow(18)))).dividedBy(new BigNumber(10).pow(item.decimals)).multipliedBy(new BigNumber(10).pow(18)).toString()
          } else {
            userWeightingValue = new BigNumber(account_supply_value).multipliedBy(new BigNumber(10).pow(18)).dividedBy(new BigNumber(10).pow(item.decimals)).plus(new BigNumber(earnData?.weightingValue)).toString()
          }
          // 此token总分发价值 (supplyRewardRate * 365 *rewardPrice)
          const tokenSupplyReward = new BigNumber(earnData?.rewardInfo?.[item.address]?.["supplyRewardRate"]).multipliedBy(new BigNumber(365)).multipliedBy(new BigNumber(earnData?.rewardPrice)).dividedBy(new BigNumber(10).pow(18));
          // 此token每1美金一年分发多少美金的 preTokenRewardValue = tokenSupplyReward / weightingSupplyValue
          const preTokenRewardValue = tokenSupplyReward.multipliedBy(new BigNumber(10).pow(18)).dividedBy(new BigNumber(earnData?.rewardInfo?.[item.address]?.["weightingSupplyValue"]).toFixed(0));
          // 用户一年总分发 userTokenRewardValue = preTokenRewardValue * weightingSupplyValue
          const userTokenRewardValue = new BigNumber(preTokenRewardValue).multipliedBy(new BigNumber(userWeightingValue)).div(new BigNumber(10).pow(18)).toFixed(0)
          _userAPR = new BigNumber(userTokenRewardValue).multipliedBy(new BigNumber(10).pow(item.decimals)).div(new BigNumber(account_supply_value)).toFixed(0);
        }
        // 计算user UTS APR
        if (chainId === 5 || chainId === 42161) {
          // 计算user ARB APR
          // 门槛计算  userTotalSupply * threshold <=  weightingValue
          const isWeightingTokenArb = earnDataArb?.rewardInfo?.[item.address]
          if (!isWeightingTokenArb || new BigNumber(earnDataArb?.BLPStakedValue).lt(new BigNumber(newAssets?.totalValue[0]).plus(iUSXStaked).multipliedBy(new BigNumber(rewardDataArb?.threshold)).dividedBy(new BigNumber(10).pow(18)).toFixed(0)) || new BigNumber(earnDataArb?.rewardInfo?.[item.address]?.["supplyRewardRate"]).eq(0) || new BigNumber(earnDataArb?.rewardInfo?.[item.address]?.["weightingSupplyValue"]).eq(0)) {
            _userAPRArb = '0'
          } else {
            // _userAPR
            let userWeightingValue: string;
            if (new BigNumber(earnDataArb?.weightingValue).gte(new BigNumber(account_supply_value).multipliedBy(new BigNumber(rewardDataArb?.magnification)).dividedBy(new BigNumber(10).pow(item.decimals)).toFixed(0))) {
              userWeightingValue = new BigNumber(account_supply_value).plus(new BigNumber(account_supply_value).multipliedBy(new BigNumber(rewardDataArb?.magnification).dividedBy(new BigNumber(10).pow(18)))).dividedBy(new BigNumber(10).pow(item.decimals)).multipliedBy(new BigNumber(10).pow(18)).toString()
            } else {
              userWeightingValue = new BigNumber(account_supply_value).multipliedBy(new BigNumber(10).pow(18)).dividedBy(new BigNumber(10).pow(item.decimals)).plus(new BigNumber(earnDataArb?.weightingValue)).toString()
            }
            // 此token总分发价值 (supplyRewardRate * 365 *rewardPrice)
            const tokenSupplyReward = new BigNumber(earnDataArb?.rewardInfo?.[item.address]?.["supplyRewardRate"]).multipliedBy(new BigNumber(365)).multipliedBy(new BigNumber(earnDataArb?.rewardPrice)).dividedBy(new BigNumber(10).pow(18));
            // 此token每1美金一年分发多少美金的 preTokenRewardValue = tokenSupplyReward / weightingSupplyValue
            const preTokenRewardValue = tokenSupplyReward.multipliedBy(new BigNumber(10).pow(18)).dividedBy(new BigNumber(earnDataArb?.rewardInfo?.[item.address]?.["weightingSupplyValue"]).toFixed(0));
            // 用户一年总分发 userTokenRewardValue = preTokenRewardValue * weightingSupplyValue
            const userTokenRewardValue = new BigNumber(preTokenRewardValue).multipliedBy(new BigNumber(userWeightingValue)).div(new BigNumber(10).pow(18)).toFixed(0)
            _userAPRArb = new BigNumber(userTokenRewardValue).multipliedBy(new BigNumber(10).pow(item.decimals)).div(new BigNumber(account_supply_value)).toFixed(0);
          }
          // 计算user ARB APR
        }

        return {
          id: 'supply' + crruteToken?.underlying_symbol,
          action: 'supply',
          symbol: crruteToken?.symbol,
          underlying_symbol: crruteToken?.underlying_symbol,
          reveal_symbol: symbol,
          name: crruteToken?.name,
          decimals: item.decimals,
          address: item.address,
          supplyAPY: crruteToken?.supplyAPY,
          borrowAPY: crruteToken?.borrowAPY,
          supplyValue: numeral(bnToDec(new BigNumber(crruteToken?.supplyValue), 18)).format("$0.00a").toLocaleUpperCase(),
          borrowValue: numeral(bnToDec(new BigNumber(crruteToken?.borrowValue), 18)).format("$0.00a").toLocaleUpperCase(),
          account_supply_amount: supply_balance,
          account_supply_value: account_supply_value,
          userAPR: _userAPR,
          userAPRArb: _userAPRArb,
          icon: icon,
          balance: balance,
          AsCollateralDetails: AsCollateralDetails,
          marketsV2,
          isSegregationAssert: AsCollateralDetails[4] ?? false,
          // earned,
          // AsCollateralDetials
        };
      })
    )
    // 封装 borrow Assets Portfolio
    let borrowJsonArr = objToJsonArr(borrowTokenList)
    const newBorrowList = await Promise.all(
      borrowJsonArr.map(async (item, index) => {
        let _userAPR: string = '0', _userAPRArb: string | undefined = undefined
        const crruteToken: any = marketsList.filter(cru => cru.address === item.address)[0]
        let icon
        let symbol = localConfig_alltoken.filter((cru: any) => cru.address === item.address)[0]['symbol'];
        try {
          icon = require(`assets/tokenList/${symbol}.svg`);
        } catch (error) {
          icon = require(`assets/tokenList/default.svg`);
        }
        const balance = bnToDec(new BigNumber(item.balance), Number(item.decimals))

        // const iToken_symbol = crruteToken?.symbol;
        // const iToken_address = lending?.contracts?.general[iToken_symbol].address;
        const iToken_address = crruteToken?.address;

        let supplyTokenData
        let account_borrow_value
        try {
          const provider = new ethers.providers.Web3Provider(lending.web3.currentProvider)
          const controller_address = controller_json.networks[chainId as number].Controller.address
          const ld = new LendingDataV2(controller_address, provider)
          supplyTokenData = await ld.getSupplyTokenData(iToken_address, account as string)
          account_borrow_value = supplyTokenData && supplyTokenData[2] && item.balance
            ? new BigNumber(supplyTokenData[2].toString())
              .multipliedBy(new BigNumber(item.balance.toString()))
              .dividedBy(new BigNumber(10 ** 18))
              .toFixed(0)
              .toString()
            : '0'
        } catch (error) {
          account_borrow_value = '0'
        }
        // 计算user UTS APR
        // 门槛计算  userTotalSupply * threshold <=  weightingValue
        const isWeightingToken = earnData?.rewardInfo?.[item.address]
        if (!isWeightingToken || new BigNumber(earnData?.BLPStakedValue).lt(new BigNumber(newAssets?.totalValue[0]).plus(iUSXStaked).multipliedBy(new BigNumber(rewardData?.threshold)).dividedBy(new BigNumber(10).pow(18)).toFixed(0)) || new BigNumber(earnData?.rewardInfo?.[item.address]?.["borrowRewardRate"]).eq(0) || new BigNumber(earnData?.rewardInfo?.[item.address]?.["weightingBorrowValue"]).eq(0)) {
          _userAPR = '0'
        } else {
          // _userAPR
          let userWeightingValue: string;
          if (new BigNumber(earnData?.weightingValue).gte(new BigNumber(account_borrow_value).multipliedBy(new BigNumber(rewardData?.magnification)).dividedBy(new BigNumber(10).pow(item.decimals)).toFixed(0))) {
            userWeightingValue = new BigNumber(account_borrow_value).plus(new BigNumber(account_borrow_value).multipliedBy(new BigNumber(rewardData?.magnification).dividedBy(new BigNumber(10).pow(18)))).dividedBy(new BigNumber(10).pow(item.decimals)).multipliedBy(new BigNumber(10).pow(18)).toString()
          } else {
            userWeightingValue = new BigNumber(account_borrow_value).multipliedBy(new BigNumber(10).pow(18)).dividedBy(new BigNumber(10).pow(item.decimals)).plus(new BigNumber(earnData?.weightingValue)).toString()
          }
          // 此token总分发价值 (supplyRewardRate * 365 *rewardPrice)
          const tokenSupplyReward = new BigNumber(earnData?.rewardInfo?.[item.address]?.["borrowRewardRate"]).multipliedBy(new BigNumber(365)).multipliedBy(new BigNumber(earnData?.rewardPrice)).dividedBy(new BigNumber(10).pow(18));
          // 此token每1美金一年分发多少美金的 preTokenRewardValue = tokenSupplyReward / weightingSupplyValue
          const preTokenRewardValue = tokenSupplyReward.multipliedBy(new BigNumber(10).pow(18)).dividedBy(new BigNumber(earnData?.rewardInfo?.[item.address]?.["weightingBorrowValue"]).toFixed(0));
          // 用户一年总分发 userTokenRewardValue = preTokenRewardValue * weightingSupplyValue
          const userTokenRewardValue = new BigNumber(preTokenRewardValue).multipliedBy(new BigNumber(userWeightingValue)).div(new BigNumber(10).pow(18)).toFixed(0)
          _userAPR = new BigNumber(userTokenRewardValue).multipliedBy(new BigNumber(10).pow(item.decimals)).div(new BigNumber(account_borrow_value)).toFixed(0);
        }
        // 计算user UTS APR

        // 计算user ARB APR
        // 门槛计算  userTotalSupply * threshold <=  weightingValue
        if (chainId === 5 || chainId === 42161) {
          const isWeightingTokenArb = earnDataArb?.rewardInfo?.[item.address]
          if (!isWeightingTokenArb || new BigNumber(earnDataArb?.BLPStakedValue).lt(new BigNumber(newAssets?.totalValue[0]).plus(iUSXStaked).multipliedBy(new BigNumber(rewardDataArb?.threshold)).dividedBy(new BigNumber(10).pow(18)).toFixed(0)) || new BigNumber(earnDataArb?.rewardInfo?.[item.address]?.["borrowRewardRate"]).eq(0) || new BigNumber(earnDataArb?.rewardInfo?.[item.address]?.["weightingBorrowValue"]).eq(0)) {
            _userAPRArb = '0'
          } else {
            // _userAPR
            let userWeightingValue: string;
            if (new BigNumber(earnDataArb?.weightingValue).gte(new BigNumber(account_borrow_value).multipliedBy(new BigNumber(rewardDataArb?.magnification)).dividedBy(new BigNumber(10).pow(item.decimals)).toFixed(0))) {
              userWeightingValue = new BigNumber(account_borrow_value).plus(new BigNumber(account_borrow_value).multipliedBy(new BigNumber(rewardDataArb?.magnification).dividedBy(new BigNumber(10).pow(18)))).dividedBy(new BigNumber(10).pow(item.decimals)).multipliedBy(new BigNumber(10).pow(18)).toString()
            } else {
              userWeightingValue = new BigNumber(account_borrow_value).multipliedBy(new BigNumber(10).pow(18)).dividedBy(new BigNumber(10).pow(item.decimals)).plus(new BigNumber(earnDataArb?.weightingValue)).toString()
            }
            // 此token总分发价值 (supplyRewardRate * 365 *rewardPrice)
            const tokenSupplyReward = new BigNumber(earnDataArb?.rewardInfo?.[item.address]?.["borrowRewardRate"]).multipliedBy(new BigNumber(365)).multipliedBy(new BigNumber(earnDataArb?.rewardPrice)).dividedBy(new BigNumber(10).pow(18));
            // 此token每1美金一年分发多少美金的 preTokenRewardValue = tokenSupplyReward / weightingSupplyValue
            const preTokenRewardValue = tokenSupplyReward.multipliedBy(new BigNumber(10).pow(18)).dividedBy(new BigNumber(earnDataArb?.rewardInfo?.[item.address]?.["weightingBorrowValue"]).toFixed(0));
            // 用户一年总分发 userTokenRewardValue = preTokenRewardValue * weightingSupplyValue
            const userTokenRewardValue = new BigNumber(preTokenRewardValue).multipliedBy(new BigNumber(userWeightingValue)).div(new BigNumber(10).pow(18)).toFixed(0)
            _userAPRArb = new BigNumber(userTokenRewardValue).multipliedBy(new BigNumber(10).pow(item.decimals)).div(new BigNumber(account_borrow_value)).toFixed(0);
          }
        }
        // 计算user ARB APR
        return {
          id: 'borrow' + crruteToken?.underlying_symbol,
          action: 'borrow',
          symbol: crruteToken?.symbol,
          underlying_symbol: crruteToken?.underlying_symbol,
          reveal_symbol: symbol,
          name: crruteToken?.name,
          decimals: item.decimals,
          address: item.address,
          supplyAPY: crruteToken?.supplyAPY,
          borrowAPY: crruteToken?.borrowAPY,
          supplyValue: numeral(bnToDec(new BigNumber(crruteToken?.supplyValue), 18)).format("$0.00a").toLocaleUpperCase(),
          borrowValue: numeral(bnToDec(new BigNumber(crruteToken?.borrowValue), 18)).format("$0.00a").toLocaleUpperCase(),
          userAPR: _userAPR,
          userAPRArb: _userAPRArb,
          icon: icon,
          balance: balance,
          account_borrow_amount: item.balance,
          account_borrow_value: account_borrow_value
        };
      })
    )
    // 封装 supply Assets Portfolio
    let mintJsonArr = objToJsonArr(mintTokenList)

    const newAllList = [...newSupplyList, ...newBorrowList]
    const newAllSyntheticList = [...newSupplyList]
    // console.log(newAllList)
    const is = newAllList.map(item => {
      return localConfig_alltoken.filter((_item: any) => _item.address === item.address)[0]
    })
    if ((localStorage.getItem('account') === account) || !account) {
      setAllList(newAllList)
      setAllList_supply(newSupplyList)
      setAllList_borrow(newBorrowList)
      setAllSyntheticList(newAllSyntheticList)
    } else {
      setAllList([])
      setAllList_supply([])
      setAllList_borrow([])
      setAllSyntheticList([])
    }
  }, [lending?.contracts, account, chainId]);
  //初始化页面 请求一次数据， 开启5秒轮询 请求最新数据
  useEffect(() => {
    if (pathname === '/lending') {
      window.IntervalMarkets && clearInterval(window.IntervalMarkets)
      setMarketsList([])
      setmintMarketsList([])
      setTotalAssetsList({})
      setAllList([])
      setBLP(initBLP)
      setAllList_supply([])
      setAllList_borrow([])
      setAllSyntheticList([])
      setMarkets_supplyValue('')
      setMarkets_borrowValue('')
      setMarkets_mintValue('')
      // MarketsCallback();
      const target = () => {
        chainId && MarketsCallback();
        return target
      }
      window.IntervalMarkets = setInterval(target(), 1000 * 10)
    }
  }, [lending?.contracts, account, chainId, pathname]);

  return (
    <Context.Provider
      value={{
        marketsList: marketsListEffect,
        totalAssets: totalAssetsList,
        allAssetsList: allList,
        allList_supply: allList_supply,
        allList_borrow: allList_borrow,
        allSyntheticList,
        totalSupplyValue: markets_supplyValue,
        totalBorrowsValue: markets_borrowValue,
        totalMintValue: markets_mintValue,
        BLP
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default Provider;
