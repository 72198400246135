import React from "react";
import styled from "styled-components";


const ActionTab = ({ children }: {
    children: React.ReactNode
  }) => {
    return (
        <ActionTabBox>
            {children}
        </ActionTabBox>
    );
}

const ActionTabBox = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    font-weight: bold;
    color: ${(props) => props.theme.operationPage.grey};
    padding-top: 26px;
    padding-bottom: 20px;

    @media (max-width: 767px) {
        font-size: 14px;
        padding-top: 18px;
        padding-bottom: 16px;
    }
`

export default ActionTab

