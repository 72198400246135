import {JSON_objToArray } from "utils";
import { network_Id } from "lending-sdk/utils/index1";
// 读取 assetsToken 配置文件
import general_DL_Token from "lending-sdk/lib/clean_build/contracts/general/DL_Token.json";
import synthetic_DL_Token from "lending-sdk/lib/clean_build/contracts/synthetic/DL_Token.json";
import stocks_DL_Token from "lending-sdk/lib/clean_build/contracts/stocks/DL_Token.json";
import BigNumber from "bignumber.js";
export const fetchMining = async (networkName) => {
  // console.log("networkName",networkName)
  let chainId,
    StakingApy = [],
    supplyMaining = [],
    rewardData = [],
    supplyMainingArb = [],
    rewardDataArb = [];
    const fetchSupplyMaining = async()=>{
      const response = await fetch(`https://beta.unitus.finance/reward/rewardInfo?network=${
        networkName
      }`);
      const _supplyMaining = await response.json();
      if (_supplyMaining.status === "OK") {
        return _supplyMaining?.data;
      } else {
        return {
          "magnification": "0",
          "threshold": "0",
          "tokenList":[]
        }
      }
    };

    const fetchStakingApy = async()=>{
      const response = await fetch(`https://beta.unitus.finance/general/markets?network=${
        networkName
      }`);
      const _StakingApy = await response.json();
      return _StakingApy?.supplyMarkets;
    };

    const fetchRewardData = async()=>{
      const response = await fetch(`https://beta.unitus.finance/reward/stakedInfo?network=${
        networkName
      }`);
      const _RewardData = await response.json();
      if (_RewardData.status === "OK") {
        return _RewardData?.data;
      } else {
        return [];
      }
    }

    const fetchSupplyMainingArb = async()=>{
      const response = await fetch(`https://beta.unitus.finance/rewardArb/rewardInfo?network=${
        networkName
      }`);
      const _supplyMainingArb = await response.json();
      if (_supplyMainingArb.status === "OK") {
        return _supplyMainingArb?.data;
      } else {
        return {
          "magnification": "0",
          "threshold": "0",
          "tokenList":[]
        }
      }
    };
    const fetchRewardDataArb = async()=>{
      const response = await fetch(`https://beta.unitus.finance/rewardArb/stakedInfo?network=${
        networkName
      }`);
      const _RewardData = await response.json();
      if (_RewardData.status === "OK") {
        return _RewardData?.data;
      } else {
        return [];
      }
    }
    try {
      StakingApy = await fetchStakingApy()
    } catch (error) {
      StakingApy = [];
    }
    try {
      supplyMaining = await fetchSupplyMaining()
    } catch (error) {
      supplyMaining = {
      "magnification": "0",
      "threshold": "0",
      "tokenList":[]
    }
    }
    try {
      rewardData = await fetchRewardData()
    } catch (error) {
      rewardData = [];
    }
    if(networkName === 'Goerli' || networkName === 'ArbitrumOne'){
      try {
        supplyMainingArb = await fetchSupplyMainingArb()
      } catch (error) {
        supplyMainingArb = {
          "magnification": "0",
          "threshold": "0",
          "tokenList":[]
        }
      }
      try {
        rewardDataArb = await fetchRewardDataArb()
      } catch (error) {
        rewardDataArb = [];
      }
    }
    
  const deployAssets = {
    general: general_DL_Token,
    synthetic: synthetic_DL_Token,
    stocks: stocks_DL_Token,
  };
  chainId = network_Id[networkName];
  let localConfig_itoken =
    deployAssets?.general["networks"][`${chainId}`];
  localConfig_itoken =
    localConfig_itoken &&
    JSON_objToArray(localConfig_itoken, "assetsType", false);
  const newDistribution = localConfig_itoken?.map((item) => {
    let _SupplyRewardAPY,_BorrowRewardAPY,_SupplyRewardAPYArb,_BorrowRewardAPYArb;
    const currentItem = supplyMaining?.tokenList?.filter(
      (fetchItem) => fetchItem.Address === item.address
    )[0];
    if(currentItem){
      _SupplyRewardAPY = new BigNumber(currentItem?.SupplyRewardAPY).multipliedBy(new BigNumber(10).pow(18).plus(new BigNumber(supplyMaining?.magnification))).dividedBy(new BigNumber(10).pow(18));
      _BorrowRewardAPY = new BigNumber(currentItem?.BorrowRewardAPY).multipliedBy(new BigNumber(10).pow(18).plus(new BigNumber(supplyMaining?.magnification))).dividedBy(new BigNumber(10).pow(18));
    }

    const currentItemArb = supplyMainingArb?.tokenList?.filter(
      (fetchItem) => fetchItem.Address === item.address
    )[0];
    if(currentItemArb){
      _SupplyRewardAPYArb = new BigNumber(currentItemArb?.SupplyRewardAPY).multipliedBy(new BigNumber(10).pow(18).plus(new BigNumber(supplyMainingArb?.magnification))).dividedBy(new BigNumber(10).pow(18));
      _BorrowRewardAPYArb = new BigNumber(currentItemArb?.BorrowRewardAPY).multipliedBy(new BigNumber(10).pow(18).plus(new BigNumber(supplyMainingArb?.magnification))).dividedBy(new BigNumber(10).pow(18));
    }

    // console.log(currentItem)
    const currentItemApy = StakingApy?.filter(
      (fetchItem) => fetchItem.address === item.address
    )[0];
    let icon;
    try {
      icon = require(`assets/tokenList/${item.symbol}.svg`);
    } catch (error) {
      icon = require(`assets/tokenList/default.svg`);
    }
    return {
      reveal_symbol: item.symbol,
      icon,
      supplyApy: currentItemApy?.supplyAPY,
      borrowApy: currentItemApy?.borrowAPY,
      ...currentItem,
      SupplyRewardAPY:_SupplyRewardAPY,
      BorrowRewardAPY:_BorrowRewardAPY,
      MinimumSupplyRewardAPYArb:currentItemArb?.MinimumSupplyRewardAPY,
      MinimumBorrowRewardAPYArb:currentItemArb?.MinimumBorrowRewardAPY,
      SupplyRewardAPYArb:_SupplyRewardAPYArb,
      BorrowRewardAPYArb:_BorrowRewardAPYArb,
      DailyDistributionArb:currentItemArb?.DailyDistribution,
      DistributedArb:currentItemArb?.Distributed,
      isFreeze: item.isFreeze ?? false
    };
  });
  // let disabeldClaim = false
  // console.log("newDistribution", newDistribution,"localConfig_itoken",localConfig_itoken,"chainId",chainId);
  return {
    supplyMaining: newDistribution,
    // totalDaily,
    // totalDistributed,
    // totalHasbeenDistributed,
    // remaining,
    rewardTokenDecimals: "18",
    rewardTokenSymbol: "UTS",
    // disabeldClaim,
    rewardData,
    rewardDataArb
  };
};
