import {
  white,
  black,
  red,
  grey,
  linearBg,
  navLinklinearBg,
  alink,
  lightGrey,
  darkGrey,
  titleGrey,
  footerGrey,
  disabledBtn,
  disabledBtnBg,
  orange,
  green,
  blue,
  primary,
  lightPageBg,
  darkPageBg,
  lightTableBorder,
  darkTableBorder,
  lightOpacityGrey,
  darkOpacityGrey,
  lightLinkBg,
  darkLinkBg,
  stakeDF,
  lockdisabledBtn,
  fontColor,
  DarkfontColor,
  supply,
  borrow,
  supplyLightBg,
  TableBg,
  DarkTableBg
} from './colors'


const theme = {
  global_grey: '#999999',
  body_background_light: '#F5F6F9',
  body_background_night: '#000000',
  global_supply: '#FF764B',
  box_bg_night: '#131313',
  borderRadius: 12,
  colors: {
    white,
    black,
    red,
    grey,
    linearBg,
    navLinklinearBg,
    alink,
    lightGrey,
    darkGrey,
    titleGrey,
    footerGrey,
    disabledBtn,
    disabledBtnBg,
    primary,
    supply: {
      light: orange[100],
      normal: orange[200],
      dark: orange[300],
      dark400: orange[400]
    },
    borrow: {
      light: green[100],
      normal: green[50],
      dark: green[200],
      dark400: green[400]
    },
    mint: {
      light: blue[100],
      normal: blue[200],
      dark: blue[300],
      dark400: blue[400]
    },
    lightPageBg,
    darkPageBg,
    lightTableBorder,
    darkTableBorder,
    lightOpacityGrey,
    darkOpacityGrey,
    lightLinkBg,
    darkLinkBg,
    stakeDF,
    lockdisabledBtn
  },
  siteWidth: 1200,
  spacing: {
    1: 4,
    2: 8,
    3: 16,
    4: 24,
    5: 32,
    6: 48,
    7: 64,
  },
  topBarSize: 72,
  borderradius: '6px',
  operationPage: {
    darkBG: '#0B122E',
    grey: '#9195A4',
    light: { mainBlack: '#242A47', noteTipsBG: '#f6f6f6', sectionBG: '#fff', borderColor: '#E8ECEF', languageBG: '#fff' },
    dark: { mainBlack: '#fff', noteTipsBG: '#131B3D', sectionBG: '#0A1130', borderColor: 'rgba(255, 255, 255, 0.08)', languageBG: '#222942' }
  },
  fontColor,
  DarkfontColor,
  supply,
  borrow,
  supplyLightBg,
  TableBg,
  DarkTableBg
}

export default theme