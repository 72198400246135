import React from "react";
import styled, { keyframes } from "styled-components";
import { useIntl } from "react-intl";
import { _formatNumberNotCarry, bnToDec, formatNumberNotCarry, formateNormalNumber } from "utils";
import { rewardRate } from 'constants/constants';
import numeral from "numeral";
import BigNumber from "bignumber.js";
import LendTitle from "components/LendTitle";

interface MarketDistributionProps {
  Distribution: any[];
  rewardTokenDecimals: string;
}

const MarketDistribution: React.FC<MarketDistributionProps> = ({
  Distribution,
  rewardTokenDecimals,
}) => {
  const intl = useIntl();
  const fmt = intl.formatMessage;
  return (
    <>
      {/* <LendTitle text={fmt({ id: "Gauge" })} /> */}
      <TableWrap>
        <Table>
          <thead>
            {/* <tr>
              <TheadBar>
                <BarTitle>{fmt({ id: "Supply_Markets" })}</BarTitle>
              </TheadBar>
            </tr> */}
            <tr>
              <th className="firstTh">{fmt({ id: "MiningAssets" })}</th>
              <th>{fmt({ id: "Paid_Day" })}</th>
              <th className="specialApy">
                <div>
                  <span>{fmt({ id: "Supply_APY" })}</span>
                </div>
                <div className="bottomAPY">
                  <span>{fmt({ id: "Reward_to_MAX" })}</span>
                </div>
              </th>
              <th className="specialApy">
                <div>
                  <span>{fmt({ id: "Borrow_APY" })}</span>
                </div>
                <div className="bottomAPY">
                  <span>{fmt({ id: "Reward_to_MAX" })}</span>
                </div>
              </th>
              <th>{fmt({ id: "Total_Distribution" })}</th>
            </tr>
          </thead>
          <tbody>
            {Distribution?.length > 0
              ? Distribution.map((item) => {
                // if(item.isFreeze){
                //   console.log(item)
                // }
                return (
                  <tr key={item.address}>
                    <td className="firstTd">
                      <TokenIconWrap>
                        <TokenIcon src={item.icon} />
                      </TokenIconWrap>
                      <TokenSymbol>{item.reveal_symbol}</TokenSymbol>
                    </td>
                    <td className="specialApy">
                      {item.DailyDistribution &&
                        <div>
                          <img src="/Unitus.svg" />
                          <span>
                            {
                              bnToDec(
                                new BigNumber(item.DailyDistribution),
                                Number(rewardTokenDecimals)
                              ) > 0 &&
                                bnToDec(
                                  new BigNumber(item.DailyDistribution),
                                  Number(rewardTokenDecimals)
                                ) < 0.01
                                ? "<0.01"
                                : numeral(
                                  bnToDec(
                                    new BigNumber(item.DailyDistribution),
                                    Number(rewardTokenDecimals)
                                  )
                                )
                                  .format("0,0.00")
                            }
                          </span>
                        </div>
                      }
                      {
                        item.DailyDistributionArb &&
                          <div>
                            <img src="/ARB.svg" />
                            <span>
                              {
                                item.DailyDistributionArb ?
                                  bnToDec(
                                    new BigNumber(item.DailyDistributionArb),
                                    Number(rewardTokenDecimals)
                                  ) > 0 &&
                                    bnToDec(
                                      new BigNumber(item.DailyDistributionArb),
                                      Number(rewardTokenDecimals)
                                    ) < 0.01
                                    ? "<0.01"
                                    : numeral(
                                      bnToDec(
                                        new BigNumber(item.DailyDistributionArb),
                                        Number(rewardTokenDecimals)
                                      )
                                    )
                                      .format("0,0.00")
                                  : '...'
                              }
                            </span>
                          </div>
                      }
                    </td>
                    <td className="specialApy">
                      <div className="supply">
                        <span>
                          {
                            item.supplyApy ?
                              bnToDec(new BigNumber(item.supplyApy).abs(), 18) > 9999.99
                                ?
                                '999,999%'
                                :
                                _formatNumberNotCarry(bnToDec(new BigNumber(item.supplyApy), 18, 4), 'percent')
                              : '...'
                          }
                        </span>
                      </div>
                      {
                        (item.SupplyRewardAPY && item.MinimumSupplyRewardAPY && item.DailyDistribution !== '0') &&
                        <div className="APR">
                          <img src="/Unitus.svg" />
                          <span>
                            {
                              bnToDec(new BigNumber(item.SupplyRewardAPY).abs(), 18) > 9999.99
                                ?
                                <div>{_formatNumberNotCarry(bnToDec(new BigNumber(item.MinimumSupplyRewardAPY), 18, 6), 'percent')}~<i>999,999%</i></div>
                                :
                                <div>{_formatNumberNotCarry(bnToDec(new BigNumber(item.MinimumSupplyRewardAPY), 18, 6), 'percent')}~<i>{_formatNumberNotCarry(bnToDec(new BigNumber(item.SupplyRewardAPY), 18, 6), 'percent')}</i></div>
                            }
                          </span>
                        </div>
                        // :<div className="APR"><span>...</span></div>
                      }

                      {
                        (item.SupplyRewardAPYArb && item.MinimumSupplyRewardAPYArb && item.DailyDistributionArb !== '0') &&
                        <div className="APR">
                          <img src="/ARB.svg" />
                          <span>
                            {
                              bnToDec(new BigNumber(item.SupplyRewardAPYArb).abs(), 18) > 9999.99
                                ?
                                <div>{_formatNumberNotCarry(bnToDec(new BigNumber(item.MinimumSupplyRewardAPYArb), 18, 6), 'percent')}~<i>999,999%</i></div>
                                :
                                <div>{_formatNumberNotCarry(bnToDec(new BigNumber(item.MinimumSupplyRewardAPYArb), 18, 6), 'percent')}~<i>{_formatNumberNotCarry(bnToDec(new BigNumber(item.SupplyRewardAPYArb), 18, 6), 'percent')}</i></div>
                            }
                          </span>
                        </div>
                      }
                    </td>
                    <td className="specialApy">
                      <div className="borrow">
                        <span>
                          {
                            item.borrowApy ?
                              bnToDec(new BigNumber(item.borrowApy).abs(), 18) > 9999.99
                                ?
                                '999,999%'
                                :
                                _formatNumberNotCarry(bnToDec(new BigNumber(item.borrowApy), 18, 4), 'percent')
                              : '...'
                          }
                        </span>
                      </div>
                      {
                        (item.BorrowRewardAPY && item.MinimumBorrowRewardAPY && item.DailyDistribution !== '0') &&
                        <div className="APR">
                          <img src="/Unitus.svg" />
                          <span>
                            {
                              bnToDec(new BigNumber(item.BorrowRewardAPY).abs(), 18) > 9999.99
                                ?
                                <div>{_formatNumberNotCarry(bnToDec(new BigNumber(item.MinimumBorrowRewardAPY), 18, 6), 'percent')}~<i>999,999%</i></div>
                                :
                                <div>{_formatNumberNotCarry(bnToDec(new BigNumber(item.MinimumBorrowRewardAPY), 18, 6), 'percent')}~<i>{_formatNumberNotCarry(bnToDec(new BigNumber(item.BorrowRewardAPY), 18, 6), 'percent')}</i></div>
                            }
                          </span>
                        </div>
                        // :<div className="APR"><span>...</span></div>
                      }
                      {
                        (item.BorrowRewardAPYArb && item.MinimumBorrowRewardAPYArb && item.DailyDistributionArb !== '0') &&
                        <div className="APR">
                          <img src="/ARB.svg" />
                          <span>
                            {
                              bnToDec(new BigNumber(item.BorrowRewardAPYArb).abs(), 18) > 9999.99
                                ?
                                <div>{_formatNumberNotCarry(bnToDec(new BigNumber(item.MinimumBorrowRewardAPYArb), 18, 6), 'percent')}~<i>999,999%</i></div>
                                :
                                <div>{_formatNumberNotCarry(bnToDec(new BigNumber(item.MinimumBorrowRewardAPYArb), 18, 6), 'percent')}~<i>{_formatNumberNotCarry(bnToDec(new BigNumber(item.BorrowRewardAPYArb), 18, 6), 'percent')}</i></div>
                            }
                          </span>
                        </div>
                      }
                    </td>
                    <td className="specialApy">
                      {item.Distributed &&
                        <div>
                          <img src="/Unitus.svg" />
                          <span>
                            {
                              bnToDec(
                                new BigNumber(item.Distributed),
                                Number(rewardTokenDecimals)
                              ) > 0 &&
                                bnToDec(
                                  new BigNumber(item.Distributed),
                                  Number(rewardTokenDecimals)
                                ) < 0.01
                                ? "0.00"
                                : numeral(
                                  bnToDec(
                                    new BigNumber(item.Distributed),
                                    Number(rewardTokenDecimals)
                                  )
                                )
                                  .format("0,0.00")
                            }
                          </span>
                        </div>
                      }
                      {item.DistributedArb  &&
                        <div>
                          <img src="/ARB.svg" />
                          <span>
                            {
                              bnToDec(
                                new BigNumber(item.DistributedArb),
                                Number(rewardTokenDecimals)
                              ) > 0 &&
                                bnToDec(
                                  new BigNumber(item.DistributedArb),
                                  Number(rewardTokenDecimals)
                                ) < 0.01
                                ? "0.00"
                                : numeral(
                                  bnToDec(
                                    new BigNumber(item.DistributedArb),
                                    Number(rewardTokenDecimals)
                                  )
                                )
                                  .format("0,0.00")
                            }
                          </span>
                        </div>
                      }
                    </td>
                  </tr>
                )
              })
              : Array.from({ length: 5 }).map((key, index) => (
                <tr key={index}>
                  <td className="firstTd">
                    <TokenIconWrap className="TokenIconBg" />
                    <TokenSymbol className="TokenSymbolBg"></TokenSymbol>
                  </td>
                  <td>...</td>
                  <td className="specialApy">
                    <div className="supply">
                      <span>...</span>
                    </div>
                    <div className="APR">
                      <span>...</span>
                    </div>
                  </td>
                  <td className="specialApy">
                    <div className="borrow">
                      <span>...</span>
                    </div>
                    <div className="APR">
                      <span>...</span>
                    </div>
                  </td>
                  <td>...</td>
                </tr>
              ))}
          </tbody>
        </Table>
      </TableWrap>
    </>
  );
};

const flash = keyframes`
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
`;
const TableWrap = styled.div`
  display:flex;
  justify-content:space-between;
  width: 72%;
  min-width: 1200px;
  margin: 20px auto 0;
  table{
    flex-grow:1;
  }
  .leftTable{
    margin-right:15px;
  }
  @media(max-width: 1199px) {
    width: calc(100% - 24px);
    min-width: inherit;
    flex-direction:column;
    .leftTable{
      margin-bottom:15px;
      thead tr{
        /* border-bottom:none; */
        height:auto;
        th{
          line-height:36px;
        }
      }
      tr{
        th,td{
          line-height:55px;
        }
      }
      
    }
    th:last-child,td:last-child{
      display: none!important;
    }
    th:nth-child(2),td:nth-child(2){
      display: none!important;
      }
    /* th:nth-child(2),td:nth-child(2){
      display:block;
      line-height:40px;
    } */
  }
`
const TheadBar = styled.th`
  ${TableWrap} table tr & {
    width:100%;
    padding:8px 0 0;
    line-height:40px;
    display:flex;
    justify-content:space-between;
    flex:inherit;
    align-items:inherit;
    font-size:16px;
    font-weight:bold;
    @media(max-width: 1199px) {
      flex-direction:column;
      padding:0;
      text-align:left;
      p{
        margin:0;
        line-height:36px;
      }
      ul{
        margin:0;
        line-height:30px;
        margin-bottom:10px;
      }
    }
  }
`
const BarTitle = styled.p`

`
const SwitchTabs = styled.ul`
  display:flex;
  height: 40px;
  background: ${(props) => props.theme.colors.lightOpacityGrey};
  .dark & {
    background: ${(props) => props.theme.colors.darkOpacityGrey};
  }
  border-radius: 20px;
  margin-bottom:10px;
  cursor: pointer;
  @media(max-width: 1199px) {
    width:max-content;
    height:30px;
  }
`
const SwitchItem = styled.li`
  padding:0 24px;
  border-radius: 20px;
  color:${(props) => props.theme.colors.lightGrey};
  background:transparent;
  &.active{
    background: ${props => props.theme.colors.linearBg};
    border-radius: 20px;
    color:${props => props.theme.colors.white};
  }
  @media(max-width: 1199px) {
    padding:0 18px;
  }
`
const Table = styled.table`
  width: max-content;
  height: max-content;
  border-radius: 16px;
  border-spacing: inherit;
  color: ${(props) => props.theme.colors.black};
  background:${(props) => props.theme.TableBg};
  font-size:14px;
  @media(max-width: 1199px) {
    width:100%;
    border-radius: 10px;
  }
  .dark & {
    color: ${(props) => props.theme.colors.white};
    background:${(props) => props.theme.DarkTableBg};
  }
  &.noSynthsLink {
    margin-right:0!important;
  }
  font-weight: 500;
  thead {
    tr:nth-child(1){
      border-bottom:1px solid ${(props) => props.theme.colors.lightTableBorder};
      .dark & {
        border-bottom:1px solid ${(props) => props.theme.colors.darkTableBorder};
      }
    }
  }
  tr {
    padding: 8px 0;
    margin:0 24px;
    text-align: right;
    height: auto;
    min-height: 85px;
    /* line-height: 54px; */
    box-sizing: border-box;
    border-bottom:1px solid ${(props) => props.theme.colors.lightTableBorder};
    .dark & {
      border-bottom:1px solid ${(props) => props.theme.colors.darkTableBorder};
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    @media(max-width: 1199px) {
      /* height: 55px; */
      margin:0 10px;
      /* line-height: 55px; */
    }
    th,
    td {
      flex: 1;
    }
    th {
      font-weight:normal;
      text-align: right;
      color: ${(props) => props.theme.fontColor['999']};
      &.specialApy {
        display: flex;
        flex-direction: column;
        justify-content: center;
        div {
          line-height: 20px;
          span {
            display: inline-block;
          }
          &.bottomAPY {
            &:first-child {
              margin-left: 5px;
            }
          }
        }
      }
      @media(max-width: 1199px) {
        display: flex;
        line-height: 20px;
        align-items: center;
        justify-content: flex-end;
        &.specialApy {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          div {
            line-height: 20px;
            span {
              display: inline-block;
            }
            &.bottomAPY {
              color: #9195a4;
              &:first-child {
                margin-left: 5px;
              }
            }
          }
        }
      }
    }
    td {
      .supply{
        color:${(props) => props.theme.borrow};
      }
      .borrow{
        color:rgb(246, 61, 61);
      }
      &.specialApy {
        display: flex;
        flex-direction: column;
        justify-content: center;
        div {
          display: flex;
            justify-content: end;
            align-items: center;
          line-height: 24px;
          img{
              display: inline-block;
              width: 15px;
              height: 15px;
              margin-right: 5px;
            }
          span {
            display: inline-block;
          }
          &.bottomAPY {
            color:${(props) => props.theme.fontColor[999]};
            &:first-child {
              margin-left: 5px;
            }
          }
          &.APR {
            font-size: 14px;
            &:first-child {
              margin-left: 5px;
            }
            i{
              font-style: normal;
            }
          }
        }
      }
      @media(max-width: 1199px) {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        &.specialApy {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          div {
            font-size: 10px;
            line-height: 20px;
            &.APR {
            font-size: 10px;
            }
            span {
              display: inline-block;
            }
            &.bottomAPY {
              font-size: 16px;
              &:first-child {
                margin-left: 5px;
              }
            }
          }
        }
      }
    }
    .firstTh,
    .firstTd {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      text-align: left;
      width: 55px;
      flex: none;
      @media(max-width: 1199px) {
        width: 60px;
      }
    }
  &:last-child{
    border-bottom:0
  }
  }
`;
const TokenSymbol = styled.span`
  display: inline-block;
  min-width: 50px;
  height: 17px;
  line-height: 17px;
  font-weight: 500;
  &.TokenSymbolBg {
    animation: ${flash} 1.2s linear infinite forwards;
    background: #e1e5ee;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 800px 100px;
    position: relative;
  }
  @media(max-width: 1199px) {
    height: 15px;
    line-height: 15px;
    font-size: 12px;
  }
`;
const TokenIconWrap = styled.div`
  width: 32px;
  min-width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 10px;
  &.TokenIconBg {
    border-radius: 50%;
    animation: ${flash} 1.2s linear infinite forwards;
    background: #e1e5ee;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 800px 100px;
    position: relative;
  }
`;
const TokenIcon = styled.img`
  display: block;
  width: 32px;
  height: 32px;
`;
export default MarketDistribution;
