import React from 'react'
import styled from 'styled-components'


const FlexBox = ({ children }: {
  children: React.ReactNode
}) => {

  return (
    <StyledMain>
      {children}
    </StyledMain>
  )
}

const StyledMain = styled.div`
  height: 100%;
  @media (min-width: 1200px) {
    width: 72%;
    min-width: 1200px;
    margin: 0 auto;
  }
  @media (max-width: 1199px) {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    padding: 0 12px;
  }
`

export default FlexBox