/*
 * @Author: your name
 * @Date: 2021-02-08 16:15:31
 * @LastEditTime: 2021-07-07 11:39:12
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \web-frontd:\a\curly-telegram\web-front\src\views\Markets\fetchMarkets.js
 */
import BigNumber from "bignumber.js";
import { JSON_objToArray } from "utils";
import {network_Id} from 'lending-sdk/utils/index1'
// 读取 assetsToken 配置文件
import general_DL_Token from 'lending-sdk/lib/clean_build/contracts/general/DL_Token.json';
import synthetic_DL_Token from 'lending-sdk/lib/clean_build/contracts/synthetic/DL_Token.json';
import stocks_DL_Token from 'lending-sdk/lib/clean_build/contracts/stocks/DL_Token.json';
export const fetchMarkets = async (networkName) => {
  let chainId,
  data,
  rewardData,
  rewardDataArb,
  totalSupply,
  totalBorrow,
  marketsList,
  totalSupplyValue,
  totalBorrowsValue,
  totalMintValue

  // const {
  //   totalSupply,
  //   totalBorrow
  // } = await requestAPI(`https://beta.unitus.finance/general/dashboard`)

  try {
    const response = await fetch(`https://beta.unitus.finance/general/dashboard`)
    const res = await response.json();
    totalSupply = res?.totalSupply
    totalBorrow = res?.totalBorrow

  } catch (error) {
    console.log(error)
    rewardData = {
      "magnification": "0",
      "threshold": "0",
      "tokenList":[]
    }
  }
  
  try {
    // data = await requestAPI(`https://beta.unitus.finance/dashboard/total/info/`);
      const response = await fetch(`https://beta.unitus.finance/dashboard/total/info/`)
      data = await response.json();
    await fetch(`https://beta.unitus.finance/reward/rewardInfo?network=${networkName ?? 'mainnet'}`)
        .then(response => response.json())
        .catch(error => console.error('Error:', error))
        .then(response => {
          // console.log('supplyMaining:', response);
          if(response.status === 'OK'){
            rewardData = response?.data
          }else{
            rewardData = {
              "magnification": "0",
              "threshold": "0",
              "tokenList":[]
            }
          }   
      });
  } catch (error) {
    console.log(error)
    rewardData = {
      "magnification": "0",
      "threshold": "0",
      "tokenList":[]
    }
  }

  const fetchRewardDataArb = async()=>{
    const response = await fetch(`https://beta.unitus.finance/rewardArb/rewardInfo?network=${
      networkName
    }`);
    const _RewardData = await response.json();
    if (_RewardData.status === "OK") {
      return _RewardData?.data;
    } else {
      return [];
    }
  }
  if(networkName === 'Goerli' || networkName === 'ArbitrumOne'){
    try {
      rewardDataArb = await fetchRewardDataArb()
    } catch (error) {
      rewardDataArb = [];
    }
  }


      try {
        if(networkName){
          const response = await fetch(`https://beta.unitus.finance/general/marketsV2?network=${networkName}`)
          const res = await response.json();
          marketsList = res?.marketsList
          totalSupplyValue = res?.totalSupplyValue
          totalBorrowsValue = res?.totalBorrowsValue
          totalMintValue = res?.totalMintValue
        }else{
          const response = await fetch(`https://beta.unitus.finance/general/marketsV2?network=mainnet`)
          const res = await response.json();
          marketsList = res?.marketsList
          totalSupplyValue = res?.totalSupplyValue
          totalBorrowsValue = res?.totalBorrowsValue
          totalMintValue = res?.totalMintValue
        }
      } catch (error) {
        console.log(error)
      }

  const deployAssets = {
    "general":general_DL_Token,
    "synthetic":synthetic_DL_Token,
    "stocks":stocks_DL_Token
  }
  chainId = network_Id[networkName] ? network_Id[networkName] : 1
  let localConfig_itoken = deployAssets?.general['networks'][`${chainId}`]
  localConfig_itoken = JSON_objToArray(localConfig_itoken,'assetsType',false)
  const newSupplyMarkets = Array.isArray(marketsList)
    ? localConfig_itoken.map((item) => {
      let _SupplyRewardAPY,_BorrowRewardAPY,_SupplyRewardAPYArb,_BorrowRewardAPYArb;
      const currentItem = marketsList?.filter(fetchItem=> fetchItem.address === item.address)[0]
      const currentItemReward = rewardData?.tokenList?.filter(fetchItem=> fetchItem.Address === item.address)[0]
      if(currentItemReward){
          _SupplyRewardAPY = new BigNumber(currentItemReward?.SupplyRewardAPY).multipliedBy(new BigNumber(10).pow(18).plus(new BigNumber(rewardData?.magnification))).dividedBy(new BigNumber(10).pow(18));
          _BorrowRewardAPY = new BigNumber(currentItemReward?.BorrowRewardAPY).multipliedBy(new BigNumber(10).pow(18).plus(new BigNumber(rewardData?.magnification))).dividedBy(new BigNumber(10).pow(18));
      };

      const currentItemRewardArb = rewardDataArb?.tokenList?.filter(fetchItem=> fetchItem.Address === item.address)[0]
      if(currentItemRewardArb){
        _SupplyRewardAPYArb = new BigNumber(currentItemRewardArb?.SupplyRewardAPY).multipliedBy(new BigNumber(10).pow(18).plus(new BigNumber(rewardDataArb?.magnification))).dividedBy(new BigNumber(10).pow(18));
        _BorrowRewardAPYArb = new BigNumber(currentItemRewardArb?.BorrowRewardAPY).multipliedBy(new BigNumber(10).pow(18).plus(new BigNumber(rewardDataArb?.magnification))).dividedBy(new BigNumber(10).pow(18));
      }
      let icon
      try {
        icon = require(`assets/tokenList/${item.symbol}.svg`);
      } catch (error) {
        icon = require(`assets/tokenList/default.svg`);
      }
        return {
          reveal_symbol:item.symbol,
          icon,
          supply_progress: new BigNumber(currentItem?.totalSupply)
            .dividedBy(new BigNumber(Number(totalSupplyValue)))
            .toNumber(),
          borrow_progress: new BigNumber(currentItem?.totalBorrows)
            .dividedBy(new BigNumber(Number(totalBorrowsValue)))
            .toNumber(),
          ...currentItem,
          ...currentItemReward,
          SupplyRewardAPY:_SupplyRewardAPY,
          BorrowRewardAPY:_BorrowRewardAPY,
          DailyDistribution:currentItemReward?.DailyDistribution,
          MinimumSupplyRewardAPYArb:currentItemRewardArb?.MinimumSupplyRewardAPY,
          MinimumBorrowRewardAPYArb:currentItemRewardArb?.MinimumBorrowRewardAPY,
          SupplyRewardAPYArb:_SupplyRewardAPYArb,
          BorrowRewardAPYArb:_BorrowRewardAPYArb,
          DailyDistributionArb:currentItemRewardArb?.DailyDistribution,
          isFreeze: item.isFreeze??false
        };
      }).filter((_)=>_)
    : [];
  // console.log(newSupplyMarkets)
  return {
    marketsList: newSupplyMarkets,
    allChainTotalSupplyValue:totalSupply,
    allChaintotalBorrowsValue:totalBorrow,
    allChainTotalAccumulatedBorrow:data?.data?.accumulated_borrow ?? '0',
    totalSupplyValue,
    totalBorrowsValue,
    totalMintValue,
  };
};
