import React from 'react'
import styled from 'styled-components'
import { useIntl } from 'react-intl'

interface Token_NumberProps {
  tab?: string,
  token?: string,
  myWallet?: string,
  value?: string,
  color?: string,
  weight?: string
}

const Token_Number: React.FC<Token_NumberProps> = ({ tab, token, myWallet, value, color, weight }) => {
  const intl = useIntl()
  const fmt = intl.formatMessage
  return (
    < StyledToken_Number >
      <StyledToken color={color} weight={weight}>
        {
          (tab === 'supply' || tab === 'stake' || tab === 'unstake') ?
            token + fmt({ id: 'balance' })
            :
            tab === 'borrow' ?
              fmt({ id: 'Available_to_Borrow' })
              :
              tab === 'mint' ? fmt({ id: 'Available_to_Mint' }) :
                tab === 'freeStaking' ? fmt({ id: 'Available_to_Unstake' }) :
                  fmt({ id: 'Available_to_Withdraw' })
        }
      </StyledToken>

      <StyledNumber>
        {value && myWallet ? value : '...'}
      </StyledNumber>
    </StyledToken_Number >
  )
}


interface StyledTokenProps {
  color?: string,
  weight?: string
}
const StyledToken = styled.div<StyledTokenProps>`
  color: ${(props) => props.theme.global_grey};
  font-weight:${(props) => props.weight ? props.weight : 'normal'};
`
const StyledNumber = styled.div`
  font-weight: bold;
  color: ${(props) => props.theme.operationPage.light.mainBlack};

  .dark & {
    color: ${(props) => props.theme.operationPage.dark.mainBlack};
  }
`
const StyledToken_Number = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  margin-top: 20px;
  height: 20px;

  @media (max-width: 1119px) {
    margin-top: 12px;
    font-size: 13px;
  }
`

export default Token_Number