import React from 'react'
import styled from 'styled-components'
import closeWallet from 'assets/header/close-wallet.svg'
interface ModalTitleProps {
  text?: string,
  onDismiss?: () => void;
}

const ModalTitle: React.FC<ModalTitleProps> = ({ text, onDismiss }) => (
  <StyledModalTitle>
    {text}
    <CloseModal src={closeWallet} onClick={onDismiss} />
  </StyledModalTitle>
)

const CloseModal = styled.img`
  width:20px;
  height:20px;
  cursor: pointer;
  @media(max-width: 1199px) {
    width:16px;
    height:16px
  }
  &:hover {
    background: rgba(0,0,0,0.1);
    .dark & {
      background: rgba(255,255,255,0.1);
    }
  }
`
const StyledModalTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 700;
  line-height:20px;
  color: #0A1130;
  padding-bottom: 26px;
  border-bottom: 1px solid #ECEDF3;
  @media(max-width: 1199px) {
    height: 20px;
    line-height:20px;
  }
  .dark & {
    color: #fff;
    border-bottom: 1px solid #ecedf310;
  }
`



export default ModalTitle