import { Table, Col, Row, Select, Input, Tooltip, Button } from 'antd';
import type { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import type { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { SearchOutlined } from '@ant-design/icons';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate,useLocation } from 'react-router-dom'

import styled from 'styled-components'
import { useWeb3React } from '@web3-react/core'
import { useIntl } from 'react-intl'
import BigNumber from 'bignumber.js'
import numeral from "numeral";
import { bnToDec, formate_round_up, useQuery, setUrlParam } from 'utils';
import { network_Name, etherscan } from 'lending-sdk/utils/index1'
import general_DL_Token from 'lending-sdk/lib/clean_build/contracts/general/DL_Token.json';
import synthetic_DL_Token from 'lending-sdk/lib/clean_build/contracts/synthetic/DL_Token.json';
import stocks_DL_Token from 'lending-sdk/lib/clean_build/contracts/stocks/DL_Token.json';

import FlexBoxWrap from "components/FlexBoxWrap"
import FlexBGFilter from 'components/FlexBGFilter'

declare global {
  interface Window {
    location: Location;
    sessionStorage: WindowSessionStorage;
    changeNetworkTimeId: NodeJS.Timeout;
    changeSearchInputTimeId: NodeJS.Timeout
  }
}
interface DataType {
  shortfall: string,
  userAddress: string,
  supplyValue: string,
  collateralValue: string,
  borrowedValue: string,
  collateralRatio: string
}

interface Params {
  pagination?: TablePaginationConfig;
  // sorter?: SorterResult<any> | SorterResult<any>[];
  total?: number;
  network?: string,
  borrowedValueMin?: string,
  shortFallMin?: string,
  shortFallMax?: string,
  Address?: string
  // sortField?: string;
  // sortOrder?: string;
}

// select end

const { Option } = Select;

const { Search } = Input;

// 获取本地token配置
const deployAssets = {
  "general": general_DL_Token,
  "synthetic": synthetic_DL_Token,
  "stocks": stocks_DL_Token,
}

// shortfallLable
const shortfallLable = {
  "-1": "All Shortfall",
  "0": "Shortfall=$0",
  "10": "Shortfall>$10",
  "5": "$0<Shortfall<=$10"
}
const Liquidation: React.FC = () => {

  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [generalLink, setGeneralLink] = useState<Record<string, any> | undefined>(undefined)
  const [stocksLink, setStocksLink] = useState<Record<string, any> | undefined>(undefined)
  const [borrowedValueMin, setborrowedValueMin] = useState<string>('')
  const [shortfall, setshortfall] = useState<string>('-1')
  const [shortfallParams, setshortfallParams] = useState<Record<string, string>>({})
  const [Address, setAddress] = useState<string>('')
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 30,
    pageSizeOptions: ['10', '30', '50', '100'],
    showSizeChanger: true,
    onShowSizeChange: (current, size) => { console.log(current, size) }
  });
  const navigate = useNavigate()
  const queryString = window.location.hash.split('?')[1] ? ('?' + window.location.hash.split('?')[1]) : ''
  const intl = useIntl()
  const fmt = intl.formatMessage
  const {
    connector,
    account,
    chainId,
    provider,
    ENSName,
    isActive,
    isActivating,
  } = useWeb3React();

  const handleChangeBorrowers = (value: { value: string; label: React.ReactNode }) => {
    const borrowValue = value?.value
    setborrowedValueMin(borrowValue)
    const _pagination = { ...pagination, current: 1 }
    const _Address = Address === '' ? {} : { Address }
    const _borrowedValueMin = borrowValue === '' ? {} : { borrowedValueMin: borrowValue }
    // window.sessionStorage.setItem('liquidation_pagination',JSON.stringify(_pagination))
    chainId && fetchData({
      ...{
        pagination: _pagination,
        network: network_Name[chainId]
      },
      ...shortfallParams,
      ..._Address,
      ..._borrowedValueMin
    });
  };

  const handleChangeShortfall = (value: { value: string; label: React.ReactNode }) => {
    // console.log(value); 
    const shortfall = value?.value
    let shortParms: Record<string, string>;
    if (shortfall === '-1') {
      shortParms = {}
    } else if (shortfall === '0') {
      shortParms = { shortFallMax: shortfall }
    } else if (shortfall === '10') {
      shortParms = { shortFallMin: shortfall }
    } else {
      // shortfall === '5'
      shortParms = { shortFallMin: '0', shortFallMax: '10' }
    }
    const _pagination = { ...pagination, current: 1 }
    // window.sessionStorage.setItem('liquidation_pagination',JSON.stringify(_pagination))
    setshortfall(shortfall)
    setshortfallParams(shortParms)
    const _Address = Address === '' ? {} : { Address }
    const _borrowedValueMin = borrowedValueMin === '' ? {} : { borrowedValueMin }
    chainId && fetchData({
      ...{
        pagination: _pagination,
        network: network_Name[chainId]
      },
      ...shortParms,
      ..._Address,
      ..._borrowedValueMin
    });
  };

  const onSearch = () => {
    const _pagination = { ...pagination, current: 1 }
    // console.log(_pagination, pagination)
    const _Address = Address === '' ? {} : { Address }
    const _borrowedValueMin = borrowedValueMin === '' ? {} : { borrowedValueMin }
    chainId && fetchData({
      ...{
        pagination: _pagination,
        network: network_Name[chainId]
      },
      ...shortfallParams,
      ..._Address,
      ..._borrowedValueMin
    });
  };
  const SearchOnchange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value
    setAddress(val)
    if (val === "") {
      const _pagination = { ...pagination, current: 1 }
      const _borrowedValueMin = borrowedValueMin === '' ? {} : { borrowedValueMin }
      chainId && fetchData({
        ...{
          pagination: _pagination,
          network: network_Name[chainId]
        },
        ...shortfallParams,
        ..._borrowedValueMin
      });
    }
  }
  // select end

  const getRandomuserParams = (params: Params) => ({
    pageSize: params.pagination?.pageSize,
    pageNumber: params.pagination?.current,
    ...params,
  });
  const columns: ColumnsType<DataType> = [
    {
      title: fmt({ id: 'Shortfall' }),
      dataIndex: 'shortfall',
      render: (val) => (bnToDec(new BigNumber(val), 18) > 0 && bnToDec(new BigNumber(val), 18) < 0.0001) ? '<0.0001' : numeral(bnToDec(new BigNumber(val), 18, 4)).format("0,0.0000"),
    },
    {
      title: fmt({ id: 'Account' }),
      dataIndex: 'userAddress',
      render: (val) => <a href={`${etherscan?.[chainId ? chainId : '1']}${val}`} target="_blank" onClick={(e) => e.stopPropagation()}>{val.slice(0, 7) + '...' + val.slice(-4)}</a>
    },
    {
      title: fmt({ id: 'supplyValue' }),
      dataIndex: 'supplyValue',
      render: (val) => numeral(new BigNumber(bnToDec(new BigNumber(val), 18, 4)).toString(10)).format("0,0.0000")
    },
    {
      title: fmt({ id: 'collateralValue' }),
      dataIndex: 'collateralValue',
      render: (val) => numeral(bnToDec(new BigNumber(val), 18, 4)).format("0,0.0000")
    },
    {
      title: fmt({ id: 'borrowedValue' }),
      dataIndex: 'borrowedValue',
      render: (val) => numeral(bnToDec(new BigNumber(val), 18, 4)).format("0,0.0000")
    },
    {
      title: fmt({ id: 'collateralRatio' }),
      dataIndex: 'collateralRatio',
      render: (val) => (bnToDec(new BigNumber(val), 18) > 0) && (bnToDec(new BigNumber(val), 18) < 0.0001) ? '<0.0001' : numeral(bnToDec(new BigNumber(val), 18, 4)).format("0,0.0000")
    }
  ];
  const fetchData = (params: Params = {}) => {
    setLoading(true);
    // console.log("params", params)
    fetch(`https://beta.unitus.finance/general/liquidationV2?${qs.stringify(getRandomuserParams(params))}&v2=true`)
      .then(res => res.json())
      .then(({ totalCount }) => {
        const current = params.pagination?.current ?? 1
        const pageSize = params.pagination?.pageSize ?? 10
        if (Number(totalCount) > ((current - 1) * pageSize)) {
          fetch(`https://beta.unitus.finance/general/liquidationV2?${qs.stringify(getRandomuserParams(params))}&v2=true`)
            .then(res => res.json())
            .then(({ totalCount, data }) => {
              setData(data);
              setLoading(false);
              // window.sessionStorage.setItem('liquidation_pagination',JSON.stringify(params.pagination))
              setPagination({
                ...params.pagination,
                total: totalCount,
                // 200 is mock data, you should read it from server
                // total: data.totalCount,
              });
              window.sessionStorage.setItem('liquidation_pagination', JSON.stringify({ ...params.pagination, total: totalCount }))
            });
        } else {
          const _params: Params = {
            pagination: {
              current: 1,
              pageSize: 10
            },
            network: network_Name[chainId ?? 1],
            ...params
          }
          console.log(_params)
          fetch(`https://beta.unitus.finance/general/liquidationV2?${qs.stringify(getRandomuserParams(_params))}&v2=true`)
            .then(res => res.json())
            .then(({ totalCount, data }) => {
              setData(data);
              setLoading(false);
              // window.sessionStorage.setItem('liquidation_pagination',JSON.stringify(params.pagination))
              setPagination({
                current: 1,
                pageSize: 10,
                total: totalCount,
                // 200 is mock data, you should read it from server
                // total: data.totalCount,
              });
              window.sessionStorage.setItem('liquidation_pagination', JSON.stringify({ current: 1, pageSize: 10, pageNumber: 1, total: totalCount }))
            });
        }
      });
  };
  useEffect(() => {
    clearInterval(window.changeNetworkTimeId)
    clearInterval(window.changeSearchInputTimeId)
    setAddress("")
    setborrowedValueMin("")
    setshortfall("-1")
    setshortfallParams({})
    let paginationStorage = window.sessionStorage.getItem('liquidation_pagination');
    const paginationStorage1: TablePaginationConfig = (paginationStorage && JSON.parse(paginationStorage));
    chainId && (paginationStorage ? fetchData({ pagination: paginationStorage1, network: network_Name[chainId] }) : fetchData({ pagination: { ...paginationStorage1, current: 1 }, network: network_Name[chainId] }));
    window.changeNetworkTimeId = setInterval(() => {
      // console.log("changeNetworkTimeId")
      let _paginationStorage = window.sessionStorage.getItem('liquidation_pagination')
      const _paginationStorage1: TablePaginationConfig = (_paginationStorage && JSON.parse(_paginationStorage))
      chainId && (_paginationStorage ? fetchData({ pagination: _paginationStorage1, network: network_Name[chainId] }) : fetchData({ pagination: _paginationStorage1, network: network_Name[chainId] }));
    }, 15000)
    return () => {
      clearInterval(window.changeNetworkTimeId)
    }
  }, [chainId, account]);
  useEffect(() => {
    clearInterval(window.changeNetworkTimeId)
    clearInterval(window.changeSearchInputTimeId)
    // let paginationStorage = window.sessionStorage.getItem('liquidation_pagination')
    // const paginationStorage1:TablePaginationConfig = (paginationStorage && JSON.parse(paginationStorage))
    // chainId &&  (paginationStorage ? fetchData({ pagination:paginationStorage1,network:network_Name[chainId] }) : fetchData({ pagination,network:network_Name[chainId] }));
    window.changeSearchInputTimeId = setInterval(() => {
      // console.log("changeSearchInputTimeId")
      let _paginationStorage = window.sessionStorage.getItem('liquidation_pagination')
      const _paginationStorage1: TablePaginationConfig = (_paginationStorage && JSON.parse(_paginationStorage))
      // console.log(Address, borrowedValueMin, shortfallParams)
      const _Address = Address === '' ? {} : { Address }
      const _borrowedValueMin = borrowedValueMin === '' ? {} : { borrowedValueMin }
      chainId && (_paginationStorage ? fetchData({ ...{ pagination: _paginationStorage1, network: network_Name[chainId] }, ...shortfallParams, ..._Address, ..._borrowedValueMin }) : fetchData({ ...{ pagination: _paginationStorage1, network: network_Name[chainId] }, ...shortfallParams, ..._Address, ..._borrowedValueMin }));
    }, 15000)
    return () => {
      clearInterval(window.changeSearchInputTimeId)
    }
  }, [Address, borrowedValueMin, shortfallParams]);
  // 动态路由链接
  useEffect(() => {
    let generalLink
    if (chainId) {
      generalLink = deployAssets["general"]['networks'][`${chainId}`]
      setGeneralLink(generalLink)
    }
  }, [chainId])
  const handleTableChange: any = (
    newPagination: TablePaginationConfig,
    network: string
    // filters: Record<string, FilterValue>,
    // sorter: SorterResult<DataType>,
  ) => {
    // console.log(newPagination)
    const _Address = Address === '' ? {} : { Address }
    const _borrowedValueMin = borrowedValueMin === '' ? {} : { borrowedValueMin }
    chainId && fetchData({
      ...{
        // sortField: sorter.field as string,
        // sortOrder: sorter.order as string,
        pagination: newPagination,
        network: network_Name[chainId]
        // ...filters,
      },
      ...shortfallParams,
      ..._Address,
      ..._borrowedValueMin
    });
  };

  return (
    <>
      <FlexBoxWrap>
        <AssetWrap>
          <StyledTitle>
            {fmt({ id: "Liquidation" })}
          </StyledTitle>
          <PriceTitleSub>{fmt({ id: "PriceTitle__sub" })}</PriceTitleSub>
        </AssetWrap>
      </FlexBoxWrap>

      <FlexBGFilter>
        <FlexBoxWrap>
          <TabWrapInner>
          <Crumb>
            <span>
              <RouterLink to={`/History${queryString}`}>
                <BackToHome>{fmt({ id: 'LiquidationHistory' })}</BackToHome>
              </RouterLink>
            </span>
          </Crumb>
          </TabWrapInner>
        </FlexBoxWrap>
      </FlexBGFilter>

      <FlexBoxWrap>
        <Row gutter={8} style={{ 'margin': '8px 0 14px 0' }}>
          <Col>
            <Select
              className="selectBorrow"
              labelInValue
              defaultValue={{ value: '', label: 'All Borrowers' }}
              value={{ value: borrowedValueMin, label: (borrowedValueMin === '') ? 'All Borrowers' : `Borrowers > $${Number(borrowedValueMin).toLocaleString()}` }}
              style={{ width: '205px' }}
              onChange={handleChangeBorrowers}
            >
              <Option value="">All Borrowers</Option>
              <Option value="100">Borrowers &gt; $100</Option>
              <Option value="1000">Borrowers &gt; $1,000</Option>
              <Option value="10000">Borrowers &gt; $10,000</Option>
              <Option value="100000">Borrowers &gt; $100,000</Option>
              <Option value="1000000">Borrowers &gt; $1,000,000</Option>
            </Select>
          </Col>
          <Col>
            <Select
              labelInValue
              defaultValue={{ value: '-1', label: 'All Shortfall' }}
              value={{ value: shortfall, label: shortfallLable[shortfall] }}
              style={{ width: '205px' }}
              onChange={handleChangeShortfall}
            >
              <Option value="-1">All Shortfall</Option>
              <Option value="0">Shortfall=$0</Option>
              <Option value="10">Shortfall&gt;$10</Option>
              <Option value="5">$0&lt;Shortfall&lt;=$10</Option>
            </Select>
          </Col>
          <Col span="10" className="searchInput">
            {/* <Search placeholder="Search Address" allowClear onSearch={onSearch}/> */}
            <Input placeholder="Search Address" allowClear value={Address} onChange={SearchOnchange} addonAfter={<SearchOutlined onClick={onSearch} />} />
          </Col>
          {/* <Input.Group compact>
        <Input
          style={{ width: 'calc(100% - 200px)' }}
          defaultValue="git@github.com:ant-design/ant-design.git"
        />
        <Tooltip title="copy git url">
          <Button icon={<SearchOutlined />} />
        </Tooltip>
      </Input.Group> */}
        </Row>
        <Table
          className="liquidationListTable"
          onRow={record => {
            return {
              onClick: event => { navigate(`/Liquidate/${record.userAddress}${queryString}`) }, // 点击行
              onDoubleClick: event => { },
              onContextMenu: event => { },
              onMouseEnter: event => { }, // 鼠标移入行
              onMouseLeave: event => { },
            };
          }}
          columns={columns}
          rowKey={record => record.userAddress}
          dataSource={data}
          pagination={pagination}
          loading={loading}
          onChange={handleTableChange}
        />
      </FlexBoxWrap>
    </>
  )
}


const PriceTitleSub = styled.div`
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  width: 600px;
  @media (max-width: 1199px) {
    width: 90%;
    font-size: 12px;
    line-height: 16px;
  }
`
const AssetWrap = styled.div`
  height: 224px;
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 1199px) {
    height: auto;
    padding: 16px 0;
  }
`
const StyledTitle = styled.div`
  color: #FFF;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
  @media (max-width: 1199px) {
    font-size: 16px;
    line-height: 28px;
  }
`
const TabWrapInner = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  @media (max-width: 1199px) {
    min-width: 100%;
    /* flex-direction: column; */
    flex-wrap: wrap;
  }
`
const Crumb = styled.div`
  display:flex;
  justify-content:space-between;
  align-items:center;
  width:100px;
  height:32px;
  /* margin-top: 15px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.08); */
  padding-left: 8px;
  @media (max-width: 1199px) {
    height: 24px;
    margin-top: 3px;
  }
`
const RouterLink = styled(NavLink)`
`
const BackToHome = styled.b`
  display:flex;
  align-items:center;
  font-size:16px;
  height:30px;
  line-height:30px;
  color: #fff;
  font-weight: 500;
  &:before {            
    content: '';            
    display: block;
    width: 8px;            
    height: 8px; 
    margin-right:5px;      
    border-right: 2px solid ${props => props.theme.colors.lightGrey};            
    border-top: 2px solid ${props => props.theme.colors.lightGrey};            
    -webkit-transform: rotate(-135deg); /*箭头方向可以自由切换角度*/            
    transform: rotate(-135deg);
}
`

export default Liquidation

