import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import UnitusLogo from 'assets/home/logo-Unitus.svg'
const Logo: React.FC = () => {
  return (
    <StyledLogo href={'https://unitus.finance/'} rel="noopener noreferrer">
      <LogoImg src={UnitusLogo}></LogoImg>
    </StyledLogo>
  )
}

const StyledLogo = styled.a`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
  text-decoration: none;
`

const LogoImg = styled.img`
  width:110px;
  height:28px;
  @media(max-width: 1199px) {
    width:75.5px;
    height:17px;
  }
`
export default Logo