const INFURA_KEY = process.env.REACT_APP_INFURA_KEY

const chain_configs = {
    'mainnet': {
        chainId: 1,
        rpc: `https://mainnet.infura.io/v3/${INFURA_KEY}`,
    },
    'arbitrum': {
        chainId: 42161,
        rpc: 'https://arb1.arbitrum.io/rpc',
    },
    'bsc': {
        chainId: 56,
        rpc: 'https://bsc-dataseed.binance.org/',
    },
    'optimism': {
        chainId: 10,
        rpc: 'https://mainnet.optimism.io/',
    },
    'polygon': {
        chainId: 137,
        rpc: 'https://polygon-rpc.com',
    },
    'avalanche': {
        chainId: 43114,
        rpc: 'https://api.avax.network/ext/bc/C/rpc',
    },
    'kava': {
        chainId: 2222,
        rpc: 'https://evm.kava.io',
    },
    'ConfluxeSpace': {
        chainId: 1030,
        rpc: 'https://evm.confluxrpc.com',
    },
    'zkSyncEra': {
        chainId: 324,
        rpc: 'https://mainnet.era.zksync.io',
    }
}


export const configs = {
    'mainnet': {
        isTest: false,
        network_name: 'Ethereum',
        chainId: 1,
        rpc: `https://mainnet.infura.io/v3/${INFURA_KEY}`,
        USX: {
            address: '0x0a5E677a6A24b2F1A2Bf4F3bFfC443231d2fDEc8'
        },
        DF: {
            address: '0x431ad2ff6a9C365805eBaD47Ee021148d6f7DBe0',
        },
        UTS: {
            address: '0x3B6564b5DA73A41d3A66e6558a98FD0e9E1e77ad',
        },
        'USX-mainnet-arbitrum': {
            bridge: '0x870ac6a76A30742800609F205c741E86Db9b71a2',
            approve_to: '0x870ac6a76A30742800609F205c741E86Db9b71a2',
            type: 'dForce_bridge',
            target_chain: {
                chainId: 42161,
                rpc: 'https://arb1.arbitrum.io/rpc',
                target_bridge: '0x1C4d5eCFBf2AF57251f20a524D0f0c1b4f6ED1C9'
            }
        },
        'DF-mainnet-arbitrum': {
            bridge: '0x72Ce9c846789fdB6fC1f34aC4AD25Dd9ef7031ef',
            approve_to: '0xcEe284F754E854890e311e3280b767F80797180d',
            type: 'dForce_bridge',
            target_chain: {
                chainId: 42161,
                rpc: 'https://arb1.arbitrum.io/rpc',
                target_bridge: '0x096760f208390250649e3e8763348e783aef5562'
            }
        },
        'USX-mainnet-optimism': {
            bridge: '0xC5b1EC605738eF73a4EFc562274c1c0b6609cF59',
            approve_to: '0xC5b1EC605738eF73a4EFc562274c1c0b6609cF59',
            messenger: '0x25ace71c97B33Cc4729CF772ae268934F7ab5fA1',
            type: 'dForce_bridge__op',
            target_chain: {
                chainId: 10,
                rpc: 'https://mainnet.optimism.io',
            }
        },
        'DF-mainnet-optimism': {
            bridge: '0x99C9fc46f92E8a1c0deC1b1747d010903E884bE1',
            approve_to: '0x99C9fc46f92E8a1c0deC1b1747d010903E884bE1',
            type: 'dForce_bridge__op',
            target_chain: {
                chainId: 10,
                rpc: 'https://mainnet.optimism.io',
            }
        },
        'DF-mainnet-polygon': {
            bridge: '0xA0c68C638235ee32657e8f720a23ceC1bFc77C77',
            approve_to: '0x40ec5B33f54e0E8A33A975908C5BA1c14e5BbbDf',
            type: 'dForce_bridge__polygon',
            target_chain: {
                chainId: 137,
                rpc: 'https://polygon-rpc.com',
            }
        },
        bridge: '0x5427FEFA711Eff984124bFBB1AB6fbf5E3DA1820',
        target_chain: chain_configs
    },
    'arbitrum': {
        isTest: false,
        network_name: 'Arbitrum',
        chainId: 42161,
        rpc: 'https://arb1.arbitrum.io/rpc',
        USX: {
            address: '0x641441c631e2F909700d2f41FD87F0aA6A6b4EDb'
        },
        DF: {
            address: '0xaE6aab43C4f3E0cea4Ab83752C278f8dEbabA689',
        },
        UTS: {
            address: '0x3B6564b5DA73A41d3A66e6558a98FD0e9E1e77ad',
        },
        bridge: '0x1619DE6B6B20eD217a58d00f37B9d47C7663feca',
        target_chain: chain_configs
    },
    'polygon': {
        isTest: false,
        network_name: 'Polygon',
        chainId: 137,
        rpc: 'https://polygon-rpc.com',
        USX: {
            address: '0xCf66EB3D546F0415b368d98A95EAF56DeD7aA752',
        },
        DF: {
            address: '0x08C15FA26E519A78a666D19CE5C646D55047e0a3',
        },
        UTS: {
            address: '0x3B6564b5DA73A41d3A66e6558a98FD0e9E1e77ad',
        },
        bridge: '0x88DCDC47D2f83a99CF0000FDF667A468bB958a78',
        target_chain: chain_configs
    },
    'bsc': {
        isTest: false,
        network_name: 'BSC',
        chainId: 56,
        rpc: 'https://bsc-dataseed.binance.org/',
        USX: {
            address: '0xB5102CeE1528Ce2C760893034A4603663495fD72',
        },
        DF: {
            address: '0x4A9A2b2b04549C3927dd2c9668A5eF3fCA473623',
        },
        UTS: {
            address: '0x3B6564b5DA73A41d3A66e6558a98FD0e9E1e77ad',
        },
        bridge: '0xdd90E5E87A2081Dcf0391920868eBc2FFB81a1aF',
        target_chain: chain_configs
    },
    'optimism': {
        isTest: false,
        network_name: 'Optimism',
        chainId: 10,
        rpc: 'https://mainnet.optimism.io/',
        USX: {
            address: '0xbfD291DA8A403DAAF7e5E9DC1ec0aCEaCd4848B9',
        },
        DF: {
            address: '0x9e5AAC1Ba1a2e6aEd6b32689DFcF62A509Ca96f3',
        },
        UTS: {
            address: '0x3B6564b5DA73A41d3A66e6558a98FD0e9E1e77ad',
        },
        bridge: '0x9D39Fc627A6d9d9F8C831c16995b209548cc3401',
        target_chain: chain_configs
    },
    'ConfluxeSpace': {
        isTest: false,
        network_name: 'Conflux eSpace',
        chainId: 1030,
        rpc: 'https://evm.confluxrpc.com',
        USX: {
            address: '0x422a86f57b6b6F1e557d406331c25EEeD075E7aA',
        },
        DF: {
            address: '0x53aa2b7bead41614577ba5b636c482790c5f54c5',
        },
        UTS: {
            address: '0x3B6564b5DA73A41d3A66e6558a98FD0e9E1e77ad',
        },
        bridge: '0x841ce48f9446c8e281d3f1444cb859b4a6d0738c',
        target_chain: chain_configs
    },
    // 'zkSyncEra': {
    //     isTest: false,
    //     network_name: 'zkSync Era',
    //     chainId: 324,
    //     rpc: 'https://mainnet.era.zksync.io',
    //     USX: {
    //         address: '0xdb89D7b0Dccd0C0e5aC3571133A9aa1a037945cb',
    //     },
    //     bridge: '0x54069e96C4247b37C2fbd9559CA99f08CD1CD66c',
    //     target_chain: chain_configs
    // },
    // 'kava': {
    //     isTest: false,
    //     network_name: 'Kava',
    //     chainId: 2222,
    //     rpc: 'https://evm.kava.io',
    //     USX: {
    //         address: '0xDb0E1e86B01c4ad25241b1843E407Efc4D615248',
    //     },
    //     bridge: '0xb51541df05DE07be38dcfc4a80c05389A54502BB',
    //     target_chain: chain_configs
    // },
    // 'avalanche': {
    //     isTest: false,
    //     network_name: 'Avalanche',
    //     chainId: 43114,
    //     rpc: 'https://api.avax.network/ext/bc/C/rpc',
    //     USX: {
    //         address: '0x853ea32391AaA14c112C645FD20BA389aB25C5e0',
    //     },
    //     bridge: '0xef3c714c9425a8f3697a9c969dc1af30ba82e5d4',
    //     target_chain: chain_configs
    // },
}

export const chainId_map = {
    1: 'mainnet',
    137: 'polygon',
    42161: 'arbitrum',
    56: 'bsc',
    97: 'bsc-test',
    10: 'optimism',
    43114: 'avalanche',
    2222: 'kava',
    1030: 'ConfluxeSpace',
    324: 'zkSyncEra'
}


// metamask 添加网络
export const chainInfos = {
    1: {
        chainId: 1
    },
    10: {
        chainId: 10,
        chainName: 'Optimism',
        rpcUrls: ['https://mainnet.optimism.io/'],
        blockExplorerUrls: ['https://optimistic.etherscan.io/'],
        name: 'OETH',
        symbol: 'OETH'
    },
    56: {
        chainId: 56,
        chainName: 'BSC',
        rpcUrls: ['https://bsc-dataseed.binance.org/'],
        blockExplorerUrls: ['https://bscscan.com'],
        name: 'BNB',
        symbol: 'BNB'
    },
    42161: {
        chainId: 42161,
        chainName: 'Arbitrum',
        rpcUrls: ['https://arb1.arbitrum.io/rpc'],
        blockExplorerUrls: ['https://arbiscan.io'],
        name: 'ETH',
        symbol: 'ETH'
    },
    137: {
        chainId: 137,
        chainName: 'Polygon',
        rpcUrls: ['https://polygon-rpc.com'],
        blockExplorerUrls: ['https://polygonscan.com/'],
        name: 'MATIC',
        symbol: 'MATIC'
    },
    43114: {
        chainId: 43114,
        chainName: 'Avalanche',
        rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
        blockExplorerUrls: ['https://snowtrace.io/'],
        name: 'AVAX',
        symbol: 'AVAX'
    },
    2222: {
        chainId: 2222,
        chainName: 'Kava',
        rpcUrls: ['https://evm.kava.io'],
        blockExplorerUrls: ['https://explorer.kava.io/'],
        name: 'Kava',
        symbol: 'KAVA'
    },
    1030: {
        chainId: 1030,
        chainName: 'Conflux eSpace',
        rpcUrls: ['https://evm.confluxrpc.com'],
        blockExplorerUrls: ['https://evm.confluxscan.net/'],
        name: 'Conflux eSpace',
        symbol: 'CFX'
    },
    324: {
        chainId: 324,
        chainName: 'zkSync Era',
        rpcUrls: ['https://mainnet.era.zksync.io'],
        blockExplorerUrls: ['https://explorer.zksync.io/'],
        name: 'zkSync Era',
        symbol: 'ETH'
    },

    // test
    97: {
        chainId: 97,
        chainName: 'BSC TestNet',
        rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
        blockExplorerUrls: ['https://testnet.bscscan.com'],
        name: 'BNB',
        symbol: 'BNB'
    }
}

// metamask 添加token
export const addtokenConfigs = {
    'USX': {
        address: {
            1: configs['mainnet'].USX.address,
            42161: configs['arbitrum'].USX.address,
            137: configs['polygon'].USX.address,
            56: configs['bsc'].USX.address,
            10: configs['optimism'].USX.address,
            1030: configs['ConfluxeSpace'].USX.address,
            // 43114: configs['avalanche'].USX.address,
            // 2222: configs['kava'].USX.address,
            // 324: configs['zkSyncEra'].USX.address,
        },
        symbol: 'USX',
        decimals: 18,
    },
    'DF': {
        address: {
            1: configs['mainnet'].DF.address,
            42161: configs['arbitrum'].DF.address,
            137: configs['polygon'].DF.address,
            56: configs['bsc'].DF.address,
            10: configs['optimism'].DF.address,
            1030: configs['ConfluxeSpace'].DF.address,
        },
        symbol: 'DF',
        decimals: 18,
    },
    'UTS': {
        address: {
            1: configs['mainnet'].UTS.address,
            42161: configs['arbitrum'].UTS.address,
            137: configs['polygon'].UTS.address,
            56: configs['bsc'].UTS.address,
            10: configs['optimism'].UTS.address,
            1030: configs['ConfluxeSpace'].UTS.address,
        },
        symbol: 'UTS',
        decimals: 18,
    }
}

export const lzChainConfigs = {
    1: {
        "ethereum": 101,
        lzChainId: 101,
        SafetyFactor: 1
    },
    56: {
        "bsc": 102,
        lzChainId: 102,
        SafetyFactor: 1
    },
    43114: {
        "avalanche": 106,
        lzChainId: 106,
        SafetyFactor: 1
    },
    137: {
        "polygon": 109,
        lzChainId: 109,
        SafetyFactor: 1
    },
    42161: {
        "arbitrum": 110,
        lzChainId: 110,
        SafetyFactor: 3
    },
    10: {
        "optimism": 111,
        lzChainId: 111,
        SafetyFactor: 1
    },
    1030: {
        "conflux": 212,
        lzChainId: 212,
        SafetyFactor: 1
    }
}