import React from "react"
import { useIntl } from "react-intl"
import styled from "styled-components"
import numeral from "numeral"
import BigNumber from "bignumber.js"
import { _formatNumberNotCarry, bnToDec } from "utils"
import LendTitle from "components/LendTitle"


interface TotalAssetsProps {
  totalAssets: Record<string,any>[];
}
const TotalAssets: React.FC<TotalAssetsProps> = ({ totalAssets }) => {
  const intl = useIntl()
  const fmt = intl.formatMessage
  // console.log(JSON.stringify(totalAssets, null, 2))
  return (
    <>
      <AssetsWarp>
        {
          totalAssets?.map((_item:any)=>
            <AssetsItem>
            <Ad>
              {
                _item["value"] ?
                ((bnToDec(new BigNumber(_item["value"]), 18) > 0 && bnToDec(new BigNumber(_item["value"]), 18) < 0.01)
                  ? '<$0.01'
                  :_item["value"] === '0' ?
                  '$0.00' 
                  :`${_item.dollar ? '$' : ''}${_formatNumberNotCarry(bnToDec(new BigNumber((_item["value"])), 18,4),'abbr')}`)
                : '...'
              }
                {
                    _item.thresholdStatus === 0 ?
                    <span className="red">({fmt({id:'Noemissions'})})</span>
                    :_item.thresholdStatus === 1 ?
                    <span className="red">
                      (${_item.needed && _formatNumberNotCarry(bnToDec(_item.needed, 18,4),'abbr')}&nbsp;{fmt({id:'eligible'})})
                    </span>
                    : _item.thresholdStatus === 2 ?
                    <span className="green">({fmt({id:'Eligibleforemissions'})})</span>
                    :''
                }
            </Ad>
            <p>
              <span>{fmt({ id: _item["title"] })}</span>
            </p>
          </AssetsItem>
          )
        }
      </AssetsWarp>
    </>
  )
}



const StyledTitle = styled.h1`
  height: 60px;
  font-size: 22px;
  font-weight: bold;
  line-height: 60px;
  margin:10px 0 0;
  color:${(props) => props.theme.colors.white};

  @media(max-width: 1119px) {
    font-size:18px;
    height:48px;
    line-height: 48px;
    margin:0
  }
`
const AssetsWarp = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  color:#ffffff;
  @media(max-width: 1119px){
    /* width: calc(100% - 24px); */
    /* margin: 30px auto 0; */
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    /* row-gap: 15px; */
  }
`;
const AssetsItem = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 200px;
  margin-right:15px;
  /* border-radius: 16px; */
  box-sizing: border-box;
  border-right: 1px solid ${(props) => props.theme.colors.darkTableBorder};
  &:nth-last-of-type(1){
    border-right:none
  }
  p {
    /* color: ${(props) => props.theme.fontColor[100]}; */
    /* color: ${(props) => props.theme.global_grey}; */
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    color: ${(props) => props.theme.fontColor[999]};
    
    /* @media(max-width: 1119px){
      color: ${(props) => props.theme.DarkfontColor[100]};
      .dark & {
        color: ${(props) => props.theme.fontColor[100]};
      }
    } */
  }
  @media(max-width: 1119px){
    display: flex;
    min-width: auto;
    flex-basis: 48%;
    margin-right: 0;
    p{
      margin-bottom: 20px;
    }
    &:nth-child(even){
      border-right:none;
    }
    
  }
`;
const Ad = styled.div`
    font-size: 28px;
    line-height: 28px;
    margin-bottom: 14px;
    font-weight: 600;
    color: ${(props) => props.theme.fontColor[100]};
    span{
      margin-left: 5px;
      font-size: 18px;
    }
    .red{
      color:${(props) => props.theme.colors.red};
    }
    .green{
      color:${(props) => props.theme.colors.borrow.dark};
    }
    @media(max-width: 1119px){
      margin-bottom: 6px;
      font-size: 16px;
      color: ${(props) => props.theme.fontColor[100]};
      span{
        font-size: 14px;
      }
      /* color: ${(props) => props.theme.DarkfontColor[100]};
      .dark & {
        color: ${(props) => props.theme.fontColor[100]};
      } */
    }
`

export default TotalAssets
