import React from 'react'
import styled from 'styled-components'
import close from "assets/stake/close.svg";
import modalbg from "assets/TotalAssets/bg1.svg"
interface ModalTitleProps {
  text?: string,
  icon?: string,
  onDismiss?: () => void;
}

const ModalTitle: React.FC<ModalTitleProps> = ({ text, icon, onDismiss }) => {
  let ImgIcon
  try {
    ImgIcon = icon
  } catch (error) {
    ImgIcon = undefined
  }
    return (
      <StyledModalTitle>
        {ImgIcon && <img className="icon" src={ImgIcon} alt={text}/>}
        {text}
        <CloseModal src={close} onClick={onDismiss}/>
      </StyledModalTitle>
    )
  }

const CloseModal = styled.img`
  position:absolute;
  top:30px;
  right:20px;
  width:20px;
  height:20px;
  cursor: pointer;
  @media(max-width: 1199px) {
    top:15px;
    right:20px;
    width:16px;
    height:16px;
  }
`
const StyledModalTitle = styled.div`
  display: flex;
  flex-direction:column;
  align-items: center;
  justify-content: space-between;
  position:relative;
  font-size: 18px;
  font-weight: 700;
  height:auto;
  padding:30px 0;
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.supply.dark};
  background-image:url(${modalbg});
  background-size:cover;
  border-top-left-radius:${(props) => props.theme.borderradius};
  border-top-right-radius:${(props) => props.theme.borderradius};
  img.icon{
    height:50px;
    margin-bottom:18px;
  }
  @media(max-width: 1199px) {
    height: auto;
    line-height:20px;
    font-size: 14px;
    padding:15px 0;
    img.icon{
      display:inline-block;
      height:25px;
      margin-bottom:9px;
    }
  }
`



export default ModalTitle