import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { transationLink } from 'lending-sdk/utils/index1'
import img_pedding from 'assets/supply/pedding.svg'
import img_view_to from 'assets/supply/View.svg'
import img_success from 'assets/supply/success.svg'
import img_fail from 'assets/supply/fail.svg'
import img_close from 'assets/supply/close.svg'
import arrow_right from 'assets/supply/arrow_right.svg'
import useNotify from 'hooks/useNotify'

interface NotificationProps {
  notifies?: any;
  del_Notify?: any;
  setNowNotifies: React.Dispatch<React.SetStateAction<any>>;
  chainID?: number | null
}

const Notification: React.FC<NotificationProps> = ({ notifies, del_Notify, setNowNotifies }) => {
  // state: "pedding",
  // action: "Receive " + format_num_to_K(format_bn(estimated_receive, 18, 4)) + " " + curToken,
  // result: result,
  // time: new Date().getTime(),
  return (
    <>
      {
        notifies && notifies.map && notifies.map((item: any, index: any) => {
          return (
            <StyledNotification key={index} state={item.state}>
              <StyledStatusimg
                src={
                  item.state === 'pedding'
                    ? img_pedding
                    : item.state === 'success' ? img_success : img_fail
                }
                className={item.state === 'pedding' ? 'rotate' : ''}
                alt="" />
              <StyledTitle>{item.action}</StyledTitle>

              {
                item.transactionHash &&
                <StyledView target='_blank' href={item.transactionHash}>
                  <StyledStatusimg src={img_view_to} alt="" />
                </StyledView>
              }

              <StyledClose src={img_close} alt="" onClick={() => { del_Notify(item, setNowNotifies) }} />
            </StyledNotification>
          )
        })
      }
    </>
  )
}

const StyledView = styled.a`
  font-size: 16px;
  font-weight: bold;
  position: absolute;
  right: 45px;
  color: ${(props: any) => props.theme.global_supply};
  &:hover{
    color:${(props: any) => props.theme.global_supply}!important
  }
`
const StyledTitle = styled.span`
  font-size: 16px;
  font-weight: bold;
  color: #9195A4;
  padding-right: 120px;

  .dark & {
    color: #fff;
  }
`
const StyledClose = styled.img`
  position: absolute;
  right: 15px;
  top: 17px;
  cursor: pointer;
`
const StyledStatusimg = styled.img`
  width: 32px;
  margin-left: 15px;
  margin-right: 12px;
`
const StyledNotificationWrap = styled.div`
  position: fixed;
  right: 15px;
  top: 85px;
  user-select: none;
  z-index:99;
`
interface NotiProps {
  state?: string
}
const StyledNotification = styled.div<NotiProps>`
  display:flex;
  align-items:center;
  min-width: 246px;
  height: 70px;
  user-select: none;
  margin-bottom: 10px;
  position: relative;
  /* line-height: 66px; */
  background-color:#fff;
  /* border: 2px solid #DFE0E5; */
  border: 2px solid #00c087;
  border-color: ${(props) => props.state === 'pedding' || props.state === 'success' ? '#00c087' : '#F84D41'};
  border-radius: 8px;

  .dark & {
    background: #414763;
    /* border: 2px solid #1C2242; */
  }
`

export default Notification