import BigNumber from "bignumber.js";
import { reverseAddressMap } from "utils";
// 读取 assetsToken 配置文件
import general_DL_Token from 'lending-sdk/lib/clean_build/contracts/general/DL_Token.json';
import synthetic_DL_Token from 'lending-sdk/lib/clean_build/contracts/synthetic/DL_Token.json';
import stocks_DL_Token from 'lending-sdk/lib/clean_build/contracts/stocks/DL_Token.json';

export const fetchPrice = async (networkName) => {
  let reverseTokenMap, PriceTokenData
  const network_id = {
    'mainnet': '1',
    'ArbitrumOne': '42161',
    'Optimism': '10',
    'bsc': '56',
    'Polygon': '137',
    'ConfluxeSpace': '1030',
    '144545313136048': 'arbitrum',
    'kovan': '42',
    'bsctest': '97',
    'heco': '128',
    'ConfluxTest': '71',
    'Sepolia': '11155111',
  }

  // const { underlyingToken } = networkName ?
  //   await requestAPI(`https://beta.unitus.finance/general/markets?network=${networkName}`)
  //   :
  //   await requestAPI(`https://beta.unitus.finance/general/markets?network=mainnet`)

  try {
    if (networkName) {
      const response = await fetch(`https://beta.unitus.finance/general/markets?network=${networkName}`)
      PriceTokenData = await response.json();
    } else {
      const response = await fetch(`https://beta.unitus.finance/general/markets?network=mainnet`)
      PriceTokenData = await response.json();
    }
  } catch (error) {
    console.log(error)
  }
  if (network_id[networkName]) {
    // console.log(networkName,11111)
    reverseTokenMap = {
      "general": reverseAddressMap(general_DL_Token, network_id[networkName]),
      "synthetic": reverseAddressMap(synthetic_DL_Token, network_id[networkName]),
      "stocks": reverseAddressMap(stocks_DL_Token, network_id[networkName])
    }
  } else {
    // console.log(networkName,22222)
    reverseTokenMap = {
      "general": reverseAddressMap(general_DL_Token, 1),
      "synthetic": reverseAddressMap(synthetic_DL_Token, 1),
      "stocks": reverseAddressMap(stocks_DL_Token, 1)
    }
  }

  // console.log('chainid: ', network_id[networkName])
  // console.log( general_DL_Token.networks[network_id[networkName]])
  // console.log('tmpCheckFreezeObject: ', tmpCheckFreezeObject)
  const tmpCheckFreezeObject = general_DL_Token.networks[network_id[networkName]];

  const newUnderlyingToken = Array.isArray(PriceTokenData?.underlyingToken) ?
    PriceTokenData?.underlyingToken.map((item) => {
      let icon
      const symbol = item.symbol
      try {
        icon = require(`assets/tokenList/${symbol}.svg`);
      } catch (error) {
        icon = require(`assets/tokenList/default.svg`).default;
      }

      let isFreezed
      try {
        // console.log({ ...item, icon })
        // console.log(tmpCheckFreezeObject['i' + item.symbol].isFreeze)
        isFreezed = tmpCheckFreezeObject['i' + item.symbol].isFreeze ?? false
      } catch (error) {
        // console.log(error)
        isFreezed = false
      }

      return {
        ...item,
        //  "underlying": "0xF76eAd4da04BbeB97d29F83e2Ec3a621d0FB3c6e",
        // "symbol": "USX",
        // "price": "1000000000000000000",
        // "priceStatus": "true"
        icon,
        isFreeze: isFreezed
      }
    })
    :
    []
  // console.log(newUnderlyingToken,newUnderlyingToken.length)
  return { underlyingToken: newUnderlyingToken }
}
