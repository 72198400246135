"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LendingDataV2 = void 0;
const ethers_1 = require("ethers");
// import { BorrowConstraintReason } from "./types";
const multicall_1 = require("@0xsequence/multicall");
const index_1 = require("./abi/index");
const utils_1 = require("./utils");
const constants_1 = require("./constants");
var BorrowConstraintReason;
(function (BorrowConstraintReason) {
    BorrowConstraintReason[BorrowConstraintReason["AccountEquity"] = 0] = "AccountEquity";
    BorrowConstraintReason[BorrowConstraintReason["BorrowCap"] = 1] = "BorrowCap";
    BorrowConstraintReason[BorrowConstraintReason["DebtCeiling"] = 2] = "DebtCeiling";
    BorrowConstraintReason[BorrowConstraintReason["Cash"] = 3] = "Cash";
    BorrowConstraintReason[BorrowConstraintReason["NotAllowedSegregation"] = 4] = "NotAllowedSegregation";
    BorrowConstraintReason[BorrowConstraintReason["NotAllowedSupercharged"] = 5] = "NotAllowedSupercharged";
})(BorrowConstraintReason || (BorrowConstraintReason = {}));
class LendingDataV2 {
    constructor(controller, provider) {
        this.iTokens = new Map();
        this.interestRateModels = new Map();
        this.underlyings = new Map();
        this.iTokenInitialized = false;
        this._smodeLabels = new Array();
        this.getSupplyTokenData = (token, account) => __awaiter(this, void 0, void 0, function* () {
            // If `token` is not a valid iToken, it will revert.
            const iToken = yield this.getIToken(token);
            const [market, accountSMode, ltv, smodeLTV, lt, smodeLT, supplyRatePerUnit,] = yield Promise.all([
                this.controller.marketsV2(token),
                this.controller.accountsSMode(account),
                this.controller.getLTV(token),
                this.controller.getSModeLTV(token),
                this.controller.getLiquidationThreshold(token),
                this.controller.getSModeLiquidationThreshold(token),
                iToken.supplyRatePerUnit(),
            ]);
            const isSuperCharged = accountSMode == market.sModeID && market.sModeID > 0;
            const collateralFactor = isSuperCharged ? smodeLTV : ltv;
            const liquidationThreshold = isSuperCharged ? smodeLT : lt;
            const interestPerDay = supplyRatePerUnit.mul(constants_1.UNITS_PER_DAY);
            const rawApy = Math.pow(interestPerDay.add(constants_1.BASE) / Math.pow(10, 18), constants_1.DAYS_PER_YEAR.toNumber());
            const apy = ethers_1.utils.parseEther(rawApy.toString()).sub(constants_1.BASE);
            const assetUSDPrice = yield this._getAssetUSDPrice(token);
            return [
                apy,
                collateralFactor,
                assetUSDPrice,
                isSuperCharged,
                liquidationThreshold,
            ];
        });
        this.getBorrowTokenData = (token) => __awaiter(this, void 0, void 0, function* () {
            // If `token` is not a valid iToken, it will revert.
            const iToken = yield this.getIToken(token);
            let borrowFactor = constants_1.ZERO;
            let apy = constants_1.ZERO;
            let assetUSDPrice = constants_1.ZERO;
            const [marketDetails, borrowRatePerUnit, cash] = yield Promise.all([
                this.controller.marketsV2(token),
                iToken.borrowRatePerUnit(),
                iToken.getCash(),
            ]);
            borrowFactor = marketDetails.borrowFactorMantissa;
            const interestPerDay = borrowRatePerUnit.mul(constants_1.UNITS_PER_DAY);
            const rawApy = Math.pow(interestPerDay.add(constants_1.BASE) / Math.pow(10, 18), constants_1.DAYS_PER_YEAR.toNumber());
            apy = ethers_1.utils.parseEther(rawApy.toString()).sub(constants_1.BASE);
            assetUSDPrice = yield this._getAssetUSDPrice(token);
            return [cash, borrowFactor, apy, assetUSDPrice];
        });
        this.getAccountTotalValue = (account) => __awaiter(this, void 0, void 0, function* () {
            const alliTokens = Array.from((yield this.getAllITokens()).values());
            const priceOracle = yield this.priceOracle;
            const [collaterals, borrows, smodeID, ...res] = yield Promise.all([
                this.controller.getEnteredMarkets(account),
                this.controller.getBorrowedAssets(account),
                this.controller.accountsSMode(account),
                ...alliTokens
                    .map((iToken) => [
                    this.controller.marketsV2(iToken.address),
                    this.controller.hasEnteredMarket(account, iToken.address),
                    priceOracle.callStatic.getUnderlyingPrice(iToken.address),
                    iToken.callStatic.balanceOfUnderlying(account),
                    iToken.callStatic.borrowBalanceCurrent(account),
                ])
                    .flat(),
            ]);
            const { sumCollateralLiq, sumBorrowed } = yield this.calcAccountEquity(account, collaterals, borrows, smodeID);
            const tokenRes = (0, utils_1.chunkArray)(res, 5);
            let supplyValue = constants_1.ZERO;
            let collateralVaule = constants_1.ZERO;
            let borrowValue = constants_1.ZERO;
            for (const [market, asCol, price, supply, borrow] of tokenRes) {
                supplyValue = supplyValue.add((0, utils_1.rmul)(supply, price));
                if (asCol && market.collateralFactorMantissa.gt(0))
                    collateralVaule = collateralVaule.add((0, utils_1.rmul)(supply, price));
                borrowValue = borrowValue.add((0, utils_1.rmul)(borrow, price));
            }
            const adequacyRatio = sumBorrowed.isZero()
                ? ethers_1.constants.MaxUint256
                : (0, utils_1.rdiv)(sumCollateralLiq, sumBorrowed);
            return [supplyValue, collateralVaule, borrowValue, adequacyRatio];
        });
        this.getAccountSupplyTokens = (account) => __awaiter(this, void 0, void 0, function* () {
            const alliTokens = Array.from((yield this.getAllITokens()).values());
            const [...res] = yield Promise.all([
                ...alliTokens
                    .map((iToken) => [
                    iToken.address,
                    iToken.callStatic.balanceOfUnderlying(account),
                    iToken.decimals(),
                ])
                    .flat(),
            ]);
            const tokenRes = (0, utils_1.chunkArray)(res, 3);
            const supply = tokenRes.filter(([, balance]) => balance.gt(0));
            const iTokens = supply.map((e) => e[0]);
            const balances = supply.map((e) => e[1]);
            const decimals = supply.map((e) => e[2]);
            return [iTokens, balances, decimals];
        });
        this.getAccountBorrowTokens = (account) => __awaiter(this, void 0, void 0, function* () {
            const alliTokens = Array.from((yield this.getAllITokens()).values());
            const [...res] = yield Promise.all([
                ...alliTokens
                    .map((iToken) => [
                    iToken.address,
                    iToken.callStatic.borrowBalanceCurrent(account),
                    iToken.decimals(),
                    iToken.isiToken(),
                ])
                    .flat(),
            ]);
            const tokenRes = (0, utils_1.chunkArray)(res, 4);
            const borrow = tokenRes.filter(([, balance, , isiToken]) => balance.gt(0) && isiToken);
            const iTokens = borrow.map((e) => e[0]);
            const balances = borrow.map((e) => e[1]);
            const decimals = borrow.map((e) => e[2]);
            return [iTokens, balances, decimals];
        });
        this.getAccountMSDTokens = (account) => __awaiter(this, void 0, void 0, function* () {
            const alliTokens = Array.from((yield this.getAllITokens()).values());
            const [...res] = yield Promise.all([
                ...alliTokens
                    .map((iToken) => [
                    iToken.address,
                    iToken.callStatic.borrowBalanceCurrent(account),
                    iToken.decimals(),
                    iToken.isiToken(),
                ])
                    .flat(),
            ]);
            const tokenRes = (0, utils_1.chunkArray)(res, 4);
            const mint = tokenRes.filter(([, balance, , isiToken]) => balance.gt(0) && !isiToken);
            const iTokens = mint.map((e) => e[0]);
            const balances = mint.map((e) => e[1]);
            const decimals = mint.map((e) => e[2]);
            return [iTokens, balances, decimals];
        });
        this.getAccountTokens = (account) => __awaiter(this, void 0, void 0, function* () {
            const alliTokens = Array.from((yield this.getAllITokens()).values());
            const [...res] = yield Promise.all([
                ...alliTokens
                    .map((iToken) => [
                    iToken.address,
                    iToken.callStatic.balanceOfUnderlying(account),
                    iToken.callStatic.borrowBalanceCurrent(account),
                    iToken.decimals(),
                ])
                    .flat(),
            ]);
            const tokenRes = (0, utils_1.chunkArray)(res, 4);
            const supply = tokenRes.filter(([, sBalance]) => sBalance.gt(0));
            const borrow = tokenRes.filter(([, , bBalance]) => bBalance.gt(0));
            const sITokens = supply.map((e) => e[0]);
            const sBalances = supply.map((e) => e[1]);
            const sDecimals = supply.map((e) => e[3]);
            const bITokens = borrow.map((e) => e[0]);
            const bBalances = borrow.map((e) => e[2]);
            const bdecimals = borrow.map((e) => e[3]);
            return [sITokens, sBalances, sDecimals, bITokens, bBalances, bdecimals];
        });
        this.getAccountSupplyData = (token, account, safeFactor) => __awaiter(this, void 0, void 0, function* () {
            const iToken = yield this.getIToken(token);
            const underlying = yield this.getUnderlying(token);
            const priceOracle = yield this.priceOracle;
            // Convert safeMaxFactor to BigNumber
            const safeMaxFactor = ethers_1.ethers.BigNumber.from(safeFactor);
            let [suppliedBalance, underlyingBalance, iTokenBalance, decimals, market, totalSupply, exchangeRate, isiToken, cash, assetPrice, asCollateral, ltv, smodeLTV, collaterals, borrows, accountSMode,] = yield Promise.all([
                iToken.callStatic.balanceOfUnderlying(account),
                this.getUnderlyingBalance(underlying, account),
                iToken.balanceOf(account),
                iToken.decimals(),
                this.controller.marketsV2(token),
                iToken.totalSupply(),
                iToken.callStatic.exchangeRateCurrent(),
                iToken.isiToken(),
                iToken.getCash(),
                priceOracle.callStatic.getUnderlyingPrice(token),
                this.controller.hasEnteredMarket(account, token),
                this.controller.getLTV(token),
                this.controller.getSModeLTV(token),
                this.controller.getEnteredMarkets(account),
                this.controller.getBorrowedAssets(account),
                this.controller.accountsSMode(account),
            ]);
            const { equity, sumCollateral, sumBorrowed } = yield this.calcAccountEquity(account, collaterals, borrows, accountSMode);
            // maxMint
            const totalUnderlying = (0, utils_1.rmul)(totalSupply, exchangeRate);
            let maxMint = (0, utils_1.min)(market.supplyCapacity.sub(totalUnderlying), underlyingBalance);
            let collateralFactor = accountSMode == market.sModeID && market.sModeID > 0 ? smodeLTV : ltv;
            const [availableToWithdrawFromEquity, safeAvailableToWithdrawFromEquity] = (() => {
                // Used as collateral and borrowed, need to check equity
                if (asCollateral && sumBorrowed.gt(0)) {
                    if (collateralFactor.isZero() && equity.gt(0)) {
                        return [ethers_1.constants.MaxUint256, ethers_1.constants.MaxUint256];
                    }
                    if (assetPrice.isZero() ||
                        collateralFactor.isZero() ||
                        equity.isZero()) {
                        // Override the iTokenBalance
                        iTokenBalance = ethers_1.BigNumber.from(0);
                        return [ethers_1.BigNumber.from(0), ethers_1.BigNumber.from(0)];
                    }
                    const safeEquity = sumCollateral.sub((0, utils_1.rdiv)(sumBorrowed, safeMaxFactor));
                    return [
                        (0, utils_1.rdiv)(equity.div(assetPrice), collateralFactor),
                        (0, utils_1.rdiv)(safeEquity.div(assetPrice), collateralFactor),
                    ];
                }
                return [ethers_1.ethers.constants.MaxUint256, ethers_1.ethers.constants.MaxUint256];
            })();
            // Can withdraw up to min(cash, balance, availableToWithdrawFromEquity)
            // iMSD should return 0 cash
            let availableToWithdraw = (0, utils_1.min)(cash, suppliedBalance, availableToWithdrawFromEquity);
            let safeAvailableToWithdraw = (0, utils_1.min)(availableToWithdraw, safeAvailableToWithdrawFromEquity);
            [maxMint, availableToWithdraw, safeAvailableToWithdraw] = (0, utils_1.ensureNonNegtive)(maxMint, availableToWithdraw, safeAvailableToWithdraw);
            return [
                suppliedBalance,
                underlyingBalance,
                maxMint,
                availableToWithdraw,
                safeAvailableToWithdraw,
                iTokenBalance,
                decimals,
            ];
        });
        this.getAccountBorrowData = (token, account, safeFactor) => __awaiter(this, void 0, void 0, function* () {
            const iToken = yield this.getIToken(token);
            const underlying = yield this.getUnderlying(token);
            const priceOracle = yield this.priceOracle;
            // Convert safeMaxFactor to BigNumber
            const safeMaxFactor = ethers_1.ethers.BigNumber.from(safeFactor);
            // Fixme: better way to get segregated collateral
            let [isInSegregation, segregatedCol] = yield this.controller.getSegregationModeState(account);
            let [borrowedBalance, underlyingBalance, decimals, market, marketCol, totalBorrows, isiToken, cash, assetPrice, collaterals, borrows, accountSMode,] = yield Promise.all([
                iToken.callStatic.borrowBalanceCurrent(account),
                this.getUnderlyingBalance(underlying, account),
                iToken.decimals(),
                this.controller.marketsV2(token),
                this.controller.marketsV2(segregatedCol),
                iToken.callStatic.totalBorrowsCurrent(),
                iToken.isiToken(),
                iToken.getCash(),
                priceOracle.callStatic.getUnderlyingPrice(token),
                this.controller.getEnteredMarkets(account),
                this.controller.getBorrowedAssets(account),
                this.controller.accountsSMode(account),
            ]);
            const { sumCollateral, sumBorrowed } = yield this.calcAccountEquity(account, collaterals, borrows, accountSMode);
            let [canBorrows, safeAvailableToBorrow, constraintedBy] = (() => {
                if (assetPrice.isZero() ||
                    market.borrowFactorMantissa.isZero() ||
                    market.borrowCapacity.isZero()) {
                    return [constants_1.ZERO, constants_1.ZERO, BorrowConstraintReason.BorrowCap];
                }
                if (accountSMode > 0 && accountSMode !== market.sModeID) {
                    return [constants_1.ZERO, constants_1.ZERO, BorrowConstraintReason.NotAllowedSupercharged];
                }
                let availableBorrowCap = [
                    market.borrowCapacity.sub(totalBorrows),
                    BorrowConstraintReason.BorrowCap,
                ];
                let availableDebtCeiling = [
                    constants_1.INFINITY,
                    BorrowConstraintReason.DebtCeiling,
                ];
                let availableCash = [
                    isiToken ? cash : constants_1.INFINITY,
                    BorrowConstraintReason.Cash,
                ];
                // when in segregation, take account of debtCeiling
                // borrow token should be stable coin
                if (isInSegregation) {
                    if (market.borrowableInSegregation) {
                        availableDebtCeiling[0] = marketCol.debtCeiling
                            .sub(marketCol.currentDebt)
                            // align it to borrow token decimals
                            .mul(ethers_1.utils.parseUnits("1", decimals - constants_1.DEBT_DECIMALS));
                    }
                    else {
                        availableDebtCeiling = [
                            constants_1.ZERO,
                            BorrowConstraintReason.NotAllowedSegregation,
                        ];
                    }
                }
                const safeEquity = (0, utils_1.rmul)(sumCollateral, safeMaxFactor).sub(sumBorrowed);
                const safeAvailableToBorrowFromEquity = [
                    (0, utils_1.rmul)(safeEquity, market.borrowFactorMantissa).div(assetPrice),
                    BorrowConstraintReason.AccountEquity,
                ];
                let _canBorrows = availableBorrowCap[0];
                let _safeAvailableToBorrow = (0, utils_1.minTuple)(availableBorrowCap, availableDebtCeiling, availableCash, safeAvailableToBorrowFromEquity);
                [_canBorrows, _safeAvailableToBorrow[0]] = (0, utils_1.ensureNonNegtive)(_canBorrows, _safeAvailableToBorrow[0]);
                return [
                    _canBorrows,
                    _safeAvailableToBorrow[0],
                    _safeAvailableToBorrow[1],
                ];
            })();
            let maxRepay = (0, utils_1.min)(borrowedBalance, underlyingBalance);
            return [
                borrowedBalance,
                canBorrows,
                safeAvailableToBorrow,
                underlyingBalance,
                maxRepay,
                decimals,
                constraintedBy,
            ];
        });
        this.getAccountSupplyInfo = (token, account, safeFactor) => __awaiter(this, void 0, void 0, function* () {
            const iToken = yield this.getIToken(token);
            const underlying = yield this.getUnderlying(token);
            const priceOracle = yield this.priceOracle;
            // Convert safeMaxFactor to BigNumber
            const safeMaxFactor = ethers_1.BigNumber.from(safeFactor);
            const [suppliedBalance, underlyingBalance, assetPrice, asCollateral, market, collaterals, borrows, accountSMode, ltv, smodeLTV, [isInSegregation, segregatedCol],] = yield Promise.all([
                iToken.callStatic.balanceOfUnderlying(account),
                this.getUnderlyingBalance(underlying, account),
                priceOracle.callStatic.getUnderlyingPrice(token),
                this.controller.hasEnteredMarket(account, token),
                this.controller.marketsV2(token),
                this.controller.getEnteredMarkets(account),
                this.controller.getBorrowedAssets(account),
                this.controller.accountsSMode(account),
                this.controller.getLTV(token),
                this.controller.getSModeLTV(token),
                this.controller.getSegregationModeState(account),
            ]);
            const { equity, sumCollateral, sumBorrowed, isValid } = yield this.calcAccountEquity(account, collaterals, borrows, accountSMode);
            let collateralFactor = accountSMode == market.sModeID && market.sModeID > 0 ? smodeLTV : ltv;
            const segregated = market.debtCeiling.gt(0);
            const availableDebt = market.debtCeiling.sub(market.currentDebt);
            const canChangeAsCollateral = this.canChangeAsCollateral(isInSegregation, collaterals, equity, sumCollateral, sumBorrowed, asCollateral, suppliedBalance, segregated, collateralFactor, assetPrice, safeMaxFactor);
            const available = (() => {
                if (!asCollateral) {
                    return true;
                }
                else {
                    return isValid;
                }
            })();
            const value = (0, utils_1.rmul)(underlyingBalance, assetPrice);
            return [
                value,
                asCollateral,
                canChangeAsCollateral,
                available,
                segregated,
                availableDebt,
            ];
        });
        this.getAccountBorrowInfo = (token, account, safeFactor) => __awaiter(this, void 0, void 0, function* () {
            const iToken = yield this.getIToken(token);
            const underlying = yield this.getUnderlying(token);
            const priceOracle = yield this.priceOracle;
            // Convert safeMaxFactor to BigNumber
            const safeMaxFactor = ethers_1.ethers.BigNumber.from(safeFactor);
            // Fixme: better way to get segregated collateral
            let [isInSegregation, segregatedCol] = yield this.controller.getSegregationModeState(account);
            const [underlyingBalance, decimals, [assetPrice, priceStatus], market, marketCol, collaterals, borrows, smodeID,] = yield Promise.all([
                this.getUnderlyingBalance(underlying, account),
                iToken.decimals(),
                priceOracle.callStatic.getUnderlyingPriceAndStatus(token),
                this.controller.marketsV2(token),
                this.controller.marketsV2(segregatedCol),
                this.controller.getEnteredMarkets(account),
                this.controller.getBorrowedAssets(account),
                this.controller.accountsSMode(account),
            ]);
            const { equity, sumCollateral, sumBorrowed, isValid: isEquityValid, } = yield this.calcAccountEquity(account, collaterals, borrows, smodeID);
            const [maxBorrow, safeBorrow] = (() => {
                let safeEquity = (0, utils_1.rmul)(sumCollateral, safeMaxFactor).sub(sumBorrowed);
                [safeEquity] = (0, utils_1.ensureNonNegtive)(safeEquity);
                // equity decimals is 36, div BASE make its decimals 18
                let _maxBorrow = (0, utils_1.rmul)(equity, market.borrowFactorMantissa).div(constants_1.BASE);
                let _safeBorrow = (0, utils_1.rmul)(safeEquity, market.borrowFactorMantissa).div(constants_1.BASE);
                // when in segregation, take account of debtCeiling
                // borrow token should be stable coin
                if (isInSegregation) {
                    if (market.borrowableInSegregation) {
                        const availableDebtCapacity = marketCol.debtCeiling
                            .sub(marketCol.currentDebt)
                            // align it to borrow token decimals
                            .mul(ethers_1.utils.parseUnits("1", decimals - constants_1.DEBT_DECIMALS));
                        _maxBorrow = (0, utils_1.min)(_maxBorrow, availableDebtCapacity);
                        _safeBorrow = (0, utils_1.min)(_safeBorrow, availableDebtCapacity);
                    }
                    else {
                        _maxBorrow = constants_1.ZERO;
                        _safeBorrow = constants_1.ZERO;
                    }
                }
                return [_maxBorrow, _safeBorrow];
            })();
            const value = (0, utils_1.rmul)(underlyingBalance, assetPrice);
            const available = priceStatus && isEquityValid;
            const borrowable = isInSegregation ? market.borrowableInSegregation : true;
            return [maxBorrow, safeBorrow, value, available, borrowable];
        });
        this.getLiquidationInfo = (borrower, liquidator, assetBorrowed, assetCollateral) => __awaiter(this, void 0, void 0, function* () {
            const borrowed = yield this.getIToken(assetBorrowed);
            const collateral = yield this.getIToken(assetCollateral);
            const borrowedUnderlying = yield this.getUnderlying(assetBorrowed);
            const priceOracle = yield this.priceOracle;
            let [liquidatorBalance, borrowBalance, collateralBalance, colExchangeRate, [colPrice, colPriceStatus], [borPrice, borPriceStatus], closeFactor, liquidationIncentive, collaterals, borrows, accountSMode, market,] = yield Promise.all([
                this.getUnderlyingBalance(borrowedUnderlying, liquidator),
                borrowed.callStatic.borrowBalanceCurrent(borrower),
                collateral.balanceOf(borrower),
                collateral.callStatic.exchangeRateCurrent(),
                priceOracle.getUnderlyingPriceAndStatus(assetCollateral),
                priceOracle.getUnderlyingPriceAndStatus(assetBorrowed),
                this.controller.closeFactorMantissa(),
                this.controller.liquidationIncentiveMantissa(),
                this.controller.getEnteredMarkets(borrower),
                this.controller.getBorrowedAssets(borrower),
                this.controller.accountsSMode(borrower),
                this.controller.marketsV2(assetCollateral),
            ]);
            const { isValid: isEquityValid, shortfallLiq: shortfall } = yield this.calcAccountEquity(borrower, collaterals, borrows, accountSMode);
            // Should use smode liquidationIncentive and closeFactor
            if (accountSMode == market.sModeID && market.sModeID > 0) {
                [liquidationIncentive, closeFactor] = yield this.controller.sModes(accountSMode);
            }
            const [maxRepay, maxRepayByCollateral] = (() => {
                if (shortfall.isZero() || borrower == liquidator) {
                    return [constants_1.ZERO, constants_1.ZERO];
                }
                else {
                    // max seizable by liquidator
                    const _maxRepay = (0, utils_1.rmul)(borrowBalance, closeFactor);
                    const maxSeizedValue = (0, utils_1.rmul)(_maxRepay.mul(borPrice), liquidationIncentive);
                    const maxSeizeCol = (0, utils_1.rdiv)(maxSeizedValue, colExchangeRate).div(colPrice);
                    // borrower does not have enough collateral
                    if (collateralBalance.lt(maxSeizeCol)) {
                        return [
                            _maxRepay,
                            (0, utils_1.rdiv)((0, utils_1.rmul)(collateralBalance, colExchangeRate)
                                .mul(colPrice)
                                .div(borPrice), liquidationIncentive),
                        ];
                    }
                    return [_maxRepay, _maxRepay];
                }
            })();
            const available = colPriceStatus && isEquityValid;
            return [maxRepay, maxRepayByCollateral, liquidatorBalance, available];
        });
        this.getAssetInterestData = (token) => __awaiter(this, void 0, void 0, function* () {
            const iToken = yield this.getIToken(token);
            const interestModel = yield this.getInterestRateModel(token);
            const priceOracle = yield this.priceOracle;
            const [tokenPrice, borrows, reserves, cash, reserveRatio, base, optimal, slope_1, slope_2,] = yield Promise.all([
                priceOracle.callStatic.getUnderlyingPrice(token),
                iToken.callStatic.totalBorrowsCurrent(),
                iToken.totalReserves(),
                iToken.getCash(),
                iToken.reserveRatio(),
                interestModel.base(),
                interestModel.optimal(),
                interestModel.slope_1(),
                interestModel.slope_2(),
            ]);
            // USD price is 1e18, so use `BASE`
            const totalBorrows = borrows.mul(tokenPrice).div(constants_1.BASE);
            const totalReserves = reserves.mul(tokenPrice).div(constants_1.BASE);
            const totalCash = cash.mul(tokenPrice).div(constants_1.BASE);
            return [
                totalBorrows,
                totalReserves,
                totalCash,
                reserveRatio,
                base,
                optimal,
                slope_1,
                slope_2,
            ];
        });
        // This function is used to calculate adequacy ratio, should use liquidation threshold
        this.getAccountCurrentEquity = (token, account) => __awaiter(this, void 0, void 0, function* () {
            const [collaterals, borrows, smodeID] = yield Promise.all([
                this.controller.getEnteredMarkets(account),
                this.controller.getBorrowedAssets(account),
                this.controller.accountsSMode(account),
            ]);
            const { sumBorrowed, equity, shortfall: shortfall, sumCollateralLiq: sumCollateral, } = yield this.calcAccountEquity(account, collaterals, borrows, smodeID, token);
            return [
                equity.div(constants_1.BASE),
                shortfall.div(constants_1.BASE),
                sumCollateral.div(constants_1.BASE),
                sumBorrowed.div(constants_1.BASE),
            ];
        });
        // This function is used to calculate adequacy ratio, should use liquidation threshold
        this.getAccountEquity = (account) => __awaiter(this, void 0, void 0, function* () {
            const [collaterals, borrows, smodeID] = yield Promise.all([
                this.controller.getEnteredMarkets(account),
                this.controller.getBorrowedAssets(account),
                this.controller.accountsSMode(account),
            ]);
            const { sumBorrowed, equity, shortfall, sumCollateralLiq: sumCollateral, } = yield this.calcAccountEquity(account, collaterals, borrows, smodeID);
            return [
                equity.div(constants_1.BASE),
                shortfall.div(constants_1.BASE),
                sumCollateral.div(constants_1.BASE),
                sumBorrowed.div(constants_1.BASE),
            ];
        });
        this.getEnableSmodeData = (account) => __awaiter(this, void 0, void 0, function* () {
            const alliTokens = Array.from((yield this.getAllITokens()).values());
            const [accountSMode, smodeLen, [isInSegregation], collaterals, borrows, ...res] = yield Promise.all([
                this.controller.accountsSMode(account),
                this.controller.getSModeLength(),
                this.controller.getSegregationModeState(account),
                this.controller.getEnteredMarkets(account),
                this.controller.getBorrowedAssets(account),
                ...alliTokens
                    .map((iToken) => [
                    iToken.address,
                    this.controller.marketsV2(iToken.address),
                    this.controller.getLTV(iToken.address),
                    this.controller.getSModeLTV(iToken.address),
                ])
                    .flat(),
            ]);
            if (accountSMode > 0) {
                throw new Error("Can not enable sMode when in sMode!");
            }
            const equities = yield Promise.all(Array.from({ length: smodeLen }, (_, i) => this.calcAccountEquity(account, collaterals, borrows, i)));
            // console.log("equities", JSON.stringify(equities, null, 2));
            const tokenRes = (0, utils_1.chunkArray)(res, 4);
            let data = new Array();
            let index = 1;
            while (index < smodeLen) {
                let smodeData = {};
                smodeData.smodeID = index;
                smodeData.smodeLabel = yield this.getSmodeLabel(index);
                const smodeAssets = tokenRes.filter(([, market]) => market.sModeID === index);
                smodeData.assets = {};
                smodeData.assets.from = tokenRes.map(([address]) => address);
                smodeData.assets.to = smodeAssets.map(([address]) => address);
                smodeData.ltv = smodeAssets.map(([address, , ltv, smodeLTV]) => {
                    return {
                        asset: address,
                        from: ltv,
                        to: smodeLTV,
                    };
                });
                try {
                    smodeData.adequacyRatio = {
                        from: (0, utils_1.rdiv)(equities[0].sumCollateralLiq, equities[0].sumBorrowed),
                        to: (0, utils_1.rdiv)(equities[index].sumCollateralLiq, equities[index].sumBorrowed),
                    };
                }
                catch (e) {
                    smodeData.adequacyRatio = {
                        from: ethers_1.constants.MaxUint256,
                        to: ethers_1.constants.MaxUint256,
                    };
                }
                smodeData.borrowingPower = {
                    from: equities[0].equity.div(constants_1.BASE),
                    to: equities[index].equity.div(constants_1.BASE),
                };
                [smodeData.canExecute, smodeData.reason] = (() => {
                    // current borrow is not compatible
                    if (borrows.some((address) => !smodeData.assets.to.includes(address))) {
                        return [false, 1];
                    }
                    // invalid equity
                    if (!equities[index].isValid) {
                        return [false, 3];
                    }
                    // insufficent equity
                    // check shortfall after
                    if (equities[index].shortfall.gt(0)) {
                        return [false, 2];
                    }
                    return [true, 0];
                })();
                data.push(smodeData);
                index++;
            }
            return data;
        });
        this.getDisableSmodeData = (account, safeFactor) => __awaiter(this, void 0, void 0, function* () {
            const alliTokens = Array.from((yield this.getAllITokens()).values());
            const [accountSMode, [isInSegregation], collaterals, borrows, ...res] = yield Promise.all([
                this.controller.accountsSMode(account),
                this.controller.getSegregationModeState(account),
                this.controller.getEnteredMarkets(account),
                this.controller.getBorrowedAssets(account),
                ...alliTokens
                    .map((iToken) => [
                    iToken.address,
                    this.controller.marketsV2(iToken.address),
                    this.controller.getLTV(iToken.address),
                    this.controller.getSModeLTV(iToken.address),
                ])
                    .flat(),
            ]);
            if (accountSMode == 0) {
                throw new Error("Can not disable sMode when in default!");
            }
            const equities = yield Promise.all([
                this.calcAccountEquity(account, collaterals, borrows, accountSMode),
                this.calcAccountEquity(account, collaterals, borrows, 0),
            ]);
            const tokenRes = (0, utils_1.chunkArray)(res, 4);
            let smodeData = {};
            smodeData.smodeID = accountSMode;
            smodeData.smodeLabel = yield this.getSmodeLabel(accountSMode);
            const smodeAssets = tokenRes.filter(([, market]) => market.sModeID === accountSMode);
            smodeData.assets = {};
            smodeData.assets.from = smodeAssets.map(([address]) => address);
            smodeData.assets.to = tokenRes.map(([address]) => address);
            smodeData.ltv = smodeAssets.map(([address, , ltv, smodeLTV]) => {
                return {
                    asset: address,
                    from: smodeLTV,
                    to: ltv,
                };
            });
            try {
                smodeData.adequacyRatio = {
                    from: (0, utils_1.rdiv)(equities[0].sumCollateralLiq, equities[0].sumBorrowed),
                    to: (0, utils_1.rdiv)(equities[1].sumCollateralLiq, equities[1].sumBorrowed),
                };
            }
            catch (e) {
                smodeData.adequacyRatio = {
                    from: ethers_1.constants.MaxUint256,
                    to: ethers_1.constants.MaxUint256,
                };
            }
            smodeData.borrowingPower = {
                from: equities[0].equity.div(constants_1.BASE),
                to: equities[1].equity.div(constants_1.BASE),
            };
            [smodeData.canExecute, smodeData.reason] = (() => {
                // invalid equity 4 => isValid
                // `from` and `to` have the same value
                if (!equities[1].isValid) {
                    return [false, 3];
                }
                // insufficent equity
                // sumCollateral * safeFactor < sumBorrowed
                if ((0, utils_1.rmul)(equities[1].sumCollateral, ethers_1.BigNumber.from(safeFactor)).lt(equities[1].sumBorrowed)) {
                    return [false, 2];
                }
                return [true, 0];
            })();
            return smodeData;
        });
        this.getMarketsData = (account, safeFactor) => __awaiter(this, void 0, void 0, function* () {
            const priceOracle = yield this.priceOracle;
            const alliTokens = Array.from((yield this.getAllITokens()).values());
            const [[isInSegregation], accountSMode, collaterals, borrows, ...res] = yield Promise.all([
                this.controller.getSegregationModeState(account),
                this.controller.accountsSMode(account),
                this.controller.getEnteredMarkets(account),
                this.controller.getBorrowedAssets(account),
                ...alliTokens
                    .map((iToken) => [
                    iToken.address,
                    iToken.callStatic.balanceOfUnderlying(account),
                    priceOracle.callStatic.getUnderlyingPrice(iToken.address),
                    this.controller.hasEnteredMarket(account, iToken.address),
                    this.controller.marketsV2(iToken.address),
                    this.controller.getLTV(iToken.address),
                    this.controller.getSModeLTV(iToken.address),
                ])
                    .flat(),
            ]);
            const { equity, sumCollateral, sumBorrowed } = yield this.calcAccountEquity(account, collaterals, borrows, accountSMode);
            const tokenRes = (0, utils_1.chunkArray)(res, 7);
            const hasSupplySomeCollateral = tokenRes.some(([, suppliedBalance, , asCollateral]) => asCollateral && suppliedBalance.gt(0));
            const data = tokenRes.reduce((acc, [address, suppliedBalance, assetPrice, asCollateral, market, ltv, smodeLTV,]) => {
                acc[address] = {
                    asCollateral,
                    canBorrow: (() => {
                        if (collaterals.length == 0 || !hasSupplySomeCollateral) {
                            return false;
                        }
                        let _canBorrow = market.borrowCapacity.gt(0) && !market.borrowPaused;
                        if (isInSegregation) {
                            _canBorrow = _canBorrow && market.borrowableInSegregation;
                        }
                        if (accountSMode > 0) {
                            _canBorrow = _canBorrow && market.sModeID == accountSMode;
                        }
                        return _canBorrow;
                    })(),
                    segregated: market.debtCeiling.gt(0),
                    canChangeAsCollateral: this.canChangeAsCollateral(isInSegregation, collaterals, equity, sumCollateral, sumBorrowed, asCollateral, suppliedBalance, market.debtCeiling.gt(0), accountSMode == market.sModeID && accountSMode > 0 ? smodeLTV : ltv, assetPrice, ethers_1.BigNumber.from(safeFactor)),
                };
                return acc;
            }, {});
            return data;
        });
        this.getAccountMode = (account) => __awaiter(this, void 0, void 0, function* () {
            const [[isInSegregation], smode] = yield Promise.all([
                this.controller.getSegregationModeState(account),
                this.controller.accountsSMode(account),
            ]);
            const smodeLabel = yield this.getSmodeLabel(smode);
            return { isInSegregation, smode, smodeLabel };
        });
        this.getDelayStrategyConfig = (account, token) => __awaiter(this, void 0, void 0, function* () {
            const timeLockStrategy = yield this.timeLockStrategy;
            const [assetLimitConfig, whitelistExtra, assetData, minSingleWaitSeconds, midSingleWaitSeconds, maxSingleWaitSeconds, minDailyWaitSeconds, midDailyWaitSeconds, maxDailyWaitSeconds,] = yield Promise.all([
                timeLockStrategy.assetLimitConfig(token),
                timeLockStrategy.whitelistExtra(token, account),
                timeLockStrategy.assetData(token),
                timeLockStrategy.minSingleWaitSeconds(),
                timeLockStrategy.midSingleWaitSeconds(),
                timeLockStrategy.maxSingleWaitSeconds(),
                timeLockStrategy.minDailyWaitSeconds(),
                timeLockStrategy.midDailyWaitSeconds(),
                timeLockStrategy.maxDailyWaitSeconds(),
            ]);
            return {
                assetLimitConfig,
                whitelistExtra,
                assetData,
                minSingleWaitSeconds,
                midSingleWaitSeconds,
                maxSingleWaitSeconds,
                minDailyWaitSeconds,
                midDailyWaitSeconds,
                maxDailyWaitSeconds,
            };
        });
        this.getDelayDetails = (amount, config) => {
            const getSingleWaitSeconds = () => {
                if (amount.lte(config.assetLimitConfig.minSingleLimit.add(config.whitelistExtra.minSingleLimit))) {
                    return config.minSingleWaitSeconds;
                }
                else if (amount.lte(config.assetLimitConfig.midSingleLimit.add(config.whitelistExtra.midSingleLimit))) {
                    return config.midSingleWaitSeconds;
                }
                else {
                    return config.maxSingleWaitSeconds;
                }
            };
            const getCurrentDailyAmountAndState = () => {
                const currentTime = ethers_1.BigNumber.from(Date.now()).div(1000); // Current time in seconds
                if (currentTime.sub(config.assetData.dailyStartTime).lt(constants_1.UNITS_PER_DAY)) {
                    return [
                        currentTime,
                        config.assetData.currentDailyAmount.add(amount),
                        false,
                    ];
                }
                else {
                    return [currentTime, amount, true];
                }
            };
            const getDailyWaitSeconds = () => {
                if (currentDailyAmount.lte(config.assetLimitConfig.minDailyLimit.add(config.whitelistExtra.minDailyLimit))) {
                    return config.minDailyWaitSeconds;
                }
                else if (currentDailyAmount.lte(config.assetLimitConfig.midDailyLimit.add(config.whitelistExtra.midDailyLimit))) {
                    return config.midDailyWaitSeconds;
                }
                else {
                    return config.maxDailyWaitSeconds;
                }
            };
            let delaySeconds = getSingleWaitSeconds();
            const [currentTime, currentDailyAmount, toUpdate] = getCurrentDailyAmountAndState();
            let dailyWaitSeconds = getDailyWaitSeconds();
            delaySeconds = delaySeconds.add(dailyWaitSeconds);
            return { delaySeconds };
        };
        this.getAccountClaimableAgreements = (account) => __awaiter(this, void 0, void 0, function* () {
            const timeLock = yield this.timeLock;
            const ids = yield timeLock.getAgreementIds(account);
            const details = yield Promise.all(ids.map((id) => timeLock.getAgreement(id)));
            const agreements = ids.map((id, i) => {
                return {
                    id: id,
                    isFrozen: details[i][0],
                    asset: details[i][1],
                    beneficiary: details[i][2],
                    releaseTime: details[i][3],
                    tokenAmounts: details[i][4],
                };
            });
            return agreements;
        });
        this._provider = new multicall_1.providers.MulticallProvider(provider, {
            // the default options use a SequenceUtilsV2 contract 0xdbbFa3cB3B087B64F4ef5E3D20Dda2488AA244e6
            // Which may not have been deployed yet in all chains
            contract: "0xd130B43062D875a4B7aF3f8fc036Bc6e9D3E1B3E",
            batchSize: 100,
        });
        this.controller = new ethers_1.Contract(controller, index_1.abi.controller, this._provider);
    }
    // Lazily initialized priceOracle
    get priceOracle() {
        return this.ensurePriceOracleInitialized();
    }
    ensurePriceOracleInitialized() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this._priceOracle) {
                const priceOracleAddress = yield this.controller.priceOracle();
                this._priceOracle = new ethers_1.Contract(priceOracleAddress, index_1.abi.priceOracle, this._provider);
            }
            return this._priceOracle;
        });
    }
    get timeLockStrategy() {
        return this.ensureTimeLockStrategyInitialized();
    }
    ensureTimeLockStrategyInitialized() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this._timeLockStrategy) {
                const timeLockStrategyAddress = yield this.controller.timeLockStrategy();
                this._timeLockStrategy = new ethers_1.Contract(timeLockStrategyAddress, index_1.abi.timeLockStrategy, this._provider);
            }
            return this._timeLockStrategy;
        });
    }
    get timeLock() {
        return this.ensureTimeLockInitialized();
    }
    ensureTimeLockInitialized() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this._timeLock) {
                const timeLockAddress = yield this.controller.timeLock();
                this._timeLock = new ethers_1.Contract(timeLockAddress, index_1.abi.timeLock, this._provider);
            }
            return this._timeLock;
        });
    }
    // Initialize all iTokens at once
    initializeITokens() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.iTokenInitialized) {
                const [iTokenAddresses, smodeLen] = yield Promise.all([
                    this.controller.getAlliTokens(),
                    this.controller.getSModeLength(),
                ]);
                this._smodeLabels = (yield Promise.all(Array.from({ length: smodeLen }, (_, i) => this.controller.sModes(i)))).map(([, , label]) => label);
                const res = yield Promise.all([
                    ...iTokenAddresses
                        .map((address) => {
                        const iToken = new ethers_1.ethers.Contract(address, index_1.abi.iToken, this._provider);
                        const underlying = iToken.underlying();
                        const interestRateModel = iToken.interestRateModel();
                        return [address, iToken, underlying, interestRateModel];
                    })
                        .flat(),
                ]);
                const tokenRes = (0, utils_1.chunkArray)(res, 4);
                tokenRes.forEach(([address, iToken, underlying, interestRateModel]) => {
                    this.iTokens.set(address, iToken);
                    // Use the iToken as the ERC20 contract
                    this.underlyings.set(address, new ethers_1.ethers.Contract(underlying, index_1.abi.iToken, this._provider));
                    this.interestRateModels.set(address, new ethers_1.ethers.Contract(interestRateModel, index_1.abi.interestModel, this._provider));
                });
                this.iTokenInitialized = true;
            }
        });
    }
    // Make contract instance
    createContractInstance(contractAddress, contractABI) {
        return new ethers_1.Contract(contractAddress, contractABI, this._provider);
    }
    getUpdatePromise(token) {
        return __awaiter(this, void 0, void 0, function* () {
            if (token) {
                const iToken = yield this.getIToken(token);
                return iToken.callStatic.updateInterest();
            }
            else {
                return () => __awaiter(this, void 0, void 0, function* () {
                    return true;
                });
            }
        });
    }
    // Getter to get all iTokens after ensuring they are initialized
    getAllITokens() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.initializeITokens(); // Ensure all iTokens are initialized
            return this.iTokens;
        });
    }
    // Method to get an iToken by address, ensuring all iTokens are initialized
    getIToken(iTokenAddress) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.initializeITokens(); // Ensure all iTokens are initialized
            const iToken = this.iTokens.get(iTokenAddress);
            if (!iToken) {
                throw new Error(`iToken with address ${iTokenAddress} not found`);
            }
            return iToken;
        });
    }
    // Method to get an interest model by address, ensuring all interest model contracts
    // are initialized
    getInterestRateModel(interestRateModelAddress) {
        return __awaiter(this, void 0, void 0, function* () {
            // Ensure all interest rate model contract are initialized
            yield this.initializeITokens();
            const interestRateModel = this.interestRateModels.get(interestRateModelAddress);
            if (!interestRateModel) {
                throw new Error(`interestRateModel with address ${interestRateModelAddress} not found`);
            }
            return interestRateModel;
        });
    }
    // Method to get underlying by iToken, ensuring all iTokens are initialized
    getUnderlying(iTokenAddress) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.initializeITokens(); // Ensure all iTokens are initialized
            const underlying = this.underlyings.get(iTokenAddress);
            if (!underlying) {
                throw new Error(`iToken with address ${iTokenAddress} not found`);
            }
            return underlying;
        });
    }
    getSmodeLabel(id) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.initializeITokens();
            if (id > this._smodeLabels.length) {
                throw new Error(`invalid sMode id ${id}`);
            }
            return this._smodeLabels[id];
        });
    }
    _getAssetUSDPrice(token) {
        return __awaiter(this, void 0, void 0, function* () {
            const priceOracle = yield this.priceOracle;
            const assetContract = this.createContractInstance(token, index_1.abi.iToken);
            // Use iUSX as price token, its decimal is always 18.
            const priceTokenDecimals = 18;
            const [assetPrice, assetDecimals] = yield Promise.all([
                priceOracle.callStatic.getUnderlyingPrice(token),
                assetContract.decimals(),
            ]);
            return assetDecimals > priceTokenDecimals
                ? assetPrice.mul(ethers_1.BigNumber.from("10").pow(ethers_1.BigNumber.from(assetDecimals - priceTokenDecimals)))
                : assetPrice.div(ethers_1.BigNumber.from("10").pow(ethers_1.BigNumber.from(priceTokenDecimals - assetDecimals)));
        });
    }
    getUnderlyingBalance(underlying, account) {
        return __awaiter(this, void 0, void 0, function* () {
            if (underlying.address === ethers_1.ethers.constants.AddressZero) {
                return this._provider.getBalance(account);
            }
            else {
                return underlying.callStatic.balanceOf(account);
            }
        });
    }
    // Read latest states instead of stored ones
    calcAccountEquity(account, collaterals, borrows, accountSMode, updateToken = "") {
        return __awaiter(this, void 0, void 0, function* () {
            const priceOracle = yield this.priceOracle;
            let equity = constants_1.ZERO;
            let shortfall = constants_1.ZERO;
            let sumCollateral = constants_1.ZERO;
            let sumBorrowed = constants_1.ZERO;
            let isValid = true;
            let equityLiq = constants_1.ZERO;
            let shortfallLiq = constants_1.ZERO;
            let sumCollateralLiq = constants_1.ZERO;
            const collateralsContracts = yield Promise.all(collaterals.map((c) => __awaiter(this, void 0, void 0, function* () { return yield this.getIToken(c); })));
            const borrowContracts = yield Promise.all(borrows.map((b) => __awaiter(this, void 0, void 0, function* () { return yield this.getIToken(b); })));
            const [...res] = yield Promise.all([
                ...collateralsContracts
                    .map((collateral) => {
                    return [
                        this.controller.marketsV2(collateral.address),
                        priceOracle.callStatic.getUnderlyingPriceAndStatus(collateral.address),
                        collateral.callStatic.balanceOf(account),
                        collateral.callStatic.exchangeRateCurrent(),
                        this.controller.getLTV(collateral.address),
                        this.controller.getSModeLTV(collateral.address),
                        this.controller.getLiquidationThreshold(collateral.address),
                        this.controller.getSModeLiquidationThreshold(collateral.address),
                    ];
                })
                    .flat(),
                ...borrowContracts
                    .map((borrow) => {
                    return [
                        this.controller.marketsV2(borrow.address),
                        priceOracle.callStatic.getUnderlyingPriceAndStatus(borrow.address),
                        borrow.callStatic.borrowBalanceCurrent(account),
                    ];
                })
                    .flat(),
            ]);
            const collateralRes = (0, utils_1.chunkArray)(res.slice(0, collaterals.length * 8), 8);
            const borrowsRes = (0, utils_1.chunkArray)(res.slice(collaterals.length * 8), 3);
            for (const [market, priceAndStatus, balance, exchangeRate, ltv, sModeLTV, liquidationThreshold, sModeLiquidationThreshold,] of collateralRes) {
                const [price, status] = priceAndStatus;
                let collateralFactor;
                let collateralFactorLiq;
                if (accountSMode == market.sModeID && market.sModeID > 0) {
                    collateralFactor = sModeLTV;
                    collateralFactorLiq = sModeLiquidationThreshold;
                }
                else {
                    collateralFactor = ltv;
                    collateralFactorLiq = liquidationThreshold;
                }
                sumCollateral = sumCollateral.add((0, utils_1.rmul)((0, utils_1.rmul)(balance.mul(price), exchangeRate), collateralFactor));
                sumCollateralLiq = sumCollateralLiq.add((0, utils_1.rmul)((0, utils_1.rmul)(balance.mul(price), exchangeRate), collateralFactorLiq));
                isValid = isValid && status;
            }
            for (const [market, priceAndStatus, borrowBalance] of borrowsRes) {
                const [price, status] = priceAndStatus;
                sumBorrowed = sumBorrowed.add((0, utils_1.rdiv)(borrowBalance.mul(price), market.borrowFactorMantissa));
                isValid = isValid && status;
            }
            if (sumCollateral.gte(sumBorrowed)) {
                equity = sumCollateral.sub(sumBorrowed);
            }
            else {
                shortfall = sumBorrowed.sub(sumCollateral);
            }
            if (sumCollateralLiq.gte(sumBorrowed)) {
                equityLiq = sumCollateralLiq.sub(sumBorrowed);
            }
            else {
                shortfallLiq = sumBorrowed.sub(sumCollateralLiq);
            }
            return {
                equity,
                shortfall,
                sumCollateral,
                sumBorrowed,
                isValid,
                equityLiq,
                shortfallLiq,
                sumCollateralLiq,
            };
        });
    }
    canChangeAsCollateral(isInSegregation, collaterals, equity, sumCollateral, sumBorrowed, asCollateral, suppliedBalance, segregated, collateralFactor, assetPrice, safeMaxFactor) {
        if (!asCollateral) {
            if (isInSegregation || (collaterals.length > 0 && segregated)) {
                return false;
            }
            return collateralFactor.gt(0);
        }
        if (sumBorrowed.isZero()) {
            return true;
        }
        // check whether can remove asset from collateral
        if (collateralFactor.isZero() && equity.gt(0)) {
            return true;
        }
        if (assetPrice.isZero() || collateralFactor.isZero() || equity.isZero()) {
            return false;
        }
        const safeEquity = sumCollateral.sub((0, utils_1.rdiv)(sumBorrowed, safeMaxFactor));
        const safeCollateralRemoveFromEquity = (0, utils_1.rdiv)(safeEquity.div(assetPrice), collateralFactor);
        return safeCollateralRemoveFromEquity.gte(suppliedBalance);
    }
}
exports.LendingDataV2 = LendingDataV2;
