import React from 'react'
import { MarketsContextValues } from 'contexts/Markets'
import TokenList from './TokenList'
interface AllMarketsProps {
    supplyMarkets: MarketsContextValues[],
    marketsData: any[],
    accountSupplyList: any,
}
const AllMarkets: React.FC<AllMarketsProps> = ({ supplyMarkets, marketsData, accountSupplyList }) => {
    return (
        <>
            <TokenList
                supplyMarkets={supplyMarkets}
                marketsData={marketsData}
                accountSupplyList={accountSupplyList}
            />
        </>
    )
}

export default AllMarkets