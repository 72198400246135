import React from 'react'
import styled from 'styled-components'

interface ModalContentProps {
  text?: string,
  children: React.ReactNode
}

const ModalContent: React.FC<ModalContentProps> = ({ text,children }) => (
    <StyledModalContent>
      {children}
      {text}
    </StyledModalContent>
)

const StyledModalContent = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 500;
  padding:0 20px 50px;
  @media(max-width: 1199px) {
    font-size:14px;
    padding:0 10px 25px;
  }
`

export default ModalContent