import React, { useCallback, useState, useEffect } from "react";
import { useParams,useNavigate, useLocation, NavLink as RouterLink } from "react-router-dom";
import styled from "styled-components";
import { useIntl } from "react-intl";
import BigNumber from "bignumber.js";
import numeral from "numeral";
import { useQuery, setUrlParam, bnToDec, formate_round_up, _formatNumberNotCarry, numberFormatter, format_bn } from "utils";
import { network_Id, network_Name } from 'lending-sdk/utils/index1'
import general_DL_Token from 'lending-sdk/lib/clean_build/contracts/general/DL_Token.json';
import synthetic_DL_Token from 'lending-sdk/lib/clean_build/contracts/synthetic/DL_Token.json';
import stocks_DL_Token from 'lending-sdk/lib/clean_build/contracts/stocks/DL_Token.json';
import { fetchMarkets } from "./fetchMarkets";

import img_eth from "assets/Bridge/net-eth.svg"
import img_arbitrum from "assets/Bridge/net-arbitrum.svg"
import img_op from "assets/Bridge/net-op.svg"
import img_bsc from "assets/Bridge/net-bsc.svg"
import img_polygon from "assets/Bridge/Polygon.svg"
import img_kava from "assets/Bridge/KAVA.svg"
import img_ConfluxTest from "assets/Bridge/net-conflux.svg"

// import LendTitle from "components/LendTitle";
import MarketOverview from "./components/MarketOverview";
import AllMarketsList from "./components/AllMarketsList";
import MarketVolume from "./components/MarketVolume";
import { Market } from "contexts/MarketOverview/types";

import FlexBoxWrap from "components/FlexBoxWrap"
import FlexBGFilter from "components/FlexBGFilter"


const Markets: React.FC = () => {
  const intl = useIntl();
  const fmt = intl.formatMessage;
  const {
    currentNetwork } = useParams<any>();
  const { pathname } = useLocation();
  const navigate = useNavigate()
  let fetchApiTimer: NodeJS.Timeout;
  const [_currentNetwork, setCurrentNetwork] = useState<string>(currentNetwork as string)
  // const [currentChainId,setCurrentChainId] = useState<number>(network_Id[currentNetwork])
  const [generalLink, setGeneralLink] = useState<Record<string, any> | undefined>(undefined)
  const [syntheticLink, setSyntheticLink] = useState<Record<string, any> | undefined>(undefined)
  const [stocksLink, setStocksLink] = useState<Record<string, any> | undefined>(undefined)
  const [synthsLink, setSynthsLink] = useState<Record<string, any> | undefined>(undefined)
  const [supplyOverview, setSupplyOverview] = useState<any[]>([]);
  const [mintOverview, setMintOverview] = useState<Market[]>([]);
  const [marketsList_local, setmarketsList] = useState<any[]>([]);
  const [mintList, setMintList] = useState<Market[]>([]);
  const [allChainTotalSupplyValue, setAllChainTotalSupplyValue] = useState("");
  const [allChaintotalBorrowsValue, setAllChaintotalBorrowsValue] = useState("");
  const [allChainTotalAccumulatedBorrow, setAllChainTotalAccumulatedBorrow] = useState("");
  const [totalSupplyValue, setTotalSupplyValue] = useState("");
  const [totalBorrowValue, setTotalBorrowValue] = useState("");
  const [totalMintValue, setTotalMintValue] = useState("");
  const [showList, setShowList] = useState(false)
  const totalAssetsTextList = [
    "Markets_Total_Supply",
    "Markets_Total_Borrow",
    "Accumulated_Borrow",
  ];

  const initalAssetsObj = [
    {
      text: "Markets_Total_Supply",
      val: "..."
    },
    {
      text: "Markets_Total_Borrow",
      val: "..."
    }
  ];
  const [AssetsObj, setAssetsObj] = useState<Record<string, any>[]>(
    initalAssetsObj
  );
  // 获取本地token配置
  const deployAssets = {
    "general": general_DL_Token,
    "synthetic": synthetic_DL_Token,
    "stocks": stocks_DL_Token,
  }
  const fetchApi = useCallback(async (NetworkName: any) => {
    const {
      marketsList,
      // mintMarkets,
      allChainTotalSupplyValue,
      allChaintotalBorrowsValue,
      allChainTotalAccumulatedBorrow,
      totalSupplyValue,
      totalBorrowsValue
    } = await fetchMarkets(NetworkName);
    window.sessionStorage.setItem("marketsList", JSON.stringify(marketsList))
    setSupplyOverview([...marketsList]);
    // setMintOverview([...mintMarkets]);
    setmarketsList(marketsList);
    // setMintList(mintMarkets);
    setAllChainTotalSupplyValue(allChainTotalSupplyValue)
    setAllChaintotalBorrowsValue(allChaintotalBorrowsValue)
    setAllChainTotalAccumulatedBorrow(allChainTotalAccumulatedBorrow)
    var fetchAssetsObj = [
      {
        text: "Markets_Total_Supply",
        // val: totalSupplyValue === '' ? '...' : ((bnToDec(new BigNumber(totalSupplyValue), 18) > 0 && bnToDec(new BigNumber(totalSupplyValue), 18) < 0.01) ?
        //   '<$0.01'
        //   :
        //   numeral(bnToDec(new BigNumber(totalSupplyValue), 18)).format("$0,0.000").slice(0, -1)),
        val: totalSupplyValue === ''
          ? '...'
          // : '$' + format_num_to_K(format_bn(totalSupplyValue, 18, 2))
          : '$' + numberFormatter(format_bn(totalSupplyValue, 18, 2), 2)
      },
      {
        text: "Markets_Total_Borrow",
        // val: totalBorrowsValue === '' ? '...' : ((bnToDec(new BigNumber(totalBorrowsValue), 18) > 0 && bnToDec(new BigNumber(totalBorrowsValue), 18) < 0.01) ?
        //   '<$0.01'
        //   :
        //   numeral(bnToDec(new BigNumber(totalBorrowsValue), 18)).format("$0,0.000").slice(0, -1)),
        val: totalBorrowsValue === ''
          ? '...'
          // : '$' + format_num_to_K(format_bn(totalBorrowsValue, 18, 2))
          : '$' + numberFormatter(format_bn(totalBorrowsValue, 18, 2), 2)
      }
    ];
    setAssetsObj(fetchAssetsObj);
  }, [currentNetwork]);
  const switchTabHandler = (e: any) => {
    // e.currentTarget.querySelector(".active").classList.remove("active");
    // e.target.querySelector(".active").classList.remove("active");
    // console.log(e.target.tagName)
    // if (e.target.tagName === "UL") {
    //   e.target.querySelector(".active").classList.remove("active");
    //   e.target.childNodes[1].classList.add("active");
    // } else {
    //   e.target.parentNode.querySelector(".active").classList.remove("active");
    //   e.target.classList.add("active");
    // }
    // e.target.parentNode.classList.add("active");
    console.log(e.target.tagName)
    console.log(e.currentTarget.querySelector(".active"))
    if (e.target.tagName === 'A') {
      e.currentTarget.querySelector(".active")?.classList.remove("active");
      e.target.parentNode.classList.add('active')
    } else if (e.target.tagName === 'UL') {
      return;
    }
    // console.log(e.target.datasets("networkName"))
  };
  useEffect(() => {
    setSupplyOverview([]);
    setMintOverview([]);
    setmarketsList([]);
    setMintList([]);
    setAssetsObj(initalAssetsObj)
    setTotalSupplyValue('');
    setTotalBorrowValue('');
    setTotalMintValue('');
    clearInterval(fetchApiTimer);
    fetchApi(currentNetwork);
    fetchApiTimer = setInterval(() => {
      fetchApi(currentNetwork);
    }, 1000 * 10);
    return () => {
      clearInterval(fetchApiTimer);
    };
  }, [currentNetwork]);

  // 动态路由链接
  useEffect(() => {
    let generalLink, syntheticLink, stocksLink, synthsLink
    if (currentNetwork) {
      generalLink = deployAssets["general"]['networks'][`${network_Id[currentNetwork]}`]
      setGeneralLink(generalLink)
      // syntheticLink = deployAssets["synthetic"]['networks'][`${network_Id[currentNetwork]}`]
      // setSyntheticLink(syntheticLink)
      // stocksLink = deployAssets["stocks"]['networks'][`${network_Id[currentNetwork]}`]
      // setStocksLink(stocksLink)
      // synthsLink = deployAssets["general"]['networks'][`${network_Id[currentNetwork]}`]
      // const synthsLinkHas = Object.values(synthsLink).filter((item:any)=>!item.assetsType)[0]
      // synthsLinkHas ? setSynthsLink(synthsLink) : setSynthsLink(undefined)
      // // switchpool时候 如果不存在 stocks池子 则切换到 general池子
    }
  }, [currentNetwork])

  const NetMap = {
    mainnet: {
      net: 'Ethereum',
      icon: img_eth
    },
    Goerli: {
      net: 'Goerli',
      icon: img_eth
    },
    ArbitrumOne: {
      net: 'Arbitrum',
      icon: img_arbitrum
    },
    Optimism: {
      net: 'Optimism',
      icon: img_op
    },
    bsc: {
      net: 'BSC',
      icon: img_bsc
    },
    Polygon: {
      net: 'Polygon',
      icon: img_polygon
    },
    // Kava: {
    //   net: 'Kava',
    //   icon: img_kava
    // },
    ConfluxTest: {
      net: 'ConfluxTest',
      icon: img_ConfluxTest
    },
    ConfluxeSpace: {
      net: 'Conflux eSpace',
      icon: img_ConfluxTest
    },
    Sepolia: {
      net: 'Sepolia',
      icon: img_eth
    }
  }

  const switchNetwork = (e: any) => {
    let currentChain;
    if (e.target.tagName === 'LI') {
      currentChain = e.target.querySelector('span').dataset.chain;
    } else if (e.target.tagName === 'A') {
      currentChain = e.target.querySelector('span').dataset.chain;
    } else if (e.target.tagName === 'IMG') {
      currentChain = e.target.parentNode.querySelector('span').dataset.chain;
    } else {
      currentChain = e.target.dataset.chain;
    }
    console.log(e.target.tagName)
    // console.log(e.currentTarget.querySelector(".active"))
    // setCurrentChainId(Number(currentChain))
    setCurrentNetwork(network_Name?.[currentChain] ?? 'Ethereum')
    navigate(
      `/Markets/${network_Name?.[currentChain]}`
    );
    if (e.target.tagName === 'A') {
      e.currentTarget.querySelector(".active")?.classList.remove("active");
      e.target.parentNode.classList.add('active')
    } else if (e.target.tagName === 'UL') {
      return;
    }
    // console.log(e.target.datasets("networkName"))
  };
  useEffect(() => {
    document.body.addEventListener("click", (e) => {
      // 如果点击了 网络切换 select 列表不消失
      const NetworkSelect = document
        .querySelector(".NetworkName")
        ?.contains(e.target as HTMLElement);
      if (NetworkSelect) {
        return;
      }
      if (showList) {
        setShowList(false)
      }

    });
    return () => {
      document.body.removeEventListener("click", (event) => {
        event.preventDefault();
      }, false);
    };
  }, [showList]);

  return (
    <>
      <FlexBoxWrap>
        <AssetWrap>
          <StyledTitle>
            {fmt({ id: "Multi_Chain_Markets" })}
          </StyledTitle>

          <AssetsWarp>
            <AssetsItem>
              <h1>
                {
                  allChainTotalSupplyValue
                    ? allChainTotalSupplyValue === ''
                      ? '...'
                      : ((bnToDec(new BigNumber(allChainTotalSupplyValue), 18) > 0 && bnToDec(new BigNumber(allChainTotalSupplyValue), 18) < 0.01)
                        ? '<$0.01'
                        : formate_round_up(numeral(bnToDec(new BigNumber(allChainTotalSupplyValue), 18)).format("$0.000a").toLocaleUpperCase()))
                    : '...'
                }
              </h1>
              <p>
                <span>{fmt({ id: totalAssetsTextList[0] })}</span>
              </p>
            </AssetsItem>

            <AssetsItem>
              <h1>
                {
                  allChaintotalBorrowsValue
                    ? allChaintotalBorrowsValue === ''
                      ? '...'
                      : ((bnToDec(new BigNumber(allChaintotalBorrowsValue), 18) > 0 && bnToDec(new BigNumber(allChaintotalBorrowsValue), 18) < 0.01)
                        ? '<$0.01'
                        : formate_round_up(numeral(bnToDec(new BigNumber(allChaintotalBorrowsValue), 18)).format("$0.000a").toLocaleUpperCase()))
                    : '...'
                }
              </h1>
              <p>
                <span>{fmt({ id: totalAssetsTextList[1] })}</span>
              </p>
            </AssetsItem>

            <AssetsItem>
              <h1>
                {
                  allChainTotalAccumulatedBorrow
                    ? allChainTotalAccumulatedBorrow === ''
                      ? '...' : ((bnToDec(new BigNumber(allChainTotalAccumulatedBorrow), 18) > 0 && bnToDec(new BigNumber(allChainTotalAccumulatedBorrow), 18) < 0.01)
                        ? '<$0.01'
                        : formate_round_up(numeral(bnToDec(new BigNumber(allChainTotalAccumulatedBorrow), 18)).format("$0.000a").toLocaleUpperCase()))
                    : '...'
                }
              </h1>
              <p>
                <span>{fmt({ id: totalAssetsTextList[2] })}</span>
              </p>
            </AssetsItem>
          </AssetsWarp>
        </AssetWrap>
      </FlexBoxWrap>


      <FlexBGFilter>
        <FlexBoxWrap>
          {/* <NetWorkName
            className="NetworkName"
            onClick={() => { setShowList(!showList) }}
          >
            <LeftBlock>
              <img className="l1img" src={NetMap[currentNetwork]?.icon} alt="" />
              <b>{NetMap[currentNetwork]?.net}</b>
            </LeftBlock>
            {<StyledArrow className={showList ? 're' : ''} />}

            {
              showList &&
              <NetWorkList className='networkList' onClick={(e) => switchTabHandler(e)}>
                <NetWorkItem>
                  <NavLink to={`/Markets/ArbitrumOne${queryString}`}>
                    <img src={NetMap['ArbitrumOne'].icon} alt="" />
                    <span className={currentNetwork === 'ArbitrumOne' ? 'active' : ''}>
                      {NetMap['ArbitrumOne'].net}
                    </span>
                  </NavLink>
                </NetWorkItem>
                <NetWorkItem>
                  <NavLink to={`/Markets/Optimism${queryString}`}>
                    <img src={NetMap['Optimism'].icon} alt="" />
                    <span className={currentNetwork === 'Optimism' ? 'active' : ''}>
                      {NetMap['Optimism'].net}
                    </span>
                  </NavLink>
                </NetWorkItem>
                <NetWorkItem>
                  <NavLink to={`/Markets/bsc${queryString}`}>
                    <img src={NetMap['bsc'].icon} alt="" />
                    <span className={currentNetwork === 'bsc' ? 'active' : ''}>
                      {NetMap['bsc'].net}
                    </span>
                  </NavLink>
                </NetWorkItem>
                <NetWorkItem>
                  <NavLink to={`/Markets/Polygon${queryString}`}>
                    <img src={NetMap['Polygon'].icon} alt="" />
                    <span className={currentNetwork === 'Polygon' ? 'active' : ''}>
                      {NetMap['Polygon'].net}
                    </span>
                  </NavLink>
                </NetWorkItem>
                <NetWorkItem>
                  <NavLink to={`/Markets/mainnet${queryString}`}>
                    <img src={NetMap['mainnet'].icon} alt="" />
                    <span className={currentNetwork === 'mainnet' ? 'active' : ''}>
                      {NetMap['mainnet'].net}
                    </span>
                  </NavLink>
                </NetWorkItem>
                <NetWorkItem>
                  <NavLink to={`/Markets/ConfluxeSpace${queryString}`}>
                    <img src={NetMap['ConfluxeSpace'].icon} alt="" />
                    <span className={currentNetwork === 'ConfluxeSpace' ? 'active' : ''}>
                      {NetMap['ConfluxeSpace'].net}
                    </span>
                  </NavLink>
                </NetWorkItem>
              </NetWorkList>
            }
          </NetWorkName> */}
          <TabWrapInner>
            <SelectType
              className="NetworkName"
              // onBlur={() => { buttonOnBlur() }}
              onClick={() => { setShowList(!showList) }}
            >
              <LeftBlock>
                <img className="l1img" src={NetMap[_currentNetwork]?.icon} alt="" />
                <b>{NetMap[_currentNetwork]?.net}</b>
              </LeftBlock>
              {<StyledArrow className={showList ? 're' : ''} />}

              {
                showList &&
                <NetWorkList className='selectList' onClick={(e) => switchNetwork(e)}>
                  <NetWorkItem>
                    <NavLink>
                      <img src={NetMap['mainnet']?.icon} alt="" />
                      <span className={_currentNetwork === 'mainnet' ? 'active' : ''} data-chain={1}>
                        {NetMap['mainnet']?.net}
                      </span>
                    </NavLink>
                  </NetWorkItem>
                  <NetWorkItem>
                    <NavLink>
                      <img src={NetMap['ArbitrumOne']?.icon} alt="" />
                      <span className={_currentNetwork === 'ArbitrumOne' ? 'active' : ''} data-chain={42161}>
                        {NetMap['ArbitrumOne']?.net}
                      </span>
                    </NavLink>
                  </NetWorkItem>
                  <NetWorkItem>
                    <NavLink>
                      <img src={NetMap['Optimism']?.icon} alt="" />
                      <span className={_currentNetwork === 'Optimism' ? 'active' : ''} data-chain={10}>
                        {NetMap['Optimism']?.net}
                      </span>
                    </NavLink>
                  </NetWorkItem>
                  <NetWorkItem>
                    <NavLink>
                      <img src={NetMap['bsc']?.icon} alt="" />
                      <span className={_currentNetwork === 'bsc' ? 'active' : ''} data-chain={56}>
                        {NetMap['bsc']?.net}
                      </span>
                    </NavLink>
                  </NetWorkItem>
                  <NetWorkItem>
                    <NavLink>
                      <img src={NetMap['Polygon']?.icon} alt="" />
                      <span className={_currentNetwork === 'Polygon' ? 'active' : ''} data-chain={137}>
                        {NetMap['Polygon']?.net}
                      </span>
                    </NavLink>
                  </NetWorkItem>
                  {/* <NetWorkItem>
                    <NavLink>
                      <img src={NetMap['Kava']?.icon} alt="" />
                      <span className={_currentNetwork === 'Kava' ? 'active' : ''} data-chain={2222}>
                        {NetMap['Kava']?.net}
                      </span>
                    </NavLink>
                  </NetWorkItem> */}
                  <NetWorkItem>
                    <NavLink>
                      <img src={NetMap['ConfluxeSpace']?.icon} alt="" />
                      <span className={_currentNetwork === 'ConfluxeSpace' ? 'active' : ''} data-chain={1030}>
                        {NetMap['ConfluxeSpace']?.net}
                      </span>
                    </NavLink>
                  </NetWorkItem>
                  {/* <NetWorkItem>
                    <NavLink>
                      <img src={NetMap['Goerli']?.icon} alt="" />
                      <span className={_currentNetwork === 'Goerli' ? 'active' : ''} data-chain={5}>
                        {NetMap['Goerli']?.net}
                      </span>
                    </NavLink>
                  </NetWorkItem> */}
                  <NetWorkItem>
                    <NavLink>
                      <img src={NetMap['Sepolia']?.icon} alt="" />
                      <span className={_currentNetwork === 'Sepolia' ? 'active' : ''} data-chain={11155111}>
                        {NetMap['Sepolia']?.net}
                      </span>
                    </NavLink>
                  </NetWorkItem>
                </NetWorkList>
              }
            </SelectType>
          </TabWrapInner>
        </FlexBoxWrap>
      </FlexBGFilter>
      <FlexBoxWrap>
        <BlockStyle>
          <AssetsWarpspe>
            {AssetsObj.map((item) => {
              return (
                <AssetsItemSpe key={item.text}>
                  <h1>{item.val}</h1>
                  <p>
                    <span>{fmt({ id: `${item.text}` })}</span>
                  </p>
                </AssetsItemSpe>
              )
            })}
          </AssetsWarpspe>
          <MarketOverview
            supplyOverview={supplyOverview}
            mintOverview={mintOverview}
            hasSynthsLink={!!synthsLink}
          />

          <AllMarketsList supplyMarkets={marketsList_local} hasSynthsLink={!!synthsLink} />
        </BlockStyle>
      </FlexBoxWrap>
    </>
  )
}


// const NetWorkName = styled.button`
//   cursor: pointer;
//   background: transparent;
//   height: 62px;
//   /* width: 190px; */
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   color: #FFF;
//   font-size: 16px;
//   position: relative;
//   &:hover {}
//   b {
//     font-weight: 600;
//     margin-bottom: -5px;
//   }
//   .l1img {
//     width: 28px;
//     margin-right: 8px;
//   }
//   @media (max-width: 1199px) {
//     height: 30px;
//     font-size: 12px;
//     .l1img {
//       width: 18px;
//       margin-right: 4px;
//     }
//   }
// `
// const LeftBlock = styled.div`

// `
// const StyledArrow = styled.div`
//   margin-left: 16px;
//   width: 0;
//   height: 0;
//   border: 4px solid transparent;
//   margin-bottom: -5px;
//   border-top: 4px solid #fff;

//   &.re {
//     transform: rotate(180deg);
//     margin-bottom: 2px;
//   }
// `
// const NetWorkList = styled.div`
//   z-index: 999;
//   position: absolute;
//   top: 60px;
//   left: 0;
//   min-width: 180px;
//   background: #fff;
//   border-radius: 8px;
//   padding-top: 10px;
//   padding-bottom: 10px;
//   border: 1px solid rgba(19, 19, 19, 0.08);
//   box-shadow: 0px 0px 6px 0px ${(props) => props.theme.colors.lightGrey};
//   .dark & {
//     background: #131313;
//     box-shadow:0px 0px 6px 0px rgba(46, 52, 78, 0.8);
//   }
//   @media (max-width: 1199px) {
//     top: 30px;
//     left: -2px;
//   }
// `
// const NetWorkItem = styled.div`
//   cursor: pointer;
//   color: #fff!important;
//   display: flex;
//   align-items: center;
//   line-height: 34px;
//   padding-left: 15px;
//   padding-right: 10px;

//   span {
//     color: #000;
//   }

//   span.active,
//   &:hover span {
//     color: #5EAFE7;
//   }
//   .dark & {
//     span {
//       color: #fff;
//       &.active,
//       &:hover {
//         color: #5EAFE7;
//       }
//     }
//   }

//   img {
//     width: 20px;
//     margin-right: 12px;
//   }
// `
// const _FlexBGFilter = styled.div`
//   width: 100%;
//   background-color: rgba(255, 255, 255, 0.1);
//   height: 62px;
//   backdrop-filter: blur(50px);
//   @media (max-width: 1199px) {
//     display: none;
//   }
// `
const TabWrapInner = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  @media (max-width: 1199px) {
    min-width: 100%;
    flex-wrap: wrap;
  }
`
const SelectType = styled.button`
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* background: ${(props) => props.theme.colors.lightOpacityGrey}; */
    background: ${(props) => props.theme.colors.darkOpacityGrey};
    position: relative;
    width: max-content;
    min-width: 200px;
    padding:0 10px 0 10px;
    color: #9195A4;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    line-height: 36px;
    b {
      margin: 0;
      font-weight: 500;
      color:${(props) => props.theme.colors.white};
      /* .dark & {
        color: #fff;
      } */
    }
    .l1img {
      width: 24px;
      margin-right: 12px;
    }
    /* .dark & {
      background: ${(props) => props.theme.colors.darkOpacityGrey};
    } */
    border-radius:40px;
  @media(max-width: 1199px) {
    cursor: pointer;
    padding:0 10px 0 10px;
    min-width: 160px;
    line-height:36px;
    font-size:14px;
    font-weight:bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: ${(props) => props.theme.colors.lightOpacityGrey};
    position: relative;
    margin: 7px 0;
    b {
      margin: 0;
      font-weight: 700;
      /* color: rgb(36,42,71); */
      /* .dark & {
        color: #fff;
      } */
    }
    .dark & {
      background: ${(props) => props.theme.colors.darkOpacityGrey};
    }
    border-radius:40px;
  }
`
const LeftBlock = styled.div`
  display: flex;
  align-items: center;
`
const StyledArrow = styled.div`
  margin-left: 16px;
  width: 0;
  height: 0;
  border: 4px solid transparent;
  margin-bottom: -5px;
  border-top: 4px solid ${(props) => props.theme.colors.white};
  &.re {
    transform: rotate(180deg);
    margin-bottom: 2px;
  }

  /* .dark & {
    border-top: 4px solid #fff;
  } */
`
const NetWorkList = styled.ul`
  z-index: 999;
  position: absolute;
  top: 44px;
  left: 0;
  min-width: 200px;
  width: max-content;
  background: ${(props) => props.theme.colors.white};
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  /* &.selectList{
    width: max-content;
  } */
  .dark & {
    background: ${(props) => props.theme.colors.black};
    box-shadow:0px 16px 32px 0px rgb(255 255 255 / 0.08);
  }
  @media(max-width: 1119px) {
    min-width: 160px;
  }
  
`
const NetWorkItem = styled.li`
  cursor: pointer;
  color: #fff!important;
  display: flex;
  align-items: center;
  /* justify-content: space-around; */
  line-height: 34px;
  padding-left: 15px;
  padding-right: 10px;
  span {
    color: #000;
  }

  span.active,
  &:hover span {
    color: #5EAFE7;
  }
  .dark & {
    span {
      color: #fff;
    }
  }
  img {
    width: 24px;
    margin-right: 12px;
  }
`
const NavLink = styled.a`
  color: ${(props) => props.theme.colors.lightGrey};
  &:hover {
    color: ${(props) => props.theme.colors.lightGrey};
  }
`;

const LendTitle = styled.h1`
  /* height: 60px; */
  font-size: 22px;
  font-weight: bold;
  /* line-height: 60px; */
  margin:20px 0 10px;
  /* margin:-15px 0 0; */
  .dark & {
    color:${(props) => props.theme.colors.white};
  }
  @media(max-width: 1119px) {
    font-size:18px;
    /* height:48px; */
    /* line-height: 48px; */
    margin:10px 0 6px;
    /* margin:-10px 0 0; */
  }
`
const SwitchPool = styled.ul`
  display: flex;
  width: 100%;
  height: 40px;
  line-height: 40px;
  font-size: 20px;
  border-bottom: 4px solid ${(props) => props.theme.colors.lightTableBorder};
  .dark & {
    border-bottom: 4px solid ${(props) => props.theme.colors.darkTableBorder};
  }
  margin: 5px 0 0;
  @media (max-width: 767px) {
    font-size: 18px;
    height: 34px;
    line-height: 34px;
  }
`;
const SwitchPoolItem = styled.li`
  padding: 0 24px;
  font-weight: 500;
  margin-bottom: -4px;
  cursor: pointer;
  a {
    display: block;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &:hover {
    a {
      color: ${(props) => props.theme.colors.black};
      .dark & {
        color: ${(props) => props.theme.colors.white};
      }
      font-weight: bold;
    }
  }
  &.active {
    border-bottom: 4px solid ${(props) => props.theme.colors.supply.dark};
    a {
      color: ${(props) => props.theme.colors.black};
      .dark & {
        color: ${(props) => props.theme.colors.white};
      }
      font-weight: bold;
    }
  }
  @media (max-width: 767px) {
    padding: 0 18px;
  }
`;
const SwitchTabs = styled.ul`
  display: flex;
  flex-wrap:wrap;
  width: fit-content;
  min-height: 40px;
  line-height: 40px;
  border-radius: 20px;
  margin-bottom:20px;
  cursor: pointer;
  @media (max-width: 767px) {
    display: none;
    width:100%;
    min-height: 30px;
    line-height: 30px;
    margin-bottom:10px;
  }
`;
// const NavLink = styled(RouterLink)`
//   color: ${(props) => props.theme.colors.lightGrey};
//   &:hover {
//     color: ${(props) => props.theme.colors.lightGrey};
//   }
// `;
const PoolLink = styled(RouterLink)`
  color: ${(props) => props.theme.colors.lightGrey};
  &:hover {
    color: ${(props) => props.theme.colors.white};
  }
`;
const SwitchItem = styled.li`
  font-weight: 500;
  border-radius: 20px;
  margin:0 10px 0 0;
  background: ${(props) => props.theme.colors.lightOpacityGrey};
  .dark & {
    background: ${(props) => props.theme.colors.darkOpacityGrey};
  }
  a {
    display: block;
    padding: 0 20px;
  }
  &.active {
    background: ${(props) => props.theme.colors.linearBg};
    border-radius: 20px;
    a {
      color: ${(props) => props.theme.colors.white};
      font-weight: bold;
    }
  }
  @media (max-width: 767px) {
    padding: 0 10px;
    margin:0 10px 10px 0;
  }
`;


const BlockStyle = styled.div`
`

const AssetWrap = styled.div`
  height: 224px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 1199px) {
    height: auto;
    padding: 16px 0;
  }
`

const StyledTitle = styled.div`
  color: #FFF;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 16px;
  @media (max-width: 1199px) {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 0;
  }
`
const AssetsWarp = styled.div`
  width: 100%;
  /* height: 120px; */
  display: flex;
  color:#ffffff;
`;
const AssetsWarpspe = styled.div`
  width: 100%;
  display: flex;
  color:#ffffff;
  margin: 16px 0;
  border-radius:16px;
  padding-top: 16px;
  background:${(props) => props.theme.TableBg};
  @media (max-width: 1199px) {
    margin: 16px 0;
    border-radius:10px;
  }
  .dark & {
    color: ${(props) => props.theme.colors.white};
    background:${(props) => props.theme.DarkTableBg};
  }
`;
const AssetsItem = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 130px;
  box-sizing: border-box;
  @media (max-width: 1199px) {
    margin-right: 30px;
  }
  h1 {    
    color: #FFF;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    @media (max-width: 1199px) {
      font-size: 16px;
      line-height: 28px;
      margin-top: 10px;
      margin-bottom: 0px;
    }
  }
  p {
    color: ${(props) => props.theme.global_grey};
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    font-style: normal;
    @media (max-width: 1199px) {
      font-size: 12px;
      line-height: 12px;
    }
  }
`
const AssetsItemSpe = styled.section`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  h1 {    
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    color: #131313;
    line-height: 24px;
    .dark & {
      color: #FFF;
    }
    @media (max-width: 1199px) {
      font-size: 16px;
      line-height: 28px;
      margin-top: 0px;
      margin-bottom: 4px;
    }
  }
  p {
    color: ${(props) => props.theme.global_grey};
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
    font-style: normal;
    @media (max-width: 1199px) {
      font-size: 12px;
      line-height: 12px;
    }
  }
`

export default Markets
