import { Table, Col, Row, Select, Input } from 'antd';
import type { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import type { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { SearchOutlined } from '@ant-design/icons';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom'

import styled from 'styled-components'
import { useWeb3React } from '@web3-react/core'
import { useIntl } from 'react-intl'
import BigNumber from 'bignumber.js'
import numeral from "numeral";
import moment from "moment";
import { bnToDec, formate_round_up, useQuery, setUrlParam } from 'utils';
import { network_Name, etherscan, transationLink } from 'lending-sdk/utils/index1'
import general_DL_Token from 'lending-sdk/lib/clean_build/contracts/general/DL_Token.json';
import synthetic_DL_Token from 'lending-sdk/lib/clean_build/contracts/synthetic/DL_Token.json';
import stocks_DL_Token from 'lending-sdk/lib/clean_build/contracts/stocks/DL_Token.json';

import FlexBoxWrap from "components/FlexBoxWrap"
import FlexBGFilter from 'components/FlexBGFilter'

declare global {
  interface Window {
    location: Location;
    sessionStorage: WindowSessionStorage;
    historyNetworkTimeId: NodeJS.Timeout;
    changeFormTimeId: NodeJS.Timeout
  }
}

interface DataType {
  transactionHash: string,
  liquidator: string,
  borrower: string,
  iTokenCollateral: string,
  seizeTokens: {
    seizeTokenDecimal: string,
    seizeTokenSymbol: string,
    seizeTokens: string
  },
  iTokenRepay: {
    repayTokenDecimal: string,
    repayTokenSymbol: string,
    repayTokens: string,
  },
  timeStamp: string,
  blockNumber: string
}

interface Params {
  pagination?: TablePaginationConfig;
  // sorter?: SorterResult<any> | SorterResult<any>[];
  total?: number;
  network?: string,
  searchVal?: string,
  liquidator?: string,
  borrower?: string
}

// select end

const { Option } = Select;

const { Search } = Input;

// 获取本地token配置
const deployAssets = {
  "general": general_DL_Token,
  "synthetic": synthetic_DL_Token,
  "stocks": stocks_DL_Token,
}
// searchValLable
const searchValLable = {
  "0": "All",
  "1": "Liquidator",
  "2": "Borrower"
}
const History: React.FC = () => {

  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [generalLink, setGeneralLink] = useState<Record<string, any> | undefined>(undefined)
  const [stocksLink, setStocksLink] = useState<Record<string, any> | undefined>(undefined)
  const [searchVal, setsearchVal] = useState<string>('0')
  const [Address, setAddress] = useState<Record<string, string>>({})
  const [address, setaddress] = useState<string>('')
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 30,
    pageSizeOptions: ['10', '30', '50', '100'],
    showSizeChanger: true,
    onShowSizeChange: (current, size) => { console.log(current, size) }
  });
  let query = useQuery()
  // 获取 queryString 参数 传递下去
  const queryString = window.location.hash.split('?')[1] ? ('?' + window.location.hash.split('?')[1]) : ''
  const intl = useIntl()
  const fmt = intl.formatMessage
  const {
    connector,
    account,
    chainId,
    provider,
    ENSName,
    isActive,
    isActivating,
  } = useWeb3React();


  const handleChangeBorrowers = (value: { value: string; label: React.ReactNode }) => {
    console.log(value); // { value: "lucy", key: "lucy", label: "Lucy (101)" }
    const _searchVal = value?.value
    let _Address: Record<string, string>;
    if (Object.values(Address).length > 0) {
      if (_searchVal === '1') {
        _Address = { 'liquidator': address }
      } else if (_searchVal === '2') {
        _Address = { 'borrower': address }
      } else {
        _Address = { 'liquidator': address, 'borrower': address }
      }
      const _pagination = { ...pagination, current: 1 }
      chainId && fetchData({
        ...{
          pagination: _pagination,
          // searchVal:_searchVal,
          network: network_Name[chainId]
        },
        ..._Address
      });
    } else {
      _Address = {}
    }
    setAddress(_Address)
    setsearchVal(_searchVal)
    // chainId && fetchData({
    //   ...{
    //   pagination,
    //   searchVal:_searchVal,
    //   network:network_Name[chainId]
    //   },
    //   ..._Address
    // });
  };

  const onSearch = () => {
    setaddress(address)
    let _Address: Record<string, string>;
    if (searchVal === '1') {
      _Address = { 'liquidator': address }
    } else if (searchVal === '2') {
      _Address = { 'borrower': address }
    } else {
      _Address = { 'liquidator': address, 'borrower': address }
    }
    setAddress(_Address)
    const _pagination = { ...pagination, current: 1 }
    chainId && fetchData({
      ...{
        pagination: _pagination,
        // searchVal,
        Address: address,
        network: network_Name[chainId]
      },
      ..._Address
    });
  };

  // select end

  const SearchOnchange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value
    setaddress(val)
    if (val === "") {
      let _Address: Record<string, string>;
      if (searchVal === '1') {
        _Address = { 'liquidator': val }
      } else if (searchVal === '2') {
        _Address = { 'borrower': val }
      } else {
        _Address = { 'liquidator': val, 'borrower': val }
      }
      setAddress(_Address)
      const _pagination = { ...pagination, current: 1 }
      chainId && fetchData({
        ...{
          pagination: _pagination,
          // searchVal,
          Address: val,
          network: network_Name[chainId]
        },
        ..._Address
      });
    }
  }

  const getRandomuserParams = (params: Params) => ({
    pageSize: params.pagination?.pageSize,
    pageNumber: params.pagination?.current,
    ...params,
  });
  const columns: ColumnsType<DataType> = [
    {
      title: fmt({ id: 'seizeTokens' }),
      dataIndex: 'seizeTokens',
      render: (val) => <span>{((bnToDec(new BigNumber(val.seizeTokens), Number(val.seizeTokenDecimal)) > 0 && bnToDec(new BigNumber(val.seizeTokens), Number(val.seizeTokenDecimal)) < 0.0001) ? '<0.0001' : numeral(bnToDec(new BigNumber(val.seizeTokens), Number(val.seizeTokenDecimal), 4)).format("0,0.0000"))}&nbsp;{val.seizeTokenSymbol}</span>,
    },
    {
      title: fmt({ id: 'repayAmount' }),
      dataIndex: 'iTokenRepay',
      render: (val) => <span>{((bnToDec(new BigNumber(val.repayTokens), Number(val.repayTokenDecimal)) > 0 && bnToDec(new BigNumber(val.repayTokens), Number(val.repayTokenDecimal)) < 0.0001) ? '<0.0001' : numeral(bnToDec(new BigNumber(val.repayTokens), Number(val.repayTokenDecimal), 4)).format("0,0.0000"))}&nbsp;{val.repayTokenSymbol}</span>,
    },
    {
      title: fmt({ id: 'borrower' }),
      dataIndex: 'borrower',
      render: (val) => <a href={`${etherscan?.[chainId ? chainId : '1']}${val}`} target="_blank" onClick={(e) => e.stopPropagation()}>{val.slice(0, 7) + '...' + val.slice(-4)}</a>
    },
    {
      title: fmt({ id: 'liquidator' }),
      dataIndex: 'liquidator',
      render: (val) => <a href={`${etherscan?.[chainId ? chainId : '1']}${val}`} target="_blank" onClick={(e) => e.stopPropagation()}>{val.slice(0, 7) + '...' + val.slice(-4)}</a>
    },
    {
      title: fmt({ id: 'blockNumber' }),
      dataIndex: 'blockNumber',
      render: (val) => val
    }
    // {
    //   title:fmt({ id: 'timestamp'}),
    //   dataIndex:'timeStamp',
    //   render:(val) => moment(Number(val)*1000).format('YYYY/MM/DD hh:mm')
    // }
  ];
  const fetchData = (params: Params = {}) => {
    setLoading(true);
    fetch(`https://beta.unitus.finance/general/liquidationHistoryV2?${qs.stringify(getRandomuserParams(params))}`)
      .then(res => res.json())
      .then(({ totalCount, data }) => {
        // console.log(totalCount, data)
        setData(data);
        setLoading(false);
        setPagination({
          ...params.pagination,
          total: totalCount,
          // 200 is mock data, you should read it from server
          // total: data.totalCount,
        });
        window.sessionStorage.setItem('liquidation_history', JSON.stringify({ ...params.pagination, total: totalCount }))
      });
  };

  useEffect(() => {
    clearInterval(window.historyNetworkTimeId)
    clearInterval(window.changeFormTimeId)
    setsearchVal("0")
    setAddress({})
    setaddress("")
    // window.sessionStorage.removeItem('liquidation_pagination')
    let paginationStorage = window.sessionStorage.getItem('liquidation_history')
    const paginationStorage1: TablePaginationConfig = (paginationStorage && JSON.parse(paginationStorage))
    chainId && (paginationStorage ? fetchData({ pagination: { ...paginationStorage1, current: 1 }, network: network_Name[chainId] }) : fetchData({ pagination, network: network_Name[chainId] }));
    window.historyNetworkTimeId = setInterval(() => {
      let _paginationStorage = window.sessionStorage.getItem('liquidation_history')
      const _paginationStorage1: TablePaginationConfig = (_paginationStorage && JSON.parse(_paginationStorage))
      chainId && (_paginationStorage ? fetchData({ pagination: _paginationStorage1, network: network_Name[chainId] }) : fetchData({ pagination, network: network_Name[chainId] }));
    }, 15000)
    return () => {
      clearInterval(window.historyNetworkTimeId)
    }
  }, [chainId, account]);
  useEffect(() => {
    clearInterval(window.historyNetworkTimeId)
    clearInterval(window.changeFormTimeId)
    window.changeFormTimeId = setInterval(() => {
      let _paginationStorage = window.sessionStorage.getItem('liquidation_history')
      const _paginationStorage1: TablePaginationConfig = (_paginationStorage && JSON.parse(_paginationStorage))
      chainId && (_paginationStorage ? fetchData({ ...{ pagination: _paginationStorage1, network: network_Name[chainId] }, ...Address }) : fetchData({ ...{ pagination, network: network_Name[chainId] }, ...Address }));
    }, 15000)
    return () => {
      clearInterval(window.changeFormTimeId)
    }
  }, [searchVal, Address]);
  // 动态路由链接
  useEffect(() => {
    let generalLink, stocksLink
    if (chainId) {
      generalLink = deployAssets["general"]['networks'][`${chainId}`]
      setGeneralLink(generalLink)
      stocksLink = deployAssets["stocks"]['networks'][`${chainId}`]
      setStocksLink(stocksLink)
    }
  }, [chainId])
  const handleTableChange: any = (
    newPagination: TablePaginationConfig,
    network: string
    // filters: Record<string, FilterValue>,
    // sorter: SorterResult<DataType>,
  ) => {
    console.log(newPagination)
    chainId && fetchData({
      ...{
        // sortField: sorter.field as string,
        // sortOrder: sorter.order as string,
        pagination: newPagination,
        // searchVal,
        network: network_Name[chainId]
        // ...filters,
      },
      ...Address,
    });
  };
  const handleToHashLink = (hash: string) => {
    chainId ? window.open(`${transationLink[chainId]}${hash}`, "_blank") : window.open(`${transationLink[1]}${hash}`, "_blank")
  }
  return (
    <>
      <FlexBoxWrap>
        <AssetWrap>
          <StyledTitle>
            {fmt({ id: "Liquidation" })}
          </StyledTitle>
          <PriceTitleSub>{fmt({ id: "PriceTitle__sub" })}</PriceTitleSub>
        </AssetWrap>
      </FlexBoxWrap>

      <FlexBGFilter>
        <FlexBoxWrap>
        <TabWrapInner>
          <Crumb>
            <span>
              <RouterLink to={`/Liquidation${queryString}`}>
                <BackToHome>{fmt({ id: 'Liquidation' })}</BackToHome>
              </RouterLink>
            </span>
          </Crumb>
          </TabWrapInner>
        </FlexBoxWrap>
      </FlexBGFilter>

      <FlexBoxWrap>
        <Row gutter={8} style={{ 'margin': '8px 0 14px 0' }}>
          <Col>
            <Select
              labelInValue
              defaultValue={{ value: '0', label: 'All' }}
              value={{ value: searchVal, label: searchValLable[searchVal] }}
              style={{ width: '175px' }}
              onChange={handleChangeBorrowers}
            >
              <Option value="0">All</Option>
              <Option value="1">Liquidator</Option>
              <Option value="2">Borrower</Option>
            </Select>
          </Col>
          <Col span="10" className="searchInput">
            <Input placeholder="Search Address" allowClear value={address} onChange={SearchOnchange} addonAfter={<SearchOutlined onClick={onSearch} />} />
          </Col>
        </Row>
        <Table
          className="HistoryListTable"
          onRow={record => {
            return {
              onClick: event => { handleToHashLink(record.transactionHash) }, // 点击行
              onDoubleClick: event => { },
              onContextMenu: event => { },
              onMouseEnter: event => { }, // 鼠标移入行
              onMouseLeave: event => { },
            };
          }}
          columns={columns}
          rowKey={record => record.transactionHash}
          dataSource={data}
          pagination={pagination}
          loading={loading}
          onChange={handleTableChange}
        />
      </FlexBoxWrap>
    </>
  )
}
const PriceTitleSub = styled.div`
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  width: 600px;
  @media (max-width: 1199px) {
    width: 90%;
    font-size: 12px;
    line-height: 16px;
  }
`
const AssetWrap = styled.div`
  height: 224px;
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 1199px) {
    height: auto;
    padding: 16px 0;
  }
`
const StyledTitle = styled.div`
  color: #FFF;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
  @media (max-width: 1199px) {
    font-size: 16px;
    line-height: 28px;
  }
`
const TabWrapInner = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  @media (max-width: 1199px) {
    min-width: 100%;
    /* flex-direction: column; */
    flex-wrap: wrap;
  }
`
const Crumb = styled.div`
  display:flex;
  justify-content:space-between;
  align-items:center;
  width:120px;
  height:32px;
  /* margin-top: 15px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.08); */
  padding-left: 8px;
  @media (max-width: 1199px) {
    height: 24px;
    margin-top: 3px;
  }
`
const RouterLink = styled(NavLink)`
  color: ${props => props.theme.colors.primary.light};
  &:hover {
    color: ${props => props.theme.colors.primary.light};
  }
`
const BackToHome = styled.b`
  display:flex;
  align-items:center;
  font-size:16px;
  height:30px;
  line-height:30px;
  color: #fff;
  font-weight: 500;
  &:before {            
    content: '';            
    display: block;
    width: 8px;            
    height: 8px; 
    margin-right:5px;      
    border-right: 2px solid ${props => props.theme.colors.lightGrey};            
    border-top: 2px solid ${props => props.theme.colors.lightGrey};            
    -webkit-transform: rotate(-135deg); /*箭头方向可以自由切换角度*/            
    transform: rotate(-135deg);
}
`

export default History
