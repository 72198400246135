import React, { useState, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { useIntl } from 'react-intl'
import lido from 'assets/home/lido.svg'
// import { requestAPI } from 'utils'
interface Logo_Token_numberProps {
  token: string,
  tab?: string,
  myWallet?: string | null,
  value?: string,
  isLido?: string
}
interface LogosProps {
  [propName: string]: any
}


const Logo_Token_number: React.FC<Logo_Token_numberProps> = ({ tab, token, myWallet, value, isLido }) => {
  const intl = useIntl()
  const fmt = intl.formatMessage
  const [WSTethAPY, setWSTethAPY] = useState('0');
  const fetchWSTethAPY = useCallback(async () => {
    // const result: any = await requestAPI("https://yields.llama.fi/poolsEnriched?pool=747c1d2a-c668-4682-b9f9-296708a3dd90")
    try {
      const response = await fetch("https://yields.llama.fi/poolsEnriched?pool=747c1d2a-c668-4682-b9f9-296708a3dd90")
      const res = await response.json();
      if(res.status === 'success'){
        setWSTethAPY(res?.["data"]?.[0]?.apy)
      }else{
        setWSTethAPY('0')
      }
    } catch (error) {
      console.log(error)
      setWSTethAPY('0')
    }
  }, [])
  useEffect(() => {
    fetchWSTethAPY()
  }, [])
  let ImgIcon
  try {
    ImgIcon = require(`assets/tokenList/${token}.svg`)
  } catch (error) {
    ImgIcon = require(`assets/tokenList/default.svg`)
  }
  const renderLink = (tokenType: string) => {
    if (tokenType === 'rETH') {
      return (
        <LidoLink
          href='https://stake.rocketpool.net/'
          target="_blank" rel="noreferrer">
          {'Get rETH'}
        </LidoLink>
      )
    }
    if (tokenType === 'sDAI') {
      return (
        <LidoLink
          href='https://app.sparkprotocol.io/sdai/'
          target="_blank" rel="noreferrer">
          {'Get sDAI'}
        </LidoLink>
      )
    }
    if (tokenType === 'tToken') {
      return (
        <LidoLink target="_blank" rel="noreferrer"
          href="https://stake.lido.fi/?ref=0x0Cf797324F358Aca98acFC9CB94D14C9738921F7">
          {`${WSTethAPY}% on Stake`}
          <LidoIcon src={lido} />
        </LidoLink>
      )
    }
  }


  return (
    <StyledSupplied>
      <SuppliedToken>
        {/* <StyledImg src={logos[token]} alt="" /> */}
        <StyledImg src={ImgIcon} alt="" />
        <SymbolInfo>
          <Symbol>
            {token}
            {
              tab === 'supply' ?
                fmt({ id: 'Supplied' })
                :
                tab === 'borrow' ?
                  fmt({ id: 'borrowed' }) : fmt({ id: 'minted' })
            }
          </Symbol>
          {isLido && renderLink(isLido)}

        </SymbolInfo>

      </SuppliedToken>

      <StyledSuppliedNumber>
        {
          (myWallet && value) &&
          <StyledSpan tab={tab}>{value}</StyledSpan>
        }
        {
          !(myWallet && value) &&
          <span style={{ color: `${(props: any) => props.theme.operationPage.grey}` }}>{'...'}</span>
        }
      </StyledSuppliedNumber>
    </StyledSupplied>
  )
}

interface StyledSpanProps {
  tab?: string
}
const StyledSpan = styled.span<StyledSpanProps>`
  display: flex;
  align-items: center;
  color: ${props => props.tab === 'supply' ? props.theme.global_supply : props.tab === 'borrow' ? '#05C7A0' : '#507BFC'
  };
`

const StyledSupplied = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.operationPage.light.borderColor};
  display: flex;
  justify-content: space-between;
  padding-bottom: 18px;
  padding-top: 32px;

  .dark & {
    border-bottom: 1px solid ${(props) => props.theme.operationPage.dark.borderColor};
  }
`
const SuppliedToken = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  color: ${(props) => props.theme.operationPage.light.mainBlack};

  .dark & {
    color: ${(props) => props.theme.operationPage.dark.mainBlack};
  }

  @media (max-width: 1119px) {
    font-size: 13px;
    line-height: 14px;    
  }
`
const StyledImg = styled.img`
  margin-right: 15px;
  width: 34px;

  @media (max-width: 1119px) {
    width: 20px;
    margin-right: 5px;
  }
`
const SymbolInfo = styled.div`
  display:flex;
  flex-direction:column;
  align-items:center;
`
const Symbol = styled.div`
  display:flex;
  flex-direction:row;
  align-self: flex-start;
`
const LidoLink = styled.a`
  display:flex;
  flex-direction:row;
  align-self:flex-start;
  align-items:center;
  color:${(props) => props.theme.colors.supply.dark};
  font-size:16px;
  line-height:25px;
  font-weight:normal;
  cursor: pointer;
  &:hover{
    color:${(props) => props.theme.colors.supply.dark};
    opacity:0.8;
  }
  &:focus{
    color:${(props) => props.theme.colors.supply.dark};
  }
  @media (max-width: 767px) {
    font-size:14px;
    line-height:14px;
    margin-top:5px;
  }
`
const LidoIcon = styled.img`
  width:9px;
  height:13px;
  margin-left:5px;
  margin-top:4px;
  @media (max-width: 767px) {
    margin-top:2px;
  }
`
const StyledSuppliedNumber = styled.div`
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: bold;

  @media (max-width: 1119px) {
    font-size: 14px;
  }
`

export default Logo_Token_number