/* eslint-disable react/jsx-pascal-case */
import React, { useCallback, useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import ReactApexChart from "react-apexcharts";
import { useIntl } from "react-intl";
import { useWeb3React } from '@web3-react/core'
import BigNumber from "bignumber.js";
import { ethers, BigNumber as BigNumberE } from "ethers";
import ReactTooltip from "react-tooltip";
import UnlockWalletModal from "components/UnlockWalletModal";
import BorderWrap from "components/BorderWrap";
import NoteTips from "components/NoteTips";
import PaddingWrap from "components/PaddingWrap";
import Logo_Token_number from "components/Logo_Token_number";
import Token_Number from "components/Token_Number";
import ChangeableValue from "components/ChangeableValue";
import Notification from "components/Notification";
import Notification__cancel from "components/Notification__cancel";
import ChangeableValueToBe from "components/ChangeableValue/ChangeableValueToBe";
import useLending from "hooks/useLending";
import useAllowance from "hooks/useAllowance";
import useDebounce from '../../hooks/useDebounce'
import StyledApproveFirst from 'styledComponents/StyledApproveFirst'
import StyledYouDid from 'styledComponents/StyledYouDid'
import StyledLeftSection from 'styledComponents/StyledLeftSection'
import StyleRightSection from 'styledComponents/StyleRightSection'
import ActionTab from 'styledComponents/ActionTab'
import { StyledChart, OverSafeMAX, StyledBool, InputWrap } from 'styledComponents/MulStyled'
import {
  format_bn__new as format_bn,
  format_num_to_K,
  formatTimeStamp,
  deconstruction_wallet_source,
  postDataToServer,
  useQuery, DFApproveXArray,
  calcUR,
  objToJsonArr
} from "utils";
import {
  getAccountTotalValue,
  getAccountSupplyTokens,
  getAccountBorrowTokens,
  getAccountMSDTokens,
  multicall_lsr,
  multicall_target
} from "lending-sdk/utils/index1";
import { network_Name, add_Notifies, update_Notifies, del_Notify, del__cancel } from 'lending-sdk/utils/index1'
import { fetchAPY } from "./fetchAPY";
import metamask from "assets/wallet-MetaMask.svg";
import Brave from 'assets/walletLog/Brave.svg';
import coinbase from "assets/wallet-Coinbase.svg";
import icon_walletconnect from "assets/wallet-WalletConnect.svg";
import tooltip from "assets/supply/tooltip.svg";
import dForceLogo from "assets/home/loading.gif";
import img__on__notClick from "assets/supply/on__notClick.svg";
import img__off from "assets/supply/img__off.svg";
import img__on from "assets/supply/img__on.svg";
import { factor } from 'constants/constants.js';
import general_tokens from 'lending-sdk/lib/clean_build/contracts/general/IERC20.json';
import synthetic_tokens from 'lending-sdk/lib/clean_build/contracts/synthetic/IERC20.json';
import stocks_tokens from 'lending-sdk/lib/clean_build/contracts/stocks/IERC20.json';
import LendingHelperABI from 'abis/LendingHelper.json';
import LendingHelperAddress from 'abis/LendingHelperAddress.json';
import FlexBoxWrap from "components/FlexBoxWrap"
import { LendingDataV2 } from 'lendingData/gen'
import controller_json from 'lending-sdk/lib/clean_build/contracts/general/Controller.json'
import useMarkets from "hooks/useMarkets"
import iETHAbi from 'lending-sdk/lib/clean_build/contracts/general/iETHAbi.json';
import Modal from "components/ModalAction"
import close from "assets/stake/closex.svg"
import tanhao from "assets/Bridge/tanhao.svg"



const SupplyWithdraw: React.FC = () => {
  const {
    connector,
    account,
    chainId,
    provider,
    ENSName,
    isActive,
    isActivating,
  } = useWeb3React();
  const Lending = useLending();

  // const { allList_supply } = useMarkets()

  // const [lengthNormalAssetsCollateral, setLengthNormalAssetsCollateral] = useState(false)
  // const [lengthISOAssetsCollateral, setLengthISOAssetsCollateral] = useState(false)

  // console.log(Lending)
  const cur_Pool: any = {
    general: general_tokens,
    synthetic: synthetic_tokens,
    stocks: stocks_tokens
  }

  const [curPercentage, setCurPercentage] = useState(0);
  const [curPercentage__tab2, setCurPercentage__tab2] = useState(0);

  const [hasClickCollateral, setHasClickCollateral] = useState(false);
  const [isUserSelected, setIsUserSelected] = useState(true);
  const [isUserSelected__ISO, setIsUserSelected__ISO] = useState(false);

  const [unlockModalIsOpen, setUnlockModalIsOpen] = useState(false);
  const [metamaskActive, setMetamaskActive] = useState(false);
  const [walletConnectActive, setWalletConnectActive] = useState(false);
  const [walletIcon, setWalletIcon] = useState("");

  const [supplyTokenData, setSupplyTokenData] = useState<any>();
  const [accountSupplyData, setAccountSupplyData] = useState<any>();
  const [accountBorrowData, setAccountBorrowData] = useState<any>();
  const [accountBorrowData__mint, setAccountBorrowData__mint] = useState<any>();

  const [assetInterestData, setAssetInterestData] = useState<any>();
  const [toBeAPY, setToBeAPY] = useState<any>();
  const [toBeAPY_tab2, setToBeAPY_tab2] = useState<any>();
  const [accountEquity, setAccountEquity] = useState<any>();
  const [healthRatio, setHealthRatio] = useState<any>();
  const [healthRatio_tab2, setHealthRatio_tab2] = useState<any>();

  const [toBePower, setToBePower] = useState<any>();
  const [toBePower_tab2, setToBePower_tab2] = useState<any>();

  const [accountSupplyInfo, setAccountSupplyInfo] = useState<any>();
  const [accountTotalValue, setAccountTotalValue] = useState<any>();
  const [marketsInfo, setMarketsInfo] = useState<any>();

  const [delayStrategyConfig, setDelayStrategyConfig] = useState<any>();
  const [delayTime, setDelayTime] = useState<any>();

  const [supply_number, setSupply_number] = useState<any>();
  const [withdraw_number, setWithdraw_number] = useState<any>();
  const [is_supply_max, setIs_supply_max] = useState<boolean>(false);
  const [is_withdraw_max, setIs_withdraw_max] = useState<boolean>(false);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [isChoosen__supply__tab, setIsChoosen__supply__tab] = useState<any>();
  const { CurrencyPair } = useParams<any>();
  // 获取router URLParams
  const navigate = useNavigate()


  const [nowNotifies, setNowNotifies] = useState<any>([]);
  const [show__cancel, setShow__cancel] = useState<boolean>(false);
  const [chainID, setChainID] = useState<number>(1);

  const intl = useIntl();
  const intl_locale = intl.locale;
  const fmt = intl.formatMessage;

  const local_account = window.localStorage.getItem('account') || ''
  // console.log(local_account)

  const [enable_status, setEnable_status] = useState<string>(fmt({ id: "ENABLE" }));
  const [bool_Btn, setBool_Btn] = useState<boolean>(false);
  const [bool_Btn__enable, setBool_Btn__enable] = useState<boolean>(false);

  const [wallet_source, setWallet_source] = useState<string>("");

  const [chartState, setChartState] = useState<any>({
    options: {
      chart: {
        toolbar: {
          show: false,
          tools: {
            download: false,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
          },
        },
        height: 350,
        type: "line",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 2,
      },
      xaxis: {
        type: "category",
        categories: [],
        min: null,
        tickAmount: 4,
        tickPlacement: "on",
        labels: {
          rotate: 0,
          style: {
            colors: "#9195A4",
          },
        },
      },
      fill: {
        type: "image",
        gradient: {
          shadeIntensity: 1,
          inverseColors: false,
          opacityFrom: 0.6,
          opacityTo: 0.45,
          stops: [25, 100, 100, 100],
        },
      },
      yaxis: {
        labels: {
          formatter: function (value: any) {
            return value.toFixed(2) + "%";
          },
          style: {
            colors: "#9195A4",
          },
        },
      },
      colors: ['#FF764B'],
    },
    series: [{
      name: "APY",
      data: []
    }]
  });
  const ParamsArr = (CurrencyPair as string).split("-");
  const this__action = ParamsArr[0];
  const this__token = ParamsArr[1] + (ParamsArr[2] ? '-' + ParamsArr[2] : '');
  // console.log(CurrencyPair)
  // console.log(this__action, this__token)
  const isGasToken = Lending?.contracts?.general[this__token]?.gasToken || false;
  const gasTokenReserve = 0.005

  // if (chainId) {
  //   let curTokens = Object.keys(curPool?.general.networks[`${chainId}`])
  //   curTokens.push('ETH')
  //   // console.log(curTokens)
  //   // console.log(curTokens.includes(this__token))
  //   if (!curTokens.includes(this__token)) {
  //     console.log(curTokens.includes(this__token))
  //     window.location.href = '/lending'
  //   }
  // }


  useEffect(() => {
    setSupply_number(undefined)
    setWithdraw_number(undefined)
    setBool_Btn(false);
    setBool_Btn__enable(false)
  }, [chainId, account])

  // useEffect(() => {
  //   setSupply_number(undefined)
  //   setWithdraw_number(undefined)
  // }, [account])

  const checkBoxChange = (val: any) => {
    console.log(val)
    setIsUserSelected(val)
  }
  const checkBoxChange__ISO = (val: any) => {
    console.log(val)
    setIsUserSelected__ISO(val)
  }



  useEffect(() => {
    if (Lending && chainId && this__token && account) {
      const asyncGetData = async () => {
        try {
          const iTokenAddress = Lending.contracts?.general["i" + this__token]?.address
          // const helperContract = new Lending.web3.eth.Contract(LendingHelperABI, LendingHelperAddress[chainId])
          // const assetInterestData = await helperContract.methods.getAssetInterestData(iTokenAddress).call()
          // // uint256	totalBorrows	  decimal($)(1e18) [0]
          // // uint256	totalReserves	  decimal($)(1e18) [1]
          // // uint256	cash	          decimal($)(1e18) [2]
          // // uint256	reserveRatio	  decimal(%)(1e18) [3]
          // // uint256	base	          decimal(%)(1e18) [4] 
          // // uint256	optimal	        decimal(%)(1e18) [5]
          // // uint256	slope_1	        decimal(%)(1e18) [6]
          // // uint256	slope_2	        decimal(%)(1e18) [7]
          // // console.log(assetInterestData)
          // setAssetInterestData(assetInterestData)
          // const controllerAddress = Lending.contracts?.general.Controller.address
          // console.log(controllerAddress)

          // const accountEquity = await helperContract.methods.getAccountCurrentEquity(iTokenAddress, account).call()
          // // uint256	euqity	      decimal(1e36)     [0]  ===> borrow power  // Borrowing_Power
          // // uint256	shortfall	    decimal(1e36)     [1]
          // // uint256	sumCollateral	decimal($)(1e18)  [2]
          // // uint256	sumBorrowed	  decimal($)(1e18)  [3]
          // // console.log(accountEquity)
          // setAccountEquity(accountEquity)
          // console.log(Lending.contracts?.general)

          const provider = new ethers.providers.Web3Provider(Lending.web3.currentProvider)
          const controller_address = controller_json.networks[chainId].Controller.address
          const ld = new LendingDataV2(controller_address, provider)
          const assetInterestData = await ld.getAssetInterestData(iTokenAddress)
          setAssetInterestData(assetInterestData)

          const accountEquity = await ld.getAccountCurrentEquity(iTokenAddress, account)
          setAccountEquity(accountEquity)


          // const contractController = Lending.contracts?.general.Controller
          // const marketsInfo = await contractController.methods.markets(iTokenAddress).call()
          // console.log(accountEquity && accountEquity[0].toString())
          // bool	mint paused	true: paused; false: unpause;    [4]
          // bool	redeem paused	true: paused; false: unpause;  [5]
          // bool	borrow paused	true: paused; false: unpause;  [6]
          // setMarketsInfo(marketsInfo)
        } catch (error) { console.log(error) }
      }

      asyncGetData()

      let t = setInterval(asyncGetData, 1000 * 10)
      return () => clearInterval(t)
    }
  }, [Lending, chainId, this__token, account])



  const supply_number__debounced = useDebounce(supply_number, 500)
  const withdraw_number__debounced = useDebounce(withdraw_number, 500)

  // Adequacy Ratio
  useEffect(() => {
    if (withdraw_number__debounced && accountEquity && supplyTokenData && accountSupplyInfo) {
      const healthRatio_tab2 = new BigNumber(accountEquity[2].toString())
        .minus(
          new BigNumber(withdraw_number__debounced)
            .multipliedBy(new BigNumber(supplyTokenData[2].toString()))
            .multipliedBy(new BigNumber(supplyTokenData[4].toString()))
            .dividedBy(new BigNumber(10).pow(new BigNumber(18)))
        )
        .multipliedBy(new BigNumber(10).pow(new BigNumber(18)))
        .dividedBy(new BigNumber(accountEquity[3].toString()))
        .toFixed(0)

      if (new BigNumber(healthRatio_tab2).lt(new BigNumber(0))) {
        return setHealthRatio_tab2('0.00')
      }
      setHealthRatio_tab2(healthRatio_tab2)


      if (accountSupplyInfo[1]) {
        const tmp = new BigNumber(accountEquity[0].toString())
          .minus(
            new BigNumber(withdraw_number__debounced)
              .multipliedBy(new BigNumber(1e18))
              .multipliedBy(new BigNumber(supplyTokenData[2].toString()))
              .multipliedBy(new BigNumber(supplyTokenData[1].toString()))
              .dividedBy(new BigNumber(10).pow(new BigNumber(36)))
          )
          .toFixed(0)
          .toString()
        setToBePower_tab2(tmp)
      } else {
        setToBePower_tab2(accountEquity[0].toString())
      }
    }
  }, [withdraw_number__debounced, accountEquity, supplyTokenData, accountSupplyInfo])
  useEffect(() => {
    if (supply_number__debounced && accountEquity && supplyTokenData && accountSupplyInfo) {
      // accountEquity[2]   // sumCollateral($)
      // supplyTokenData[2] // price
      // supplyTokenData[4] // t-hold (***) 
      // accountEquity[3]   // sumBorrowed($) 
      const tmp_ratio = new BigNumber(accountEquity[2].toString())
        .plus(
          new BigNumber(supply_number__debounced)
            .multipliedBy(new BigNumber(supplyTokenData[2].toString()))
            .multipliedBy(new BigNumber(supplyTokenData[4].toString()))
            .dividedBy(new BigNumber(1e18))
        )
        .multipliedBy(new BigNumber(1e18))
        .dividedBy(new BigNumber(accountEquity[3].toString()))
        .toFixed(0)

      if (new BigNumber(tmp_ratio).lt(new BigNumber(0))) {
        return setHealthRatio(0)
      }
      // console.log(tmp_ratio, accountEquity[2].toString(), accountEquity[3].toString())
      setHealthRatio(tmp_ratio)


      // supplyTokenData[1] // ltv
      // supplyTokenData[2] // price
      // accountSupplyInfo[1] // asC
      // accountEquity[0] borrow power
      // accountEquity[1] 	shortfall
      if (new BigNumber(tmp_ratio).lte(new BigNumber(1e18))) {
        return setToBePower('0')
      }
      // console.log('borrow power: ',accountEquity[0].toString())
      if (new BigNumber(accountEquity[0].toString()).eq(new BigNumber('0'))) {
        // console.log('nnnnn')
        if (
          !(
            (accountSupplyInfo[1]) ||
            (isUserSelected && // User Selected (default: true)
              !accountSupplyInfo[4] && // normal assert
              !accountSupplyInfo[1]  // non as Collateral
              && accountSupplyInfo[2] // can exe.
            ) ||
            isUserSelected__ISO
          )
        ) {
          // console.log('lllll')
          setToBePower('0')
          return
        }
        const tmp = new BigNumber(supply_number__debounced)
          .multipliedBy(new BigNumber(1e18))
          .multipliedBy(new BigNumber(supplyTokenData[2].toString()))
          .multipliedBy(new BigNumber(supplyTokenData[1].toString()))
          .dividedBy(new BigNumber(10).pow(new BigNumber(36)))
          // .plus(accountEquity[2].toString())
          // .minus(accountEquity[3].toString())
          .minus(accountEquity[1].toString())
          .toFixed(0)
          .toString()
        return setToBePower(new BigNumber(tmp).lt(new BigNumber(0)) ? '0' : tmp)
      }
      if (
        (accountSupplyInfo[1]) ||
        (isUserSelected && // User Selected (default: true)
          !accountSupplyInfo[4] && // normal assert
          !accountSupplyInfo[1]  // non as Collateral
          && accountSupplyInfo[2] // can exe.
        ) ||
        isUserSelected__ISO
      ) {
        // console.log('nnnnn')
        const tmp = new BigNumber(accountEquity[0].toString())
          .plus(
            new BigNumber(supply_number__debounced)
              .multipliedBy(new BigNumber(1e18))
              .multipliedBy(new BigNumber(supplyTokenData[2].toString()))
              .multipliedBy(new BigNumber(supplyTokenData[1].toString()))
              .dividedBy(new BigNumber(10).pow(new BigNumber(36)))
          )
          .toFixed(0)
          .toString()
        setToBePower(tmp)
      } else {
        setToBePower(accountEquity[0].toString())
      }
    }
  }, [supply_number__debounced, accountEquity, supplyTokenData, accountSupplyInfo, isUserSelected, isUserSelected__ISO])


  useEffect(() => {
    if (supply_number__debounced && assetInterestData && supplyTokenData) {
      const _cash = new BigNumber(assetInterestData[2].toString())
        .plus(new BigNumber(supply_number__debounced).multipliedBy(new BigNumber(supplyTokenData[2].toString())))
      const _borrows = new BigNumber(assetInterestData[0].toString())
      const _reserves = new BigNumber(assetInterestData[1].toString())
      let ur = calcUR(_cash, _borrows, _reserves)

      let borrow_rate
      if (ur.lt(new BigNumber(assetInterestData[5].toString()))) {
        borrow_rate = new BigNumber(assetInterestData[6].toString())
          .multipliedBy(ur)
          .dividedBy(new BigNumber(assetInterestData[5].toString()))
          .plus(new BigNumber(assetInterestData[4].toString()))
      } else {
        borrow_rate = new BigNumber(assetInterestData[7].toString())
          .multipliedBy(ur.minus(new BigNumber(assetInterestData[5].toString())))
          .dividedBy(new BigNumber(10).pow(new BigNumber(18)).minus(new BigNumber(assetInterestData[5].toString())))
          .plus(new BigNumber(assetInterestData[4].toString()))
          .plus(new BigNumber(assetInterestData[6].toString()))
      }
      // console.log('borrow_rate: ', borrow_rate.toString())
      const borrow_rate_day = borrow_rate.dividedBy(new BigNumber(365))
      // console.log('borrow_rate_day: ', borrow_rate_day.toString())
      // supply_rate = borrow_rate*ur*(1-reserve_ratio)
      let supply_rate_day: any = borrow_rate_day
        .multipliedBy(ur)
        .multipliedBy(new BigNumber(10).pow(new BigNumber(18)).minus(new BigNumber(assetInterestData[3].toString())))
        .dividedBy(new BigNumber(10).pow(new BigNumber(36)))
        .toFixed(0)
      supply_rate_day = new BigNumber(supply_rate_day)

      const BASE = (new BigNumber('10')).pow(new BigNumber('18'))
      const supply_rate_year = rpow(supply_rate_day.plus(BASE), new BigNumber(365))
      const borrow_rate_year = rpow(borrow_rate_day.plus(BASE), new BigNumber(365))
      // console.log('supply_rate_year: ', supply_rate_year.toString())
      // console.log('borrow_rate_year: ', borrow_rate_year.toString())
      setToBeAPY(supply_rate_year.toString())
      // console.log(supply_number__debounced , assetInterestData , supplyTokenData.toString())
    }
  }, [supply_number__debounced, assetInterestData, supplyTokenData])
  useEffect(() => {
    if (withdraw_number__debounced && assetInterestData && supplyTokenData) {
      const _cash = new BigNumber(assetInterestData[2].toString())
        .minus(new BigNumber(withdraw_number__debounced).multipliedBy(new BigNumber(supplyTokenData[2].toString())))
      const _borrows = new BigNumber(assetInterestData[0].toString())
      const _reserves = new BigNumber(assetInterestData[1].toString())
      let ur = calcUR(_cash, _borrows, _reserves)
      // console.log('ur: ', ur.toString())

      // console.log('ur: ', ur.toString(), assetInterestData[5])
      let borrow_rate
      if (ur.lt(new BigNumber(assetInterestData[5].toString()))) {
        borrow_rate = new BigNumber(assetInterestData[6].toString())
          .multipliedBy(ur)
          .dividedBy(new BigNumber(assetInterestData[5].toString()))
          .plus(new BigNumber(assetInterestData[4].toString()))
      } else {
        borrow_rate = new BigNumber(assetInterestData[7].toString())
          .multipliedBy(ur.minus(new BigNumber(assetInterestData[5].toString())))
          .dividedBy(new BigNumber(10).pow(new BigNumber(18)).minus(new BigNumber(assetInterestData[5].toString())))
          .plus(new BigNumber(assetInterestData[4].toString()))
          .plus(new BigNumber(assetInterestData[6].toString()))
      }
      // console.log('borrow_rate: ', borrow_rate.toString())
      const borrow_rate_day = borrow_rate.dividedBy(new BigNumber(365))
      // console.log('borrow_rate_day: ', borrow_rate_day.toString())
      // supply_rate = borrow_rate*ur*(1-reserve_ratio)
      let supply_rate_day: any = borrow_rate_day
        .multipliedBy(ur)
        .multipliedBy(new BigNumber(10).pow(new BigNumber(18)).minus(new BigNumber(assetInterestData[3].toString())))
        .dividedBy(new BigNumber(10).pow(new BigNumber(36)))
        .toFixed(0)
      supply_rate_day = new BigNumber(supply_rate_day)
      // console.log('supply_rate_day: ', supply_rate_day.toString())
      const BASE = (new BigNumber('10')).pow(new BigNumber('18'))
      const supply_rate_year = rpow(supply_rate_day.plus(BASE), new BigNumber(365))
      const borrow_rate_year = rpow(borrow_rate_day.plus(BASE), new BigNumber(365))
      // console.log('supply_rate_year111: ', supply_rate_year.toString())
      // console.log('borrow_rate_year: ', borrow_rate_year.toString())
      setToBeAPY_tab2(supply_rate_year.toString())
      // console.log(withdraw_number__debounced , assetInterestData , supplyTokenData.toString())
    }
  }, [withdraw_number__debounced, assetInterestData, supplyTokenData])
  const rpow = (a: any, b: any) => {
    const BASE = (new BigNumber('10')).pow(new BigNumber('18'))
    // const daysPerYear = new BN('365');
    return (
      a
        .pow(b)
        .dividedBy(BASE.pow(b.minus(new BigNumber('1'))))
        .minus(BASE)
        .toFixed(0)
    )
  }



  useEffect(() => {
    if (chainId) {
      try {
        let curTokens = Object.keys(cur_Pool?.general.networks[`${chainId}`])
        // debugger
        console.log(this__token)
        curTokens.push(chainId === 137 ? '' : 'ETH')
        curTokens.push(chainId === 2221 ? 'KAVA' : '')
        curTokens.push(chainId === 56 || chainId === 97 ? 'BNB' : '')
        curTokens.push(chainId === 137 ? 'MATIC' : '')
        curTokens.push(chainId === 71 ? 'CFX' : '')
        curTokens.push(chainId === 1030 ? 'CFX' : '')
        if (!curTokens.includes(this__token)) {
          navigate(`/lending`)
          return
        }
      } catch (error) { console.log(error) }
    }
  }, [chainId, this__token])


  useEffect(() => {
    if (enable_status === "ENABLE" || "授权") {
      setEnable_status(fmt({ id: "ENABLE" }));
    } else {
      setEnable_status(fmt({ id: "ENABLING" }));
    }
  }, [intl_locale]);

  const fetchApi = useCallback(async () => {
    // console.log(Lending.contracts?.general[this__token].address)
    const echartsAPY =
      Lending &&
      (await fetchAPY(
        chainId,
        Lending.contracts?.general["i" + this__token]?.address,
        'supply'
      ));
    const seriesData = echartsAPY.length > 0 ?
      echartsAPY.map((item: any) => format_bn(item.apy, 16, 2)) : [];
    const categories = echartsAPY.length > 0 ?
      echartsAPY.map((item: any) => { return formatTimeStamp(new Date(parseInt(item.timeStamp) * 1000), "MM/dd") }) : [];
    setChartState({
      series: [
        {
          name: "APY",
          // data: [31, 40, 38, 79]
          data: seriesData,
        },
      ],
      options: {
        chart: {
          toolbar: {
            show: false,
            tools: {
              download: false,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
            },
          },
          height: 350,
          type: "line",
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: 2,
        },
        xaxis: {
          type: "category",
          categories,
          min: echartsAPY && (echartsAPY.length > 1 ? echartsAPY[0]?.timeStamp : null),
          tickAmount: 4,
          tickPlacement: "on",
          labels: {
            rotate: 0,
            style: {
              colors: "#9195A4",
            },
          },
        },
        fill: {
          type: "image",
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.6,
            opacityTo: 0.45,
            stops: [25, 100, 100, 100],
          },
        },
        yaxis: {
          labels: {
            formatter: function (value: any) {
              return value.toFixed(2) + "%";
            },
            style: {
              colors: "#9195A4",
            },
          },
        },
        colors: ['#FF764B'],
      },
    });
  }, [Lending, this__token]);

  useEffect(() => {
    if (Lending && this__token) {
      fetchApi();
      setSupply_number('')
      setWithdraw_number('')
    }
  }, [Lending, this__token]);
  const allowance = useAllowance(
    Lending?.contracts?.general[this__token]?.address,
    Lending?.contracts?.general["i" + this__token]?.address
  );
  // console.log('allowance: ',allowance?.toNumber())

  const handleDismissUnlockModal = useCallback(() => {
    setUnlockModalIsOpen(false);
    setMetamaskActive(false);
    setWalletConnectActive(false);
  }, [setUnlockModalIsOpen]);

  const handleUnlockWalletClick = useCallback(() => {
    setUnlockModalIsOpen(true);
  }, [setUnlockModalIsOpen]);

  const getStatesData = useCallback(async () => {
    console.log("***** get States Data *****")
    if (Lending && account && chainId) {
      const provider = new ethers.providers.Web3Provider(Lending.web3.currentProvider)
      const controller_address = controller_json.networks[chainId].Controller.address
      const ld = new LendingDataV2(controller_address, provider)
      const iTokenAddress = Lending.contracts?.general["i" + this__token]?.address

      //   Supply APY (uint256)  //  supplyTokenData[0]
      //   Loan to Value (uint256)  //  supplyTokenData[1]
      //   USDx Price (uint256)  //  supplyTokenData[2]
      //   isSuperCharged: boolean
      //   t-hold (***) 
      const fn_getSupplyTokenData = new Promise((resolve, reject) => {
        const fn = async () => {
          const res = await ld.getSupplyTokenData(iTokenAddress, account)
          return res
        }
        const res = fn()
        resolve(res)
      })


      //   Supply Balance ($) (uint256)  //  accountTotalValue[0]
      //   Collateral Balance ($) (uint256)  //  accountTotalValue[1]
      //   Borrow Balance ($) (uint256)  //  accountTotalValue[2]
      //   Collateralization ratio (%) (uint256)  //  accountTotalValue[3]
      const fn_getAccountTotalValue = new Promise((resolve, reject) => {
        const fn = async () => {
          const res = await ld.getAccountTotalValue(account)
          return res
        }
        const res = fn()
        resolve(res)
      })


      //   Token Supplied (uint256)  // accountSupplyData[0]
      //   Token Balance (uint256)  //  accountSupplyData[1]
      //   account Max Supply (uint256)  //  accountSupplyData[2]
      //   Available to Withdraw (uint256)  // accountSupplyData[3]
      //   SAFE MAX Withdraw (uint256)  // accountSupplyData[4]
      //   DLToken Balance (uint256)  // accountSupplyData[5]
      //   Decimals (uint256)  // accountSupplyData[6]
      const fn_getAccountSupplyData = new Promise((resolve, reject) => {
        const fn = async () => {
          const res = await ld.getAccountSupplyData(iTokenAddress, account, factor)
          return res
        }
        const res = fn()
        resolve(res)
      })


      //   Token Value ($)(uint256)  //  accountSupplyInfo[0]
      //   As Collateral (bool) (true: Already used as collateral; false: Not as collateral)  accountSupplyInfo[1]
      //   executed (bool) (true: Can be executed; false: Not executable)  accountSupplyInfo[2]
      //   markets_closed  //  accountSupplyInfo[3]
      //   is iso assert (bool)
      //   a to borrow (decimals 2)
      const fn_getAccountSupplyInfo = new Promise((resolve, reject) => {
        const fn = async () => {
          const res = await ld.getAccountSupplyInfo(iTokenAddress, account, factor)
          return res
        }
        const res = fn()
        resolve(res)
      })


      const [
        SupplyTokenData,
        AccountSupplyData,
        AccountSupplyInfo,
        AccountTotalValue
      ] = await Promise.all([
        fn_getSupplyTokenData,
        fn_getAccountSupplyData,
        fn_getAccountSupplyInfo,
        fn_getAccountTotalValue
      ])

      setSupplyTokenData(SupplyTokenData)
      setAccountTotalValue(AccountTotalValue)
      setAccountSupplyData(AccountSupplyData)
      setAccountSupplyInfo(AccountSupplyInfo)
      // console.log(AccountSupplyData.toString())
      // console.log(SupplyTokenData.toString())
      // console.log(AccountSupplyInfo)
      // console.log('ratio: ', AccountTotalValue && AccountTotalValue[3].toString())


      // const supplyTokenList = await ld.getAccountSupplyTokens(account)
      // console.log(supplyTokenList)
      // const supplyJsonArr = objToJsonArr(supplyTokenList)
      // console.log(supplyJsonArr)
      // const fetchSupplyList = await Promise.all(
      //   supplyJsonArr.map(async (item: any, index: any) => {
      //     const iToken_address = item?.address;
      //     const AsCollateralDetails = await ld.getAccountSupplyInfo(iToken_address, account, factor)
      //     return {
      //       AsCollateralDetails: AsCollateralDetails,
      //       isSegregationAssert: AsCollateralDetails[4] ?? false,
      //     };
      //   })
      // )


      // console.log(fetchSupplyList)
      // // tmp
      // if (fetchSupplyList && fetchSupplyList.length > 0) {
      //   const AsCollateralNormalAssetsArray: any = []
      //   const AsCollateralISOAssetsArray: any = []
      //   fetchSupplyList.map((item: any) => {
      //     // console.log(item.symbol, ' is-iso-', item.isSegregationAssert, 'executed-', item.AsCollateralDetails[2])
      //     // As-Collateral
      //     if (item.AsCollateralDetails[1]) {
      //       // Normal Assets
      //       if (!item.isSegregationAssert) {
      //         AsCollateralNormalAssetsArray.push(item)
      //       }
      //       // ISO Assets
      //       if (item.isSegregationAssert) {
      //         AsCollateralISOAssetsArray.push(item)
      //       }
      //     }
      //   })

      //   // console.log(AsCollateralNormalAssetsArray)
      //   // console.log(AsCollateralISOAssetsArray)
      //   // lengthNormalAssetsCollateral
      //   // lengthISOAssetsCollateral
      //   // setLengthNormalAssetsCollateral(AsCollateralNormalAssetsArray.length > 0)
      //   // setLengthISOAssetsCollateral(AsCollateralISOAssetsArray.length > 0)
      //   // console.log('Normal list: ', lengthNormalAssetsCollateral)
      //   // console.log('ISO list: ', lengthISOAssetsCollateral)
      // }
    }
  }, [Lending, this__token, chainId, account])

  useEffect(() => {
    getStatesData();
    clearLocalStorage();

    let cur_source = deconstruction_wallet_source();
    console.log(cur_source);
    setWallet_source(cur_source);

    // Lending?.web3.eth.getChainId().then((chainID: any) => {
    //   console.log(chainID);
    //   setChainID(chainID);
    // });

    let refreshInterval = setInterval(getStatesData, 1000 * 10);
    return () => clearInterval(refreshInterval);
  }, [Lending, this__token, chainId, account]);



  useEffect(() => {
    const getDelayDetails = async () => {
      if (Lending && this__token && chainId && account) {
        const tokenAddress = Lending.contracts?.general['i' + this__token]?.address
        const provider = new ethers.providers.Web3Provider(Lending.web3.currentProvider)
        const controller_address = controller_json.networks[chainId].Controller.address
        const ld = new LendingDataV2(controller_address, provider)
        const delayStrategyConfig = await ld.getDelayStrategyConfig(account, tokenAddress)
        setDelayStrategyConfig(delayStrategyConfig)
        // console.log(delayStrategyConfig) // delayStrategyConfig
        // console.log(tokenAddress)
      }
    }

    getDelayDetails()
    let t = setInterval(getDelayDetails, 1000 * 30);
    return () => clearInterval(t);
  }, [Lending, this__token, chainId, account])

  useEffect(() => {
    if (withdraw_number__debounced && delayStrategyConfig && chainId && accountSupplyData) {
      const getDelayDetails = async () => {
        const provider = new ethers.providers.Web3Provider(Lending.web3.currentProvider)
        const controller_address = controller_json.networks[chainId].Controller.address
        const ld = new LendingDataV2(controller_address, provider)
        const amount = new BigNumber(withdraw_number__debounced)
          .multipliedBy(new BigNumber(10).pow(new BigNumber(accountSupplyData[6])))
          .toString()
        const delayDetails = await ld.getDelayDetails(BigNumberE.from(amount), delayStrategyConfig)
        // console.log(amount, delayDetails && delayDetails.delaySeconds.toString())
        setDelayTime(delayDetails.delaySeconds.toString())
      }
      getDelayDetails()
    }
  }, [withdraw_number__debounced, delayStrategyConfig, chainId, accountSupplyData && accountSupplyData[6]])

  const withdraw__pre = () => {
    console.log(delayTime)
    if (delayTime === undefined) { return console.log(delayTime, 'undefined') }

    if (new BigNumber(delayTime).gte(new BigNumber(0))) {
      if (new BigNumber(delayTime).gt(new BigNumber(0))) {
        setIsModalOpen(true)
      } else {
        if (isGasToken) {
          click_withdraw__spe()
        } else {
          click_withdraw()
        }
      }
    }
    // isGasToken ? click_withdraw__spe : click_withdraw
  }

  const handleDismiss_disable = () => {
    setIsModalOpen(false)
  }




  const clearLocalStorage = () => {
    let localdata: any = window.localStorage.getItem("my_notify");

    if (localdata) {
      console.log("*** removeItem---my_notify ***");
      window.localStorage.removeItem("my_notify");
    }
  };

  const supply_max = () => {
    if (!(accountSupplyData && supplyTokenData)) {
      return console.log("not ready: accountSupplyData && supplyTokenData");
    }
    // if (accountSupplyData[2] === "0") {
    //   return console.log("account MAX supply is 0");
    // }
    setIs_supply_max(true);
    setCurPercentage(100)

    if (isGasToken) {
      let tmp = Number(format_bn(accountSupplyData[2].toString(), accountSupplyData[6], 6)) - gasTokenReserve
      tmp = tmp > 0 ? Number(tmp.toFixed(6)) : 0
      setSupply_number(tmp);
    } else {
      setSupply_number(format_bn(accountSupplyData[2].toString(), accountSupplyData[6], 6));
    }
    console.log("supply_max");
  };
  const supply_input_change = (val: string) => {
    val = val.trim();
    var reg_ZH_CN = new RegExp("[\\u4E00-\\u9FFF]+", "g");
    var reg_ABC = /[a-z]/g;
    // 限制只可输入小数点后6位
    let quantityScale = "6";
    let reg_maxLength = new RegExp(
      "^(0|[1-9][0-9]*)(\\.[0-9]{0," + quantityScale + "})?$"
    );
    // 限制只可输入小数点后6位
    if (reg_ZH_CN.test(val)) {
      return console.log("不可以输入中文");
    }
    if (reg_ABC.test(val)) {
      return console.log("English ABC prohibited");
    }
    if (!(accountSupplyData && supplyTokenData)) {
      return console.log("not ready: accountSupplyData && supplyTokenData");
    }
    // if (accountSupplyData[2].toString() === "0") {
    //   return console.log("account MAX supply is 0");
    // }
    if (!reg_maxLength.test(val) && val !== "") {
      return console.log(
        "The maximum number of digits after the decimal point is exceeded"
      );
    }
    setIs_supply_max(false);
    console.log("supply_changed");
    setCurPercentage(0)

    let bool_gt;
    if (isGasToken) {
      bool_gt = new BigNumber(val)
        .multipliedBy(new BigNumber(10).pow(new BigNumber(accountSupplyData[6])))
        .gt(
          new BigNumber(accountSupplyData[2].toString())
            .minus(new BigNumber(gasTokenReserve).multipliedBy(new BigNumber(10).pow(new BigNumber(accountSupplyData[6]))))
        )
    } else {
      bool_gt = new BigNumber(val)
        .multipliedBy(new BigNumber(10).pow(new BigNumber(accountSupplyData[6])))
        .gt(new BigNumber(accountSupplyData[2].toString()))
    }
    if (bool_gt) {
      return supply_max();
      // return console.log('gte gte');
    }

    setSupply_number(val);
  };


  const withdraw_max = () => {
    if (!(accountSupplyData && supplyTokenData)) {
      return console.log("not ready: accountSupplyData && supplyTokenData");
    }
    // if (accountSupplyData[4] === "0") {
    //   return console.log("account safe available withdraw is 0");
    // }

    setIs_withdraw_max(true);
    setCurPercentage__tab2(100)

    setWithdraw_number(format_bn(accountSupplyData[4].toString(), accountSupplyData[6], 6));
    console.log("withdraw max");
  };
  const withdraw_input_change = (val: string) => {
    val = val.trim();
    var reg_ZH_CN = new RegExp("[\\u4E00-\\u9FFF]+", "g");
    var reg_ABC = /[a-z]/g;
    // 限制只可输入小数点后6位
    let quantityScale = "6";
    let reg_maxLength = new RegExp(
      "^(0|[1-9][0-9]*)(\\.[0-9]{0," + quantityScale + "})?$"
    );
    // 限制只可输入小数点后6位
    if (reg_ZH_CN.test(val)) {
      return console.log("不可以输入中文");
    }
    if (reg_ABC.test(val)) {
      return console.log("English ABC prohibited");
    }
    if (!(accountSupplyData && supplyTokenData)) {
      return console.log("not ready: accountSupplyData && supplyTokenData");
    }
    if (accountSupplyData[4].toString() === "0") {
      return console.log("account safe available withdraw is 0");
    }
    if (!reg_maxLength.test(val) && val !== "") {
      return console.log(
        "The maximum number of digits after the decimal point is exceeded"
      );
    }
    setIs_withdraw_max(false);
    console.log("withdraw_changed");
    setCurPercentage__tab2(0)

    let bool_gt = new BigNumber(val)
      .multipliedBy(new BigNumber(10).pow(new BigNumber(accountSupplyData[6])))
      .gt(new BigNumber(accountSupplyData[4].toString()));
    if (bool_gt) {
      return withdraw_max();
    }

    setWithdraw_number(val);
  };

  const changePercentage = (val: number) => {
    setCurPercentage(val)

    if (val === 100) {
      return supply_max();
    }

    setIs_supply_max(false);

    let temp_val;
    if (isGasToken) {
      temp_val = new BigNumber(accountSupplyData[2].toString())
        .minus(new BigNumber(gasTokenReserve).multipliedBy(new BigNumber(10).pow(new BigNumber(accountSupplyData[6]))))
        .multipliedBy(new BigNumber(val))
        .dividedBy(new BigNumber(100))
        .toFixed(0)
        .toString()
    } else {
      temp_val = new BigNumber(accountSupplyData[2].toString())
        .multipliedBy(new BigNumber(val))
        .dividedBy(new BigNumber(100))
        .toFixed(0)
        .toString()
    }

    let to_input = format_bn(temp_val, accountSupplyData[6], 6);
    setSupply_number(to_input);
  }


  const changePercentage__tab2 = (val: number) => {
    setCurPercentage__tab2(val)

    if (val === 100) {
      return withdraw_max();
    }

    setIs_withdraw_max(false);

    let temp_val = new BigNumber(accountSupplyData[4].toString())
      .multipliedBy(new BigNumber(val))
      .dividedBy(new BigNumber(100))
      .toFixed(0)
      .toString();

    let to_input = format_bn(temp_val, accountSupplyData[6], 6);
    setWithdraw_number(to_input);
  }


  const makeItAsCollateral = () => {
    console.log(accountSupplyInfo[1]);
    if (hasClickCollateral) {
      return console.log('has Click btn')
    }
    setHasClickCollateral(true);
    Lending.contracts?.general.Controller.methods[
      accountSupplyInfo[1] ? "exitMarkets" : "enterMarkets"
    ]([
      Lending.contracts?.general["i" + this__token]?.address,
    ])
      .send({ from: account })
      .on("transactionHash", function (hash: string) {
        console.log("transactionHash: ", hash);
        add_Notifies({
          state: "pedding",
          action: !accountSupplyInfo[1] ?
            "Use " + this__token + " As Collateral" : "Disable As Collateral",
          transactionHash: hash,
          time: new Date().getTime()
        }, setNowNotifies, account as string, chainId as number);
      })
      .on("receipt", function (receipt: any) {
        setHasClickCollateral(false);
        console.log("receipt: ", receipt);
        update_Notifies(receipt, setNowNotifies, account as string, chainId as number);
        setTimeout(() => {
          del_Notify(receipt, setNowNotifies);
        }, 3000);
      })
      .on("error", function (error: any) {
        console.log("error", error);
        setHasClickCollateral(false);
        setShow__cancel(true);
        setTimeout(() => {
          setShow__cancel(false);
        }, 3000);
      });
  }

  const renderCollateralDetails = () => {
    /**
     * 
     * *** ISO assert ***
     * 
     * **/
    if (accountSupplyInfo[4]) {
      if (accountSupplyInfo[1]) {
        if (!accountSupplyInfo[2]) {
          // s-token asC non-exe
          return (
            <StyledAs_collateral>
              <input
                type="checkbox"
                name="" id=""
                checked={true}
                data-tip
                data-for={"tips004"}
              />
              {'Enable '}{this__token}{' as collateral'}
              <ReactTooltip
                id={"tips004"}
                place="top"
                type="light"
                effect="float"
                className="tooltip"
                textColor={`${(props: any) => props.theme.operationPage.grey}`}
                borderColor={"#E8ECEF"}
                border={true}
              >
                <span>
                  {'Closing this collateral asset will render your position undercollateralized.'}
                </span>
              </ReactTooltip>
            </StyledAs_collateral>
          )
        } else {
          // s-token asC exe
          return (
            <StyledAs_collateral>
              {/* <img
                className={"img__AsCollateral img__AsCollateral__pointer"}
                src={img__on}
                alt=""
                onClick={() => { makeItAsCollateral() }}
              /> */}
              <input
                type="checkbox"
                name="" id="checkIIDD"
                checked={true}
                onClick={() => {
                  makeItAsCollateral()
                }}
              />
              {'Enable '}{this__token}{' as collateral'}
            </StyledAs_collateral>
          )
        }
      } else {
        if (!accountSupplyInfo[2]) {
          // s-token non-asC non-exe
          return (
            <StyledAs_collateral>
              {/* <>🔴</> */}
              <input
                type="checkbox"
                name="" id="checkIIDD"
                checked={false}
                data-tip
                data-for={"tips001"}
              />
              {'Enable '}{this__token}{' as collateral'}
              <ReactTooltip
                id={"tips001"}
                place="top"
                type="light"
                effect="float"
                className="tooltip"
                textColor={`${(props: any) => props.theme.operationPage.grey}`}
                borderColor={"#E8ECEF"}
                border={true}
              >
                <span>
                  {'When enabling Segregated asset as collateral, other assets are not allowed to be used as collateral.'}
                </span>
              </ReactTooltip>
            </StyledAs_collateral>
          )
        } else {
          // s-token non-asC exe
          return (
            <StyledAs_collateral>
              {/* <img
                className={"img__AsCollateral img__AsCollateral__pointer"}
                src={img__off}
                alt=""
                onClick={() => { makeItAsCollateral() }}
              /> */}
              <input
                type="checkbox"
                name="" id="checkIIDD"
                checked={isUserSelected__ISO}
                onChange={(e) => { checkBoxChange__ISO(e.target.checked) }}
              />
              {'Enable '}{this__token}{' as collateral'}
            </StyledAs_collateral>
          )
        }
      }
    }
    /**
     * 
     * *** Normal assert ***
     * 
     * **/
    else {
      // asCollateral - true  Normal 已经作为抵押物 点击可以关闭(需要判断)
      if (accountSupplyInfo[1]) {
        if (!accountSupplyInfo[2]) {
          // n-token asC non-exe
          return (
            <StyledAs_collateral>
              <input
                type="checkbox"
                name="" id=""
                checked={true}
                data-tip
                data-for={"tips003"}
              />
              {'Enable '}{this__token}{' as collateral'}
              <ReactTooltip
                id={"tips003"}
                place="top"
                type="light"
                effect="float"
                className="tooltip"
                textColor={`${(props: any) => props.theme.operationPage.grey}`}
                borderColor={"#E8ECEF"}
                border={true}
              >
                <span>
                  {'Closing this collateral asset will render your position undercollateralized.'}
                </span>
              </ReactTooltip>
            </StyledAs_collateral>
          )
        } else {
          // n-token asC exe
          return (
            <StyledAs_collateral>
              {/* <img
                className={"img__AsCollateral img__AsCollateral__pointer"}
                src={img__on}
                alt=""
                onClick={() => { makeItAsCollateral() }}
              /> */}
              <input
                type="checkbox"
                name="" id="checkIIDD"
                checked={isUserSelected}
                onClick={() => {
                  makeItAsCollateral()
                }}
              />
              {'Enable '}{this__token}{' as collateral'}
            </StyledAs_collateral>
          )
        }
      } else {
        // ISO作为抵押物 不能激活作为抵押物
        if (!accountSupplyInfo[2]) {
          // n-token non-asC non-exe
          return (
            <StyledAs_collateral>
              {/* <>🔴</> */}
              <input
                type="checkbox"
                name="" id=""
                checked={false}
                data-tip
                data-for={"tips002"}
              />
              {'Enable '}{this__token}{' as collateral'}
              <ReactTooltip
                id={"tips002"}
                place="top"
                type="light"
                effect="float"
                className="tooltip"
                textColor={`${(props: any) => props.theme.operationPage.grey}`}
                borderColor={"#E8ECEF"}
                border={true}
              >
                <span>
                  {'When Segragated asset is used as collateral, other assets are not allowed to be used as collateral.'}
                </span>
              </ReactTooltip>
            </StyledAs_collateral>
          )
        } else {
          // n-token asC exe
          return (
            <StyledAs_collateral>
              {/* <img
                className={"img__AsCollateral img__AsCollateral__pointer"}
                src={img__off}
                alt=""
                onClick={() => { makeItAsCollateral() }}
              /> */}
              <input
                type="checkbox"
                name="" id="checkIIDD"
                checked={isUserSelected}
                onChange={(e) => { checkBoxChange(e.target.checked) }}
              />
              {/* <label htmlFor='checkIIDD'> */}
              {'Enable '}{this__token}{' as collateral'}
              {/* </label> */}
            </StyledAs_collateral>
          )
        }
      }
    }
  }



  useEffect(() => {
    if (this__action.toLowerCase() === "supply") {
      setIsChoosen__supply__tab(true);
    } else if (this__action.toLowerCase() === "withdraw") {
      setIsChoosen__supply__tab(false);
    }
    console.log(ParamsArr, isChoosen__supply__tab);
  }, []);

  const click_supply__spe = async () => {
    if (!Number(supply_number)) {
      if (!(is_supply_max && new BigNumber(accountSupplyData[2].toString()).gt(new BigNumber(0)))) {
        return console.log("supply_number undefined")
      }
    }
    // if (!supply_number) {
    //   return console.log("supply_number undefined");
    // }
    // if (new BigNumber(supply_number).eq(new BigNumber(0))) {
    //   return console.log("supply_number is 0...");
    // }
    if (bool_Btn) {
      return console.log("already supplying...");
    }
    setBool_Btn(true);
    let to_bn__value = is_supply_max
      ? new BigNumber(accountSupplyData[2].toString())
        .minus(new BigNumber(gasTokenReserve).multipliedBy(new BigNumber(10).pow(new BigNumber(accountSupplyData[6]))))
        .toString()
      : new BigNumber(supply_number).multipliedBy(new BigNumber(10).pow(new BigNumber(accountSupplyData[6]))).toString();
    // return console.log(supply_number, to_bn__value.toString(), is_supply_max)

    let callMethod = 'mint'
    if (
      isUserSelected && // User Selected (default: true)
      !accountSupplyInfo[4] && // normal assert
      !accountSupplyInfo[1]  // non as Collateral
      && accountSupplyInfo[2] // can exe.
      // !lengthISOAssetsCollateral // ISO list = 0
      ||
      isUserSelected__ISO
    ) {
      // console.log(isUserSelected, !accountSupplyInfo[4], !accountSupplyInfo[1])
      callMethod = 'mintForSelfAndEnterMarket'
      // callMethod = 'mint'
    }

    // // ISO asset
    // if (accountSupplyInfo[4]) {
    //   if (isUserSelected__ISO) {
    //     callMethod = 'mintForSelfAndEnterMarket'
    //   }
    // }else{

    // }


    console.log(callMethod)
    // const callData = callMethod === 'mintForSelfAndEnterMarket' ? [to_bn__value] : [account, to_bn__value]
    // console.log(Lending.contracts?.general[this__token])
    const iTokenContracts = new Lending.web3.eth.Contract(iETHAbi.abi, Lending.contracts?.general[this__token]?.address)
    // console.log(iTokenContracts)
    // Lending.contracts?.general[this__token]
    // .methods.mint(account)
    // iTokenContracts.methods[callMethod](...callData)

    const callData = callMethod === 'mintForSelfAndEnterMarket' ? [] : [account]
    iTokenContracts.methods[callMethod](...callData)
      .send({ from: account, value: to_bn__value })
      .on("transactionHash", function (hash: string) {
        console.log("transactionHash: ", hash);
        postDataToServer(
          wallet_source,
          "Supply",
          account || "",
          (chainId && network_Name[chainId]) || "",
          Lending.contracts?.general[this__token]?.address,
          accountSupplyData[6],
          supplyTokenData[2],
          to_bn__value
        );
        supply_input_change("");
        add_Notifies({
          state: "pedding",
          action: "Supply " + format_num_to_K(format_bn(to_bn__value, accountSupplyData[6], 4)) + " " + this__token,
          transactionHash: hash,
          time: new Date().getTime()
        }, setNowNotifies, account as string, chainId as number);
        setBool_Btn(false);
      })
      .on("receipt", function (receipt: any) {
        console.log("receipt: ", receipt);
        getStatesData();
        update_Notifies(receipt, setNowNotifies, account as string, chainId as number);
        setTimeout(async () => {
          await del_Notify(receipt, setNowNotifies);
        }, 3000);
        setBool_Btn(false);
      })
      .on("error", function (error: any) {
        console.log("error111111", error);
        setBool_Btn(false);
        setShow__cancel(true);
        setTimeout(() => {
          setShow__cancel(false);
        }, 3000);
      });
  };
  const click_supply = async () => {
    if (!Number(supply_number)) {
      if (!(is_supply_max && new BigNumber(accountSupplyData[2].toString()).gt(new BigNumber(0)))) {
        return console.log("supply_number undefined")
      }
    }
    // if (!supply_number) {
    //   return console.log("supply_number undefined");
    // }
    // if (new BigNumber(supply_number).eq(new BigNumber(0))) {
    //   return console.log("supply_number is 0...");
    // }
    if (bool_Btn) {
      return console.log("already supplying...");
    }
    setBool_Btn(true);
    let to_bn__value = is_supply_max
      ? accountSupplyData[2].toString()
      : new BigNumber(supply_number)
        .multipliedBy(new BigNumber(10).pow(new BigNumber(accountSupplyData[6])))
        .toString();
    // return console.log(supply_number, to_bn__value.toString(), is_supply_max)
    // mintForSelfAndEnterMarket
    // console.log(lengthNormalAssetsCollateral
    //   , lengthISOAssetsCollateral)
    let callMethod = 'mint'
    if (
      isUserSelected && // User Selected (default: true)
      !accountSupplyInfo[4] && // normal assert
      !accountSupplyInfo[1]  // non as Collateral
      // !lengthISOAssetsCollateral // ISO list = 0
      && accountSupplyInfo[2] // can exe.
      ||
      isUserSelected__ISO
    ) {
      callMethod = 'mintForSelfAndEnterMarket'
    }
    console.log(callMethod)
    const callData = callMethod === 'mintForSelfAndEnterMarket' ? [to_bn__value] : [account, to_bn__value]
    Lending.contracts?.general["i" + [this__token]].methods[callMethod](...callData)
      // .mint(account, to_bn__value)
      .send({ from: account })
      .on("transactionHash", function (hash: string) {
        console.log("transactionHash: ", hash);
        postDataToServer(
          wallet_source,
          "Supply",
          account || "",
          (chainId && network_Name[chainId]) || "",
          Lending.contracts?.general[this__token]?.address,
          accountSupplyData[6],
          supplyTokenData[2],
          to_bn__value
        );
        supply_input_change("");
        add_Notifies({
          state: "pedding",
          action: "Supply " + format_num_to_K(format_bn(to_bn__value, accountSupplyData[6], 4)) + " " + this__token,
          transactionHash: hash,
          time: new Date().getTime()
        }, setNowNotifies, account as string, chainId as number);
        setBool_Btn(false);
      })
      .on("receipt", function (receipt: any) {
        console.log("receipt: ", receipt);
        getStatesData();
        update_Notifies(receipt, setNowNotifies, account as string, chainId as number);
        setTimeout(async () => {
          await del_Notify(receipt, setNowNotifies);
        }, 3000);
        setBool_Btn(false);
      })
      .on("error", function (error: any) {
        console.log("error", error);
        setBool_Btn(false);
        setShow__cancel(true);
        setTimeout(() => {
          setShow__cancel(false);
        }, 3000);
      });
  };
  const click_withdraw = () => {
    if (!Number(withdraw_number)) {
      if (!(is_withdraw_max && new BigNumber(accountSupplyData[4].toString()).gt(new BigNumber(0)))) {
        return console.log("withdraw_number undefined")
      }
    }
    // if (!withdraw_number) {
    //   return console.log("withdraw_number undefined");
    // }
    // if (new BigNumber(withdraw_number).eq(new BigNumber(0))) {
    //   return console.log("withdraw_number is 0...");
    // }
    // if (new BigNumber(accountSupplyData[4]).eq(new BigNumber(0))) {
    //   return console.log("Available SAFE to Withdraw is 0...");
    // }
    if (bool_Btn) {
      return console.log("already withdraw...");
    }
    setBool_Btn(true);
    let to_bn__value = new BigNumber(withdraw_number).multipliedBy(
      new BigNumber(10).pow(new BigNumber(accountSupplyData[6]))
    );
    let is_equal =
      accountSupplyData[0].toString() === accountSupplyData[3].toString() &&
      accountSupplyData[0].toString() === accountSupplyData[4].toString() &&
      accountSupplyData[3].toString() === accountSupplyData[4].toString();

    console.log(to_bn__value.toString(), is_equal);
    // return

    Lending.contracts?.general["i" + [this__token]].methods[
      is_withdraw_max ?
        is_equal ? "redeem" : "redeemUnderlying"
        : "redeemUnderlying"
    ](
      account,
      is_withdraw_max ?
        is_equal ? accountSupplyData[5].toString() : accountSupplyData[4].toString()
        : to_bn__value.toString()
    )
      .send({ from: account })
      .on("transactionHash", function (hash: string) {
        console.log("transactionHash: ", hash);
        postDataToServer(
          wallet_source,
          "Withdraw",
          account || "",
          (chainId && network_Name[chainId]) || "",
          Lending.contracts?.general[this__token]?.address,
          accountSupplyData[6],
          supplyTokenData[2],
          is_withdraw_max ?
            is_equal ? accountSupplyData[5] : accountSupplyData[4]
            : to_bn__value.toString()
        );
        withdraw_input_change("");
        add_Notifies({
          state: "pedding",
          action: "Withdraw " + format_num_to_K(format_bn(to_bn__value.toString(), accountSupplyData[6], 4)) + " " + this__token,
          transactionHash: hash,
          time: new Date().getTime()
        }, setNowNotifies, account as string, chainId as number);
        setBool_Btn(false);
        setIsModalOpen(false);
      })
      .on("receipt", function (receipt: any) {
        console.log("receipt: ", receipt);
        getStatesData();
        update_Notifies(receipt, setNowNotifies, account as string, chainId as number);
        setTimeout(async () => {
          await del_Notify(receipt, setNowNotifies);
        }, 3000);
        setBool_Btn(false);
      })
      .on("error", function (error: any) {
        console.log("error", error);
        setBool_Btn(false);
        setShow__cancel(true);
        setTimeout(() => {
          setShow__cancel(false);
        }, 3000);
      });
  };
  const click_withdraw__spe = () => {
    if (!Number(withdraw_number)) {
      if (!(is_withdraw_max && new BigNumber(accountSupplyData[4].toString()).gt(new BigNumber(0)))) {
        return console.log("withdraw_number undefined")
      }
    }
    // if (new BigNumber(withdraw_number).eq(new BigNumber(0))) {
    //   return console.log("withdraw_number is 0...");
    // }
    // if (new BigNumber(accountSupplyData[4]).eq(new BigNumber(0))) {
    //   return console.log("Available SAFE to Withdraw is 0...");
    // }
    if (bool_Btn) {
      return console.log("already withdraw...");
    }
    setBool_Btn(true);
    let to_bn__value = new BigNumber(withdraw_number).multipliedBy(
      new BigNumber(10).pow(new BigNumber(accountSupplyData[6]))
    );
    let is_equal =
      accountSupplyData[0].toString() === accountSupplyData[3].toString() &&
      accountSupplyData[0].toString() === accountSupplyData[4].toString() &&
      accountSupplyData[3].toString() === accountSupplyData[4].toString();

    console.log(to_bn__value.toString(), is_equal);
    // return

    Lending.contracts?.general[this__token].methods[
      is_withdraw_max ?
        is_equal ? "redeem" : "redeemUnderlying"
        : "redeemUnderlying"
    ](
      account,
      is_withdraw_max ?
        is_equal ? accountSupplyData[5].toString() : accountSupplyData[4].toString()
        : to_bn__value.toString()
    )
      .send({ from: account, })
      .on("transactionHash", function (hash: string) {
        console.log("transactionHash: ", hash);
        postDataToServer(
          wallet_source,
          "Withdraw",
          account || "",
          (chainId && network_Name[chainId]) || "",
          Lending.contracts?.general[this__token]?.address,
          accountSupplyData[6],
          supplyTokenData[2],
          is_withdraw_max ?
            is_equal ? accountSupplyData[5] : accountSupplyData[4]
            : to_bn__value.toString()
        );
        withdraw_input_change("");
        add_Notifies({
          state: "pedding",
          action: "Withdraw " + format_num_to_K(format_bn(to_bn__value.toString(), accountSupplyData[6], 4)) + " " + this__token,
          transactionHash: hash,
          time: new Date().getTime()
        }, setNowNotifies, account as string, chainId as number);
        setBool_Btn(false);
        setIsModalOpen(false);
      })
      .on("receipt", function (receipt: any) {
        console.log("receipt: ", receipt);
        getStatesData();
        update_Notifies(receipt, setNowNotifies, account as string, chainId as number);
        setTimeout(async () => {
          await del_Notify(receipt, setNowNotifies);
        }, 3000);
        setBool_Btn(false);
      })
      .on("error", function (error: any) {
        console.log("error", error);
        setBool_Btn(false);
        setShow__cancel(true);
        setTimeout(() => {
          setShow__cancel(false);
        }, 3000);
      });
  };

  const click_approve_pre = () => {
    const curTokenAddress = Lending.contracts?.general[this__token]?.address
    if (DFApproveXArray.includes(curTokenAddress)) {
      click_approve(true)
    } else {
      click_approve()
    }
  }
  const click_approve = (reApprove?: boolean) => {
    if (bool_Btn__enable) {
      return console.log("already approving...");
    }
    setBool_Btn__enable(true);

    Lending.contracts?.general[this__token]?.methods
      .approve(
        Lending?.contracts?.general["i" + this__token]?.address,
        reApprove ? '0' : ethers.constants.MaxUint256
      )
      .send({ from: account })
      .on("transactionHash", function (hash: string) {
        console.log("transactionHash: ", hash);
        setEnable_status(fmt({ id: "ENABLING" }));
        add_Notifies({
          state: "pedding",
          action: "Enable " + this__token,
          transactionHash: hash,
          time: new Date().getTime()
        }, setNowNotifies, account as string, chainId as number);
      })
      .on("receipt", function (receipt: any) {
        console.log("receipt: ", receipt);
        getStatesData();
        update_Notifies(receipt, setNowNotifies, account as string, chainId as number);
        setTimeout(() => {
          del_Notify(receipt, setNowNotifies);
          if (reApprove) {
            click_approve()
          }
        }, 3000);
        setBool_Btn__enable(false)
        setEnable_status(fmt({ id: "ENABLE" }))
      })
      .on("error", function (error: any) {
        console.log("error", error);
        setBool_Btn__enable(false);
        setEnable_status(fmt({ id: "ENABLE" }));
        setShow__cancel(true);
        setTimeout(() => {
          setShow__cancel(false);
        }, 3000);
      });
  };

  return (
    <>
      <UnlockWalletModal
        isOpen={unlockModalIsOpen}
        onDismiss={handleDismissUnlockModal}
        metamaskActive={metamaskActive}
        walletConnectActive={walletConnectActive}
      />
      <Notification
        chainID={chainId}
        notifies={nowNotifies}
        setNowNotifies={setNowNotifies}
        del_Notify={del_Notify}
      />
      <Notification__cancel
        notifies={show__cancel}
        setShow__cancel={setShow__cancel}
        del_Notify={del__cancel}
      />


      <FlexBoxWrap>
        <Crumb>
          <span>
            <RouterLink to={`/lending`}>
              <BackToHome>{fmt({ id: "Home" })}</BackToHome>
            </RouterLink>
          </span>
        </Crumb>

        <BorderWrap>
          <StyledLeftSection>
            <NoteTips>{fmt({ id: "supply_notes" })}</NoteTips>

            <PaddingWrap>
              <Logo_Token_number
                tab={"supply"}
                token={this__token}
                myWallet={account}
                isLido={Lending?.contracts?.general?.[`i${this__token}`]?.tokenType}
                value={
                  accountSupplyData
                    ? new BigNumber(accountSupplyData[0]).gt(new BigNumber(0)) &&
                      new BigNumber(accountSupplyData[0]).div(new BigNumber(10).pow(new BigNumber(accountSupplyData[6]))).lt(new BigNumber(0.01))
                      ? format_bn(accountSupplyData[0].toString(), accountSupplyData[6])
                      : format_num_to_K(format_bn(accountSupplyData[0].toString(), accountSupplyData[6], 2))
                    : ""
                }
              />

              <ActionTab>
                <StyledTab onClick={() => { setIsChoosen__supply__tab(true) }} className={isChoosen__supply__tab ? 'isChoosen' : ''}>
                  {fmt({ id: "SUPPLY" })}
                </StyledTab>
                <StyledTab onClick={() => { setIsChoosen__supply__tab(false) }} className={isChoosen__supply__tab ? '' : 'isChoosen'}>
                  {fmt({ id: "WITHDRAW" })}
                </StyledTab>
              </ActionTab>

              {
                !local_account ?
                  // 没有关联过 wallet (直接显示 链接钱包)
                  <>
                    <StyledApproveFirst>
                      {fmt({ id: "connect_wallet_first" })}
                    </StyledApproveFirst>
                    <StyledSupply_btn onClick={handleUnlockWalletClick}>
                      {fmt({ id: "Connect_Wallet" })}
                    </StyledSupply_btn>
                  </>
                  :
                  // 已经关联过 wallet
                  <>
                    {
                      !(supplyTokenData && accountSupplyData && accountSupplyInfo && accountTotalValue) ?
                        <LogoImgWrap>
                          <LogoImg src={dForceLogo}></LogoImg>
                        </LogoImgWrap>
                        :
                        <>
                          {
                            isChoosen__supply__tab ?
                              <>
                                {
                                  // mint close ?
                                  marketsInfo && marketsInfo[4] === true
                                    // true
                                    ? <>
                                      <Token_Number
                                        tab={"supply"}
                                        token={this__token}
                                        myWallet={"account-0x"}
                                        value={accountSupplyData ? format_num_to_K(format_bn(accountSupplyData[1].toString(), accountSupplyData[6], 2)) : ""}
                                      />
                                      <StyledYouDid>
                                        {fmt({ id: "markets_closed_1__Mintings" })}
                                        {fmt({ id: "markets_closed_1" })}
                                      </StyledYouDid>
                                      <StyledSupply_btn className='disabledBtn'>
                                        {fmt({ id: "SUPPLY" })}
                                      </StyledSupply_btn>
                                    </>
                                    : accountBorrowData &&
                                      (new BigNumber(accountBorrowData[0]).gt(new BigNumber(0))) ||
                                      (new BigNumber(accountBorrowData__mint && accountBorrowData__mint[0]).gt(new BigNumber(0))) ?
                                      // you have borrowed tokens
                                      <>
                                        <Token_Number
                                          tab={"supply"}
                                          token={this__token}
                                          myWallet={"account-0x"}
                                          value={accountSupplyData ? format_num_to_K(format_bn(accountSupplyData[1].toString(), accountSupplyData[6], 2)) : ""}
                                        />
                                        <StyledYouDid>
                                          {fmt({ id: "borrowed_tokens" })}
                                        </StyledYouDid>
                                        <StyledSupply_btn className='disabledBtn'>
                                          {fmt({ id: "SUPPLY" })}
                                        </StyledSupply_btn>
                                      </>
                                      :
                                      <>
                                        {
                                          // allowance?.toNumber() === 0 && !isGasToken ?
                                          //   // enable first
                                          //   <>
                                          //     <StyledApproveFirst>
                                          //       {fmt({ id: "enable_first__supply" }, { token: this__token })}
                                          //     </StyledApproveFirst>
                                          //     <StyledSupply_btn
                                          //       className={bool_Btn__enable ? "disabledBtn" : ""}
                                          //       onClick={() => { click_approve() }}>
                                          //       {enable_status}
                                          //     </StyledSupply_btn>
                                          //   </>
                                          //   :
                                          // not need enable
                                          <>
                                            <Token_Number
                                              tab={"supply"}
                                              token={this__token}
                                              myWallet={"account-0x"}
                                              value={accountSupplyData ? format_num_to_K(format_bn(accountSupplyData[1].toString(), accountSupplyData[6], 2)) : ""}
                                            />

                                            <InputWrap>
                                              <Styledinput
                                                placeholder={fmt({ id: "Amount" })}
                                                onChange={(e) => { supply_input_change(e.target.value) }}
                                                value={supply_number || ""}
                                              />
                                              <SpanMax onClick={supply_max}>
                                                {fmt({ id: "MAX" })}
                                              </SpanMax>
                                            </InputWrap>

                                            <RangeWrap>
                                              <StyledPercentage>
                                                <PercentageItem
                                                  onClick={() => { changePercentage(100) }}
                                                  className={curPercentage === 100 ? 'isPercentageChoosen' : ''}>
                                                  100%</PercentageItem>
                                                <PercentageItem
                                                  onClick={() => { changePercentage(75) }}
                                                  className={curPercentage === 75 ? 'isPercentageChoosen' : ''}>
                                                  75%</PercentageItem>
                                                <PercentageItem
                                                  onClick={() => { changePercentage(50) }}
                                                  className={curPercentage === 50 ? 'isPercentageChoosen' : ''}>
                                                  50%</PercentageItem>
                                                <PercentageItem
                                                  onClick={() => { changePercentage(25) }}
                                                  className={curPercentage === 25 ? 'isPercentageChoosen' : ''}>
                                                  25%</PercentageItem>
                                              </StyledPercentage>

                                              {
                                                !((allowance?.toNumber() === 0 && !isGasToken) ||
                                                  (!isGasToken && allowance &&
                                                    new BigNumber(supply_number)
                                                      .multipliedBy(new BigNumber(10).pow(new BigNumber(accountSupplyData[6])))
                                                      .gt(new BigNumber(allowance)))) &&
                                                renderCollateralDetails()
                                              }
                                              {/* <div>{'Normal list: ' + lengthNormalAssetsCollateral}</div> */}
                                              {/* <div>{'ISO list: ' + lengthISOAssetsCollateral}</div> */}


                                              {
                                                accountSupplyData &&
                                                new BigNumber(accountSupplyData[2]).lt(new BigNumber(accountSupplyData[1])) &&
                                                <OverSafeMAX>
                                                  {fmt(
                                                    { id: "withdraw__warning" },
                                                    {
                                                      params:
                                                        format_num_to_K(format_bn(accountSupplyData[2].toString(), accountSupplyData[6], 2)) +
                                                        (intl_locale === "cn" ? "" : " ") +
                                                        this__token,
                                                    }
                                                  )}
                                                </OverSafeMAX>
                                              }
                                            </RangeWrap>

                                            {
                                              allowance?.toNumber() === 0 &&
                                                !isGasToken
                                                ? <>
                                                  <StyledSupply_btn
                                                    className={bool_Btn__enable ? "disabledBtn" : ""}
                                                    onClick={() => { click_approve() }}>
                                                    {enable_status}
                                                  </StyledSupply_btn>
                                                </>
                                                : !isGasToken && allowance &&
                                                  new BigNumber(supply_number)
                                                    .multipliedBy(new BigNumber(10).pow(new BigNumber(accountSupplyData[6])))
                                                    .gt(new BigNumber(allowance))
                                                  ? <StyledSupply_btn className={bool_Btn__enable ? "disabledBtn" : ""} onClick={click_approve_pre}>
                                                    {enable_status}
                                                  </StyledSupply_btn>
                                                  : <StyledSupply_btn
                                                    className={
                                                      (accountBorrowData && (new BigNumber(accountBorrowData[0]).gt(new BigNumber(0)))) ||
                                                        (new BigNumber(accountBorrowData__mint && accountBorrowData__mint[0]).gt(new BigNumber(0))) ||
                                                        bool_Btn ? "disabledBtn" : ""
                                                    }
                                                    onClick={isGasToken ? click_supply__spe : click_supply}
                                                  >
                                                    {fmt({ id: "SUPPLY" })}
                                                  </StyledSupply_btn>
                                            }
                                          </>
                                        }
                                      </>
                                }
                              </>
                              :
                              <>
                                <Token_Number
                                  tab={"withdraw"}
                                  token={this__token}
                                  myWallet={"account-0x"}
                                  value={
                                    accountSupplyData ?
                                      new BigNumber(accountSupplyData[4].toString()).gt(new BigNumber(0)) &&
                                        new BigNumber(accountSupplyData[4].toString()).div(new BigNumber(10).pow(new BigNumber(accountSupplyData[6]))).lt(new BigNumber(0.01)) ?
                                        format_num_to_K(format_bn(accountSupplyData[4].toString(), accountSupplyData[6]))
                                        :
                                        format_num_to_K(format_bn(accountSupplyData[4].toString(), accountSupplyData[6], 2))
                                      :
                                      ""
                                  }
                                />

                                {
                                  // redeem close ?
                                  marketsInfo && marketsInfo[5] === true
                                    ? <StyledYouDid>
                                      {fmt({ id: "markets_closed_1__Reedem_collaterals" })}
                                      {fmt({ id: "markets_closed_1" })}
                                    </StyledYouDid>
                                    : (accountSupplyInfo && accountSupplyInfo[3] === false) ?
                                      <StyledYouDid>
                                        {fmt({ id: "markets_closed" })}
                                      </StyledYouDid>
                                      :
                                      <>
                                        <InputWrap>
                                          <Styledinput
                                            placeholder={fmt({ id: "Amount" })}
                                            onChange={(e) => { withdraw_input_change(e.target.value) }}
                                            value={withdraw_number || ""}
                                          />
                                          <SpanMax onClick={withdraw_max}>
                                            {fmt({ id: "safe_max" })}
                                          </SpanMax>
                                        </InputWrap>

                                        <RangeWrap>
                                          <StyledPercentage>
                                            <PercentageItem
                                              onClick={() => { changePercentage__tab2(100) }}
                                              className={curPercentage__tab2 === 100 ? 'isPercentageChoosen' : ''}>
                                              100%</PercentageItem>
                                            <PercentageItem
                                              onClick={() => { changePercentage__tab2(75) }}
                                              className={curPercentage__tab2 === 75 ? 'isPercentageChoosen' : ''}>
                                              75%</PercentageItem>
                                            <PercentageItem
                                              onClick={() => { changePercentage__tab2(50) }}
                                              className={curPercentage__tab2 === 50 ? 'isPercentageChoosen' : ''}>
                                              50%</PercentageItem>
                                            <PercentageItem
                                              onClick={() => { changePercentage__tab2(25) }}
                                              className={curPercentage__tab2 === 25 ? 'isPercentageChoosen' : ''}>
                                              25%</PercentageItem>
                                          </StyledPercentage>
                                        </RangeWrap>
                                      </>
                                }

                                <StyledSupply_btn
                                  className={bool_Btn || (marketsInfo && marketsInfo[5]) ? "disabledBtn" : ""}
                                  // onClick={isGasToken ? click_withdraw__spe : click_withdraw}
                                  onClick={withdraw__pre}
                                >
                                  {fmt({ id: "WITHDRAW" })}
                                </StyledSupply_btn>
                              </>
                          }
                        </>
                    }
                  </>
              }
            </PaddingWrap>
          </StyledLeftSection>

          <StyleRightSection>
            <ChangeableValueToBe
              i18n_text={"Supply_Balance"}
              value={accountTotalValue ? "$" + format_num_to_K(format_bn(accountTotalValue[0].toString(), 18, 2)) : ""}
              tobe={
                supply_number__debounced && supplyTokenData && accountTotalValue
                  ? "$" + format_num_to_K(
                    format_bn(
                      new BigNumber(supply_number__debounced)
                        .multipliedBy(new BigNumber(supplyTokenData[2].toString()))
                        .plus(new BigNumber(accountTotalValue[0].toString()))
                        .toFixed(0), //.toString()
                      18,
                      2
                    )
                  )
                  : ''
              }
              tobe__tab2={
                withdraw_number__debounced && supplyTokenData && accountTotalValue
                  ? "$" + format_num_to_K(
                    format_bn(
                      new BigNumber(accountTotalValue[0].toString())
                        .minus(
                          new BigNumber(withdraw_number__debounced)
                            .multipliedBy(new BigNumber(supplyTokenData[2].toString()))
                        ).toFixed(0),
                      18,
                      2
                    )
                  )
                  : ''
              }
              isRed_right={true}
              isChoosen__supply__tab={isChoosen__supply__tab}
            />
            {/* <div>
            {accountTotalValue && accountTotalValue[0]} 
          </div>
          <div>
            {withdraw_number && withdraw_number} 
          </div>
          <div>
            {supplyTokenData && supplyTokenData[2]}
          </div> */}
            {/* <div>
            {accountTotalValue && withdraw_number && supplyTokenData &&
              (accountTotalValue[0] - withdraw_number * supplyTokenData[2])
            }
          </div> */}
            {/* <div>
            {accountTotalValue && withdraw_number && supplyTokenData &&
              format_bn((accountTotalValue[0] - withdraw_number * supplyTokenData[2]).toString(), 18)
            }
          </div>
          <div>
            {accountTotalValue && withdraw_number && supplyTokenData &&
              format_num_to_K(
                format_bn(
                  new BigNumber(accountTotalValue[0])
                    .minus(
                      new BigNumber(withdraw_number)
                        // .multipliedBy(new BigNumber(supplyTokenData[2]))
                      .multipliedBy(new BigNumber('999948773648852992'))
                      .toFixed(0)
                      // .multipliedBy(new BigNumber('1000000000000000000'))
                    ).toString(),
                  18,
                  2)
              )
            }
          </div> */}
            <ChangeableValueToBe
              i18n_text={"Health_Factor"}
              showIcon={fmt({ id: "Health_Factor_tips" })}
              value={
                accountTotalValue ?
                  accountTotalValue[2].toString() !== "0" ?
                    new BigNumber(accountTotalValue[3].toString()).gt(new BigNumber(10).pow(new BigNumber(22))) ?
                      ">10,000" : format_num_to_K(format_bn(accountTotalValue[3].toString(), 18, 2))
                    : ""
                  : ""
              }
              tobe={
                supply_number__debounced && healthRatio
                  ? accountTotalValue[2].toString() === "0"
                    ? '0.00'
                    : accountSupplyInfo && accountSupplyInfo[1] === false && accountTotalValue
                      ? new BigNumber(accountTotalValue[3].toString()).gt(new BigNumber(10).pow(new BigNumber(22)))
                        ? ">10,000" : format_num_to_K(format_bn(accountTotalValue[3].toString(), 18, 2))
                      : new BigNumber(healthRatio).gt(new BigNumber(10).pow(new BigNumber(22)))
                        ? ">10,000" : format_num_to_K(format_bn(healthRatio, 18, 2))
                  : ''
              }
              tobe__tab2={
                withdraw_number__debounced && healthRatio_tab2
                  ? accountTotalValue[2].toString() === "0"
                    ? '0.00'
                    : accountSupplyInfo && accountSupplyInfo[1] === false && accountTotalValue
                      ? new BigNumber(accountTotalValue[3].toString()).gt(new BigNumber(10).pow(new BigNumber(22)))
                        ? ">10,000" : format_num_to_K(format_bn(accountTotalValue[3].toString(), 18, 2))
                      : new BigNumber(healthRatio_tab2).gt(new BigNumber(10).pow(new BigNumber(22)))
                        ? ">10,000" : format_num_to_K(format_bn(healthRatio_tab2, 18, 2))
                  : ''
              }
              isRed_right={true}
              isChoosen__supply__tab={isChoosen__supply__tab}
            />

            <ChangeableValueToBe
              i18n_text={"Borrowing_Power"}
              value={
                accountEquity
                  ? accountEquity[0].toString() === "0"
                    ? '0.00'
                    : '$' + format_num_to_K(format_bn(accountEquity[0].toString(), 18, 2))
                  : ""
              }
              tobe={
                supply_number__debounced && toBePower && healthRatio
                  ? new BigNumber(healthRatio).lte(new BigNumber(1e18))
                    ? '0.00'
                    : '$' + format_num_to_K(format_bn(toBePower, 18, 2))
                  : ''
              }
              tobe__tab2={
                withdraw_number__debounced && toBePower_tab2
                  ? '$' + format_num_to_K(format_bn(toBePower_tab2, 18, 2))
                  : ''
              }
              isRed_right={true}
              isChoosen__supply__tab={isChoosen__supply__tab}
              isPower={true}
            />

            <ChangeableValueToBe
              i18n_text={"supply_apy"}
              value={supplyTokenData ? format_num_to_K(format_bn(supplyTokenData[0].toString(), 16, 2)) + "%" : ""}
              tobe={
                supply_number__debounced && toBeAPY
                  ? format_num_to_K(format_bn(toBeAPY, 16, 2)) + "%"
                  : ''
              }
              tobe__tab2={
                withdraw_number__debounced && toBeAPY_tab2
                  ? format_num_to_K(format_bn(toBeAPY_tab2, 16, 2)) + "%"
                  : ''
              }
              isRed={true}
              isChoosen__supply__tab={isChoosen__supply__tab}
              showIcon={fmt({ id: "supply_apy_tips" })}
            />
            <ChangeableValue
              i18n_text={"loan_to_value"}
              value={supplyTokenData ? format_num_to_K(format_bn(supplyTokenData[1].toString(), 16, 2)) + "%" : ""}
              // showIcon={fmt({ id: "loan_to_value_tips" })}
              showIcon={fmt({ id: "ltv_popup_tips" })}
              isSuperCharged={supplyTokenData && supplyTokenData[3] || false}
            />
            <ChangeableValue
              i18n_text={"Segregated_Asset"}
              value={accountSupplyInfo && accountSupplyInfo[4] ? 'YES' : "NO"}
            />
            {
              accountSupplyInfo && accountSupplyInfo[4] &&
              <ChangeableValue
                i18n_text={"Available_to_borrow"}
                showIcon={fmt({ id: "Available_to_borrow_tips" })}
                value={accountSupplyInfo && accountSupplyInfo[5]
                  ? '$' + format_num_to_K(format_bn(accountSupplyInfo[5].toString(), 2, 2)) : ''}
              />
            }
            <ChangeableValue
              i18n_text={"price"}
              value={supplyTokenData ? "$" + format_num_to_K(format_bn(supplyTokenData[2].toString(), 18, 2)) : ""}
              token={this__token}
              showPriceUpdate={true}
              add_Notifies={add_Notifies}
              setNowNotifies={setNowNotifies}
              update_Notifies={update_Notifies}
              del_Notify={del_Notify}
              setShow__cancel={setShow__cancel}
            />
            <StyledChart>
              <ReactApexChart
                options={chartState.options}
                series={chartState.series}
                type="area"
                height={320}
              />
            </StyledChart>
          </StyleRightSection>
        </BorderWrap>
      </FlexBoxWrap>


      <Modal isOpen={isModalOpen}>
        <StyledModalTitle>
          {'Withdraw ' + this__token}
          <StyledClosed>
            <CloseModal src={close} onClick={handleDismiss_disable} />
          </StyledClosed>
        </StyledModalTitle>

        <PaddingWrap>
          <OperateSec>
            <SecTop>
              <ChangeItem>
                <ChangeLeft>Withdraw Amout</ChangeLeft>
                <ChangeRight>
                  {format_num_to_K(format_bn(withdraw_number, 0, 2))}
                </ChangeRight>
              </ChangeItem>
              <ChangeItem>
                <ChangeLeft>Delay Time</ChangeLeft>
                <ChangeRight>
                  {
                    delayTime &&
                    <>{Math.floor(Number(delayTime) / 60) + ' min : ' + Number(delayTime) % 60 + ' Sec'}</>
                  }
                </ChangeRight>
              </ChangeItem>
            </SecTop>
          </OperateSec>

          <OperateSec className="spe">
            <TipsText>
              <LeftIcon>
                <img src={tanhao} alt="" />
              </LeftIcon>
              <RightText>
                The withdraw will be delayed, and the user will claim the withdraw assets on the Dashboard page.
              </RightText>
            </TipsText>
          </OperateSec>

          <StyledSupply_btn
            className={bool_Btn || (marketsInfo && marketsInfo[5]) ? "disabledBtn" : ""}
            onClick={isGasToken ? click_withdraw__spe : click_withdraw}
          >
            {fmt({ id: "WITHDRAW" })}
          </StyledSupply_btn>
          <div style={{ marginBottom: '16px' }}></div>
        </PaddingWrap>
      </Modal>
    </>
  )
}



const StyledTab = styled.div`
  width: 50%;
  text-align: center;
  cursor: pointer;
  color: ${(props) => props.theme.global_grey};
  position: relative;

  &.isChoosen {
    color: ${(props) => props.theme.operationPage.light.mainBlack};

    .dark & {
      color: ${(props) => props.theme.operationPage.dark.mainBlack};
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 28px;
      height: 5px;
      background-color: ${(props) => props.theme.global_supply};
      border-radius: 3px;
      left: calc(50% - 14px);
      bottom: -20px;

      @media (max-width: 1119px) {
        bottom: -14px;
        height: 3px;
      }
    }
  }
`
const StyledModalTitle = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  padding-top: 24px;
  margin-bottom: 16px;
  .dark & {
    color: #fff;
  }
`
const StyledClosed = styled.div`
  position: absolute;
  right: 20px;
  top: 22px;
`
const CloseModal = styled.img`
  width: 24px;
  height: 24px;
`
const OperateSec = styled.div`
  width: 100%;
  padding: 8px 8px;
  border-radius: 8px;
  background: #F5F6F9;
  &.mt16 {
    margin-top: 16px;
  }
  &.Nopadding {
    height: 44px;
    padding: 0 8px;
  }
  select {
    border: none;
    width: 100%;
    height: 100%;
    background: transparent;
  }
  .dark & {
    background: rgba(19, 19, 19, 1);
  }
  &.spe {
    margin-top: 16px;
    margin-bottom: 16px;
    background: rgba(255, 167, 38, 0.16);
  }
`;
const SecTop = styled.div`
  color: #999;
`;
const ChangeItem = styled.div`
display: flex;
justify-content: space-between;
margin-top: 6px;
margin-bottom: 6px;
font-size: 14px;
`
const ChangeLeft = styled.div`
display: flex;
`
const ChangeRight = styled.div`
display: flex;
font-weight:600;
.dark & {
  color: #fff;
}
`
const TipsText = styled.div`
  color: rgba(255, 167, 38, 1);
  font-size: 12px;
  display: flex;
  gap: 10px;
`
const LeftIcon = styled.div`
  display: flex;
  /* margin-right: 20px; */
  width: 40px;
  /* background-color: red; */
  img {
    width: 18px;
  }
`
const RightText = styled.div`
  display: flex;
`



const LogoImgWrap = styled.div`
  text-align: center;
  padding-top: 90px;
  height: 279px;

  @media (max-width: 1199px) {
    height: 224px;
    padding-top: 70px;
  }
`;
const LogoImg = styled.img`
  width: 50px;
  height: 50px;
  @media (max-width: 1199px) {
    width: 40px;
    height: 40px;
  }
`;



// change height
const RangeWrap = styled.div`
  /* background: red; */
  height: 120px;
  padding-top: 10px;
  position: relative;
`;
const StyledRange = styled.div`
  position: absolute;
  right: 0;
  color: ${(props) => props.theme.global_supply};
  font-size: 14px;
  font-weight: 500;
  top: 28px;
`;

// change height
const StyledAs_collateral = styled.div`
  position: absolute;
  left: 0;
  color: ${(props) => props.theme.global_grey};
  font-size: 14px;
  font-weight: 500;
  top: 85px;
  input {
    margin-right: 6px;
  }
`;

const StyledPercentage = styled.div`
  /* background: green; */
  display: flex;
  flex-direction: row-reverse;
`;
const PercentageItem = styled.div`
  width: 66px;
  text-align: center;
  height: 32px;
  line-height: 30px;
  border-radius: 30px;
  margin-left: 6px;
  font-size: 14px;
  font-weight: 500;
  color: ${(props) => props.theme.global_grey};
  cursor: pointer;
  border: 1px solid ${(props) => props.theme.operationPage.light.borderColor};

  .dark & {
    border: 1px solid ${(props) => props.theme.operationPage.dark.borderColor};

    &.isPercentageChoosen {
      background: ${(props) => props.theme.global_supply};
      color: #fff;
      opacity: 1;
    }
  }

  &.isPercentageChoosen {
    background: ${(props) => props.theme.global_supply};
    color: #fff;
    opacity: 0.8;
  }
`;

const Styledinput = styled.input`
  height: 100%;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  padding-left: 20px;
  color: ${(props) => props.theme.operationPage.light.mainBlack};
  background: ${(props) => props.theme.body_background_light};

  .dark & {
    color: ${(props) => props.theme.operationPage.dark.mainBlack};
    background: ${(props) => props.theme.body_background_night};
  }

  @media (max-width: 1119px) {
    font-size: 13px;
    padding-left: 14px;
  }
`;

const SpanMax = styled.span`
  position: absolute;
  right: 20px;
  top: 16px;
  height: 30px;
  line-height: 30px;
  color: ${(props) => props.theme.global_supply};
  background: #fff3e0;
  border-radius: 8px;
  font-weight: bold;
  cursor: pointer;
  padding-left: 14px;
  padding-right: 14px;
  opacity: 0.8;
  transition: 0.1s;

  &:hover {
    opacity: 1;
  }

  @media (max-width: 1119px) {
    font-size: 13px;
    right: 14px;
    top: 9px;
    height: 24px;
    line-height: 24px;
    padding-left: 10px;
    padding-right: 10px;
  }
`;



const StyledSupply_btn = styled.div`
  height: 60px;
  line-height: 60px;
  text-align: center;
  background: ${(props) => props.theme.global_supply};
  color: #ffffff;
  border-radius: 12px;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  opacity: 0.9;
  transition: 0.1s;

  &:hover {
    opacity: 1;
  }

  @media (max-width: 1119px) {
    height: 42px;
    line-height: 42px;
    font-size: 13px;
  }
`;

const Crumb = styled.div`
  display:flex;
  justify-content:space-between;
  align-items:center;
  width: 70px;
  height:32px;
  margin-top: 64px;
  margin-bottom: 16px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.08);
  padding-left: 8px;
  @media(max-width: 1199px) {
    margin-top: 20px;
  }
`
const RouterLink = styled(NavLink)`
`
const BackToHome = styled.b`
  display:flex;
  align-items:center;
  font-size:16px;
  height:30px;
  line-height:30px;
  color: #fff;
  font-weight: 500;
  &:before {            
    content: '';            
    display: block;
    width: 8px;            
    height: 8px; 
    margin-right:5px;      
    border-right: 2px solid ${props => props.theme.colors.lightGrey};            
    border-top: 2px solid ${props => props.theme.colors.lightGrey};            
    -webkit-transform: rotate(-135deg); /*箭头方向可以自由切换角度*/            
    transform: rotate(-135deg);
}
`

export default SupplyWithdraw;
