import React from "react";
import styled from "styled-components";


const StyleRightSection = ({ children }: {
    children: React.ReactNode
  }) => {
    return (
        <StyleRight>
            {children}
        </StyleRight>
    );
}

const StyleRight = styled.div`
    flex: 1;
    min-width: 417px;
    padding-left: 20px;
    margin-top: -10px;

    @media (max-width: 1119px) {
        width: 100%;
        min-width: 100%;
        padding-left: 0px;
        margin-top: 0px;
    }
`

export default StyleRightSection

